import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { saveCustomer, get_gst_treatment } from "../../../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";

const schema = yup
  .object()
  .shape({
    gstTreat: yup
      .array()
      .min(1, "Please select gst registration type !")
      .required("Please select gst registration type !"),
    TIN: yup.
      string()
      .matches(/^[A-Z0-9]*$/, 'Enter valid TIN no!')
      .notRequired(),
    TAN: yup.
      string()
      .matches(/^[A-Z0-9]*$/, 'Enter valid TAN no!')
      .notRequired(),
    CSTNO: yup.
      string()
      .matches(/^[A-Z0-9]*$/, 'Enter valid CST no!')
      .notRequired(),
    VATNO: yup.
      string()
      .matches(/^[A-Z0-9]*$/, 'Enter valid VAT no!')
      .notRequired(),
    // gst: yup.
    //   string()
    //   .matches(/^[A-Z0-9]*$/, 'Enter valid GST no!')
    //   .notRequired(),
    gst: yup
      .string()
      .when(['gstTreat'], (gstTreat, schema) => {
          if (gstTreat !== undefined && gstTreat.length > 0) {

              const gst_typeSelected = gstTreat[0].name == 'Register';

              // If 'gst_tye' is in the category, make 'GST number' mandatory
              return gst_typeSelected
                  ? schema
                      .required("Please enter GST number !")
                      .max(15, 'GST number must be 15 characters only !')
                      .matches(/^[A-Z0-9]*$/, 'Enter valid GST number !')
                  : schema
                      
          } else {
              return schema

          }
      }),
  })
  .required();

function BillingDetail(props) {
  let navigate = useNavigate();
  const { register, handleSubmit, formState, reset, control } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  const [gst_treat, setGST_Treat] = useState({ loading: false, data: [] });
  const [constLabel, setConstLabel] = useState({});

  let { errors } = formState;

  useEffect(() => {
    setConstLabel(getLabels('Party Master', 'Customer'));
    fetchGSTTreatment();
  }, []);

  function fetchGSTTreatment(){
    setGST_Treat({ ...gst_treat, state: true, data: [] });

    get_gst_treatment().then(
      (res) => {
        
        setGST_Treat({
          ...gst_treat,
          state: false,
          data: res.data.data,
        });
      },
      (err) => {
        
        setGST_Treat({ ...gst_treat, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  const onSubmit = (data) => {
    const basic = JSON.parse(localStorage.getItem("basic"));
    const party_contact = JSON.parse(localStorage.getItem("contacts"));
    const billing = JSON.parse(localStorage.getItem("billing"));
    const shipping = JSON.parse(localStorage.getItem("shipping"));

    if (
      (data !== "" || data.length !== 0) &&
      (basic !== "" || basic !== undefined) &&
      (party_contact !== "" || party_contact !== undefined) &&
      (billing !== "" || billing !== undefined) &&
      (shipping !== "" || shipping !== undefined)
    ) {
      const reqPayload = {
        party_contact: party_contact,
        billingAddresse: billing,
        shippingAddresse: shipping,
        ...basic,
        TIN: data.TIN,
        TAN: data.TAN,
        VATNO: data.VATNO,
        CSTNO: data.CSTNO,
        gst: data.gst,
        serviceTaxNo: data.serviceTaxNo,
        gst_treatment: data.gstTreat[0]?.name,
        createdBy: 1,
        modifiedBy: 1,
      };

      

      saveCustomer(reqPayload).then(
        (res) => {
          
          if (res.status === 200 || res.status === 201) {
            toast.success("Customer details created Successfully !", {
              autoClose: 3000,
            });
            // props.setKey("bankDetail");
            localStorage.removeItem("basic");
            localStorage.removeItem("contacts");
            localStorage.removeItem("billing");
            localStorage.removeItem("shipping");
            navigate(`${process.env.PUBLIC_URL}/pcms/customer`);
            reset();
          }
        },
        (err) => {
          
          if (err.response.status === 400) {
            
            toast.error(err.response.data.message, {
              autoClose: 3000,
            });
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <>
      <div className="row mt-2">
        <div className="col-md-6">
          <div className="form-group row m-t-10">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-control col-md-4"
            >
              {constLabel?.lbl_tin ? constLabel?.lbl_tin : "TIN"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control form-control-sm"
                maxLength={11}
                id="TIN"
                name="TIN"
                placeholder="Enter TIN"
                {...register("TIN")}
              />
              <span className="text-danger err-msg">{errors.TIN?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-control col-md-4"
            >
              {constLabel?.lbl_service_tax_no ? constLabel.lbl_service_tax_no : "Service Tax No"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="serviceTaxNo"
                name="serviceTaxNo"
                maxLength={15}
                placeholder="Enter service tax no."
                {...register("serviceTaxNo")}
              />
            </div>
          </div>

          <div className="form-group row mt-2">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-control col-md-4"
            >
              {constLabel?.lbl_cst_no ? constLabel.lbl_cst_no : "CST No"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="CSTNO"
                maxLength={11}
                name="CSTNO"
                placeholder="Enter CST no."
                {...register("CSTNO")}
              />
              <span className="text-danger err-msg">{errors.CSTNO?.message}</span>
            </div>
          </div>
        
          <div className="form-group row mt-2">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-control col-md-4"
            >
             {constLabel?.lbl_gst_no ? constLabel.lbl_gst_no : "GST No"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="gst"
                maxLength={15}
                name="gst"
                placeholder="Enter GST no."
                {...register("gst")}
              />
              <span className="text-danger err-msg">{errors.gst?.message}</span>
            </div>
          </div>

        </div>

        <div className="col-md-6">
          <div className="form-group row mt-2">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-control col-md-4"
            >
              {constLabel?.lbl_tan ? constLabel.lbl_tan : "TAN"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control form-control-sm"
                maxLength={10}
                id="TAN"
                name="TAN"
                placeholder="Enter TAN"
                {...register("TAN")}
              />
              <span className="text-danger err-msg">{errors.TAN?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-control col-md-4"
            >
              {constLabel?.lbl_vat ? constLabel.lbl_vat : "VAT"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="VATNO"
                maxLength={15}
                name="VATNO"
                placeholder="Enter VAT"
                {...register("VATNO")}
              />
              <span className="text-danger err-msg">{errors.VATNO?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-4"
            >
              {constLabel?.lbl_gst_treatment ? constLabel.lbl_gst_treatment : "GST Registration Type"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <div className="col-md-8">
              <Controller
                name="gstTreat"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    size="sm"
                    multiple={false}
                    options={gst_treat.data !== undefined ? gst_treat.data : []}
                    isLoading={gst_treat.state}
                    placeholder="Select gst registration type..."
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid={errors.gstTreat ? true : false}
                  />
                )}
              />
              <span className="text-danger">{errors.gstTreat?.message}</span>
            </div>
          </div>
        </div>
       
      </div>

      <div className="row justify-content-between mt-2">
        <div className="col-md-4">
          <span>
            <i style={{ color: "red" }}>*</i>
            <u> indicate Mandatory fields</u>
          </span>
        </div>
        <div className="col-sm-2 text-end">
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-w-600 f-16 btn btn-yellow "
          >
            {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
            {/* <FontAwesomeIcon style={{ color: "#000" }} icon={faPlay} size="sm" /> */}
          </button>
        </div>
      </div>
    </>
  );
}

export default BillingDetail;

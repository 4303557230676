import React, { useState, useEffect, useRef } from "react";
import logo from "../../../src/assets/images/prodi_tm.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import loginImg from "../../../src/assets/images/ProdiLogInFront.png";
import * as yup from "yup";
import { Carousel, Spinner } from "react-bootstrap";
import axios from "axios";
import { forgot_password, forgot_verify_otp } from "../../constant";
import SetNewCredential from "./SetNewCredential";
import OTPInput from "./OTPInput";

const schema = yup
  .object()
  .shape({
    username: yup.string().required("Username is required !"),
  })
  .required();

function ForgotPassword() {

    const [lockBtn, setLockBtn] = useState(false);
    const [t_username, setT_Username] = useState("");

    const [otp, setOtp] = useState(Array(4).fill(""));
    const otpInputs = useRef([]);

    const [otpCalled, setOtpCalled] = useState(false);
    const [new_credential, setNew_Credential] = useState(false);

    const [time, setTime] = useState(0); // Timer starts at 0
    const [isActive, setIsActive] = useState(false); // Control timer start/stop

    const {
        register,
        handleSubmit,
        formState,
        getValues,
        setError,
        clearErrors,
      } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
      }); // initialise the hook
    
      const { errors } = formState;

      useEffect(() => {
        let timer;
        if (isActive && time > 0) {
          timer = setTimeout(() => {
            setTime(time - 1);
          }, 1000);
        }
    
        // If time reaches 0, deactivate the timer
        if (time === 0) {
          setIsActive(false);
        }
    
        return () => clearTimeout(timer); // Cleanup the timer
      }, [time, isActive]);
    
      const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
      };
    
      const startTimer = () => {
        setTime(120); // Set timer to 5 minutes (300 seconds)
        setIsActive(true); // Activate the timer
      };
    
      const onSubmit = (data) => {
        console.log("data", data);
        setLockBtn(true);
        // setOtpCalled(true);
        startTimer();
        setT_Username(data.username);
        send_otp(data.username);
        
      };
    
      function resendOtp(){
        // console.log("props", props);
        startTimer();
        send_otp(t_username);
    
        setOtp(Array(4).fill("")); // Reset the mobOtp array
        otpInputs.current.forEach((input) => {
          if (input) input.value = ""; // Clear each input field
        });
      }
    
      function verifyOtp(){
    
          let base_url = process.env.REACT_APP_COGNITO_AUTH_URL
          const url = `${base_url}${forgot_verify_otp}`;
          // console.log("url",url)
          const payload = {
            username : t_username,
            otpInput : otp.join(""),
          }
    
          axios.post(url, payload)
          .then((res) => {
            // console.log("verify res", res);
            toast.success(res.data.message, {autoClose: 2000});
            setNew_Credential(true);
          })
          .catch((err) => {
            console.log("err", err);
            toast.error(err.response.data.message, {autoClose: 2000});
          });
      } 
    
      function send_otp(username){
      let base_url = process.env.REACT_APP_COGNITO_AUTH_URL
      const url = `${base_url}${forgot_password}`;
      // console.log("url",url)
    
        axios.post(url, { username: username})
        .then((res) => {
          // console.log("otp res", res);
          toast.success(res.data.message, {autoClose: 2000});
          setTimeout(() => {
            setOtpCalled(true);
          }, 2200);
        })
        .catch((err) => {
          console.log("err", err);
          setError("username", {
            type: "custom",
            message: err.response.data.message,
          });
          setOtpCalled(false);
          setLockBtn(false);
        });
      }

  return (
    <React.Fragment>
    <ToastContainer />

    <div
      className="container d-flex justify-content-center align-items-center"
      style={{ height: "100vh" }}
    >
      <div
        className="card rounded-0 border-0 shadow-dark"
        style={{ width: "70%" }}
      >
        <div className="row">
          <div className="col-12 col-md-6">
            <Carousel
              prevIcon={false}
              nextIcon={false}
              interval={null}
              indicators={null}
            >
              <Carousel.Item>
                <img
                  className="d-block w-100"
                  src={loginImg}
                  alt="Third slide"
                  style={{ height: "520px", filter: "brightness(0.5)" }}
                />
                <Carousel.Caption style={{ marginBottom: "1rem" }}>
                  <div className="text-start">
                    <p className="roboto body3 w-100 my-0 py-4">
                      Smart MOS Solution for SME Manufacturers
                    </p>
                    {/* <h3 className="bold w-100"></h3> */}
                    <h3 className="bold w-100">
                      Empowering Manufacturing Excellence with PRODi
                    </h3>
                  </div>
                </Carousel.Caption>
              </Carousel.Item>
            </Carousel>
          </div>
          <div className="col-12 col-md-6">
            <div className="d-flex justify-content-center">
              <img src={logo} height={130} width={150}></img>
            </div>
            <div className="text-center">
              <h3 className="bold w-100">{new_credential ? "Reset Password" : "Forgot Password"}</h3>
            </div>
          {new_credential || otpCalled == false ? null :
            <div className="d-flex justify-content-center mt-2">
              {time > 0 && <h3 className="text-muted body2">{"OTP has sent to your registered mobile no and email "}.</h3>}
            </div>
          }

            { new_credential == false ? otpCalled == true ? null :
                <div className="mx-auto px-4">
                    <div>
                        <form>
                        <div className="col mt-3">
                            <div className="input-group">
                            <input
                                className={
                                    errors.username
                                    ? "form-control is-invalid"
                                    : "form-control"
                                }
                                type="text"
                                placeholder="Enter username"
                                id="username"
                                name="username"
                                {...register("username")}
                            />
                            </div>
                            <span className="text-danger err-msg">
                            {errors.username?.message}
                            </span>
                        </div>

                        <button
                            className="btn button-primary w-100 mt-4 roboto-bold"
                            type="submit"
                            onClick={handleSubmit(onSubmit)}
                            style={{ letterSpacing: "1px" }}
                        >
                            Send OTP{" "}
                            {lockBtn ? (
                            <Spinner animation="border" size="sm" />
                            ) : null}{" "}
                        </button>
                        </form>
                    </div>
                </div>
            : null}

            {new_credential == false ? otpCalled ?
              <> 
                <div className="d-flex justify-content-center"> 
                    <OTPInput
                      length={4}
                      otp={otp}
                      setOtp={setOtp}
                      otpInputs={otpInputs}
                      resendOtp={resendOtp} // Pass down the resend otp and clear inputs function
                    />
                </div> 
                <div className="d-flex justify-content-center mt-1">
                    {time > 0 && <h3 className="text-danger body2">OTP expires in: {formatTime(time)}</h3>}
                    {time === 0 && isActive && (
                      <p className="text-muted body2">OTP has expired. Please request a new one.</p>
                    )}
                </div>
                <div className="d-flex justify-content-between col-12 col-md-10 mx-auto">
                  <button
                    className="btn button-secondary w-100 mt-1 roboto-bold me-2"
                    type="submit"
                    onClick={() => verifyOtp()}
                    disabled={isActive == false ? true : false}
                  >
                    Verify OTP{" "}
                  </button>
                  <button
                    className="btn button-primary w-100 mt-1 roboto-bold"
                    type="submit"
                    onClick={() => resendOtp()}
                    disabled={isActive == true ? true :  false}
                  >
                    Resend OTP{" "}
                  </button>
                </div>
              </>
            : null : null}

            {new_credential ? 
                <SetNewCredential t_username={t_username} />
            : null }


          </div>
        </div>
      </div>
    </div>
  </React.Fragment>
  )
}

export default ForgotPassword
import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Modal } from "react-bootstrap";
import { get_document_type, update_WorkOrder } from "../../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faClose } from "@fortawesome/free-solid-svg-icons";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { Trash2 } from "react-feather";
import { convertBase64 } from "../../../../common/Functions/CommonFunctions";
import { useNavigate } from "react-router-dom";

const documentsFileTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf",
];
const schema = yup
  .object()
  .shape({
    items: yup.array().of(
      yup.object().shape({
        doc_type: yup
          .array()
          .min(1, "Doc type is required")
          .required("Doc type is required !"),
        doc: yup
          .mixed()
          .nullable()
          .notRequired()
          .test("fileSize", "Document can be uploaded up to 10 MB", (value) => {
            console.log("value", value);
            if (value.length == 0) return true; // If no file is provided, it's valid.
            return value[0]?.size <= 10 * 1024 * 1024; // Check file size if a file is provided.
          })
          .test(
            "fileType",
            "Only .doc, .docx, .xls, .xlsx, png ,jpg , jpeg and .pdf files are allowed",
            (value) => {
              if (value.length == 0) return true; // If no file is selected, it's considered valid.
              // Check if the file type is included in the allowed document file types.
              return documentsFileTypes.includes(value[0]?.type);
            }
          ),
        // .required("Required"),
      })
    ),
  })
  .required();

function WOImageGallary(props) {
  let navigate = useNavigate();
  const {
    galVisible,
    setGalVisible,
    woData,
    page,
    entriesPerPage,
    fetchWorkOrders,
  } = props;
  const [constLabel, setConstLabel] = useState({});
  const [docType, setDocType] = useState({ data: [], loading: false });
  const imageFileExtensions = ["png", "jpg", "jpeg"];
  const { register, handleSubmit, formState, control, reset, watch, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        items: [
          {
            doc: "",
            doc_type: [],
          },
        ],
      },
    }); // initialise the hook

  const { fields, append, remove } = useFieldArray({ control, name: "items" });
  let { errors } = formState;

  useEffect(() => {
    fetchDoctype();
  }, []);

  useEffect(() => {
    var imageItems = [];
    if (woData !== undefined && woData?.work_order_files?.length > 0) {
      for (let i = 0; i < woData?.work_order_files?.length; i++) {
        imageItems.push({
          uuid: woData?.work_order_files?.[i]?.uuid,
          doc_type: [
            {
              name: woData?.work_order_files?.[i]?.document_type,
            },
          ],
        });
      }
      // setValue("items", poLineItems);
      reset({ items: imageItems });
    }
  }, [woData]);

  function fetchDoctype() {
    get_document_type().then(
      (res) => {
        setDocType({ ...docType, loading: false, data: res.data.data });
      },
      (err) => {
        setDocType({ ...docType, loading: false, data: [] });
      }
    );
  }

  const onSubmit = async (data) => {
    console.log("data", data);
    if (data !== "") {
      const imageData = [];
      if (data.items !== null && data.items?.length > 0) {
        for (let i = 0; i < data.items?.length; i++) {
          // start if
          if (data.items[i].doc.length > 0) {
            const getExt = data.items[i].doc[0].name?.split(".").pop();
            let dataimage = await convertBase64(data.items[i].doc[0]);
            let newImage = dataimage.replace(
              /data:image\/[a-zA-Z]+;/,
              "data:image/" + getExt + ";"
            );

            const imgDataItem = {
              document_type: data.items[i].doc_type[0].name,
              document: newImage,
              document_name: data.items[i].doc[0].name,
            };

            if (woData?.work_order_files?.[i]?.uuid) {
              imgDataItem.uuid = woData?.work_order_files?.[i]?.uuid;
            }

            imageData.push(imgDataItem);
          } // end if data.items[i].doc.length > 0
        }
      }

      const reqPayload = {
        uuid: woData?.uuid,
        org_id: woData?.org_id,
        location_id: woData?.location_id,
        party_id: woData?.party_id,
        product_id: woData?.product_info?.uuid,
        product_name: woData?.product_info?.productName,
        productId: woData?.product_info?.productId,
        part_no: woData?.part_no,
        quantity: parseInt(woData?.quantity),
        unit: woData?.unit,
        remark: woData?.remark,
        work_order_status: woData?.work_order_status,
        serial_number_start: parseInt(woData?.serial_number_start),
        serial_number_end: parseInt(woData?.serial_number_end),
        start_date: woData?.start_date,
        end_date: woData?.end_date,
        word_order_files: imageData,
      };

      // console.log("reqPayload", reqPayload);

      update_WorkOrder(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Work order images added successfully !", {
              autoClose: 2000,
            });
            setGalVisible(false);
            fetchWorkOrders(page, entriesPerPage);
            // setTimeout(() => {
            //   navigate("/pcms/work-order");
            // }, 2200);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={galVisible}
        onHide={() => {
          setGalVisible(false);
          reset();
        }}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_image_gallary
              ? constLabel.lbl_image_gallary
              : "Image Gallery"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row mx-2">
            <table className="table table-bordered">
              <thead className="table-grey roboto-bold">
                <tr>
                  <th scope="col">
                    {constLabel?.lbl_select_image
                      ? constLabel.lbl_select_image
                      : "Select Image"}
                  </th>
                  <th scope="col">
                    {constLabel?.lbl_select_doc_type
                      ? constLabel.lbl_select_doc_type
                      : "Select Doc Type"}
                  </th>

                  <th scope="col" width="5%">
                    {"Action"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {fields.map(({ id }, index) => (
                  <tr key={id}>
                    <td style={{ verticalAlign: "top" }}>
                      <input
                        className={
                          errors.items?.[index]?.doc
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        type="file"
                        name={`items[${index}].doc`}
                        {...register(`items[${index}].doc`)}
                        // onChange={(e) => handleFileChange(e, index)}
                        placeholder="Please select image"
                      />

                      {errors.items?.[index]?.doc && (
                        <span className="text-danger err-msg">
                          {errors.items?.[index]?.doc.message}
                        </span>
                      )}

                      {woData && woData?.work_order_files?.length > 0 ? (
                        <div>
                          <a
                            href={
                              process.env.REACT_APP_IMAGE_URL +
                              woData?.work_order_files?.[index]?.attachment
                            }
                            target="_blank"
                          >
                            {woData?.work_order_files?.[index]?.name}
                          </a>
                        </div>
                      ) : null}

                      {index + 1 == fields.length && (
                        <button
                          style={{ fontSize: "16px" }}
                          className="btn btn-sm button-primary px-2 mt-2"
                          onClick={() => {
                            // if (fields.length < 2) {
                            append({});
                            // } else {
                            //   toast.error("Maximum 2 images allowed", {
                            //     autoClose: 2000,
                            //   });
                            // }
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              color: "#ffffff",
                            }}
                            icon={faAdd}
                            size="sm"
                          />
                          {constLabel?.lbl_add_image
                            ? constLabel?.lbl_add_image
                            : "Add Image"}
                        </button>
                      )}
                    </td>

                    <td style={{ verticalAlign: "top" }}>
                      <Controller
                        name={`items[${index}].doc_type`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            multiple={false}
                            size="sm"
                            options={docType.data}
                            isLoading={docType.loading}
                            placeholder="Select doc Type"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={
                              errors.items?.[index]?.doc_type ? true : false
                            }
                          />
                        )}
                      />

                      {errors.items?.[index]?.doc_type && (
                        <span className="text-danger err-msg">
                          {errors.items?.[index]?.doc_type.message}
                        </span>
                      )}
                    </td>

                    <td
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      <div
                        className="deleteIcon cursor_pointer"
                        onClick={
                          index > 0
                            ? () => {
                                remove(index);
                              }
                            : null
                        }
                      >
                        <Trash2 color="#FFFFFF" size={18} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {constLabel?.lbl_close ? constLabel.lbl_close : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default WOImageGallary;

import React, { useState, useEffect } from 'react'
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Edit2 } from 'react-feather';
import { Modal } from 'react-bootstrap';
import { updateCutlot, RMI_list_for_Cutlot, get_product_by_rmi } from '../../../api';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { Trash2 } from "react-feather";

const imageFileTypes = ["image/jpeg", "image/jpg", "image/png"];
const schema = yup
    .object()
    .shape({
        rawtype: yup
            .array()
            .min(1, "Material type is required !")
            .required("Material type is required !"),
        rmi: yup
            .array()
            .min(1, "Material code is required !")
            .required("Material code is required !"),
        qty: yup
            .string()
            .max(200, "Maximum 200 characters allowed !")
            .required("Quantity is required !"),
        cutpiece_qty: yup
            .string()
            .max(200, "Maximum 200 characters allowed !")
            .required("Quantity is required !"),
        cutpiece_length: yup
            .string()
            .max(200, "Maximum 200 characters allowed !")
            .required("Quantity is required !"),
        items: yup.array().of(
            yup.object().shape({
                doc_type: yup
                    .array()
                    .min(1, "Doc type is required")
                    .required("Doc type is required !"),
                image: yup
                    .mixed()
                    .test('fileSize', 'Image can be uploaded upto 10 MB',
                        (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024))
                    .test("fileType", "Only image files are allowed", (value) => {
                        if (!value) return true; // If no file is selected, it's considered valid.
                        // return value[0]?.type.startsWith('image/');
                        return imageFileTypes.includes(value[0]?.type);
                    })
                    .required("Required"),
            })
        ),
    })
    .required();

function EditCutlot(props) {
    const [visible, setVisible] = useState(false);

    const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        // defaultValues: { items: [{}] },
    }); // initialise the hook

    let { errors } = formState;

    const { fields, append, remove } = useFieldArray({ control, name: "items" });
    const [productDetails, setProductDetails] = useState({});
    const [rmilistForm, setRMIListForm] = useState({ data: [], loading: false });
    const material_typeChange = watch("rawtype");
    const rmiChange = watch("rmi");

    useEffect(() => {

        if (visible) {
            console.log(props);
            setValue("rawtype", [{ material_type: props.data.rawtype }]);

            setValue("rmi", [{ material_code: props.data.RMIDetails.material_code, toolinvenId: props.data.rmi }]);
            setValue("qty", props.data.qty);
            setValue("productName", props.data.productInfo.productName);
            setValue("cutpiece_qty", props.data.cutpiece_qty);
            setValue("cutpiece_length", props.data.cutpiece_length);
        }
    }, [visible]);

    useEffect(() => {
        if (material_typeChange && material_typeChange.length > 0) {
            console.log('material_typeChange', material_typeChange);

            console.log("material_typeChange", material_typeChange);
            setRMIListForm({ ...rmilistForm, data: [], loading: true });

            RMI_list_for_Cutlot({ materialtype: material_typeChange[0].material_type }).then((res) => {
                console.log("res", res);
                const data = res.data.data.map((val) => {
                    if (!val.material_code) {
                        val.material_code = "";
                    }
                    return val
                })
                setRMIListForm({ ...rmilistForm, data: data, loading: false });
            }, (err) => {
                console.log("err", err);
                setRMIListForm({ ...rmilistForm, data: [], loading: false });
            })
        }


    }, [material_typeChange]);


    useEffect(() => {
        if (rmiChange && rmiChange.length > 0) {
            console.log("rmiChange", rmiChange);

            get_product_by_rmi({ toolinvenId: rmiChange[0].toolinvenId }).then((res) => {
                console.log("res", res);

                if (res.data.data.length > 0) {

                    setValue("productName", res.data.data[0].productname);
                    setValue("availableqty", res.data.data[0].acceptyqty);
                    setProductDetails(res.data.data[0]);
                } else {
                    setProductDetails({});
                }
            }, (err) => {
                console.log("err", err);
            })
        }
    }, [rmiChange]);

    function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            if (file) {
                reader.readAsDataURL(file);
            } else {
                reject(new Error("File is empty"));
            }
        });
    }

    const onSubmit = async (data) => {


        if (data !== "") {
            console.log("data", data);

            const reqPayload = {
                "uuid": props.data.uuid,
                "rawtype": data.rawtype[0].material_type,
                "rmi": data.rmi[0].toolinvenId,
                "semiproductid": "",
                "productId": productDetails.actualprodid,
                "qty": data.qty,
                "processedqty": data.qty,
                "destructivetestingqty": "0",
                "productName": productDetails.productname,
                "cutpiece_qty": data.cutpiece_qty,
                "cutpiece_length": data.cutpiece_length,
                "availqty": data.availableqty
                // availqty is the limit
            }

            const imageData = [];
            if (data.items !== null && data.items.length > 0) {
                for (let i = 0; i < data.items.length; i++) {
                    const getExt = data.items[i].image[0].name?.split('.').pop();

                    let dataimage = await convertBase64(data.items[i].image[0]);
                    let newImage = dataimage.replace(/data:image\/[a-zA-Z]+;/, "data:image/" + getExt + ";");

                    imageData.push({
                        doc_type: data.items[i].doc_type[0].name,
                        image: newImage,
                        filename: data.items[i].image[0].name,
                    });
                }
            }

            if (imageData.length > 0) {
                reqPayload.imagelist = imageData;
            }

            console.log("imageData", imageData);

            updateCutlot(reqPayload)
                .then(
                    (res) => {

                        if (res.status === 200 || res.status === 201) {
                            toast.success("Cutlot updated Successfully !", {
                                autoClose: 3000,
                            });
                            setVisible(false);
                            props.fetchCutlot(props.page, props.entriesPerPage);
                        }
                    },
                    (err) => {

                        if (err.response.status === 400) {

                            toast.error(err.response.data.message, {
                                autoClose: 2000,
                            });

                        }
                    }
                )
        } else {
            errors.showMessages();
        }
    };

    return (
        <React.Fragment>
            <Tooltip id={"edit-tooltip"} place="top" />
            <div
                data-tooltip-id={"edit-tooltip"}
                data-tooltip-content={props.constLabel?.lbl_edit_cutlot ? props.constLabel?.lbl_edit_cutlot : "~Edit Cutlot"}
                className="editIcon me-2">
                <Edit2
                    color="#FFFFFF"
                    size={18}
                    className="cursor-pointer"
                    onClick={() => {
                        // setUpdateForm(val)
                        setVisible(true);
                    }}
                />
            </div>
            <Modal show={visible} onHide={() => setVisible(false)} size={"lg"}>
                <Modal.Header
                    style={{ background: "#2A3643", color: "white" }}
                    closeButton
                >
                    <Modal.Title>{props.constLabel?.lbl_update_cutlot ? props.constLabel?.lbl_update_cutlot : "~ Update Cutlot"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <div className="form-group mt-2">
                                <label style={{ fontSize: "14px" }} className="col-form-label  roboto-bold">
                                    {props.constLabel?.lbl_material_type ? props.constLabel?.lbl_material_type : "~ Material Type"}
                                    <span className="text-danger">*</span>
                                </label>

                                <Controller
                                    name="rawtype"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="material_type"
                                            multiple={false}

                                            options={props.materialTypelist.data}
                                            isLoading={props.materialTypelist.loading}
                                            placeholder="Select Material Type"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.rawtype ? true : false}
                                        />
                                    )}
                                />

                                <span className="text-danger err-msg">
                                    {errors.rawtype?.message}
                                </span>
                            </div>
                        </div>

                        {material_typeChange && material_typeChange.length > 0 &&
                            <div className='col-12 col-md-6'>
                                <div className="form-group mt-2">
                                    <label style={{ fontSize: "14px" }} className="col-form-label  roboto-bold">
                                        {props.constLabel?.lbl_materialcode ? props.constLabel?.lbl_materialcode : "~ Material Code"}
                                        <span className="text-danger">*</span>
                                    </label>

                                    <Controller
                                        name="rmi"
                                        control={control}
                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                            <Typeahead
                                                id="basic-typeahead-single"
                                                labelKey="material_code"
                                                multiple={false}
                                                options={rmilistForm.data}
                                                isLoading={rmilistForm.loading}
                                                placeholder="Select Material Code"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                selected={value}
                                                isInvalid={errors.rmi ? true : false}
                                            />
                                        )}
                                    />

                                    <span className="text-danger err-msg">
                                        {errors.rmi?.message}
                                    </span>
                                </div>
                            </div>
                        }

                        {material_typeChange && material_typeChange.length > 0 &&
                            <div className='col-12 col-md-6'>
                                <div className="form-group mt-2">
                                    <label style={{ fontSize: "14px" }} className="col-form-label  roboto-bold">
                                        {props.constLabel?.lbl_productname ? props.constLabel.lbl_productname : "~ Product Name"}
                                        <span className="text-danger">*</span>
                                    </label>

                                    <input
                                        type="text"
                                        className={`form-control ${errors.productName ? "is-invalid" : null
                                            }`}
                                        {...register("productName")}
                                        disabled
                                    />

                                    <span className="text-danger err-msg">
                                        {errors.productName?.message}
                                    </span>
                                </div>
                            </div>
                        }


                        <div className='col-12 col-md-6'>
                            <div className="form-group mt-2">
                                <label style={{ fontSize: "14px" }} className="col-form-label  roboto-bold">
                                    {props.constLabel?.lbl_available_qty ? props.constLabel?.lbl_available_qty : "~ Available Qty"}
                                    <span className="text-danger">*</span>
                                </label>

                                <input
                                    type="text"
                                    className={`form-control ${errors.availableqty ? "is-invalid" : null
                                        }`}
                                    {...register("availableqty")}
                                    disabled
                                />

                                <span className="text-danger err-msg">
                                    {errors.qty?.availableqty}
                                </span>
                            </div>
                        </div>
                        <div className='col-12 col-md-6'>
                            <div className="form-group mt-2">
                                <label style={{ fontSize: "14px" }} className="col-form-label  roboto-bold">
                                    {props.constLabel?.lbl_quantity ? props.constLabel.lbl_quantity : "~ Quantity"}
                                    <span className="text-danger">*</span>
                                </label>

                                <input
                                    type="text"
                                    className={`form-control ${errors.qty ? "is-invalid" : null
                                        }`}
                                    placeholder='Enter quantity'
                                    {...register("qty")}

                                />

                                <span className="text-danger err-msg">
                                    {errors.qty?.message}
                                </span>
                            </div>
                        </div>

                        <div className='col-12 col-md-6'>
                            <div className="form-group mt-2">
                                <label style={{ fontSize: "14px" }} className="col-form-label  roboto-bold">
                                    {props.constLabel?.lbl_cutpiece_qty ? props.constLabel?.lbl_cutpiece_qty : "~ Cut piece qty"}
                                    <span className="text-danger">*</span>
                                </label>

                                <input
                                    type="text"
                                    className={`form-control ${errors.cutpiece_qty ? "is-invalid" : null
                                        }`}
                                    placeholder='Enter cutpiece quantity'
                                    {...register("cutpiece_qty")}
                                />

                                <span className="text-danger err-msg">
                                    {errors.cutpiece_qty?.message}
                                </span>
                            </div>
                        </div>

                        <div className='col-12 col-md-6'>
                            <div className="form-group mt-2">
                                <label style={{ fontSize: "14px" }} className="col-form-label  roboto-bold">
                                    {props.constLabel?.lbl_cutpiece_length ? props.constLabel?.lbl_cutpiece_length : "~ Cut piece length"}
                                    <span className="text-danger">*</span>
                                </label>

                                <input
                                    type="text"
                                    className={`form-control ${errors.cutpiece_length ? "is-invalid" : null
                                        }`}
                                    placeholder='Enter cutpiece length'
                                    {...register("cutpiece_length")}
                                />

                                <span className="text-danger err-msg">
                                    {errors.cutpiece_length?.message}
                                </span>
                            </div>
                        </div>

                        {fields.length == 0 ?
                            <div className='col-12 d-flex justify-content-end'>
                                <button
                                    style={{ fontSize: "16px" }}
                                    className="btn btn-sm button-primary px-2 mt-2"
                                    onClick={() => {
                                        if (fields.length < 2) {
                                            append({});
                                        } else {
                                            toast.error("Maximum 2 images allowed", {
                                                autoClose: 2000,
                                            });
                                        }
                                    }}
                                >
                                    <FontAwesomeIcon
                                        style={{
                                            color: "#ffffff",
                                        }}
                                        icon={faAdd}
                                        size="sm"
                                    />{props.constLabel?.lbl_add_image ? props.constLabel?.lbl_add_image : "Add Image"}
                                </button>
                            </div>
                            : null}

                        {fields.map(({ id }, index) => (
                            <div className="py-2 mt-3 table-responsive" key={id}>
                                <table className="table table-bordered">
                                    <thead className="table-grey roboto-bold">
                                        <tr>
                                            <th scope="col" width="40%">
                                                {props.constLabel?.lbl_select_image
                                                    ? props.constLabel.lbl_select_image
                                                    : "Select Image"}
                                            </th>
                                            <th scope="col" width="40%">
                                                {props.constLabel?.lbl_select_doc_type
                                                    ? props.constLabel.lbl_select_doc_type
                                                    : "Select Doc Type"}
                                            </th>

                                            <th scope="col" width="20%">
                                                {"Action"}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>

                                        <tr >
                                            <td style={{ verticalAlign: "top" }}>
                                                <input
                                                    className={
                                                        errors.items?.[index]?.image
                                                            ? "form-control form-control-sm is-invalid"
                                                            : "form-control form-control-sm"
                                                    }
                                                    type="file"
                                                    accept="image/*"
                                                    name={`items[${index}].image`}
                                                    {...register(`items[${index}].image`)}
                                                    // onChange={(e) => handleFileChange(e, index)}
                                                    placeholder="Please select image"
                                                />

                                                {errors.items?.[index]?.image && (
                                                    <span className="text-danger err-msg">
                                                        {errors.items?.[index]?.image.message}
                                                    </span>
                                                )}

                                                {index + 1 == fields.length &&
                                                    <button
                                                        style={{ fontSize: "16px" }}
                                                        className="btn btn-sm button-primary px-2 mt-2"
                                                        onClick={() => {
                                                            if (fields.length < 2) {
                                                                append({});
                                                            } else {
                                                                toast.error("Maximum 2 images allowed", {
                                                                    autoClose: 2000,
                                                                });
                                                            }
                                                        }}
                                                    >
                                                        <FontAwesomeIcon
                                                            style={{
                                                                color: "#ffffff",
                                                            }}
                                                            icon={faAdd}
                                                            size="sm"
                                                        />{props.constLabel?.lbl_add_image ? props.constLabel?.lbl_add_image : "Add Image"}
                                                    </button>
                                                }
                                            </td>

                                            <td style={{ verticalAlign: "top" }}>
                                                <Controller
                                                    name={`items[${index}].doc_type`}
                                                    control={control}
                                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                                        <Typeahead
                                                            id="basic-typeahead-single"
                                                            labelKey="name"
                                                            multiple={false}
                                                            size='sm'
                                                            options={props.docType.data}
                                                            isLoading={props.docType.loading}
                                                            placeholder="Select doc Type"
                                                            onChange={onChange}
                                                            onBlur={onBlur}
                                                            selected={value}
                                                            isInvalid={errors.items?.[index]?.doc_type ? true : false}
                                                        />
                                                    )}
                                                />

                                                {errors.items?.[index]?.doc_type && (
                                                    <span className="text-danger err-msg">
                                                        {errors.items?.[index]?.doc_type.message}
                                                    </span>
                                                )}
                                            </td>

                                            <td
                                                style={{
                                                    verticalAlign: "top",
                                                }}
                                            >
                                                <div
                                                    className="deleteIcon cursor_pointer"
                                                    onClick={
                                                        () => {
                                                            remove(index);
                                                        }
                                                    }
                                                >
                                                    <Trash2 color="#FFFFFF" size={18} />
                                                </div>
                                            </td>
                                        </tr>


                                    </tbody>
                                </table>
                            </div>
                        ))}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={handleSubmit(onSubmit)}
                        className="f-16 btn btn-yellow "
                    >
                        {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "~ Save"}
                    </button>
                </Modal.Footer>
            </Modal>

        </React.Fragment>
    )
}

export default EditCutlot
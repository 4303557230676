import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  getProdCategory,
  getCustomer,
  getMarket_Group,
  getProduct_Family,
  getUOM,
  saveProduct,
  getBOM,
} from "../../../../api";
import { getLabels } from "../../../../common/Functions/CommonFunctions";

const schema = yup
  .object()
  .shape({
    // category: yup
    //   .array()
    //   .required("Product category is required !")
    //   .min(1, "Select atleast one type !"),
    marketGroup: yup
      .array()
      .required("Please select market group")
      .min(1, "Please select market group"),
    productFamily: yup
      .array()
      .required("Please select product family")
      .min(1, "Please select product family"),
    openingStock: yup
      .string()
      .matches(/^[0-9]+$/, "Enter valid opening stock")
      .nullable(true),
    // OEMpartNo: yup
    //   .string()
    //   .required("OEM part no is required !"),
    productName: yup.string().required("Product name is required !"),
    hsn: yup
      .string()
      .matches(/^[a-zA-Z0-9]*$/, "Enter valid hsn")
      .nullable(true),
    unit: yup
      .array()
      .required("Unit is required !")
      .min(1, "Please select unit !"),
    machineCost: yup
      .string()
      .required("Cost is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid cost"),
    salesPrice: yup
      .string()
      .required("Sales price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid sales price"),
    selling_price: yup
      .string()
      .required("Selling price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid selling price"),
    purchase_price: yup
      .string()
      .required("Purchase price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid purchase price"),
    can_be_sold: yup.string().required("Required"),
    can_be_purchased: yup.string().required("Required"),
    can_be_expensed: yup.string().required("Required"),
    // has_bom: yup
    //   .string()
    //   .required("Required"),
  })
  .required();

function ProductDetailConfigure(props) {
  let navigate = useNavigate();

  const [prodCategory, setProdCategory] = useState({
    data: [],
    loading: false,
  });
  const [marketGroup, setMarketGroup] = useState({ data: [], loading: false });
  const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
  const [partyList, setPartyList] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [bomList, setBomList] = useState({ data: [], loading: false });
  const [createBom, setCreateBom] = useState(false);
  const [constLabel, setConstLabel] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    setConstLabel(getLabels("Master", "Product"));
    fetchProductCategory();
    fetchMarketGroup();
    fetchProductFamily();
    fetchParty();
    fetchUOM();
    fetchBoms();
  }, []);

  function fetchBoms() {
    setBomList({ ...bomList, data: [], loading: true });

    getBOM().then(
      (res) => {
        
        setBomList({ ...bomList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setBomList({ ...bomList, data: [], loading: false });
      }
    );
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM({pageNo:1,entriesPerPage:100}).then(
      (res) => {
        
        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchProductCategory() {
    setProdCategory({ ...prodCategory, data: [], loading: true });
    getProdCategory().then(
      (res) => {
        
        setProdCategory({
          ...prodCategory,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setProdCategory({ ...prodCategory, data: [], loading: false });
      }
    );
  }

  function fetchMarketGroup() {
    setMarketGroup({ ...marketGroup, data: [], loading: true });
    getMarket_Group().then(
      (res) => {
        
        setMarketGroup({
          ...marketGroup,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setMarketGroup({ ...marketGroup, data: [], loading: false });
      }
    );
  }

  function fetchProductFamily() {
    setProdFamily({ ...prodFamily, data: [], loading: true });
    getProduct_Family().then(
      (res) => {
        
        setProdFamily({
          ...prodFamily,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setProdFamily({ ...prodFamily, data: [], loading: false });
      }
    );
  }

  function fetchParty() {
    setPartyList({ ...partyList, data: [], loading: true });
    getCustomer({partyType: "Customer"}).then(
      (res) => {
        
        const result = res.data.data.filter((item) => item.name !== null);
        setPartyList({ ...partyList, data: result, loading: false });
      },
      (err) => {
        
        setPartyList({ ...partyList, data: [], loading: false });
      }
    );
  }

  // const onSubmit = (data) => {
  //   props.setCurrentStep(props.currentStep + 1);
  // };

  const onSubmit = (data) => {
    setBtnDisable(true);
    

    if (data !== "") {
      const reqPayload = {
        productType: props.product_type?.prodTypeId,
        productTypename: props.product_type?.productType,
        product_type_id: props.product_type?.uuid,
        category: data.category ? data.category[0]?.categoryname : "",
        category_id: data.category ? data.category[0]?.uuid : "",
        marketgrp: data.marketGroup[0]?.grpId,
        market_grp_id: data.marketGroup[0]?.uuid,
        prodfamily: data.productFamily[0]?.familyId,
        prod_family_id: data.productFamily[0]?.uuid,
        // subcategory: data.subCategory,
        productName: data.productName,
        openingstock: data.openingStock,
        hsn: data.hsn,
        // has_bom: createBom,
        // partyName: data.party[0]?.name,
        // drawingno: data.drawingNo,
        // OEMnumber: data.OEMpartNo,
        // material: data.material,
        // diameter: data.diameter,
        // cutlength_mn: data.cutLength,
        // finishLenght: data.finishLength,
        // cncweight: data.cncWeight,
        // htweight: data.htWeight,
        // finishweight: data.finishWeight,

        // partNo: data.partNo,
        machineCost: data.machineCost,
        salesPrice: data.salesPrice,
        can_be_sold: data.can_be_sold,
        can_be_purchased: data.can_be_purchased,
        can_be_expensed: data.can_be_expensed,
        beacon_id: data.beacon_id,
        qr_code_id: data.qr_code_id,
        selling_price: data.selling_price,
        purchase_price: data.purchase_price,
        unit_id: data.unit ? data.unit[0]?.uuid : "",
        remark: data.remark,
        party: [],
      };

      

      saveProduct(reqPayload).then(
        (res) => {
          
          setBtnDisable(false);

          if (res.status === 200 || res.status === 201) {
            toast.success("Manufactured goods product saved successfully !", {
              autoClose: 3000,
            });
            props.setProductDataLocal(res.data.product);
            localStorage.setItem("prod_res", JSON.stringify(res.data.product));

            props.setCurrentStep((prevStep) => prevStep + 1);
            if (createBom == true) {
              props.setBomStat(true);
            }
          }
        },
        (err) => {
          setBtnDisable(false);
          
          if (err.response.status === 400) {
            
            toast.error(err.response.data.non_field_errors[0], {
              autoClose: 2000,
            });
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <p className="pc_card_title roboto-bold">
            {constLabel?.lbl_product_summary
              ? constLabel?.lbl_product_summary
              : "Product Summary"}
          </p>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_product_category
                    ? constLabel?.lbl_product_category
                    : "Product Category"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <Controller
                  name="category"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="categoryname"
                      className="mt-2"
                      multiple={false}
                      options={prodCategory.data}
                      isLoading={prodCategory.loading}
                      placeholder="Select product category"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                    // isInvalid={errors.category ? true : false}
                    />
                  )}
                />

                {/* {errors.category && (
                  <span className="text-danger err-msg">
                    {errors.category.message}
                  </span>
                )} */}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_market_group
                    ? constLabel?.lbl_market_group
                    : "Market group"}
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name="marketGroup"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="groupname"
                      className="mt-2"
                      multiple={false}
                      options={marketGroup.data}
                      isLoading={marketGroup.loading}
                      placeholder="Select market group"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.marketGroup ? true : false}
                    />
                  )}
                />

                {errors.marketGroup && (
                  <span className="text-danger err-msg">
                    {errors.marketGroup.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_product_family
                    ? constLabel?.lbl_product_family
                    : "Product Family"}
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name="productFamily"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="familyname"
                      className="mt-2"
                      multiple={false}
                      options={prodFamily.data}
                      isLoading={prodFamily.loading}
                      placeholder="Select product family"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.productFamily ? true : false}
                    />
                  )}
                />

                {errors.productFamily && (
                  <span className="text-danger err-msg">
                    {errors.productFamily.message}
                  </span>
                )}
              </div>
            </div>

            {/* <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{"Sub Category"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter sub category name"
                  name="subCategory"
                  {...register("subCategory")}
                />
              </div>
            </div> */}

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_product_name
                    ? constLabel?.lbl_product_name
                    : "Product Name"}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.productName
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter product name"
                  name="productName"
                  {...register("productName")}
                />
                {errors.productName && (
                  <span className="text-danger err-msg">
                    {errors.productName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_opening_stock
                    ? constLabel?.lbl_opening_stock
                    : "Opening Stock"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  defaultValue={0}
                  placeholder="Enter opening stock"
                  name="openingStock"
                  {...register("openingStock")}
                />
                {errors.openingStock && (
                  <span className="text-danger err-msg">
                    {errors.openingStock.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_hsn ? constLabel?.lbl_hsn : "HSN"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter hsn"
                  name="hsn"
                  {...register("hsn")}
                />
                {errors.hsn && (
                  <span className="text-danger err-msg">
                    {errors.hsn.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_unit ? constLabel?.lbl_unit : "Unit"}
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name="unit"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="uom"
                      className="mt-2"
                      multiple={false}
                      options={uomList.data}
                      isLoading={uomList.loading}
                      placeholder="Select unit"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.unit ? true : false}
                    />
                  )}
                />

                {errors.unit && (
                  <span className="text-danger err-msg">
                    {errors.unit.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <label className="lbl-style roboto-bold invisible">
                {"Unit"}
                <span className="text-danger">*</span>
              </label>
              <div className="form-check mt-4">
                <input
                  className="form-check-input"
                  type="checkbox"
                  name="has_bom"
                  onChange={(e) => {
                    localStorage.setItem("bomStatus", e.target.checked);
                    setCreateBom(e.target.checked);
                  }}
                  checked={createBom}
                // {...register("has_bom")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.create_bom
                    ? constLabel?.create_bom
                    : "Create BOM"}
                </label>
              </div>
            </div>

            {/* <div className="col-md-3 mt-5">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"

                  name="has_bom"
                  onChange={(e) => setCreateBom(e.target.checked)}
                  checked={createBom}
                // {...register("has_bom")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  Create BOM
                </label>
              </div>
            </div> */}
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">
            {constLabel?.lbl_product_detail
              ? constLabel?.lbl_product_detail
              : "Product Detail"}
          </p>
          <div className="row col-12">
            {/* <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">{"Drawing No."}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter drawing no"
                  name="drawingNo"
                  {...register("drawingNo")}
                />
              </div>
            </div> */}

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_can_be_sold
                  ? constLabel?.lbl_can_be_sold
                  : "Can be Sold"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={false}
                  name="can_be_sold"
                  {...register("can_be_sold")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_can_be_sold
                    ? constLabel?.lbl_can_be_sold
                    : "Can be Sold"}
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_can_be_pruchase
                  ? constLabel?.lbl_can_be_pruchase
                  : "Can be Purchase"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={false}
                  name="can_be_purchased"
                  {...register("can_be_purchased")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_can_be_pruchase
                    ? constLabel?.lbl_can_be_pruchase
                    : "Can be Purchase"}
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {constLabel?.lbl_can_be_expense
                  ? constLabel?.lbl_can_be_expense
                  : "Can be Expense"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={false}
                  name="can_be_expensed"
                  {...register("can_be_expensed")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  {constLabel?.lbl_can_be_expense
                    ? constLabel?.lbl_can_be_expense
                    : "Can be Expense"}
                </label>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_beacon_id
                    ? constLabel?.lbl_beacon_id
                    : "Beacon Id"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter BeaconId"
                  name="beacon_id"
                  {...register("beacon_id")}
                />
                {errors.beacon_id && (
                  <span className="text-danger err-msg">
                    {errors.beacon_id.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_qr_code_id
                    ? constLabel?.lbl_qr_code_id
                    : "QR Code Id"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter QRCodeId"
                  name="qr_code_id"
                  {...register("qr_code_id")}
                />
                {errors.qr_code_id && (
                  <span className="text-danger err-msg">
                    {errors.qr_code_id.message}
                  </span>
                )}
              </div>
            </div>

            {/* <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {"OEM Part No."}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.OEMpartNo
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter OEM part no"
                  name="OEMpartNo"
                  {...register("OEMpartNo")}
                />
                {errors.OEMpartNo && (
                  <span className="text-danger err-msg">
                    {errors.OEMpartNo.message}
                  </span>
                )}
              </div>
            </div> */}

            {/* <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">{"Material Name"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter material"
                  name="material"
                  {...register("material")}
                />
              </div>
            </div> */}

            {/* <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">{"Ø in mm"}</label>
                <input
                  className="form-control mt-2"
                  type="number"
                  placeholder="Enter diameter"
                  name="diameter"
                  {...register("diameter")}
                />
              </div>
            </div> */}

            {/* <div className="col-md-3 mt-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {"Cut Length in mm."}
                </label>
                <input
                  className="form-control mt-2"
                  type="number"
                  placeholder="Enter cut length"
                  name="cutLength"
                  {...register("cutLength")}
                />
              </div>
            </div> */}

            {/* <div className="col-md-3 mt-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">{"Finish Length"}</label>
                <input
                  className="form-control mt-2"
                  type="number"
                  placeholder="Enter finish length"
                  name="finishLength"
                  {...register("finishLength")}
                />
              </div>
            </div> */}

            {/* <div className="col-md-3 mt-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">{"CNC Weight"}</label>
                <input
                  className="form-control mt-2"
                  type="number"
                  placeholder="Enter cnc weight"
                  name="cncWeight"
                  {...register("cncWeight")}
                />
              </div>
            </div> */}

            {/* <div className="col-md-3 mt-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">{"HT Weight"}</label>
                <input
                  className="form-control mt-2"
                  type="number"
                  placeholder="Enter ht weight"
                  name="htWeight"
                  {...register("htWeight")}
                />
              </div>
            </div> */}

            {/* <div className="col-md-3 mt-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">{"Finish Weight"}</label>
                <input
                  className="form-control mt-2"
                  type="number"
                  placeholder="Enter finish weight"
                  name="finishWeight"
                  {...register("finishWeight")}
                />
              </div>
            </div> */}

            {/* <div className="col-md-3">
            <div className="form-group">
              <label className="lbl-style roboto-bold">
                {"Customer Part No."}
              </label>
              <input
                className={
                  errors.partNo
                    ? "form-control mt-2 is-invalid"
                    : "form-control mt-2"
                }
                type="text"
                placeholder="Enter part no"
                name="partNo"
                {...register("partNo")}
              />
              {errors.partNo && (
                <span className="text-danger err-msg">
                  {errors.partNo.message}
                </span>
              )}
            </div>
          </div>

          <div className="col-md-3">
            <div className="form-group">
              <label className="lbl-style roboto-bold">{"Party Name"}
              <span className='text-danger'>*</span></label>
              <Controller
                name="party"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    className="mt-2"
                    multiple={false}
                    options={partyList.data}
                    isLoading={partyList.loading}
                    placeholder="Select party"
                    onChange={onChange}
                    selected={value}
                    onBlur={onBlur}
                    isInvalid={errors.party ? true : false}
                  />
                )}
              />

              {errors.party && (
                <span className="text-danger err-msg">
                  {errors.party.message}
                </span>
              )}
            </div>
          </div> */}
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">
            {constLabel?.lbl_product_price
              ? constLabel?.lbl_product_price
              : "Product Price"}
          </p>
          <div className="row col-12">
            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_cost ? constLabel?.lbl_cost : "Cost"}
                  <span>&#8377;</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.machineCost
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter cost"
                  name="machineCost"
                  defaultValue={0.0}
                  {...register("machineCost")}
                />
                {errors.machineCost && (
                  <span className="text-danger err-msg">
                    {errors.machineCost.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_sales_price ? constLabel?.lbl_sales_price : "Sales Price "}
                  <span>&#8377;</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.salesPrice
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter sales price"
                  name="salesPrice"
                  defaultValue={0.0}
                  {...register("salesPrice")}
                />
                {errors.salesPrice && (
                  <span className="text-danger err-msg">
                    {errors.salesPrice.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_selling_price ? constLabel?.lbl_selling_price : "Selling Price "}
                  <span>&#8377;</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.selling_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter selling price"
                  name="selling_price"
                  defaultValue={0.0}
                  {...register("selling_price")}
                />
                {errors.selling_price && (
                  <span className="text-danger err-msg">
                    {errors.selling_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {constLabel?.lbl_purchase_price ? constLabel?.lbl_purchase_price : "Purchase Price "}
                  <span>&#8377;</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.purchase_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter purchase price"
                  name="purchase_price"
                  defaultValue={0.0}
                  {...register("purchase_price")}
                />
                {errors.purchase_price && (
                  <span className="text-danger err-msg">
                    {errors.purchase_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-6 mt-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">{constLabel?.lbl_remark ? constLabel?.lbl_remark : "Remark"}</label>
                <textarea
                  className="form-control mt-2"
                  id="remark"
                  type="text"
                  placeholder="Enter remark"
                  name="remark"
                  {...register("remark")}
                ></textarea>
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <div className="d-flex justify-content-end">
            <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
              disabled={btnDisable}
            // onClick={() => props.setCurrentStep(props.currentStep + 1)}
            >
              {constLabel?.lbl_save ? constLabel?.lbl_save : "Save"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductDetailConfigure;

import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faSearch, faFilter, faBan, faPlus } from "@fortawesome/free-solid-svg-icons";
import { getModuleList } from "../../../api/index";
import { Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Edit2, Eye, Trash2, Image } from "react-feather";
import { getLabels } from '../../../common/Functions/CommonFunctions';

function Modules() {
    const [page, setPage] = useState(1);
    const [srNo, setSrNo] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [modList, setModList] = useState({ data: [], loading: false });

    const [constLabel, setConstLabel] = useState({});

    useEffect(() => {
      setConstLabel(getLabels('Module Master', 'Modules'));
    }, [])
    

    useEffect(() => {
        fetchModules()
    }, []);

    function fetchModules() {
        setModList({ ...modList, data: [], loading: true });

        getModuleList().then((res) => {
            
            setModList({ ...modList, data: res.data.data.response, loading: false });
        }, (err) => {
            setModList({ ...modList, data: [], loading: false });
            
        })
    }


    return (
        <React.Fragment>
            <div className='nc'>
                <div className='d-flex justify-content-between'>
                    <h1 className="bold">{constLabel?.lbl_modules ? constLabel.lbl_modules : "Modules"}</h1>

                </div>

                <div className='row mt-3'>
                    <div className='col-12'>
                        {/* <div className='entriesPerPage roboto-bold d-flex align-items-end'>
                            <p className='mb-0'>Show
                                <select className='mx-1' defaultValue={'10'} onChange={(e) => {
                                    setEntriesPerPage(e.target.value);
                                    fetchModules(page, e.target.value)
                                }}>
                                    <option value={'10'}>10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="100">100</option>
                                </select>Entries</p>

                        </div> */}
                        <div className='card border-0 mt-3 mb-4'>
                            <div className='card-body'>
                                {modList.loading ?
                                    <div className="text-center">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                    :
                                    modList.data?.length > 0 ?

                                        <div className="table-responsive" style={{ overflowX: 'scroll' }}>
                                            <table
                                                className="table mt-2 table-bordered table-sm"
                                            >
                                                <thead className="table-grey  roboto-bold">
                                                    <tr className="">
                                                        <th scope="col" width={"10%"}>{"SrNo"}</th>
                                                        <th scope="col">{constLabel?.lbl_module_name ? constLabel.lbl_module_name : "Module Name"}</th>
                                                        <th scope="col">{constLabel?.lbl_module_information ? constLabel.lbl_module_information : "Module Information"}</th>
                                                        {/* <th scope="col">
                                                            {"Action"}
                                                        </th> */}
                                                    </tr>
                                                </thead>
                                                <tbody className=" roboto">
                                                    {modList.data.map((val, i) => (
                                                        <tr key={i}>
                                                            <td style={{ verticalAlign: "middle" }}>{parseInt(i) + 1 + parseInt(srNo)}</td>
                                                            <td>{val.module_name}</td>
                                                            <td>{val.module_information !== null ? val.module_information : "NA"}</td>

                                                            {/* <td className="">
                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip id="tooltip">{"Update NC"}</Tooltip>}
                                                                >
                                                                    <div
                                                                        className="editIcon m-1 cursor_pointer"
                                                                    >
                                                                        <Edit2 color="#FFFFFF" size={18} />
                                                                    </div>
                                                                </OverlayTrigger>



                                                                <OverlayTrigger
                                                                    placement="top"
                                                                    overlay={<Tooltip id="tooltip">{"Delete NC"}</Tooltip>}
                                                                >
                                                                    <div
                                                                        className="deleteIcon m-1 cursor_pointer"
                                                                    >
                                                                        <Trash2 color="#FFFFFF" size={18} />
                                                                    </div>
                                                                </OverlayTrigger>


                                                            </td> */}
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                        :
                                        <p className='text-center text-danger mb-0'>No Module List Found</p>
                                }


                                {/* {ncList.loading ? null :

                                    ncList.data?.length > 0 ?
                                        (
                                            <div className="mt-4 d-flex justify-content-between">
                                                <p className='mb-0'>{`Showing ${parseInt(srNo) + 1} to ${ncList.data.length + (entriesPerPage * page) - entriesPerPage} of ${ncList.totalRecords} entries`}</p>
                                                <Pagination className="my-2">
                                                    <Pagination.Prev
                                                        disabled={page === 1 ? true : false}
                                                        onClick={() => {
                                                            fetchNCList(page - 1, entriesPerPage, parseInt(srNo) - parseInt(entriesPerPage))
                                                            setSrNo((prevC) => page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage));
                                                            setPage(page - 1)
                                                        }}
                                                    >
                                                        {"Prev"}
                                                    </Pagination.Prev>

                                                    <Pagination.Item active>{page}</Pagination.Item>

                                                    <Pagination.Next
                                                        disabled={
                                                            page === maxPage || maxPage === 0 || entriesPerPage > ncList.data.length
                                                                ? true
                                                                : false
                                                        }
                                                        onClick={() => {
                                                            // console.log("parseInt(srNo) + parseInt(entriesPerPage)", parseInt(srNo) + parseInt(entriesPerPage));
                                                            fetchNCList(page + 1, entriesPerPage, parseInt(srNo) + parseInt(entriesPerPage))
                                                            setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                                                            setPage(page + 1)
                                                        }}
                                                    >
                                                        {"Next"}
                                                    </Pagination.Next>
                                                </Pagination>
                                            </div>
                                        ) : null} */}
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </React.Fragment>
    )
}

export default Modules
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
// import XLSX from "xlsx";
import { updateOperationCat } from "../../../../api/index";

const schema = yup
  .object()
  .shape({
    oprationCategoryName: yup
      .string()
      .required("Operation category name is required !"),
  })
  .required();

function EditOperationCategory(props) {
  const [visible, setVisible] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const onSubmit = (data) => {
    

    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        operationCategoryId: props.data.operationCategoryId,
        // internalId: 1,
        oprationCategoryName: data.oprationCategoryName,
        // remark: "",
      };

      

      updateOperationCat(reqPayload).then(
        (res) => {
          
          if (res.status === 200 || res.status === 201) {
            toast.success("Operation category updated successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchOperationCat(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          
          if (err.response.status === 400) {
            
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_category
            ? props.constLabel.lbl_edit_category
            : "Edit Category"
        }
        className="menu_icon_grey cursor-pointer me-2"
        size={18}
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true);
        }}
      />
      {/* Add Item Modal*/}
      <Modal show={visible} onHide={() => {setVisible(false); reset();}} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{props.constLabel?.lbl_update_operation_category ? props.constLabel?.lbl_update_operation_category :  "Update Operation Category"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_category_name ? props.constLabel?.lbl_category_name : "Category Name" }
                <span className="text-danger">*</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={
                    errors.oprationCategoryName
                      ? "form-control  is-invalid"
                      : "form-control "
                  }
                  id="oprationCategoryName"
                  name="oprationCategoryName"
                  placeholder="Enter operation category name"
                  defaultValue={
                    props.data !== undefined
                      ? props.data.oprationCategoryName
                      : ""
                  }
                  {...register("oprationCategoryName")}
                />
                <span className="text-danger err-msg">
                  {errors.oprationCategoryName?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props?.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}
            {/* <FontAwesomeIcon
              style={{ color: "#000" }}
              icon={faPlay}
              size="sm"
            /> */}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditOperationCategory;

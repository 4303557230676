import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getProdCategory,
  getProductType,
  getCustomer,
  getMarket_Group,
  getProduct_Family,
  getUOM,
  updateProduct,
  getBOM,
  deleteBOM,
} from "../../../../api";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap";
import { Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";
import { getLabels } from "../../../../common/Functions/CommonFunctions";

const schema = yup
  .object()
  .shape({
    // category: yup
    //   .array()
    //   .required("Product category is required !")
    //   .min(1, "Select atleast one type !"),
    marketGroup: yup
      .array()
      .required("Market group is required !")
      .min(1, "Please select market group !"),
    productFamily: yup
      .array()
      .required("Product family is required !")
      .min(1, "Please select product family!"),
    // OEMpartNo: yup
    //   .string()
    //   .required("OEM part no is required !"),
    openingStock: yup
      .string()
      .matches(/^[0-9]+$/, 'Enter valid opening stock')
      .nullable(true),
    productName: yup.string().required("Product name is required !"),
    hsn: yup
      .string()
      .matches(/^[a-zA-Z0-9]*$/, "Enter valid hsn")
      .nullable(true),
    unit: yup
      .array()
      .required("Unit is required !")
      .min(1, "Please select unit !"),
    machineCost: yup
      .string()
      .required("Cost is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid cost"),
    salesPrice: yup
      .string()
      .required("Sales price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid sales price"),
    selling_price: yup
      .string()
      .required("Selling price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid selling price"),
    purchase_price: yup
      .string()
      .required("Purchase price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid purchase price"),
    can_be_sold: yup.string().required("Required"),
    can_be_purchased: yup.string().required("Required"),
    can_be_expensed: yup.string().required("Required"),
    // has_bom: yup
    //   .string()
    //   .required("Required"),
  })
  .required();

function EditProductDetailConfigure(props) {
  let navigate = useNavigate();
  let location = useLocation();

  const [prodCategory, setProdCategory] = useState({
    data: [],
    loading: false,
  });
  const [marketGroup, setMarketGroup] = useState({ data: [], loading: false });
  const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
  const [partyList, setPartyList] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [bomList, setBomList] = useState({ data: [], loading: false });
  const [particularBomList, setParticularBomList] = useState({
    data: [],
    loading: false,
  });

  const [constLabel, setConstLabel] = useState({});

  const [modal, setModal] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    
    setConstLabel(getLabels('Master', 'Product'));
    fetchProductCategory();
    fetchMarketGroup();
    fetchProductFamily();
    fetchParty();
    fetchUOM();
    fetchBoms();
  }, []);

  function fetchBoms() {
    setBomList({ ...bomList, data: [], loading: true });

    getBOM().then(
      (res) => {
        
        setBomList({ ...bomList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setBomList({ ...bomList, data: [], loading: false });
      }
    );
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM({pageNo:1,entriesPerPage:100}).then(
      (res) => {
        
        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchProductCategory() {
    setProdCategory({ ...prodCategory, data: [], loading: true });
    getProdCategory().then(
      (res) => {
        // 
        setProdCategory({
          ...prodCategory,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setProdCategory({ ...prodCategory, data: [], loading: false });
      }
    );
  }

  function fetchMarketGroup() {
    setMarketGroup({ ...marketGroup, data: [], loading: true });
    getMarket_Group().then(
      (res) => {
        
        setMarketGroup({
          ...marketGroup,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setMarketGroup({ ...marketGroup, data: [], loading: false });
      }
    );
  }

  function fetchProductFamily() {
    setProdFamily({ ...prodFamily, data: [], loading: true });
    getProduct_Family().then(
      (res) => {
        
        setProdFamily({
          ...prodFamily,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setProdFamily({ ...prodFamily, data: [], loading: false });
      }
    );
  }

  function fetchParty() {
    setPartyList({ ...partyList, data: [], loading: true });
    getCustomer({partyType: "Customer"}).then(
      (res) => {
        // 
        const result = res.data.data.filter((item) => item.name !== null);
        setPartyList({ ...partyList, data: result, loading: false });
      },
      (err) => {
        
        setPartyList({ ...partyList, data: [], loading: false });
      }
    );
  }

  // const onSubmit = (data) => {
  //   props.setCurrentStep(props.currentStep + 1);
  // };

  const onSubmit = (data) => {
    

    if (data !== "") {
      const reqPayload = {
        uuid: props.data?.uuid,
        productType: props.data.product_type_id?.prodTypeId,
        productTypename: props.data.product_type_id?.productType,
        product_type_id: props.data.product_type_id?.uuid,
        category: (data.category&&data.category.length>0) ? data.category[0]?.categoryname : "",
        category_id:(data.category&&data.category.length>0)? data.category[0]?.uuid : "",
        marketgrp: data.marketGroup[0]?.grpId,
        market_grp_id: data.marketGroup[0]?.uuid,
        prodfamily: data.productFamily[0]?.familyId,
        prod_family_id: data.productFamily[0]?.uuid,
        // subcategory: data.subCategory,
        productName: data.productName,
        openingstock: data.openingStock,
        hsn: data.hsn,
        has_bom: true,
        // partyName: data.party[0]?.name,
        // drawingno: data.drawingNo,
        // OEMnumber: data.OEMpartNo,
        // material: data.material,
        // diameter: data.diameter,
        // cutlength_mn: data.cutLength,
        // finishLenght: data.finishLength,
        // cncweight: data.cncWeight,
        // htweight: data.htWeight,
        // finishweight: data.finishWeight,

        // partNo: data.partNo,
        machineCost: data.machineCost,
        salesPrice: data.salesPrice,
        can_be_sold: data.can_be_sold,
        can_be_purchased: data.can_be_purchased,
        can_be_expensed: data.can_be_expensed,
        beacon_id: data.beacon_id,
        qr_code_id: data.qr_code_id,
        selling_price: data.selling_price,
        purchase_price: data.purchase_price,
        unit_id: data.unit ? data.unit[0]?.uuid : "",
        remark: data.remark,
        party: [],
      };

      

      updateProduct(reqPayload)
        .then(
          (res) => {
            
            if (res.status === 200 || res.status === 201) {
              toast.success("Manufactured goods product updated successfully !", {
                autoClose: 3000,
              });
              props.setCurrentStep((prevStep) => prevStep + 1);
            }
          },
          (err) => {
            
            if (err.response.status === 400) {
              
              toast.error(err.response, {
                autoClose: 2000,
              });
              reset();
            }
          }
        )
        .catch((error) => {
          
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  function delete_bom(val) {
    
    SweetAlert.fire({
      title: "Are you sure to delete \n" + val.bom_type + " ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteBOM({ uuid: val.uuid }).then(
          (res) => {
            
            toast.success("Bill of material deleted successfully !", {
              autoClose: 2000,
            });
            fetchParticularBoms();
          },
          (err) => {
            
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function fetchParticularBoms() {
    setParticularBomList({ ...particularBomList, data: [], loading: true });
    getBOM({ product_id: location.state.uuid }).then(
      (res) => {
        
        setParticularBomList({
          ...particularBomList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setParticularBomList({
          ...particularBomList,
          data: [],
          loading: false,
        });
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <p className="pc_card_title roboto-bold"> {constLabel?.lbl_product_summary
              ? constLabel?.lbl_product_summary
              : "Product Summary"}</p>

          <div className="row">
            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                {constLabel?.lbl_product_category
                    ? constLabel?.lbl_product_category
                    : "Product Category"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <Controller
                  name="category"
                  control={control}
                  defaultValue={props.data?.category !== null && props.data?.category_id !== null ? [
                    { categoryname: props.data?.category, uuid: props.data?.category_id?.uuid },
                  ] : []}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      defaultSelected={props.data?.category !== null && props.data?.category_id !== null ? [
                        { categoryname: props.data?.category, uuid: props.data?.category_id?.uuid },
                      ] : []}
                      labelKey="categoryname"
                      className="mt-2"
                      multiple={false}
                      options={prodCategory.data}
                      isLoading={prodCategory.loading}
                      placeholder="Select product category"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                    // isInvalid={errors.category ? true : false}
                    />
                  )}
                />

                {/* {errors.category && (
              <span className="text-danger err-msg">
                {errors.category.message}
              </span>
            )} */}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                {constLabel?.lbl_market_group
                    ? constLabel?.lbl_market_group
                    : "Market group"}
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name="marketGroup"
                  control={control}
                  defaultValue={props.data.market_grp_id !== null ? [props.data.market_grp_id] : []}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      defaultSelected={props.data.market_grp_id !== null ? [props.data.market_grp_id] : []}
                      labelKey="groupname"
                      className="mt-2"
                      multiple={false}
                      options={marketGroup.data}
                      isLoading={marketGroup.loading}
                      placeholder="Select market group"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.marketGroup ? true : false}
                    />
                  )}
                />

                {errors.marketGroup && (
                  <span className="text-danger err-msg">
                    {errors.marketGroup.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                {constLabel?.lbl_product_family
                    ? constLabel?.lbl_product_family
                    : "Product Family"}
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name="productFamily"
                  control={control}
                  defaultValue={props.data.prod_family_id !== null ? [props.data.prod_family_id] : []}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      defaultSelected={props.data.prod_family_id !== null ? [props.data.prod_family_id] : []}
                      labelKey="familyname"
                      className="mt-2"
                      multiple={false}
                      options={prodFamily.data}
                      isLoading={prodFamily.loading}
                      placeholder="Select product family"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.productFamily ? true : false}
                    />
                  )}
                />

                {errors.productFamily && (
                  <span className="text-danger err-msg">
                    {errors.productFamily.message}
                  </span>
                )}
              </div>
            </div>

            {/* <div className="col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label className="lbl-style roboto-bold">{"Sub Category"}</label>
            <input
              className="form-control mt-2"
              type="text"
              defaultValue={props.data && props.data?.subcategory}
              name="subCategory"
              {...register("subCategory")}
            />
          </div>
        </div> */}

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                {constLabel?.lbl_product_name
                    ? constLabel?.lbl_product_name
                    : "Product Name"}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.productName
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter product name"
                  defaultValue={props.data?.productName !== null ? props.data?.productName : ""}
                  name="productName"
                  {...register("productName")}
                />
                {errors.productName && (
                  <span className="text-danger err-msg">
                    {errors.productName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                {constLabel?.lbl_opening_stock
                    ? constLabel?.lbl_opening_stock
                    : "Opening Stock"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter opening stock"
                  defaultValue={props.data?.openingstock !== null ? props.data?.openingstock : ""}
                  name="openingStock"
                  {...register("openingStock")}
                />
                {errors.openingStock && (
                  <span className="text-danger err-msg">
                    {errors.openingStock.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{constLabel?.lbl_hsn ? constLabel?.lbl_hsn : "HSN"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter hsn"
                  defaultValue={props.data?.hsn !== null ? props.data?.hsn : ""}
                  name="hsn"
                  {...register("hsn")}
                />
                {errors.hsn && (
                  <span className="text-danger err-msg">
                    {errors.hsn.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                {constLabel?.lbl_unit ? constLabel?.lbl_unit : "Unit"}
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name="unit"
                  control={control}
                  defaultValue={props.data?.unit_id !== null ? [props.data?.unit_id] : []}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      defaultSelected={props.data?.unit_id !== null ? [props.data?.unit_id] : []}
                      labelKey="uom"
                      className="mt-2"
                      multiple={false}
                      options={uomList.data}
                      isLoading={uomList.loading}
                      placeholder="Select unit"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.uomList ? true : false}
                    />
                  )}
                />

                {errors.uomList && (
                  <span className="text-danger err-msg">
                    {errors.uomList.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">{"Unit"}</label>
              <div className="d-flex align-items-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    defaultChecked={props.data.has_bom !== null ? props.data.has_bom : false}
                    disabled
                  // name="can_be_sold"
                  // {...register("can_be_sold")}
                  />
                  <label className="form-check-label lbl-style roboto-bold">has bom</label>
                </div>
                <div className="ml-2">
                  <button
                    onClick={() => {
                      setModal(true);
                      
                      
                      fetchParticularBoms();
                    }}
                    className="btn btn-grey d-block px-3 mt-2"
                  >
                    {constLabel?.lbl_view_bill_of_material
              ? constLabel?.lbl_view_bill_of_material
              : "View Bill of Material"}
                  </button>
                </div>
              </div>
            </div>
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">{constLabel?.lbl_product_detail
              ? constLabel?.lbl_product_detail
              : "Product Detail"}</p>
          <div className="row col-12">
            {/* <div className="col-md-3">
          <div className="form-group">
            <label className="lbl-style roboto-bold">{"Drawing No."}</label>
            <input
              className="form-control mt-2"
              type="text"
              defaultValue={props.data && props.data?.drawingno}
              name="drawingNo"
              {...register("drawingNo")}
            />
          </div>
        </div> */}

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
              {constLabel?.lbl_can_be_sold
                    ? constLabel?.lbl_can_be_sold
                    : "Can be Sold"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={props.data.can_be_sold !== null ? props.data.can_be_sold : false}
                  name="can_be_sold"
                  {...register("can_be_sold")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                {constLabel?.lbl_can_be_sold
                    ? constLabel?.lbl_can_be_sold
                    : "Can be Sold"}
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
              {constLabel?.lbl_can_be_pruchase
                    ? constLabel?.lbl_can_be_pruchase
                    : "Can be Purchase"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={props.data.can_be_purchased !== null ? props.data.can_be_purchased : ""}
                  name="can_be_purchased"
                  {...register("can_be_purchased")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                {constLabel?.lbl_can_be_pruchase
                    ? constLabel?.lbl_can_be_pruchase
                    : "Can be Purchase"}
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
              {constLabel?.lbl_can_be_expense
                    ? constLabel?.lbl_can_be_expense
                    : "Can be Expense"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={props.data.can_be_expensed !== null ? props.data.can_be_expensed : ""}
                  name="can_be_expensed"
                  {...register("can_be_expensed")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                {constLabel?.lbl_can_be_expense
                    ? constLabel?.lbl_can_be_expense
                    : "Can be Expense"}
                </label>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{constLabel?.lbl_beacon_id
                    ? constLabel?.lbl_beacon_id
                    : "Beacon Id"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  name="beacon_id"
                  placeholder="Enter BeaconId"
                  defaultValue={props.data.beacon_id !== null ? props.data.beacon_id : ""}
                  {...register("beacon_id")}
                />
                {errors.beacon_id && (
                  <span className="text-danger err-msg">
                    {errors.beacon_id.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{constLabel?.lbl_qr_code_id ? constLabel?.lbl_qr_code_id : "QR Code Id"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter QRCodeId"
                  name="qr_code_id"
                  defaultValue={props.data.qr_code_id !== null ? props.data.qr_code_id : ""}
                  {...register("qr_code_id")}
                />
                {errors.qr_code_id && (
                  <span className="text-danger err-msg">
                    {errors.qr_code_id.message}
                  </span>
                )}
              </div>
            </div>


          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">Product Price</p>
          <div className="row col-12">
            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                {constLabel?.lbl_cost ? constLabel?.lbl_cost : "Cost"}<span>&#8377;</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.machineCost
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter cost"
                  defaultValue={
                    props.data?.machineCost !== null ? props.data.machineCost : 0.0
                  }
                  name="machineCost"
                  {...register("machineCost")}
                />
                {errors.machineCost && (
                  <span className="text-danger err-msg">
                    {errors.machineCost.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                {constLabel?.lbl_sales_price ? constLabel?.lbl_sales_price : "Sales Price "}<span>&#8377;</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.salesPrice
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter sales price"
                  defaultValue={
                    props.data?.salesPrice !== null ? props.data.salesPrice : 0.0
                  }
                  name="salesPrice"
                  {...register("salesPrice")}
                />
                {errors.salesPrice && (
                  <span className="text-danger err-msg">
                    {errors.salesPrice.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                {constLabel?.lbl_selling_price ? constLabel?.lbl_selling_price : "Selling Price "}<span>&#8377;</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.selling_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter selling price"
                  name="selling_price"
                  defaultValue={
                    props.data?.selling_price !== null ? props.data.selling_price : 0.0
                  }
                  {...register("selling_price")}
                />
                {errors.selling_price && (
                  <span className="text-danger err-msg">
                    {errors.selling_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                {constLabel?.lbl_purchase_price ? constLabel?.lbl_purchase_price : "Purchase Price "}<span>&#8377;</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.purchase_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter purchase price"
                  name="purchase_price"
                  defaultValue={
                    props.data?.purchase_price !== null ? props.data.purchase_price : 0.0
                  }
                  {...register("purchase_price")}
                />
                {errors.purchase_price && (
                  <span className="text-danger err-msg">
                    {errors.purchase_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-12 col-md-6 mt-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">{constLabel?.lbl_remark ? constLabel?.lbl_remark : "Remark"}</label>
                <textarea
                  className="form-control mt-2"
                  type="text"
                  defaultValue={props.data?.remark !== null ? props.data?.remark : ""}
                  name="remark"
                  {...register("remark")}
                ></textarea>
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <div className="d-flex justify-content-end">
            <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
            >
              {constLabel?.lbl_update ? constLabel?.lbl_update : "Update"}
            </button>
            <button
              className="btn btn-grey px-4"
              onClick={() => props.setCurrentStep(props.currentStep + 1)}
            >
              {constLabel?.lbl_next ? constLabel?.lbl_next :"Next"}
              <FontAwesomeIcon
                style={{
                  color: "#fff",
                }}
                icon={faArrowRight}
                size="sm"
                className="ms-2"
              />
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={modal}
        onHide={() => setModal(false)}
        size="xl"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Bill Of Material List"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion>
            <div className="row prod_bom_accor">
              {particularBomList.loading ? (
                <div className="loader-box justify-content-center">
                  <Spinner />
                </div>
              ) : particularBomList.data.length > 0 ? (
                particularBomList.data.map((val, ind) => (
                  <div className="col-12 col-md-6 mb-3" key={ind}>
                    <Accordion.Item eventKey={ind}>
                      <Accordion.Header className="position-relative">
                        <div className="d-flex justify-content-between align-items-center">
                          {val.bom_type}
                        </div>


                        <OverlayTrigger
                          placement="top"
                          overlay={<Tooltip id="tooltip">{constLabel?.lbl_delete_bom ? constLabel?.lbl_delete_bom : "Delete BOM"}</Tooltip>}
                        >
                          <FontAwesomeIcon
                            onClick={() => delete_bom(val)}
                            className="icon ms-3"
                            style={{
                              color: "#fff",
                              position: "absolute",
                              top: "17px",
                              right: "60px",
                            }}
                            icon={faTrashAlt}
                          // size={"lg"}
                          />
                        </OverlayTrigger>

                      </Accordion.Header>
                      <Accordion.Body>
                        <div className="row">
                          <div className="col-12 col-md-8">
                            <p className="mb-1">
                              <span className="roboto-bold ">{constLabel?.lbl_remark ? constLabel?.lbl_remark : "Remark"} : </span>
                              {`${val.remarks}`}
                            </p>
                          </div>
                          <div className="col-12 col-md-4">
                            <p>
                              <span className="roboto-bold">{constLabel?.lbl_quantity ? constLabel?.lbl_quantity : "Quantity"} : </span>
                              {`${val.qty}`}
                            </p>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-md-4 roboto-bold">
                            {constLabel?.lbl_product_family ? constLabel?.lbl_product_family : "Product Family"}
                          </div>
                          <div className="col-12 col-md-4 roboto-bold">
                            {constLabel?.lbl_product_name ? constLabel?.lbl_product_name : "Product Name"}
                          </div>
                          <div className="col-12 col-md-2 roboto-bold">
                            {constLabel?.lbl_quantity ? constLabel?.lbl_quantity : "Quantity"}
                          </div>
                          <div className="col-12 col-md-2 roboto-bold">{constLabel?.lbl_unit ? constLabel?.lbl_unit : "Unit"}</div>
                          {val.bom_line_item.map((item, ind) => (
                            <>
                              <div className="col-12 col-md-4">
                                {item.product_family_id !== null ?
                                  item.product_family_id?.familyname : "NA"}
                              </div>
                              <div className="col-12 col-md-4">
                                {item.productname !== null ? item.productname : "NA"}
                              </div>
                              <div className="col-12 col-md-2">{item.qty}</div>
                              <div className="col-12 col-md-2">
                                {item.unitname}
                              </div>
                            </>
                          ))}
                        </div>
                      </Accordion.Body>
                    </Accordion.Item>
                  </div>
                ))
              ) : (
                <div>
                  <div
                    className="alert alert-danger text-center p-4"
                    role="alert"
                  >
                    {"No Bill Of Material Found"}
                  </div>
                </div>
              )}
            </div>
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-grey px-4" onClick={() => setModal(false)}>
            {constLabel?.lbl_close ? constLabel?.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditProductDetailConfigure;

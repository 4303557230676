import React, { useState, useEffect} from "react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import { getBILotProducedQty, getBILotWiseProdData, getBIProductWiseProdData } from "../../api/index";
import { convertDateYYMMDD, getFinancialYearStartDate, getLabels } from "../../common/Functions/CommonFunctions";
import { Tooltip } from "react-tooltip";
import List from "../../assets/images/icons/project_menu/checklist.png"


const schema = yup
  .object()
  .shape({
    // fromDate: yup.date().typeError("Please select from date !").required("Please select from date !"),
    fromDate: yup
      .date()
      .typeError("Please select From date!")
      .required("Please select a From date!"),
    toDate: yup
      .date()
      .typeError("Please select To date!")
      .required("Please select a To date!"),
  })
  .required();

function ProductionDashboard() {
  let navigate = useNavigate();
  const app_env = process.env.REACT_APP_ENV == "development" ? "dev" : "prod";
  const { control, formState, setError } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  const percentages = [
    { value: 25, color: "#EEAE2D" },
    { value: 75, color: "#344454" },
    { value: 50, color: "#FF134E" },
  ];

  const totalPercentage = percentages.reduce(
    (total, item) => total + item.value,
    0
  );
  const [startDate, setStartDate] = useState(getFinancialYearStartDate(new Date()));
  const [endDate, setEndDate] = useState(new Date());

  const [lotList, setLotList] = useState({data: [], loading: true });


  const [prodWithOprStack, setProdWithOprStack] = useState({data: [], loading: true });
  const [lotWiseProduct, setLotWiseProduct] = useState({data: [], loading: true });
  const [lotProducedQty, setLotProducedQty] = useState({data: [], loading: true });

  const loginData = useSelector((state) =>  state.auth?.loginData?.data?.response);
  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });
  const abortController = new AbortController();

  const [constLabel, setConstLabel] = useState({});

  let startAngle = 330;
  const colors = [
    "#4E7DAE",
    "#FF134E",
    "#344454",
    "#EEAE2D",
    "#ABBCCC",
    "#8D8D8D",
    "#2ECC71",
    "#8E44AD",
    "#D35400",
    "#85C1E9",
    "#FF5733",
    "#8C94D6",
    "#D68FD6",
    "#6F1E51",
    "#00FF7F",
    "#FFD700",
    "#F08080",
    "#20B2AA",
    "#DA70D6",
    "#C71585",
    "#6495ED",
    "#7FFF00",
    "#B0E0E6",
    "#9370DB",
    "#F0E68C",
    "#4682B4",
    "#CD5C5C",
    "#32CD32",
    "#DB7093",
    "#FFA500",
    "#800080",
    "#FF4500",
    "#00CED1",
    "#808000",
    "#8B008B",
    "#FF6347",
    "#008080",
    "#556B2F",
    "#9932CC",
    "#FF8C00",
    "#008B8B",
    "#FFDAB9",
    "#000080",
    "#8B0000",
    "#20B2AA",
    "#FF1493",
    "#00FF00",
    "#FA8072",
    "#800000",
    "#BA55D3",
    "#F5FFFA",
    "#FF69B4",
    "#00BFFF",
    "#FFE4C4",
    "#8A2BE2",
    "#E6E6FA",
    "#FFB6C1",
    "#66CDAA",
    "#00FA9A",
    "#191970",
  ];

  const [cardShow, setCardShow] = useState([
    { name: "Product with Operation", flag: true },
    { name: "Lot wise Product", flag: true },
    { name: "Lot wise Produced Quantity", flag: true },
  ]);

  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 1);
    return endDate;
  }

  useEffect(() => {
    setConstLabel(getLabels("Production", "Production Dashboard"));
    // setEmpListTop({ ...empListTop, loading: true, data: [] });
    // setPrdList({ ...prdList, loading: true, data: [] });
    // setOprList({ ...oprList, loading: true, data: [] });

    // fetchEmpTop();
    // fetchPrd();
    // fetchOpr();
    let isUserAdmin;
    if (
      loginData.role_name.trim() == "admin" ||
      loginData.role_name.trim() == "client_admin"
    ) {
      isUserAdmin = true;
    } else {
      isUserAdmin = false;
    }
    // console.log("isUserAdmin", isUserAdmin);
    if (isUserAdmin) {
      setPrivilage({
        read: true,
        write: true,
        view: true,
        delete: true,
      });
    } else {
      for (let i = 0; i < loginData.module_list.length; i++) {
        if (loginData.module_list[i].module == "Production Dashboard") {
          setPrivilage(loginData.module_list[i]);
        } else {
          for (let i = 0; i < loginData.module_list.length; i++) {
            if (loginData.module_list[i].module == "Production Dashboard") {
              setPrivilage(loginData.module_list[i]);
            } else {
              if (loginData.module_list[i].screens !== undefined) {
                for (let j = 0; j < loginData.module_list[i].screens.length; j++) {
                  if (loginData.module_list[i].screens[j].screen_name == "Production Dashboard") {
                    setPrivilage(loginData.module_list[i]);
                  }
                }
              }
            }
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    // console.log("start date", startDate);
    // console.log("end date", endDate);
    let debounceTimeout;
    if (
      startDate !== undefined &&
      startDate !== null &&
      endDate !== undefined &&
      endDate !== null
    ) {
      // console.log("sdfsdlfjsdlfjsdklfjlsdkfjsdkfj");
      debounceTimeout = setTimeout(() => {

        setProdWithOprStack({ ...prodWithOprStack, loading: true, data: [] });
        setLotWiseProduct({ ...lotWiseProduct, loading: true, data: [] });
        setLotProducedQty({...lotProducedQty, data: [], loading: true });

        fetchProdWithOprStack();
        fetchLotWiseProduct(0);
        fetchLotProducedQty();

      }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)
    }

    return () => {
      abortController.abort();
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [startDate, endDate]);


  function fetchProdWithOprStack() {
    getBIProductWiseProdData(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        env: app_env
        // type: "operation"
      }, { signal: abortController.signal }).then(
        (res) => {
          // console.log("product with operation stack", res);

          const product_array = res?.data?.data?.map(
            (entry) => `${entry.productName}`
          );
          
          let prod_operation_name_arr = [];
          res?.data?.data?.forEach((item, ind) => {
            let arr = [];
            arr = item.operation_name.map((el) => {
              return el.operation_name;
            });

            prod_operation_name_arr = [
              ...prod_operation_name_arr,
              ...arr,
            ];
          });
          let uniqueArray_prod = [...new Set(prod_operation_name_arr)];
          // console.log("uniqueArray_prod", uniqueArray_prod);

          let result = [];
          for (let i = 0; i < uniqueArray_prod.length; i++) {
            let obj = {};
            obj.name = uniqueArray_prod[i];
            let counts = [];
            res?.data?.data?.forEach((ele) => {
              let check = ele.operation_name.filter(
                (obj) => obj.operation_name === uniqueArray_prod[i]
              );
              if (check.length > 0) {
                counts.push(check[0].count);
              } else {
                counts.push(0);
              }
            });
            obj.data = counts;
            result.push(obj);
          }
          // console.log("result", result);
          const state2 = {
            series: result,
            options: {
              chart: {
                type: "bar",
                height: 350,
                stacked: true,
                toolbar: {
                  show: false,
                },
              },
              responsive: [
                {
                  breakpoint: 480,
                  options: {
                    legend: {
                      position: "bottom",
                      offsetX: -10,
                      offsetY: 0,
                      show: false,
                    },
                  },
                },
              ],
              plotOptions: {
                bar: {
                  horizontal: false,
                  dataLabels: {
                    show: false,
                    total: {
                      enabled: true,
                    },
                  },
                },
              },
              colors: colors,
              xaxis: {
                // categories: [1,2,3,4,5,6,7,8,9,10],
                categories: product_array,
                labels: {
                  show: true, // Set show to false to hide series names on the x-axis
                  rotate: -35,
                  style: {
                    fontSize: '10px',
                },
                },
                
              },
              legend: {
                position: "bottom",
                markers: {
                  width: 4,
                  height: 12,
                },
                fontSize: "12px",
                show: false,
              },
              fill: {
                opacity: 1,
              },
              tooltip: {
                x: {
                  formatter: function (value, { seriesIndex, dataPointIndex }) {
                    return `${product_array[dataPointIndex]}`;
                  },
                },
              },
            },
          };

          // console.log("state2", state2);

          setProdWithOprStack({ ...prodWithOprStack, loading: false, data: state2 });
        },
        (err) => {
          
          setProdWithOprStack({ ...prodWithOprStack, loading: false, data: [] });
        }
      );
  }

  function handleChangeLot(val, index){
    // 
    // console.log("index", index);
    setLotWiseProduct({ ...lotWiseProduct, loading: true, data: [] });
    fetchLotWiseProduct(index);
  }

  function fetchLotWiseProduct(index="") {
    getBILotWiseProdData(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        env: app_env
        // type: "operation"
      }, { signal: abortController.signal }).then(
        (res) => {
          // console.log("lot wise product data", res);
          setLotList({data: res?.data?.result});

          const lot_array = res?.data?.result?.map(
            (entry) => `${entry.lotNo}`
          );
          

          const result = [
            { name: 'Produced Quantity', data: [] },
            { name: 'NC Count', data: [] }
          ];
          const operationArr = [];
          let nameOfProduct;
          res?.data?.result[index]?.data?.forEach(item => {
            operationArr.push(item.operation);
            nameOfProduct = item.product_name;
            result[0].data.push(parseInt(item.produced_qty));
            result[1].data.push(parseInt(item.nc_count));
          });

          // console.log("lotList", lotList);
           
          // result= res.data[0].data.map((el)=>{
          //    let obj= {}
          //    obj.name = el.operation;
          //    obj.group = el.product_name;
          //    obj.data = [el.produced_qty];
          //    return obj;
          // })
              
          console.log("result", result);

          const state2 = {
            series: result,
            options: {
              chart: {
                type: 'bar',
                height: 350,
                toolbar: {
                  show: false,
                },
              },
              plotOptions: {
                bar: {
                  horizontal: false,
                  columnWidth: '90%',
                  endingShape: 'rounded'
                },
              },
              colors: colors,
              dataLabels: {
                enabled: true,
                style: {
                  fontSize: '7px',
                },
              },
              stroke: {
                show: true,
                width: 1,
                colors: ['transparent']
              },
              xaxis: {
                categories: [0],
                title: {
                  text: `Lot No - ${lot_array[index]} & Product Name - ${nameOfProduct}`
                }
              },
              fill: {
                opacity: 1
              },
              tooltip: {
                x: {
                  formatter: function (value, { seriesIndex, dataPointIndex }) {
                    return `${operationArr[dataPointIndex]}`;
                  },
                },
              },
            },
          };

          // console.log("state2", state2);

          setLotWiseProduct({ ...lotWiseProduct, loading: false, data: state2 });
        },
        (err) => {
          
          setLotWiseProduct({ ...lotWiseProduct, loading: false, data: [] });
        }
      );
  }

  function fetchLotProducedQty() {
    getBILotProducedQty(
      {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        env: app_env
      }, { signal: abortController.signal },
    ).then(
      (res) => {
        console.log("Lot Produced qty res", res);
        const result = [
          // { name: 'Count', data: [] },
          { name: 'Produced Quantity', data: [] },
          { name: 'NC Count', data: [] }
        ];
        const lot_array = [];
      
        res?.data?.data?.forEach(item => {
          lot_array.push(item.lotNo);
          // result[0].data.push(parseInt(item.count));
          result[0].data.push(parseInt(item.produced_qty));
          result[1].data.push(parseInt(item.nc_count));
        });
        // console.log("result2", result2);

        const state2 = {
          series: result,
          options: {
            chart: {
              type: 'bar',
              height: 350,
              toolbar: {
                show: false,
              },
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '70%',
                endingShape: 'rounded'
              },
            },
            xaxis: {
              title: {
                text: 'Lot No'
              }
            },
            colors: colors,
            dataLabels: {
              enabled: true,
              style: {
                fontSize: '7px',
              },
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: lot_array,
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              x: {
                formatter: function (value, { seriesIndex, dataPointIndex }) {
                  return `Lot No - ${lot_array[dataPointIndex]}`;
                },
              },
            },
          },
        };

        // console.log("Lot produced quantity", state2);

        setLotProducedQty({ ...lotProducedQty, loading: false, data: state2 });
      },
      (err) => {
        
        setLotProducedQty({ ...lotProducedQty, loading: false, data: [] });
      }
    );
  }

  function handleClick(ind, checkVal) {
    // 

    let trueCount = 0;
    cardShow.map((val) => {
      if (val.flag == true) {
        trueCount += 1;
      }
    });

    // console.log("trueCount", trueCount);
    // console.log("checkVal", checkVal);

    if (checkVal == true) {
      setCardShow((prevCardShow) => {
        return prevCardShow.map((val, vali) => {
          if (vali == ind) {
            val.flag = false;
          }
          return val;
        });
      });
    } else {
      const lastIndex = cardShow.lastIndexOf((item) => item.flag == true);
      // console.log("lastIndex", lastIndex);

      setCardShow((prevCardShow) => {
        const res = prevCardShow.map((val, vali) => {
          if (vali == ind) {
            val.flag = true;
          }
          return val;
        });

        return res;
      });
    }
  }


  const handleFromDateChange = (date) => {
    if (endDate && date > endDate) {
      return;
    }
    setStartDate(date);
  };


  const handleToDateChange = (date) => {
    if (startDate && date < startDate) {
      return;
    }
    setEndDate(date);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="nc_dashboard mb-4">
        <div className="mt-2">
          <h1 className="bold">
            {constLabel?.lbl_production_dashboard ? constLabel?.lbl_production_dashboard : "Production Dashboard"}
            {/* {"NC Dashboard: Production Overview"} */}
          </h1>

          <div className="card border-0">
            <div className="card-body row">
              <div className="col-12 col-md-8">
                <div className="row">
                  <div
                    className="col-12 col-md-2 d-flex align-items-center"
                    style={{ maxWidth: "120px" }}
                  >
                    <p className="mb-0">{constLabel?.lbl_from_date ? constLabel?.lbl_from_date : "From Date"}</p>
                  </div>
                  <div className="col-12 col-md-4">
                    <div style={{ maxWidth: "250px" }}>
                      <Controller
                        control={control}
                        name="fromDate"
                        render={({ field }) => (
                          <DatePicker
                            id="fromDate"
                            className="form-control form-control-sm"
                            selected={startDate}
                            onChange={(date) => {
                              field.onChange(date);
                              handleFromDateChange(date)
                            }}
                            showYearDropdown
                            scrollableMonthYearDropdown
                            dateFormat="dd/MM/yyyy"
                            placeholderText="From Date"
                            maxDate={new Date()}
                            autoComplete="false"
                          />
                        )}
                      />
                      {errors.fromDate && (
                        <span className="text-danger err-msg">
                          {errors.fromDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className="col-12 col-md-2 d-flex align-items-center"
                    style={{ maxWidth: "120px" }}
                  >
                    <p className="mb-0">{constLabel?.lbl_to_date ? constLabel?.lbl_to_date : "To Date"}</p>
                  </div>
                  <div className="col-12 col-md-4">
                    <div style={{ maxWidth: "250px" }}>
                      <Controller
                        control={control}
                        name="toDate"
                        render={({ field }) => (
                          <DatePicker
                            id="toDate"
                            className="form-control form-control-sm"
                            selected={endDate}
                            onChange={(date) => {
                              field.onChange(date);
                              handleToDateChange(date)
                            }}
                            showYearDropdown
                            scrollableMonthYearDropdown
                            dateFormat="dd/MM/yyyy"
                            placeholderText="To Date"
                            maxDate={new Date()}
                            autoComplete="false"
                          />
                        )}
                      />
                      {errors.toDate && (
                        <span className="text-danger err-msg">
                          {errors.toDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <p className="roboto-bold" style={{ fontSize: "18px" }}>
              {constLabel?.lbl_quick_stats ? constLabel?.lbl_quick_stats : "Quick stats"}
            </p>

            <Tooltip id={"show-tooltip"} place="top" />
            <div 
              className="dropdown"
              data-tooltip-id={"show-tooltip"}
              data-tooltip-content={"Show / Hide Columns"}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {/* <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faCog}
                  size="sm"
                /> */}
                <img src={List} height='32' width='32' className="cursor_pointer" />
              </div>

              <ul
                className="dropdown-menu px-2 shadow-sm"
                style={{
                  maxHeight: "300px",
                  overflowY: "scroll",
                  width: "200px",
                }}
              >
                {cardShow !== undefined && cardShow.length > 0
                  ? cardShow.map((val, ind, arr) => (
                    <li key={ind}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => handleClick(ind, val.flag)}
                          value=""
                          checked={val.flag}
                          id={val.name}
                        />
                        <label
                          className="ms-1"
                          style={{ fontSize: "13px" }}
                          htmlFor={val.name}
                        >
                          {val.name}
                        </label>
                      </div>
                    </li>
                  ))
                  : null}
              </ul>
            </div>
          </div>

          {cardShow.map((card, index) =>
            card.name === "Product with Operation" && card.flag ? (
              <div key={index} className="col-12 col-md-12 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h4 className="roboto-bold">{"Product with Operation"}</h4>
                    <p className="roboto-light nc_detail_label">
                      {"Top product with top operation"}
                    </p>
                    {prodWithOprStack.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : prodWithOprStack.data.options &&
                      prodWithOprStack.data.series &&
                      prodWithOprStack.data?.series?.length > 0 ? (
                      <Chart
                        options={prodWithOprStack.data?.options}
                        series={prodWithOprStack.data?.series}
                        type="bar"
                        width={"100%"}
                        height={500}
                      />
                    ) : (
                      <div
                        className="alert alert-danger mb-0 text-center my-1"
                        role="alert"
                      >
                        {"Product with operations data not found !"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === "Lot wise Product" && card.flag ? (
              <div key={index} className="col-12 col-md-6 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h4 className="roboto-bold">{"Lot wise Product"}</h4>
                      <p className="roboto-light nc_detail_label">
                        {"Top product produced quantity with operation "}
                      </p>
                    </div>
                    <div>
                    <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {"Lot No"}
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          name={`lot_no`}
                          control={control}
                          render={({ field: { onChange, onBlur, value, ref } }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="lotNo"
                              multiple={false}
                              options={lotList.data}
                              isLoading={lotList.loading}
                              placeholder="Select lot no"
                              onChange={(selectedOptions) => {
                                // If an option is selected, update the selected index
                                if (selectedOptions.length > 0) {
                                  const selectedOption = selectedOptions[0];
                                  const newIndex = lotList.data.findIndex(
                                    (option) => option.lotNo === selectedOption.lotNo
                                  );
                                  handleChangeLot(selectedOption, newIndex);
                                }
                                // Call the provided onChange function with the selected option
                                onChange(selectedOptions);
                              }}
                              onBlur={onBlur}
                              defaultSelected={lotList.data.length > 0 ? [lotList?.data[0]?.lotNo] : value}
                            />
                          )}
                        />
                      </div>
                    </div>
                  </div>
                    
                    {lotWiseProduct.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : lotWiseProduct.data.options &&
                      lotWiseProduct.data.series &&
                      lotWiseProduct.data?.series?.length > 0 ? (
                      <Chart
                        options={lotWiseProduct.data?.options}
                        series={lotWiseProduct.data?.series}
                        type="bar"
                        width={"100%"}
                        height={500}
                      />
                    ) : (
                      <div
                        className="alert alert-danger mb-0 text-center my-1"
                        role="alert"
                      >
                        {"Lot wise product produced quantity with operations data not found !"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === "Lot wise Produced Quantity" && card.flag ? (
              <div key={index} className="col-12 col-md-6 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h4 className="roboto-bold">{"Lot wise Produced Quantity"}</h4>
                    <p className="roboto-light nc_detail_label">
                      {"Lot with produced quantity and nc count"}
                    </p>
                    {lotProducedQty.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : lotProducedQty.data.options &&
                    lotProducedQty.data.series &&
                    lotProducedQty.data?.series?.length > 0 ? (
                      <Chart
                        options={lotProducedQty.data?.options}
                        series={lotProducedQty.data?.series}
                        type="bar"
                        width={"100%"}
                        height={500}
                      />
                    ) : (
                      <div
                        className="alert alert-danger mb-0 text-center my-1"
                        role="alert"
                      >
                        {"Lot wise produced quantity data not found !"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductionDashboard;

import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Modal } from "react-bootstrap";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import {
    getProdConfig,
    getOperatorList,
    getProdList,
    get_inspection_type,
    getProductAttribute,
    create_job_card,
} from "../../../api";
import {
    convertDateYYMMDD,
    getLabels, convert_date_and_time
} from "../../../common/Functions/CommonFunctions";
import { useNavigate, useLocation } from "react-router-dom";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import { useSelector } from "react-redux";

const schema = yup
    .object()
    .shape({
        startDate: yup
            .date()
            .typeError("Please select start date!")
            .required("Please select start date!"),
        startTime: yup
            .date()
            .typeError("Please select start time!")
            .required("Please select start time!"),
        endDate: yup
            .date()
            .typeError("Please select end date!")
            .required("Please select end date!"),
        endTime: yup
            .date()
            .typeError("Please select end time!")
            .required("Please select end time!"),
        target_qty: yup
            .string()
            .matches(/^[1-9][0-9]*$/, "Enter valid target qty")
            .required("Enter target qty !"),
        operator: yup
            .array()
            .min(1, "Please select operator !")
            .required("Please select operator !"),
        machine: yup
            .array()
            .min(1, "Please select machine !")
            .required("Please select machine !")

    })
    .required().test(
        'endDateTime-after-startDateTime',
        'End date and time must be after start date and time',
        function (values) {
            const { startDate, startTime, endDate, endTime, } = values;
            // console.log("startDate", startDate);
            // console.log("startTime", startTime);
            // console.log("endDate", endDate);
            // console.log("endTime", endTime);

            // Check if all fields are present
            if (!startDate || !startTime || !endDate || !endTime) {
                return this.createError({
                    path: 'endTime',
                    message: 'Please ensure all date and time fields are selected',
                });
            }
            // console.log('setting 0 time');
            startDate.setHours(0, 0, 0, 0);
            endDate.setHours(0, 0, 0, 0);

            // console.log('before if');
            if (startDate > endDate) {
                return this.createError({
                    path: 'endDate',
                    message: 'Start date should be greater or equal to end date',
                });
            }
            // console.log('afterr if');
            const startDateTime = convert_date_and_time(startDate, startTime);
            const endDateTime = convert_date_and_time(endDate, endTime);

            // console.log("startDateTime", startDateTime);
            // console.log("endDateTime", endDateTime);

            if (endDateTime <= startDateTime) {
                return this.createError({
                    path: 'endTime',
                    message: 'End date and time must be after start date and time',
                });
            }

            return true;
        }
    );

function JobCardModal(props) {
    const { uniProdBom, setUniProdBom, selRoute, visible, setVisible, fetchEmp,
        empListWithAvail, setEmpListWithAvail, selectedopr, routeType } = props;

    const [constLabel, setConstLabel] = useState({});

    let navigate = useNavigate();
    let location = useLocation();
    const [operations, setOperations] = useState({ data: [], loading: false });
    const [oprtList, setOprtList] = useState({ data: [], loading: false });

    const [machineList, setMachineList] = useState({ data: [], loading: false });
    const loginData = useSelector((state) => state.auth);

    // setting this value in useEffect every time modal is opened setting latest value from localstorage


    const disableTargetQty = loginData.loginData?.data?.response.role_name !==
        "client_admin" &&
        loginData.loginData?.data?.response.role_name !==
        "admin" ? true : false;

    const [jobStatus, setJobStatus] = useState([
        { id: 1, statusName: "Open" },
        { id: 2, statusName: "In Progress" },
        { id: 3, statusName: "Hold" },
        { id: 4, statusName: "Closed" },
        { id: 5, statusName: "Cancelled" }
    ]);
    const cuur_date_plus_one_hr = new Date();
    cuur_date_plus_one_hr.setHours(cuur_date_plus_one_hr.getHours() + 1);

    const { register, handleSubmit, formState, control, reset, watch, setError, setValue, clearErrors } = useForm({
        resolver: yupResolver(schema),
        criteriaMode: 'all',
        mode: "onChange",
        defaultValues: {
            // startDate: new Date(),
            // endDate: new Date(),
            // startTime: new Date(),
            // endTime: cuur_date_plus_one_hr,
            target_qty: 0,
            operator: [],
            machine: []
        }
    }); // initialise the hook

    let { errors } = formState;


    const selProdOpr = watch("prod_operation");
    const selMachine = watch("machine");

    const start_date = watch("startDate");
    const start_time = watch("startTime");
    const end_date = watch("endDate");
    const end_time = watch("endTime");

    const selectedProdIndex = uniProdBom.findIndex((val) => val.selected == true);
    const selectedProd = {...uniProdBom[selectedProdIndex]};
    delete selectedProd.bom;
    delete selectedProd.product_routes;
    delete selectedProd.selected_bom;
    delete selectedProd.selected_route;


    useEffect(() => {
        if (visible && selectedopr.data) {
            console.log("selectedopr", selectedopr);
            console.log("uniProdBom", uniProdBom);




            if (uniProdBom &&
                uniProdBom[selectedProdIndex] &&
                uniProdBom[selectedProdIndex].selected_route) {

                console.log("selectedProdIndex", selectedProdIndex);
                const oldSubmittedData = uniProdBom[selectedProdIndex]
                    .selected_route.route_operation_list[selectedopr.index];

                console.log("oldSubmittedData", oldSubmittedData)



                if (oldSubmittedData.create_job_card) {
                    oldSubmittedData.create_job_card.startDate = new Date(oldSubmittedData.selected_startdate);
                    oldSubmittedData.create_job_card.startTime = new Date(oldSubmittedData.selected_startdate);
                    oldSubmittedData.create_job_card.endDate = new Date(oldSubmittedData.selected_enddate);
                    oldSubmittedData.create_job_card.endTime = new Date(oldSubmittedData.selected_enddate);

                    reset(oldSubmittedData.create_job_card);
                } else {

                    if (oldSubmittedData.selected_startdate && oldSubmittedData.selected_enddate) {
                        console.log(new Date(oldSubmittedData.selected_startdate));

                        setValue("startDate", new Date(oldSubmittedData.selected_startdate));
                        setValue("startTime", new Date(oldSubmittedData.selected_startdate));
                        setValue("endDate", new Date(oldSubmittedData.selected_enddate));
                        setValue("endTime", new Date(oldSubmittedData.selected_enddate));
                        setValue("operator", oldSubmittedData.selected_emp);
                    }
                }
            }

            setConstLabel(getLabels("Production", "Job Card"));


            console.log("selectedProd", selectedProd);

            console.log("selectedopr.data", selectedopr.data);


            // here we are changing the logic based on global routes or product routes selected
            if (routeType == "product_routes") {
                const machinesFromProdOperation = selectedopr.data?.machine?.map((item) => ({
                    ...item,
                    machineNameSerialNo:
                        item.serialNo && item.serialNo !== ""
                            ? item.name + " - " + item.serialNo
                            : item.name,
                }));

                console.log("machinesFromProdOperation", machinesFromProdOperation);
                if (machinesFromProdOperation) {
                    setMachineList({ ...machineList, data: machinesFromProdOperation, loading: false });
                } else {
                    setMachineList({ ...machineList, data: [], loading: false });
                }
            } else {

                const machineList = [{
                    machineNameSerialNo: selectedopr.data.serialNo && selectedopr.data.serialNo !== ""
                        ? selectedopr.data.machine_name + " - " + selectedopr.data.serialNo
                        : selectedopr.data.machine_name,
                    machine_id_id: selectedopr.data.machine_id_id,
                    name: selectedopr.data.machine_name,
                    cycle_time: selectedopr.data.cycle_time,
                    settings_time: selectedopr.data.setting_time
                }]
                setMachineList({ ...machineList, data: machineList, loading: false });
            }




            setValue("prod_operation", [selectedopr.data]);
            setValue("product", [selectedProd]);
            setValue("partNo", selectedProd.partNo);
            // fetchProductOperations(selectedProd.uuid);
        }
    }, [visible, selectedopr.data]);



    useEffect(() => {

        if (start_date && end_date) {
            start_date.setHours(0, 0, 0, 0);
            end_date.setHours(0, 0, 0, 0);
            if (end_date >= start_date) {
                console.log("endDate");
                clearErrors("endDate");
            }

            const startDateTime = convert_date_and_time(start_date, start_time);
            const endDateTime = convert_date_and_time(end_date, end_time);



            if (endDateTime > startDateTime) {
                console.log("clear endTime");
                clearErrors("endTime");
            }


            const calculatePlannedQuantity = (st_tm, ed_tm, setting_time, cycle_time) => {
                setting_time = setting_time !== "" && setting_time !== null ? setting_time : 0;

                console.log("ed_tm", ed_tm);
                console.log("st_tm", st_tm);
                console.log("setting_time", setting_time);
                console.log("cycle_time", cycle_time);

                const timeDiffInMinutes = (ed_tm - st_tm) / (60 * 1000);
                console.log("timeDiffInMinutes", timeDiffInMinutes);

                if (timeDiffInMinutes > 0 && parseFloat(cycle_time) > 0) {
                    const plannedQuantity = Math.ceil(
                        (timeDiffInMinutes - parseFloat(setting_time)) /
                        parseFloat(cycle_time)
                    );
                    return plannedQuantity;
                } else {
                    return 0;
                }
            };

            if (!selMachine || !start_date || !start_time || !end_date || !end_time) {
                // Handle the case where any of the dependencies are null or undefined
                setValue("target_qty", 0);
                return;
            }

            console.log("selMachine", selMachine);

            const start_datetime = convert_date_and_time(start_date, start_time);
            const end_datetime = convert_date_and_time(end_date, end_time);
            let plannedQty = calculatePlannedQuantity(start_datetime, end_datetime,
                selMachine?.[0]?.settings_time, selMachine?.[0]?.cycle_time);

            console.log("plannedQty", plannedQty);

            const selectedProd = uniProdBom.find(val => val.selected == true);
            console.log(selectedProd);
            
            if (plannedQty > selectedProd.so_item_quantity) {
                setError('target_qty', {
                    type: 'custom',
                    message: `Target qty ${plannedQty} should be less than WO - ${selectedProd.so_item_quantity} qty`
                });
            }
            setValue("target_qty", plannedQty);


        }

    }, [start_date, start_time, end_date, end_time, selMachine]);


    function fetchProductOperations(prod_id) {

        setOperations({ ...operations, data: [], loading: true });

        getProdConfig({ product_id: prod_id, routes_uuid: selRoute[0].route_uuid }).then(
            (res) => {
                // console.log("prod opr res", res);
                setOperations({ ...operations, data: res.data.data, loading: false });
            },
            (err) => {
                setOperations({ ...operations, data: [], loading: false });
            }
        );
    }

    function fetchOperatorList() {
        setOprtList({ ...oprtList, data: [], loading: true });
        getOperatorList({ designation: ["Operator", "Packing and Dispatch Lead"] }).then(
            (res) => {
                const result = res.data.data.map((item) => ({
                    ...item,
                    fullName:
                        item.MiddleName !== null && item.MiddleName !== ""
                            ? item.FirstName + " " + item.MiddleName + " " + item.LastName
                            : item.FirstName + " " + item.LastName,
                }));
                setOprtList({ ...oprtList, data: result, loading: false });
            },
            (err) => {
                setOprtList({ ...oprtList, data: [], loading: false });
            }
        );
    }

    function newDateTimeForm(date, time) {
        // console.log("date", date);
        // console.log("time", time);
        let year = date.getFullYear();
        let month = (date.getMonth() + 1).toString().padStart(2, "0");
        let day = date.getDate().toString().padStart(2, "0");

        // Extracting time components
        let hours = time.getHours().toString().padStart(2, "0");
        let minutes = time.getMinutes().toString().padStart(2, "0");
        let seconds = time.getSeconds().toString().padStart(2, "0");

        // Creating start_time in the desired format (assuming UTC time for the example)
        return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
    }

    // const handleFromDateChange = (date) => {
    //     if (end_date && date > end_date && end_time && date > end_time) {
    //         return;
    //     }
    //     setStart_Date(date);
    //     setStart_Time(date);
    // };

    // const handleToDateChange = (date) => {
    //     if (start_date && date < start_date && start_time && date < start_time) {
    //         return;
    //     }
    //     setEnd_Date(date);
    //     setEnd_Time(date);
    // };






    const onSubmit = (data) => {
        if (data !== "") {            

            const selectedProd = uniProdBom.find(val => val.selected == true);

            if (data.target_qty > selectedProd.so_item_quantity) {
                setError('target_qty', {
                    type: 'custom',
                    message: `Target qty ${data.target_qty} should be less than WO - ${selectedProd.so_item_quantity} qty`
                });
                return;
            }

            console.log("data", data);
            console.log("uniProdBom", uniProdBom);
            console.log("selectedProdIndex", selectedProdIndex);
            console.log("selectedopr", selectedopr);



            const startDateTime = convert_date_and_time(data.startDate, data.startTime);
            const endDateTime = convert_date_and_time(data.endDate, data.endTime);

            const timeDiff = Math.abs(new Date(startDateTime) - new Date(endDateTime));
            data.total_time_hrs = Math.floor(timeDiff / 3600000).toString();
            data.total_time_mins = Math.floor(timeDiff / 60000).toString();
            data.total_time_sec = Math.floor(timeDiff / 1000).toString();


            const tempUniProdBom = [...uniProdBom];
            console.log("tempUniProdBom", tempUniProdBom);
            tempUniProdBom[selectedProdIndex].selected_route.route_operation_list[selectedopr.index].create_job_card
                = data;

            setUniProdBom(tempUniProdBom);





            // fetchEmp(selectedOper.data, selectedOper.index, selectedProdIndex);
            setVisible(false);
            reset();

        } else {
            errors.showMessages();
        }
    };

    return (
        <React.Fragment>



            <Modal show={visible} onHide={() => {
                setVisible(false);
            }} size={"xl"}>
                <Modal.Header
                    style={{ background: "#2A3643", color: "white" }}
                    closeButton
                >
                    <Modal.Title>
                        {constLabel?.lbl_job_card
                            ? constLabel.lbl_job_card
                            : "Create Job Card"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row pb-3">

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_product_name
                                        ? constLabel.lbl_product_name
                                        : "Product Name"}
                                </label>

                                <Controller
                                    name="product"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="productName"
                                            multiple={false}
                                            placeholder="Select product"
                                            options={[]}
                                            disabled
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.product ? true : false}
                                        />
                                    )}
                                />
                                <span className="text-danger err-msg">
                                    {errors.product?.message}
                                </span>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_part_no
                                        ? constLabel.lbl_part_no
                                        : "Part No"}
                                </label>

                                <input
                                    type="text"
                                    className="form-control "
                                    id="partNo"
                                    name="partNo"
                                    placeholder="Enter part no"
                                    disabled
                                    {...register("partNo")}
                                />

                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_status ? constLabel.lbl_status : "Status"}
                                </label>

                                <Controller
                                    name="status"
                                    control={control}
                                    defaultValue={[{ id: 1, statusName: "Open" }]}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="statusName"
                                            multiple={false}
                                            defaultSelected={[{ id: 1, statusName: "Open" }]}
                                            // size="sm"
                                            options={jobStatus}
                                            placeholder="Select status"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            disabled
                                        />
                                    )}
                                />

                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_start_date
                                        ? constLabel.lbl_start_date
                                        : "Start Date"}
                                    <span className="text-danger"> *</span>
                                </label>

                                <Controller
                                    control={control}
                                    name="startDate"
                                    render={({ field }) => (
                                        <DatePicker
                                            className={
                                                errors.startDate
                                                    ? "form-control  is-invalid"
                                                    : "form-control  "
                                            }
                                            placeholderText="Select date"
                                            selected={field.value}
                                            onChange={(date) => {
                                                field.onChange(date);
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            autoComplete="false"
                                            disabled
                                        />
                                    )}
                                />

                                {errors.startDate && (
                                    <span className="text-danger err-msg">
                                        {errors.startDate.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_start_time
                                        ? constLabel?.lbl_start_time
                                        : "Start Time"}
                                    <span className="text-danger"> *</span>
                                </label>


                                <Controller
                                    control={control}
                                    name="startTime"
                                    render={({ field }) => (
                                        <DatePicker
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeCaption="Time"
                                            className={
                                                errors.startTime
                                                    ? "form-control  is-invalid"
                                                    : "form-control  "
                                            }
                                            placeholderText="Select start time"
                                            selected={field.value}
                                            onChange={(date) => {
                                                field.onChange(date);
                                            }}
                                            dateFormat="h:mm aa"
                                            autoComplete="false"
                                            disabled
                                        // minDate={
                                        //     new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate()).getTime()
                                        // }
                                        />
                                    )}
                                />


                                {errors.startTime && (
                                    <span className="text-danger err-msg">
                                        {errors.startTime.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_end_date
                                        ? constLabel.lbl_end_date
                                        : "End Date"}
                                    <span className="text-danger"> *</span>
                                </label>

                                <Controller
                                    control={control}
                                    name="endDate"
                                    render={({ field }) => (
                                        <DatePicker
                                            className={
                                                errors.endDate
                                                    ? "form-control  is-invalid"
                                                    : "form-control  "
                                            }
                                            placeholderText="Select date"
                                            selected={field.value}
                                            onChange={(date) => {
                                                field.onChange(date);
                                            }}
                                            dateFormat="dd/MM/yyyy"
                                            autoComplete="false"
                                            disabled
                                        />
                                    )}
                                />

                                {errors.endDate && (
                                    <span className="text-danger err-msg">
                                        {errors.endDate.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_end_time
                                        ? constLabel?.lbl_end_time
                                        : "End Time"}
                                    <span className="text-danger"> *</span>
                                </label>



                                <Controller
                                    control={control}
                                    name="endTime"
                                    render={({ field }) => (
                                        <DatePicker
                                            showTimeSelect
                                            showTimeSelectOnly
                                            timeIntervals={30}
                                            timeCaption="Time"
                                            className={
                                                errors.endTime
                                                    ? "form-control  is-invalid"
                                                    : "form-control  "
                                            }
                                            placeholderText="Select start time"
                                            selected={field.value}
                                            onChange={(date) => {
                                                field.onChange(date);
                                            }}
                                            dateFormat="h:mm aa"
                                            autoComplete="false"
                                            disabled
                                        // minDate={
                                        //     new Date(new Date().getFullYear(), new Date().getMonth() - 3, new Date().getDate()).getTime()
                                        // }
                                        />
                                    )}
                                />


                                {errors.endTime && (
                                    <span className="text-danger err-msg">
                                        {errors.endTime.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>

                    <hr className="my-2" />
                    <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                        {constLabel?.lbl_job_card_detail
                            ? constLabel.lbl_job_card_detail
                            : "Job Card Detail"}
                    </p>
                    <hr className="my-2" />
                    <div className="row pb-3">
                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_product_operation
                                        ? constLabel.lbl_product_operation
                                        : "Product Operation"}
                                    <span className="text-danger"> *</span>
                                </label>

                                <Controller
                                    name="prod_operation"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="operationname"
                                            multiple={false}
                                            options={[]}
                                            // options={
                                            //     operations.data !== undefined ? operations.data : []
                                            // }
                                            // isLoading={operations.loading}
                                            placeholder="Select product operation"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.prod_operation ? true : false}
                                            disabled
                                        />
                                    )}
                                />
                                <span className="text-danger err-msg">
                                    {errors.prod_operation?.message}
                                </span>
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_machine
                                        ? constLabel.lbl_machine
                                        : "Machine"}
                                    <span className="text-danger"> *</span>
                                </label>

                                <Controller
                                    name={`machine`}
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="machineNameSerialNo"
                                            multiple={false}
                                            options={machineList.data}
                                            isLoading={machineList.loading}
                                            placeholder="Select machine"
                                            onChange={(selected) => {
                                                onChange(selected);
                                                if (selected.length > 0) {
                                                    setValue("cycle_time", selected?.[0]?.cycle_time);
                                                    setValue(
                                                        "setting_time",
                                                        selected?.[0]?.settings_time
                                                    );
                                                } else {
                                                    setValue("cycle_time", "");
                                                    setValue("setting_time", "");
                                                }
                                            }}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.machine ? true : false}
                                        />
                                    )}
                                />
                                {errors.machine && (
                                    <span className="text-danger err-msg">
                                        {errors.machine.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_cycle_time
                                        ? constLabel.lbl_cycle_time
                                        : "Cycle Time"}

                                </label>
                                <input
                                    type="text"
                                    className="form-control "
                                    id="cycle_time"
                                    name="cycle_time"
                                    placeholder="Enter cycle time"
                                    disabled
                                    {...register("cycle_time")}
                                />

                            </div>
                        </div>

                        <div className="col-12 col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_target_qty
                                        ? constLabel.lbl_target_qty
                                        : "Target Qty"}
                                    <span className="text-danger"> *</span>{" "}

                                </label>
                                <input
                                    type="text"
                                    className={
                                        errors.target_qty
                                            ? "form-control  is-invalid"
                                            : "form-control  "
                                    }
                                    id="target_qty"
                                    name="target_qty"
                                    placeholder="Enter target qty"
                                    {...register(`target_qty`, {
                                        onChange: (e) => {
                                            setValue("target_qty", e.target.value, { shouldValidate: true });
                                        },
                                    })}
                                    disabled={disableTargetQty}
                                />
                                <span className="text-danger err-msg">
                                    {errors.target_qty?.message}
                                </span>
                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_actual_qty
                                        ? constLabel.lbl_actual_qty
                                        : "Actual Qty"}
                                </label>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="actual_qty"
                                    defaultValue={0}
                                    name="actual_qty"
                                    placeholder="Enter actual qty"
                                    {...register("actual_qty")}
                                    disabled
                                />
                            </div>
                        </div>


                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_operator
                                        ? constLabel.lbl_operator
                                        : "Operator"}
                                    <span className="text-danger"> *</span>
                                </label>

                                <Controller
                                    name="operator"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="FullName"
                                            multiple={false}
                                            // options={
                                            //     oprtList.data !== undefined ? oprtList.data : []
                                            // }
                                            // isLoading={oprtList.loading}
                                            options={[]}
                                            disabled
                                            placeholder="Select operator"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.operator ? true : false}
                                        />
                                    )}
                                />
                                <span className="text-danger err-msg">
                                    {errors.operator?.message}
                                </span>
                            </div>
                        </div>


                        <div className="col-12 col-md-3 mt-2">
                            <div className="form-group mt-md-0 mt-3">
                                <label
                                    style={{ fontSize: "14px" }}
                                    className="col-form-label roboto-bold"
                                >
                                    {constLabel?.lbl_remarks
                                        ? constLabel.lbl_remarks
                                        : "Remarks"}
                                </label>
                                <textarea
                                    type="text"
                                    className="form-control"
                                    id="remarks"
                                    name="remarks"
                                    placeholder="Enter remarks"
                                    {...register("remarks")}
                                >
                                </textarea>

                            </div>
                        </div>

                        <div className="col-12 col-md-3 mt-2">
                            <label className="lbl-style roboto-bold invisible">
                                {"Unit"}
                            </label>
                            <div className="d-flex align-items-center mt-3">
                                <div className="form-check">
                                    <input
                                        className="form-check-input"
                                        type="checkbox"
                                        defaultChecked={selProdOpr?.[0]?.serial_number_tracking}
                                        disabled
                                        checked={selProdOpr?.[0]?.serial_number_tracking}
                                    />
                                    <label className="form-check-label lbl-style roboto-bold">
                                        {constLabel?.lbl_serial_number_tracking
                                            ? constLabel?.lbl_serial_number_tracking
                                            : "Serial number tracking"}
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={handleSubmit(onSubmit)}
                        className="f-16 btn btn-yellow "
                    >
                        {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default JobCardModal
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { XMLParser } from 'fast-xml-parser';

const LedgerList = () => {
  const [ledgers, setLedgers] = useState([]);
  const [ledgers1, setLedgers1] = useState([]);

  useEffect(() => {
    const fetchConnection = async () => {
      try {
        const response = await axios.get('http://192.168.1.12:9000', {
            
        });
        console.log(response);
      } catch (error) {
        console.error('Failed to fetch connection:', error);
      }
    };

    fetchConnection();
  }, []);
  

  const tallyXMLPayload = `<ENVELOPE>
        <HEADER>
            <TALLYREQUEST>Export</TALLYREQUEST>
        </HEADER>
        <BODY>
            <EXPORTDATA>
                <REQUESTDESC>
                    <REPORTNAME>List of Ledgers</REPORTNAME>
                    <STATICVARIABLES>
                        <SVEXPORTFORMAT>$$SysName:XML</SVEXPORTFORMAT>
                    </STATICVARIABLES>
                </REQUESTDESC>
            </EXPORTDATA>
        </BODY>
    </ENVELOPE>`;

    const tallyXMLPayload1 = `<ENVELOPE>
    <HEADER>
        <VERSION>1</VERSION>
        <TALLYREQUEST>Export</TALLYREQUEST>
        <TYPE>Collection</TYPE>
        <ID>Ledgers</ID>
    </HEADER>
    <BODY>
        <DESC>
            <STATICVARIABLES>
                <SVEXPORTFORMAT>SysName:XML</SVEXPORTFORMAT>
                <SVCURRENTCOMPANY>RAVI CAM MANUFACTURING PVT LTD 2023-2024</SVCURRENTCOMPANY>
            </STATICVARIABLES>
            <TDL>
                <TDLMESSAGE>
                    <COLLECTION ISMODIFY="No" ISFIXED="No" ISINITIALIZE="No" ISOPTION="No" ISINTERNAL="No" NAME="Ledgers">
                        <TYPE>Ledger</TYPE>
                        <CHILDOF>Sundry Creditors</CHILDOF>
                        <BELONGSTO>Yes</BELONGSTO>
                        <NATIVEMETHOD>*</NATIVEMETHOD>
                    </COLLECTION>
                </TDLMESSAGE>
            </TDL>
        </DESC>
    </BODY>
</ENVELOPE>
        `;

  async function fetchLedgers(){
    try {
      const data = await fetchLedgersFromTally(tallyXMLPayload);
      setLedgers(data);
    } catch (error) {
      console.error('Failed to fetch ledgers:', error);
    }
  };

  async function fetchLocal(){
    try {
        const data = await fetchLedgersFromTally(tallyXMLPayload1);
        setLedgers1(data);
    } catch (error) {
      console.error('Failed to fetch ledgers:', error);
    }
  };

  const parseXML = (xmlString) => {
    const parser = new XMLParser({
      ignoreAttributes: false,
      attributeNamePrefix: '', // Optional, to customize attribute prefix handling
    });
    const jsonObj = parser.parse(xmlString);
    
    // Assuming the ledger names are within the <LEDGER> tag
    const ledgersArray = jsonObj.ENVELOPE.BODY.EXPORTDATA?.LEDGER || [];
    
    return Array.isArray(ledgersArray) ? ledgersArray.map(ledger => ledger.NAME) : [];
  };

  const fetchLedgersFromTally = async (data) => {
    try {
      const response = await axios.post('http://192.168.1.12:9000', data, {
        headers: {
          'Content-Type': 'text/xml',
        },
      });

      const parsedLedgers = parseXML(response.data); // Parse the XML response
      console.log(parsedLedgers); // Handle the parsed JSON result
      return parsedLedgers;
    } catch (error) {
      console.error('Error fetching ledgers:', error);
      throw error;
    }
  };

  const handleCheckin = async () => {
    try {
      const response = await axios.get('http://192.168.1.12:5000/env');
      console.log(response.data);
    //   alert(response.data.message);
    } catch (error) {
      console.error('Error fetching the check-in:', error);
      alert('Check-in failed!');
    }
  };
  
  const handleCheckin2 = async () => {
    try {
      const response = await axios.get('http://192.168.1.12:5000/tallylocal');
      console.log(response.data);
    //   alert(response.data.message);
    } catch (error) {
      console.error('Error fetching the check-in:', error);
      alert('Check-in failed!');
    }
  };

  const handleCheckin3 = async () => {
    try {
      const response = await axios.get('http://192.168.1.12:5000/tally');
      console.log(response.data);
    //   alert(response.data.message);
    } catch (error) {
      console.error('Error fetching the check-in:', error);
      alert('Check-in failed!');
    }
  };

  const handleCheckin4 = async () => {
    try {
      const response = await axios.post('http://192.168.1.12:5000/savetally');
      console.log(response.data);
    //   alert(response.data.message);
    } catch (error) {
      console.error('Error fetching the check-in:', error);
      alert('Check-in failed!');
    }
  };

  return (
    <div>
      <h1>Ledger List</h1>
      <ul>
        {/* {ledgers.map((ledger, index) => (
          <li key={index}>{ledger}</li>
        ))} */}
        {"Tally data \n" + ledgers}
      </ul>

      <ul>
        {/* {ledgers.map((ledger, index) => (
          <li key={index}>{ledger}</li>
        ))} */}
        {"Tally data \n" + ledgers1}
      </ul>

        <button className='btn button-primary me-2' onClick={() => fetchLedgers()}>Check Server Running</button>
        <button className='btn btn-secondary me-2' onClick={() => fetchLocal()}>Get Local Data</button>
        <button className='btn btn-secondary me-2' onClick={() => handleCheckin()}>Check dummy server connection</button>
        <button className='btn btn-danger me-2' onClick={() => handleCheckin2()}>Check tally with local server connection</button>
        <button className='btn btn-warning me-2' onClick={() => handleCheckin3()}>Check tally with main server</button>
        <button className='btn btn-success me-2' onClick={() => handleCheckin4()}>Check post data</button>
      
    </div>
  );
};

export default LedgerList;

import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect} from 'react';

function Address(props) {

     
    const {address,address2,city_name,country_name,state_name ,postalCode,faxNo} = props.data;

  return (
    <React.Fragment>
        <div className='row'>
        <div className="col-12 col-md-12">
                  <div className="card border-0">
                    <div className="card-body p-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="roboto-bold">{"Address Detail"}</h5>
                        <FontAwesomeIcon
                          className="pt-1"
                          icon={faCircleInfo}
                          size="sm"
                        />
                      </div>

                    <div className='bg-lightgrey'>
                        <div className="row p-2">
                            <div className="col-12 col-md-4">
                                <div>
                                    <p className="m-0 nc_detail_label">{`Address line 1`}</p>
                                    <p className="mb-2 roboto-bold">{address !== "" && address !== null ? address : "NA"}</p>
                                </div>

                                <div>
                                    <p className="m-0 nc_detail_label">{`Address line 2`}</p>
                                    <p className="mb-2 roboto-bold">{address2 !== "" && address2 !== null ?address2  : "NA"}</p>
                                </div>
                            
                                <div>
                                    <p className="m-0 nc_detail_label">{`Country`}</p>
                                    <p className="mb-2 roboto-bold">{country_name !== "" && country_name !== null ? country_name : "NA"}</p>
                                </div>
            
                            </div>
                            <div className="col-12 col-md-4">
                                <div>
                                    <p className="m-0 nc_detail_label">{`State`}</p>
                                    <p className="mb-2 roboto-bold">{state_name !== "" && state_name !== null ? state_name : "NA"}</p>
                                </div>
                                <div>
                                    <p className="m-0 nc_detail_label">{`City`}</p>
                                    <p className="mb-2 roboto-bold">{city_name !== "" && city_name !== null ? city_name : "NA"}</p>
                                </div>

                            
                                <div>
                                    <p className="m-0 nc_detail_label">{`Postal Code`}</p>
                                    <p className="mb-2 roboto-bold">{postalCode !== "" && postalCode !== null ? postalCode : "NA"}</p>
                                </div>
            
                            </div>
                            <div className="col-12 col-md-4">
                                <div>
                                    <p className="m-0 nc_detail_label">{`Fax Number `}</p>
                                    <p className="mb-2 roboto-bold">{faxNo !== "" && faxNo !== null ? faxNo : "NA"}</p>
                                </div>

                               
            
                            </div>
                            
                        </div>
                    </div>
                        
                    </div>
                  </div>
                </div>
        </div>

    </React.Fragment>
  )
}

export default Address
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import { getProdTypeList, getProdCatList, getCurrList, getToolGradeList, getToolFamilyList } from "../../../api/index";
import { Typeahead } from "react-bootstrap-typeahead";
import ManfGoodForm from './Forms/ManfGoodForm';
import { useNavigate } from 'react-router-dom';
import RawMaterial from './Forms/RawMaterial';

function CreateProduct() {
    const [visible, setVisible] = useState(false);
    const [prodTypeList, setProdTypeList] = useState({ data: [], loading: false });
    const [prodCatList, setProdCatList] = useState({ data: [], loading: false });
    const [prodCurrList, setCurrList] = useState({ data: [], loading: false });
    const [toolGradeList, setToolGradeList] = useState({ data: [], loading: false });
    const [toolFamList, setToolFamList] = useState({ data: [], loading: false });
    const [selType, setSelType] = useState("");
    const [rawMatModal, setRawMatModal] = useState(false);
    const [toolsModal, setToolsModal] = useState(false);
    let navigate = useNavigate();

    useEffect(() => {
        fetchProdType();
        if (visible) {
            fetchProdCatList();
            fetchCurr();
            fetchToolGList();
            fetchToolFList();
        }
    }, [visible]);


    function fetchToolGList() {
        setToolGradeList({ ...toolGradeList, data: [], loading: true });

        getCurrList().then((res) => {
            
            setToolGradeList({ ...toolGradeList, data: res.data.TGlist, loading: false });
        }, (err) => {
            
            setToolGradeList({ ...toolGradeList, data: [], loading: false });
        })
    }
    function fetchToolFList() {
        setToolFamList({ ...toolFamList, data: [], loading: true });

        getCurrList().then((res) => {
            
            setToolFamList({ ...toolFamList, data: res.data.ToolFamilyList, loading: false });
        }, (err) => {
            
            setToolFamList({ ...toolFamList, data: [], loading: false });
        })
    }

    function fetchCurr() {
        setCurrList({ ...prodCurrList, data: [], loading: true });

        getCurrList().then((res) => {
            
            setCurrList({ ...prodCurrList, data: res.data.currencyList, loading: false });
        }, (err) => {
            
            setCurrList({ ...prodCurrList, data: [], loading: false });
        })
    }

    function fetchProdCatList() {
        setProdCatList({ ...prodCatList, data: [], loading: true });
        getProdCatList().then((res) => {
            
            setProdCatList({ ...prodCatList, data: res.data.ProductCategoryList, loading: false });
        }, (err) => {
            
            setProdCatList({ ...prodCatList, data: [], loading: false });
        })
    }

    function fetchProdType() {
        setProdTypeList({ ...prodTypeList, data: [], loading: true });

        getProdTypeList({
            "action": "alllist"
        }).then((res) => {
            
            setProdTypeList({ ...prodTypeList, data: res.data.ProductTypeList, loading: false });
        }, (err) => {
            
            setProdTypeList({ ...prodTypeList, data: [], loading: false });
        })
    }


    return (
        <React.Fragment>
            {/* <button
                onClick={() => setVisible(true)}
                className='btn button-primary px-4'>
                <FontAwesomeIcon style={{ color: "#fff" }} icon={faPlus} size="sm" />{" "}Add Product</button> */}
            <RawMaterial setRawMatModal={setRawMatModal} rawMatModal={rawMatModal} />

            <div className='product'>
                <div className="dropdown">
                    <button
                        disabled={prodTypeList.loading ? true : false}
                        className="btn button-primary px-4 dropdown-toggle"
                        type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <FontAwesomeIcon style={{ color: "#fff" }} icon={faPlus} size="sm" /> Add Product
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {prodTypeList.data.map((val, i) => (
                            <li key={i}>
                                <a className="dropdown-item"
                                    onClick={() => {
                                        if (val.ProductTypeName == "Manufactured Goods") {
                                            navigate('/pcms/manufacturing-goods/');
                                        } else if (val.ProductTypeName == "Raw material") {
                                            // navigate('');
                                            setRawMatModal(true);
                                        } else if (val.ProductTypeName == "Tools") {
                                            setToolsModal(false);
                                        }
                                    }}>{val.ProductTypeName}</a>
                            </li>
                        ))}
                        {/* <li><a className="dropdown-item" href="#">Another action</a></li>
                        <li><a className="dropdown-item" href="#">Something else here</a></li> */}
                    </ul>
                </div>
            </div>

        </React.Fragment >
    )
}

export default CreateProduct
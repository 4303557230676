import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { Typeahead } from 'react-bootstrap-typeahead'
import DatePicker from 'react-datepicker'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faImage, faAdd, faEye, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { getMachineList, saveTask, getStatusMaster, getTaskCategory, fetch_Supp_Customer, getSupplier, master_multi_file_upload, getOperatorList } from '../../../api';
import { getUsers } from '../../../api/userManagement';
import SweetAlert from 'sweetalert2'
import { useLocation, useNavigate } from 'react-router-dom';
import { convertDateFormat, convertDateYYMMDD } from '../../../common/Functions/CommonFunctions';
import TaskGallery from './TaskGallery';

const schema = yup
  .object()
  .shape({
    // ncPrefix: yup.string().required("Prefix is required !").matches(/^[A-Z0-9]*$/, "Only alphabets and space Allowed !"),,
    // machine: yup
    //   .array()
    //   .min(1, "Please select machine !")
    //   .required("Please select machine !"),
    machine: yup
      .array()
      .when(['category'], (category, schema) => {
        // Check if 'machine_' exists in any of the selected categories

        if (category !== undefined && category.length > 0) {

          const machineCategorySelected = category[0].name.includes('machine_');

          // If 'machine_' is in the category, make 'supplier' mandatory
          return machineCategorySelected
            ? schema.min(1, "Please select machine !").required("Please select machine !")
            : schema;
        } else {
          return schema
        }
      }),
    category: yup
      .array()
      .min(1, "Please select task category !")
      .required("Please select task category !"),
    assignTo: yup
      .array()
      .min(1, "Please select assignee !")
      .required("Please select assignee !"),
    // supplier: yup
    //   .array()
    //   .when(['category'], (category, schema) => {
    //     // Check if 'machine_' exists in any of the selected categories

    //     if (category !== undefined && category.length > 0) {

    //       const machineCategorySelected = category[0].name.includes('machine_');

    //       // If 'machine_' is in the category, make 'supplier' mandatory
    //       return machineCategorySelected
    //         ? schema.min(1, "Please select supplier !").required("Please select supplier !")
    //         : schema;
    //     } else {
    //       return schema
    //     }
    //   }),
    supplier: yup
      .array()
      .min(1, "Please select supplier !")
      .required("Please select supplier !"),
    status: yup
      .array()
      .min(1, "Please select status !")
      .required("Please select status !"),

    task_name: yup.string().required("Task name is required !"),
    start_date: yup.date().typeError("Please select start date !").required("Please select start date !"),
    end_date: yup
      .date()
      .typeError("Please select end date")
      .required("Please select end date")
      .when("start_date", (start_date, schema) => {
        if (!start_date || isNaN(new Date(start_date))) {
          return schema.test({
            test: (value) => {
              // Check if the end_date is not selectable when start_date is not selected or not valid
              return !value;
            },
            message: "Please select the start date first",
          });
        }

        // return schema.min(
        //   start_date,
        //   "End date should be greater than or equal to the start date"
        // );

        return schema.test({
          test: (end_date) => {
            // Custom validation to allow end_date to be equal to start_date
            return end_date >= start_date;
          },
          message: "End date should be greater than or equal to the start date",
        });

      }),
    service_cost: yup.string().required("Service cost is required !").matches(/^\d+(\.\d+)?$/, "Enter valid cost"),
  })
  .required();

function CreateTaskMaster() {
  let navigate = useNavigate();
  let location = useLocation();
  const [task_cat, setTask_Cat] = useState({ data: [], loading: false });
  const [task_status, setTask_Status] = useState({ data: [], loading: false });
  const [machineList, setMachineList] = useState({ data: [], loading: false });
  const [suppList, setSuppList] = useState({ data: [], loading: false });
  const [galVisible, setGalVisible] = useState(false);
  const [drawingFiles, setDrawingFiles] = useState([]);
  const [empList, setEmpList] = useState({ data: [], loading: false });
  const [star, setStar] = useState();

  const { handleSubmit, control, register, watch, formState, reset, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange"
  });

  let { errors } = formState;

  const taskCat = watch('category');
  useEffect(() => {
    if (taskCat !== undefined && taskCat.length > 0) {
      const res = taskCat[0].name.includes('machine_');

      setStar(res);
      if (!res) {
        setValue('machine', []);
        setValue('supplier', []);
      }
    }
  }, [taskCat])

  useEffect(() => {
    fetchStatus();
    fetchTaskCategory();
    fetchMachine();
    fetchSupplier();
    fetchEmployees();
  }, []);

  function fetchEmployees() {
    setEmpList({ ...empList, data: [], loading: true });

    getOperatorList({ linked: 1 }).then((res) => {
      const result = res.data.data.map((val) => {
        val.full_name = val.FirstName + " " + val.LastName;
        return val
      });

      setEmpList({ ...empList, data: result, loading: false });
    }, (err) => {

      setEmpList({ ...empList, data: [], loading: false });
    })
  }

  function fetchSupplier() {
    setSuppList({ ...suppList, data: [], loading: true });

    // getSupplier().then((res) => {
    fetch_Supp_Customer({ partyType: "Supplier" }).then((res) => {
      setSuppList({ ...suppList, data: res.data.data, loading: false });
    }, (err) => {
      setSuppList({ ...suppList, data: [], loading: false });

    })
  }

  function fetchTaskCategory() {
    setTask_Cat({ ...task_cat, data: [], loading: true });
    getTaskCategory().then(
      (res) => {


        setTask_Cat({ ...task_cat, data: res.data.data, loading: false });
        // const result = res.data.data.filter((item) => item.status_name === "Open");

        // setValue('status', result)
      },
      (err) => {

        setTask_Cat({ ...task_cat, data: [], loading: false });
      }
    );
  }

  function fetchStatus() {
    setTask_Status({ ...task_status, data: [], loading: true });
    getStatusMaster().then(
      (res) => {

        setTask_Status({ ...task_status, data: res.data.data, loading: false });
        const result = res.data.data.filter((item) => item.status_name == "Open" && item.model_name == "task");

        setValue('status', result)
      },
      (err) => {

        setTask_Status({ ...task_status, data: [], loading: false });
      }
    );
  }

  function fetchMachine() {
    setMachineList({ ...machineList, loading: true });

    getMachineList().then(
      (res) => {
        const result = res.data.data.filter(
          (item) =>
            item.machineName !== "" &&
            item.machineName !== null &&
            item.machineName !== undefined
        );

        const ress = result.map((item) => ({
          ...item,
          machineNameSerialNo: item.machineName + " - " + item.serialNo,
        }));

        setMachineList({ ...machineList, data: ress, loading: false });
      },
      (err) => {

        setMachineList({ ...machineList, data: [], loading: false });
      }
    );
  }

  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      if (file) {
        reader.readAsDataURL(file);
      } else {
        reject(new Error("File is empty"));
      }
    });
  }

  const onSubmit = (data) => {


    if (data !== "") {
      let reqPayload = {
        task_name: data.task_name,
        machine_id: (data.machine !== undefined && data.machine.length > 0) ? data.machine[0]?.uuid : null,
        task_category: data.category[0]?.name,

        start_date: convertDateYYMMDD(data.start_date, '-'),
        end_date: convertDateYYMMDD(data.end_date, '-'),
        service_cost: data.service_cost,
        status: data.status[0]?.status_name,
        comments: data.comments,
        assigned_to: data.assignTo[0].uuid,
        assigned_name: data.assignTo[0].full_name,
        serviced_by: (data.supplier !== undefined && data.supplier.length > 0) ? data.supplier[0]?.uuid : null,
        serviced_by_name: (data.supplier !== undefined && data.supplier.length > 0) ? data.supplier[0]?.name : null,
      }

      // if (data.supplier !== undefined) {
      //   reqPayload.serviced_by = data.supplier[0]?.uuid;
      //   reqPayload.serviced_by_name = data.supplier[0]?.name;

      // }


      console.log("drawingFiles", drawingFiles);

      saveTask(reqPayload).then(
        async (res) => {

          if (res.status === 200 || res.status === 201) {

            const imgArr = [];

            for (let a = 0; a < drawingFiles.length; a++) {
              const getExt = drawingFiles[a].file.name?.split(".").pop();

              console.log("get", getExt);
              const dataimage = await convertBase64(drawingFiles[a].file);
              const newImage = dataimage.replace(
                /data:image\/[a-zA-Z]+;/,
                "data:image/" + getExt + ";"
              );
              imgArr.push({
                document: newImage,
                document_type: "image",
                document_name: drawingFiles[a].filename + `.${getExt}`,
              });

            }

            const payload = {
              model_name: "Task",
              ref_model_id: "",
              ref_model_uuid: res.data.uuid,
              files: imgArr
            }



            if (drawingFiles.length > 0) {

              master_multi_file_upload(payload).then((res) => {

                toast.success("Task created Successfully !", {
                  autoClose: 3000,
                });
                setTimeout(() => {
                  navigate('/pcms/taskMaster');
                  reset();
                }, 2000);

              }, (err) => {

                if (err.response.status == 413) {
                  toast.error("Image upload failed !", {
                    autoClose: 3000,
                  });
                }
              });
            } else {
              toast.success("Task created Successfully !", {
                autoClose: 3000,
              });
              setTimeout(() => {
                navigate('/pcms/taskMaster');
                reset();
              }, 2000);
            }

          }
        },
        (err) => {

          if (err.response.status === 400) {

            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            reset();
          }
        }
      )
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <TaskGallery
        setGalVisible={setGalVisible}
        galVisible={galVisible}
        setDrawingFiles={setDrawingFiles}
        drawingFiles={drawingFiles}
        constLabel={location.state}
      />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                SweetAlert.fire({
                  title: "Are you sure?",
                  text: "Your present data will be lost!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, go back!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.onpopstate = undefined;

                    navigate("/pcms/taskMaster");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {location.state?.lbl_add_task ? location.state.lbl_add_task : "Add Task"}
            </p>
          </div>



          <div className="card-body">
            <div className="createNc container-fluid">
              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {location.state?.lbl_task_detail ? location.state.lbl_task_detail : "Task Detail"}
              </p>
              <hr className="my-2" />

              <div className="row my-4">
                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >{location.state?.lbl_task_name ? location.state.lbl_task_name : "Task Name"}
                    <span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.task_name
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                      type='text'
                      id="task_name"
                      name="task_name"
                      placeholder="Enter task name"
                      {...register("task_name")}
                    />
                    {errors.task_name && (
                      <span className="text-danger err-msg">
                        {errors.task_name.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {location.state?.lbl_category_name ? location.state.lbl_category_name : "Category Name"}
                    <span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name={`category`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="name"

                          multiple={false}
                          options={task_cat.data}
                          isLoading={task_cat.loading}
                          placeholder="Select task category"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.category ? true : false}
                        />
                      )}
                    />
                    {errors.category && (
                      <span className="text-danger err-msg">
                        {errors.category.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {location.state?.lbl_machine_name ? location.state.lbl_machine_name : "Machine Name"}
                    {star ? (
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    ) : null}
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name={`machine`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="machineNameSerialNo"
                          multiple={false}
                          options={machineList.data}
                          isLoading={machineList.loading}
                          placeholder="Select machine"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.machine ? true : false}
                        />
                      )}
                    />
                    {errors.machine && (
                      <span className="text-danger err-msg">
                        {errors.machine.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {location.state?.lbl_serviceBy_name ? location.state.lbl_serviceBy_name : "Service By Name"}(Supplier)
                    {star ? (
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    ) : null}
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name={`supplier`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="name"

                          multiple={false}
                          options={suppList.data}
                          isLoading={suppList.loading}
                          placeholder="Select supplier"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.supplier ? true : false}
                        />
                      )}
                    />
                    {errors.supplier && (
                      <span className="text-danger err-msg">
                        {errors.supplier.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {location.state?.lbl_assignTo_name ? location.state.lbl_assignTo_name : "Assign To Name"}
                    <span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name={`assignTo`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="full_name"

                          multiple={false}
                          options={empList.data}
                          isLoading={empList.loading}
                          placeholder="Select Assignee"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.assignTo ? true : false}
                        />
                      )}
                    />
                    {errors.assignTo && (
                      <span className="text-danger err-msg">
                        {errors.assignTo.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {location.state?.lbl_start_date ? location.state.lbl_end_date : "Start Date"}
                    <span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      control={control}
                      name='start_date'
                      defaultValue={new Date()}
                      render={({ field }) => (
                        <DatePicker
                          className={errors.start_date ? "form-control  is-invalid"
                            : "form-control  "}
                          placeholderText='Select start date'
                          onChange={(date) => {
                            field.onChange(date)
                          }}
                          selected={field.value}
                          defaultValue={new Date()}
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                        />
                      )}
                    />

                    {errors.start_date && (
                      <span className="text-danger err-msg">
                        {errors.start_date.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {location.state?.lbl_end_date ? location.state.lbl_end_date : "End Date"}
                    <span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      control={control}
                      name='end_date'
                      defaultValue={new Date()}
                      render={({ field }) => (
                        <DatePicker
                          className={errors.end_date ? "form-control  is-invalid"
                            : "form-control  "}
                          placeholderText='Select end date'
                          onChange={(date) => field.onChange(date)}
                          selected={field.value}
                          defaultValue={new Date()}
                          // maxDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                        />
                      )}
                    />

                    {errors.end_date && (
                      <span className="text-danger err-msg">
                        {errors.end_date.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >{location.state?.lbl_service_cost ? location.state.lbl_service_cost : "Service Cost"}
                    <span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.service_cost
                          ? "form-control  is-invalid"
                          : "form-control  "
                      }
                      type='text'
                      id="service_cost"
                      name="service_cost"
                      defaultValue={0.00}
                      placeholder="Enter service cost"
                      {...register("service_cost")}
                    />
                    {errors.service_cost && (
                      <span className="text-danger err-msg">
                        {errors.service_cost.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {location.state?.lbl_status ? location.state.lbl_status : "Status"}
                    <span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name={`status`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="status_name"

                          multiple={false}
                          options={task_status.data}
                          isLoading={task_status.loading}
                          placeholder="Select status"
                          onChange={onChange}
                          disabled
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.status ? true : false}
                        />
                      )}
                    />
                    {errors.status && (
                      <span className="text-danger err-msg">
                        {errors.status.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >{location.state?.lbl_comment ? location.state.lbl_comment : "Comment"}
                  </label>

                  <div className="col-12 col-md-9">
                    <textarea
                      className="form-control "
                      id="comments"
                      name="comments"
                      placeholder="Enter comments"
                      {...register("comments")}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-3">
            <button
              onClick={() => setGalVisible(true)}
              className="btn btn-yellow px-3">{location.state?.lbl_image_gallary ? location.state.lbl_image_gallary : "Image Gallary"}
              <FontAwesomeIcon
                style={{
                  color: "#000",
                  fontSize: '16px',
                  marginLeft: '5px'
                }}
                icon={faImage}
              /></button>


            <button
              className="btn btn-grey px-4 ms-0 ms-md-2"
              onClick={handleSubmit(onSubmit)}
            >
              {location.state?.lbl_save ? location.state.lbl_save : "Save"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default CreateTaskMaster
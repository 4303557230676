import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faAdd } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from 'react-router-dom';
import { Typeahead } from "react-bootstrap-typeahead";
import { getModuleList, get_modules_api, save_role_api } from '../../../api';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Trash2 } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import { createRole } from '../../../api/index';
import { Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";

const schema = yup
    .object()
    .shape({
        roleName: yup.string().required("Please enter role name"),
        items: yup.array().of(
            yup.object().shape({
                module: yup.array()
                    .min(1, "Please select module")
                    .required("Please select module"),
            })
        ),
    })
    .required();

function CreateRole() {
    const localData = JSON.parse(localStorage.getItem('localData'));

    let navigate = useNavigate();
    let location = useLocation();
    const [moduList, setModuList] = useState({ data: [], loading: false });

    const { register, handleSubmit, formState, control, reset, setValue, getValues, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            items: [{ module: [] }]
        },
    }); // initialise the hook

    let { errors } = formState;

    const { fields, append, remove } = useFieldArray({
        control,
        name: "items",
    });

    const formValues = watch("items");


   

    useEffect(() => {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
            window.history.pushState(null, "", window.location.href);
        };
        fetchModList()
    }, [])

    function fetchModList() {
        
        setModuList({ ...moduList, data: [], loading: true });

        get_modules_api().then((res) => {
            
            setModuList({ ...moduList, data: res.data.data.response, loading: false });
        }, (err) => {
            setModuList({ ...moduList, data: [], loading: false });
            
        })
    }

    const isModuleNamesUnique = items => {
        const moduleNames = new Set();

        for (const item of items) {
            for (const mod of item.module) {
                if (moduleNames.has(mod.module_name)) {
                    return true; // Found a duplicate module_name
                }
                moduleNames.add(mod.module_name);
            }
        }

        return false;
    };

    const onSubmit = (data) => {
        

        if (isModuleNamesUnique(data.items)) {
            toast.error("Duplicate modules are not allowed", {
                autoClose: 2000,
            });
            return;
        } else {
            const filteredData = data.items.filter(item => {
                return item.write || item.read || item.delete || item.view || item.report;
            });
            

            if (filteredData.length > 0) {
                const resData = filteredData.map(item => {
                    item.module = item.module[0].module_name
                    return item
                });
                
                const payload = {
                    app_id:localData.app_id,
                   // client_id:localData.client_id,
                    name: data.roleName,
                    module_list: resData
                }
                save_role_api(payload).then((res) => {
                    

                    toast.success("Role created successfully", {
                        autoClose: 1000,
                    });
                    setTimeout(() => {
                        navigate("/pcms/roles");
                    }, [1000])
                }, (err) => {
                    
                    toast.error("Something went wrong", {
                        autoClose: 2000,
                    });
                })
            } else {
                toast.error("Module options are uncheck", {
                    autoClose: 2000,
                });
            }
        }

    }


    return (
        <React.Fragment>
            <ToastContainer />
            <div className='create_role mb-4'>
                <div className='card'>

                    <div className='card-header bg-grey d-flex align-items-center'>
                        <button className='btn btn-yellow' onClick={() => {
                            if (window.confirm("Are you sure to go back, your present data will be lost !")) {
                                window.onpopstate = undefined;
                                window.history.back();

                                setTimeout(() => {
                                    navigate("/pcms/roles");
                                }, [1000])

                            }
                        }}><FontAwesomeIcon icon={faArrowLeft} size="sm" /></button>

                        <p className='mb-0 ms-3' style={{ fontSize: '24px' }}>{location.state?.lbl_add_role ? location.state.lbl_add_role : "Add Role"}</p>
                    </div>
                    <div className='card-body'>
                        <div className='row'>
                            <div className='col-12 col-md-5me-0 me-md-1'>
                                <div className='d-md-flex'>
                                    <label
                                        style={{ fontSize: '14px', minWidth: "90px" }}
                                        className='col-form-label'>{location.state?.lbl_role_name ? location.state.lbl_role_name : "Role Name"}</label>

                                    <div>
                                        <input
                                            className={errors.roleName ?
                                                'is-invalid form-control form-control-sm d-block'
                                                : 'form-control form-control-sm'}
                                            type='text'
                                            placeholder='Enter role name'
                                            {...register("roleName")}
                                        />

                                        {errors.roleName
                                            && <span className='text-danger err-msg'>{errors.roleName.message}</span>}
                                    </div>
                                </div>




                            </div>

                            <div className="table-responsive py-3" style={{ overflowX: 'unset' }}>
                                <table className="table table-bordered">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">{location.state?.lbl_module_name ? location.state.lbl_module_name : "Module Name"}</th>
                                            <th className="text-center" scope="col">{location.state?.lbl_view ? location.state.lbl_view : "View"}</th>
                                            <th className="text-center" scope="col">{location.state?.lbl_read ? location.state.lbl_read : "Read"}</th>
                                            <th className="text-center" scope="col">{location.state?.lbl_write ? location.state.lbl_write : "Write"}</th>
                                            <th className="text-center" scope="col">{location.state?.lbl_delete ? location.state.lbl_delete : "Delete"}</th>
                                            <th className="text-center" scope="col">{location.state?.lbl_report ? location.state.lbl_report : "Report"}</th>
                                            <th className="text-center" scope="col">{"Action"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fields.map(({ id }, index) => (
                                            <tr key={id} className="f-18">
                                                <td>
                                                    <Controller
                                                        name={`items[${index}].module`}
                                                        control={control}
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <Typeahead
                                                                size="sm"
                                                                id="basic-typeahead"
                                                                labelKey="module_name"
                                                                multiple={false}
                                                                options={moduList.data}
                                                                isLoading={moduList.loading}
                                                                placeholder="Select"
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                selected={value}
                                                                isInvalid={errors.items?.[index]?.module ? true : false}
                                                            />
                                                        )}
                                                    />
                                                    {errors.items?.[index]?.module
                                                        && <span className='text-danger err-msg'>{errors.items?.[index]?.module.message}</span>}

                                                    {index + 1 == fields.length ?
                                                        <div>
                                                            <button
                                                                style={{ fontSize: "16px" }}
                                                                className="btn btn-sm button-primary px-2 mt-2"
                                                                onClick={() => append({})}
                                                            >
                                                                <FontAwesomeIcon
                                                                    style={{ marginRight: "5px" }}
                                                                    icon={faAdd}
                                                                    size="sm"
                                                                /> {location.state?.lbl_add ? location.state.lbl_add : "Add"}
                                                            </button>
                                                        </div>
                                                        : null}
                                                </td>

                                                <td style={{ verticalAlign: 'top' }}>
                                                    <div className="form-check d-flex justify-content-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="flexCheckDefault"
                                                            {...register(`items[${index}].view`)}
                                                            onChange={(e) => {
                                                                // Set "read" checkbox based on "view" checkbox
                                                                
                                                                setValue(`items[${index}].read`, e.target.checked);
                                                            }}
                                                        />

                                                    </div>
                                                </td>

                                                <td style={{ verticalAlign: 'top' }}>
                                                    <div className="form-check d-flex justify-content-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="flexCheckDefault"
                                                            {...register(`items[${index}].read`)}
                                                        />

                                                    </div>
                                                </td>
                                                
                                                <td style={{ verticalAlign: 'top' }}>
                                                    <div className="form-check d-flex justify-content-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="flexCheckDefault"
                                                            {...register(`items[${index}].write`)}
                                                        />

                                                    </div>
                                                </td>
                                               
                                                <td style={{ verticalAlign: 'top' }}>
                                                    <div className="form-check d-flex justify-content-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="flexCheckDefault"
                                                            {...register(`items[${index}].delete`)}
                                                        />

                                                    </div>
                                                </td>
                                                
                                                <td style={{ verticalAlign: 'top', }}>
                                                    <div className="form-check d-flex justify-content-center">
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            value=""
                                                            id="flexCheckDefault"
                                                            {...register(`items[${index}].report`)}
                                                        />
                                                    </div>
                                                </td>


                                                <td style={{ width: "70px", textAlign: 'center', verticalAlign: "top" }}>
                                                    <OverlayTrigger
                                                        placement="top"
                                                        overlay={<Tooltip id={"tooltip" + index}>{location.state?.lbl_delete_module ? location.state.lbl_delete_module : "Delete Module"}</Tooltip>}
                                                    >
                                                        <div
                                                            className="deleteIcon cursor_pointer"
                                                            onClick={index > 0 ? () => remove(index) : null}
                                                        >
                                                            <Trash2 color="#FFFFFF" size={18} />
                                                        </div>
                                                    </OverlayTrigger>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>


                            </div>

                        </div>
                    </div>

                    <div className='card-footer py-3'>
                        <button className="btn btn-yellow px-4 float-end" onClick={handleSubmit(onSubmit)}>
                            {location.state?.lbl_save ? location.state.lbl_save : "Save"}
                        </button>
                    </div>
                </div>
            </div>

        </React.Fragment >
    )
}

export default CreateRole
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { update_gst_slab } from "../../../api";

const schema = yup
  .object()
  .shape({
    tax_name: yup
      .string()
      .required("Tax name is required!"),
    tax_type: yup
      .string()
      .required("Tax type is required!"),
    rate: yup
      .string()
      .required("Rate is required!")
      .matches(/^[0-9]+(\.[0-9]+)?$/, 'Enter valid rate')
      .test('is-between-1-and-100', 'Rate must be between 1 and 100', value => {
        if (!value) return true; // Let Yup.string().required() handle the required validation
        const numericValue = parseFloat(value);
        return numericValue >= 1 && numericValue <= 100;
      }),
  })
  .required();

function EditGSTSlab(props) {
    const [visible, setVisible] = useState(false);

    const { register, handleSubmit, formState, control, reset, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        }); // initialise the hook
    
        let { errors } = formState;
    
        const onSubmit = (data) => {
            
        
            if (data !== "") {
              const reqPayload = {
                uuid:props.data.uuid,
                tax_name: data.tax_name.trim(),
                tax_type: data.tax_type.trim().toUpperCase(),
                rate: parseInt(data.rate)
              };
        
              
        
              update_gst_slab(reqPayload).then(
                  (res) => {
                    
                    if (res.status === 200 || res.status === 201) {
                      toast.success("GST slab updated successfully !", {
                        autoClose: 3000,
                      });
                      setVisible(false);
                      props.fetchGST_SlabList(props.page,props.entriesPerPage);
                    }
                  },
                  (err) => {
                    
                    if (err.response.status === 400) {
                      
                      toast.error(err.response.data.message, {
                        autoClose: 2000,
                      });
                      // reset();
                    }
                  }
                )
            } else {
              errors.showMessages();
            }
          };

  return (
    <React.Fragment>
    <Tooltip id={"edit-tooltip"} place="top" />
      <div
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={props.constLabel?.lbl_edit_gst_slab ? props.constLabel.lbl_edit_gst_slab : "Edit GST Slab"} 
        className="editIcon me-2">
        <Edit2
          color="#FFFFFF"
          size={18}
          className="cursor-pointer"
          onClick={() => {
            // setUpdateForm(val)
            setVisible(true);
          }}
        />
      </div>
    <Modal show={visible} onHide={() => {setVisible(false); reset();}} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{props.constLabel?.lbl_update_gst_slab ? props.constLabel.lbl_update_gst_slab : "Update GST Slab"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
          <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  {props.constLabel?.lbl_tax_name ? props.constLabel.lbl_tax_name : "Tax Name"}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text" 
                    className={
                        errors.tax_name
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                    id="tax_name"
                    name="tax_name"
                    defaultValue={props.data?.tax_name}
                    {...register("tax_name")}
                  />
                  <span className="text-danger err-msg">
                    {errors.tax_name?.message}
                  </span>
                </div>
              </div>

              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  {props.constLabel?.lbl_tax_type ? props.constLabel.lbl_tax_type : "Tax Type"}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text" 
                    className={
                        errors.tax_type
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                    id="tax_type"
                    name="tax_type"
                    defaultValue={props.data?.tax_type}
                    {...register("tax_type")}
                  />
                  <span className="text-danger err-msg">
                    {errors.tax_type?.message}
                  </span>
                </div>
              </div>

              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  {props.constLabel?.lbl_rate ? props.constLabel.lbl_rate : "Rate"} (%)
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text" 
                    className={
                        errors.rate
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                    id="rate"
                    name="rate"
                    min={1}
                    max={100}
                    defaultValue={props.data?.rate}
                    {...register("rate")}
                  />
                  <span className="text-danger err-msg">
                    {errors.rate?.message}
                  </span>
                </div>
              </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>

  </React.Fragment>
  )
}

export default EditGSTSlab
import React, { useState, useEffect } from "react";
import { delete_party_contact, get_party_contact } from "../../../../api";
import { Tooltip } from "react-tooltip";
import { Pagination } from "react-bootstrap";
import { Edit, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { calMaxPage } from "../../../../common/Functions/CommonFunctions";

function ContactDetail(props) {
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);

  const [dataList, setDataList] = useState({
    data: [],
    loading: false,
    totalRecords: "",
  });

  useEffect(() => {
    if (props !== undefined) {
      fetch_contactDetail(page, entriesPerPage);
    }
  }, [props.data?.uuid]);

  function fetch_contactDetail(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: parseInt(pg),
      entriesPerPage: parseInt(epp),
      partyId: props.data?.uuid !== undefined ? props.data?.uuid : "",
    };

    if (payload.customer_id == "" && payload.customer_id == null) {
      return;
    }

    get_party_contact(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });
        setMaxPage(calMaxPage(res.data.total));
      },
      (err) => {
        setDataList({ ...dataList, loading: true, data: [] });
      }
    );
  }

  const delContact = (val) => {
    SweetAlert.fire({
      title: "Are you sure to delete ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const payload = {
          uuid: val.uuid,
        };
        delete_party_contact(payload)
          .then((res) => {
            toast.success("Customer contact deleted successfully !", {
              autoClose: 2000,
            });
            fetch_contactDetail(page, entriesPerPage);
          })
          .catch((err) => {
            if (err.response.status === 400) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
            }
          });
      }
    });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {dataList.loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : dataList && dataList.data?.length > 0 ? (
        <>
          <table className="table table-responsive table-sm table-bordered">
            <thead className="table-grey">
              <tr>
                <th>{"Sr. No"}</th>
                <th>{"Name"}</th>
                <th>{"Designation"}</th>
                <th>{"Mobile Number"}</th>
                <th>{"Email"}</th>
                <th>{"Action"}</th>
              </tr>
            </thead>
            <tbody>
              {dataList.data.map((val, i) => (
                <tr key={i} className="f-14">
                  <td>{i + 1}</td>
                  <td>
                    {val.firstName != "" && val.firstName != null
                      ? val.firstName
                      : "NA"}{" "}
                    {val.lastName != "" && val.lastName != null
                      ? val.lastName
                      : "NA"}
                  </td>
                  <td>
                    {val.designation != "" && val.designation != null
                      ? val.designation
                      : "NA"}
                  </td>
                  <td>
                    {val.mobileNo != "" && val.mobileNo != null
                      ? val.mobileNo
                      : "NA"}
                  </td>
                  <td>
                    {val.emailid && val.emailid != null ? val.emailid : "NA"}
                  </td>
                  <td>
                    <Tooltip id={"edit-tooltip" + i} place="top" />
                    <Edit
                      data-tooltip-id={"edit-tooltip" + i}
                      data-tooltip-content={"Edit Contact"}
                      className="menu_icon_grey cursor_pointer me-2"
                      size={18}
                      //update link
                      // onClick={() =>
                      //   navigate("/pcms/orgnization/update", {
                      //     state: val,
                      //   })
                      // }
                    />

                    <Tooltip id={"delete-tooltip" + i} place="top" />
                    <Trash2
                      data-tooltip-id={"delete-tooltip" + i}
                      data-tooltip-content={"Delete Contact"}
                      className="menu_icon_red cursor_pointer"
                      size={18}
                      onClick={() => delContact(val)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="mt-4 d-flex justify-content-between">
            <p className="mb-0">{`Showing ${parseInt(srNo) + 1} to ${
              dataList.data.length + entriesPerPage * page - entriesPerPage
            } of ${dataList.totalRecords} entries`}</p>
            <Pagination className="my-2">
              <Pagination.Prev
                disabled={page === 1 ? true : false}
                onClick={() => {
                  fetch_contactDetail(
                    page - 1,
                    entriesPerPage,
                    parseInt(srNo) - parseInt(entriesPerPage)
                  );
                  setSrNo((prevC) =>
                    page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                  );
                  setPage(page - 1);
                }}
              >
                {"Prev"}
              </Pagination.Prev>

              <Pagination.Item active>{page}</Pagination.Item>

              <Pagination.Next
                disabled={
                  page === maxPage ||
                  maxPage === 0 ||
                  entriesPerPage > dataList.data.length
                    ? true
                    : false
                }
                onClick={() => {
                  fetch_contactDetail(
                    page + 1,
                    entriesPerPage,
                    parseInt(srNo) + parseInt(entriesPerPage)
                  );
                  setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                  setPage(page + 1);
                }}
              >
                {"Next"}
              </Pagination.Next>
            </Pagination>
          </div>
        </>
      ) : (
        <div
          className="alert alert-sm alert-danger mb-0 text-center my-1"
          role="alert"
        >
          {"Customer contact list is empty"}
        </div>
      )}
    </React.Fragment>
  );
}

export default ContactDetail;

import React, { useState, useEffect, useReducer } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, OverlayTrigger, Tooltip, Nav } from "react-bootstrap";
import { Edit2, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import { format } from "date-fns";
import axios from "axios";
import { getLotNumList, deleteMachine, getMachineList, getTask, get_master_files } from "../../../api/index";
import {
    convertDateYYMMDD,
  calMaxPage,
  convertDateYYMMDDHHMM,
  convertDate
} from "../../../common/Functions/CommonFunctions";
import { machine_asset_csv } from "../../../constant";
import EditMachineAsset from "../Machine/MachineAsset/EditMachineAsset";
import ViewMachineAsset from "../Machine/MachineAsset/ViewMachineAsset";
import CreateMachine from "../Machine/MachineAsset/CreateMachine";
import EditMachine from "../Machine/MachineAsset/EditMachine";
import { useNavigate } from "react-router-dom";

function Manufacturing() {
    let navigate = useNavigate();
    const [startDate, setStartDate] = useState(getPrvMonthDateFromToday);
    const [endDate, setEndDate] = useState(new Date());
    const [view, setView] = useState("work-order");

    const [page, setPage] = useState(1);
    const [srNo, setSrNo] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [entriesPerPage, setEntriesPerPage] = useState(10);

    const [lotList, setLotList] = useState({data: [], loading: false});
    const [selLot, setSelLot] = useState({});
  
    const [machineList, setMachineList] = useState({ data: [], loading: false });
    const [selMachine, setSelMachine] = useState({});
    const [machineName, setMachineName] = useState("");
    const [machineImgs, setMachineImgs] = useState({ loading: false, data: [] });
  
    const [taskList, setTaskList] = useState({ data: [], loading: false, totalRecords: "" });

    const [filterConfig, setFilterConfig] = useState({ lot_id: "" });
  
    const { register, handleSubmit, formState, control, reset, watch } = useForm({
      mode: "onChange",
    }); // initialise the hook
  
    let { errors } = formState;
  
    useEffect(() => {
      
      
      openNav();
      // fetchMachine();
    }, []);
  
    useEffect(() => {
      const getData = setTimeout(() => {
        fetchMachine();
      }, 1000);
  
      return () => clearTimeout(getData);
    }, [machineName]);

    useEffect(() => {
        fetchLot();
      }, [filterConfig, startDate, endDate]);

    function getPrvMonthDateFromToday() {
        const today = new Date();
        const endDate = new Date(today);
        endDate.setMonth(today.getMonth() - 3);
        return endDate;
    }
  
    function fetchMachine(para1) {
      setMachineList({ ...machineList, loading: true });
      let payload = {
        machine_name: machineName.length > 0 ? machineName : null,
      };
  
      if (para1 == "deleting") {
        payload = null;
      }
      
      getMachineList(payload).then(
        (res) => {
          
          // const result = res.data.data.filter((item) => item.machineName !== null);
          setMachineList({ ...machineList, data: res.data.data, loading: false });
  
          if (Object.keys(selMachine).length > 0) {
            const result = res.data.data.filter((val) => {
              return selMachine.uuid == val.uuid
            })
            
            if (result.length > 0) {
              setSelMachine(result[0]);
            }
          }
        },
        (err) => {
          
          setMachineList({ ...machineList, data: [], loading: false });
        }
      );
    }

    function fetchLot(para1) {
        setLotList({ ...lotList, loading: true });
        // let payload = {
        //   machine_name: machineName.length > 0 ? machineName : null,
        // };

        let payload = {
            start_date: convertDateYYMMDD(new Date(startDate), "-"),
            end_date: convertDateYYMMDD(new Date(endDate), "-"),
            // start_date : "2021-01-01",
            // end_date : "2023-11-03" 
        }

        if (filterConfig.lot_id != '') {
            payload.lotId = filterConfig.lot_id;
        } else {
            delete payload.lotId
        }
    
        // if (para1 == "deleting") {
        //   payload = null;
        // }
        
        getLotNumList(payload).then(
          (res) => {
            
            // const result = res.data.data.filter((item) => item.machineName !== null);
            setLotList({ ...lotList, data: res.data.data, loading: false });
    
            if (Object.keys(selLot).length > 0) {
              const result = res.data.data.filter((val) => {
                return selLot.uuid == val.uuid
              })
              
              if (result.length > 0) {
                setSelLot(result[0]);
              }
            }
          },
          (err) => {
            
            setLotList({ ...lotList, data: [], loading: false });
          }
        );
      }

      function clearFilter() {
        setFilterConfig({
            ...filterConfig,
            lot_id: ''
        })
        setSelLot({});
        setStartDate(getPrvMonthDateFromToday)
        setEndDate(new Date());
    }
  
    // Deleting machine asset
    const delMachine = (val) => {
      
      SweetAlert.fire({
        title: "Are you sure?",
        text: "Once deleted, you will not be able to recover this record !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ok",
        cancelButtonText: "cancel",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          // Firing delete api
          const payload = {
            uuid: val.uuid,
          };
          deleteMachine(payload).then(
            (res) => {
              
              toast.success("Machine details deleted successfully !", {
                autoClose: 3000,
              });
  
              setMachineName("");
              setSelMachine({});
              fetchMachine("deleting");
              setTaskList({ ...taskList, data: [], loading: false });
            },
            (err) => {
              
              toast.error(err.response.data.message, { autoClose: 3000 });
            }
          );
        }
      });
    };
  
    function fetchMaintenanceTask(pg, epp, machine) {
      
      
      setTaskList({ ...taskList, loading: true });
  
      const payload = {
        entriesPerPage: epp,
        pageNo: pg,
        machine_id: machine && machine.uuid
      };
  
      getTask(payload).then(
        (res) => {
          
          // const result = res.data.data.filter((item) => item.machineName !== null);
          setTaskList({ ...taskList, data: res.data.data, loading: false, totalRecords: res.data.total });
        },
        (err) => {
          
          setTaskList({ ...taskList, data: [], loading: false });
        }
      );
    }
  
    const generateAndDownloadCSV = (jsonData) => {
      if (!Array.isArray(jsonData) || jsonData.length === 0) return;
  
      const csvRows = jsonData.map((data) =>
        Object.values(data)
          .map((value) => `"${value}"`)
          .join(",")
      );
  
      const csvContent = csvRows.join("\n");
  
      const blob = new Blob([csvContent], { type: "text/csv" });
  
      const url = URL.createObjectURL(blob);
  
      const a = document.createElement("a");
      a.href = url;
      a.download = "Machine_asset.csv";
  
      document.body.appendChild(a);
      a.click();
  
      document.body.removeChild(a);
    };
  
    const fetchDataAndDownloadCSV = async () => {
      const token = localStorage.getItem("access");
      try {
        const response = await axios.get(
          process.env.REACT_APP_API_URL + machine_asset_csv,
          {
            responseType: "json", // Set the responseType to ‘arraybuffer’
            headers: {
              Authorization: `Bearer ${token}`, // Add the token to the Authorization header
            },
          }
        );
        
        generateAndDownloadCSV(response.data.MachineDetails);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
  
    function fetchImage(val) {
      setMachineImgs({ ...machineImgs, loading: true, data: [] });
      get_master_files(
        {
          ref_model_id: val.machineId,
          ref_model_uuid: val.uuid,
          model_name: "Machine",
          document_type: "image"
        })
        .then((res) => {
          
          setMachineImgs({ ...machineImgs, loading: false, data: res.data.data });
        }, (err) => {
          
          setMachineImgs({ ...machineImgs, loading: false, data: [] });
        })
    }
  
    function openNav() {
      document.getElementById("mySidebar").style.width = "320px";
      document.getElementById("root_div_main").style.marginLeft = "320px";
      setTimeout(() => {
        document.getElementById("mySidebar").style.overflowX = "visible";
      }, [200]);
    }
  
    function closeNav() {
      document.getElementById("mySidebar").style.width = "0";
      document.getElementById("root_div_main").style.marginLeft = "0";
  
      document.getElementById("mySidebar").style.overflowX = "hidden";
    }
  
  return (
    <React.Fragment>
    <ToastContainer />
    <div className="min-vh-100" id="root_div_main">
      <div id="mySidebar" className="supplier_customsidebar">
        <div className="d-flex justify-content-between m-2">
          <p className="roboto-bold pt-2" style={{ fontSize: "24px" }}>
            Lots
          </p>
          <div>
            <a className="custom-close-btn" onClick={() => closeNav()}>
              ×
            </a>
          </div>
        </div>

        <div className="row pb-2 px-2">
            <div className="fromDate col-12 col-md-6">
                <DatePicker
                    id="fromDate"
                    className='form-control form-control-sm'
                    selected={startDate}
                    showYearDropdown
                    scrollableMonthYearDropdown
                    dateFormat="dd/MM/yyyy"
                    placeholderText='From Date'
                    onChange={(date) => setStartDate(date)} 
                />
            </div>
            <div className="toDate col-12 col-md-6">
                <DatePicker
                    id="toDate"
                    className='form-control form-control-sm'
                    showYearDropdown
                    scrollableMonthYearDropdown
                    selected={endDate}
                    dateFormat="dd/MM/yyyy"
                    placeholderText='To Date'
                    onChange={(date) => setEndDate(date)} 
                />
            </div>
        </div>

        <div className="row pb-2 px-2">
            <div className="col-12 col-md-10">
          <input
            type="text"
            className="form-control form-control-sm"
            placeholder="Search by lot no"
            value={filterConfig.lot_id}
            onChange={(e) => {
                if (e.target.value != '') {
                    
                    setFilterConfig({ ...filterConfig, lot_id: e.target.value });
                } else {
                    setFilterConfig({ ...filterConfig, lot_id: "" });
                }
            }}
          />
          </div>
          <div className="col-12 col-md-2">
          <button
                onClick={() => clearFilter()}
                className="btn btn-sm btn-yellow "
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faRefresh}
                  size="sm"
                />{" "}
              </button>
          </div>
        </div>

        <div className="content" style={{ marginTop: "0px" }}>
          {lotList.loading ? (
            <div className="d-flex justify-content-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            lotList.data.map((val, ind) => (
              <div className="supplier_box" key={ind}>
                <div className="form-check">
                  <input
                    onChange={() => {
                      
                      setSelLot(val);
                    }}
                    checked={selLot && selLot.uuid === val.uuid}
                    className="form-check-input cursor_pointer"
                    type="checkbox"
                    value=""
                    id={"flexCheckDefault" + ind}
                  />
                  <label
                    className="form-check-label cursor_pointer"
                    htmlFor={"flexCheckDefault" + ind}
                  >
                    {val.lotId}
                  </label>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
      <div className="machine_view">
        <div className="row">
          <div className="col-12 m-0">
            <button
              onClick={() => openNav()}
              className="btn btn-sm btn-grey my-2"
            >
              View Lots
            </button>
          </div>

          {Object.keys(selLot).length > 0 ? (
            <div className="col-12 mt-3">
              <div className="card border-0">
                <div className="card-body p-4">
                  <div className="d-flex justify-content-between pb-3 position-relative">
                    {/* <ArrowRightCircle onClick={navigate('')} /> */}
                    <p className="roboto-bold" style={{ fontSize: "24px" }}>
                      Lot Details
                    </p>
                  </div>
                  {selLot?.lotId != "" ? (
                    <div className="row ">
                      <div className="col-12 col-md-3">
                        <label className="lbl-style roboto-bold">
                          Lot Start No
                        </label>
                        <p className="mb-0">{selLot ? selLot.lotstartnum : "N/A"}</p>
                      </div>
                      <div className=" col-12 col-md-3">
                        <label className="lbl-style roboto-bold">Lot End No</label>
                        <p className="mb-0">{selLot ? selLot.lotendnum : "N/A"}</p>
                      </div>
                      <div className="col-12 col-md-3">
                        <label className="lbl-style roboto-bold">
                          OEM No.
                        </label>
                        <p className="mb-0">{selLot ? selLot.oem : "N/A"}</p>
                      </div>
                      <div className="col-12 col-md-3">
                        <label className="lbl-style roboto-bold">
                          Lot Qty
                        </label>
                        <p className="mb-0">{selLot ? selLot.qty : "N/A"}</p>
                      </div>
                      <div className="col-12 col-md-3 mt-3">
                        <label className="lbl-style roboto-bold">Product Family</label>
                        <p className="mb-0">{selLot ? selLot.product_familyname : "N/A"}</p>
                      </div>
                      <div className="col-12 col-md-3 mt-3">
                        <label className="lbl-style roboto-bold">
                          Product Name
                        </label>
                        <p className="mb-0">{selLot ? selLot.productname : "N/A"}</p>
                      </div>
                    </div>
                  ) : (
                    <div
                      className="alert alert-danger mb-0 text-center my-4"
                      role="alert"
                    >
                      {"Please select lot !"}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ) : null}

          <div className="col-12 mt-4">
          
          <Nav variant="underline">
                      <Nav.Item className="pr-2">
                        <Nav.Link
                          active={view == "work-order"}
                          onClick={() => setView("work-order")}
                        >
                          Work Order
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="px-2">
                        <Nav.Link
                          active={view == "sales-order"}
                          onClick={() => setView("sales-order")}
                        >
                          Sales Order
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="px-2">
                        <Nav.Link
                          active={view == "dispatch"}
                          onClick={() => setView("dispatch")}
                        >
                          Dispatch
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item className="px-2">
                        <Nav.Link
                          active={view == "documents"}
                          onClick={() => setView("documents")}
                        >
                          Document
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
          </div>

          <div
                    style={{
                      borderTop: "2px solid #c6c6c6",
                      marginTop: "-1px",
                      background: "white",
                    }}
                  >
                    {view == "work-order" ? (
                      // <Overview selectedSupp={selectedSupp} />
                      <>Work order</>
                    ) : view == "sales-order" ? (
                      // <SupplierQuoteList selectedSupp={selectedSupp} />
                      <>Sales order</>
                    ) : view == "dispatch" ? (
                      // <SupplierPOList selectedSupp={selectedSupp} />
                      <>Dispatch</>
                    ) : view == "documents" ? (
                      // <SupplierPOList selectedSupp={selectedSupp} />
                      <>Documents</>
                    ) : null}
                  </div>

          {/* <div className="col-12 mt-4">
            <div className="d-flex justify-content-between align-items-center">
              <p className="roboto-bold" style={{ fontSize: "24px" }}>
                Operation Data
              </p>
            </div>

            <div
              className="row "
              style={{ paddingTop: "10px", paddingBottom: "25px" }}
            >
              <div className="d-flex justify-content-between align-items-center">
              {Object.keys(selLot).length > 0 ? (
                <div className="col-12 col-md-8">
                  <p className="mb-0">
                    Show
                    <select
                      className="mx-1"
                      defaultValue={"10"}
                      onChange={(e) => {
                        setEntriesPerPage(e.target.value);
                        fetchMaintenanceTask(page, e.target.value, selMachine);
                      }}
                    >
                      <option value={"10"}>10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    Entries
                  </p>
                </div>
              ) : null }
                
              </div>
              <div className="col-12 mt-3">
                {taskList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : taskList.data !== undefined &&
                  taskList.data.length > 0 ? (
                  <>
                    <div
                      className="table-responsive"
                      style={{ minHeight: "60vh" }}
                    >
                      <table className="table table-borderless">
                        <thead>
                          <tr className="">
                            <th>Sr.No</th>
                            <th>Task Name</th>
                            <th>Start Date</th>
                            <th>End Date</th>
                            <th>Service Cost</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {taskList.data.map((val, index) => (
                            <tr key={index} className="text-center">
                              <td width="5%">{index + 1}</td>
                              <td>{val.task_name}</td>
                              <td>
                                {convertDate(new Date(val.start_date), '-')}
                              </td>
                              <td>
                                {convertDate(new Date(val.end_date), '-')}
                              </td>
                              <td>{val.service_cost}</td>
                              <td>{val.status}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-4 d-flex justify-content-between">
                      <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                        } to ${taskList.data.length +
                        entriesPerPage * page -
                        entriesPerPage
                        } of ${taskList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchMaintenanceTask(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1 ? 0 : prevC - entriesPerPage
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                              maxPage === 0 ||
                              entriesPerPage > taskList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchMaintenanceTask(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center my-4"
                    role="alert"
                  >
                    {"No operation found !"}
                  </div>
                )}
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  </React.Fragment>
  )
}

export default Manufacturing
import React, { useState, useEffect } from "react";
import logo from "../../../assets/images/proditm.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { industry_type } from "../../../constant";
import axios from "axios";

const schema = yup
  .object()
  .shape({
    gst_no: yup
      .string()
      .required("Please enter gst no !")
      .matches(
        /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})$/,
        "Enter valid gst no !"
      ),
    company_name: yup.string().required("Company name is required!"),
    org_type: yup.string().required("Organization type is required!"),
    industry: yup.array().min(1, "Please select industry type").required("Please select industry type!"),
    address: yup.string().required("Address is required!"),
  })
  .required();

function RegisterClient(props) {
  let navigate = useNavigate();

  const { register, handleSubmit, formState, setValue, control} = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { errors } = formState;
  const [orgTypeList, setOrgTypeList] = useState([
    {id: 1, name: "Partnership"},
    {id: 2, name: "Sole Proprietorship"},
    {id: 3, name: "Limited Liability Partnership"},
    {id: 4, name: "Private Limited Companies"},
    {id: 5, name: "Public Limited Companies"},
    {id: 6, name: "One-Person Companies"},
    {id: 7, name: "Section 8 Company"},
    {id: 8, name: "Joint-Venture Company"},
    {id: 9, name: "Non-Government Organization (NGO)"}
  ]);

  const [industryType, setIndustryType] = useState({
    data: [],
    loading: false,
  });

  useEffect(() => {
    fetchIndustryType();
  }, [])
  

  useEffect(() => {
    // console.log("props", props);
    if(Object.keys(props.main_obj.gst_detail).length !== 0){
      // if(props.main_obj?.gst_detail?.ctb !== "Private Limited Company"){
      //   setValue("company_name", props.main_obj?.gst_detail?.tradeNam || props.main_obj?.gst_detail?.lgnm);
      // } else {
      //   setValue("company_name", props.main_obj?.gst_detail?.lgnm);
      // }
      if(props.main_obj?.name_choice === "GstTradeName"){
        setValue("company_name", props.main_obj?.gst_detail?.tradeNam);
      } else {
        setValue("company_name", props.main_obj?.gst_detail?.lgnm);
      }
      setValue("gst_no", props.main_obj?.gst_detail?.gstin || "");
      // setValue("reg_type", props.main_obj?.gst_detail?.dty || "");
      if(Object.keys(props.main_obj?.company_detail).length > 0){
        setValue("industry", (props.main_obj?.company_detail?.industry !== "" && props.main_obj?.company_detail?.industry !== null) ? [{type_name: props.main_obj?.company_detail?.industry}] : []);
      } else {
        setValue("industry", []);
      }
      setValue("org_type", props.main_obj?.gst_detail?.ctb || "");
      if(props.main_obj?.gst_detail?.adadr.length > 0){
        setValue("address", props.main_obj?.gst_detail?.adadr[0].addr.bno +" "+ props.main_obj?.gst_detail?.adadr[0].addr.bnm +" "+ props.main_obj?.gst_detail?.adadr[0].addr.st +" "+ props.main_obj?.gst_detail?.adadr[0].addr.loc +" "+ props.main_obj?.gst_detail?.adadr[0].addr.stcd +" "+ props.main_obj?.gst_detail?.adadr[0].addr.pncd || "", {shouldValidate: true});
      } else {
        setValue("address", props.main_obj?.gst_detail?.pradr?.addr?.bno +" "+ props.main_obj?.gst_detail?.pradr?.addr?.bnm +" "+ props.main_obj?.gst_detail?.pradr?.addr?.st +" "+ props.main_obj?.gst_detail?.pradr?.addr?.loc +" "+ props.main_obj?.gst_detail?.pradr?.addr?.stcd +" "+ props.main_obj?.gst_detail?.pradr?.addr?.pncd|| "", {shouldValidate: true});
      }
    }
  }, [props.main_obj]);

  function fetchIndustryType() {
    setIndustryType({ loading: true, data: [] });

    let base_url = process.env.REACT_APP_COGNITO_AUTH_URL;
    const url = `${base_url}/${industry_type}`;

    axios.get(url).then(
      (res) => {
        setIndustryType({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setIndustryType({ loading: false, data: [] });
      }
    );
  }
  
  function onSubmit(data){
    const orgData = {
      company_name : data.company_name,
      industry: data.industry?.[0]?.type_name,
      company_type : data.org_type,
      organization : data.company_name,
      gst_no : data.gst_no,
      address :  data.address,
      gst_type : props.main_obj?.gst_detail?.dty
  }
    // console.log("orgData", orgData);
    props.setMain_obj({...props.main_obj, company_detail : orgData});
    props.nextStep();
  };

  return (
    <>
      <ToastContainer />
          <div className="col-12 col-md-6 pt-5 align-items-center d-flex flex-column pb-2 mt-30" id="mt-20">
            <div className="text-center">
              <img
                src={logo}
                className="img-fluid mx-auto"
                alt="Logo"
                style={{ height: "70px", width: "150px" }} // Adjust image size
              />
              <h3 className="bold w-100 mt-3">Welcome to PRODi !</h3>
              <h4 className="w-100 my-0 mt-2">Experience the seamless</h4>
              <h4 className="w-100">onboarding process</h4>

              <p className="bold w-100 my-0 mt-3">Please confirm your organization details.</p>
            </div>

              <div className="row col-10 mt-2">
                <div className="col-12 col-md-6">
                  <div className="form-group mt-md-0 mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label body3 col-md-12 roboto-bold"
                    >
                      {"Company Name"}
                      <span>
                        <i style={{ color: "red" }}> *</i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className={
                        errors.company_name
                          ? "form-control form-control-sm rounded is-invalid"
                          : "form-control form-control-sm rounded"
                      }
                      id="company_name"
                      name="company_name"
                      placeholder="Enter company name"
                      {...register("company_name")}
                    />
                    <span className="text-danger err-msg">
                      {errors.company_name?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="form-group mt-md-0 mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label body3 col-md-12 roboto-bold"
                    >
                      {"GST Number"}
                      <span>
                        <i style={{ color: "red" }}> *</i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className={
                        errors.gst_no
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm "
                      }
                      id="gst_no"
                      name="gst_no"
                      placeholder="Enter gst no"
                      {...register("gst_no")}
                      disabled={props.main_obj?.reg_manual == true ? false : true}
                    />
                    <span className="text-danger err-msg">
                      {errors.gst_no?.message}
                    </span>
                  </div>
                </div>
                {/* <div className="col-12 col-md-6 mt-2">
                  <div className="form-group mt-md-0 mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label body3 col-md-12 roboto-bold"
                    >
                      {"Registration Type"}
                      <span>
                        <i style={{ color: "red" }}> *</i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className={
                        errors.reg_type
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm"
                      }
                      id="reg_type"
                      name="reg_type"
                      placeholder="Enter registration type"
                      {...register("reg_type")}
                    />
                    <span className="text-danger err-msg">
                      {errors.reg_type?.message}
                    </span>
                  </div>
                </div> */}
                <div className="col-12 col-md-6 mt-2">
                  <div className="form-group mt-md-0 mt-3 typeahead-container">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label body3 col-md-12 roboto-bold"
                    >
                      {"Industry Type"}
                      <span>
                        <i style={{ color: "red" }}> *</i>
                      </span>
                    </label>
                    <Controller
                      name="industry"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="type_name"
                          multiple={false}
                          size="sm"
                          options={industryType.data}
                          isLoading={industryType.loading}
                          placeholder="Select industry type "
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.industry ? true : false}
                        />
                      )}
                    />
                    {!industryType.loading && !errors.industry && <span className="arrow-icon"></span>}
                    <span className="text-danger err-msg">
                      {errors.industry?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-2">
                  <div className="form-group mt-md-0 mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label body3 col-md-12 roboto-bold"
                    >
                      {"Organization Type"}
                      <span>
                        <i style={{ color: "red" }}> *</i>
                      </span>
                    </label>
                    <input
                      type="text"
                      // className={
                      //   errors.org_type
                      //     ? "form-control form-control-sm is-invalid"
                      //     : "form-control form-control-sm"
                      // }
                      className={"form-control form-control-sm"}
                      id="org_type"
                      name="org_type"
                      placeholder="Enter organization type"
                      {...register("org_type")}
                    />
                    <span className="text-danger err-msg">
                      {errors.org_type?.message}
                    </span>
                  </div>
                </div>
                
                <div className="col-12 col-md-12 mt-2">
                  <div className="form-group mt-md-0 mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label body3 col-md-12 roboto-bold"
                    >
                      {"Registration Address"}
                      <span>
                        <i style={{ color: "red" }}> *</i>
                      </span>
                    </label>
                    <input
                      type="text"
                      className={
                        errors.address
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm "
                      }
                      id="address"
                      name="address"
                      placeholder="Enter address"
                      {...register("address")}
                    />
                    <span className="text-danger err-msg">
                      {errors.address?.message}
                    </span>
                  </div>
                </div>
                

              </div>
              <div className="wt-50 d-flex justify-content-between">
                <button
                  className="btn button-secondary w-100 mt-4 roboto-bold me-5"
                  type="submit"
                  onClick={() => props.prevStep()}
                >
                  Back{" "}
                </button>
                <button
                  className="btn button-primary w-100 mt-4 roboto-bold"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                >
                  Proceed{" "}
                </button>
              </div>
          </div>
    </>
  );
}

export default RegisterClient;

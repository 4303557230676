import React, { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { saveCustomer, get_gst_treatment } from "../../../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";

const schema = yup
  .object()
  .shape({
    gstTreat: yup
      .array()
      .min(1, "Please select gst registration type !")
      .required("Please select gst registration type !"),
    TIN: yup
      .string()
      .matches(/^[A-Z0-9]*$/, "Enter valid TIN no!")
      .notRequired(),
    TAN: yup
      .string()
      .matches(/^[A-Z0-9]*$/, "Enter valid TAN no!")
      .notRequired(),
    CSTNO: yup
      .string()
      .matches(/^[A-Z0-9]*$/, "Enter valid CST no!")
      .notRequired(),
    VATNO: yup
      .string()
      .matches(/^[A-Z0-9]*$/, "Enter valid VAT no!")
      .notRequired(),
    // gst: yup.
    //   string()
    //   .matches(/^[A-Z0-9]*$/, 'Enter valid GST no!')
    //   .notRequired(),
    gst: yup.string().when(["gstTreat"], (gstTreat, schema) => {
      if (gstTreat !== undefined && gstTreat.length > 0) {
        const gst_typeSelected = gstTreat[0].name == "Regular";

        // If 'gst_tye' is in the category, make 'GST number' mandatory
        return gst_typeSelected
          ? schema
              .required("Please enter GST number !")
              .max(15, "GST number must be 15 characters only !")
              .matches(
                /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})$/,
                "Enter valid GST number!"
              )
          : schema;
      } else {
        return schema;
      }
    }),

    PANNO: yup.string().when(["gstTreat"], (gstTreat, schema) => {
      if (gstTreat !== undefined && gstTreat.length > 0) {
        const gst_typeSelected = gstTreat[0].name == "Regular";

        // If 'gst_tye' is in the category, make 'GST number' mandatory
        return gst_typeSelected
          ? schema
            .required("Please enter PAN number !")
            .min(10, "PAN number must be 10 characters only !")
            .max(10, "PAN number must be 10 characters only !")
            .matches(
              /^([A-Z]{5}[0-9]{4}[A-Z]{1})$/,
              "Enter a valid PAN number!"
            )
          : schema;
      } else {
        return schema;
      }
    }),

      // PANNO: yup
      //   .string()
      //   .required("Please enter PAN number !")
      //   .min(10, "PAN number must be 10 characters only !")
      //   .max(10, "PAN number must be 10 characters only !")
      //   .matches(
      //     /^([A-Z]{5}[0-9]{4}[A-Z]{1})$/,
      //     "Enter a valid PAN number!"
      //   ),
    })
    .required();

function NewBillingDetail(props) {
  let navigate = useNavigate();
  const { register, handleSubmit, formState, reset, control, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook
  const { TIN, TAN, VATNO, CSTNO, gst, serviceTaxNo, gst_treatment, PANNO } =
    props.main_obj;
  const [gst_treat, setGST_Treat] = useState({ loading: false, data: [] });

  const [constLabel, setConstLabel] = useState({});

  let { errors } = formState;

  let gstType = watch("gstTreat");

  useEffect(() => {
    setConstLabel(getLabels("Party Master", "Customer"));
    fetchGSTTreatment();
  }, []);

  function fetchGSTTreatment() {
    setGST_Treat({ ...gst_treat, state: true, data: [] });

    get_gst_treatment().then(
      (res) => {

        setGST_Treat({
          ...gst_treat,
          state: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setGST_Treat({ ...gst_treat, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  const onSubmit = (data) => {
    // const basic = JSON.parse(localStorage.getItem("basic"));
    // const party_contact = JSON.parse(localStorage.getItem("contacts"));
    // const billing = JSON.parse(localStorage.getItem("billing"));
    // const shipping = JSON.parse(localStorage.getItem("shipping"));

    if (data !== "" || data.length !== 0) {
      const reqPayload = {
        TIN: data.TIN,
        TAN: data.TAN,
        VATNO: data.VATNO,
        CSTNO: data.CSTNO,
        PANNO: data.PANNO ?? null,
        gst: data.gst,
        serviceTaxNo: data.serviceTaxNo,
        gst_treatment: data.gstTreat[0]?.name,
        createdBy: 1,
        modifiedBy: 1,
      };

      // console.log("props.main_obj", props.main_obj);

      props.setMain_obj({ ...props.main_obj, ...reqPayload });
      props.nextStep();
    } else {
      errors.showMessages();
    }
  };

  return (
    <>
    <ToastContainer />
      <h4 className="mb-4 roboto-bold mt-2">
        {constLabel?.lbl_tax_detail ? constLabel?.lbl_tax_detail : "Tax Detail"}
      </h4>
      <div className="row">
        <div className="col-md-6">
          <div className="form-group row mt-2">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold mt-2"
            >
              {constLabel?.lbl_gst_treatment
                ? constLabel?.lbl_gst_treatment
                : "GST Registration Type"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <div className="col-md-8">
              <Controller
                name="gstTreat"
                control={control}
                defaultValue={
                  gst_treatment !== undefined && gst_treatment.length > 0
                    ? [{ name: gst_treatment }]
                    : [{ name: "Regular" }]
                }
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    multiple={false}
                    options={gst_treat.data !== undefined ? gst_treat.data : []}
                    isLoading={gst_treat.state}
                    placeholder="Select gst registration type..."
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    defaultSelected={
                      gst_treatment !== undefined && gst_treatment.length > 0
                        ? [{ name: gst_treatment }]
                        : [{ name: "Regular" }]
                    }
                    isInvalid={errors.gstTreat ? true : false}
                  />
                )}
              />
              <span className="text-danger">{errors.gstTreat?.message}</span>
            </div>
          </div>
        </div>
      </div>
      <hr className="my-3 mx-2" style={{ width: "98%" }} />
      <h4 className="mb-4 roboto-bold mt-2">
        {constLabel?.lbl_gst_detail ? constLabel?.lbl_gst_detail : "GST Detail"}
      </h4>
      <div className="row mt-2">

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
          <label
              style={{ fontSize: "14px" }}
              className="col-form-control roboto-bold"
            >
              {constLabel?.lbl_tin ? constLabel?.lbl_tin : "TIN"}
            </label>
              <input
                type="text"
                className="form-control mt-2"
                maxLength={11}
                id="TIN"
                name="TIN"
                placeholder="Enter TIN"
                defaultValue={
                  (TIN !== undefined && TIN && TIN.length) > 0 ? TIN : ""
                }
                {...register("TIN")}
              />
              <span className="text-danger err-msg">{errors.TIN?.message}</span>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
          <label
              style={{ fontSize: "14px" }}
              className="col-form-control roboto-bold"
            >
              {constLabel?.lbl_gst_no ? constLabel.lbl_gst_no : "GST No"}
              { gstType && gstType?.[0]?.name == "Regular" ? <span className="text-danger" > *</span> : null }
            </label>
              <input
                type="text"
                className={
                  errors.gst && (gstType && gstType?.[0]?.name == "Regular")
                    ? "form-control is-invalid mt-2"
                    : "form-control mt-2"
                }
                id="gst"
                maxLength={15}
                name="gst"
                placeholder="Enter GST number"
                defaultValue={
                  gst !== undefined && gst && gst.length > 0 ? gst : ""
                }
                {...register("gst")}
              />
              {errors.gst && gstType && gstType?.[0]?.name == "Regular" ? 
                <span className="text-danger err-msg">
                  {errors.gst?.message}
                </span> : null
              }
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
          <label
              style={{ fontSize: "14px" }}
              className="col-form-control roboto-bold"
            >
              {constLabel?.lbl_service_tax_no
                ? constLabel.lbl_service_tax_no
                : "Service Tax No"}
            </label>
              <input
                type="text"
                className="form-control mt-2"
                id="serviceTaxNo"
                name="serviceTaxNo"
                maxLength={15}
                placeholder="Enter service tax no."
                defaultValue={
                  serviceTaxNo !== undefined &&
                  (serviceTaxNo && serviceTaxNo.length) > 0
                    ? serviceTaxNo
                    : ""
                }
                {...register("serviceTaxNo")}
              />
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
          <label
              style={{ fontSize: "14px" }}
              className="col-form-control roboto-bold"
            >
              {constLabel?.lbl_cst_no ? constLabel.lbl_cst_no : "CST No"}
            </label>
              <input
                type="text"
                className="form-control mt-2"
                id="CSTNO"
                maxLength={11}
                name="CSTNO"
                placeholder="Enter CST no."
                defaultValue={
                  CSTNO !== undefined && CSTNO && CSTNO.length > 0 ? CSTNO : ""
                }
                {...register("CSTNO")}
              />
              <span className="text-danger err-msg">
                {errors.CSTNO?.message}
              </span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
          <label
              style={{ fontSize: "14px" }}
              className="col-form-control roboto-bold"
            >
              {constLabel?.lbl_vat ? constLabel.lbl_vat : "VAT"}
            </label>
              <input
                type="text"
                className="form-control mt-2"
                id="VATNO"
                maxLength={15}
                name="VATNO"
                placeholder="Enter VAT"
                defaultValue={
                  VATNO !== undefined && VATNO && VATNO.length > 0 ? VATNO : ""
                }
                {...register("VATNO")}
              />
              <span className="text-danger err-msg">
                {errors.VATNO?.message}
              </span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
          <label
              style={{ fontSize: "14px" }}
              className="col-form-control roboto-bold"
            >
              {constLabel?.lbl_pan ? constLabel.lbl_pan : "PAN"}
              { gstType && gstType?.[0]?.name == "Regular" ? <span className="text-danger" > *</span> : null }
            </label>
              <input
                type="text"
                className={
                  errors.PANNO && (gstType && gstType?.[0]?.name == "Regular")
                    ? "form-control is-invalid mt-2"
                    : "form-control mt-2"
                }
                id="PANNO"
                maxLength={10}
                name="PANNO"
                placeholder="Enter PAN number"
                defaultValue={
                  PANNO !== undefined && PANNO !== null && PANNO.length > 0
                    ? PANNO
                    : ""
                }
                {...register("PANNO")}
              />
              {errors.PANNO && gstType && gstType?.[0]?.name == "Regular" ? 
                <span className="text-danger err-msg">
                  {errors.PANNO?.message}
                </span> : null
              }
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
          <label
              style={{ fontSize: "14px" }}
              className="col-form-control roboto-bold"
            >
              {constLabel?.lbl_tan ? constLabel.lbl_tan : "TAN"}
            </label>
              <input
                type="text"
                className="form-control mt-2"
                maxLength={10}
                id="TAN"
                name="TAN"
                placeholder="Enter TAN"
                defaultValue={
                  TAN !== undefined && TAN && TAN.length > 0 ? TAN : ""
                }
                {...register("TAN")}
              />
              <span className="text-danger err-msg">{errors.TAN?.message}</span>
          </div>
        </div>
      </div>

      <hr className="my-3 mx-2" style={{ width: "98%" }} />
      <div className="row  mt-0 ">
        <div className="d-md-flex justify-content-end">
          <button
            className="btn btn-grey px-4 me-2"
            onClick={() => props.previousStep()}
          >
            {constLabel?.lbl_previous ? constLabel?.lbl_previous : "Previous"}
          </button>
          <button
            className="btn button-primary px-4 me-2"
            onClick={handleSubmit(onSubmit)}
          >
            {constLabel?.lbl_save_continue
              ? constLabel?.lbl_save_continue
              : "Save & Continue"}
          </button>
        </div>
      </div>
    </>
  );
}

export default NewBillingDetail;

import React, { useState, useEffect } from "react";
import { delete_job_card } from "../../api";
import { Modal } from "react-bootstrap";
import SweetAlert from "sweetalert2";
import { Trash2, Edit2 } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { getLabels, getShortReadableId } from "../../common/Functions/CommonFunctions";

function ViewJobCard(props) {
  let navigate = useNavigate();
  const [constLabel, setConstLabel] = useState({});

  useEffect(() => {
    setConstLabel(getLabels("Production", "Job Card Board"));
  }, [])
  

  function formatDateTime(datetime) {
    const dateObject = new Date(datetime);

    const formattedDate = dateObject
      .toLocaleDateString("en-GB", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
      .replace(/\//g, "-");

    const formattedTime = dateObject.toLocaleTimeString("en-US", {
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    });

    return { formattedDate, formattedTime };
  }

  const delJobCard = (val) => {
    // console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure to delete ? \n" + val.job_card_no,
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delete_job_card({ uuid: val.uuid }).then(
          (res) => {
            // console.log("res", res);
            toast.success("Job card deleted successfully", {
              autoClose: 2000,
            });
            
            setTimeout(() => {
              props.setVisible(false)
              props.fetchJobCardList(val?.work_order?.uuid);
              navigate("/pcms/job-card");
            }, [2300]);
            
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  function adjustDateTime(database_time) {
    // Parse the input time string to a Date object
    let date = new Date(database_time);
    // Subtract 5 hours and 30 minutes (330 minutes) from the date
    date.setMinutes(date.getMinutes() - 330);
    // Format the date without milliseconds and the "Z" indicating UTC
    return date;
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <Modal
        show={props.visible}
        onHide={() => props.setVisible(false)}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{getShortReadableId(props.selItem?.job_card_no)}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex">
            <div className="col-md-3">
              <label className="roboto-bold">
                {constLabel?.lbl_work_order_no
                  ? constLabel?.lbl_work_order_no
                  : "Work Order No"}
              </label>
              <p className="roboto-light">
                {getShortReadableId(props.selItem?.workorderDetails?.work_order_no)}
              </p>
            </div>
            <div className="col-md-3">
              <label className="roboto-bold">
                {constLabel?.lbl_product_name
                  ? constLabel?.lbl_product_name
                  : "Product Name"}
              </label>
              <p className="roboto-light">
                {props.selItem.productDetail?.productName}
              </p>
            </div>
            <div className="col-md-3">
              <label className="roboto-bold">
                {constLabel?.lbl_product_operation
                  ? constLabel?.lbl_product_operation
                  : "Product Operation"}
              </label>
              <p className="roboto-light">
                {props.selItem.productOperDetails?.operationname}
              </p>
            </div>
          </div>
          <hr />

          <div className="d-flex">
            <div className="col-md-3">
              <label className="roboto-bold">
                {constLabel?.lbl_start_date
                  ? constLabel?.lbl_start_date
                  : "Start Date"}
              </label>
              <p className="roboto-light">
                {formatDateTime(adjustDateTime(props.selItem?.start_time)).formattedDate}
              </p>
            </div>
            <div className="col-md-3">
              <label className="roboto-bold">
                {constLabel?.lbl_start_time
                  ? constLabel?.lbl_start_time
                  : "Start Time"}
              </label>
              <p className="roboto-light">
                {formatDateTime(adjustDateTime(props.selItem?.start_time)).formattedTime}
              </p>
            </div>
            <div className="col-md-3">
              <label className="roboto-bold">
                {constLabel?.lbl_end_date
                  ? constLabel?.lbl_end_date
                  : "End Date"}
              </label>
              <p className="roboto-light">
                {formatDateTime(adjustDateTime(props.selItem?.end_time)).formattedDate}
              </p>
            </div>
            <div className="col-md-3">
              <label className="roboto-bold">
                {constLabel?.lbl_end_time
                  ? constLabel?.lbl_end_time
                  : "End Time"}
              </label>
              <p className="roboto-light">
                {formatDateTime(adjustDateTime(props.selItem?.end_time)).formattedTime}
              </p>
            </div>
          </div>

          <div className="d-flex">
            <div className="col-md-3">
              <label className="roboto-bold">
                {constLabel?.lbl_machine_name
                  ? constLabel?.lbl_machine_name
                  : "Machine Name"}
              </label>
              <p className="roboto-light">
                {props.selItem?.machineDetails?.machineName +
                  "-" +
                  props.selItem?.machineDetails?.serialNo}
              </p>
            </div>
            <div className="col-md-3">
              <label className="roboto-bold">
                {constLabel?.lbl_planned_qty
                  ? constLabel?.lbl_planned_qty
                  : "Planned Qty"}
              </label>
              <p className="roboto-light">{props.selItem?.planned_qty}</p>
            </div>
            <div className="col-md-3">
              <label className="roboto-bold">
                {constLabel?.lbl_actual_qty
                  ? constLabel?.lbl_actual_qty
                  : "Actual Qty"}
              </label>
              <p className="roboto-light">{props.selItem?.actual_qty !== "" && props.selItem?.actual_qty !== null ? props.selItem?.actual_qty : "N/A" }</p>
            </div>
            <div className="col-md-3">
              <label className="roboto-bold">
                {constLabel?.lbl_operator_name
                  ? constLabel?.lbl_operator_name
                  : "Operator Name"}
              </label>
              <p className="roboto-light">
                {props.selItem?.operatorDetails?.FirstName +
                  " " +
                  props.selItem?.operatorDetails?.LastName}
              </p>
            </div>
          </div>

          <div className="d-flex">
            <div className="col-md-3">
              <label className="roboto-bold">
                {constLabel?.lbl_remark ? constLabel?.lbl_remark : "Remark"}
              </label>
              <p className="roboto-light">{props.selItem?.remarks}</p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-end">
        <div>
          <Tooltip id={"edit-tooltip"} place="top" />
          <div
            data-tooltip-id={"edit-tooltip"}
            data-tooltip-content={
              constLabel?.lbl_edit_job_card
                ? constLabel.lbl_edit_job_card
                : "Edit Job Card"
            }
            className="editIcon m-1 cursor_pointer"
            onClick={() =>
              navigate("/pcms/update-job-card", {
                state: {...props.selItem, jobCardBoard:true},
              })
            }
          >
            <Edit2 color="#FFFFFF" size={18} />
          </div>
          <Tooltip id={"delete-tooltip"} place="top" />
          <div
            data-tooltip-id={"delete-tooltip"}
            data-tooltip-content={
              constLabel?.lbl_delete_job_card
                ? constLabel.lbl_delete_job_card
                : "Delete Job Card"
            }
            className="deleteIcon m-1 cursor_pointer"
            onClick={() => delJobCard(props.selItem)}
          >
            <Trash2 color="#FFFFFF" size={18} />
          </div>
        </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ViewJobCard;

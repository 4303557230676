import React, { useState, useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
// import XLSX from "xlsx";
import { updateMachineCategory } from "../../../../api/index";

const schema = yup
  .object()
  .shape({
    machineCatName: yup
      .string()
      .required("Machine category name is required !"),
  })
  .required();

function EditMachineCategory(props) {
  const [visible, setVisible] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const onSubmit = (data) => {
    

    if (data !== "") {
      const reqPayload = {
        uuid: props.data?.uuid,
        // machineCatId: props.data.id,
        machineCatName: data.machineCatName,
        remark: data.remark,
      };

      

      updateMachineCategory(reqPayload)
        .then(
          (res) => {
            
            if (res.status === 200 || res.status === 201) {
              toast.success("Machine category updated Successfully !", {
                autoClose: 3000,
              });
              setVisible(false);
              props.fetchMachineCat(props.page,props.entriesPerPage);
            }
          },
          (err) => {
            
            if (err.response.status === 400) {
              
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
              // reset();
            }
          }
        )
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
        <Tooltip id={"edit-tooltip"} place="top" />
        <Edit
            data-tooltip-id={"edit-tooltip"}
            data-tooltip-content={
              props.constLabel?.lbl_view_category
              ? props.constLabel?.lbl_view_category
              : "Edit Category"
            }
          className="menu_icon_grey cursor_pointer me-2"
          size={20} 
          onClick={() => setVisible(true)}
        />
      {/* Add Item Modal*/}
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header style={{ background: "#2A3643", color: "white" }} closeButton>
          <Modal.Title>{props.constLabel?.lbl_update_machine_category ? props.constLabel.lbl_update_machine_category : "Update Machine Category"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form action="">
            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-md-12 roboto-bold">
                {props.constLabel?.lbl_category_name ? props.constLabel.lbl_category_name : "Category Name"}
                <span className="text-danger">*</span>
                </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={errors.machineCatName ? "form-control  is-invalid"
                  : "form-control "}
                  id="machineCatName"
                  name="machineCatName"
                  placeholder="Enter machine category name"
                  defaultValue={props.data !== undefined ? props.data.machineCatName : ''}
                  {...register("machineCatName")}
                />
                <span className="text-danger err-msg">
                  {errors.machineCatName?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-md-12 roboto-bold">
                {props.constLabel?.lbl_remark ? props.constLabel.lbl_remark : "Remark"}
              </label>
              <div className="col-md-12">
                <textarea
                  type="text"
                  className="form-control "
                  id="remark"
                  name="remark"
                  placeholder="Enter remark"
                  defaultValue={props.data !== undefined ? props.data.remark : ''}
                  {...register("remark")}
                ></textarea>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditMachineCategory;

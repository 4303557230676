import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faEye,
  faArrowLeft,
  faAdd,
} from "@fortawesome/free-solid-svg-icons";
import { Modal, Spinner, Tab, Tabs } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import {
  getLotNumList,
  getOperationListForNc,
  getMachineList,
  getNCStatus,
  getNCReason,
  updateNCAPI,
  getOperatorList,
  getProductAttribute,
  get_WorkOrder,
  getProdConfig,
  get_job_card,
  getGlobalRouting,
} from "../../api/index";
import {
  convertDateYYMMDD,
  convertDate,
  convertDateFormat,
  getLabels,
  convertBase64,
} from "../../common/Functions/CommonFunctions";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import camshaft from "../../assets/images/camshaft.jpg";
import { useLocation } from "react-router-dom";
import NcAttributes from "./NC-Attributes/NcAttributes";
import { Tooltip } from "react-tooltip";
import SweetAlert from "sweetalert2";
import { REWORK, REWORK_S, SALVAGE, SCRAP } from "../../common/Constants/constants";

const schema = yup
  .object()
  .shape({
    ncTag: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Please enter nc tag"),
    date: yup
      .date()
      .typeError("Please select date !")
      .required("Please select date !"),
    camType: yup.string().required("Cam shaft type is required !"),
    // partNo: yup
    //     .string()
    //     .required("Part no is required !"),
    lotNo: yup
      .array()
      .min(1, "Please select Lot no.")
      .required("Please select Lot no."),
    jobNo: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Job no. is required !"),
    quantity: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Please enter quantity"),
    ncOperation: yup
      .array()
      .min(1, "Please select NC operation")
      .required("Please select NC operation"),
    machineNo: yup
      .array()
      .min(1, "Please select machine !")
      .required("Please select machine !"),
    // machineNo: yup
    //     .array()
    //     .min(1, "Machine number is required !")
    //     .required("Machine number is required !"),
    ncReason: yup
      .array()
      .min(1, "Please select NC reason")
      .required("Please select NC reason"),
    qty: yup
      .number()
      .typeError("Please enter a valid number")
      .required("Quantity is required !"),
    operation: yup
      .array()
      .min(1, "Please select Operation")
      .required("Please select Operation"),
    disposalAction: yup
      .array()
      .min(1, "Please select disposal action")
      .required("Please select disposal action"),
    operator: yup
      .array()
      .min(1, "Please select nc by operator")
      .required("Please select nc by operator"),
    operator_tag: yup
      .array()
      .min(1, "Please select nc tag operator")
      .required("Please select nc tag operator"),
    selfile: yup
      .mixed()
      .test("fileSize", "Image can be uploaded upto 10 MB", (value) => {
        if (!value || value?.length == 0) return true; // If no file is uploaded, validation passes
        return value && value[0]?.size <= 10 * 1024 * 1024;
      }),
    remark: yup.string().required("Please enter root cause"),
    // selfile: yup
    //     .mixed()
    //     .test('fileSize', 'Image can be uploaded upto 10 MB',
    //         (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)),
  })
  .required();

function EditNC2(props) {
  let navigate = useNavigate();
  let location = useLocation();

  const [visible, setVisible] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [lotList, setLotList] = useState({ data: [], loading: false });
  const [opList, setOpList] = useState({ data: [], loading: false });
  const [machineList, setMachineList] = useState({ data: [], loading: false });
  const [disActionList, setDisActionList] = useState({
    data: [],
    loading: false,
  });
  const [ncRsnList, setNcRsnList] = useState({ data: [], loading: false });
  const [operatorList, setOperatorList] = useState({
    data: [],
    loading: false,
  });
  const [operatorList1, setOperatorList1] = useState({
    data: [],
    loading: false,
  });
  const [jobNoRange, setJobNoRange] = useState({ start: "", end: "" });
  const [sellot, setSelLot] = useState({});

  const [attributeModal, setAttributeModal] = useState({ state: false });
  const [nc_attrib, setNC_Attrib] = useState([]);
  const [prod_attr, setProd_Attr] = useState({ data: [], loading: false });

  const [constLabel, setConstLabel] = useState({});
  const [isLot, setIsLot] = useState(
    location.state !== undefined && location.state?.lot_no_id === null
      ? false
      : true
  );
  const [lockBtn, setLockBtn] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  let image = watch("selfile");
  let lotNo = watch("lotNo");
  let { errors } = formState;
  useEffect(() => {
    if (image?.length > 0) {
      setImagePreview(URL.createObjectURL(image[0]));
    } else {
      setImagePreview(null);
    }
  }, [image]);

  useEffect(() => {
    console.log("lotNo", lotNo);
    if (lotNo !== undefined && lotNo?.length > 0) {
      setValue(
        "camType",
        lotNo[0].productname || lotNo[0]?.productDetail?.productName
      );
      setValue("partNo", lotNo[0].partno || lotNo[0]?.productDetail?.partNo);
      setValue("qty", lotNo[0].qty || lotNo[0]?.workorderDetails?.quantity);
      setSelLot(lotNo[0]);
      setJobNoRange({
        ...jobNoRange,
        start: lotNo[0]?.lotstartnum || lotNo[0]?.workorderDetails?.serial_number_start,
        end: lotNo[0]?.lotendnum || lotNo[0]?.workorderDetails?.serial_number_end,
      });
    }
  }, [lotNo]);

  const selOperation = watch("operation");

  useEffect(() => {
    if (selOperation !== undefined && selOperation.length > 0) {
      //   fetchProductAttributes(location.state?.lot_no_id?.product_id.uuid);      // django api
      fetchProductAttributes(
        location.state?.lot_no_id?.product_id ||
          location.state?.job_card_id?.product_id
      ); // node api
    }
  }, [selOperation]);

  useEffect(() => {
    console.log("location.state", location.state);
    setConstLabel(getLabels("Quality", "NC"));

    // if (visible) {
    // fetchLotList();
    // fetchOperationList(location.state?.lot_no_id?.product_id.uuid);            // django api
    if(location.state?.work_order_id?.global_route_uuid !== null){
      fetchGlobalRoute(location.state?.work_order_id?.global_route_uuid);
    } else {
      fetchOperationList(
        location.state?.lot_no_id?.product_id ||
          location.state?.job_card_id?.product_id
      ); // node api
    }
   
    // fetchProductAttributes(location.state?.lot_no_id?.product_id.uuid);
    fetchMachineList();
    fetchDisposalList();
    fetchNcReasonList();
    fetchOperatorList();
    if (
      location?.state?.nc_parameter_detail !== null &&
      location?.state?.nc_parameter_detail.length > 0
    ) {
      setNC_Attrib([location?.state?.nc_parameter_detail]);
    }
    // }
    if (
      location.state !== undefined &&
      location.state?.job_card_id !== null
    ) {
      // get_WorkOrder({
      //   work_order_no: location.state?.work_order_id?.work_order_no,
      // }).then(
      //   (res) => {
          
      //     //   if (res.data.data.length == 0) {
      //     //     toast.error("Work order no not found", {
      //     //       autoClose: 2000,
      //     //     });
      //     //   } else {
      //     //     console.log('work order res', res);
      //     //     setValue("camType", res.data.data[0]?.product_info?.productName);
      //     //     setValue("partNo", res.data.data[0]?.product_info?.partno);
      //     //     setValue("qty", res.data.data[0].quantity);
      //     //     setSelLot(res.data.data[0]);
      //     //     setJobNoRange({
      //     //       ...jobNoRange,
      //     //       start: res.data.data[0].serial_number_start,
      //     //       end: res.data.data[0].serial_number_end,
      //     //     });
      //     //   }
      //   },
      //   (err) => {}
      // );

      setValue("work-order", [location.state?.work_order_id]);
      get_job_card({
          uuid: location.state?.job_card_id?.uuid,
        }).then(
          (res) => {
            setValue("lotNo", res.data.data);
          },
          (err) => {}
        );
    }
  }, []);

  function fetchLotList() {
    setLotList({ ...lotList, data: [], loading: true });

    getLotNumList().then(
      (res) => {
        setLotList({ ...lotList, data: res.data.data, loading: false });
      },
      (err) => {
        setLotList({ ...lotList, data: [], loading: false });
      }
    );
  }

  function fetchProductAttributes(prod_uuid) {
    setProd_Attr({ ...prod_attr, data: [], loading: true });
    const payload = {
      product_id: prod_uuid,
    };
    getProductAttribute(payload).then(
      (res) => {
        const result = res.data.data.filter(
          (item) =>
            selOperation?.[0]?.uuid === item.product_operation?.operation_id
        );

        if (result) {
          setProd_Attr({ ...prod_attr, data: result, loading: false });
        } else {
          setProd_Attr({ ...prod_attr, data: [], loading: false });
        }
      },
      (err) => {
        setProd_Attr({ ...prod_attr, data: [], loading: false });
      }
    );
  }

  function fetchOperationList(pid) {
    if (pid == null) {
      toast.error("Product id not found in lot response", {
        autoClose: 2000,
      });
    } else {
      setOpList({ ...opList, data: [], loading: true });
      getProdConfig({ product_id: pid }).then(
        (res) => {
          let arr = res.data.data
            ? res.data.data.map((item) => {
                const {
                  operationname,
                  operationid,
                  operation_id,
                  uuid,
                  ...rest
                } = item;
                let obj = {
                  ...rest,
                  operationName: operationname,
                  operationID: operationid,
                  uuid: operation_id,
                };
                return obj;
              })
            : [];
          setOpList({ ...opList, data: arr, loading: false });
        },
        (err) => {
          setOpList({ ...opList, data: [], loading: false });
        }
      );
    }
  }

  function fetchMachineList() {
    setMachineList({ ...machineList, data: [], loading: true });
    getMachineList().then(
      (res) => {
        const updatedList = res.data.data.map((item) => ({
          ...item,
          machineNameSerialNo: item.machineName + " - " + item.serialNo,
        }));

        setMachineList({ ...machineList, data: updatedList, loading: false });
      },
      (err) => {
        setMachineList({ ...machineList, data: [], loading: false });
      }
    );
  }

  function fetchDisposalList() {
    setDisActionList({ ...disActionList, data: [], loading: true });
    getNCStatus().then(
      (res) => {
        setDisActionList({
          ...disActionList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setDisActionList({ ...disActionList, data: [], loading: false });
      }
    );
  }

  function fetchNcReasonList() {
    setNcRsnList({ ...ncRsnList, data: [], loading: true });
    getNCReason().then(
      (res) => {
        setNcRsnList({ ...ncRsnList, data: res.data.data, loading: false });
      },
      (err) => {
        setNcRsnList({ ...ncRsnList, data: [], loading: false });
      }
    );
  }

  function fetchOperatorList() {
    setOperatorList({ ...operatorList, data: [], loading: true });
    setOperatorList1({ ...operatorList1, data: [], loading: true });
    getOperatorList({ designation: "Operator" }).then(
      (res) => {
        if (res.data.data.length > 0) {
          const updatedPeopleList = res.data.data.map((person) => ({
            ...person,
            fullName:
              person.MiddleName !== null && person.MiddleName !== ""
                ? person.FirstName +
                  " " +
                  person.MiddleName +
                  " " +
                  person.LastName
                : person.FirstName + " " + person.LastName,
          }));

          setOperatorList({
            ...operatorList,
            data: updatedPeopleList,
            loading: false,
          });
          setOperatorList1({
            ...operatorList1,
            data: updatedPeopleList,
            loading: false,
          });
        } else {
          setOperatorList({ ...operatorList, data: [], loading: false });
          setOperatorList1({ ...operatorList1, data: [], loading: false });
        }
      },
      (err) => {
        setOperatorList({ ...operatorList, data: [], loading: false });
        setOperatorList1({ ...operatorList1, data: [], loading: false });
      }
    );
  }

  function isNumberInRange(number, min, max) {
    return number >= min && number <= max;
  }

  const calculateSum = (sq_no) => {

    let sum = 0;
      sum = opList.data
        .filter(item => item.sequenceNo < sq_no)  // Get operations before sqNo 4
        .reduce((acc, curr) => acc + Number(curr.oprcost), 0); // Sum their costs
      return Number(sum);
    };

  const onSubmit = async (data) => {
    // console.log("data", data);
    setLockBtn(true);

    if (
      [REWORK, REWORK_S, SCRAP, SALVAGE].includes(location.state?.ncstatus_id?.statusname)
    ) {
      SweetAlert.fire({
        title: "Action Not Allowed",
        text: "Cannot update from Rework, Rework S, Scrap, or Salvage.",
        icon: "error",
        confirmButtonText: "Ok",
      }).then(() => {
        setLockBtn(false); // Ensure the button unlocks
      });
      return;
    }

    const payload = {
      uuid: location.state?.uuid,
      // nc_date: convertDate(data.date, "-"),
      nc_date: convertDateYYMMDD(data.date, "-"),
      ncreason: data.ncReason[0].NCReason,
      ncreason_id: data.ncReason[0].uuid,
      ncstatus: data.disposalAction[0].statusname,
      ncstatus_id: data.disposalAction[0].uuid,
      nc_tag: data.ncTag,
      qty: data.quantity,
      finished_weight: location.state?.finished_weight,
      selling_price: location.state?.selling_price,

      // qty: data.qty,
      machine_id: data.machineNo[0].machineId,
      machine_id_id: data.machineNo[0].uuid,
      operation: data.operation[0].operationName,
      operation_id: data.operation[0].operationID,
      operation_id_id: data.operation[0].uuid,
      operation_inwhich: data.ncOperation[0].operationName,
      operation_inwhich_id: data.ncOperation[0].uuid,
      // operator: data.operator[0].FirstName + " " + data.operator[0].MiddleName + " " + data.operator[0].LastName,
      operator: data.operator[0].FirstName + " " + data.operator[0].LastName,
      operator_id: data.operator[0].uuid,
      nc_tag_operator: data.operator_tag[0].FirstName + " " + data.operator_tag[0].LastName,
      nc_tag_operator_id: data.operator_tag[0].uuid,
      //   lot_no: data.lotNo[0].lotId,
      //   lot_no_id: sellot.uuid,
      job_no: data.jobNo,
      // lot_size: data.quantity,
      lot_size: location.state?.lot_size,
      camshaft_type:
        sellot.productDetail?.productName || sellot.productDetail?.productName,
      camshaft_type_id: sellot.productDetail?.uuid || sellot.productDetail?.uuid,
      part_no: sellot.productDetail?.partNo || sellot.productDetail?.partNo,
      // ncjobimage: data.selfile.length > 0 ? data.selfile[0] : "",
      // ncjobimgname: data.selfile.length > 0 ? data.selfile[0].name : "",
      remark: data.remark,
      isLot: isLot,
      operation_labor_cost: data.disposalAction[0]?.statusname !== SALVAGE ? calculateSum(data.ncOperation[0].sequenceNo) : 0,
    };

    // console.log("payload", payload); return;

    if (isLot && isLot === true) {
      payload.lot_no = sellot.lotId;
      payload.lot_no_id = sellot.uuid;
      payload.product_id = lotList.data?.[0]?.product_id?.productId;;
      payload.work_order_no = null;
      payload.work_order_id = null;
      payload.work_order_status = null;
    } else {
      payload.lot_no = null;
      payload.lot_no_id = null;
      payload.work_order_no = sellot.workorderDetails?.work_order_no;
      payload.work_order_id = sellot.workorderDetails?.uuid;
      payload.work_order_status = sellot.workorderDetails?.work_order_status;
      payload.product_id = sellot.productDetail?.productId;
      payload.job_card_id = sellot.uuid;
    }

    if (nc_attrib !== undefined && nc_attrib[0]?.length > 0) {
      payload.nc_parameter_detail = nc_attrib[0];
    } else {
      payload.nc_parameter_detail = [];
    }

    if (data.selfile?.length > 0) {
      const getExt = data.selfile?.[0]?.name?.split(".").pop();

      let dataimage = await convertBase64(data.selfile[0]);
      let newImage = dataimage.replace(
        /data:image\/[a-zA-Z]+;/,
        "data:image/" + getExt + ";"
      );

      payload.ncjobimage = newImage;
      payload.ncjobimgname = data.selfile?.[0]?.name;
    } else {
      payload.ncjobimage = null;
      payload.ncjobimgname = null;
    }

    // console.log("payload", payload);

    // const formDataToSend = new FormData();

    // for (const [key, value] of Object.entries(payload)) {
    //     formDataToSend.append(key, value);
    // }

    if (isNumberInRange(data.jobNo, jobNoRange.start, jobNoRange.end)) {
      updateNCAPI(payload).then(
        (res) => {
          toast.success("NC updated successfully", {
            autoClose: 2000,
          });
          setTimeout(() => {
            navigate("/pcms/nc");
          }, [1000]);
          setLockBtn(false);
        },
        (err) => {
          toast.error(err.response.data.message, {
            autoClose: 2000,
          });
          setLockBtn(false);
        }
      );
    } else {
      setError(
        "jobNo",
        {
          type: "focus",
          message: `Job no must be between ${jobNoRange.start} and ${jobNoRange.end}`,
        },
        { shouldFocus: true }
      );
    }
  };

  function fetchGlobalRoute(glob_route_uuid){
    getGlobalRouting({uuid: glob_route_uuid}).then(
      (res) => {
        console.log("res", res);
        let arr = res.data.data
            ? res.data.data?.[0]?.routeData.map((item) => {
                const {
                  operationname,
                  operationid,
                  operation_id,
                  uuid,
                  ...rest
                } = item;
                let obj = {
                  ...rest,
                  operationName: operationname,
                  operationID: operationid,
                  uuid: operation_id,
                };
                return obj;
              })
            : [];
        setOpList({ ...opList, data: arr, loading: false });
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <NcAttributes
        attributeModal={attributeModal}
        setAttributeModal={setAttributeModal}
        prod_attr={prod_attr}
        nc_attrib={nc_attrib}
        setNC_Attrib={setNC_Attrib}
        currInd={0}
      />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                navigate("/pcms/nc");
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_update_non_conformance
                ? constLabel.lbl_update_non_conformance
                : "Update Non-Conformance"}
            </p>
          </div>
          <div className="card-body">
            <div className="EditNC2 container-fluid">
              <div className="row mb-3">
                <div
                  className="form-check form-switch"
                  style={{ paddingLeft: "3rem" }}
                >
                  <label
                    className="form-check-label lbl-style roboto-bold"
                    htmlFor="flexSwitchCheckChecked"
                  >
                    {"Lot / Work Order"}
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked={isLot}
                    disabled
                    // onChange={() => setIsLot(!isLot)}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-md-6 row me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-3"
                  >
                    {location.state?.lot_no_id !== null
                      ? constLabel?.lbl_lot_no
                        ? constLabel.lbl_lot_no
                        : "Lot No"
                      : constLabel?.lbl_work_order_no
                      ? constLabel.lbl_work_order_no
                      : "Work Order No"}
                  </label>
                  {location.state !== undefined &&
                  location.state?.lot_no_id !== null ? (
                    <div className="col-12 col-md-9">
                      <Controller
                        name={`lotNo`}
                        control={control}
                        defaultValue={[location.state?.lot_no_id]}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="lotId"
                            size="sm"
                            multiple={false}
                            defaultSelected={[location.state?.lot_no_id]}
                            // options={[]}
                            // options={lotList.data}
                            // isLoading={lotList.loading}
                            placeholder="Select Lot No."
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            disabled={true}
                            isInvalid={errors.lotNo ? true : false}
                          />
                        )}
                      />
                      {errors.lotNo && (
                        <span className="text-danger err-msg">
                          {errors.lotNo.message}
                        </span>
                      )}
                    </div>
                  ) : (
                    <div className="col-12 col-md-9">
                      <Controller
                        name={`work-order`}
                        control={control}
                        // defaultValue={[location.state?.work_order_id]}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="work_order_no"
                            size="sm"
                            multiple={false}
                            // defaultSelected={[location.state?.work_order_id]}
                            // options={[]}
                            // options={lotList.data}
                            // isLoading={lotList.loading}
                            placeholder="Select work order no."
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            disabled={true}
                            isInvalid={errors.lotNo ? true : false}
                          />
                        )}
                      />
                      {errors.lotNo && (
                        <span className="text-danger err-msg">
                          {errors.lotNo.message}
                        </span>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {location.state !== undefined &&
                  location.state?.lot_no_id === null ? (
                <div className="row mb-3">
                  <div className="col-12 col-md-6 row">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label roboto-bold col-12 col-md-3"
                    >
                      {constLabel?.lbl_job_card_no
                        ? constLabel.lbl_job_card_no
                        : "Job Card No"}
                    </label>

                    <div className="col-12 col-md-9">
                      <Controller
                        name={`lotNo`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="job_card_no"
                            size="sm"
                            multiple={false}
                            // options={jobCardList.data}
                            // isLoading={jobCardList.loading}
                            placeholder="Select job card no"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            // isInvalid={errors.job-card ? true : false}
                            disabled={true}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              {lotNo !== undefined && lotNo !== null && lotNo?.length > 0 ? (
                <>
                  <hr className="my-2" />
                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                    {constLabel?.lbl_product_detail
                      ? constLabel.lbl_product_detail
                      : "Product Detail"}
                  </p>
                  <hr className="my-2" />

                  <div className="row my-3" style={{ fontSize: "16px" }}>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-md-3">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_name
                              ? constLabel.lbl_name
                              : "Name"}
                          </p>
                          <p className="mb-0" style={{ color: "#FF134E" }}>
                            {lotNo[0]?.productname ||
                              lotNo[0]?.productDetail?.productName}
                          </p>
                        </div>
                        <div className="col-12 col-md-3">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_part_no
                              ? constLabel.lbl_part_no
                              : "Part No"}
                          </p>
                          <p className="mb-0">
                            {lotNo[0]?.productDetail?.partNo ||
                              lotNo[0]?.productDetail?.partNo}
                          </p>
                        </div>
                        <div className="col-12 col-md-3">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_lot_size
                              ? constLabel.lbl_lot_size
                              : "Lot Size"}
                          </p>
                          <p className="mb-0">
                            {lotNo[0]?.qty || lotNo[0]?.workorderDetails?.quantity}
                          </p>
                        </div>
                        <div className="col-12 col-md-3">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_family
                              ? constLabel.lbl_family
                              : "Family"}
                          </p>
                          <p className="mb-0">
                            {lotNo[0]?.product_familyname || location.state?.camshaft_type_id?.prodfamily1?.familyname}
                              {/* lotNo[0]?.product_info?.prodfamily1?.familyname */}
                          </p>
                        </div>
                        <div className="col-12 col-md-3 mt-2">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_lot_start_no
                              ? constLabel.lbl_lot_start_no
                              : "Lot Start No"}
                          </p>
                          <p className="mb-0">
                            {lotNo[0]?.lotstartnum ||
                              lotNo[0]?.workorderDetails?.serial_number_start}
                          </p>
                        </div>
                        <div className="col-12 col-md-3 mt-2">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_lot_end_no
                              ? constLabel.lbl_lot_end_no
                              : "Lot End No"}
                          </p>
                          <p className="mb-0">
                            {lotNo[0]?.lotendnum || lotNo[0]?.workorderDetails?.serial_number_end}
                          </p>
                        </div>
                        <div className="col-12 col-md-3 mt-2">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_finished_weight
                              ? constLabel.lbl_finished_weight
                              : "Finished Weight"}
                          </p>
                          <p className="mb-0">
                            {location.state?.finished_weight}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <button
                            onClick={() => setVisible(true)}
                            className="btn btn-sm btn-grey px-3"
                          >
                            {constLabel?.lbl_view_product_image
                              ? constLabel.lbl_view_product_image
                              : "View Product Images"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}

              <hr className="my-2" />
              <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_product_detail
                  ? constLabel.lbl_product_detail
                  : "NC Detail"}
              </p>
              <hr className="my-2" />

              <div className="row mt-3">
                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_job_no ? constLabel.lbl_job_no : "Job No"}
                    <span className="text-danger"> *</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.jobNo
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                      placeholder="Enter Job No"
                      type="text"
                      defaultValue={location.state?.job_no}
                      readOnly
                      {...register("jobNo")}
                    />
                    {errors.jobNo && (
                      <span className="text-danger err-msg">
                        {errors.jobNo.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_quantity
                      ? constLabel.lbl_quantity
                      : "Quantity"}
                      <span className="text-danger"> *</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.quantity
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                      type="number"
                      placeholder="Enter quantity"
                      // defaultValue={location.state?.lot_size}
                      defaultValue={location.state?.qty}
                      readOnly
                      {...register("quantity")}
                    />
                    {errors.quantity && (
                      <span className="text-danger err-msg">
                        {errors.quantity.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_machine
                      ? constLabel.lbl_machine
                      : "Machine"}
                      <span className="text-danger"> *</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name={`machineNo`}
                      control={control}
                      defaultValue={
                        location.state?.machine_id_id !== null &&
                        location.state?.machine_id_id !== undefined
                          ? [location.state?.machine_id_id]
                          : []
                      }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="machineNameSerialNo"
                          multiple={false}
                          defaultSelected={
                            location.state?.machine_id_id !== null &&
                            location.state?.machine_id_id !== undefined
                              ? [location.state?.machine_id_id]
                              : []
                          }
                          options={machineList.data}
                          isLoading={machineList.loading}
                          placeholder="Select Machine"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.machineNo ? true : false}
                          // disabled={location.state.job_card_id !== null ? true : false}
                        />
                      )}
                    />
                    {errors.machineNo && (
                      <span className="text-danger err-msg">
                        {errors.machineNo.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_date ? constLabel.lbl_date : "Date"}
                    <span className="text-danger"> *</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name="date"
                      control={control}
                      defaultValue={
                        location.state !== undefined
                          ? new Date(location.state?.nc_date)
                          : new Date()
                      }
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.date
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          {...field}
                          defaultValue={
                            location.state !== undefined
                              ? new Date(location.state?.nc_date)
                              : new Date()
                          }
                          showYearDropdown
                          scrollableMonthYearDropdown
                          dateFormat="dd/MM/yyyy"
                          maxDate={new Date()}
                          selected={field.value}
                          placeholderText="Select a date"
                          autoComplete="off"
                        />
                      )}
                    />
                    {errors.date && (
                      <span className="text-danger err-msg">
                        {errors.date.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_nc_detected_operation
                      ? constLabel.lbl_nc_detected_operation
                      : "NC Detected Operation"}
                      <span className="text-danger"> *</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name={`ncOperation`}
                      control={control}
                      defaultValue={
                        location.state?.operation_inwhich_id !== null &&
                        location.state?.operation_inwhich_id !== undefined
                          ? [location.state?.operation_inwhich_id]
                          : []
                      }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="operationName"
                          multiple={false}
                          defaultSelected={
                            location.state?.operation_inwhich_id !== null &&
                            location.state?.operation_inwhich_id !== undefined
                              ? [location.state?.operation_inwhich_id]
                              : []
                          }
                          options={opList.data}
                          isLoading={opList.loading}
                          placeholder="Select NC Operation"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.ncOperation ? true : false}
                        />
                      )}
                    />
                    {errors.ncOperation && (
                      <span className="text-danger err-msg">
                        {errors.ncOperation.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_nc_reason
                      ? constLabel.lbl_nc_reason
                      : "NC Reason"}
                      <span className="text-danger"> *</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name={`ncReason`}
                      control={control}
                      defaultValue={
                        location.state?.ncreason_id !== null &&
                        location.state?.ncreason_id !== undefined
                          ? [location.state?.ncreason_id]
                          : []
                      }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="NCReason"
                          multiple={false}
                          defaultSelected={
                            location.state?.ncreason_id !== null &&
                            location.state?.ncreason_id !== undefined
                              ? [location.state?.ncreason_id]
                              : []
                          }
                          options={ncRsnList.data}
                          isLoading={ncRsnList.loading}
                          placeholder="Select NC Reason"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.ncReason ? true : false}
                        />
                      )}
                    />
                    {errors.ncReason && (
                      <span className="text-danger err-msg">
                        {errors.ncReason.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_root_cause_at_operation
                      ? constLabel.lbl_root_cause_at_operation
                      : "Root Cause At Operation"}
                      <span className="text-danger"> *</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name={`operation`}
                      control={control}
                      defaultValue={
                        location.state?.operation_id_id !== null &&
                        location.state?.operation_id_id !== undefined
                          ? [location.state?.operation_id_id]
                          : []
                      }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="operationName"
                          multiple={false}
                          defaultSelected={
                            location.state?.operation_id_id !== null &&
                            location.state?.operation_id_id !== undefined
                              ? [location.state?.operation_id_id]
                              : []
                          }
                          options={opList.data}
                          isLoading={opList.loading}
                          placeholder="Select Operation"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.operation ? true : false}
                          // disabled={location.state.job_card_id !== null ? true : false}
                        />
                      )}
                    />
                    {errors.operation && (
                      <span className="text-danger err-msg">
                        {errors.operation.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_root_cause
                      ? constLabel.lbl_root_cause
                      : "Root Cause"}
                      <span className="text-danger"> *</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <textarea
                      className={
                        errors.remark
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                      rows="1"
                      placeholder="Enter root cause"
                      {...register("remark")}
                      defaultValue={location.state?.remark}
                    ></textarea>
                    {errors.remark && (
                      <span className="text-danger err-msg">
                        {errors.remark.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_disposal_action
                      ? constLabel.lbl_disposal_action
                      : "Disposal Action"}
                      <span className="text-danger"> *</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name={`disposalAction`}
                      control={control}
                      defaultValue={
                        location.state?.ncstatus_id !== null &&
                        location.state?.ncstatus_id !== undefined
                          ? [location.state?.ncstatus_id]
                          : null
                      }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="statusname"
                          multiple={false}
                          defaultSelected={
                            location.state?.ncstatus_id !== null &&
                            location.state?.ncstatus_id !== undefined
                              ? [location.state?.ncstatus_id]
                              : null
                          }
                          options={disActionList.data}
                          isLoading={disActionList.loading}
                          placeholder="Select Disposal Action"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.disposalAction ? true : false}
                        />
                      )}
                    />
                    {errors.disposalAction && (
                      <span className="text-danger err-msg">
                        {errors.disposalAction.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_nc_by_operator
                      ? constLabel.lbl_nc_by_operator
                      : "NC By Operator"}
                      <span className="text-danger"> *</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name={`operator`}
                      control={control}
                      defaultValue={[
                        {
                          fullName: 
                            location.state?.operator_id?.FirstName +
                            (location.state?.operator_id?.MiddleName ? " " + location.state?.operator_id?.MiddleName : "") +
                            " " + location.state?.operator_id?.LastName,
                          uuid: location.state?.operator_id?.uuid,
                          FirstName: location.state?.operator_id?.FirstName,
                          LastName: location.state?.operator_id?.LastName,
                          MiddleName: location.state?.operator_id?.MiddleName,
                        },
                      ]}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="fullName"
                          defaultSelected={[
                            {
                              fullName: 
                                location.state?.operator_id?.FirstName +
                                (location.state?.operator_id?.MiddleName ? " " + location.state?.operator_id?.MiddleName : "") +
                                " " + location.state?.operator_id?.LastName,
                              uuid: location.state?.operator_id?.uuid,
                              FirstName: location.state?.operator_id?.FirstName,
                              LastName: location.state?.operator_id?.LastName,
                              MiddleName: location.state?.operator_id?.MiddleName,
                            },
                          ]}
                          multiple={false}
                          options={operatorList.data}
                          isLoading={operatorList.loading}
                          placeholder="Select Operator"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.operator ? true : false}
                          // disabled={location.state.job_card_id !== null ? true : false}
                        />
                      )}
                    />
                    {errors.operator && (
                      <span className="text-danger err-msg">
                        {errors.operator.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_nc_tag_operator
                      ? constLabel.lbl_nc_tag_operator
                      : "NC Tag Operator"}
                      <span className="text-danger"> *</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name={`operator_tag`}
                      control={control}
                      defaultValue={[
                        {
                          fullName: 
                            location.state?.nc_tag_operator_id?.FirstName +
                            (location.state?.nc_tag_operator_id?.MiddleName ? " " + location.state?.nc_tag_operator_id?.MiddleName : "") +
                            " " + location.state?.nc_tag_operator_id?.LastName,
                          uuid: location.state?.nc_tag_operator_id?.uuid,
                          FirstName: location.state?.nc_tag_operator_id?.FirstName,
                          LastName: location.state?.nc_tag_operator_id?.LastName,
                          MiddleName: location.state?.nc_tag_operator_id?.MiddleName,
                        },
                      ]}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="fullName"
                          defaultSelected={[
                            {
                              fullName: 
                                location.state?.nc_tag_operator_id?.FirstName +
                                (location.state?.nc_tag_operator_id?.MiddleName ? " " + location.state?.nc_tag_operator_id?.MiddleName : "") +
                                " " + location.state?.nc_tag_operator_id?.LastName,
                              uuid: location.state?.nc_tag_operator_id?.uuid,
                              FirstName: location.state?.nc_tag_operator_id?.FirstName,
                              LastName: location.state?.nc_tag_operator_id?.LastName,
                              MiddleName: location.state?.nc_tag_operator_id?.MiddleName,
                            },
                          ]}
                          multiple={false}
                          options={operatorList.data}
                          isLoading={operatorList.loading}
                          placeholder="Select Operator"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.operator ? true : false}
                          // disabled={location.state.job_card_id !== null ? true : false}
                        />
                      )}
                    />
                    {errors.operator && (
                      <span className="text-danger err-msg">
                        {errors.operator.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_nc_tag ? constLabel.lbl_nc_tag : "NC Tag"}
                    <span className="text-danger"> *</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.ncTag
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                      type="number"
                      {...register("ncTag")}
                      placeholder="Enter NC Tag"
                      defaultValue={location?.state?.nc_tag}
                    />
                    {errors.ncTag && (
                      <span className="text-danger err-msg">
                        {errors.ncTag.message}
                      </span>
                    )}
                  </div>
                </div>

                {/* <div className='col-12 col-md-6  row mb-3 me-0 me-md-1'>

                                    <label style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-12 roboto-bold'>Cam Shaft Type</label>

                                    <div className='col-12 col-md-9'>
                                        <input className={errors.camType ?
                                            "form-control  is-invalid"
                                            : "form-control "}
                                            placeholder='Cam Shaft Type'
                                            disabled={true}
                                            defaultValue={location.state?.nc_tag}
                                            type="text" {...register("camType")} />
                                        {errors.camType
                                            && <span className='text-danger err-msg'>{errors.camType.message}</span>}
                                    </div>

                                </div>
                                <div className='col-12 col-md-6  row mb-3 me-0 me-md-1'>

                                    <label style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-12 roboto-bold'>Part No</label>

                                    <div className='col-12 col-md-9'>
                                        <input
                                            className={errors.partNo ?
                                                "form-control  is-invalid"
                                                : "form-control "}
                                            type="text"
                                            placeholder='Part No.'
                                            defaultValue={location.state?.nc_tag}
                                            disabled={true}
                                            {...register("partNo")} />
                                        {errors.partNo
                                            && <span className='text-danger err-msg'>{errors.partNo.message}</span>}

                                    </div>

                                </div>








                                <div className='col-12 col-md-6  row mb-3 me-0 me-md-1'>

                                    <label style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-12 roboto-bold'>Quantity</label>

                                    <div className='col-12 col-md-9'>
                                        <input
                                            className={errors.qty ?
                                                "form-control  is-invalid"
                                                : "form-control "}
                                            type="text"
                                            placeholder='Enter Quantity'
                                            {...register("qty")}
                                            defaultValue={location.state?.qty}
                                            disabled={true}
                                        />
                                        {errors.qty
                                            && <span className='text-danger err-msg'>{errors.qty.message}</span>}
                                    </div>

                                </div> */}

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_upload_image
                      ? constLabel.lbl_upload_image
                      : "Upload Image"}
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className={
                        errors.selfile
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                      type="file"
                      accept="image/*"
                      placeholder="Upload Image"
                      {...register("selfile")}
                    />
                    {errors.selfile && (
                      <span className="text-danger err-msg">
                        {errors.selfile.message}
                      </span>
                    )}
                  </div>
                </div>
                {imagePreview && !errors.selfile && (
                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-3"
                    >
                      {constLabel?.lbl_image_preview
                        ? constLabel.lbl_image_preview
                        : "Image Preview"}
                    </label>

                    <div className="col-12 col-md-9">
                      <div
                        className="text-center"
                        style={{
                          border: "1px solid #c6c6c6",
                          borderRadius: "8px",
                        }}
                      >
                        <img
                          src={imagePreview}
                          alt="Preview"
                          style={{
                            maxWidth: "200px",
                            margin: "10px",
                            borderRadius: "8px",
                          }}
                        />{" "}
                        {/*process.env.REACT_APP_IMAGE_URL+*/}
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold invisible"
                  >
                    {constLabel?.lbl_nc_tag ? constLabel.lbl_nc_tag : "NC Tag"}
                    <span className="text-danger"> *</span>
                  </label>
                  <Tooltip id={"edit-tooltip"} place="top" />
                  <div className="col-12 col-md-9">
                  <button
                      data-tooltip-id={"edit-tooltip"}
                      data-tooltip-content={"Edit Attribute"}
                      style={{ fontSize: "13px" }}
                      className="btn btn-sm button-primary ms-2"
                      onClick={() => {
                        setAttributeModal({ state: true });
                      }}
                    >
                      {/* <FontAwesomeIcon
                        style={{
                          color: "#ffffff",
                        }}
                        icon={faAdd}
                        size="sm"
                      /> */}
                      {"Edit Attribute"}
                    </button>
                    
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-3"
                    >
        
                  </label>
                  <Tooltip id={"edit-tooltip"} place="top" />
                  <div className="col-12 col-md-9">
                    
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="card-footer py-3">
            <button
              className="btn btn-yellow px-4 float-end"
              onClick={handleSubmit(onSubmit)}
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
              {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_product_images
              ? constLabel.lbl_product_images
              : "Product Images"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row image_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
            {lotNo !== undefined && lotNo !== null && lotNo?.length > 0
              ? (
                  lotNo[0]?.productDetail?.product_img ||
                  lotNo[0]?.work_order_files
                )?.map((val, i) => (
                  <div className="col" key={i}>
                    <div className="image-container">
                      <img
                        src={process.env.REACT_APP_IMAGE_URL + val.attachment}
                        className="img-fluid"
                        alt="Image 1"
                      />
                      <div className="image-overlay">
                        <FontAwesomeIcon
                          onClick={() =>
                            window.open(
                              process.env.REACT_APP_IMAGE_URL + val.attachment,
                              "_blank"
                            )
                          }
                          className="icon"
                          style={{ color: "#fff" }}
                          icon={faEye}
                          size="xl"
                        />
                      </div>
                    </div>
                  </div>
                ))
              : null}

            {lotNo !== undefined && lotNo !== null && lotNo?.length > 0 ? (
              lotNo[0]?.productDetail?.product_img.length == 0 ||
              lotNo[0]?.work_order_files?.length == 0 ? (
                <div className="col">
                  <div className="image-container">
                    <img src={camshaft} className="img-fluid" alt="Image 1" />

                    <div className="image-overlay">
                      <FontAwesomeIcon
                        onClick={() => window.open(camshaft, "_blank")}
                        className="icon"
                        style={{ color: "#fff" }}
                        icon={faEye}
                        size="xl"
                      />
                    </div>
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-grey px-4"
            onClick={() => setVisible(false)}
          >
            {constLabel?.close ? constLabel.close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditNC2;

import { tool_grade } from "../../constant";

// if you are add variable to this object also add that variable in below list
export const screenList = {
    nc_screen: "NC",
    quality_dashbaord_screen: "Quality Dashboard",
    product_screen: "Product",
    generic_screen: "Generic",
    customer_type_screen: "Customer Type",
    customer_screen: "Customer",
    supplier_type_screen: "Supplier Type",
    supplier_screen: "Supplier",
    operation_category_screen: "Operation Category",
    operation_screen: "Operation",
    machine_category_screen: "Machine Category",
    machine_screen: "Machine",
    task_category_screen: "Task Category",
    task_screen: "Task",
    location_type_screen: "Location Type",
    location_screen: "Location",
    location_mapping_screen: "Location Mapping",
    store_type_screen: "Store Type",
    store_screen: "Store",
    inventory_screen: "Inventory Management",
    user_screen: "User",
    status_screen: "Status",
    purchase_screen: "Purchase",
    sales_screen: "Sales",
    transaction_screen: "Transaction",
    organization_type_screen: "Organization Type",
    organization_screen: "Organization",
    company_type_screen: "Company Type",
    company_screen: "Company",
    role_screen: "Role",
    supplier_quotation_screen: "Supplier Quotation",
    purchase_order_screen: "Purchase Order",
    purchase_inward_screen: "Purchase Inward",
    sales_order_screen: "Sales Order",
    disposal_action_screen: "Disposal Action",
    nc_reason_screen: "NC Reason",
    modules_screen: "Modules",
    supplier360_screen: "Supplier360",
    customer360_screen: "Customer360",
    movement_screen: "Movement",
    gst_slab_screen: "GST Slab",
    gst_treatment_screen: "GST Registration Type",
    manufacture_screen: "Manufacturing",
    bom_screen: "Bill of Material",
    work_order: "Work Order",
    job_screen: "Job Card",
    production_data_screen: "Production Data",
    document_type: "Document Type",
    employee_master: "Employee Master",
    production_dashboard_screen: "Production Dashboard",
    product_family_screen: "Product Family",
    product_category_screen: "Product Category",
    job_card_board_screen: "Job Card Board",
    tool_type_screen: 'Tool Type',
    tool_sub_type_screen: "Tool Sub-Type",
    tool_grade: "Tool Grade",
    rmi_screen: "RMI",
    machine_dashboard_screen: "Machine Dashboard",
    cutlot_screen: "Cutlot",
    challan_type_screen: "Challan Type",
    goods_challan_screen: "Goods Challan",
    goods_challan_work_order_view: "Goods Challan Work Order View",
    inspection_type_screen: "Inspection Type",
    stock_availability_screen: "Stock Availability",
    stock_ledger_screen: "Stock Ledger",
    dispatch_screen: "Dispatch",
    hsn_master_screen: "HSN Master",
    corrective_action_screen: "Corrective Action",
    preventive_action_screen: "Preventive Action",
    material_type_screen: "Material Type",
    setting_screen: "Setting",
    ProductionPlanning: "ProductionPlanning",
    adjustment_reason_screen: "Adjustment Reason",
    material_request_screen: "Material Request",
    routing_master_screen: "Routing Master",
    shift_master_screen: "Shift Master"
}


export const screen_list = [
    { name: 'NC', read: null, write: null, no_access: null },
    { name: 'Quality Dashboard', read: null, write: null, no_access: null },
    { name: 'Product', read: null, write: null, no_access: null },
    { name: 'Generic', read: null, write: null, no_access: null },
    { name: 'Customer Type', read: null, write: null, no_access: null },
    { name: 'Customer', read: null, write: null, no_access: null },
    { name: 'Supplier Type', read: null, write: null, no_access: null },
    { name: 'Supplier', read: null, write: null, no_access: null },
    { name: 'Operation Category', read: null, write: null, no_access: null },
    { name: 'Operation', read: null, write: null, no_access: null },
    { name: 'Machine Category', read: null, write: null, no_access: null },
    { name: 'Machine', read: null, write: null, no_access: null },
    { name: 'Task Category', read: null, write: null, no_access: null },
    { name: 'Task', read: null, write: null, no_access: null },
    { name: 'Location Type', read: null, write: null, no_access: null },
    { name: 'Location', read: null, write: null, no_access: null },
    { name: 'Location Mapping', read: null, write: null, no_access: null },
    { name: 'Store Type', read: null, write: null, no_access: null },
    { name: 'Store', read: null, write: null, no_access: null },
    { name: 'Inventory Management', read: null, write: null, no_access: null },
    { name: 'User', read: null, write: null, no_access: null },
    { name: 'Status', read: null, write: null, no_access: null },
    { name: 'Purchase', read: null, write: null, no_access: null },
    { name: 'Sales', read: null, write: null, no_access: null },
    { name: 'Transaction', read: null, write: null, no_access: null },
    { name: 'Organization Type', read: null, write: null, no_access: null },
    { name: 'Organization', read: null, write: null, no_access: null },
    { name: 'Company Type', read: null, write: null, no_access: null },
    { name: 'Company', read: null, write: null, no_access: null },
    { name: 'Role', read: null, write: null, no_access: null },
    { name: 'Supplier Quotation', read: null, write: null, no_access: null },
    // { name: 'Purchase Order', read: null, write: null, no_access: null },
    { name: 'Purchase Inward', read: null, write: null, no_access: null },
    { name: 'Sales Order', read: null, write: null, no_access: null },
    { name: 'Disposal Action', read: null, write: null, no_access: null },
    { name: 'NC Reason', read: null, write: null, no_access: null },
    { name: 'Modules', read: null, write: null, no_access: null },
    { name: 'Supplier360', read: null, write: null, no_access: null },
    { name: 'Customer360', read: null, write: null, no_access: null },
    { name: 'Movement', read: null, write: null, no_access: null },
    { name: 'GST Slab', read: null, write: null, no_access: null },
    { name: 'GST Registration Type', read: null, write: null, no_access: null },
    { name: 'Manufacturing', read: null, write: null, no_access: null },
    { name: 'Bill of Material', read: null, write: null, no_access: null },
    { name: 'Work Order', read: null, write: null, no_access: null },
    { name: 'Job Card', read: null, write: null, no_access: null },
    { name: 'Production Data', read: null, write: null, no_access: null },
    { name: 'Document Type', read: null, write: null, no_access: null },
    { name: 'Employee Master', read: null, write: null, no_access: null },
    { name: 'Production Dashboard', read: null, write: null, no_access: null },
    { name: 'Product Family', read: null, write: null, no_access: null },
    { name: 'Product Category', read: null, write: null, no_access: null },
    { name: 'Job Card Board', read: null, write: null, no_access: null },
    { name: 'Tool Type', read: null, write: null, no_access: null },
    { name: 'Tool Sub-Type', read: null, write: null, no_access: null },
    { name: 'Tool Grade', read: null, write: null, no_access: null },
    { name: 'RMI', read: null, write: null, no_access: null },
    { name: 'Machine Dashboard', read: null, write: null, no_access: null },
    { name: 'Cutlot', read: null, write: null, no_access: null },
    { name: 'Challan Type', read: null, write: null, no_access: null },
    { name: 'Goods Challan', read: null, write: null, no_access: null },
    { name: 'Goods Challan Work Order View', read: null, write: null, no_access: null },
    { name: 'Inspection Type', read: null, write: null, no_access: null },
    { name: 'Stock Availability', read: null, write: null, no_access: null },
    { name: 'Stock Ledger', read: null, write: null, no_access: null },
    { name: 'Dispatch', read: null, write: null, no_access: null },
    { name: 'HSN Master', read: null, write: null, no_access: null },
    { name: 'Corrective Action', read: null, write: null, no_access: null },
    { name: 'Preventive Action', read: null, write: null, no_access: null },
    { name: 'Material Type', read: null, write: null, no_access: null },
    { name: 'Setting', read: null, write: null, no_access: null },
    { name: 'ProductionPlanning', read: null, write: null, no_access: null },
    { name: 'Adjustment Reason', read: null, write: null, no_access: null },
    { name: 'Material Request', read: null, write: null, no_access: null },
    { name: 'Routing Master', read: null, write: null, no_access: null },
    { name: 'Shift Master', read: null, write: null, no_access: null }
]

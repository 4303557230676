import { createSlice } from '@reduxjs/toolkit';
import { getModuleList } from '../../api/index';


const moduleSlice = createSlice({
    name: 'users',
    initialState: {
        data: [],
        loading: false,
        error: null,
    },
    reducers: {
        setModules: (state, action) => {
            
            console.log("action", action);
            state.data = action.payload.da
            state.loading = false;
        },
        setLoading: (state, action) => {
            state.loading = true;
        },
        setErr: (state, action) => {
            state.error = action.payload;
            state.data = [];
            state.loading = false;
        },

    },
});

export const { setModules, setLoading, setErr } = moduleSlice.actions;

export const fetchModules = () => async (dispatch) => {
    dispatch(setLoading());
    try {
        getModuleList().then((res) => {
            
            dispatch(setModules({ da: res.data.data.response }));
        }, (err) => {
            
            dispatch(setModules(err.response));
        });

    } catch (error) {
        // Handle error
    }
};

export default moduleSlice.reducer;

import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { updateBank_Detail, getBankAll, createBank_Detail } from "../../../../../api/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";

const schema = yup
  .object()
  .shape({
    bankName: yup
      .array()
      .min(1, "Select atleast one bank")
      .required("Bank name is required !"),
  })
  .required();

function EditBankDetail(props) {
  
  const [bankList, setBankList] = useState({ data: [], state: false });

  const { register, handleSubmit, formState, control, reset, watch, setValue } =
    useForm({
      // resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: { items: [{}] },
    }); // initialise the hook

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  useEffect(() => {
    // reset();
    setBankList({ ...bankList, state: true, data: [] });
    getBankAll().then(
      (res) => {
        
        setBankList({ ...bankList, state: false, data: res.data.bankList });
      },
      (err) => {
        
        setBankList({ ...bankList, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }, []);

  useEffect(() => {
    // reset();
    
    var bankArr = [];
    if (props.data?.length > 0) {
      for (let i = 0; i < props.data.length; i++) {
        bankArr.push({
          // bankId: props.data[i].bankId,
          // bankListId: props.data[i].bankId,
          brId: props.data[i].brId,
          bank: [{ name: props.data[i].bankName }],
          bankAccNo: props.data[i].bankAccNo,
          bankAddress: props.data[i].bankAddress,
          bankIfscCode: props.data[i].bankIfscCode,
          bankMicrCode: props.data[i].bankMicrCode,
          postalCode: props.data[i].postalCode,
        });
      }
      
      setValue("items", bankArr);
      // setValue(`items.0.bankName`, [{name : props.data[0].bankName}]);
    }
  }, [props.data]);

  const onSubmit = (data) => {
    
    const partyCode = localStorage.getItem("partyCode");

    if (
      (data !== "" || data.items.length !== 0) &&
      (partyCode !== "" || partyCode !== undefined)
    ) {
      for (let i = 0; i < data.items.length; i++) {
        data.items[i].bankName = data.items[i].bank[0]?.name;
        data.items[i].bankId = data.items[i].bank[0]?.bankId;
      }

      const reqPayload = {
        partyCode: partyCode,
        banks: data.items,
        action: "edit"
      };

      

      createBank_Detail(reqPayload).then(
          (res) => {
            
            if (res.status === 200 || res.status === 201) {
              toast.success("Customer updated Successfully !", {
                autoClose: 200,
                onClose: () => {
                  localStorage.removeItem("partyCode");
                  localStorage.removeItem("basic");
                  localStorage.removeItem("billing");
                  props.setVisible(false);
                }
              });
            }
          },
          (err) => {
            
            if (err.response.status === 400) {
              
              toast.error("Bank Details already exist ...", {
                autoClose: 3000,
              });
              reset();
            }
          }
        )
        .catch((error) => {
          
          toast.error("Something went wrong, please try again !", {
            autoClose: 3000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <>
    <ToastContainer />
      <a
        href="#"
        className="btn btn-yellow roboto-bold f-w-600"
        onClick={() => append({})}
      >
        <FontAwesomeIcon style={{ color: "#000" }} icon={faPlus} size="sm" />{" "}
        {" Bank Details"}
      </a>
      {fields.map(({ id }, index) => (
        <div className="row mt-2" key={id}>
          <div className="col-md-6">
            <div className="form-group row mt-2">
              <label className="form-label form_label roboto-bold col-md-4">
                Bank Name
                <span>
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                {/* <input
                  type="hidden"
                  className="form-control"
                  id="bankId"
                  name={`items[${index}].bankId`}
                  {...register(`items[${index}].bankId`)}
                /> */}

                <input
                  type="hidden"
                  className="form-control"
                  id="brId"
                  name={`items[${index}].brId`}
                  {...register(`items[${index}].brId`)}
                />

                <Controller
                  name={`items[${index}].bank`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      options={bankList.data !== undefined ? bankList.data : []}
                      isLoading={bankList.state}
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      //isInvalid={errors.bankName ? true : false}
                    />
                  )}
                />
              </div>
            </div>

            <div className="form-group row mt-2">
              <label className="form-label form_label roboto-bold col-md-4">
                Bank IFSC code
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  id="bankIfscCode"
                  name={`items[${index}].bankIfscCode`}
                  {...register(`items[${index}].bankIfscCode`)}
                />
              </div>
            </div>

            <div className="form-group row mt-2">
              <label className="form-label form_label roboto-bold col-md-4">
                Address
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  id="bankAddress"
                  name={`items[${index}].bankAddress`}
                  {...register(`items[${index}].bankAddress`)}
                />
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group row mt-2">
              <label className="form-label form_label roboto-bold col-md-4">
                Bank A/c No
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  id="bankAccNo"
                  name={`items[${index}].bankAccNo`}
                  {...register(`items[${index}].bankAccNo`)}
                />
              </div>
            </div>

            <div className="form-group row mt-2">
              <label className="form-label form_label roboto-bold col-md-4">
                Bank MICR code
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  id="bankMicrCode"
                  name={`items[${index}].bankMicrCode`}
                  {...register(`items[${index}].bankMicrCode`)}
                />
              </div>
            </div>

            <div className="form-group row mt-2">
              <label className="form-label form_label roboto-bold col-md-4">
                Postal code
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control"
                  maxLength={6}
                  id="postalCode"
                  name={`items[${index}].postalCode`}
                  {...register(`items[${index}].postalCode`)}
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <a
              href="#"
              className={
                index > 0
                  ? "btn btn-default border roboto-bold"
                  : "cursor_banned btn btn-default border roboto-bold"
              }
              onClick={index > 0 ? () => remove(index) : null}
            >
              <FontAwesomeIcon style={{ color: "#000"}} icon={faMinus} size="sm" /> {" Remove"}
            </a>
          </div>
        </div>
      ))}

      <div className="row justify-content-between mt-2">
        <div className="col-md-4">
          <span>
            <i style={{ color: "red" }}>*</i>
            <u> indicate Mandatory fields</u>
          </span>
        </div>
        <div className="col-sm-2 text-end">
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {"Save"}
            <i></i>
          </button>
        </div>
      </div>
    </>
  );
}

export default EditBankDetail;

import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect } from 'react';

function SupplierOverview(props) {

    
    const { name, supp_type, partyType, gst, VATNO, PANNO, email } = props.data;

    return (
        <React.Fragment>
            <div className='row'>
                <div className="col-12 col-md-12">
                    <div className="card border-0">
                        <div className="card-body p-2">
                            <div className="d-flex justify-content-between align-items-center">
                                <h5 className="roboto-bold">{"Supplier Detail"}</h5>
                                <FontAwesomeIcon
                                    className="pt-1"
                                    icon={faCircleInfo}
                                    size="sm"
                                />
                            </div>

                            <div className='bg-lightgrey'>
                                <div className="row p-2">
                                    <div className="col-12 col-md-4">
                                        <div>
                                            <p className="m-0 nc_detail_label">{`Name`}</p>
                                            <p className="mb-2 roboto-bold">{name !== "" && name !== null ? name : "NA"}</p>
                                        </div>

                                        <div>
                                            <p className="m-0 nc_detail_label">{`Supplier Type`}</p>
                                            <p className="mb-2 roboto-bold">{supp_type !== "" && supp_type !== null ? supp_type : "NA"}</p>
                                        </div>

                                        <div>
                                            <p className="m-0 nc_detail_label">{`GST No`}</p>
                                            <p className="mb-2 roboto-bold">{gst !== "" && gst !== null ? gst : "NA"}</p>
                                        </div>

                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div>
                                            <p className="m-0 nc_detail_label">{`Party Type`}</p>
                                            <p className="mb-2 roboto-bold">{partyType !== "" && partyType !== null ? partyType : "NA"}</p>
                                        </div>

                                        <div>
                                            <p className="m-0 nc_detail_label">{`VAT Number`}</p>
                                            <p className="mb-2 roboto-bold">{VATNO !== "" && VATNO !== null ? VATNO : "NA"}</p>
                                        </div>

                                        <div>
                                            <p className="m-0 nc_detail_label">{`PAN Number`}</p>
                                            <p className="mb-2 roboto-bold">{PANNO !== "" && PANNO !== null ? PANNO : "NA"}</p>
                                        </div>

                                    </div>
                                    <div className="col-12 col-md-4">
                                        <div>
                                            <p className="m-0 nc_detail_label">{`Email `}</p>
                                            <p className="mb-2 roboto-bold">{email !== "" && email !== null ? email : "NA"}</p>
                                        </div>

                                        {/* <div>
                                    <p className="m-0 nc_detail_label">{`VAT Number`}</p>
                                    <p className="mb-2 roboto-bold">{VATNO !== "" && VATNO !== null ? VATNO : "NA"}</p>
                                </div>
                            
                                <div>
                                    <p className="m-0 nc_detail_label">{`PAN Number`}</p>
                                    <p className="mb-2 roboto-bold">{PANNO !== "" && PANNO !== null ? PANNO : "NA"}</p>
                                </div> */}

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </React.Fragment>
    )
}

export default SupplierOverview
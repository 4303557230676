import React, { useState, useEffect } from 'react'
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Edit2 } from 'react-feather';
import { Modal } from 'react-bootstrap';
import { get_cutlot_images, del_cutlot_images } from '../../../api';
import { removePrefix } from '../../../common/Functions/CommonFunctions';
import { Tooltip } from 'react-tooltip';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faClose } from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";

function CutlotGallery(props) {
    const { galleryModal, setGalleryModal } = props;

    const [imageList, setImageList] = useState({ data: [], loading: false });

    useEffect(() => {
        if (galleryModal.mode) {
            setImageList({ ...imageList, data: [], loading: true });
            get_cutlot_images({ cutlot_id: galleryModal.data.cutlotId }).then((res) => {

                console.log("res", res);
                setImageList({ ...imageList, data: res.data.data, loading: false });
            }, (err) => {
                console.log("err", err);
                setImageList({ ...imageList, data: [], loading: false });
            })
        }
    }, [galleryModal.mode]);


    function deletefile(val) {
        SweetAlert.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "cancel",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                // Firing delete api
                const payload = {
                    imagePath: removePrefix(val.image),
                };
                del_cutlot_images(payload).then(
                    (res) => {

                        toast.success("Image deleted successfully !", {
                            autoClose: 3000,
                        });

                    },
                    (err) => {
                        toast.error(err.response.data.message, { autoClose: 3000 });
                    }
                );
            }
        });
    }


    return (
        <React.Fragment>
            <Modal show={galleryModal.mode} onHide={() => { setGalleryModal({ ...galleryModal, mode: false, data: {} }) }} size={"lg"}>
                <Modal.Header
                    style={{ background: "#2A3643", color: "white" }}
                    closeButton
                >
                    <Modal.Title>{props.constLabel?.lbl_cut_lot_gallery ? props.constLabel?.lbl_cut_lot_gallery : "Cutlot Gallery"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="row drawing_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">

                        {imageList.loading ?
                            <div className="mx-auto">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                            : imageList.data.length == 0 ?

                                <p className="text-danger mb-0 f-22 mx-auto mt-4">
                                    {"No image found !"}
                                </p>
                                : imageList.data.map((val, i) => (


                                    <div className="col text-center" key={i}>
                                        <div className="image-container">
                                            <img src={val.image}
                                                className="img-fluid" alt="Image 1" />

                                            <FontAwesomeIcon
                                                onClick={() => deletefile(val)}
                                                className='icon-close'
                                                style={{ color: "#000" }} icon={faClose} size="lg" />
                                        </div>

                                        {/* <span className="image-name cursor_pointer">{val.filename}</span> */}
                                    </div>

                                ))}

                    </div>

                </Modal.Body>
                {/* <Modal.Footer>
                    <button
                        // onClick={handleSubmit(onSubmit)}
                        className="f-16 btn btn-yellow "
                    >
                        {props.constLabel?.lbl_close ? props.constLabel.lbl_close : "Save"}
                    </button>
                </Modal.Footer> */}
            </Modal>

        </React.Fragment >
    )
}

export default CutlotGallery
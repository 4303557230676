import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { getCountry, getState, getCity, get_country, get_state, get_city } from "../../../../../api/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";

const schema = yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      addressLine1: yup.string().required("Address is required"),
      // addressLine2: yup.string().required('Country is required'),
      cCountry: yup
        .array()
        .min(1, "Please select country !")
        .required("Please select country !"),
      cState: yup
        .array()
        .min(1, "Please select state !")
        .required("Please select state !"),
      cCity: yup
        .array()
        .min(1, "Please select city !")
        .required("Please select city !"),
      postalCode: yup
        .string()
        .test("is-number", "Enter valid postal code", (value) => {
          // Test function to check if the postalCode consists only of numbers
          if (!value) {
            return true; // Skip validation if no value is present
          }
          return /^\d+$/.test(value);
        }),
      // yup.string().matches(/^[0-9]*$/, 'Enter valid postal code').notRequired(),
    })
  ),
});

function EditBillingAddress(props) {
  
  const partyCode = localStorage.getItem("partyCode");

  const [countryList, setCountryList] = useState({ data: [], state: false });
  const [stateList, setStateList] = useState({ data: [], state: false });
  const [cityList, setCityList] = useState({ data: [], state: false });

  const [constLabel, setConstLabel] = useState({});

  let stateData = [];
  let cityData = [];

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [{}],
    },
  }); // initialise the hook

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  let { errors } = formState;

  useEffect(() => {
    localStorage.removeItem("stateData");
    localStorage.removeItem("cityData");
    setConstLabel(getLabels('Party Master', 'Customer'));
    setCountryList({ ...countryList, state: true, data: [] });
    get_country().then(
      (res) => {
        
        setCountryList({
          ...countryList,
          state: false,
          data: res.data.data,
        });

        //
        var billArr = [];
        if (props.data?.length > 0) {
          for (let i = 0; i < props.data.length; i++) {
            // if(res.data.countryList !== undefined && res.data.countryList.length > 0){
            // const cn = res.data.data.find(
            //   (c) => c.uuid === props.data[i]?.country_uuid
            // );
            
            // }
            billArr.push({
              uuid: props.data[i]?.uuid,
              addressType: props.data[i]?.addressType,
              addressId: props.data[i]?.addressId,
              addressLine1: props.data[i].addressLine1,
              addressLine2: props.data[i].addressLine2,
              cCountry: [{ id: props.data[i]?.country, countryName: props.data[i]?.country_name != null ? props.data[i]?.country_name : "" , uuid: props.data[i]?.country_id != null ? props.data[i]?.country_id : ""  }],
              cState: [{ id: props.data[i]?.state, stateName: props.data[i]?.state_name != null ? props.data[i]?.state_name : "", uuid: props.data[i]?.state_id != null ? props.data[i]?.state_id : "" }],
              dispState: [props.data[i]?.state_name],
              cCity: [{ id: props.data[i]?.city, cityName: props.data[i]?.city_name != null ? props.data[i]?.city_name : "", uuid: props.data[i]?.city_id != null ? props.data[i]?.city_id : ""}],
              dispCity: [props.data[i]?.city_name],
              postalCode: props.data[i]?.postalCode,
            });
          }
          
          // setValue("items", billArr);
          reset({items : billArr })
        }
      },
      (err) => {
        
        setCountryList({ ...countryList, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }, [props.data]);

  // useEffect(() => {
  
  //   reset();
  

  
  //   var billArr = [];
  //   if (props.data?.length > 0) {
  //     for (let i = 0; i < props.data.length; i++) {
  //       if(countryList.data !== undefined && countryList.data.length > 0){
  //         const cn = countryList.data.find((c) => c.id === parseInt(props.data[i].country));
  
  //       }
  //       billArr.push({
  //         addressType: props.data[i].addressType,
  //         addressId: props.data[i].addressId,
  //         addressLine1: props.data[i].addressLine1,
  //         addressLine2: props.data[i].addressLine2,
  //         // cCountry: [{ countryName: cn ? cn.countryName : '' }],
  //         cState: [{ stateName: props.data[i].statename }],
  //         cCity: [{ cityname: props.data[i].cityname }],
  //         postalCode: props.data[i].postalCode,
  //       });
  //     }
  
  //     setValue("items", billArr);

  //     // reset({
  //     //   items : [
  //     //     {
  //     //       addressType: props.data[i].addressType,
  //     //       addressId: props.data[i].addressId,
  //     //       addressLine1: props.data[i].addressLine1,
  //     //       addressLine2: props.data[i].addressLine2,
  //     //       cCountry: [{ countryName: props.data[i].countryName }],
  //     //       cState: [{ stateName: props.data[i].stateName }],
  //     //       cCity: [{ cityName: props.data[i].cityName }],
  //     //       postalCode: props.data[i].postalCode,
  //     //     }
  //     //   ]
  //     // });
  //   }

  //   //setValue('items', billArr);
  //   // setValue("items", [{ addressLine1: "oijaosdijasoidjaso" }]);
  // }, [props.data]);

  function handleCountryChange(index, e) {
    
    

    const tempVar = "items." + index + "." + "cCountry";
    
    setValue(tempVar, e, { shouldValidate: true });
    
    if (tempVar !== undefined || tempVar.length !== 0) {
      if (e.length > 0) {
        const statePayload = {
          country_id: e[0].uuid,
        };
        setStateList({ ...stateList, state: true, data: [] });
        get_state(statePayload).then(
          (res) => {
            
            

            
            if (JSON.parse(localStorage.getItem("stateData")) !== null) {
              stateData = JSON.parse(localStorage.getItem("stateData"));
            }

            stateData.splice(index, 0, res.data.data);

            localStorage.setItem("stateData", JSON.stringify(stateData));

            
            setStateList({ ...stateList, state: false, data: stateData });
          },
          (err) => {
            
          }
        );
      }
    }
  }

  function handleStateChange(index, e) {
    
    

    const tempVar = "items." + index + "." + "cState";
    
    setValue(tempVar, e, { shouldValidate: true });
    
    if (tempVar !== undefined || tempVar.length !== 0) {
      if (e.length > 0) {
        const cityPayload = {
          state_id: e[0].uuid,
        };
        setCityList({ ...cityList, state: true, data: [] });
        get_city(cityPayload).then(
          (res) => {
            
            

            
            if (JSON.parse(localStorage.getItem("cityData")) !== null) {
              cityData = JSON.parse(localStorage.getItem("cityData"));
            }

            cityData.splice(index, 0, res.data.data);

            localStorage.setItem("cityData", JSON.stringify(cityData));

            
            setCityList({ ...cityList, state: false, data: cityData });
          },
          (err) => {
            
          }
        );
      }
    }
  }

  const delBillingAddress = (index) => {
    
  };

  const onSubmit = (data) => {
    
    

    if (data !== "" || data.items.length !== 0) {
      // for set country : id from cCountry object likewise state and city
      for (let i = 0; i < data.items.length; i++) {
        data.items[i].country = data.items[i].cCountry[0]?.countryName;
        data.items[i].state = data.items[i].cState[0]?.stateName;
        data.items[i].city = data.items[i].cCity[0]?.cityName;
        data.items[i].country_id = data.items[i].cCountry[0]?.uuid;
        data.items[i].state_id = data.items[i].cState[0]?.uuid;
        data.items[i].city_id = data.items[i].cCity[0]?.uuid;
      }

      localStorage.setItem("billing", JSON.stringify(data.items));
      props.setKey("shippingAddress");
    } else {
      errors.showMessages();
    }
  };

  return (
    <>
      <a
        className="btn btn-yellow roboto-bold"
        onClick={() => {
          append({});
          
          
          //   "localStorage.getItem(stateData)",
          //   JSON.parse(localStorage.getItem("stateData"))
          // );
        }}
      >
        <FontAwesomeIcon style={{ color: "#000" }} icon={faPlus} size="sm" />{" "}
        {constLabel?.lbl_address ? constLabel.lbl_address : "Address"}
      </a>
      {fields.map(({ id }, index) => (
        <div className="row mt-3" key={id}>
          <div className="col-md-6">
            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
                {constLabel?.lbl_address_line1 ? constLabel.lbl_address_line1 : "Address Line1"}
                <span><i style={{ color: "red" }}>*</i></span>
              </label>
              <div className="col-md-8">
                <input
                  type="hidden"
                  className="form-control form-control-sm"
                  id="addressType"
                  name={`items[${index}].addressType`}
                  {...register(`items[${index}].addressType`)}
                />

                <input
                  type="hidden"
                  className="form-control form-control-sm"
                  id="addressId"
                  name={`items[${index}].addressId`}
                  {...register(`items[${index}].addressId`)}
                />

                <input
                  type="text"
                  className={ errors.items && errors.items[index] && errors.items[index].addressLine1 ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"}
                  id="addressLine1"
                  name={`items[${index}].addressLine1`}
                  placeholder="Enter address line1"
                  {...register(`items[${index}].addressLine1`)}
                />
                {errors.items && errors.items[index] && (
                  <span className="text-danger err-msg">
                    {errors.items[index].addressLine1?.message}
                  </span>
                )}
              </div>
            </div>

            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
                {constLabel?.lbl_country ? constLabel.lbl_country : "Country"}<span><i style={{ color: "red" }}>*</i></span>
              </label>
              <div className="col-md-8">
                <Controller
                  name={`items[${index}].cCountry`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="countryName"
                      size="sm"
                      multiple={false}
                      options={
                        countryList.data !== undefined ? countryList.data : []
                      }
                      placeholder="Select country"
                      isLoading={countryList.state}
                      onChange={(e) => {
                        handleCountryChange(index, e);
                      }}
                      onBlur={onBlur}
                      defaultSelected={value}
                      isInvalid={errors.items && errors.items[index] && errors.items[index].cCountry ? true : false}
                      
                    />
                  )}
                />
                {errors.items && errors.items[index] && (
                  <span className="text-danger err-msg">
                    {errors.items[index].cCountry?.message}
                  </span>
                )}
              </div>
            </div>

            <div className="form-group row mt-2">
              <label className="control-label col-md-4"></label>
              <label className="col-md-8">
                Current city is{" "}
                <input
                  className="border-0 roboto-bold"
                  name={`items[${index}].dispCity`}
                  readOnly
                  {...register(`items[${index}].dispCity`)}
                />
              </label>
            </div>

            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-md-4">City<span><i style={{ color: "red" }}>*</i></span></label>
              <div className="col-md-8">
                <Controller
                  name={`items[${index}].cCity`}
                  control={control}
                  // rules={{ validate: validateCCity }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="cityName"
                      size="sm"
                      multiple={false}
                      options={
                        cityList.data.length > 0
                          ? cityList.data[index] === undefined
                            ? []
                            : cityList.data[index]
                          : cityList.data
                      }
                      placeholder="Select city"
                      isLoading={cityList.state}
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultSelected={value}
                      isInvalid={errors.items && errors.items[index] && errors.items[index].cCity ? true : false}
                      
                    />
                  )}
                />
                {errors.items && errors.items[index] && (
                  <span className="text-danger err-msg">
                    {errors.items[index].cCity?.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
                Address Line2
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="addressLine2"
                  name={`items[${index}].addressLine2`}
                  placeholder="Enter address line2"
                  {...register(`items[${index}].addressLine2`)}
                />
              </div>
            </div>

            <div className="form-group row mt-2">
              <label className="control-label col-md-4"></label>
              <label className="col-md-8">
                Current state is{" "}
                <input
                  className="border-0 roboto-bold"
                  readOnly
                  name={`items[${index}].dispState`}
                  {...register(`items[${index}].dispState`)}
                />
              </label>
            </div>
            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-md-4">State<span><i style={{ color: "red" }}>*</i></span></label>
              <div className="col-md-8">
                <Controller
                  name={`items[${index}].cState`}
                  control={control}
                  // rules={{ validate: validateCState }}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="stateName"
                      size="sm"
                      multiple={false}
                      onChange={(e) => {
                        handleStateChange(index, e);
                      }}
                      onBlur={onBlur}
                      options={
                        stateList.data.length > 0
                          ? stateList.data[index] === undefined
                            ? []
                            : stateList.data[index]
                          : stateList.data
                      }
                      placeholder="Select state"
                      isInvalid={errors.items && errors.items[index] && errors.items[index].cState ? true : false}
                      defaultSelected={value}
                      
                    />
                  )}
                />
                {errors.items && errors.items[index] && (
                  <span className="text-danger err-msg">
                    {errors.items[index].cState?.message}
                  </span>
                )}
              </div>
            </div>

            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
                Postal code
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="postalCode"
                  name="postalCode"
                  maxLength={6}
                  placeholder="Enter Postal Code"
                  {...register(`items[${index}].postalCode`)}
                />
                {errors.items && errors.items[index] && (
                  <span className="text-danger err-msg">
                    {errors.items[index].postalCode?.message}
                  </span>
                )}
              </div>
            </div>
          </div>
          <div className="mt-3">
            <a
              className={
                index > 0
                  ? "btn btn-default border roboto-bold"
                  : "cursor_banned btn btn-default border roboto-bold"
              }
              onClick={
                index > 0
                  ? () => {
                      remove(index);
                      delBillingAddress(index);
                    }
                  : null
              }
            >
              <FontAwesomeIcon
                style={{ color: "#000", border: "1px" }}
                icon={faMinus}
                size="sm"
              />{" "}
              {" Remove"}
            </a>
          </div>
        </div>
      ))}

      <div className="row justify-content-between mt-2">
        <div className="col-md-4">
          <span>
            <i style={{ color: "red" }}>*</i>
            <u> indicate Mandatory fields</u>
          </span>
        </div>
        <div className="col-sm-2 text-end">
          <button
            onClick={handleSubmit(onSubmit)}
            className="btn btn-yellow roboto-bold"
          >
            {"Next"}
            {/* <FontAwesomeIcon
              style={{ color: "#000" }}
              icon={faPlay}
              size="sm"
            /> */}
          </button>
        </div>
      </div>
    </>
  );
}

export default EditBillingAddress;

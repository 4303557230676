import axios from "axios";
// import { RefreshToken } from "./index";
import { toast } from "react-toastify";

const client2 = axios.create({
  baseURL: process.env.REACT_APP_API_INV_URL,
});


// function saveToken(access_token) {
//   localStorage.setItem("access", access_token);
//   // localStorage.setItem('refresh', refresh_token);
// }

// function destroyToken() {
//   localStorage.removeItem("access");
//   localStorage.removeItem("refresh");
// }

// function refresh() {
//   return new Promise((resolve, reject) => {
//     client2
//       .post(RefreshToken, {
//         refresh: localStorage.getItem("refresh"),
//       })
//       .then((response) => {
//         saveToken(response.data.access, response.data.refresh_token);
//         return resolve(response.data.access_token);
//       })
//       .catch((error) => {
//         destroyToken();
//         window.location.replace(`${process.env.PUBLIC_URL}/login`);
//         return reject(error);
//       });
//   });
// }

client2?.interceptors.request.use((config) => {



  let congnito = localStorage.getItem("congnito");
  // let normal = localStorage.getItem("normal");
  if (congnito == "true") {
    var authToken = localStorage.getItem("access");
    var idtoken = localStorage.getItem('id');


    config.headers = {
      "X-AUTH-TOKEN": `${authToken}`,
      'X-ID-TOKEN': `${idtoken}`
    };
    //  console.log(config);
    return config;
  }
  else {
    var authToken = localStorage.getItem("access");
    config.headers = { Authorization: `Bearer ${authToken}` };
    // console.log(config);
    return config;
  }

});

client2?.interceptors.response.use(
  (res) => res,
  async (error) => {
    // console.log("error", error);
    const status = error.response ? error.response.status : null;

    if(error && (error.code)  === "ERR_NETWORK" && status==null){
      let ref_token = JSON.parse(localStorage.getItem('localData'));
      var username = localStorage.getItem('user_name');

      const payload = {
        "username": username,
        "refreshToken": ref_token.refreshToken
      }
      await axios
      // .post(`${process.env.REACT_APP_COGNITO_REFRESH_URL}`, payload)
      .post(`${process.env.REACT_APP_COGNITO_AUTH_URL}/login/refresh`, payload)
      .then((response) => {
         localStorage.setItem("access", response.data.data.response.accessToken);
         localStorage.setItem('id', response.data.data.response.idToken);
        //  window.location.replace(`${process.env.REACT_APP_HOME_URL}pcms/customer`);
         window.location.reload();
      })
      .catch((err) => {
        // console.log("err",err)
        localStorage.clear();
        window.location.replace(`${process.env.PUBLIC_URL}/login`);
      });

    }

    // console.log("status", status);
    switch (status) {
      case 401:
    
        localStorage.clear();
        window.location.replace(`${process.env.PUBLIC_URL}/login`);
        break;


      case 403:

        localStorage.clear();
        window.location.replace(`${process.env.PUBLIC_URL}/login`);
        break;
      //localStorage.removeItem('refresh');

      case 400:
        break;

      case 502:
        toast.error("Bad Gateway", {
          autoClose: 2000,
        });
        break;

      default:
        // return null;
        break;
    }

    if (status === 401 || status === 403) {
      //window.location.replace('/login');
      // localStorage.removeItem("access");
      // window.location.replace(`${process.env.PUBLIC_URL}/login`);
      //localStorage.removeItem('refresh');
    }

    // status might be undefined
    if (!status) {
      // refresh();
    }
    return Promise.reject(error);
  }
);

export default client2;

import React, { useState, useEffect } from "react";
import "./productStepper.css"; // Import your CSS file for styling
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useNavigate, useLocation } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLabels } from "../../../common/Functions/CommonFunctions";
import { getProductType } from "../../../api";
import UploadProductImages from "./ProductForms/Image-Upload/UploadProductImages";
import ProductAttribute from "./ProductForms/Product-Attribute/ProductAttribute";
import ProductSpecification from "./ProductForms/Product-Specification/ProductSpecification";
import PreviewProduct from "./PreviewProduct";
import CloneManufacturedGoods from "./CloneForms/CloneManufacturedGoods";
import CloneRawMaterial from "./CloneForms/CloneRawMaterial";
import CloneSemiFinishGoods from "./CloneForms/CloneSemiFinishGoods";
import CloneProductImages from "./CloneForms/CloneProductImages";
import CloneProductAttribute from "./CloneForms/CloneProductAttribute";
import CloneProductSpecification from "./CloneForms/CloneProductSpecification";
import { MANUFACTURED_GOODS, FINISHED_GOODS, SEMI_FINISH_GOODS, RAW_MATERIAL, CONSUMABLE_ITEMS, FIXTURES, GAUGES, GENERAL_ITEM } from "../../../common/Constants/constants";

function CloneProductConfigurator() {
  let navigate = useNavigate();
  let location = useLocation();
  let old_prod_uuid = location.state.uuid;
  if (location.pathname === "/pcms/cloneProductConfigurator" && location.state && location.state.uuid) {
    delete location.state.uuid;
  }
  const [prodType, setProdType] = useState({ data: [], loading: false });
  const [currentStep, setCurrentStep] = useState(1);
  const [main_obj, setMain_obj] = useState({ prod_overview: location.state, prod_image: [], prod_attrib: [], prod_specs: [], obj_bom: { data: {}, flag: false }, old_prod_uuid: old_prod_uuid })
  const steps_array = [
    "Product Overview",
    "Upload Product Images",
    "Product Specification",
    "Product Attribute",
  ];
  const numSteps = 4;
  const [constLabel, setConstLabel] = useState({});

  const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const selProductType = watch("type");
  

  useEffect(() => {
    
    setConstLabel(getLabels("Master", "Product"));
    // setMain_obj({...main_obj, prod_overview: location.state});
    fetchProductType();
  }, []);

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        
        setProdType({ ...prodType, data: res.data.data, loading: false });
        // setValue('type', [res.data.data[0]]);
      },
      (err) => {
        
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }

  const nextStep = () => {
    
    let next = currentStep + 1;
    // if (next > numSteps) {
    //     next = 1;
    // }
    setCurrentStep(next);
  };


  const previousStep = (stepNumber) => {
    if (stepNumber !== undefined && stepNumber >= 1 && stepNumber <= numSteps) {
      setCurrentStep(stepNumber);
    } else {
      let prev = currentStep - 1;
      if (prev < 1) {
        prev = numSteps;
      }
      setCurrentStep(prev);
    }
  };

  return (
    <React.Fragment>
      {currentStep !== 5 && (
        <div className="mb-4">
          <div className="card">
            <div className="card-header bg-grey d-flex align-items-center">
              <button
                className="btn btn-yellow"
                onClick={() => navigate("/pcms/product")}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="sm" />
              </button>

              <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
                {constLabel?.lbl_clone_product_configurator ? constLabel?.lbl_clone_product_configurator : "Clone Product Configurator"}
              </p>
            </div>
            <div className="card-body" id="party_master">
              <div className="stepper-horizontal" id="stepper1">
                {[...Array(numSteps)].map((_, index) => {
                  const stepNum = index + 1;
                  const isEditing = stepNum === currentStep ? "editing" : "";
                  const isDone = stepNum < currentStep ? "done" : "";

                  return (
                    <div className={`step ${isEditing} ${isDone}`} key={index}>
                      <div className="step-circle">
                        <span>{stepNum}</span>
                      </div>
                      <div className="step-title">{steps_array[stepNum - 1]}</div>
                      <div className="step-bar-left"></div>
                      <div className="step-bar-right"></div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="card-body">
              <h4 className="roboto-bold">{steps_array[currentStep - 1]}</h4>
              <hr />
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_product_type
                        ? constLabel?.lbl_product_type
                        : "Product Type"}
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="type"
                      control={control}
                      defaultValue={
                        location.state?.product_type_id?.productType !==
                          undefined &&
                          location.state?.product_type_id?.productType !== null
                          ? [location.state?.product_type_id]
                          : []
                      }
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="productType"
                          className="mt-2"
                          multiple={false}
                          options={prodType.data}
                          isLoading={prodType.loading}
                          placeholder="Select product type"
                          onChange={onChange}
                          disabled={location.pathname == "/pcms/cloneProductConfigurator" ? true : false}
                          defaultSelected={
                            main_obj.prod_overview?.product_type_id?.productType !==
                              undefined &&
                              main_obj.prod_overview?.product_type_id?.productType !== null
                              ? [main_obj.prod_overview?.product_type_id]
                              : []
                          }
                          selected={value}
                          onBlur={onBlur}
                          isInvalid={errors.type ? true : false}
                        />
                      )}
                    />

                    {errors.type && (
                      <span className="text-danger err-msg">
                        {errors.type.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <hr />
              {currentStep === 1 && (
                <>

                  {(location.state.productTypename === MANUFACTURED_GOODS || location.state.productTypename === FINISHED_GOODS) && (
                    <CloneManufacturedGoods
                      main_obj={main_obj}
                      setMain_obj={setMain_obj}
                      nextStep={nextStep}
                      // data={location.state}
                      product_type={selProductType?.[0]}
                    />
                  )}
                  {location.state.productTypename === RAW_MATERIAL && (
                    <CloneRawMaterial
                      main_obj={main_obj}
                      setMain_obj={setMain_obj}
                      nextStep={nextStep}
                      // data={location.state}
                      product_type={selProductType?.[0]}
                    />
                  )}
                  {location.state.productTypename === SEMI_FINISH_GOODS && (
                    <CloneSemiFinishGoods
                      main_obj={main_obj}
                      setMain_obj={setMain_obj}
                      nextStep={nextStep}
                      // data={location.state}
                      product_type={selProductType?.[0]}
                    />
                  )}
                </>
              )}
              {currentStep === 2 && (
                <CloneProductImages
                  // currentStep={currentStep}
                  // setCurrentStep={setCurrentStep}
                  main_obj={main_obj}
                  setMain_obj={setMain_obj}
                  nextStep={nextStep}
                  previousStep={previousStep}
                // data={location.state}
                />
              )}
              {currentStep === 3 && (
                <CloneProductSpecification
                  // currentStep={currentStep}
                  // setCurrentStep={setCurrentStep}
                  main_obj={main_obj}
                  setMain_obj={setMain_obj}
                  nextStep={nextStep}
                  previousStep={previousStep}
                // data={location.state}
                // bomStat={bomStat}
                />


              )}
              {currentStep === 4 && (
                <CloneProductAttribute
                  // currentStep={currentStep}
                  // setCurrentStep={setCurrentStep}
                  main_obj={main_obj}
                  setMain_obj={setMain_obj}
                  nextStep={nextStep}
                  previousStep={previousStep}
                // data={location.state}
                />
              )}

            </div>
          </div>
        </div>
      )}
      {currentStep === 5 && (
        <PreviewProduct
          main_obj={main_obj}
          setMain_obj={setMain_obj}
          nextStep={nextStep}
          previousStep={previousStep}
        />
      )}
    </React.Fragment>
  )
}

export default CloneProductConfigurator
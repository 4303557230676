import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare, faAddressBook} from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "react-tooltip";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";


function ViewBillingAddress(props) {

  const [visible, setVisible] = useState(false);
  const [constLabel, setConstLabel] = useState({});

  useEffect(() => {
    setConstLabel(getLabels('Party Master', 'Customer'));
  }, [])
  

  return (
    <React.Fragment>
      <Tooltip id={"view-tooltip"} place="top" />
      <button
        data-tooltip-id={"view-tooltip"}
        data-tooltip-content={constLabel?.lbl_billing_address ? constLabel.lbl_billing_address :"Billing Address"}
        className="btn border roboto-bold"
        type="button"
        // style={{ padding: "0rem 0.5rem" }}
        onClick={() => {
          setVisible(true);
        }}
      >
        {/* <FontAwesomeIcon style={{ color: "#000" }} icon={faPenToSquare} size="sm" />{" "} */}
        <FontAwesomeIcon style={{ color: "#000" }} icon={faAddressBook} size="lg" />{" "}
        {/* {" Billing"} */}
      </button>
      <Modal
        show={visible} onHide={() => setVisible(false)} size={"lg"}
        className="modal-dialog-centered"
      >
        <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>{constLabel?.lbl_billing_address ? constLabel.lbl_billing_address : "Billing Address"}</Modal.Title>
          </Modal.Header>
        <Modal.Body>
          <form action="">
            {props.data && props.data.length > 0 ? (
              <div className="table-responsive">
                <table
                  className="table table-bordered mt-2 table-bordered"
                  width={"100%"}
                >
                  <thead className="thead-light text-ceter">
                    <tr className="">
                      <th scope="col">{"Sr.No"}</th>
                      <th scope="col">{constLabel?.lbl_address1 ? constLabel.lbl_address1 : "Address1"}</th>
                      <th scope="col">{constLabel?.lbl_address2 ? constLabel.lbl_address2 : "Address2"}</th>
                      <th scope="col">{constLabel?.lbl_country ? constLabel.lbl_country : "Country"}</th>
                      <th scope="col">{constLabel?.lbl_state ? constLabel.lbl_state : "State"}</th>
                      <th scope="col">{constLabel?.lbl_city ? constLabel.lbl_city : "City"}</th>
                      <th scope="col">{constLabel?.lbl_postal_code ? constLabel.lbl_postal_code : "Postal Code"}</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {props.data.map((val, i) => (
                      <tr key={i} className="f-14">
                        <td>{i+1}</td>
                        <td>{val.addressLine1 != "" &&  val.addressLine1 != null ? val.addressLine1 : "NA"}</td>
                        <td>{val.addressLine2 && val.addressLine2 != null ? val.addressLine2 : "NA"}</td>
                        <td>{val.country_name && val.country_name != null ? val.country_name : "NA"}</td>
                        <td>{val.state_name && val.state_name != null ? val.state_name : "NA"}</td>
                        <td>{val.city_name && val.city_name != null ? val.city_name : "NA"}</td>
                        <td>{val.postalCode && val.postalCode != null ? val.postalCode : "NA"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-danger mb-0 f-22 text-center mt-4">
                {"Billing Address list is empty"}
              </p>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default ViewBillingAddress;
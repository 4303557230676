import React, {useState, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from 'yup';
import { Typeahead } from "react-bootstrap-typeahead";
import { OverlayTrigger } from "react-bootstrap";
import { Copy, Trash2 } from "react-feather";
import AddEditParameter from "./AddEditParameter";
import { Tooltip } from "react-bootstrap";
import EditOperationList from "./EditOperationList";
import DrawingFile from "./DrawingFile";
import AddEditMachine from "./AddEditMachine";
import AddEditTool from "./AddEditTool";
import { Modal } from "react-bootstrap";

const schema = yup.object().shape({
    items: yup.array().of(
      yup.object().shape({
        sequenceNo: yup
            .number()
            .typeError("Please enter a valid number")
            .required("Sequence no is required !"),
        operation: yup
            .array()
            .min(1,"Select atleast one type")
            .required("Operation is required !"),
      })
    ),
    // selfile: yup
    //     .mixed()
    //     .test('fileSize', 'Image can be uploaded upto 10 MB',
    //         (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)),
  })
  .required();

function OperationList() {
    const [typeList, setTypeList] = useState({ data:[{name: "type1"}, {name: "type2"}], loading: false});
    const [visible, setVisible] = useState(false);
    
    const { register, handleSubmit, formState, control, reset, watch } = useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        items: [{ sequenceNo: "", operation: [], operationCost: "",  route: [] }],
      },
    }); // initialise the hook
    
    const { fields, append, remove } = useFieldArray({
        control,
        name: "items",
      });
    
    let { errors } = formState;
    
    const onSubmit = (data) => {
      
    
    //   if (data !== "") {
    //     const reqPayload = {
    //       uuid: props.data.uuid,
    //       typename: data.typename,
    //     };
    
    //     
    
    //     updateLocationType(reqPayload).then(
    //         (res) => {
    //           
    //           if (res.status === 200 || res.status === 201) {
    //             toast.success("Location type update Successfully !", {
    //               autoClose: 3000,
    //             });
    //             setVisible(false);
    //             props.fetchLocationType();
    //           }
    //         },
    //         (err) => {
    //           
    //           if (err.response.status === 400) {
    //             
    //             toast.error(err.response, {
    //               autoClose: 2000,
    //             });
    //             reset();
    //           }
    //         }
    //       )
    //       .catch((error) => {
    //         
    //         toast.error("Something went wrong, please try again !", {
    //           autoClose: 2000,
    //         });
    //       });
    //   } else {
    //     errors.showMessages();
    //   }
    };

  return (
    <React.Fragment>
      <Modal show={visible} onHide={() => setVisible(false)} size={"xl"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Add Operation"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div
              className="table-responsive py-3"
              style={{ overflowX: "scroll" }}
            >
              <table className="table table-bordered">
                <thead className="thead-light text-center">
                  <tr>
                    <th scope="col">{"Sequence No"}</th>
                    <th scope="col">{"Operation"}</th>
                    <th scope="col">{"Operation Cost"}</th>
                    <th scope="col">{"Route"}</th>
                    <th scope="col">{"Action"}</th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map(({ id }, index) => (
                    <tr key={id} className="f-18">
                      <td>
                      <input
                          className="form-control form-control-sm"
                          type="text"
                          name={`items[${index}].sequenceNo`}
                          {...register(`items[${index}].sequenceNo`)}
                          // onChange={(e) => handleFileChange(e, index)}
                          placeholder="Enter sequence no"
                        />

                        {errors.items?.[index]?.sequenceNo && (
                          <span className="text-danger err-msg">
                            {errors.items?.[index]?.sequenceNo.message}
                          </span>
                        )}

                        {index + 1 == fields.length ? (
                          <div>
                            <button
                              style={{ fontSize: "16px" }}
                              className="btn btn-sm button-primary px-2 mt-2"
                              onClick={() => append({})}
                            >
                              <FontAwesomeIcon
                                style={{
                                  color: "#ffffff",
                                  marginRight: "5px",
                                }}
                                icon={faAdd}
                                size="sm"
                              />{" "}
                              Add
                            </button>
                          </div>
                        ) : null}
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                      <Controller
                          name={`items[${index}].operation`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              size="sm"
                              id="basic-typeahead"
                              labelKey="name"
                              multiple={false}
                              options={typeList.data}
                              isLoading={typeList.loading}
                              placeholder="Select operation"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={
                                errors.items?.[index]?.operation ? true : false
                              }
                            />
                          )}
                        />
                        {errors.items?.[index]?.operation && (
                          <span className="text-danger err-msg">
                            {errors.items?.[index]?.operation.message}
                          </span>
                        )}
                      </td>

                      <td style={{ verticalAlign: "top" }}>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          name={`items[${index}].operationCost`}
                          {...register(`items[${index}].operationCost`)}
                          // onChange={(e) => handleFileChange(e, index)}
                          placeholder="Enter operation cost"
                        />

                        {errors.items?.[index]?.operationCost && (
                          <span className="text-danger err-msg">
                            {errors.items?.[index]?.operationCost.message}
                          </span>
                        )}
                      </td>

                      <td style={{ verticalAlign: "top" }}>
                      <Controller
                          name={`items[${index}].route`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              size="sm"
                              id="basic-typeahead"
                              labelKey="name"
                              multiple={false}
                              options={typeList.data}
                              isLoading={typeList.loading}
                              placeholder="Select route"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={
                                errors.items?.[index]?.route ? true : false
                              }
                            />
                          )}
                        />
                        {errors.items?.[index]?.route && (
                          <span className="text-danger err-msg">
                            {errors.items?.[index]?.route.message}
                          </span>
                        )}
                      </td>

                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "top",
                          width: "10%",
                        }}
                      >
                        <div
                          className="deleteIcon cursor_pointer"
                          onClick={index > 0 ? () => remove(index) : null}
                        >
                          <Trash2 color="#FFFFFF" size={18} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
      <div className="row mb-3">
        <div className="card border-0">
          <div className="card-body">
            <div className="d-flex justify-content-end">
            <button
              className="btn button-primary px-4"
              onClick={() => {
                setVisible(true);
                reset();
              }}
            >
              <FontAwesomeIcon
                style={{ color: "#fff" }}
                icon={faPlus}
                size="sm"
              />{" "}
              Add Operation
            </button>
            </div>
            <table className="table mt-2 table-bordered table-sm">
              <thead className="table-grey  roboto-bold">
                <tr className="">
                  <th scope="col">{"Sr. No"}</th>
                  <th scope="col">{"Sequence No"}</th>
                  <th scope="col">{"Operation Name"}</th>
                  <th scope="col">{"Operation Cost"}</th>
                  <th scope="col">{"Has OutSource"}</th>
                  <th scope="col">{"Route"}</th>
                  <th scope="col">{"Drawing File"}</th>
                  <th scope="col">{"Add/Edit Parameters"}</th>
                  <th scope="col">{"Add/Edit Machine"}</th>
                  <th scope="col">{"Add/Edit Tool"}</th>
                  <th scope="col" width="15%">
                    {"Action"}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>

                  <td>
                    <DrawingFile />
                  </td>
                  <td>
                    <AddEditParameter />
                  </td>
                  <td>
                    <AddEditMachine />
                  </td>
                  <td>
                    <AddEditTool />
                  </td>

                  <td>
                    <EditOperationList />
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">{"Delete Operation"}</Tooltip>
                      }
                    >
                      <div className="deleteIcon m-1 cursor_pointer">
                        <Trash2 color="#FFFFFF" size={18} />
                      </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip">{"Clone Operation"}</Tooltip>
                      }
                    >
                      <div className="downloadIcon m-1 cursor_pointer">
                        <Copy color="#FFFFFF" size={18} />
                      </div>
                    </OverlayTrigger>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default OperationList;

import React, { useState, useEffect } from "react";
import { Modal, Pagination } from "react-bootstrap";
import { getQuotation, getQuote_Products } from "../../../api";
import { Eye } from "react-feather";
import { Tooltip } from "react-tooltip";
import { calMaxPage, convertDate } from "../../../common/Functions/CommonFunctions";

function SupplierQuoteList(props) {
  const [dataList, setDataList] = useState({
    data: [],
    loading: false,
    totalRecords: "",
  });
  const [quoteProd, setQuoteProd] = useState({ data: [], loading: false });
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [show2, setShow2] = useState(false);
  const [selData, setSelData] = useState({});
  // const [total, setTotal] = useState("");
  const [total, setTotal] = useState(100000);

  useEffect(() => {
    if (
      props.selectedSupp.uuid !== undefined &&
      props.selectedSupp.uuid !== null
    ) {
      fetchQuoteList(1, entriesPerPage);
    }
  }, [props.selectedSupp.uuid]);

  useEffect(() => {
    if (show2) {
      if (selData.uuid !== undefined && selData.uuid !== null) {
        // fetchQuoteProd();
        setQuoteProd({
          ...quoteProd,
          data: selData.quote_products,
          loading: false,
        });
        const tot = selData.quote_products.reduce(
          (accumulator, currentValue) => {
            return parseFloat(accumulator) + parseFloat(currentValue.amount);
          },
          0
        );

        setTotal(tot);
      }
    }
  }, [show2, selData.uuid]);

  // function fetchQuoteProd() {
  //   setQuoteProd({ ...quoteProd, loading: true });
  //   const payload = {
  //     quote_id: selData?.uuid,
  //   };

  //   if(payload.quote_id == "" && payload.quote_id == null){
  //     return;
  //   }
  //   getQuote_Products(payload).then(
  //     (res) => {

  //       setQuoteProd({ ...quoteProd, data: res.data.data, loading: false ,});
  //       const tot = res.data.data.reduce((accumulator, currentValue) => {
  //           // Extract the 'number' property from each object and add it to the accumulator
  //           return parseFloat(accumulator) + parseFloat(currentValue.amount);
  //         }, 0);

  //         setTotal(tot);
  //     },
  //     (err) => {

  //       setQuoteProd({ ...quoteProd, data: [], loading: false });
  //     }
  //   );
  // }

  function fetchQuoteList(pg, epp) {
    const payload = {
      entriesPerPage: epp,
      pageNo: pg,
      supplierid_id: props.selectedSupp.uuid,
    };
    setDataList({ ...dataList, data: [], loading: true });

    getQuotation(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          data: res.data.data,
          loading: false,
          totalRecords: res.data.total,
        });

        setMaxPage(calMaxPage(res.data.total));
      },
      (err) => {
        setDataList({ ...dataList, data: [], loading: false });
      }
    );
  }

  return (
    <React.Fragment>
      {dataList.loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : dataList.data.length > 0 ? (
        <div className="table-responsive">
          <table className="table table-bordered table-sm">
            <thead className="table-grey roboto-bold">
              <tr className="">
                <th scope="col">{"Sr.No"}</th>
                <th scope="col">
                  {props.constLabel?.lbl_date
                    ? props.constLabel?.lbl_date
                    : "Date"}
                </th>
                <th scope="col">
                  {props.constLabel?.lbl_total_amount
                    ? props.constLabel?.lbl_total_amount
                    : "Total Total"}
                </th>
                <th scope="col">
                  {props.constLabel?.lbl_status
                    ? props.constLabel?.lbl_status
                    : "Status"}
                </th>
                <th scope="col" width="15%">
                  {"Action"}
                </th>
              </tr>
            </thead>
            <tbody className=" roboto">
              {dataList.data.map((val, ind) => (
                <tr key={ind}>
                  <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                  <td>{val.quote_date}</td>
                  <td>{val.final_total}</td>
                  <td>{val.status_name}</td>
                  <td>
                    <Tooltip id={"view-tooltip" + ind} place="top" />
                    <Eye
                      data-tooltip-id={"view-tooltip" + ind}
                      data-tooltip-content={
                        props.constLabel?.lbl_view_quotation
                          ? props.constLabel?.lbl_view_quotation
                          : "View Quotation"
                      }
                      className="menu_icon_grey cursor_pointer"
                      size={18}
                      onClick={() => {
                        setShow2(true);
                        setSelData(val);
                      }}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="alert alert-danger mb-0 text-center my-4" role="alert">
          {"No Quotations Found !"}
        </div>
      )}

      {/* <div className="mt-4 d-flex justify-content-between">
          <p className="mb-0">{`Showing ${parseInt(srNo) + 1} to ${
            dataList.data.length + entriesPerPage * page - entriesPerPage
          } of ${dataList.totalRecords} entries`}</p>
          <Pagination className="my-2">
            <Pagination.Prev
              disabled={page === 1 ? true : false}
              onClick={() => {
                fetchQuoteList(
                  page - 1,
                  entriesPerPage,
                  parseInt(srNo) - parseInt(entriesPerPage)
                );
                setSrNo((prevC) =>
                  page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                );
                setPage(page - 1);
              }}
            >
              {"Prev"}
            </Pagination.Prev>

            <Pagination.Item active>{page}</Pagination.Item>

            <Pagination.Next
              disabled={
                page === maxPage ||
                maxPage === 0 ||
                entriesPerPage > dataList.data.length
                  ? true
                  : false
              }
              onClick={() => {
                fetchQuoteList(
                  page + 1,
                  entriesPerPage,
                  parseInt(srNo) + parseInt(entriesPerPage)
                );
                setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                setPage(page + 1);
              }}
            >
              {"Next"}
            </Pagination.Next>
          </Pagination>
          </div> */}

      <Modal
        show={show2}
        onHide={() => setShow2(false)}
        size="xl"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_supplier_quotation
              ? props.constLabel.lbl_supplier_quotation
              : "Supplier Quotation"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="container-fluid">
            <div className="bg-lightgrey" style={{ padding: "12px", borderRadius: "12px" }}>
                <div className="row my-2">
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {props.constLabel?.lbl_quote_id ? props.constLabel?.lbl_quote_id : "Quote No"}
                    </p>
                    <p className="mb-2 roboto-bold">{selData?.quotesid}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {props.constLabel?.lbl_date ? props.constLabel.lbl_date : "Date"}
                    </p>
                    <p className="mb-2 roboto-bold">{convertDate(selData?.quote_date, '-')}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {props.constLabel?.lbl_quotation_for ? props.constLabel?.lbl_quotation_for : "Quotation For"}
                    </p>
                    <p className="mb-2 roboto-bold">{selData?.quotationForDetails?.comName}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {props.constLabel?.lbl_supplier_name ? props.constLabel.lbl_supplier_name : "Supplier Name"}
                    </p>
                    <p className="mb-2 roboto-bold">{selData?.supplierDetails?.name}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {props.constLabel?.lbl_status ? props.constLabel.lbl_status : "Status"}
                    </p>
                    <p className="mb-2 roboto-bold">{selData?.status_name}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {props.constLabel?.lbl_remark ? props.constLabel.lbl_remark : "Remark"}
                    </p>
                    <p className="mb-2 roboto-bold">{selData?.remark}</p>
                  </div>
                </div>
            </div>

            <hr className="my-2" />
              <h4 className="roboto-bold mb-0">
                {props.constLabel?.lbl_product_detail
                  ? props.constLabel.lbl_product_detail
                  : "Product Detail"}
              </h4>
            <hr className="my-2" />

            <div className="my-3">
              <div
                className="table-responsive"
                style={{ maxHeight: "300px", overflowY: "auto" }}
              >
                <table className="table table-bordered">
                  <thead className="table-grey">
                    <tr>
                      <th scope="col">
                        {props.constLabel?.lbl_product_type
                          ? props.constLabel.lbl_product_type
                          : "Product Type"}
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_product_name
                          ? props.constLabel.lbl_product_name
                          : "Product Name"}
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_quantity
                          ? props.constLabel.lbl_quantity
                          : "Quantity"}
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_unit
                          ? props.constLabel.lbl_unit
                          : "Unit"}
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_rate
                          ? props.constLabel.lbl_rate
                          : "Rate"}{" "}
                        <span>&#8377;</span>
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_gst
                          ? props.constLabel.lbl_gst
                          : "GST "}(%)
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_discount
                          ? props.constLabel.lbl_discount
                          : "Discount "}(%)
                      </th>
                      <th scope="col">
                        {props.constLabel?.lbl_amount
                          ? props.constLabel.lbl_amount
                          : "Amount"}{" "}
                        <span>&#8377;</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {quoteProd.data?.map((val, ind) => (
                      <tr key={ind}>
                        <td>{val.producttype}</td>
                        <td style={{ verticalAlign: "top" }}>{val.product_name}</td>
                        <td style={{ verticalAlign: "top" }}>{val.quantity}</td>
                        <td style={{ verticalAlign: "top" }}>{val.unit}</td>
                        <td style={{ verticalAlign: "top" }}>{val.rate}</td>
                        <td style={{ verticalAlign: "top" }}>{val.gst_rate}</td>
                        <td style={{ verticalAlign: "top" }}>{val.discount}</td>
                        <td style={{ verticalAlign: "top" }}>{val.amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>

            <div
                className="p-3 ms-auto col-12 col-md-4"
                style={{
                  border: "1px solid #c6c6c6",
                  borderRadius: "2px",
                  fontSize: "14px",
                }}
              >
                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label className="col-12 col-md-6 roboto-bold">
                      {"Sub Total"} <span>&#8377;</span>
                    </label>

                    <div className="col-12 col-md-6">
                      {parseFloat(total).toFixed(2)}
                    </div>
                  </div>
                </div>

                <hr />
                {selData && selData?.cgst_cost !== 0 ? (
                  <>
                    <div className="row mb-2">
                      <div className="col-12 row ms-auto">
                        <label className="col-12 col-md-6 roboto-bold">
                          {"CGST "} &#8377;
                        </label>

                        {/* <div className="col-12 col-md-4">
                          {poList.data[indexx]?.cgst}
                        </div> */}
                        <div className="col-12 col-md-6 d-flex align-items-center">
                         
                          {parseFloat(selData?.cgst_cost).toFixed(
                            2
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-12 row ms-auto">
                        <label className="col-12 col-md-6 roboto-bold">
                          {"SGST "} &#8377;
                        </label>

                        {/* <div className="col-12 col-md-4">
                          {selData?.sgst}
                        </div> */}
                        <div className="col-12 col-md-6 d-flex align-items-center">
                          
                          {parseFloat(selData?.sgst_cost).toFixed(
                            2
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {selData && selData?.igst_cost !== 0 ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label className="col-12 col-md-6 roboto-bold">
                        {"IGST "} &#8377;
                      </label>

                      {/* <div className="col-12 col-md-4">
                        {selData?.igst}
                      </div> */}
                      <div className="col-12 col-md-6 d-flex align-items-center">
                        
                        {parseFloat(selData?.igst_cost).toFixed(2)}
                      </div>
                    </div>
                  </div>
                ) : null}

                {selData && selData?.ugst_cost !== 0 ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label className="col-12 col-md-6 roboto-bold">
                        {"UGST "} &#8377;
                      </label>

                      {/* <div className="col-12 col-md-4">
                        {selData?.ugst}
                      </div> */}
                      <div className="col-12 col-md-6">
                        
                        {parseFloat(selData?.ugst_cost).toFixed(2)}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label className="col-12 col-md-6 roboto-bold">
                      {"Total Amount "} &#8377;
                    </label>

                    <div className="col-12 col-md-6">
                      {parseFloat(selData?.final_total).toFixed(2)}
                    </div>
                  </div>
                </div>
            </div>

          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-grey px-4" onClick={() => setShow2(false)}>
            {props.constLabel?.lbl_close ? props.constLabel.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default SupplierQuoteList;

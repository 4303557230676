import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { get_document_type } from "../../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faClose } from "@fortawesome/free-solid-svg-icons";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { Trash2 } from "react-feather";
import { convertBase64 } from "../../../../common/Functions/CommonFunctions";

const documentsFileTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  // "application/msword",
  // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  // "application/vnd.ms-excel",
  // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  // "application/pdf",
];
const schema = yup
  .object()
  .shape({
    items: yup.array().of(
      yup.object().shape({
        doc_type: yup
          .array()
          .min(1, "Doc type is required")
          .required("Doc type is required !"),
        doc: yup
          .mixed()
          .test(
            "fileSize",
            "Image can be uploaded up to 10 MB",
            (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)
          )
          .test(
            "fileType",
            //   "Only .doc, .docx, .xls, .xlsx, png ,jpg , jpeg and .pdf files are allowed",
            "Only .png, .jpg, and .jpeg files are allowed",
            (value) => {
              if (!value) return true; // If no file is selected, it's considered valid.
              // Check if the file type is included in the allowed document file types.
              return documentsFileTypes.includes(value[0]?.type);
            }
          )
          .required("Required"),
      })
    ),
  })
  .required();

function UpdateImages(props) {
    const {imageList, setImageList, constLabel, img_List } = props;
    const [docType, setDocType] = useState({ data: [], loading: false });
    const imageFileExtensions = ["png", "jpg", "jpeg"];
    const { register, handleSubmit, formState, control, reset, watch, setValue } =
      useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
          items: [
            {
              doc: "",
              doc_type: [],
            },
          ],
        },
      }); // initialise the hook
  
    const { fields, append, remove } = useFieldArray({ control, name: "items" });
    let { errors } = formState;

    useEffect(() => {

        var imageItems = [];
        if (
          img_List.data !== undefined &&
          img_List.data.length > 0
        ) {
          for (let i = 0; i < img_List.data?.length; i++) {
           
            imageItems.push({
              uuid: img_List.data[i]?.uuid,
              doc_type: [
                {
                  name: img_List.data?.[i]?.document_type,
                },
              ],
            });
          }
    
          // setValue("items", poLineItems);
          reset({ items: imageItems })
        }
      }, [img_List]);
    
  
    useEffect(() => {
      fetchDoctype();
    }, []);
  
    function fetchDoctype() {
      get_document_type().then(
        (res) => {
          console.log("res", res);
          setDocType({ ...docType, loading: false, data: res.data.data });
        },
        (err) => {
          console.log("err", err);
          setDocType({ ...docType, loading: false, data: [] });
        }
      );
    }
  
    const onSubmit = async (data) => {
    //   console.log("data", data);
  
      if (data !== "") {
        const imageData = [];
        if (data.items !== null && data.items?.length > 0) {
          for (let i = 0; i < data.items?.length; i++) {
            const getExt = data.items[i].doc[0].name?.split(".").pop();
            let dataimage = await convertBase64(data.items[i].doc[0]);
            let newImage = dataimage.replace(
              /data:image\/[a-zA-Z]+;/,
              "data:image/" + getExt + ";"
            );

            const imgDataItem = {
                document_type: data.items[i].doc_type[0].name,
                document: newImage,
                document_name: data.items[i].doc[0].name,
            }
            
            if (img_List.data?.[i]?.uuid) {
                imgDataItem.uuid = img_List.data[i].uuid;
            }
  
            imageData.push(imgDataItem);
          }
        }
  
        // console.log("imageData", imageData);
      toast.success("Images saved successfully !", {
      autoClose: 1000,
      });
  
      setImageList({data: imageData});
        
      } else {
        errors.showMessages();
      }
    };

  return (
    <div className="row my-4 mx-2">
      <table className="table table-bordered">
        <thead className="table-grey roboto-bold">
          <tr>
            <th scope="col">
              {constLabel?.lbl_select_image
                ? constLabel.lbl_select_image
                : "Select Image"}
            </th>
            <th scope="col">
              {constLabel?.lbl_select_doc_type
                ? constLabel.lbl_select_doc_type
                : "Select Doc Type"}
            </th>

            <th scope="col" width="10%">
              {"Action"}
            </th>
          </tr>
        </thead>
        <tbody>
          {fields.map(({ id }, index) => (
            <tr>
              <td style={{ verticalAlign: "top" }} key={id}>
                <input
                  className={
                    errors.items?.[index]?.doc
                      ? "form-control form-control-sm is-invalid"
                      : "form-control form-control-sm"
                  }
                  type="file"
                  name={`items[${index}].doc`}
                  {...register(`items[${index}].doc`)}
                  // onChange={(e) => handleFileChange(e, index)}
                  placeholder="Please select image"
                />

                {errors.items?.[index]?.doc && (
                  <span className="text-danger err-msg">
                    {errors.items?.[index]?.doc.message}
                  </span>
                )}

                {img_List.data && img_List.data.length > 0 ? 
                    <div>
                        <a href={process.env.REACT_APP_IMAGE_URL + img_List.data?.[index]?.attachment} target="_blank">
                            {img_List.data?.[index]?.name}
                        </a> 
                    </div>
                    : null
                }

                {index + 1 == fields.length && (
                  <button
                    style={{ fontSize: "16px" }}
                    className="btn btn-sm button-primary px-2 mt-2"
                    onClick={() => {
                      if (fields.length < 2) {
                        append({});
                      } else {
                        toast.error("Maximum 2 images allowed", {
                          autoClose: 2000,
                        });
                      }
                    }}
                  >
                    <FontAwesomeIcon
                      style={{
                        color: "#ffffff",
                      }}
                      icon={faAdd}
                      size="sm"
                    />
                    {constLabel?.lbl_add_image
                      ? constLabel?.lbl_add_image
                      : "Add Image"}
                  </button>
                )}
              </td>

              <td style={{ verticalAlign: "top" }}>
                <Controller
                  name={`items[${index}].doc_type`}
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      size="sm"
                      options={docType.data}
                      isLoading={docType.loading}
                      placeholder="Select doc Type"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.items?.[index]?.doc_type ? true : false}
                    />
                  )}
                />

                {errors.items?.[index]?.doc_type && (
                  <span className="text-danger err-msg">
                    {errors.items?.[index]?.doc_type.message}
                  </span>
                )}
              </td>

              <td
                style={{
                  verticalAlign: "top",
                }}
              >
                <div
                  className="deleteIcon cursor_pointer"
                  onClick={
                    index > 0
                      ? () => {
                          remove(index);
                        }
                      : null
                  }
                >
                  <Trash2 color="#FFFFFF" size={18} />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
        <div className="d-flex justify-content-end" style={{paddingRight: "0px"}}>
            <button
                onClick={handleSubmit(onSubmit)}
                className="f-16 btn btn-yellow "
            >
                {constLabel?.lbl_close ? constLabel.lbl_close : "Save"}
            </button>
        </div>
    </div>
  )
}

export default UpdateImages
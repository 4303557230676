import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  faBan,
  faCog,
  faDownload,
  faFilter,
  faPlus,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import {
  getInternalCompany,
  delete_internal_company,
} from "../../../api/index";
import {
  calMaxPage,
  getLabels,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import { Pagination } from "react-bootstrap";
import { Eye, Trash2, Edit } from "react-feather";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import { Org_Col_Name } from "../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import axios from "axios";
import List from "../../../assets/images/icons/project_menu/checklist.png"
import Slide from "../../../assets/images/icons/project_menu/collapse.png"
import AccessDenied from "../../Common/AccessDenied";

function Organisation() {
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });

  const [sea_val, setSea_val] = useState(false);

  const [filterConfig, setFilterConfig] = useState({
    comName: "",
    comGSTnumber: "",
  });

  const [showLabel, setShowLabel] = useState(Org_Col_Name);
  const [constLabel, setConstLabel] = useState({});

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });

  let navigate = useNavigate();

  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Organization"));
  }, []);

  useEffect(() => {
    setConstLabel(getLabels("Master", "Organization"));
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchInternal_company(page, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function fetchInternal_company(pg, epp) {
    setDataList({
      ...dataList,
      data: [],
      loading: true
    });
    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    if (filterConfig.comName !== "" || filterConfig.comGSTnumber !== "") {
      payload.comName = filterConfig.comName;
      payload.comGSTnumber = filterConfig.comGSTnumber;
      if (
        (filterConfig.comName?.length > 0 || filterConfig.comGSTnumber?.length > 0) &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;
        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.comName;
      delete payload.comGSTnumber;
    }

    getInternalCompany(payload).then(
      (res) => {
        setDataList({ ...dataList, data: res.data.data, loading: false, totalRecords: res.data.total });

        if (
          (filterConfig.comName != "" && filterConfig.comName?.length > 0) ||
          (filterConfig.comGSTnumber != "" && filterConfig.comGSTnumber?.length > 0)
        ) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }

        setMaxPage(calMaxPage(res.data.total));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, data: [], loading: false });

      }
    );
  };

  const delInternalCompnay = (val) => {
    SweetAlert.fire({
      title: "Are you sure to delete ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        const payload = {
          uuid: val.uuid,
        };
        delete_internal_company(payload)
          .then((res) => {
            toast.success("Organization deleted successfully !", {
              autoClose: 2000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchInternal_company(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchInternal_company(page, entriesPerPage);
            }
          })
          .catch((err) => {
            if (err.response.status === 400) {
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
            }
          });
      }
    });
  };

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });
      return updatedCardShow;
    });
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  function clearFilter() {
    setFilterConfig({
      ...filterConfig,
      comName: "",
      comGSTnumber: ""
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />

      {!privilage.read ?
        <AccessDenied />
        :
        <div className="min-vh-100" id="root_div_main">
          <div id="mySidebar" className="customsidebar">
            <a className="closebtn" onClick={() => toggleNav()}>
              ×
            </a>

            <div className=" content">
              <div>
                <label
                  className="filterLabel mb-1 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_company_name
                    ? constLabel.lbl_company_name
                    : "Company Name"}
                </label>

                <input
                  className="form-control"
                  type="text"
                  placeholder="Search by company name"
                  value={filterConfig.comName}
                  onChange={(val) => {
                    const inputValue = val.target.value;
                    if (!inputValue.trim()) {
                      // Input contains only whitespace, don't trigger the search.
                      if (filterConfig.comName.length == 1) {
                        setFilterConfig({ ...filterConfig, comName: "" });
                      }

                      if (inputValue.length == 0) {
                        setFilterConfig({ ...filterConfig, comName: "" });
                      }

                    } else {
                      setFilterConfig({ ...filterConfig, comName: inputValue });
                    }
                  }}
                />
              </div>

              <div className="mt-2">
                <label
                  className="filterLabel mb-1 roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {constLabel?.lbl_gst_no
                    ? constLabel?.lbl_gst_no
                    : "GST No"}
                </label>
                <input
                  className="form-control"
                  type="text"
                  placeholder="Search by gst no"
                  value={filterConfig.comGSTnumber}
                  onChange={(val) => {
                    const inputValue = val.target.value;
                    if (!inputValue.trim()) {
                      // Input contains only whitespace, don't trigger the search.
                      if (filterConfig.comGSTnumber.length == 1) {
                        setFilterConfig({ ...filterConfig, comGSTnumber: "" });
                      }

                      if (inputValue.length == 0) {
                        setFilterConfig({ ...filterConfig, comGSTnumber: "" });
                      }
                    } else {
                      setFilterConfig({
                        ...filterConfig,
                        comGSTnumber: inputValue,
                      });
                    }
                  }}
                />
              </div>
              <div className="my-3 d-flex justify-content-end">
                <button
                  className="btn btn-sm btn-yellow"
                  onClick={() => clearFilter()}
                >
                  <FontAwesomeIcon
                    style={{ color: "#344454" }}
                    icon={faBan}
                    size="sm"
                  />{" "}
                  {constLabel?.lbl_clear_filter
                    ? constLabel.lbl_clear_filter
                    : "Clear Filter"}
                </button>
              </div>
            </div>
          </div>

          <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start col-12 col-md-6">
                    <Tooltip id={"open-tooltip"} place="top" />
                    <img src={Slide} height='32' width='32' className="cursor_pointer my-1 me-2" onClick={() => toggleNav()}
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-content={"Filters"}
                    />
                    <h1 className="bold">
                      {constLabel?.lbl_organization
                        ? constLabel.lbl_organization
                        : "Organization"}
                    </h1>
                  </div>

                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                    {privilage.write && (
                      <button
                        className="btn btn-grey me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => navigate(`/pcms/orgnization/hierarchy-view`)}
                      >
                        {constLabel?.lbl_show_hierarchy_view
                          ? constLabel.lbl_show_hierarchy_view
                          : "Show Hierarchy View"}
                      </button>
                    )}

                    {privilage.write && (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => navigate(`/pcms/orgnization/create`)}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_create_organization
                          ? constLabel.lbl_create_organization
                          : "Create Organization"}
                      </button>
                    )}

                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faListCheck} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img src={List} height='32' width='32' className="cursor_pointer" />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {dataList.loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : dataList.data.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table mt-2 table-bordered">
                        <thead className="table-grey roboto">
                          <tr className="">
                            <th scope="col">{"Sr.No"}</th>
                            {showLabel.map((v, i) =>
                              v.label === "Parent Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Parent Name"}
                                </th>
                              ) : v.label === "Company Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Company Name"}
                                </th>
                              ) : v.label === "Company Address" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Company Address"}
                                </th>
                              ) : v.label === "Company Type" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Company Type"}
                                </th>
                              ) : v.label === "First Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {"First Name"}
                                </th>
                              ) : v.label === "Last Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Last Name"}
                                </th>
                              ) : v.label === "Email" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Email"}
                                </th>
                              ) : v.label === "Phone" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Phone"}
                                </th>
                              ) : v.label === "Location" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Location"}
                                </th>
                              ) : v.label === "Website" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Website"}
                                </th>
                              ) : v.label === "GST Type" && v.flag ? (
                                <th key={i} scope="col">
                                  {"GST Type"}
                                </th>
                              ) : v.label === "GST No" && v.flag ? (
                                <th key={i} scope="col">
                                  {"GST No"}
                                </th>
                              ) : v.label === "CIN No" && v.flag ? (
                                <th key={i} scope="col">
                                  {"CIN No"}
                                </th>
                              ) : v.label === "UAN No" && v.flag ? (
                                <th key={i} scope="col">
                                  {"UAN No"}
                                </th>
                              ) : v.label === "Industry" && v.flag ? (
                                <th key={i} scope="col">
                                  {"Industry"}
                                </th>
                              ) : v.label === "LinkedIn" && v.flag ? (
                                <th key={i} scope="col">
                                  {"LinkedIn"}
                                </th>
                              ) : null
                            )}

                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className=" roboto">
                          {dataList.data.map((val, ind) => (
                            <tr key={ind}>
                              <td>{parseInt(ind) + 1 + parseInt(srNo)}</td>
                              {showLabel.map((item, idx) =>
                                item.label === "Parent Name" && item.flag ? (
                                  <td key={idx}>
                                    {val.parent_name != "" &&
                                      val.parent_name != null
                                      ? val.parent_name
                                      : "NA"}
                                  </td>
                                ) : item.label === "Company Name" && item.flag ? (
                                  <td key={idx}>
                                    {val.comName != "" && val.comName != null
                                      ? val.comName
                                      : "NA"}
                                  </td>
                                ) : item.label === "Company Address" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.comaddress != "" &&
                                      val.comaddress != null
                                      ? val.comaddress
                                      : "NA"}
                                  </td>
                                ) : item.label === "Company Type" && item.flag ? (
                                  <td key={idx}>
                                    {val.company_type != "" &&
                                      val.company_type != null
                                      ? val.company_type
                                      : "NA"}
                                  </td>
                                ) : item.label === "First Name" && item.flag ? (
                                  <td key={idx}>
                                    {val.first_name != "" &&
                                      val.first_name != null
                                      ? val.first_name
                                      : "NA"}
                                  </td>
                                ) : item.label === "Last Name" && item.flag ? (
                                  <td key={idx}>
                                    {val.last_name != "" && val.last_name != null
                                      ? val.last_name
                                      : "NA"}
                                  </td>
                                ) : item.label === "Email" && item.flag ? (
                                  <td key={idx}>
                                    {val.mail != "" && val.mail != null
                                      ? val.mail
                                      : "NA"}
                                  </td>
                                ) : item.label === "Phone" && item.flag ? (
                                  <td key={idx}>
                                    {val.phone != "" && val.phone != null
                                      ? val.phone
                                      : "NA"}
                                  </td>
                                ) : item.label === "Location" && item.flag ? (
                                  <td key={idx}>
                                    {val.location_name != "" &&
                                      val.location_name != null
                                      ? val.location_name
                                      : "NA"}
                                  </td>
                                ) : item.label === "Website" && item.flag ? (
                                  <td key={idx}>
                                    {val.website != "" && val.website != null
                                      ? val.website
                                      : "NA"}
                                  </td>
                                ) : item.label === "GST Type" && item.flag ? (
                                  <td key={idx}>
                                    {val.gst_type != "" && val.gst_type != null
                                      ? val.gst_type
                                      : "NA"}
                                  </td>
                                ) : item.label === "GST No" && item.flag ? (
                                  <td key={idx}>
                                    {val.comGSTnumber != "" && val.comGSTnumber != null
                                      ? val.comGSTnumber
                                      : "NA"}
                                  </td>
                                ) : item.label === "CIN No" && item.flag ? (
                                  <td key={idx}>
                                    {val.cin_no != "" && val.cin_no != null
                                      ? val.cin_no
                                      : "NA"}
                                  </td>
                                ) : item.label === "UAN No" && item.flag ? (
                                  <td key={idx}>
                                    {val.uan_no != "" && val.uan_no != null
                                      ? val.uan_no
                                      : "NA"}
                                  </td>
                                ) : item.label === "Industry" && item.flag ? (
                                  <td key={idx}>
                                    {val.industry != "" && val.industry != null
                                      ? val.industry
                                      : "NA"}
                                  </td>
                                ) : item.label === "LinkedIn" && item.flag ? (
                                  <td key={idx}>
                                    {val.location != "" && val.location != null
                                      ? val.location
                                      : "NA"}
                                  </td>
                                ) : null
                              )}
                              <td>
                                {privilage.write && (
                                  <>
                                    <Tooltip id={"edit-tooltip" + ind} place="top" />
                                    <Edit
                                      data-tooltip-id={"edit-tooltip" + ind}
                                      data-tooltip-content={
                                        constLabel?.lbl_edit_organization
                                          ? constLabel?.lbl_edit_organization
                                          : "Edit Organization"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() =>
                                        navigate("/pcms/orgnization/update", {
                                          state: val,
                                        })
                                      }
                                    />
                                  </>
                                )}

                                {privilage.read && (
                                  <>
                                    <Tooltip id={"view-tooltip" + ind} place="top" />
                                    <Eye
                                      data-tooltip-id={"view-tooltip" + ind}
                                      data-tooltip-content={
                                        constLabel?.lbl_view_organization
                                          ? constLabel?.lbl_view_organization
                                          : "View Organization"
                                      }
                                      className="menu_icon_grey cursor_pointer me-2"
                                      size={20}
                                      onClick={() =>
                                        navigate(`/pcms/orgnization/view`, {
                                          state: val,
                                        })
                                      }
                                    />
                                  </>
                                )}

                                {privilage.delete && (
                                  <>
                                    <Tooltip id={"delete-tooltip" + ind} place="top" />
                                    <Trash2
                                      data-tooltip-id={"delete-tooltip" + ind}
                                      data-tooltip-content={
                                        constLabel?.lbl_delete_organization
                                          ? constLabel?.lbl_delete_organization
                                          : "Delete Organization"
                                      }
                                      className="menu_icon_red cursor_pointer"
                                      size={20}
                                      onClick={() => delInternalCompnay(val)}
                                    />
                                  </>
                                )}

                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <div
                      className="alert alert-danger mb-0 text-center my-4"
                      role="alert"
                    >
                      {"No Data Found"}
                    </div>
                  )}

                  {dataList.loading ? null : (
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchInternal_company(1, e.target.value);
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                        } to ${Math.min(
                          parseInt(entriesPerPage) + parseInt(srNo),
                          dataList.totalRecords
                        )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchInternal_company(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                              maxPage === 0 ||
                              entriesPerPage > dataList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchInternal_company(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default Organisation;

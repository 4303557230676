import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faImage,
  faPlus,
  faAdd,
  faCircle,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import { Trash2 } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import {
  getSupplier,
  getUOM,
  getProdList,
  getProductType,
  getPOStatus,
  getInternalCompany,
  createPO,
  getGST,
  getQuotation,
  getQuote_Products,
  master_multi_file_upload,
} from "../../../../api/index";
import {
  convertDateYYMMDD,
  convertDate,
} from "../../../../common/Functions/CommonFunctions";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";
import AddProduct from "../../AddProducts/AddProduct";
import POImageGallary from "../../PurchaseOrder/POImageGallary";

const schema = yup
  .object()
  .shape({
    supplier: yup
      .array()
      .min(1, "Please select supplier !")
      .required("Please select supplier !"),
    invoiceTo: yup
      .array()
      .min(1, "Please select company !")
      .required("Please select company !"),
    dispatchTo: yup
      .array()
      .min(1, "Please select company !")
      .required("Please select company !"),
    status: yup
      .array()
      .min(1, "Please select status !")
      .required("Please select status !"),
    remarks: yup.string().required("Please enter remarks !"),
    client_poid: yup.string().required("Please enter client po no !"),
    internalRemarks: yup.string().when("quoteRef", {
      is: (quoteRef) => quoteRef == undefined || quoteRef?.length == 0,
      then: yup.string().required("Please enter internal remarks"),
      otherwise: yup.string(),
    }),
    creditPeriod: yup
      .string()
      .matches(/^[0-9]+$/, "Enter valid credit period")
      .required("Please enter credit period !"),

    po_date: yup.date().required("Please select date !"),
    items: yup.array().of(
      yup.object().shape({
        prod_type: yup
          .array()
          .min(1, "Please select product type !")
          .required("Please select product type !"),
        product: yup
          .array()
          .min(1, "Please select product !")
          .required("Please select product !"),
        quantity: yup
          .string()
          .required("Quantity is required")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid quantity")
          .typeError("Enter valid quantity"),
        unit: yup
          .array()
          .min(1, "Please select unit !")
          .required("Please select unit !"),
        price: yup
          .string()
          .required("Price is required")
          .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid price")
          .typeError("Enter valid price"),
      })
    ),
    discount: yup
      .string()
      .matches(/^\d+(\.\d+)?$/, "Enter valid discount")
      .required("Discount is required"),
  })
  .required();

function CreatePO(props) {
  const [visible, setVisible] = useState(false);
  let navigate = useNavigate();
  let location = useLocation();
  let urlParams = useParams();

  const [supplierList, setSupplierList] = useState({
    data: [],
    loading: false,
  });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [prod_List, setProd_List] = useState({ data: [], loading: false });
  const [prodType, setProdType] = useState({ data: [], loading: false });
  const [poStatusList, setPoStatusList] = useState({
    data: [],
    loading: false,
  });
  const [internalCompList, setInternalComp] = useState({
    data: [],
    loading: false,
  });
  const [quotationList, setQuotationList] = useState({
    data: [],
    loading: false,
  });
  const [taxDetails, setTaxDetails] = useState({});
  const [usingQuoteProd, setUsingQuoteProd] = useState(false);
  const [cameFromQuoteTable, setCameFromQuoteTable] = useState();
  const [gstErr, setGstErr] = useState(false);

  const [galVisible, setGalVisible] = useState(false);
  const [drawingFiles, setDrawingFiles] = useState([]);

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    getValues,
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      CGST_amount: 0,
      SGST_amount: 0,
      IGST_amount: 0,
      items: [
        {
          prod_type: [],
          product: [],
          quantity: 0,
          unit: [],
          price: 0,
          amount: 0,
        },
      ],
    },
  });
  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  const userData = JSON.parse(localStorage.getItem("localData"));
  const lineItems = watch("items");
  const invTo = watch("invoiceTo");
  const supp = watch("supplier");
  const qRef = watch("quoteRef");
  const tempQI = [];
  const tempQI2 = [];

  const [subTotalForRef, setSubtotalForRef] = useState(0);
  let subTotalForRef2 = "";

  useEffect(() => {
    window.history.pushState(null, "", window.location.href);
    window.onpopstate = function () {
      window.history.pushState(null, "", window.location.href);
    };

    fetchUOM();
    fetchSuppliers();
    // fetchProdList();
    fetchProductType();
    fetchPOStatus();
    fetchInternalComp();
    fetchQuotation();

    // If quotation is approved search take id from url search it in list and set quoteRef
    // location contains data of quotation
  }, []);

  useEffect(() => {
    calSubAmt();
    calDiscount(getValues("discount"));
  }, [lineItems]);

  useEffect(() => {
    if (Object.keys(taxDetails).length > 0) {
      calDiscount(getValues("discount"));
    }
  }, [taxDetails]);

  useEffect(() => {
    if (invTo !== undefined && supp !== undefined) {
      if (invTo.length > 0 && supp.length > 0) {
        fetchGST({
          suplier_gst_number: supp[0]?.gst,
          invoice_gst_number: invTo[0]?.comGSTnumber,
          // invoice_gst_number: "56asuagsdyu"
        });
      }
    }
  }, [invTo, supp]);

  useEffect(() => {
    if (
      qRef !== undefined &&
      qRef.length > 0 &&
      props.supplierList.length > 0
    ) {
      setValue("invoiceTo", [qRef[0]?.quotationForDetails]);

      // disable invoice to and supplier if quote ref selected
      setCameFromQuoteTable(true);

      // getQuote_Products({ quote_id: qRef[0].uuid, supplier_id: qRef[0].supplierid_id }).then(async (res) => {

      // items: [{ prod_type: [], product: [], quantity: 0, unit: [], price: 0, amount: 0 }],
      // tempQI

      for (let i = 0; i < qRef[0]?.quotationForDetails.length; i++) {
        tempQI.push({
          prod_type: [
            {
              // productType: "test3",
              productType: qRef[0]?.quotationForDetails[i].producttype,
              uuid: qRef[0]?.quotationForDetails[i].product_type_id,
              // productId: ""
              // uuid: "577649d2-a5c2-47ea-80f3-6cf6e38e1ce7"
            },
          ],
          product: [
            {
              uuid: qRef[0]?.quotationForDetails[i].product_id,
              productName: qRef[0]?.quotationForDetails[i].product_name,
              productId: "",
            },
          ],
          quantity: qRef[0]?.quotationForDetails[i].quantity,
          unit: [
            {
              uom: qRef[0]?.quotationForDetails[i].unit,
              uuid: qRef[0]?.quotationForDetails[i].unit_id,
            },
          ],
          price: qRef[0]?.quotationForDetails[i].rate,
          amount: qRef[0]?.quotationForDetails[i].amount,
        });
      }

      const resSupp = props.supplierList.filter(
        (val) => val.uuid == qRef[0].supplierid_id
      );

      setValue("supplier", resSupp);

      if (
        qRef !== undefined &&
        qRef.length > 0 &&
        resSupp !== undefined &&
        resSupp.length > 0
      ) {
        fetchGST({
          suplier_gst_number: resSupp[0]?.gst,
          invoice_gst_number: qRef[0]?.quotationForDetails?.comGSTnumber,
        });
      } else {
        toast.error("Supplier not found from quotation ref !", {
          autoClose: 3000,
        });
      }

      setValue("items", tempQI, { shouldValidate: true });

      setUsingQuoteProd(true);

      setValue("discount", qRef[0].discount);

      // }, (err) => {

      // })
    } else {
      // enable invoice to and supplier if quote ref removed
      setCameFromQuoteTable(false);

      setValue("items", [{}]);
      setUsingQuoteProd(false);
      setValue("subTotal", 0.0);
      setValue("taxAmt", 0.0);
      setValue("discount", 0);
      setValue("CGST", 0);
      setValue("CGST_amount", 0);
      setValue("SGST", 0);
      setValue("SGST_amount", 0);
      setValue("IGST", 0);
      setValue("IGST_amount", 0);
      setValue("UGST", 0);
      setValue("UGST_amount", 0);
      setTaxDetails({});
    }
  }, [qRef, props.supplierList]);

  function fetchQuotation() {
    setQuotationList({ ...quotationList, data: [], loading: true });

    getQuotation({ status_name: "Approved" }).then(
      (res) => {
        // if (atob(urlParams.quoteId) !== "null" && atob(urlParams.supplierId) !== "null") {
        //   const result = res.data.data.filter((val) => {
        //     return val.uuid == atob(urlParams.quoteId)
        //   })

        //   setValue('quoteRef', result);

        //   setValue('invoiceTo', [result[0]?.quotation_for_id]);

        // }

        setQuotationList({
          ...quotationList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setQuotationList({ ...quotationList, data: [], loading: false });
      }
    );
  }

  async function fetchGST(payload) {
    await getGST(payload).then(
      (res) => {
        // taxDetails.cgst = res.data.cgst;
        // taxDetails.igst = res.data.igst;
        // taxDetails.invoice_gst_number = res.data.invoice_gst_number;
        // taxDetails.invoice_gst_number_state_code = res.data.invoice_gst_number_state_code;
        // taxDetails.invoice_gst_number_state_name = res.data.invoice_gst_number_state_name;
        // taxDetails.sgst = res.data.sgst;
        // taxDetails.suplier_gst_number = res.data.suplier_gst_number;
        // taxDetails.suplier_gst_number_state_code = res.data.suplier_gst_number_state_code;
        // taxDetails.suplier_gst_number_state_name = res.data.suplier_gst_number_state_name;
        // taxDetails.type = res.data.type;
        // taxDetails.ugst = res.data.ugst;

        // setTaxDetails(taxDetails);
        setTaxDetails(res.data);

        const subTot = parseFloat(getValues("subTotal"));

        setValue("SGST_amount", ((subTot * res.data.sgst) / 100).toFixed(2));
        setValue("CGST_amount", ((subTot * res.data.cgst) / 100).toFixed(2));
        setValue("IGST_amount", ((subTot * res.data.igst) / 100).toFixed(2));
        setValue("UGST_amount", ((subTot * res.data.ugst) / 100).toFixed(2));

        setValue("CGST", res.data.cgst);
        setValue("SGST", res.data.sgst);
        setValue("IGST", res.data.igst);
        setValue("UGST", res.data.ugst);

        setGstErr(false);
      },
      (err) => {
        toast.error("Error in fetching gst details !", {
          autoClose: 3000,
        });
        setGstErr(true);
      }
    );
  }

  function calSubAmt() {
    const totalAmount = lineItems.reduce(
      (accumulator, item) => parseFloat(accumulator) + parseFloat(item.amount),
      0
    );

    setValue("subTotal", totalAmount);
    setSubtotalForRef(parseFloat(totalAmount).toFixed(2));
    subTotalForRef2 = parseFloat(totalAmount);
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM().then(
      (res) => {
        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchProdList(selVal) {
    if (selVal.length > 0) {
      setProd_List({ ...prod_List, loading: true, data: [] });

      getProdList({ product_type_id: selVal[0].uuid }).then(
        (res) => {
          setProd_List({ ...prod_List, loading: false, data: res.data.data });
        },
        (err) => {}
      );
    } else {
    }
  }

  function handleProdType(selVal, index) {
    setValue(`items[${index}].prod_type`, selVal);
    setValue(`items[${index}].product`, []);
    if (selVal.length > 0) {
      fetchProdList(selVal);
    } else {
      setProd_List({ ...prod_List, loading: false, data: [] });
    }
  }

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        setProdType({ ...prodType, data: res.data.data, loading: false });
      },
      (err) => {
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }

  function fetchSuppliers() {
    setSupplierList({ ...supplierList, loading: true, data: [] });
    getSupplier({ partyType: "Supplier" }).then(
      (res) => {
        const result = res.data.data.filter((item) => item.name !== null);
        setSupplierList({
          ...supplierList,
          loading: false,
          data: result,
        });
      },
      (err) => {
        //
        setSupplierList({ ...supplierList, loading: false, data: [] });
        toast.error("Something went wrong in suppliers!", {
          autoClose: 3000,
        });
      }
    );
  }

  function fetchPOStatus() {
    setPoStatusList({ ...poStatusList, data: [], loading: true });

    getPOStatus().then(
      (res) => {
        setPoStatusList({
          ...poStatusList,
          data: res.data.data,
          loading: false,
        });
        const result = res.data.data.filter((item) => item.postatus == "Open");

        setValue("status", result);
      },
      (err) => {
        setPoStatusList({ ...poStatusList, data: [], loading: false });
      }
    );
  }

  function fetchInternalComp() {
    setInternalComp({ ...internalCompList, data: [], loading: true });

    getInternalCompany().then(
      (res) => {
        setInternalComp({
          ...internalCompList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setInternalComp({ ...internalCompList, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    let reqPayload = [];

    for (let i = 0; i < data.items.length; i++) {
      const lineItems = {
        producttype: data.items[i].prod_type[0].productType,
        product_type_id: data.items[i].prod_type[0].uuid,
        productid_id: data.items[i].product[0].uuid,
        product_id: data.items[i].product[0].productId,
        productname: data.items[i].product[0].productName,
        diameter: data.items[i].product[0].diameter,
        partnumber: data.items[i].product[0].partNo,
        quantity: parseFloat(data.items[i].quantity),
        // "qtyinkg": "12",
        rate: data.items[i].price,
        unit: data.items[i].unit[0].uom,
        amount: data.items[i].amount,
        unit_id: data.items[i].unit[0].uuid,
        comp_id: null,
      };

      reqPayload.push(lineItems);
    }

    const payload = {
      client_poid: data.client_poid,
      po_date: convertDateYYMMDD(data.po_date, "-"),
      // "purchaserequisitionid": "102",
      invoice_to: data.invoiceTo[0].id,
      invoice_to_id: data.invoiceTo[0].uuid,
      invoice_toname: data.invoiceTo[0].comName,
      invoicetoaddress: data.invoiceTo[0].comaddress,
      despatch_to: data.dispatchTo[0].id,
      despatch_to_id: data.dispatchTo[0].uuid,
      despatch_toname: data.dispatchTo[0].comName,
      despatchtoaddress: data.dispatchTo[0].comaddress,
      supplier_id: data.supplier[0].uuid,
      supplier: data.supplier[0].partyCode,
      suppliername: data.supplier[0].name,
      supplieraddress: data.supplier[0].address,
      // "supplier_ref_no": "121",
      credit_period: data.creditPeriod,
      discount: data.discount,
      sub_total: data.subTotal,
      net_amount: data.totalAmount,
      po_status: data.status[0].postatus,
      po_status_id: data.status[0].uuid,
      remark: data.remarks,
      internal_remark: data.internalRemarks,
      quote_ref:
        data.quoteRef !== undefined ? data.quoteRef[0]?.quotesid : null,
      quote_ref_id: data.quoteRef !== undefined ? data.quoteRef[0]?.uuid : null,
      // sgst: data.SGST,
      // cgst: data.CGST,
      // igst: data.IGST,
      // sgst_cost: data.SGST_amount,
      // cgst_cost: data.CGST_amount,
      // igst_cost: data.IGST_amount,
      po_product_list: reqPayload,
    };

    if (taxDetails.type == "sgst") {
      payload.cgst = data.CGST;
      payload.cgst_cost = data.CGST_amount;
      payload.sgst = data.SGST;
      payload.sgst_cost = data.SGST_amount;
    } else if (taxDetails.type == "igst") {
      payload.igst = data.IGST;
      payload.igst_cost = data.IGST_amount;
    } else if (taxDetails.type == "ugst") {
      payload.ugst = data.UGST;
      payload.ugst_cost = data.UGST_amount;
    }

    createPO(payload).then(
      (res) => {
        if (res.status === 200 || res.status === 201) {
          // toast.success("Purchase order created successfully", {
          //   autoClose: 3000,
          // });

          // setTimeout(() => {
          //   navigate("/pcms/purchase-order");
          // }, [2000])

          for (let i = 0; i < drawingFiles.length; i++) {
            drawingFiles[i].ref_model_id = "";
            drawingFiles[i].ref_model_uuid = res.data?.po?.uuid;
            drawingFiles[i].model_name = "PO";
            drawingFiles[i].name = drawingFiles[i].filename;
            drawingFiles[i].document_type = "image";
          }

          let imgArr = {};
          let formDataToSend = new FormData();
          for (let a = 0; a < drawingFiles.length; a++) {
            imgArr["attachment" + (a + 1)] = drawingFiles[a].file;
            imgArr["obj" + (a + 1)] = JSON.stringify(drawingFiles[a]);
            imgArr["img_count"] = drawingFiles.length;

            for (const [key, value] of Object.entries(imgArr)) {
              formDataToSend.append(key, value);
            }
          }

          if (drawingFiles.length > 0) {
            master_multi_file_upload(formDataToSend).then(
              (res) => {
                toast.success("Purchase order created successfully !", {
                  autoClose: 2000,
                });
                setTimeout(() => {
                  navigate("/pcms/purchase-order");
                  reset();
                }, [2000]);
              },
              (err) => {
                if (err.response.status == 413) {
                  toast.error("Image upload failed !", {
                    autoClose: 3000,
                  });
                }
              }
            );
          } else {
            toast.success("Purchase order created successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              navigate("/pcms/purchase-order");
              reset();
            }, [2200]);
          }
        }
      },
      (err) => {
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  };

  async function calRate(para1, para2, index) {
    let rate;

    switch (para2) {
      case "quantity":
        // items[${index}].price
        const price = getValues(`items[${index}].price`);

        rate = para1 * price;
        setValue(`items[${index}].amount`, parseFloat(rate).toFixed(2));

        await calSubAmt();
        calDiscount(getValues("discount"));

        break;

      case "price":
        const quantity = getValues(`items[${index}].quantity`);
        rate = para1 * quantity;
        setValue(`items[${index}].amount`, parseFloat(rate).toFixed(2));

        await calSubAmt();
        calDiscount(getValues("discount"));
        break;
    }
  }

  async function calDiscount(val) {
    await calSubAmt();
    let subTotal = parseFloat(getValues("subTotal"));
    let SGST = parseFloat(getValues("SGST"));
    let CGST = parseFloat(getValues("CGST"));
    let IGST = parseFloat(getValues("IGST"));

    // Discount Calcultaion logic

    if (subTotal < 0 || val < 0 || val > 100) {
      return;
    }

    const discountAmount = (subTotalForRef2 * val) / 100;
    const discountedAmount = subTotalForRef2 - discountAmount;

    setValue("subTotal", discountedAmount.toFixed(2));

    setValue(
      "discount_amount",
      (subTotalForRef2 - discountedAmount).toFixed(2)
    );

    // If discount logic is used also need to change the CGST and SGST on subTotal
    subTotal = parseFloat(getValues("subTotal"));
    let taxAmt = "";
    let taxedAmt = 0;

    if (taxDetails.type == "sgst") {
      taxAmt = (subTotal * (parseFloat(CGST) + parseFloat(SGST))) / 100;
      taxedAmt = subTotal + taxAmt;

      setValue("SGST_amount", ((subTotal * SGST) / 100).toFixed(2));
      setValue("CGST_amount", ((subTotal * CGST) / 100).toFixed(2));
    } else if (taxDetails.type == "igst") {
      taxAmt = (subTotal * taxDetails.igst) / 100;
      taxedAmt = subTotal + taxAmt;

      setValue("IGST_amount", taxAmt.toFixed(2));
    } else if (taxDetails.type == "ugst") {
      taxAmt = (subTotal * taxDetails.ugst) / 100;
      taxedAmt = subTotal + taxAmt;

      setValue("UGST_amount", taxAmt.toFixed(2));
    }

    // setValue("totalAmount", taxedAmt?.toFixed(2));
    if (taxedAmt !== 0) {
      setValue("totalAmount", taxedAmt?.toFixed(2));
    } else {
      setValue("totalAmount", parseFloat(subTotal).toFixed(2));
    }
  }

  function CaltotalAmount(val, para2) {
    let subTotal = parseFloat(getValues("subTotal"));
    let SGST = getValues("SGST");
    let CGST = getValues("CGST");

    if (para2 == "CGST") {
      const taxAmt = (subTotal * (parseFloat(val) + parseFloat(SGST))) / 100;
      const taxedAmt = subTotal + taxAmt;

      setValue("totalAmount", taxedAmt);

      setValue("CGST_amount", ((subTotal * CGST) / 100).toFixed(2));
    }
    if (para2 == "SGST") {
      const taxAmt = (subTotal * (parseFloat(val) + parseFloat(CGST))) / 100;
      const taxedAmt = subTotal + taxAmt;

      setValue("totalAmount", taxedAmt);

      setValue("SGST_amount", ((subTotal * SGST) / 100).toFixed(2));
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div>
        <button
          className="btn button-primary btn-sm me-2"
          onClick={() => {
            // setUpdateForm(val)
            setVisible(true);
          }}
        >
          <FontAwesomeIcon
            style={{ color: "#fff", marginRight: "6px" }}
            icon={faPlus}
            size="sm"
          />
          {props.constLabel?.lbl_create_purchase_order
            ? props.constLabel.lbl_create_purchase_order
            : "Create Purchase Order"}
        </button>
      </div>
      <POImageGallary
        setGalVisible={setGalVisible}
        galVisible={galVisible}
        setDrawingFiles={setDrawingFiles}
        drawingFiles={drawingFiles}
      />
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          reset();
        }}
        size={"xl"}
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Create Purchase Order"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="purchase_order mb-4">
            <div className="card border-0">
              <div className="card-body">
                <div className="createPO container-fluid">
                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                    {props.constLabel?.lbl_purhcase_order_detail
                      ? props.constLabel.lbl_purhcase_order_detail
                      : "Purchase Order Detail"}
                  </p>
                  <hr className="my-2" />

                  <div className="row my-4">
                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {props.constLabel?.lbl_client_po_no
                          ? props.constLabel.lbl_client_po_no
                          : "Client PO No"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <div className="col-12 col-md-9">
                        <input
                          className={
                            errors.client_poid
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          id="client_poid"
                          name="client_poid"
                          type="text"
                          placeholder="Enter client po no"
                          {...register("client_poid")}
                        />
                        {errors.client_poid && (
                          <span className="text-danger err-msg">
                            {errors.client_poid.message}
                          </span>
                        )}
                        <label style={{ fontSize: "12px" }} className="col-12">
                          {"Last client PO No : "}
                          <span>
                            <i style={{ color: "red" }}>
                              {location.state?.client_poid}
                            </i>
                          </span>
                        </label>
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {props.constLabel?.lbl_date
                          ? props.constLabel.lbl_date
                          : "Date"}{" "}
                        <span className="text-danger">
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          control={control}
                          name="po_date"
                          defaultValue={new Date()}
                          render={({ field }) => (
                            <DatePicker
                              className={
                                errors.po_date
                                  ? "form-control  is-invalid"
                                  : "form-control  "
                              }
                              placeholderText="Select date"
                              onChange={(date) => field.onChange(date)}
                              selected={field.value}
                              defaultValue={new Date()}
                              maxDate={new Date()}
                              dateFormat="dd/MM/yyyy"
                            />
                          )}
                        />

                        {errors.po_date && (
                          <span className="text-danger err-msg">
                            {errors.po_date.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {props.constLabel?.lbl_invoice_to
                          ? props.constLabel.lbl_invoice_to
                          : "Invoice To"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          name={`invoiceTo`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="comName"
                              multiple={false}
                              options={internalCompList.data}
                              isLoading={internalCompList.loading}
                              placeholder="Select company"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              disabled={cameFromQuoteTable == true}
                              isInvalid={errors.invoiceTo ? true : false}
                            />
                          )}
                        />
                        {errors.invoiceTo && (
                          <span className="text-danger err-msg">
                            {errors.invoiceTo.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        Dispatch To
                        {props.constLabel?.lbl_dispatch_to
                          ? props.constLabel.lbl_dispatch_to
                          : "Dispatch To"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          name={`dispatchTo`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="comName"
                              multiple={false}
                              options={internalCompList.data}
                              isLoading={internalCompList.loading}
                              placeholder="Select company"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={errors.dispatchTo ? true : false}
                            />
                          )}
                        />
                        {errors.dispatchTo && (
                          <span className="text-danger err-msg">
                            {errors.dispatchTo.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {props.constLabel?.lbl_supplier_name
                          ? props.constLabel.lbl_supplier_name
                          : "Supplier Name"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          name={`supplier`}
                          control={control}
                          defaultValue={
                            props.selectedSupp &&
                            Object.keys(props.selectedSupp[0]).length > 0
                              ? props.selectedSupp
                              : []
                          }
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              multiple={false}
                              options={props.supplierList}
                              // isLoading={supplierList.loading}
                              placeholder="Select supplier"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              disabled={cameFromQuoteTable}
                              defaultSelected={
                                props.selectedSupp &&
                                Object.keys(props.selectedSupp[0]).length > 0
                                  ? props.selectedSupp
                                  : []
                              }
                              isInvalid={errors.supplier ? true : false}
                            />
                          )}
                        />
                        {errors.supplier && (
                          <span className="text-danger err-msg">
                            {errors.supplier.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {props.constLabel?.lbl_quote_ref
                          ? props.constLabel.lbl_quote_ref
                          : "Quote Ref"}
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          name={`quoteRef`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="quotesid"
                              multiple={false}
                              options={quotationList.data}
                              isLoading={quotationList.loading}
                              placeholder="Select Quotation"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              // disabled={cameFromQuoteTable == true}
                              isInvalid={errors.quoteRef ? true : false}
                            />
                          )}
                        />
                        {errors.quoteRef && (
                          <span className="text-danger err-msg">
                            {errors.quoteRef.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {props.constLabel?.lbl_credit_period
                          ? props.constLabel.lbl_credit_period
                          : "Credit Period"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <div className="col-12 col-md-9">
                        <input
                          className={
                            errors.creditPeriod
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          id="creditPeriod"
                          name="creditPeriod"
                          min={0}
                          type="text"
                          placeholder="Enter credit period (days)"
                          {...register("creditPeriod")}
                        />
                        {errors.creditPeriod && (
                          <span className="text-danger err-msg">
                            {errors.creditPeriod.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {props.constLabel?.lbl_status
                          ? props.constLabel.lbl_status
                          : "Status"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>
                      <div className="col-12 col-md-9">
                        <Controller
                          name={`status`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="postatus"
                              multiple={false}
                              options={poStatusList.data}
                              isLoading={poStatusList.loading}
                              placeholder="Select status"
                              disabled
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={errors.status ? true : false}
                            />
                          )}
                        />
                        {errors.status && (
                          <span className="text-danger err-msg">
                            {errors.status.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {props.constLabel?.lbl_remark
                          ? props.constLabel.lbl_remark
                          : "Remark"}
                        <span>
                          <i style={{ color: "red" }}>*</i>
                        </span>
                      </label>

                      <div className="col-12 col-md-9">
                        <textarea
                          className={
                            errors.remarks
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          id="remarks"
                          name="remarks"
                          placeholder="Enter remarks"
                          {...register("remarks")}
                        ></textarea>
                        {errors.remarks && (
                          <span className="text-danger err-msg">
                            {errors.remarks.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {props.constLabel?.lbl_internal_remark
                          ? props.constLabel.lbl_internal_remark
                          : "Internal Remark"}
                      </label>

                      <div className="col-12 col-md-9">
                        <textarea
                          className={
                            errors.internalRemarks
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          id="internalRemarks"
                          name="internalRemarks"
                          placeholder="Enter Internal Remarks"
                          {...register("internalRemarks")}
                        ></textarea>
                        {errors.internalRemarks && (
                          <span className="text-danger err-msg">
                            {errors.internalRemarks.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>

                  <p className="mt-2 mb-4">
                    <i>
                      {" "}
                      <span className="text-danger roboto-bold">Note: </span>
                      <FontAwesomeIcon
                        style={{
                          color: "#c7c7c7",
                        }}
                        icon={faCircle}
                        size="2xs"
                        className="mx-2"
                      />
                      Internal remarks will not be displayed in report
                    </i>
                    <i className=" d-block">
                      {" "}
                      <span className="text-danger roboto-bold invisible">
                        Note:{" "}
                      </span>
                      <FontAwesomeIcon
                        style={{
                          color: "#c7c7c7",
                        }}
                        icon={faCircle}
                        size="2xs"
                        className="mx-2"
                      />
                      Product type must be selected first then product
                    </i>
                  </p>

                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                    {props.constLabel?.lbl_product_detail
                      ? props.constLabel.lbl_product_detail
                      : "Product Detail"}
                  </p>
                  <hr className="my-2" />

                  <div
                    className="table-responsive my-3"
                    style={{ maxHeight: "80vh" }}
                  >
                    <table className="table table-bordered">
                      <thead className="thead-light text-center">
                        <tr>
                          <th scope="col">
                            {props.constLabel?.lbl_product_type
                              ? props.constLabel.lbl_product_type
                              : "Product Type"}
                          </th>
                          <th scope="col">
                            {props.constLabel?.lbl_product_name
                              ? props.constLabel.lbl_product_name
                              : "Product Name"}
                          </th>
                          <th scope="col">
                            {props.constLabel?.lbl_quantity
                              ? props.constLabel.lbl_quantity
                              : "Quantity"}
                          </th>
                          <th scope="col">
                            {props.constLabel?.lbl_unit
                              ? props.constLabel.lbl_unit
                              : "Unit"}
                          </th>
                          <th scope="col">
                            {props.constLabel?.lbl_rate
                              ? props.constLabel.lbl_rate
                              : "Rate"}{" "}
                            <span>&#8377;</span>
                          </th>
                          <th scope="col">
                            {props.constLabel?.lbl_amount
                              ? props.constLabel.lbl_amount
                              : "Amount"}{" "}
                            <span>&#8377;</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {fields.map(({ id }, index) => (
                          <tr key={id} className="f-18">
                            <td style={{ verticalAlign: "top" }}>
                              <Controller
                                name={`items[${index}].prod_type`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="productType"
                                    size="sm"
                                    multiple={false}
                                    options={prodType.data}
                                    isLoading={prodType.loading}
                                    placeholder="Select product type"
                                    // onChange={onChange}
                                    onChange={(selected) => {
                                      handleProdType(selected, index);
                                    }}
                                    onBlur={onBlur}
                                    selected={value}
                                    disabled={usingQuoteProd}
                                    isInvalid={
                                      errors.items?.[index]?.prod_type
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              />

                              {errors.items?.[index]?.prod_type && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.prod_type.message}
                                </span>
                              )}

                              {!usingQuoteProd ? (
                                index + 1 == fields.length ? (
                                  <div>
                                    <button
                                      style={{ fontSize: "16px" }}
                                      className="btn btn-sm button-primary px-2 mt-2"
                                      onClick={() => {
                                        append({
                                          prod_type: [],
                                          product: [],
                                          quantity: 0,
                                          unit: [],
                                          price: 0,
                                          amount: 0,
                                        });
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        style={{
                                          color: "#ffffff",
                                          marginRight: "5px",
                                        }}
                                        icon={faAdd}
                                        size="sm"
                                      />{" "}
                                      {props.constLabel?.lbl_save
                                        ? props.constLabel.lbl_save
                                        : "Save"}
                                    </button>
                                  </div>
                                ) : null
                              ) : null}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <Controller
                                name={`items[${index}].product`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="productName"
                                    size="sm"
                                    multiple={false}
                                    options={prod_List.data}
                                    isLoading={prod_List.loading}
                                    placeholder="Select product"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    disabled={usingQuoteProd}
                                    isInvalid={
                                      errors.items?.[index]?.product
                                        ? true
                                        : false
                                    }
                                  />
                                )}
                              />

                              {errors.items?.[index]?.product && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.product.message}
                                </span>
                              )}

                              {/* {!usingQuoteProd ? index + 1 == fields.length ? (
                            <AddProduct fetchProdList={fetchProdList} />
                          ) : null : null} */}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={
                                  errors.items?.[index]?.quantity
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                defaultValue={0}
                                name={`items[${index}].quantity`}
                                {...register(`items[${index}].quantity`, {
                                  onChange: (e) =>
                                    calRate(e.target.value, "quantity", index),
                                })}
                                disabled={usingQuoteProd}
                              />

                              {errors.items?.[index]?.quantity && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.quantity.message}
                                </span>
                              )}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <Controller
                                name={`items[${index}].unit`}
                                control={control}
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                }) => (
                                  <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="uom"
                                    size="sm"
                                    multiple={false}
                                    options={uomList.data}
                                    isLoading={uomList.loading}
                                    placeholder="Select unit"
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    selected={value}
                                    isInvalid={
                                      errors.items?.[index]?.unit ? true : false
                                    }
                                    disabled={usingQuoteProd}
                                  />
                                )}
                              />

                              {errors.items?.[index]?.unit && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.unit.message}
                                </span>
                              )}
                            </td>

                            <td style={{ verticalAlign: "top" }}>
                              <input
                                className={
                                  errors.items?.[index]?.price
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                defaultValue={0}
                                min={0}
                                name={`items[${index}].price`}
                                {...register(`items[${index}].price`, {
                                  onChange: (e) =>
                                    calRate(e.target.value, "price", index),
                                })}
                                disabled={usingQuoteProd}
                              />

                              {errors.items?.[index]?.price && (
                                <span className="text-danger err-msg">
                                  {errors.items?.[index]?.price.message}
                                </span>
                              )}
                            </td>

                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "top",
                                width: "10%",
                              }}
                            >
                              <input
                                className={
                                  errors.items?.[index]?.amount
                                    ? "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"
                                }
                                type="text"
                                defaultValue={0}
                                disabled
                                name={`items[${index}].amount`}
                                {...register(`items[${index}].amount`)}
                              />
                            </td>

                            <td
                              style={{
                                textAlign: "center",
                                verticalAlign: "top",
                                width: "10%",
                              }}
                            >
                              <OverlayTrigger
                                placement="bottom"
                                overlay={
                                  <Tooltip id="tooltip">
                                    {props.constLabel?.lbl_delete_product
                                      ? props.constLabel.lbl_delete_product
                                      : "Delete Product"}
                                  </Tooltip>
                                }
                              >
                                {
                                  <div
                                    className="deleteIcon cursor_pointer"
                                    onClick={
                                      !usingQuoteProd
                                        ? index > 0
                                          ? () => {
                                              remove(index);
                                            }
                                          : null
                                        : null
                                    }
                                  >
                                    <Trash2 color="#FFFFFF" size={18} />
                                  </div>
                                }
                              </OverlayTrigger>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>

                  <div
                    className="p-3 ms-auto col-12 col-md-6"
                    style={{ border: "1px solid #c6c6c6", borderRadius: "2px" }}
                  >
                    <div className="row mb-2">
                      <div className="col-12 row ms-auto">
                        <label
                          style={{ fontSize: "14px" }}
                          className="col-form-label roboto-bold col-12 col-md-3"
                        >
                          {props.constLabel?.lbl_sub_total
                            ? props.constLabel.lbl_sub_total
                            : "Sub Total"}{" "}
                          <span>&#8377;</span>
                        </label>

                        <div className="col-12 col-md-4 ">
                          <input
                            className="form-control form-control-sm"
                            type="number"
                            value={subTotalForRef}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-12 row ms-auto">
                        <label
                          style={{ fontSize: "14px" }}
                          className="col-form-label roboto-bold col-12 col-md-3"
                        >
                          {props.constLabel?.lbl_discount
                            ? props.constLabel.lbl_discount
                            : "Discount"}{" "}
                          (%)
                        </label>

                        <div className="col-12 col-md-4">
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            {...register("discount", {
                              onChange: (e) => calDiscount(e.target.value),
                            })}
                            max={100}
                            min={0}
                            defaultValue={0}
                            placeholder="Enter discount"
                            disabled={usingQuoteProd}
                          />
                          {errors.discount && (
                            <span className="text-danger err-msg">
                              {errors.discount.message}
                            </span>
                          )}
                        </div>

                        <div className="col-12 col-md-4 d-flex align-items-center">
                          <span>&#8377;</span>
                          <input
                            className="form-control form-control-sm ms-2"
                            type="text"
                            disabled
                            {...register("discount_amount")}
                            defaultValue={0}
                            placeholder="Discounted Amount"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-12 row ms-auto">
                        <label
                          style={{ fontSize: "14px" }}
                          className="col-form-label roboto-bold col-12 col-md-3"
                        >
                          {props.constLabel?.lbl_amountAfter_discount
                            ? props.constLabel.lbl_amountAfter_discount
                            : "Amount after discount"}{" "}
                          <span>&#8377;</span>
                        </label>

                        <div className="col-12 col-md-4 ">
                          <input
                            className="form-control form-control-sm"
                            type="number"
                            {...register("subTotal")}
                            disabled
                          />
                        </div>
                      </div>
                    </div>

                    <hr />

                    {taxDetails.cgst ? (
                      <div className="row mb-2">
                        <div className="col-12 row ms-auto">
                          <label
                            style={{ fontSize: "14px" }}
                            className="col-form-label roboto-bold col-12 col-md-3"
                          >
                            {props.constLabel?.lbl_cgst
                              ? props.constLabel.lbl_cgst
                              : "CGST"}{" "}
                            (%)
                          </label>

                          <div className="col-12 col-md-4">
                            <input
                              className="form-control form-control-sm"
                              type="number"
                              {...register("CGST", {
                                onChange: (e) =>
                                  CaltotalAmount(e.target.value, "CGST"),
                              })}
                              defaultValue={0}
                              placeholder="Enter CGST"
                              disabled
                            />
                            {errors.CGST && (
                              <span className="text-danger err-msg">
                                {errors.CGST.message}
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-4  d-flex align-items-center">
                            <span>&#8377;</span>
                            <input
                              className="form-control form-control-sm ms-2"
                              type="text"
                              disabled
                              defaultValue={0}
                              {...register("CGST_amount")}
                              placeholder="CGST Amount"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {taxDetails.sgst ? (
                      <div className="row mb-2">
                        <div className="col-12 row ms-auto">
                          <label
                            style={{ fontSize: "14px" }}
                            className="col-form-label roboto-bold col-12 col-md-3"
                          >
                            {props.constLabel?.lbl_sgst
                              ? props.constLabel.lbl_sgst
                              : "SGST"}{" "}
                            (%)
                          </label>

                          <div className="col-12 col-md-4">
                            <input
                              className="form-control form-control-sm"
                              type="number"
                              defaultValue={0}
                              {...register("SGST", {
                                onChange: (e) =>
                                  CaltotalAmount(e.target.value, "SGST"),
                              })}
                              placeholder="Enter SGST"
                              disabled
                            />
                            {errors.SGST && (
                              <span className="text-danger err-msg">
                                {errors.SGST.message}
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-4 d-flex align-items-center">
                            <span>&#8377;</span>
                            <input
                              className="form-control form-control-sm ms-2"
                              type="text"
                              defaultValue={0}
                              {...register("SGST_amount")}
                              disabled
                              placeholder="SGST Amount"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {taxDetails.igst ? (
                      <div className="row mb-2">
                        <div className="col-12 row ms-auto">
                          <label
                            style={{ fontSize: "14px" }}
                            className="col-form-label roboto-bold col-12 col-md-3"
                          >
                            {props.constLabel?.lbl_igst
                              ? props.constLabel.lbl_igst
                              : "IGST"}{" "}
                            (%)
                          </label>

                          <div className="col-12 col-md-4">
                            <input
                              className="form-control form-control-sm"
                              type="text"
                              value={taxDetails.igst}
                              {...register("IGST")}
                              // {...register("IGST", {
                              //     onChange: (e) => CaltotalAmount(e.target.value, "IGST")
                              // })}
                              placeholder="Enter IGST"
                              disabled
                            />
                            {errors.IGST && (
                              <span className="text-danger err-msg">
                                {errors.IGST.message}
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-4 d-flex align-items-center">
                            <span>&#8377;</span>
                            <input
                              className="form-control form-control-sm ms-2"
                              type="text"
                              defaultValue={0}
                              {...register("IGST_amount")}
                              disabled
                              placeholder="IGST Amount"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    {taxDetails.type == "ugst" ? (
                      <div className="row mb-2">
                        <div className="col-12 row ms-auto">
                          <label
                            style={{ fontSize: "14px" }}
                            className="col-form-label roboto-bold col-12 col-md-3"
                          >
                            {props.constLabel?.lbl_ugst
                              ? props.constLabel.lbl_ugst
                              : "UGST"}{" "}
                            (%)
                            <span>
                              <i style={{ color: "red" }}>*</i>
                            </span>
                          </label>

                          <div className="col-12 col-md-4">
                            <input
                              className="form-control form-control-sm"
                              type="number"
                              placeholder="Enter ugst"
                              {...register("UGST")}
                              value={taxDetails.ugst}
                              disabled
                            />
                            {errors.ugst && (
                              <span className="text-danger err-msg">
                                {errors.ugst.message}
                              </span>
                            )}
                          </div>
                          <div className="col-12 col-md-4 d-flex align-items-center">
                            <span>&#8377;</span>
                            <input
                              className="form-control form-control-sm ms-2"
                              type="number"
                              disabled
                              {...register("UGST_amount")}
                              placeholder="ugst cost"
                            />
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="row mb-2">
                      <div className="col-12 row ms-auto">
                        <label
                          style={{ fontSize: "14px" }}
                          className="col-form-label roboto-bold col-12 col-md-3"
                        >
                          {props.constLabel?.lbl_total_amount
                            ? props.constLabel.lbl_total_amount
                            : "Total Amount"}{" "}
                          &#8377;
                        </label>

                        <div className="col-12 col-md-4">
                          <input
                            className="form-control form-control-sm"
                            type="number"
                            {...register("totalAmount")}
                            defaultValue={0}
                            placeholder="Total Amount"
                            disabled
                          />
                          {errors.totalAmount && (
                            <span className="text-danger err-msg">
                              {errors.totalAmount.message}
                            </span>
                          )}
                        </div>
                      </div>

                      {gstErr ? (
                        <div className="col-12 mt-3">
                          <div
                            className="alert alert-danger mb-0 text-center my-4"
                            role="alert"
                          >
                            GST Number is not valid of supplier or invoice to's
                            company, purchase order will be saved without gst.
                          </div>
                        </div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            {/* <button
                  onClick={() => setGalVisible(true)}
                  className="btn btn-yellow px-3"
                >
                  Image Gallery
                  <FontAwesomeIcon
                    style={{
                      color: "#000",
                      fontSize: "16px",
                      marginLeft: "5px",
                    }}
                    icon={faImage}
                  />
                </button>

            <button
              className="btn btn-grey px-4 ms-0 ms-md-2"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </button> */}
          </div>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default CreatePO;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faSearch } from "@fortawesome/free-solid-svg-icons";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import CreateBasicDetail from "../Customer/BasicDetail/CreateBasicDetail";
import CreateContactDetails from "../Customer/ContactDetail/CreateContactDetails";
import CreateBillingAddress from "../Customer/BillingAddress/CreateBillingAddress";
import CreateShippingAddress from "../Customer/ShippingAddress/CreateShippingAddress";
import BillingDetail from "../Customer/BillingDetail/BillingDetail";
import BankDetail from "../Customer/BankDetail/BankDetail";
import { useNavigate } from "react-router-dom";
import SweetAlert from 'sweetalert2';
import { getLabels } from "../../../../common/Functions/CommonFunctions";

function CreateCustomer() {
  let navigate = useNavigate();
  const [key, setKey] = useState("basicDetail");
  const [visible, setVisible] = useState(false);
  const [constLabel, setConstLabel] = useState({});

  useEffect(() => {
    setConstLabel(getLabels('Party Master', 'Customer'));
  }, [])

  

  return (
    <React.Fragment>
       <div className="mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                SweetAlert.fire({
                  title: "Are you sure?",
                  text: "Your present data will be lost!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, go back!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.onpopstate = undefined;

                    navigate("/pcms/customer");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
            {constLabel?.lbl_add_customer ? constLabel.lbl_add_customer : "Add Customer"}
            </p>
          </div>
          <div className="card-body" id="party_master">
          <form action="">
            <Tabs
              variant="pills"
              id="controlled-tab-example"
              activeKey={key}
              // onSelect={(k) => {
              //   setKey(k);              
              // }}
              className="mb-3"
              fill
            >
              <Tab
                eventKey="basicDetail"
                title={<span className="f-14 f-w-600">{constLabel?.lbl_basic_detail ? constLabel.lbl_basic_detail : "Basic Detail"}</span>}
              >
                <CreateBasicDetail setKey={setKey} />
              </Tab>

              <Tab
                eventKey="contactDetail"
                title={<span className="f-14 f-w-600">{constLabel?.lbl_contact_detail ? constLabel.lbl_contact_detail : "Contact Detail"}</span>}
              >
                <CreateContactDetails setKey={setKey} />
              </Tab>

              <Tab
                eventKey="billingAddress"
                title={<span className="f-14 f-w-600">{constLabel?.lbl_billing_address ? constLabel.lbl_billing_address : "Billing Address"}</span>}
              >
                <CreateBillingAddress setKey={setKey} />
              </Tab>

              <Tab
                eventKey="shippingAddress"
                title={<span className="f-14 f-w-600">{constLabel?.lbl_shipping_address ? constLabel.lbl_shipping_address : "Shipping Address"}</span>}
              >
                <CreateShippingAddress setKey={setKey} />
              </Tab>

              <Tab
                eventKey="billingDetail"
                title={<span className="f-14 f-w-600">{constLabel?.lbl_tax_detail ? constLabel.lbl_tax_detail : "Tax Detail"}</span>}
              >
                <BillingDetail setKey={setKey} />
              </Tab>

              {/* <Tab
                eventKey="bankDetail"
                title={<span className="f-14 f-w-600">Bank Detail</span>}
              >
                <BankDetail setVisible={setVisible}/>
              </Tab> */}
            </Tabs>
          </form>
          </div>
        </div>
      </div>  
    </React.Fragment>
  );
}

export default CreateCustomer;

import React from 'react'

export const Screen = () => {
  let module_list=JSON.parse(localStorage.getItem("lablelist")); 


  const mergedScreens = module_list.reduce((acc, curr) => {
    return acc.concat(curr.screen_list);
}, []);

console.log(mergedScreens);
console.log(module_list);

  
  return (
    <div className="card-body">
      <h1>Screen Configuration Setting</h1>
      <hr />
            <div >
              <p className="mb-2 ms-3" style={{ fontSize: "24px" }}>
                Modules
              </p>
           <div >
           {module_list.map((item,ind)=>{
              return <div className='col-12'>{item.module_name}</div>
            })}
           </div>
           
              
            </div>

            <hr />

    </div>
  )
}

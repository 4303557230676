import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Trash2, Eye } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import {
  getOperationApi,
  create_tool_fixture_operation,
  get_tool_fixture_operation,
  update_tool_fixture_operation,
  delete_tool_fixture_operation,
} from "../../../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";

const schema = yup
  .object()
  .shape({
    items: yup.array().of(
      yup.object().shape({
        operation: yup
          .array()
          .required("Please select operation")
          .min(1, "Please select operation"),
      })
    ),
  })
  .required();

function ToolsFixturesOpration({
  nextStep,
  previousStep,
  main_obj,
  setMain_obj,
}) {
  const [operationList, setOperationList] = useState({
    data: [],
    loading: false,
  });

  const [action, setAction] = useState("create");

  const [currIndex, setCurrIndex] = useState(0);
  const [operList, setOperList] = useState({ data: [], loading: false });
  const [constLabel, setConstLabel] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {}, []);

  // const productData = JSON.parse(localStorage.getItem("prod_res"));
  // const bom_status = JSON.parse(localStorage.getItem('bomStatus'));

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [],
    },
  });

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({ control, name: "items" });
  // const prod_data = JSON.parse(localStorage.getItem("prod_res"));

  // function removeMachine(index) {
  //   let tempMachines = [...machines];

  //   tempMachines[index]?.data?.splice(index, 1);
  //   setMachines(tempMachines);

  // }

  useEffect(() => {
    setConstLabel(getLabels("Master", "Product"));
    setOperationList({ ...operationList, data: [], loading: true });

    getOperationApi().then(
      (res) => {
        setOperationList({
          ...operationList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        setOperationList({ ...operationList, data: [], loading: false });
      }
    );

    gettools_fixture_operation();
  }, []);

  function gettools_fixture_operation() {
    setOperList({ ...operList, data: [], loading: true });

    get_tool_fixture_operation({ tool_id: main_obj?.prod_overview?.uuid }).then(
      (res) => {
        if (res.data.data.length > 0) {
          setOperList({ ...operList, data: res.data.data, loading: false });
          setAction("update");
          const tempList = [];

          // console.log("res.data.data", res.data.data);
          for (let i = 0; i < res.data.data.length; i++) {
            tempList.push({
              uuid: res.data.data[i].uuid,
              operation: [
                {
                  uuid: res.data.data[i].operation_id,
                  operationName: res.data.data[i].operation_name,
                  operationID: res.data.data[i].operationid,
                },
              ],
            });
          }

          reset({
            items: tempList,
          });
        } else {
          setAction("create");
        }
      },
      (err) => {
        setOperList({ ...operList, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    // setBtnDisable(true);
    // setMachineData([...machineData, data]);

    let primaryData = [];

    if (action == "create") {
      for (let i = 0; i < data.items.length; i++) {
        primaryData.push({
          toolid: main_obj && main_obj?.prod_overview?.productId,
          tool_name: main_obj && main_obj?.prod_overview?.productName,
          tool_id: main_obj && main_obj?.prod_overview?.uuid,
          operation_id: data.items[i].operation[0].uuid,
          operation_name: data.items[i].operation[0].operationName,
          operationid: data.items[i].operation[0].operationID,
        });
      }

      // console.log("asdf", primaryData);

      const payload = {
        operation_data: primaryData,
      };

      create_tool_fixture_operation(payload).then(
        (res) => {
          setBtnDisable(false);
          let message =
            main_obj?.prod_overview?.product_type_id?.productType == "Consumable Items"
              ? "Tool operation created successfully"
              : main_obj?.prod_overview?.product_type_id?.productType == "Fixtures"?
               "Fixture operation created successfully":"Gauges operation created successfully";
          toast.success(message, {
            autoClose: 2000,
          });
          setTimeout(() => {
            nextStep();
          }, [2300]);
        },
        (err) => {
          setBtnDisable(false);

          toast.error("Something went wrong in saving operation", {
            autoClose: 2000,
          });
        }
      );
    } else {
      for (let i = 0; i < data.items.length; i++) {
        // console.log("data", data);
        let temp = {
          uuid: data.items[i].uuid,
          toolid: main_obj && main_obj?.prod_overview?.productId,
          tool_name: main_obj && main_obj?.prod_overview?.productName,
          tool_id: main_obj && main_obj?.prod_overview?.uuid,
          operation_id: data.items[i].operation[0].uuid,
          operation_name: data.items[i].operation[0].operationName,
          operationid: data.items[i].operation[0].operationID,
        };

        if (temp.uuid == undefined) {
          delete temp.uuid;
        }
        primaryData.push(temp);
      }

      const payload = {
        operation_data: primaryData,
      };
      // console.log("payload", payload);

      update_tool_fixture_operation(payload).then(
        (res) => {
          setBtnDisable(false);

          let message =
            main_obj?.prod_overview?.product_type_id?.productType == "Consumable Items"
              ? "Consumable item operation updated successfully"
              : main_obj?.prod_overview?.product_type_id?.productType == "Fixtures"?
               "Fixture operation updated successfully" :"Gauges operation updated successfully";
          toast.success(message, {
            autoClose: 2000,
          });
          setTimeout(() => {
            nextStep();
          }, [2300]);
        },
        (err) => {
          setBtnDisable(false);
          toast.error("Something went wrong in updating", {
            autoClose: 2000,
          });
        }
      );
    }
  };

  function deltoolsfixturesOper(index) {
    SweetAlert.fire({
      title: "Are you sure to delete",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delete_tool_fixture_operation({ uuid: fields[index].uuid }).then(
          (res) => {
            let message =
              main_obj?.prod_overview?.product_type_id?.productType == "Consumable Items"
                ? "Consumable item operation deleted successfully"
                : main_obj?.prod_overview?.product_type_id?.productType == "Fixtures"?
                "Fixture operation deleted successfully" : "Gauges operation deleted successfully";
            toast.success(message, { autoClose: 2000 });
            gettools_fixture_operation();
          },
          (err) => {}
        );
      }
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="prod_configurator card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <div className="d-flex justify-content-start">
            <p className="pc_card_title roboto-bold">
              {main_obj?.prod_overview?.product_type_id?.productType == "Consumable Items"
                ? constLabel?.lbl_tool_operation
                  ? constLabel.lbl_tool_operation
                  : "Consumable Item Operation"
                :main_obj?.prod_overview?.product_type_id?.productType == "Fixtures"?
                 constLabel?.lbl_fixture_operation
                ? constLabel?.lbl_fixture_operation
                : "Fixture Operation": constLabel?.lbl_gauges_operation	
                ? constLabel?.lbl_gauges_operation	
                : "Gauges Operation"}
            </p>
          </div>

          {fields.length > 0 ? (
            fields.map(({ id }, index) => (
              <React.Fragment key={index}>
                <div className="col-12 row me-0 me-md-1 vf-danger">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <label className="lbl-style col-form-label roboto-bold">
                        {main_obj?.prod_overview?.product_type_id
                          ?.productType == "Consumable Items"
                          ? constLabel?.lbl_tool_name
                            ? constLabel.lbl_tool_name
                            : "Consumable Item Name"
                          :main_obj?.prod_overview?.product_type_id?.productType == "Fixtures"? 
                          constLabel?.lbl_equipment_name
                          ? constLabel?.lbl_equipment_name
                          : "Equipment Name":constLabel?.lbl_gauge_name
                          ? constLabel?.lbl_gauge_name
                          : "Gauge Name"}
                      </label>

                      <label
                        className="col-form-label roboto-bold ms-0 ms-md-2"
                        style={{ color: "#EEAE2D" }}
                      >
                        {main_obj && main_obj?.prod_overview?.productName}
                      </label>
                    </div>
                    <div>
                      <FontAwesomeIcon
                        style={{
                          color: "black",
                          cursor: "pointer",
                        }}
                        icon={faClose}
                        size="lg"
                        onClick={() => {
                          setCurrIndex((prevCurr) => parseInt(prevCurr) - 1);

                          if (fields[index].uuid !== undefined) {
                            deltoolsfixturesOper(index);
                          } else {
                            remove(index);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-3 mb-5">
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_sequence_no
                              ? constLabel.lbl_sequence_no
                              : "Sequence No."}
                          </label>
                          <input
                            className={
                              errors.items?.[index]?.sequenceNo
                                ? "form-control mt-2 is-invalid"
                                : "form-control mt-2 "
                            }
                            type="text"
                            value={index + 1}
                            disabled={true}
                            // {...register(`items[${index}].sequenceNo`)}
                            placeholder="Enter sequence no."
                          />
                        </div>
                      </div>

                      <div className="col-md-7">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_operation_name
                              ? constLabel.lbl_operation_name
                              : "Operation Name"}
                          </label>
                          <Controller
                            name={`items[${index}].operation`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                className="mt-2"
                                id="basic-typeahead"
                                labelKey="operationName"
                                multiple={false}
                                options={operationList.data}
                                isLoading={false}
                                placeholder="Select operation"
                                // onChange={onChange}
                                onChange={(selected) => {
                                  onChange(selected);
                                }}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.operation
                                    ? true
                                    : false
                                }
                              />
                            )}
                          />

                          {errors.items?.[index]?.operation && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.operation.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <hr className="my-4" />
              </React.Fragment>
            ))
          ) : (
            <div
              className="alert alert-danger mb-0 text-center my-4"
              role="alert"
            >
              {main_obj?.prod_overview?.product_type_id?.productType == "Consumable Items"
                ? "No consumable item operation found !"
                :main_obj?.prod_overview?.product_type_id?.productType == "Fixtures"?
                 "No fixture operation found":"No gauges operation found"}
            </div>
          )}

          <div>
            <span
              onClick={() => {
                append({});

                setCurrIndex((prevCurr) => parseInt(prevCurr) + 1);
              }}
              className="mt-4 mb-0 text_gold roboto-bold cursor_pointer"
              style={{ fontSize: "20px" }}
            >
              {"+"}{" "}
              {constLabel?.lbl_add_sequence
                ? constLabel?.lbl_add_sequence
                : "Add Sequence"}
            </span>
          </div>

          <div className="d-flex justify-content-end">
            {/* <button
          className="btn btn-yellow px-4 me-2"
          onClick={() => navigate("/pcms/product")}
        >
          {constLabel?.lbl_cancel ? constLabel?.lbl_cancel : ""}
        </button> */}
            <button
              className="btn btn-grey px-4 me-2"
              onClick={() => previousStep()}
            >
              {constLabel?.lbl_previous ? constLabel?.lbl_previous : "Previous"}
            </button>
            <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
              disabled={btnDisable}
            >
              {constLabel?.lbl_save_continue
                ? constLabel?.lbl_save_continue
                : "Save & Continue"}
            </button>

            <button
              className="btn btn-grey px-4 "
              // onClick={() => props.setCurrentStep(props.currentStep + 1)}
              onClick={() => {
                nextStep();
              }}
            >
              {constLabel?.lbl_skip ? constLabel?.lbl_skip : "Skip"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ToolsFixturesOpration;

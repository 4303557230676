import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faDownload } from "@fortawesome/free-solid-svg-icons";
import Roboto from "../../assets/fonts/Roboto-Regular.ttf";
import Roboto_Bold from "../../assets/fonts/Roboto-Bold.ttf";

import {
  PDFViewer,
  Document,
  Text,
  View,
  Image,
  Page,
  Link,
  StyleSheet,
  Font,
  pdf,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import { formatDate5 } from "../../common/Functions/CommonFunctions";

function GoodsChallanPDF() {
  let navigate = useNavigate();
  let location = useLocation();
  const [party_details, setparty_details] = useState(null);
  const [gst_type, setgst_type] = useState(null);
  const [sub_total, setSub_total] = useState(null);
  const [tax_total, setTax_total] = useState(null);
  const [pdfBlobUrl, setPdfBlobUrl] = useState("");
  const [isPdfLoaded, setIsPdfLoaded] = useState(false);
  const downloadLinkRef = useRef(null);
  const data = location.state;

  console.log("data", data);

  Font.register({
    family: "roboto, roboto-light,roboto-bold",
    fonts: [
      {
        src: Roboto,
      },
      {
        src: Roboto_Bold,
        fontWeight: 600,
      },
    ],
  });

  const styles = StyleSheet.create({
    viewer: {
      width: "100%",
      height: window.innerHeight,
    },
    page: {
      paddingTop: 15,
      paddingBottom: 40,
      paddingHorizontal: 15,
      color: "black",
      fontFamily: "roboto, roboto-light,roboto-bold",
      border: "1px solid black",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 28,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
    },
    footer: {
      position: "absolute",
      fontSize: 12,
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
      paddingTop: "50px",
      paddingBottom: "10",
      paddingLeft: "15",
      paddingRight: "15",
      // borderTop:'1px solid black',
    },
    footer1: {
      position: "absolute",
      fontSize: 12,
      bottom: 50,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
      paddingTop: "50px",
      paddingBottom: "10",
      paddingLeft: "15",
      paddingRight: "15",
      // borderTop:'1px solid black',
    },
    row1: {
      flexDirection: "row",
      border: "1px solid black",
    },
    row2: {
      flexDirection: "row",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
    },
    row3: {
      flexDirection: "row",
      fontSize: "10px",
      width: "100%",
    },
    tablColumn1: {
      width: "6%",
      fontSize: "10px",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn1Data: {
      width: "6%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn2: {
      width: "30%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn2Data: {
      width: "30%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn3: {
      width: "10%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn3Data: {
      width: "10%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn4: {
      width: "12%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn4Data: {
      width: "12%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn5: {
      width: "10%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn5Data: {
      width: "10%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn6: {
      width: "12%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn6Data: {
      width: "12%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn7: {
      width: "18%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn7Data: {
      width: "18%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn8: {
      width: "20%",
      border: "1px solid black",
      textAlign: "center",
      // padding: "5px",
      fontWeight: 600,
    },
    tablColumn8Data: {
      width: "20%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },

    smallcolumn: {
      // border: "1px solid black",
      width: "50%",
    },
    subDivRow: {
      flexDirection: "row",
    },
    column: {
      width: "50%",
      // borderRight: '1px solid black',
      // backgroundColor: 'red',
      fontSize: "10px",
    },
    title: {
      textAlign: "center",
      fontWeight: 600,
    },
    companyName: {
      fontWeight: "600",
      fontSize: "12px",
    },
    signatureBlock: {
      width: "100%",
      marginLeft: "auto",
      marginTop: "100px",
      borderTop: "1px solid black",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    section: {
      marginBottom: 10,
    },
    label: {
      fontWeight: "bold",
      fontSize: "12px",
      marginLeft: "10px",
    },
    value: {
      flex: 1,
      fontSize: "12px",
    },
    row4: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
    },
    label2: {
      fontWeight: "bold",
      fontSize: "12px",
      marginLeft: "10px",
    },
    value2: {
      flex: 1,
      fontSize: "12px",
    },
    row5: {
      flexDirection: "row",
      alignItems: "flex-start",
      marginBottom: 5,
    },
    bottomSection: {
      marginTop: 20,
      flexDirection: "column",
      alignItems: "flex-end",
    },
    text: {
      fontSize: 12,
      marginBottom: 10,
      fontWeight: "bold",
    },
  });

  useEffect(() => {
    if (location.state.party_id !== null) {
      setparty_details(location.state.partyDetails);
      let sub_value = location.state.challan_line_items.reduce((ac, dc) => {
        let a = dc.amount ? dc.amount : 0;
        return ac + Number(dc.qty * a);
      }, 0);
      setSub_total(sub_value);
      let total_tax;
      if (
        location?.state?.challan_line_items[0]?.igst_rate &&
        location?.state?.challan_line_items[0]?.igst_rate !== 0
      ) {
        setgst_type("igst");
        total_tax = location.state.challan_line_items.reduce((ac, dc) => {
          return ac + Number(dc.igst_amount);
        }, 0);
        setTax_total(total_tax);
      } else if (
        location?.state?.challan_line_items[0]?.ugst_rate &&
        location?.state?.challan_line_items[0]?.ugst_rate !== 0
      ) {
        total_tax = location.state.challan_line_items.reduce((ac, dc) => {
          return ac + Number(dc.ugst_amount);
        }, 0);
        total_tax = total_tax * 2;
        setTax_total(total_tax);
        setgst_type("ugst");
      } else if (
        location?.state?.challan_line_items[0]?.sgst_rate &&
        location?.state?.challan_line_items[0]?.sgst_rate !== 0
      ) {
        setgst_type("sgst");

        total_tax = location.state.challan_line_items.reduce((ac, dc) => {
          return ac + Number(dc.sgst_amount);
        }, 0);
        total_tax = total_tax * 2;
        setTax_total(total_tax);
      } else {
        setTax_total(null);
        setSub_total(null);
        setgst_type(null);
      }

      let is_job_card_present=false; 
      location.state.challan_line_items.forEach((item,ind)=>{
         if(item.job_card_id){
          is_job_card_present=true; 
          item.cgst_amount=0; 
          item.cgst_rate=0; 
          item.igst_amount=0; 
          item.igst_rate=0; 
          item.ugst_amount=0;
          item.ugst_rate=0; 
          item.sgst_amount=0; 
          item.sgst_rate=0; 
         }

         
      }); 

      console.log("location.state.challan_line_items",  location.state.challan_line_items)

      if(is_job_card_present){
        setTax_total(null);
        setSub_total(null);
        setgst_type(null);
      }
     
    }
  }, [location.state]);

  useEffect(() => {
    const generatePdfBlob = async () => {
      const doc = (
        <ChallanDocument data={data} party_name={party_details?.name} />
      );
      const asPdf = pdf([]);
      asPdf.updateContainer(doc);
      const blob = await asPdf.toBlob();
      const url = URL.createObjectURL(blob);
      setPdfBlobUrl(url);
      setIsPdfLoaded(true);
    };

    generatePdfBlob();
  }, [data, party_details]);

  const handleDownload = () => {
    const link = downloadLinkRef.current;
    link.href = pdfBlobUrl;
    link.download = `Goods Challan - ${data.gmchallanid}.pdf`;
    link.click();
  };

  const ChallanDocument = ({ data, party_name }) => (
    <Document title={`Goods Challan - ${data.gmchallanid}`}>
      <Page style={styles.page}>
        <Text style={styles.title}>Goods Challan</Text>
        <View style={[styles.row1, { marginTop: "10px" }]}>
          <View style={[styles.column, { marginTop: "20px" }]}>
            <View style={styles.section}>
              <View style={styles.row4}>
                <Text style={styles.label}>Company Name: </Text>
                {data.challantype.includes("Outward") ||
                data.challantype == "Dispatch Challan" ? (
                  <Text style={styles.value}>
                    {data?.fromDetails?.company?.comName
                      ? data.fromDetails.company.comName
                      : "NA"}
                  </Text>
                ) : (
                  <Text style={styles.value}>
                    {data?.toDetails?.company?.comName
                      ? data.toDetails.company.comName
                      : "NA"}
                  </Text>
                )}
              </View>
              <View style={styles.row4}>
                <Text style={styles.label}>Address: </Text>
                {data.challantype.includes("Outward") ||
                data.challantype == "Dispatch Challan" ? (
                  <Text style={styles.value}>
                    {data?.fromDetails?.company?.comaddress
                      ? data.fromDetails.company.comaddress
                      : "NA"}
                  </Text>
                ) : (
                  <Text style={styles.value}>
                    {data?.toDetails?.company?.comaddress
                      ? data.toDetails.company.comaddress
                      : "NA"}
                  </Text>
                )}{" "}
              </View>
              <View style={styles.row4}>
                <Text style={styles.label}>GSTIN: </Text>
                {data.challantype.includes("Outward") ||
                data.challantype == "Dispatch Challan" ? (
                  <Text style={styles.value}>
                    {data?.fromDetails?.company?.comGSTnumber
                      ? data.fromDetails.company.comGSTnumber
                      : "NA"}
                  </Text>
                ) : (
                  <Text style={styles.value}>
                    {data?.toDetails?.company?.comGSTnumber
                      ? data.toDetails.company.comGSTnumber
                      : "NA"}
                  </Text>
                )}
              </View>
              <View style={styles.row4}>
                <Text style={styles.label}>Phone: </Text>
                {data.challantype.includes("Outward") ||
                data.challantype == "Dispatch Challan" ? (
                  <Text style={styles.value}>
                    {data?.fromDetails?.company?.phone
                      ? data.fromDetails.company.phone
                      : "NA"}
                  </Text>
                ) : (
                  <Text style={styles.value}>
                    {data?.toDetails?.company?.phone
                      ? data.toDetails.company.phone
                      : "NA"}
                  </Text>
                )}
              </View>
            </View>
          </View>
          <View style={[styles.column, { borderLeft: "1px solid black" }]}>
            <View
              style={[styles.subDivRow, { borderBottom: "1px solid black" }]}
            >
              <View style={[styles.column, { padding: "5px", padding: "5px" }]}>
                <Text>DcNo.</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text>{data.gmchallanid}</Text>
              </View>
            </View>
            <View style={[styles.subDivRow]}>
              <View style={[styles.column, { padding: "5px" }]}>
                <Text>Challan Number</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text>{data.challanno ? data.challanno : "NA"}</Text>
              </View>
            </View>
            <View style={[styles.subDivRow, { borderTop: "1px solid black" }]}>
              <View style={[styles.column, { padding: "5px" }]}>
                <Text>Date</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text>{formatDate5(data.challandate)}</Text>
              </View>
            </View>
            <View style={[styles.subDivRow, { borderTop: "1px solid black" }]}>
              <View style={[styles.column, { padding: "5px", padding: "5px" }]}>
                <Text>Challan Reference</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text>{data.challanRef ? data.challanRef : "NA"}</Text>
              </View>
            </View>
            <View style={[styles.subDivRow, { borderTop: "1px solid black" }]}>
              <View style={[styles.column, { padding: "5px", padding: "5px" }]}>
                <Text>Challan Type</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text>{data.challantype ? data.challantype : "NA"}</Text>
              </View>
            </View>
            <View style={[styles.subDivRow, { borderTop: "1px solid black" }]}>
              <View style={[styles.column, { padding: "5px", padding: "5px" }]}>
                <Text>Challan State</Text>
              </View>
              <View
                style={[
                  styles.column,
                  { padding: "5px", borderLeft: "1px solid black" },
                ]}
              >
                <Text>{data.challanstate ? data.challanstate : "NA"}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={[styles.row1, { marginTop: "10px" }]}>
          <View style={[styles.column,]}>
            <View style={[{ marginTop: "10px" }]}>
            {party_name && location.state.challantype.endsWith("Inward") && (
              <>
                <View style={[styles.section, ]}>
                  <View style={styles.row5}>
                    <Text style={styles.label2}>Party Name: </Text>
                    {data.partyDetails.partyType == "Customer" ||
                    data.partyDetails.partyType == "customer" ? (
                      <Text style={styles.value2}>
                        {data?.partyDetails?.organization
                          ? data?.partyDetails?.organization
                          : "NA"}
                      </Text>
                    ) : (
                      <Text style={styles.value2}>
                        {data?.partyDetails?.name
                          ? data?.partyDetails?.name
                          : "NA"}
                      </Text>
                    )}
                  </View>
                  <View style={styles.row5}>
                    <Text style={styles.label2}>Party Address: </Text>

                    <Text style={styles.value2}>
                      {data?.partyDetails?.address
                        ? data.partyDetails.address
                        : "NA"}
                    </Text>
                  </View>
                  <View style={styles.row5}>
                    <Text style={styles.label2}>GSTIN: </Text>

                    <Text style={styles.value2}>
                      {data?.partyDetails?.gst ? data.partyDetails.gst : "NA"}
                    </Text>
                  </View>
                  <View style={styles.row5}>
                    <Text style={styles.label2}>Phone: </Text>

                    <Text style={styles.value2}>
                      {data?.partyDetails?.primaryContactNo
                        ? data.partyDetails.primaryContactNo
                        : "NA"}
                    </Text>
                  </View>
                </View>
              </>
            )}
            <View style={styles.row5}>
              <Text style={styles.label2}>From Location: </Text>

              <Text style={styles.value2}>
                {data?.fromDetails?.locationname
                  ? data.fromDetails.locationname
                  : "NA"}
              </Text>
            </View>
            </View>
          </View>
          <View style={[styles.column, { borderLeft: "1px solid black" },]}>
          <View style={[{ marginTop: "10px" }]}>
            {party_name &&
              (location.state.challantype.endsWith("Outward") ||
                location.state.challantype == "Dispatch Challan") && (
                <>
                  <View style={[styles.section, ]}>
                 {  location.state.challantype == "Dispatch Challan"&& <Text style={[styles.label2,{marginTop:"5px"}]}>Bill To: </Text>
}
                    <View style={styles.row5}>
                      <Text style={styles.label2}>Party Name: </Text>
                      {data.partyDetails.partyType == "Customer" ||
                      data.partyDetails.partyType == "customer" ? (
                        <Text style={styles.value2}>
                          {data?.partyDetails?.organization
                            ? data?.partyDetails?.organization
                            : "NA"}
                        </Text>
                      ) : (
                        <Text style={styles.value2}>
                          {data?.partyDetails?.name
                            ? data?.partyDetails?.name
                            : "NA"}
                        </Text>
                      )}
                    </View>
                    <View style={styles.row5}>
                      <Text style={styles.label2}>Party Address: </Text>

                      <Text style={styles.value2}>
                        {data?.partyDetails?.address
                          ? data.partyDetails.address
                          : "NA"}
                      </Text>
                    </View>
                    <View style={styles.row5}>
                      <Text style={styles.label2}>GSTIN: </Text>

                      <Text style={styles.value2}>
                        {data?.partyDetails?.gst ? data.partyDetails.gst : "NA"}
                      </Text>
                    </View>
                    <View style={styles.row5}>
                      <Text style={styles.label2}>Phone: </Text>

                      <Text style={styles.value2}>
                        {data?.partyDetails?.primaryContactNo
                          ? data.partyDetails.primaryContactNo
                          : "NA"}
                      </Text>
                    </View>
                  </View>
                </>
              )}
           <View style={[styles.row5,]}>
              <Text style={[styles.label2,]}>To Location: </Text>

              <Text style={styles.value2}>
                {data?.toDetails?.locationname
                  ? data.toDetails.locationname
                  : "NA"}
              </Text>
            </View>
          </View>
          </View>
        </View>

        <View style={[styles.row1, { marginTop: "10px" }]}>
          <View style={styles.column}>
            <Text style={{ margin: "5px", fontSize: "15px", fontWeight:"bold" }}>
              Transport Details
            </Text>
            <View style={styles.row4}>
              <Text style={styles.label2}>Transport: </Text>
              <Text style={styles.value2}> {data.transporttypename}</Text>
          </View>
            <View style={styles.row4}>
              <Text style={styles.label2}>Driver Name: </Text>
              <Text style={styles.value2}> {data.drivername}</Text>
          </View>
            <View style={styles.row4}>
              <Text style={styles.label2}>Vehicle Number: </Text>
              <Text style={styles.value2}>{data.vehicalnumber}</Text>
          </View>
            <View style={styles.row4}>
              <Text style={styles.label2}>Remark: </Text>
              <Text style={styles.value2}>{data.remark}</Text>
          </View>
          </View>
         
        </View>

        <View style={[styles.row3, { marginTop: "10px" }]}>
          <View style={styles.tablColumn1}>
            <Text>Sr No</Text>
          </View>
          <View style={styles.tablColumn2}>
            <Text>Product Name</Text>
          </View>
          <View style={styles.tablColumn4}>
            <Text>HSN/SAC</Text>
          </View>

          <View style={styles.tablColumn5}>
            <Text>Qty</Text>
          </View>
          <View style={styles.tablColumn6}>
            <Text>Price</Text>
          </View>
          <View style={styles.tablColumn7}>
            <Text>Taxable Value</Text>
          </View>
          <View style={styles.tablColumn8}>
            {gst_type && gst_type == "igst" ? (
              <Text style={[{ marginTop: "4px" }]}> IGST</Text>
            ) : gst_type && gst_type == "ugst" ? (
              <Text style={[{ marginTop: "4px" }]}> UGST</Text>
            ) : (
              <Text style={[{ marginTop: "4px" }]}> CGST</Text>
            )}
            <View
              style={[
                styles.subDivRow,
                { borderTop: "1px solid black", marginTop: "5px" },
              ]}
            >
              <Text style={[styles.smallcolumn, { borderRight: "1px" }]}>
                {" "}
                Rate
              </Text>
              <Text style={styles.smallcolumn}> Amount</Text>
            </View>
          </View>
          <View style={styles.tablColumn8}>
            {gst_type && gst_type == "ugst" ? (
              <Text style={[{ marginTop: "4px" }]}> CGST</Text>
            ) : (
              <Text style={[{ marginTop: "4px" }]}> SGST</Text>
            )}
            <View
              style={[
                styles.subDivRow,
                { borderTop: "1px solid black", marginTop: "5px" },
              ]}
            >
              <Text style={[styles.smallcolumn, { borderRight: "1px" }]}>
                {" "}
                Rate
              </Text>
              <Text style={styles.smallcolumn}> Amount</Text>
            </View>
          </View>
        </View>

        {data.challan_line_items.map((val, i) => (
          <View key={i} style={[styles.row3]}>
            <View style={styles.tablColumn1Data}>
              <Text>{i + 1}</Text>
            </View>
            <View style={styles.tablColumn2Data}>
              <Text>{val.product_name}</Text>
              <Text>{`WO No- ${val.work_order_no}`}</Text>
              <Text>{`Operation- ${val.operation_name}`}</Text>
              <Text>{`WO Qty- ${val.work_order_quantity}`}</Text>
            </View>
            <View style={styles.tablColumn4Data}>
              <Text>{val.hsn ? val.hsn : "NA"}</Text>
            </View>
            <View style={styles.tablColumn5Data}>
              <Text>{val.qty}</Text>
            </View>
            <View style={styles.tablColumn6Data}>
            { val.job_card_id ?<Text>{"NA"}</Text>:<Text>{val.amount ? val.amount.toFixed(2) : "NA"}</Text>}
            </View>
            <View style={styles.tablColumn7Data}>
             {val.job_card_id?<Text>
                { "NA"}
              </Text> :<Text>
                {val.amount ? (val.amount * val.qty).toFixed(2) : "NA"}
              </Text>}
            </View>
            <View style={styles.tablColumn8Data}>
              <View style={[styles.subDivRow]}>
                {gst_type && gst_type == "igst" ? (
                  <>
                    <Text style={[styles.smallcolumn, { textAlign: "center" }]}>
                      {val.igst_rate ? `${val.igst_rate * 100}%` : "NA"}
                    </Text>
                    <Text style={[styles.smallcolumn, { textAlign: "center" }]}>
                      {val.igst_amount ? val.igst_amount.toFixed(2) : "NA"}
                    </Text>
                  </>
                ) : gst_type && gst_type == "ugst" ? (
                  <>
                    <Text style={[styles.smallcolumn, { textAlign: "center" }]}>
                      {val.ugst_rate ? `${val.ugst_rate * 100}%` : "NA"}
                    </Text>
                    <Text style={[styles.smallcolumn, { textAlign: "center" }]}>
                      {val.ugst_amount ? val.ugst_amount.toFixed(2) : "NA"}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text style={[styles.smallcolumn, { textAlign: "center" }]}>
                      {val.cgst_rate ? `${val.cgst_rate * 100}%` : "NA"}
                    </Text>
                    <Text style={[styles.smallcolumn, { textAlign: "center" }]}>
                      {val.cgst_amount ? val.cgst_amount.toFixed(2) : "NA"}
                    </Text>
                  </>
                )}
              </View>
            </View>
            <View style={styles.tablColumn8Data}>
              <View style={[styles.subDivRow]}>
                {gst_type && gst_type == "ugst" ? (
                  <>
                    <Text style={[styles.smallcolumn, { textAlign: "center" }]}>
                      {val.cgst_rate ? `${val.cgst_rate * 100}%` : "NA"}
                    </Text>
                    <Text style={[styles.smallcolumn, { textAlign: "center" }]}>
                      {val.cgst_amount ? val.cgst_amount.toFixed(2) : "NA"}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text style={[styles.smallcolumn, { textAlign: "center" }]}>
                      {val.sgst_rate ? `${val.sgst_rate * 100}%` : "NA"}
                    </Text>
                    <Text style={[styles.smallcolumn, { textAlign: "center" }]}>
                      {val.sgst_amount ? val.sgst_amount.toFixed(2) : "NA"}
                    </Text>
                  </>
                )}
              </View>
            </View>
          </View>
        ))}

        <View style={styles.bottomSection}>
          <Text style={styles.text}>
            Sub Total: ₹ {sub_total ? sub_total.toFixed(2) : "0.00"}
          </Text>
          <Text style={styles.text}>
            Total Tax: ₹ {tax_total ? tax_total.toFixed(2) : "0.00"}
          </Text>
          <Text style={styles.text}>
            Total Amount: ₹{" "}
            {sub_total && tax_total
              ? (Number(sub_total) + Number(tax_total)).toFixed(2)
              : "0.00"}
          </Text>
        </View>
        <View style={styles.footer1}>
          <View
            style={{
              flexDirection: "row",
              fontSize: "12px",
              marginTop: "80px",
              marginLeft: "40px",
            }}
          >
            <View style={{ width: "30%" }}>
              <Text>Prepared By</Text>
            </View>
            <View style={{ width: "30%" }}>
              <Text>Verified By</Text>
            </View>
            <View style={{ width: "40%" }}>
              <Text>Authorised Signatory</Text>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />

        <Text style={styles.footer} fixed>
          This is a Computer Generated Document
        </Text>
      </Page>
    </Document>
  );
  return (
    <React.Fragment>
      <ToastContainer />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center justify-content-between">
            <div>
              <div className="p-2 d-flex align-items-center">
                <button
                  className="btn btn-yellow"
                  onClick={() => {
                    navigate("/pcms/goodsMovementChallan");
                  }}
                >
                  <FontAwesomeIcon icon={faArrowLeft} size="sm" />
                </button>

                <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
                  Goods Challan PDF
                </p>
              </div>
            </div>

            <div className=" p-2">
              <button
                className="btn btn-yellow"
                style={styles.downloadButton}
                onClick={handleDownload}
                disabled={!isPdfLoaded}
              >
                <FontAwesomeIcon icon={faDownload} size="sm" /> Download PDF
              </button>
            </div>
          </div>
          <div className="card-body">
            <div className="createNc container-fluid">
              <PDFViewer style={styles.viewer}>
                <ChallanDocument data={data} party_name={party_details?.name} />
              </PDFViewer>
              <a ref={downloadLinkRef} style={{ display: "none" }} />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default GoodsChallanPDF;
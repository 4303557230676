import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Trash2 } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,  
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { getAttribute, getUOM, createProductAttribute, updateProductAttribute, getProductAttribute, deleteProductAttribute } from "../../../../api";
import SweetAlert from "sweetalert2";

const schema = yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      name: yup
        .string()
        .required("Name is required !")
        .matches(/^[a-zA-Z0-9 ]*$/, "Only alphabets, number and space Allowed !"),
      attribute: yup
        .array()
        .min(1, "Please select attribute")
        .required("Attribute is required !"),
      value: yup
        .string()
        .required("Value is required !")
        .matches(/^\d+(\.\d+)?$/, "Value must be a number !"),
      minValue: yup
        .string()
        .required("Min value is required !")
        .matches(/^\d+(\.\d+)?$/, "Min value must be a number !")
        .test(
          "minValueLessThanValue",
          "Min value must be less than value !",
          function (value) {
            const val = this.resolve(yup.ref("value"));
            return parseFloat(value) <= parseFloat(val);
          }
        ),
      maxValue: yup
        .string()
        .required("Max value is required !")
        .matches(/^\d+(\.\d+)?$/, "Max value must be a number !")
        .test(
          "maxValueGreaterThanValue",
          "Max value must be greater than value !",
          function (value) {
            const val = this.resolve(yup.ref("value"));
            return parseFloat(value) >= parseFloat(val);
          }
        ),
      unit: yup
        .array()
        .min(1, "Please select unit")
        .required("Unit is required !"),
    })
  ),
  // selfile: yup
  //     .mixed()
  //     .test('fileSize', 'Image can be uploaded upto 10 MB',
  //         (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)),
})
  .required();

function EditProductSpecifications(props) {
  const [attributeList, setAttribList] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [prod_attr, setProd_Attr] = useState({ data: [], loading: false });
  const [action, setAction] = useState("create");
  const [btnDisable, setBtnDisable] = useState(false);
  const prod_data = JSON.parse(localStorage.getItem("prod_res"));

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  useEffect(() => {
  
    var attrArr = []
    if (prod_attr.data.length > 0) {
      for (let i = 0; i < prod_attr.data.length; i++) {
        let temp = {
          productId: prod_attr.data[i].productId,
          product_id: prod_attr.data[i].product_id,
          name: prod_attr.data[i].name,
          attribute: [{ name: prod_attr.data[i].attname, attId: prod_attr.data[i].attId, attribute_id: prod_attr.data[i].attribute_id }],
          value: prod_attr.data[i].value,
          minValue: prod_attr.data[i].minValue,
          maxValue: prod_attr.data[i].maxValue,
          unit: [{ uom: prod_attr.data[i].unitname, uomid: prod_attr.data[i].unit, uuid: prod_attr.data[i].unit_id }],
          remark: prod_attr.data[i].remark,
          uuid: prod_attr.data[i].uuid
        }
        
        
        if (action == 'create') {
          
          delete temp.uuid;
        }
        attrArr.push(temp)
      }
      
      // 
      // setValue("items", legArr);
      reset({
        items: attrArr,
      })
    } else {
      reset({
        items: [{}],
      })
    }
  }, [prod_attr, action])
  
  useEffect(() => {
    fetchAttribute();
    fetchUOM();
    

    // fetching product attributes of new created product
    if (props.productDataLocal?.uuid !== undefined) {
      fetchProductSpecifications(props.productDataLocal.uuid);
    } else {
      toast.error("Created product uuid not found !", {
        autoClose: 2000,
      });
    }
  }, []);

  function fetchProductSpecifications(pro_uuid) {
    setProd_Attr({ ...prod_attr, data: [], loading: true });
    const payload = {
      pageNo: 1,
      entriesPerPage: 1000,
      product_id: pro_uuid
    }
    getProductAttribute(payload).then(
      (res) => {
        
        setProd_Attr({ ...prod_attr, data: res.data.data, loading: false });

        // fetching product attributes of product from which we are cloning
        if (res.data.data.length == 0) {
          payload.product_id = props.data.uuid;
          getProductAttribute(payload).then(
            (res) => {
              
              setProd_Attr({ ...prod_attr, data: res.data.data, loading: false });
              setAction("create");
            },
            (err) => {
              
              setProd_Attr({ ...prod_attr, data: [], loading: false });
            }
          );

        } else {

          setAction("update");
        }
      },
      (err) => {
        
        setProd_Attr({ ...prod_attr, data: [], loading: false });
      }
    );
  }

  function fetchAttribute() {
    setAttribList({ ...attributeList, data: [], loading: true });
    getAttribute().then(
      (res) => {
        // 
        setAttribList({
          ...attributeList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setAttribList({ ...attributeList, data: [], loading: false });
      }
    );
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM({pageNo:1,entriesPerPage:100}).then(
      (res) => {
        // 
        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  // const onSubmit = (data) => {
  //   props.setCurrentStep(props.currentStep+1)
  // }

  const onSubmit = (data) => {
    setBtnDisable(true);
    
    let reqPayload = [];
    if (data !== "") {
      for (let i = 0; i < data.items.length; i++) {
        const payload = {
          productId: prod_data && prod_data.productId,
          product_id: prod_data && prod_data.uuid,
          name: data.items[i].name,
          attId: data.items[i].attribute[0]?.attId,
          attname: data.items[i].attribute[0]?.name,
          attribute_id: data.items[i].attribute[0]?.attribute_id,
          value: data.items[i].value,
          minValue: data.items[i].minValue,
          maxValue: data.items[i].maxValue,
          unit: data.items[i].unit[0]?.uomid,
          unitname: data.items[i].unit[0]?.uom,
          unit_id: data.items[i].unit[0]?.uuid,
          remark: data.items[i].remark,

        };
        if (action == "update") {
          payload.uuid = data.items[i].uuid;
        }

        reqPayload.push(payload);
        // if (!data.items[i].uuid) {
        //   reqPayload.push(payload);
        // } else {
        //   delete reqPayload.payload;
        // }
      }

      

      if (action == "create") {
        createProductAttribute({ data: reqPayload })
          .then(
            (res) => {
              
              setBtnDisable(false);
              if (res.status === 200 || res.status === 201) {
                toast.success("Product attribute created successfully !", {
                  autoClose: 3000,
                });
                reset();
                props.setCurrentStep((prevStep) => prevStep + 1);
              }
            },
            (err) => {
              setBtnDisable(false);
              
              if (err.response.status === 400) {
                
                toast.error(err.response.data.message, {
                  autoClose: 2000,
                });
                reset();
              }
            }
          )
          .catch((error) => {
            
            toast.error("Something went wrong, please try again !", {
              autoClose: 2000,
            });
          });

      } else {
        updateProductAttribute({ data: reqPayload }).then(
          (res) => {
            
            if (res.status === 200 || res.status === 201) {
              toast.success("Product attribute updated successfully !", {
                autoClose: 3000,
              });
              reset();
              props.setCurrentStep((prevStep) => prevStep + 1);
            }
          },
          (err) => {
            
            if (err.response.status === 400) {
              
              toast.error(err.response, {
                autoClose: 2000,
              });
              reset();
            }
          }
        )
      }
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <div className="d-flex justify-content-start">
            <p className="pc_card_title roboto-bold">Product Attributes</p>
          </div>

          <div className="col-12">
            {fields.length > 0 ? (
              <div className="" >
                <table className="table my-2 table-responsive table-stirped table-bordered">
                  <thead>
                    <tr>
                      <th>Sr.No</th>
                      <th>Name</th>
                      <th>Attribute</th>
                      <th>Value</th>
                      <th>Min. Value</th>
                      <th>Max. Value</th>
                      <th>Unit</th>
                      <th>Remark</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id}>
                        <td width="5%" style={{ verticalAlign: "top" }}>
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            readOnly
                            placeholder={index + 1}
                          />
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.name
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            placeholder="Enter attribute name"
                            name={`items[${index}].name`}
                            {...register(`items[${index}].name`)}

                          />

                          {errors.items?.[index]?.name && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.name.message}
                            </span>
                          )}
                        </td>
                        <td style={{ verticalAlign: "top" }} width={"20%"}>
                          <Controller
                            name={`items[${index}].attribute`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                size="sm"
                                id="basic-typeahead"
                                labelKey="name"
                                multiple={false}
                                options={attributeList.data}
                                isLoading={attributeList.loading}
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.attribute ? true : false
                                }
                                placeholder="Select attribute"
                              />
                            )}
                          />
                          {errors.items?.[index]?.attribute && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.attribute.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.value
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].value`}
                            placeholder="Enter value"
                            {...register(`items[${index}].value`)}
                          />

                          {errors.items?.[index]?.value && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.value.message}
                            </span>
                          )}
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.minValue
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].minValue`}
                            placeholder="Enter min value"
                            {...register(`items[${index}].minValue`)}
                          />

                          {errors.items?.[index]?.minValue && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.minValue.message}
                            </span>
                          )}
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.maxValue
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].maxValue`}
                            placeholder="Enter max value"
                            {...register(`items[${index}].maxValue`)}
                          />

                          {errors.items?.[index]?.maxValue && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.maxValue.message}
                            </span>
                          )}
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          <Controller
                            name={`items[${index}].unit`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                size="sm"
                                id="basic-typeahead"
                                labelKey="uom"
                                multiple={false}
                                options={uomList.data}
                                isLoading={uomList.loading}
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.unit ? true : false
                                }
                                placeholder="Select unit"
                              />
                            )}
                          />
                          {errors.items?.[index]?.unit && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.unit.message}
                            </span>
                          )}
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.remark
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].remark`}
                            placeholder="Enter remark"
                            {...register(`items[${index}].remark`)}
                          />

                          {errors.items?.[index]?.remark && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.remark.message}
                            </span>
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <div
                            className="deleteIcon cursor_pointer"
                            onClick={() => {

                              SweetAlert.fire({
                                title: "Are you sure to delete",
                                icon: "warning",
                                showCancelButton: true,
                                confirmButtonText: "Ok",
                                cancelButtonText: "cancel",
                                reverseButtons: true,
                              }).then((result) => {
                                if (result.value) {
                                  remove(index);

                                  let arr=prod_attr.data.filter((item,ind)=>ind!==index);
                                  
                                     setProd_Attr({...prod_attr,data:arr,});
                                     
                                  //removed form local only
                                  // if (fields[index].uuid !== null && fields[index].uuid !== undefined) {

                                  //   deleteProductAttribute({ uuid: fields[index].uuid }).then((res) => {
                                  //     toast.success("Attribute Deleted Successfully !", {
                                  //       autoClose: 1000
                                  //     });
                                  //     fetchProductSpecifications();
                                  //   }, (err) => {
                                  //     toast.error("Something went wrong ")
                                  //   })
                                  // } else {

                                    
                                  // }
                                }
                              });



                            }}
                          >
                            <Trash2 color="#FFFFFF" size={18} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <div
                className="alert alert-danger mb-0 text-center my-4"
                role="alert"
              >
                {"No specifications found !"}
              </div>
            )}
            <div className="d-flex justify-content-end">
              <button
                style={{ fontSize: "16px" }}
                className="btn btn-sm btn-yellow px-2 mt-2"
                onClick={() => append({})}
              >
                <FontAwesomeIcon
                  style={{
                    color: "#000000",
                    marginRight: "5px",
                  }}
                  icon={faAdd}
                  size="sm"
                />{" "}
                Add More
              </button>
            </div>
          </div>

          <hr className="my-4" />
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-grey px-3 me-3"
              onClick={() => props.setCurrentStep(props.currentStep - 1)}
            >
              <FontAwesomeIcon
                style={{
                  color: "#fff",
                }}
                icon={faArrowLeft}
                size="sm"
                className="me-2"
             />
              Back
            </button>
            <button
              className="btn button-primary px-3 me-3"
              onClick={handleSubmit(onSubmit)}
              disabled={btnDisable}
            >
              Update
            </button>
            <button
              className="btn btn-grey px-4"
              onClick={() => props.setCurrentStep(props.currentStep + 1)}
            >
              Skip
              <FontAwesomeIcon
                style={{
                  color: "#fff",
                }}
                icon={faArrowRight}
                size="sm"
                className="ms-2"
              />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditProductSpecifications
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";

const schema = yup
  .object()
  .shape({
    password: yup
      .string()
      .required("Please enter password")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@$&])(?=.{8,})/,
        "1 uppercase, 1 number, 1 special character and atleast 8 character"
      ),
    confirmPassword: yup
      .string()
      .required("Please enter the same password")
      .oneOf([yup.ref("password"), null], "Password and confirm password does not match !"),
  })
  .required();

const Account = () => {
    const [showPassword, setShowPassword] = useState(false);
    const [showPassword2, setShowPassword2] = useState(false);
    const [loginClick, setLoginClick] = useState(false);

    const {
        register,
        handleSubmit,
        formState,
        getValues,
        setError,
        clearErrors,
      } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
      }); // initialise the hook

      const { errors } = formState;

      const onSubmit = (data) => {
        
      };

    return (
        <div className="col-12 col-md-6">
            <form>
                <div className="col-12 col-md-6 mt-2">
                    <div className="form-group mt-md-0 mt-3">
                    <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label roboto-bold"
                    >
                        {"Username"}
                    </label>

                    <input
                        type="text"
                        className={
                        errors.username
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        id="username"
                        name="username"
                        placeholder="Enter username"
                        maxLength={10}
                        {...register("username")}
                        disabled
                    />
                    <span className="text-danger err-msg">
                        {errors.username?.message}
                    </span>
                    </div>
                </div>
                <div className="col-12 col-md-6 mt-2">
                  <div className="form-group mt-md-0 mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-md-12 roboto-bold"
                    >
                      {"Password"}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className={errors.password ? "form-control is-invalid"
                        : " form-control"}
                      id="password"
                      name="password"
                      placeholder="Enter password"
                      {...register("password")}
                    />
                    <span className="input-group-text">
                          {showPassword ?
                            <FontAwesomeIcon
                              style={{ color: "#212529", cursor: 'pointer' }}
                              icon={faEyeSlash}
                              size="sm"
                              onClick={() => setShowPassword(false)}
                            />
                            :
                            <FontAwesomeIcon
                              style={{ color: "#212529", cursor: 'pointer' }}
                              icon={faEye}
                              size="sm"
                              onClick={() => setShowPassword(true)}
                            />
                          }
                        </span>
                        </div>
                    <span className="text-danger err-msg">
                      {errors.password?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-2">
                  <div className="form-group mt-md-0 mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-md-12 roboto-bold"
                    >
                      {"Confirm Password"}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="input-group">
                    <input
                      type={showPassword2 ? "text" : "password"}
                      className={errors.confirmPassword ? "form-control is-invalid"
                        : " form-control"}
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Enter confirm password"
                      {...register("confirmPassword")}
                    />
                    <span className="input-group-text">
                          {showPassword2 ?
                            <FontAwesomeIcon
                              style={{ color: "#212529", cursor: 'pointer' }}
                              icon={faEyeSlash}
                              size="sm"
                              onClick={() => setShowPassword2(false)}
                            />
                            :
                            <FontAwesomeIcon
                              style={{ color: "#212529", cursor: 'pointer' }}
                              icon={faEye}
                              size="sm"
                              onClick={() => setShowPassword2(true)}
                            />
                          }
                        </span>
                        </div>
                    <span className="text-danger err-msg">
                      {errors.confirmPassword?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-3">
                    <button
                        className="btn button-primary w-100 mt-4 roboto-bold"
                        type="submit"
                        onClick={handleSubmit(onSubmit)}
                        style={{ letterSpacing: "1px" }}
                        disabled={loginClick}
                    >
                        Save{" "}
                        {loginClick ? (
                        <Spinner animation="border" size="sm" />
                        ) : null}{" "}
                    </button>
                </div>
            </form>
        </div>
    );
};

export default Account;

import React, { useState, useEffect, useReducer } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faPlus,
  faFilter,
  faBan,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal } from "react-bootstrap";
import { Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import axios from "axios";
import {
  getOperationCat,
  createOperationCat,
  deleteOperationCat,
} from "../../../../api/index";
import {
  calMaxPage_new,
  getLabels,
  setAccessPrivilage,
} from "../../../../common/Functions/CommonFunctions";
import { operation_cat_csv } from "../../../../constant";
import EditOperationCategory from "./EditOperationCategory";
import { Tooltip } from "react-tooltip";
import { Operation_Category_Col_Name } from "../../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import List from "../../../../assets/images/icons/project_menu/checklist.png";

const schema = yup
  .object()
  .shape({
    oprationCategoryName: yup
      .string()
      .required("Operation category name is required !"),
  })
  .required();

function OperationCategory() {
  const [showLabel, setShowLabel] = useState(Operation_Category_Col_Name);
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [sea_val, setSea_val] = useState(false);

  const [filterConfig, setFilterConfig] = useState({ searchVal: "" });

  const [apiBySearch, setApiBySearch] = useState(false);
  const [totalRecords, setTotalRecords] = useState("");

  const [constLabel, setConstLabel] = useState({});

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );

  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Operation Category"));
  }, [])

  useEffect(() => {
    setConstLabel(getLabels("Operations Master", "Operation Category"));
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchOperationCat(page, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function clearFilter() {
    setFilterConfig({ ...filterConfig, searchVal: "" });
    setSrNo(0);
    setPage(1);
  }

  function fetchOperationCat(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    if (filterConfig.searchVal !== "") {
      // payload.productName_list = filterConfig.productName;
      payload.searchVal = filterConfig.searchVal;

      if (
        filterConfig.searchVal?.length > 0 &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;

        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.searchVal;
    }

    getOperationCat(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });

        if (
          filterConfig.searchVal !== "" &&
          filterConfig.searchVal?.length > 0
        ) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }

        //  console.log('calMaxPage_new(res.data.total)', calMaxPage_new(res.data.total,epp));
        setMaxPage(calMaxPage_new(res.data.total, epp));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        oprationCategoryName: data.oprationCategoryName,
        // internalId: 1,
        // remark: "",
      };

      createOperationCat(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Operation category created successfully !", {
              autoClose: 2000,
            });
            setVisible(false);
            fetchOperationCat(page, entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  // Excel import functionality
  // function ExportToExcel(type, fn, dl) {
  //   var elt = document.getElementById("tbl_Operation");
  //   var wb = XLSX.utils.table_to_book(elt, { sheet: "sheet1" });
  //   return dl ? XLSX.write(wb, { bookType: type, bookSST: true, type: "base64" })
  //     : XLSX.writeFile(wb, fn || "Operations." + (type || "xlsx"));
  // }

  // Deleting operation category
  const delOperationCategory = (val) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
        };
        deleteOperationCat(payload).then(
          (res) => {
            toast.success("Operation category deleted successfully !", {
              autoClose: 3000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchOperationCat(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchOperationCat(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };

  const generateAndDownloadCSV = (jsonData) => {
    if (!Array.isArray(jsonData) || jsonData.length === 0) return;

    const csvRows = jsonData.map((data) =>
      Object.values(data)
        .map((value) => `"${value}"`)
        .join(",")
    );

    const csvContent = csvRows.join("\n");

    const blob = new Blob([csvContent], { type: "text/csv" });

    const url = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Operation_category.csv";

    document.body.appendChild(a);
    a.click();

    document.body.removeChild(a);
  };

  const fetchDataAndDownloadCSV = async () => {
    const token = localStorage.getItem("access");
    try {
      const response = await axios.get(
        process.env.REACT_APP_API_URL + operation_cat_csv,
        {
          responseType: "json", // Set the responseType to ‘arraybuffer’
          headers: {
            Authorization: `Bearer ${token}`, // Add the token to the Authorization header
          },
        }
      );

      generateAndDownloadCSV(response.data.CategoryDetails);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <div className="min-vh-100" id="root_div_main">
        <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>
              {constLabel?.lbl_add_operation_category
                ? constLabel?.lbl_add_operation_category
                : "Add Operation Category"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="">
              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  {constLabel?.lbl_category_name
                    ? constLabel.lbl_category_name
                    : "Category Name"}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    className={
                      errors.oprationCategoryName
                        ? "form-control  is-invalid"
                        : "form-control "
                    }
                    id="oprationCategoryName"
                    name="oprationCategoryName"
                    placeholder="Enter operation category name"
                    {...register("oprationCategoryName")}
                  />
                  <span className="text-danger err-msg">
                    {errors.oprationCategoryName?.message}
                  </span>
                </div>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {constLabel?.lbl_save ? constLabel?.lbl_save : "Save"}
              {/* <FontAwesomeIcon
              style={{ color: "#000" }}
              icon={faPlay}
              size="sm"
            /> */}
            </button>
          </Modal.Footer>
        </Modal>
        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div className="filter row">
              <label
                className="filterLabel mb-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_search ? constLabel.lbl_search : "Search"}
              </label>
              <div className="col-12">
                <input
                  type="text"
                  id="searchField"
                  className="form-control form-control-sm"
                  placeholder="Search by name"
                  value={filterConfig.searchVal}
                  onChange={(val) => {
                    const inputValue = val.target.value;
                    if (!inputValue.trim()) {
                      // Input contains only whitespace, don't trigger the search.
                      if (filterConfig.searchVal.length == 1) {
                        setFilterConfig({ ...filterConfig, searchVal: "" });
                      }

                      if (inputValue.length == 0) {
                        setFilterConfig({ ...filterConfig, searchVal: "" });
                      }
                    } else {
                      setFilterConfig({
                        ...filterConfig,
                        searchVal: inputValue,
                      });
                    }
                  }}
                />
              </div>
            </div>
            <div className="my-3 d-flex justify-content-end">
              <button
                className="btn btn-sm btn-yellow"
                onClick={() => clearFilter()}
              >
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter
                  ? constLabel.lbl_clear_filter
                  : "Clear Filter"}
              </button>
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="card border-0">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="d-md-flex justify-content-start align-items-center col-12 col-md-6">
                  {/* <FontAwesomeIcon
                      onClick={() => toggleNav()}
                      className="cursor_pointer mb-2"
                      style={{
                        color: "Black",
                        marginRight: "10px",
                      }}
                      icon={Slide}
                      size="lg"
                    /> */}
                  <h1 className="bold">
                    {constLabel?.lbl_operation_category
                      ? constLabel.lbl_operation_category
                      : "Operation Category"}
                  </h1>
                </div>
                <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                  <div className="col-12 col-md-6 me-md-2 me-md-0">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search by name"
                      value={filterConfig.searchVal}
                      onChange={(val) => {
                        const inputValue = val.target.value;
                        if (!inputValue.trim()) {
                          // Input contains only whitespace, don't trigger the search.
                          if (filterConfig.searchVal.length == 1) {
                            setFilterConfig({ ...filterConfig, searchVal: "" });
                          }

                          if (inputValue.length == 0) {
                            setFilterConfig({ ...filterConfig, searchVal: "" });
                          }
                        } else {
                          setFilterConfig({
                            ...filterConfig,
                            searchVal: inputValue,
                          });
                        }
                      }}
                    />
                  </div>
                  {privilage.write && (
                    <button
                      className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                      onClick={() => {
                        setVisible(true);
                        reset();
                      }}
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faPlus}
                        size="sm"
                      />{" "}
                      {constLabel?.lbl_add_category
                        ? constLabel.lbl_add_category
                        : "Add Category"}
                    </button>
                  )}

                  <Tooltip id={"show-tooltip"} place="top" />
                  <div
                    className="dropdown"
                    data-tooltip-id={"show-tooltip"}
                    data-tooltip-content={"Show / Hide Columns"}
                  >
                    <div
                      className="d-md-flex justify-content-start align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                      {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                      <img src={List} height='32' width='32' className="cursor_pointer" />
                    </div>

                    <ul className="dropdown-menu px-2">
                      {showLabel !== undefined && showLabel.length > 0
                        ? showLabel.map((val, ind, arr) => (
                          <li key={ind}>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                style={{ width: "20px", height: "20px" }}
                                onChange={() => handleClick(ind)}
                                value=""
                                checked={val.flag}
                              />
                              <label
                                className=""
                                style={{ fontSize: "13px" }}
                                htmlFor="flexCheckDefault"
                              >
                                {val.label}
                              </label>
                            </div>
                          </li>
                        ))
                        : null}
                    </ul>
                  </div>
                </div>
              </div>
              <div className="mt-3">
                {dataList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : dataList && dataList.data?.length > 0 ? (
                  <>
                    <div className="">
                      <table
                        className="table table-responsive table-bordered"
                      >
                        <thead className="table-grey roboto">
                          <tr className="f-14 ">
                            <th scope="col" width="10%">
                              {"Sr.No"}
                            </th>
                            {showLabel.map((v, i) =>
                              v.label === "Category Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_category_name
                                    ? constLabel.lbl_category_name
                                    : "Category Name"}
                                </th>
                              ) : null
                            )}
                            <th scope="col" width="10%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className=" roboto">
                          {dataList.data?.map((val, i) => (
                            <tr key={i}>
                              <td>{i + parseInt(srNo) + 1}</td>
                              {showLabel.map((item, idx) =>
                                item.label === "Category Name" && item.flag ? (
                                  <td key={idx}>
                                    {val.oprationCategoryName != "" &&
                                      val.oprationCategoryName !== null
                                      ? val.oprationCategoryName
                                      : "NA"}
                                  </td>
                                ) : null
                              )}
                              <td className="">
                                {privilage.write && (
                                  <>
                                    <EditOperationCategory
                                      data={val}
                                      constLabel={constLabel}
                                      fetchOperationCat={fetchOperationCat}
                                      page={page}
                                      entriesPerPage={entriesPerPage}
                                    />
                                  </>
                                )}

                                {privilage.delete && (
                                  <>
                                    <Tooltip id={"delete-tooltip" + i} place="top" />
                                    <Trash2
                                      data-tooltip-id={"delete-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_delete_category
                                          ? constLabel.lbl_delete_category
                                          : "Edit Category"
                                      }
                                      className="menu_icon_red cursor_pointer me-2"
                                      size={20}
                                      onClick={() => delOperationCategory(val)}
                                    />
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                    <div className="mt-4 d-flex justify-content-between">
                      <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                        } to ${Math.min(
                          parseInt(entriesPerPage) + parseInt(srNo),
                          dataList.totalRecords
                        )} of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchOperationCat(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1
                                ? 0
                                : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage || maxPage === 0 ? true : false
                          }
                          onClick={() => {
                            fetchOperationCat(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo(
                              (prevC) => prevC + parseInt(entriesPerPage)
                            );
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  </>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Operation category not found !"}
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default OperationCategory;

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { get_party_contact } from "../../../../../api";
import { Tooltip } from "react-tooltip";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";

function ViewContactDetail(props) {
  const [visible, setVisible] = useState(false);
  const [contactList, setContactList] = useState({ data: [], loading: false });
  const [constLabel, setConstLabel] = useState({});

  useEffect(() => {
    setConstLabel(getLabels('Party Master', 'Supplier'));
    if (props.data !== undefined) {
      fetchContacts();
    }
  }, []);

  function fetchContacts() {
    setContactList({ ...contactList, data: [], loading: true });
    const payload = {
      partyId: props.data?.uuid,
    };
    get_party_contact(payload).then(
      (res) => {
        
        setContactList({
          ...contactList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setContactList({ ...contactList, data: [], loading: false });
        // toast.error("Something went wrong in fetching states !", { autoClose: 5000 })
      }
    );
  }

  return (
    <React.Fragment>
      <Tooltip id={"view-tooltip"} place="top" />
      <button
        data-tooltip-id={"view-tooltip"}
        data-tooltip-content="Contact Details"
        className="btn border roboto-bold"
        type="button"
        // style={{ padding: "0rem 0.5rem" }}
        onClick={() => {
          setVisible(true);
        }}
      >
        <FontAwesomeIcon style={{ color: "#000" }} icon={faPhone} size="lg" />{" "}
        {/* {" Bank"} */}
      </button>

      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        size={"lg"}
        className="modal-dialog-centered"
      >
        <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>{constLabel?.lbl_contact_detail ? constLabel.lbl_contact_detail : "Contact Detail"}</Modal.Title>
          </Modal.Header>
        <Modal.Body>
          <form action="">
            {contactList.data && contactList.data.length > 0 ? (
              <div className="table-responsive">
                <table
                  className="table table-bordered mt-2 table-bordered"
                  width={"100%"}
                >
                  <thead className="thead-light text-ceter">
                    <tr className="">
                      <th scope="col">{"Sr.No"}</th>
                      <th scope="col">{constLabel?.lbl_first_name ? constLabel.lbl_first_name : "First Name"}</th>
                      <th scope="col">{constLabel?.lbl_last_name ? constLabel.lbl_last_name : "Last Name"}</th>
                      <th scope="col">{constLabel?.lbl_mobile_number ? constLabel.lbl_mobile_number : "Mobile Number"}</th>
                      <th scope="col">{constLabel?.lbl_alt_mobile_no ? constLabel.lbl_alt_mobile_no : "Alt. Mobile No"}</th>
                      <th scope="col">{constLabel?.lbl_landline_ext_no ? constLabel.lbl_landline_ext_no : "Landline Ext No"}</th>
                      <th scope="col">{constLabel?.lbl_landline_number ? constLabel.lbl_landline_number : "Landline Number"}</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {contactList.data.map((val, i) => (
                      <tr key={i} className="f-14">
                        <td>{i + 1}</td>
                        <td>{val.firstName != "" &&  val.firstName != null ? val.firstName : "NA"}</td>
                        <td>{val.lastName != "" &&  val.lastName != null ? val.lastName : "NA"}</td>
                        <td>{val.mobileNo != "" &&  val.mobileNo != null ? val.mobileNo : "NA"}</td>
                        <td>{val.alternateMobileNo != "" &&  val.alternateMobileNo != null ? val.alternateMobileNo : "NA"}</td>
                        <td>{val.landlineExtentionNo != "" &&  val.landlineExtentionNo != null ? val.landlineExtentionNo : "NA"}</td>
                        <td>{val.landlineNo != "" &&  val.landlineNo != null ? val.landlineNo : "NA"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-danger mb-0 f-22 text-center mt-4">
                {"Supplier contact list is empty"}
              </p>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default ViewContactDetail;

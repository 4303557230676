import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2, Trash2 } from "react-feather";
import { Modal } from "react-bootstrap";
import {
  getOperationCat,
  updateOperation,
  getMachineList,
  createOperationCat,
} from "../../../../api/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";

const schema = yup
  .object()
  .shape({
    category: yup
      .array()
      .min(1, "Please select category !")
      .required("Please select category !"),
    operationName: yup.string().required("Operation name is required !"),
    man_hour_cost: yup.string().matches(/^\d+(\.\d+)?$/, {
      message: "Enter valid man hour cost !",
      excludeEmptyString: true,
    }),
      items: yup.array().of(
      yup.object().shape({
        machine: yup
          .array()
          .min(1, "Please select machine !")
          .required("Please select machine !"),
        cycleTime: yup
          .string()
          .required("Cycle time is required !")
          .matches(/^\d+(\.\d+)?$/, "Enter valid cycle time !"),
        settingTime: yup
          .string()
          .matches(/^$|^\d+(\.\d+)?$/, "Enter valid setting time !")
          .notRequired(),
        toolChangeTime: yup
          .string()
          .matches(/^$|^\d+(\.\d+)?$/, "Enter valid tool change time !")
          .notRequired(),
        machineRate: yup
          .string()
          .matches(/^$|^\d+(\.\d+)?$/, "Enter valid machine rate !")
          .notRequired(),
      })
    ),
  })
  .required();

function EditOperation(props) {
  const [visible, setVisible] = useState(false);
  const [op_cat, setOp_Cat] = useState({ data: [], state: false });

  const [machineList, setMachineList] = useState({ data: [], loading: false });

  const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  const selCategory = watch("category");

  useEffect(() => {
    // resetting the form
    console.log('props', props);
    if (visible) {
      fetchMachineList();
      fetchOpCategory();
    }
  }, [visible]);

  useEffect(() => {
    var macArr = [];
    if (props.data?.machine_data?.length > 0) {
      for (let i = 0; i < props.data?.machine_data.length; i++) {
        macArr.push({
          cycleTime: props.data?.machine_data[i]?.cycleTime,
          settingTime: props.data?.machine_data[i]?.settingTime,
          toolChangeTime: props.data?.machine_data[i]?.toolChangeTime,
          machineRate: props.data?.machine_data[i]?.machineRate,
          machine: props.data?.machine_data[i]?.machine,
        });
      }
      reset({
        items: macArr,
      });
    } else {
      reset({
        items: [{}],
      });
    }
  }, [props.data.machine_data]);

  function fetchMachineList() {
    setMachineList({ ...machineList, data: [], loading: true });
    getMachineList().then(
      (res) => {
        if (res.data.data.length > 0) {
          const result = res.data.data.map((item) => ({
            ...item,
            machineNameSerialNo: item.machineName + " - " + item.serialNo,
          }));
          setMachineList({ ...machineList, data: result, loading: false });
        } else {
          setMachineList({ ...machineList, data: [], loading: false });
        }
      },
      (err) => {
        setMachineList({ ...machineList, data: [], loading: false });
      }
    );
  }

  function fetchOpCategory(){
    setOp_Cat({ ...op_cat, state: true, data: [] });
      getOperationCat().then(
        (res) => {
          setOp_Cat({
            ...op_cat,
            state: false,
            data: res.data.data,
          });
        },
        (err) => {
          //
          setOp_Cat({ ...op_cat, state: false, data: [] });
          toast.error(err.response.data.message, {
            autoClose: 3000,
          });
        }
      );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      let machineArr = [];
      if (data.items.length > 0) {
        for (let i = 0; i < data.items.length; i++) {
          const payload = {
            cycleTime: data.items[i]?.cycleTime,
            settingTime: data.items[i]?.settingTime,
            toolChangeTime: data.items[i]?.toolChangeTime,
            machineRate: data.items[i]?.machineRate,
            machine: data.items[i]?.machine,
          };
          machineArr.push(payload);
        }
      }

      const reqPayload = {
        id: props.data.OpId,
        uuid: props.data.uuid,
        // operationID: props.data.operationID,
        operationName: data.operationName,
        // operationCategoryId: selCategory[0]?.operationCategoryId,
        oprationCategoryName: selCategory[0]?.oprationCategoryName,
        operation_category_id: selCategory?.[0]?.uuid,
        man_hour_cost: data.man_hour_cost == "" ? null : data.man_hour_cost,
        machine_data: machineArr,
      };

      updateOperation(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Operation updated successfully !", {
              autoClose: 2000,
            });
            props.fetchOperation(props.page, props.entriesPerPage);
            setVisible(false);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  function addOpCategory(data){

    if (data !== "") {
      const reqPayload = {
        oprationCategoryName: data,
      };
      createOperationCat(reqPayload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Operation category created successfully !", {
              autoClose: 2000,
            });
            fetchOpCategory();
            setValue("category", [res.data], { shouldValidate: true });
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  }

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
        <Edit
            data-tooltip-id={"edit-tooltip"}
            data-tooltip-content={
              props.constLabel?.lbl_edit_operation
              ? props.constLabel?.lbl_edit_operation
              : "Edit Operation"
            }
          className="menu_icon_grey cursor_pointer me-2"
          size={20} 
          onClick={() => setVisible(true)}
        />
      <Modal show={visible} onHide={() => {setVisible(false); reset();}} size={"lg"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_operation
              ? props.constLabel.lbl_update_operation
              : "Update Operation"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="row">
              <div className="col-12 col-md-4">
                <label
                  className="lbl-style roboto-bold mb-2"
                  style={{ fontSize: "14px" }}
                >
                  {props.constLabel?.lbl_category_name
                    ? props.constLabel.lbl_category_name
                    : "Category Name"}
                  <span className="text-danger"> *</span>
                </label>

                <Controller
                  name="category"
                  control={control}
                  defaultValue={[
                    {
                      oprationCategoryName: props.data.oprationCategoryName,
                      uuid: props.data.operation_category_id,
                    },
                  ]}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="oprationCategoryName"
                      defaultSelected={[
                        {
                          oprationCategoryName: props.data.oprationCategoryName,
                          uuid: props.data.operation_category_id,
                        },
                      ]}
                      multiple={false}
                      options={op_cat.data !== undefined ? op_cat.data : []}
                      isLoading={op_cat.state}
                      placeholder="Select category name"
                      // onChange={onChange}
                      onChange={(selected) => {
                        // If the new option is added
                        const isNewOption = selected && selected[0]?.customOption;
                        if (isNewOption) {
                          const newItem = selected[0].oprationCategoryName;
                          // Fire API to add the new category
                          addOpCategory(newItem);
                        }
                        onChange(selected);
                      }}
                      newSelectionPrefix="Add New: "
                      allowNew                // Enables adding new options
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.category ? true : false}
                    />
                  )}
                />
                {errors.category && (
                  <span className="text-danger err-msg">
                    {errors.category.message}
                  </span>
                )}
              </div>

              <div className="col-12 col-md-4">
                <label
                  className="lbl-style roboto-bold"
                  style={{ fontSize: "14px" }}
                >
                  {props.constLabel?.lbl_operation_name
                    ? props.constLabel.lbl_operation_name
                    : "Operation Name"}
                  <span className="text-danger"> *</span>
                </label>
                <input
                  type="text"
                  className={
                    errors.operationName
                      ? "form-control  is-invalid mt-2"
                      : "form-control mt-2 "
                  }
                  id="operationName"
                  name="operationName"
                  defaultValue={
                    props.data !== undefined || props.data.operationName !== ""
                      ? props.data.operationName
                      : ""
                  }
                  placeholder="Enter operation name"
                  {...register("operationName")}
                />
                {errors.operationName && (
                  <span className="text-danger err-msg">
                    {errors.operationName.message}
                  </span>
                )}
              </div>

              <div className="col-12 col-md-4">
                  <label
                    className="lbl-style roboto-bold"
                    style={{ fontSize: "14px" }}
                  >
                    {props.constLabel?.lbl_man_hour_cost
                      ? props.constLabel.lbl_man_hour_cost
                      : "Man Hour Cost"}{" "}<span>&#8377;</span>
                  </label>
                  <input
                    type="text"
                    className={
                      errors.man_hour_cost
                        ? "form-control is-invalid mt-2"
                        : "form-control mt-2 "
                    }
                    id="man_hour_cost"
                    name="man_hour_cost"
                    defaultValue={
                      props.data !== undefined && props.data.man_hour_cost !== null
                        ? props.data.man_hour_cost
                        : ""
                    }
                    placeholder="Enter man hour cost"
                    {...register("man_hour_cost")}
                  />
                  {errors.man_hour_cost && (
                    <span className="text-danger err-msg">
                      {errors.man_hour_cost.message}
                    </span>
                  )}
                </div>
            </div>
            <hr className="mb-1" />
            <div className="d-flex justify-content-between align-items-center">
              <p className="roboto-bold mb-0" style={{ fontSize: "16px" }}>
                {props.constLabel?.lbl_machine_detail
                  ? props.constLabel?.lbl_machine_detail
                  : "Machine Detail"}
              </p>
              <div>
                <a
                  style={{ fontSize: "16px" }}
                  className="btn btn-sm button-primary px-2 mt-2"
                  onClick={() => {
                    append({});
                  }}
                >
                  <FontAwesomeIcon
                    style={{
                      color: "#ffffff",
                      marginRight: "5px",
                    }}
                    icon={faAdd}
                    size="sm"
                  />{" "}
                  {props.constLabel?.lbl_add
                    ? props.constLabel?.lbl_add
                    : "Add"}
                </a>
              </div>
            </div>
            <hr className="mt-1" />
            <div className="col-12">
              {/* {fields.length > 0 ? ( */}
              <div className="">
                <table className="table my-2 table-stirped table-bordered">
                  <thead>
                    <tr>
                      <th>
                        {props.constLabel?.lbl_machine
                          ? props.constLabel?.lbl_machine
                          : "Machine"}
                          <span className="text-danger"> *</span>
                      </th>
                      <th>
                        {props.constLabel?.lbl_cycle_time
                          ? props.constLabel?.lbl_cycle_time
                          : "Cycle Time"}
                          <span className="text-danger"> *</span>
                      </th>
                      <th>
                        {props.constLabel?.lbl_setting_time
                          ? props.constLabel?.lbl_setting_time
                          : "Setting Time"}
                      </th>
                      <th>
                        {props.constLabel?.lbl_tool_change_time
                          ? props.constLabel?.lbl_tool_change_time
                          : "Tool Change Time"}
                      </th>
                      <th>
                        {props.constLabel?.lbl_machine_rate
                          ? props.constLabel?.lbl_machine_rate
                          : "Machine Rate"}
                      </th>
                      <th>{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id}>
                        <td style={{ verticalAlign: "top" }} width={"30%"}>
                          <Controller
                            name={`items[${index}].machine`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                size="sm"
                                id="basic-typeahead"
                                labelKey="machineNameSerialNo"
                                multiple={false}
                                options={machineList.data}
                                isLoading={machineList.loading}
                                placeholder="Select machine"
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.machine ? true : false
                                }
                              />
                            )}
                          />
                          {errors.items?.[index]?.machine && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.machine.message}
                            </span>
                          )}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.cycleTime
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].cycleTime`}
                            {...register(`items[${index}].cycleTime`)}
                            placeholder="Enter cycle time"
                          />

                          {errors.items?.[index]?.cycleTime && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.cycleTime.message}
                            </span>
                          )}
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.settingTime
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].settingTime`}
                            {...register(`items[${index}].settingTime`)}
                            placeholder="Enter setting time"
                          />

                          {errors.items?.[index]?.settingTime && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.settingTime.message}
                            </span>
                          )}
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.toolChangeTime
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].toolChangeTime`}
                            {...register(`items[${index}].toolChangeTime`)}
                            placeholder="Enter tool change time"
                          />

                          {errors.items?.[index]?.toolChangeTime && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.toolChangeTime.message}
                            </span>
                          )}
                        </td>
                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className={
                              errors.items?.[index]?.machineRate
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="text"
                            name={`items[${index}].machineRate`}
                            {...register(`items[${index}].machineRate`)}
                            placeholder="Enter machine rate"
                          />

                          {errors.items?.[index]?.machineRate && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.machineRate.message}
                            </span>
                          )}
                        </td>
                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top"
                          }}
                        >
                          <Tooltip id={"delete-tooltip" + index} place="top" />
                          <Trash2
                              data-tooltip-id={"delete-tooltip" + index}
                              data-tooltip-content={"Delete"}
                            className="menu_icon_red cursor_pointer"
                            size={20} 
                            onClick={
                              index > 0 ? () => { remove(index) } : null 
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {/* ) : (
                  <div
                    className="alert alert-danger mb-0 text-center"
                    role="alert"
                  >
                    {"No machine data found !"}
                  </div>
                )} */}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditOperation;

import React, { useState, useEffect } from 'react';
import administrator from '../../assets/images/administrator.png'

function ContactAdminErrorBoundary({ children }) {
    const [hasError, setHasError] = useState(false);


    useEffect(() => {        
        const errorHandler = (error) => {
            console.error('Error caught by error boundary:', error);
            setHasError(true);
        };

        window.addEventListener('error', errorHandler);

        return () => {
            window.removeEventListener('error', errorHandler);
        };
    }, []);


    if (hasError) {
        // You can render a fallback UI here
        return (
            <React.Fragment>
                <div className='row'>
                    <div className='col-12 text-center'>
                        <img src={administrator} height="120" width="120" />
                        <p
                            className='text-uppercase mt-3'
                            style={{ letterSpacing: '1px', fontSize: '32px', fontWeight: '600' }}>Contact Admin</p>
                    </div>
                </div>
            </React.Fragment>);
    }

    return children;

}

export default ContactAdminErrorBoundary
import React, { useState, useEffect } from 'react';
import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function TaxDetail(props) {
    const {CSTNO, PANNO, TAN, TIN, VATNO, gst, serviceTaxNo } = props.data;
  return (
    <React.Fragment>
        <div className='row'>
        <div className="col-12 col-md-12">
                  <div className="card border-0">
                    <div className="card-body p-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="roboto-bold">{"Tax Detail"}</h5>
                        <FontAwesomeIcon
                          className="pt-1"
                          icon={faCircleInfo}
                          size="sm"
                        />
                      </div>

                    <div className='bg-lightgrey'>
                        <div className="row p-2">
                            <div className="col-12 col-md-4">
                                <div>
                                    <p className="m-0 nc_detail_label">{`GST No`}</p>
                                    <p className="mb-2 roboto-bold">{gst !== "" && gst !== null ? gst : "NA"}</p>
                                </div>

                                <div>
                                    <p className="m-0 nc_detail_label">{`CST NO`}</p>
                                    <p className="mb-2 roboto-bold">{CSTNO !== "" && CSTNO !== null ? CSTNO : "NA"}</p>
                                </div>
                            
                                <div>
                                    <p className="m-0 nc_detail_label">{`Service Tax No`}</p>
                                    <p className="mb-2 roboto-bold">{serviceTaxNo !== "" && serviceTaxNo !== null ? serviceTaxNo : "NA"}</p>
                                </div>
            
                            </div>
                            <div className="col-12 col-md-4">
                                <div>
                                    <p className="m-0 nc_detail_label">{`PAN No`}</p>
                                    <p className="mb-2 roboto-bold">{PANNO !== "" && PANNO !== null ? PANNO : "NA"}</p>
                                </div>

                                <div>
                                    <p className="m-0 nc_detail_label">{`TAN No`}</p>
                                    <p className="mb-2 roboto-bold">{TAN !== "" && TAN !== null ? TAN : "NA"}</p>
                                </div>           

                            </div>
                            <div className="col-12 col-md-4">
                                <div>
                                    <p className="m-0 nc_detail_label">{`VAT No`}</p>
                                    <p className="mb-2 roboto-bold">{VATNO !== "" && VATNO !== null ? VATNO : "NA"}</p>
                                </div>

                                <div>
                                    <p className="m-0 nc_detail_label">{`TIN No`}</p>
                                    <p className="mb-2 roboto-bold">{TIN !== "" && TIN !== null ? TIN : "NA"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                    </div>
                  </div>
                </div>
        </div>

    </React.Fragment>
  )
}

export default TaxDetail
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle as faCheckCircleSolid } from "@fortawesome/free-solid-svg-icons";
import ProductDetailConfigure from "./ProductDetailConfigure";
import ImageConfigure from "./ImageConfigure";
import ProductSpecifications from "./ProductSpecifications";
import ProductSequence from "./ProductSequence";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getProductType } from "../../../../api";
import SemiFinishedGoods from "../ProductForms/SemiFinishedGoods";
import RawMaterial from "../ProductForms/RawMaterial";
import BOMCreation from "./BOMCreation";
import { getLabels } from "../../../../common/Functions/CommonFunctions";

function ProductConfigure(props) {
  const [prodType, setProdType] = useState({ data: [], loading: false });
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);

  const [productDataLocal, setProductDataLocal] = useState({});
  let temp = [1];

  const [constLabel, setConstLabel] = useState({});


  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const selProductType = watch("type");
  const bom_status = localStorage.getItem('bomStatus');
  const [bomStat, setBomStat] = useState(false);

  let location = useLocation();
  useEffect(() => {
    
    setConstLabel(getLabels('Master', 'Product'));
    fetchProductType();
  }, []);



  useEffect(() => {
    temp = [...completedSteps];
    temp.push(parseInt(currentStep));
    
    setCompletedSteps(temp);
  }, [currentStep]);

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        
        setProdType({ ...prodType, data: res.data.data, loading: false });
      },
      (err) => {
        
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="">
        <div className="row justify-content-start">
          <div className="col-md-6">
            <div className="steps position-relative">
              <div className="d-md-flex">
                <span className={currentStep == 1 ? "active" : ""}>
                  Step 1
                  <FontAwesomeIcon
                    className="icon ms-1"
                    style={{ color: "#000000" }}
                    icon={
                      completedSteps.includes(1)
                        ? faCheckCircleSolid
                        : faCheckCircle
                    }
                    size={"lg"}
                  />
                </span>
                <hr className="my-auto mx-2" style={{ width: "50px" }} />
                <span className={currentStep == 2 ? "active" : ""}>
                  Step 2
                  <FontAwesomeIcon
                    className="icon ms-1"
                    style={{ color: "#000000" }}
                    icon={
                      completedSteps.includes(2)
                        ? faCheckCircleSolid
                        : faCheckCircle
                    }
                    size={"lg"}
                  />
                </span>
                <hr className="my-auto mx-2" style={{ width: "50px" }} />
                <span className={currentStep == 3 ? "active" : ""}>
                  Step 3
                  <FontAwesomeIcon
                    className="icon ms-1"
                    style={{ color: "#000000" }}
                    icon={
                      completedSteps.includes(3)
                        ? faCheckCircleSolid
                        : faCheckCircle
                    }
                    size={"lg"}
                  />
                </span>
                <hr className="my-auto mx-2" style={{ width: "50px" }} />
                <span className={currentStep == 4 ? "active" : ""}>
                  Step 4
                  <FontAwesomeIcon
                    className="icon ms-1"
                    style={{ color: "#000000" }}
                    icon={
                      completedSteps.includes(4)
                        ? faCheckCircleSolid
                        : faCheckCircle
                    }
                    size={"lg"}
                  />
                </span>

                {Object.keys(productDataLocal).length > 0 ?

                  // productDataLocal.has_bom == true ?
                  bom_status == true || bomStat == true ?
                    <>
                      <hr className="my-auto mx-2" style={{ width: "50px" }} />
                      <span className={currentStep == 5 ? "active" : ""}>
                        Step 5
                        <FontAwesomeIcon
                          className="icon ms-1"
                          style={{ color: "#000000" }}
                          icon={
                            completedSteps.includes(5)
                              ? faCheckCircleSolid
                              : faCheckCircle
                          }
                          size={"lg"}
                        />
                      </span>
                    </> : null
                  : null}
              </div>
            </div>
          </div>
          <div className="step-content py-4">
            <div className="pc">
              <div className="d-flex justify-content-between mb-4 mt-3">
                <h1 className="bold">
                  {constLabel?.lbl_product_configurator
                  ? constLabel?.lbl_product_configurator
                  : "Product Configurator"}
                </h1>
              </div>
              <div className="card shadow-sm rounded-0 p-2">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="lbl-style roboto-bold">
                          {constLabel?.lbl_product_type
                          ? constLabel?.lbl_product_type
                          : "Product Type"}
                          <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="type"
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="productType"
                              className="mt-2"
                              multiple={false}
                              options={prodType.data}
                              isLoading={prodType.loading}
                              placeholder="Select product type"
                              onChange={onChange}
                              selected={value}
                              onBlur={onBlur}
                              isInvalid={errors.type ? true : false}
                            />
                          )}
                        />

                        {errors.type && (
                          <span className="text-danger err-msg">
                            {errors.type.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {currentStep === 1 && (
                <>
                  {selProductType && selProductType[0]?.productType === 'Manufactured Goods' && (
                    <ProductDetailConfigure
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      data={location.state}
                      product_type={selProductType[0]}
                      setProductDataLocal={setProductDataLocal}
                      setBomStat={setBomStat}
                    />
                  )}
                  {selProductType && selProductType[0]?.productType === 'Raw material' && (
                    <RawMaterial
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      data={location.state}
                      product_type={selProductType[0]}
                      setProductDataLocal={setProductDataLocal}
                      setBomStat={setBomStat}

                    />
                  )}
                  {selProductType && selProductType[0]?.productType === 'Semi Finish Goods' && (
                    <SemiFinishedGoods
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      data={location.state}
                      product_type={selProductType[0]}
                      setProductDataLocal={setProductDataLocal}
                      setBomStat={setBomStat}
                    />
                  )}
                </>
              )}
              {currentStep === 2 && (
                <ImageConfigure
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  data={location.state}
                />
              )}
              {currentStep === 3 && (
                <ProductSpecifications
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  data={location.state}
                />
              )}
              {currentStep === 4 && (
                <ProductSequence
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  data={location.state}
                  bomStat={bomStat}
                />
              )}
              {(currentStep === 5 && JSON.parse(localStorage.getItem("bomStatus")) == true) && (
                <div>

                  <BOMCreation
                    productDataLocal={productDataLocal}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    data={location.state}
                  />

                  {/* {currentStep}
                  {JSON.parse(localStorage.getItem("bomStatus"))} */}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ProductConfigure;

import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import axios from "axios";
import { reset_password } from "../../constant";

const schema = yup
  .object()
  .shape({
    password: yup
      .string()
      .required("Please enter password")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@$&])(?=.{8,})/,
        "1 uppercase, 1 number, 1 special character and atleast 8 character"
      ),
    confirmPassword: yup
      .string()
      .required("Please enter the same password")
      .oneOf(
        [yup.ref("password"), null],
        "Password and confirm password does not match !"
      ),
  })
  .required();

function SetNewCredential(props) {
  let navigate = useNavigate();
  const [lockbtn, setLockBtn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const { register, handleSubmit, formState, setValue, clearErrors, setError } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

  const { errors } = formState;

  const onSubmit = (data) => {
    console.log("props", props);
    setLockBtn(true);
    if (data !== "") {
      let base_url = process.env.REACT_APP_COGNITO_AUTH_URL;
      const url = `${base_url}${reset_password}`;

      const payload = {
        username: props?.t_username,
        password: data.password,
        // pan_no : "",
        // website : "",
      };

      axios
        .post(url, payload)
        .then((res) => {
          // console.log("Client register", res);
          toast.success("Password reset successfully !", {
            autoClose: 2000,
          });
          setTimeout(() => {
            navigate(`${process.env.PUBLIC_URL}/login`);
          }, 2200);

          setLockBtn(false);
        })
        .catch((err) => {
          console.log("err", err);
          setLockBtn(false);
        });
    }
  };

  return (
    <div className="mx-auto px-4">
      <div className="col-12 mt-2">
        <div className="form-group mt-md-0 mt-3">
          <label
            style={{ fontSize: "14px" }}
            className="col-form-label body3 col-md-12 roboto-bold"
          >
            {"Password"}
            <span className="text-danger"> *</span>
          </label>
          <div className="input-group">
            <input
              type={showPassword ? "text" : "password"}
              className={
                errors.password ? "form-control is-invalid" : " form-control"
              }
              id="password"
              name="password"
              placeholder="Enter password"
              {...register("password")}
            />
            <span className="input-group-text">
              {showPassword ? (
                <FontAwesomeIcon
                  style={{ color: "#212529", cursor: "pointer" }}
                  icon={faEyeSlash}
                  size="sm"
                  onClick={() => setShowPassword(false)}
                />
              ) : (
                <FontAwesomeIcon
                  style={{ color: "#212529", cursor: "pointer" }}
                  icon={faEye}
                  size="sm"
                  onClick={() => setShowPassword(true)}
                />
              )}
            </span>
          </div>
          <span className="text-danger err-msg">
            {errors.password?.message}
          </span>
        </div>
      </div>
      <div className="col-12 mt-2">
        <div className="form-group mt-md-0 mt-3">
          <label
            style={{ fontSize: "14px" }}
            className="col-form-label body3 col-md-12 roboto-bold"
          >
            {"Confirm Password"}
            <span className="text-danger"> *</span>
          </label>
          <div className="input-group">
            <input
              type={showPassword2 ? "text" : "password"}
              className={
                errors.confirmPassword
                  ? "form-control is-invalid"
                  : " form-control"
              }
              id="confirmPassword"
              name="confirmPassword"
              placeholder="Enter confirm password"
              {...register("confirmPassword")}
            />
            <span className="input-group-text">
              {showPassword2 ? (
                <FontAwesomeIcon
                  style={{ color: "#212529", cursor: "pointer" }}
                  icon={faEyeSlash}
                  size="sm"
                  onClick={() => setShowPassword2(false)}
                />
              ) : (
                <FontAwesomeIcon
                  style={{ color: "#212529", cursor: "pointer" }}
                  icon={faEye}
                  size="sm"
                  onClick={() => setShowPassword2(true)}
                />
              )}
            </span>
          </div>
          <span className="text-danger err-msg">
            {errors.confirmPassword?.message}
          </span>
        </div>
      </div>

      <div className="w-100 d-flex justify-content-end">
        <button
          className="btn button-primary w-100 mt-4 roboto-bold"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={lockbtn}
        >
          Proceed {lockbtn ? <Spinner animation="border" size="sm" /> : null}{" "}
        </button>
      </div>
    </div>
  );
}

export default SetNewCredential;

import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {  updateTTransactionMaster,  } from "../../../api";
import { Typeahead } from "react-bootstrap-typeahead";

const schema = yup
  .object()
  .shape({
    transaction_name: yup
      .string()
      .required("Transaction name is required !"),
    transaction_code: yup
      .string()
      .required("Transaction code is required !")
      .matches(/^[a-zA-Z0-9]+$/, 'Only alphanumeric characters are allowed'),
    action: yup
      .array()
      .min(1, "Please select any one action !")
      .required("Please select any one action !"),
  })
  .required();

function EditMovementMaster(props) {
    const actiondata = [
        {
          id: 1,
          name: "I"
        },
        {
          id: 2,
          name: "R"
        },
        // {
        //   id: 3,
        //   name: "I,R"
        // }
      ]
    const [visible, setVisible] = useState(false);

    const { register, handleSubmit, formState, control, reset, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
      }); // initialise the hook
    
      let { errors } = formState;
    
      const onSubmit = (data) => {
        
    
        if (data !== "") {
            const reqPayload = {
                uuid:props.data.uuid,
                transaction_name: data.transaction_name,
                transaction_code: data.transaction_code,
                action: data.action[0].name,
                description: data.description
              };
    
          
   
          updateTTransactionMaster(reqPayload).then(
              (res) => {
                
                if (res.status === 200 || res.status === 201) {
                  toast.success("Transaction master updated Successfully !", {
                    autoClose: 3000,
                  });
                  setVisible(false);
                  props.fetchTransactions();
                }
              },
              (err) => {
                
                toast.error(err.response.data.non_field_errors[0], {
                  autoClose: 2000,
                });
                reset();              
              }
            )
            .catch((error) => {
              
              toast.error("Something went wrong, please try again !", {
                autoClose: 2000,
              });
            });
        } else {
          errors.showMessages();
        }
      };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
        <div
          data-tooltip-id={"edit-tooltip"}
          data-tooltip-content={props.constLabel?.lbl_edit_movement ? props.constLabel?.lbl_edit_movement :"Edit Movement"}
          className="editIcon me-2">
          <Edit2
            color="#FFFFFF"
            size={18}
            className="cursor-pointer"
            onClick={() => {
              // setUpdateForm(val)
              setVisible(true);
            }}
          />
        </div>
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>{props.constLabel?.lbl_update_movement ? props.constLabel?.lbl_update_movement : "Update Movement"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form action=""> 
            <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">
                  {props.constLabel?.lbl_movement_code ? props.constLabel?.lbl_movement_code : "Movement Code"} <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>

                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="movementCode"
                    name="movementCode"
                    placeholder="Enter movement code "
                    {...register("movementCode")}
                  />
                  <span className="text-danger">
                    {errors.movementCode?.message}
                  </span>
                </div>
              </div>
            
              <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">
                 {props.constLabel?.lbl_transaction_name ? props.constLabel?.lbl_transaction_name : "Transaction Name"}
                  <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-8">

                  <Controller
                    name={`transaction`}
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="transaction_name"
                        size="sm"
                        multiple={false}
                        // options={transaction.data}
                        // isLoading={orgtype.loading}
                        placeholder="Select transaction"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.transaction ? true : false}
                      />
                    )}
                  />
                  {errors.transaction && (
                    <span className="text-danger err-msg">
                      {errors.transaction.message}
                    </span>
                  )}
                </div>
              </div>
              
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {props.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}
            </button>
          </Modal.Footer>
        </Modal>

    </React.Fragment>
  )
}

export default EditMovementMaster

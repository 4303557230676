import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faClose } from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import {
  del_rmi_images,
  get_rmi_images,
  get_document_type,
  upload_rmi_images,
} from "../../../../api";
import { removePrefix } from "../../../../common/Functions/CommonFunctions";
import { faAdd, faEye } from "@fortawesome/free-solid-svg-icons";
import { Trash2 } from "react-feather";

const documentsFileTypes = [
  "image/jpeg",
  "image/jpg",
  "image/png",
  "application/msword",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  "application/vnd.ms-excel",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  "application/pdf",
];
const schema = yup
  .object()
  .shape({
    items: yup.array().of(
      yup.object().shape({
        doc_type: yup
          .array()
          .min(1, "Doc type is required")
          .required("Doc type is required !"),
        doc: yup
          .mixed()
          .test(
            "fileSize",
            "Document can be uploaded up to 10 MB",
            (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)
          )
          .test(
            "fileType",
            "Only .doc, .docx, .xls, .xlsx, png ,jpg , jpeg and .pdf files are allowed",
            (value) => {
              if (!value) return true; // If no file is selected, it's considered valid.
              // Check if the file type is included in the allowed document file types.
              return documentsFileTypes.includes(value[0]?.type);
            }
          )
          .required("Required"),
      })
    ),
  })
  .required();
function RMIGallery(props) {
  const { galleryModal, setGalleryModal } = props;
  const [docType, setDocType] = useState({ data: [], loading: false });
  const imageFileExtensions = ["png", "jpg", "jpeg"];
  const [imageList, setImageList] = useState({ data: [], loading: false });
  const { register, handleSubmit, formState, control, reset, watch, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        items: [
          {
            doc: "",
            doc_type: [],
          },
        ],
      },
    }); // initialise the hook

  const { fields, append, remove } = useFieldArray({ control, name: "items" });
  let { errors } = formState;
  useEffect(() => {
    fetchDoctype();
    if (galleryModal.mode) {
        fetchimages()
    }
  }, [galleryModal.mode]);

  function fetchDoctype() {
    get_document_type().then(
      (res) => {
        console.log("res", res);
        setDocType({ ...docType, loading: false, data: res.data.data });
      },
      (err) => {
        console.log("err", err);
        setDocType({ ...docType, loading: false, data: [] });
      }
    );
  }
  function deletefile(val) {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          docPath: removePrefix(val.image),
        };
        del_rmi_images(payload).then(
          (res) => {
            toast.success("Document deleted successfully !", {
              autoClose: 3000,
            });
            fetchimages()
          },
          (err) => {
            toast.error(err.response.data.message, { autoClose: 3000 });
          }
        );
      }
    });
  }
  function convertBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
      if (file) {
        reader.readAsDataURL(file);
      } else {
        reject(new Error("File is empty"));
      }
    });
  }

  function fetchimages(){
    setImageList({ ...imageList, data: [], loading: true });
    get_rmi_images({ rmi_id: galleryModal.data.toolinvenId }).then(
      (res) => {
        console.log("res", res);
        setImageList({ ...imageList, data: res.data.data, loading: false });
      },
      (err) => {
        console.log("err", err);
        setImageList({ ...imageList, data: [], loading: false });
      }
    );
  }

  const onSubmit = async (data) => {
    console.log("data", data);

    
    if (data !== "") {
      const imageData = [];
      if (data.items !== null && data.items?.length > 0) {
        for (let i = 0; i < data.items?.length; i++) {
          const getExt = data.items[i].doc[0].name?.split(".").pop();

          console.log("get", getExt);
          let dataimage = await convertBase64(data.items[i].doc[0]);
          let newImage = dataimage.replace(
            /data:image\/[a-zA-Z]+;/,
            "data:image/" + getExt + ";"
          );

          imageData.push({
            doc_type: data.items[i].doc_type[0].taskDocumentTypeId,
            document: newImage,
            document_name: data.items[i].doc[0].name,
          });
        }
      }

      console.log("imageData", imageData);

      
      const payload = {
        toolinvid: galleryModal.data.toolinvenId,
        docs: imageData,
      };
      console.log("payload", payload);
      upload_rmi_images(payload).then(
        (res) => {
          console.log("res", res);
          toast.success("Document uploaded Successfully !", {
            autoClose: 3000,
          });
  setGalleryModal({mode:false, data:{}})
          
        },
        (err) => {
          console.log("err", err);
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Modal
        show={galleryModal.mode}
        onHide={() => {
          setGalleryModal({ ...galleryModal, mode: false, data: {} });
        }}
        size={"lg"}
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_rmi_gallery
              ? props.constLabel?.lbl_rmi_gallery
              : "RMI Gallery"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <p className="pc_card_title roboto-bold form-label mt-2">
              {props.constLabel?.lbl_add_image
                ? props.constLabel?.lbl_add_image
                : "Add Document"}
            </p>
          </div>

          <div className="py-2 mt-3">
            <table className="table table-bordered">
              <thead className="table-grey roboto-bold">
                <tr>
                  <th scope="col" width="40%">
                    {props.constLabel?.lbl_select_image
                      ? props.constLabel.lbl_select_image
                      : "Select Document"}
                  </th>
                  <th scope="col" width="40%">
                    {props.constLabel?.lbl_select_doc_type
                      ? props.constLabel.lbl_select_doc_type
                      : "Select Doc Type"}
                  </th>

                  <th scope="col" width="20%">
                    {"Action"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {fields.map(({ id }, index) => (
                  <tr>
                    <td style={{ verticalAlign: "top" }} key={id}>
                      <input
                        className={
                          errors.items?.[index]?.doc
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        type="file"
                        name={`items[${index}].doc`}
                        {...register(`items[${index}].doc`)}
                        // onChange={(e) => handleFileChange(e, index)}
                        placeholder="Please select doc"
                      />

                      {errors.items?.[index]?.doc && (
                        <span className="text-danger err-msg">
                          {errors.items?.[index]?.doc.message}
                        </span>
                      )}

                      {index + 1 == fields.length && (
                        <button
                          style={{ fontSize: "16px" }}
                          className="btn btn-sm button-primary px-2 mt-2"
                          onClick={() => {
                            if (fields.length < 2) {
                              append({});
                            } else {
                              toast.error("Maximum 2 images allowed", {
                                autoClose: 2000,
                              });
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              color: "#ffffff",
                            }}
                            icon={faAdd}
                            size="sm"
                          />
                          {props.constLabel?.lbl_add_image
                            ? props.constLabel?.lbl_add_image
                            : "Add Image"}
                        </button>
                      )}
                    </td>

                    <td style={{ verticalAlign: "top" }}>
                      <Controller
                        name={`items[${index}].doc_type`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="name"
                            multiple={false}
                            size="sm"
                            options={docType.data}
                            isLoading={docType.loading}
                            placeholder="Select doc Type"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            isInvalid={
                              errors.items?.[index]?.doc_type ? true : false
                            }
                          />
                        )}
                      />

                      {errors.items?.[index]?.doc_type && (
                        <span className="text-danger err-msg">
                          {errors.items?.[index]?.doc_type.message}
                        </span>
                      )}
                    </td>

                    <td
                      style={{
                        verticalAlign: "top",
                      }}
                    >
                      <div
                        className="deleteIcon cursor_pointer"
                        onClick={
                          index > 0 ? () => { remove(index) } : null
                        }
                      >
                        <Trash2 color="#FFFFFF" size={18} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <hr />

          <div className="row image_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
            {imageList.loading ? (
              <div className="mx-auto">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : imageList.data.length == 0 ? (
              <p className="text-danger mb-0 f-22 mx-auto mt-4">
                {"No image found !"}
              </p>
            ) : (
              imageList.data.map((val, i) => (
                <div className="col" key={i}>
                  {imageFileExtensions.includes(
                    val.image.split(".").pop().toLowerCase()
                  ) ? (
                    <div className="image-container">
                      <img
                        src={process.env.REACT_APP_IMAGE_URL + val.image}
                        className="img-fluid"
                        alt="Image 1"
                      />

                      <div className="image-overlay">
                        <FontAwesomeIcon
                          onClick={() =>
                            window.open(
                              process.env.REACT_APP_IMAGE_URL + val.image,
                              "_blank"
                            )
                          }
                          className="icon"
                          style={{ color: "#fff" }}
                          icon={faEye}
                          size="xl"
                        />

                        <FontAwesomeIcon
                          onClick={() => deletefile(val)}
                          className="icon-close"
                          style={{ color: "#fff" }}
                          icon={faClose}
                          size="lg"
                        />
                      </div>
                      <p  className="mt-2 text-center">{val.doc_details.name}</p>
                    </div>
                  ) : (
                    <div className="image-container"
                      onClick={() =>
                        window.open(
                          process.env.REACT_APP_IMAGE_URL + val.image,
                          "_blank"
                        )
                      }
                    >
                      
                        <FontAwesomeIcon
                          className="icon-file-pdf"
                           style={{  height:"120px", marginLeft: "40px" }}
                          icon={faFileAlt}
                          size="3x"
                         
                        />

                        <div className="image-overlay">
                          <FontAwesomeIcon
                            onClick={() =>
                              window.open(
                                process.env.REACT_APP_IMAGE_URL + val.image,
                                "_blank"
                              )
                            }
                            className="icon"
                            style={{ color: "#fff" }}
                            icon={faEye}
                            size="xl"
                          />

                          <FontAwesomeIcon
                            onClick={() => deletefile(val)}
                            className="icon-close"
                            style={{ color: "#fff" }}
                            icon={faClose}
                            size="lg"
                          />
                        </div>

                      <p className="mt-2 text-center">{val.doc_details.name}</p>
                    </div>

                  )}
              
                </div>
              ))
            )}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_close ? props.constLabel.lbl_close : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default RMIGallery;

import React from 'react';
import Lottie from 'react-lottie';
import ACCESS_DENIED from '../../assets/lottie_json/ACCESS_DENIED.json';
import { useNavigate } from 'react-router-dom';

function AccessDenied() {
    const navigate = useNavigate();

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: ACCESS_DENIED, // Your Lottie JSON data
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className='access_denied '>
            <div className='row '>
                <div className='col-12 col-md-7 card border-0'>
                    <div className='card-body text-center'>
                        {/* <img src={road} alt='access_denied' className='img-fluid' /> */}
                        <Lottie
                            options={defaultOptions}
                            height={120}
                            width={120}
                        />

                        <h4 className='mt-2'>Access Denied</h4>
                        <p className='mb-0 mt-2'>Sorry about that, but you don't have
                            permission to access this page.</p>
                        <p className='mb-0 text-muted'>Contact Admin</p>

                        <button
                            onClick={() => navigate(-1)}
                            className='btn button-primary px-4 mt-2'>Go Back</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AccessDenied
import React, { useState, useEffect } from "react";
import { Nav, Tab, Tabs } from "react-bootstrap";
import Overview from "./Overview";
import { get_dispatch, getCustomer, getSalesOrder } from "../../../api/index";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import CustomerQuoteList from "./CustomerQuoteList";
import CustomerSOList from "./CustomerSOList";
import CreateSO from "./Creation_SO/CreateSO";
import { faCircleInfo, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DatePicker from "react-datepicker";
import ContactDetail from "./New360_Tabs/ContactDetail";
import SalesOrder from "./New360_Tabs/SalesOrder";
import BillingShippingAddress from "./New360_Tabs/BillingShippingAddress";
import CustomerOverview from "./New360_Tabs/CustomerOverview";
import TaxDetail from "./New360_Tabs/TaxDetail";
import { convertDateYYMMDD, getFinancialYearStartDate, getLabels, setAccessPrivilage } from "../../../common/Functions/CommonFunctions";
import { useSelector } from "react-redux";
import Slide from "../../../assets/images/icons/project_menu/collapse.png"
import { Tooltip } from "react-tooltip";
import AccessDenied from "../../Common/AccessDenied";

const schema = yup
  .object()
  .shape({
    // fromDate: yup.date().typeError("Please select from date !").required("Please select from date !"),
    fromDate: yup
      .date()
      .typeError("Please select From date!")
      .required("Please select a From date!"),
    toDate: yup
      .date()
      .typeError("Please select To date!")
      .required("Please select a To date!"),
  })
  .required();

function NewCustomer360() {
  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 1);
    return endDate;
  }
  const [view, setViews] = useState("overview");
  const [custList, setCustList] = useState({ data: [], loading: false });
  const [selectedCust, setSelectedCust] = useState({});
  const [compresent, setCompresent] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [startDate, setStartDate] = useState(getFinancialYearStartDate(new Date()));
  const [endDate, setEndDate] = useState(new Date());

  const [dateFilterTab, setDateFilterTab] = useState('Today');
  const [statusTab, setStatusTab] = useState('Pending');
  const [invoiceStatus, setInvoiceStatus] = useState('Pending');
  const [paymentStatus, setPaymentStatus] = useState('Status1');
  const [placeholderStatus, setPlaceholderStatus] = useState('Status1');

  const [so_value, setSO_Value] = useState(0);
  const [disp_value, setDisp_Value] = useState(0);

  const [key, setkey] = useState("customerOverview");

  const [constLabel, setConstLabel] = useState({});
  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });

  const { control, formState, setError } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;


  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Customer 360"));
    setConstLabel(getLabels('Master', 'Customer360'));
    openNav();
    // fetchCustomer();
  }, []);

  useEffect(() => {
    const getData = setTimeout(() => {
      fetchCustomer();
    }, 1000);

    return () => clearTimeout(getData);
  }, [customerName]);

  // Call the fetch function inside useEffect
  useEffect(() => {
    fetchSalesOrderValue();
    fetchDispatchValue();
  }, [startDate, endDate, selectedCust]); // Re-run when these dependencies change

  function fetchCustomer(para1, para2) {
    let payload = {
      searchVal: customerName.length > 0 ? customerName : null,
      partyType: "Customer"
    };
    setCustList({ ...custList, loading: true });

    getCustomer(payload).then(
      (res) => {

        setCustList({ ...custList, data: res.data.data, loading: false });
        if (res.data.data.length > 0) {

          setSelectedCust(res.data.data[0]);
          setCompresent(false);
        }
        else {
          setCompresent(true);
        }
      },
      (err) => {

        setCustList({ ...custList, data: [], loading: false });
      }
    );
  }

  function handleFilterTab(tab) {

    setDateFilterTab(tab);
  }

  function handleOrderStatus(ord_st) {

    setStatusTab(ord_st);
  }

  function handleInvoiceStatus(inv_st) {

    setInvoiceStatus(inv_st);
  }

  function handlePaymentStatus(pay_st) {

    setPaymentStatus(pay_st);
  }

  function handlePlaceholderStatus(plc_st) {

    setPlaceholderStatus(plc_st);
  }

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function toggleNav() {
    const sidebar = document.getElementById("mySidebar");
    const mainDiv = document.getElementById("root_div_main");

    if (sidebar.style.width === "320px") {
      // Sidebar is open, so close it
      sidebar.style.width = "0";
      mainDiv.style.marginLeft = "0";
      sidebar.style.overflowX = "hidden";
    } else {
      // Sidebar is closed, so open it
      sidebar.style.width = "320px";
      mainDiv.style.marginLeft = "320px";
      setTimeout(() => {
        sidebar.style.overflowX = "visible";
      }, 200);
    }
  }

  function salesOrderValue() {
    const payload = {
      startDate: convertDateYYMMDD(startDate, "-"),
      endDate: convertDateYYMMDD(endDate, "-"),
      customer_id: selectedCust.uuid,
    }
    getSalesOrder(payload).then((res) => {
      console.log("so value", res);
      let totalAmount = 0;

      // Summing up all amounts from so_line_items across all salesOrders
      totalAmount = res.data.data?.reduce((orderAcc, order) => {
        const lineItemsSum = order.so_order_lines.reduce((itemAcc, item) => itemAcc + item.amount, 0);
        return orderAcc + lineItemsSum;
      }, 0);

    }, (err) => {


    })
  }

  // Separate function to fetch and calculate total amount
  async function fetchSalesOrderValue() {
    try {
      const payload = {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        customer_name: `["${selectedCust.organization}"]`,
      };

      const res = await getSalesOrder(payload);
      // console.log("so value", res);

      const amount = res.data.data?.reduce((orderAcc, order) => {

        return orderAcc + Number(order.final_total ?? 0); // Use final_total if it exists

        // const lineItemsSum = order.so_order_lines.reduce(
        //   (itemAcc, item) => itemAcc + Number(item.amount),
        //   0
        // );
        // return orderAcc + lineItemsSum;
      }, 0);

      setSO_Value(amount.toFixed(2)); // Update state with total amount
    } catch (error) {
      // console.error("Error fetching sales order:", error);
      setSO_Value(0);
    }
  };

  // Separate function to fetch and calculate total amount
  async function fetchDispatchValue() {
    try {
      const payload = {
        startDate: convertDateYYMMDD(startDate, "-"),
        endDate: convertDateYYMMDD(endDate, "-"),
        party_id: selectedCust.uuid,
      };

      const res = await get_dispatch(payload);
      // console.log("disp value", res);

      const amount = res.data.data?.reduce((orderAcc, order) => {
        // const lineItemsSum = order.dispatch_line_items.reduce(
        //   (itemAcc, item) => itemAcc + (Number(item.amount) ?? 0),
        //   0
        // );
        // return orderAcc + lineItemsSum;
        return orderAcc + Number(order.amount ?? 0); // Use final_total if it exists
      }, 0);

      setSO_Value(amount); // Update state with total amount
    } catch (error) {
      console.error("Error fetching sales order:", error);
    }
  };

  const handleFromDateChange = (date) => {
    if (endDate && date > endDate) {
      return;
    }
    setStartDate(date);
  };


  const handleToDateChange = (date) => {
    if (startDate && date < startDate) {
      return;
    }
    setEndDate(date);
  };

  return (
    <React.Fragment>
      {!privilage.read ?
        <AccessDenied />
        :
        <div className="min-vh-100" id="root_div_main">
          <div id="mySidebar" className="supplier_customsidebar ">

            {privilage.read && <>

              <div className="d-flex justify-content-between m-2">
                <p className="roboto-bold pt-2" style={{ fontSize: "24px" }}>
                  {constLabel?.lbl_customers ? constLabel.lbl_customers : "Customers"}
                </p>
                <div>
                  <a className="custom-close-btn" onClick={() => toggleNav()}>
                    ×
                  </a>
                </div>
              </div>

              <div className="col-12 pb-2 px-2">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search by name"
                  onChange={(val) => {
                    const inputValue = val.target.value;
                    if (!inputValue.trim()) {
                      if (customerName.length == 1) {
                        setCustomerName("");
                      }
                    } else {
                      setCustomerName(inputValue);
                    }
                  }}
                />
              </div>

              <div className="content" style={{ marginTop: "0px" }}>
                {custList.loading ? (
                  <div className="d-flex justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  custList.data.map((val, ind) => (
                    <div className="supplier_box" key={ind}>
                      <div className="form-check">
                        <input
                          onChange={() => {

                            setSelectedCust(val);
                          }}
                          checked={selectedCust.uuid === val.uuid}
                          className="form-check-input cursor_pointer"
                          type="checkbox"
                          value=""
                          id={"flexCheckDefault" + ind}
                        />
                        <label
                          className="form-check-label cursor_pointer"
                          htmlFor={"flexCheckDefault" + ind}
                        >
                          {val.organization}
                        </label>
                      </div>
                    </div>
                  ))
                )}
              </div>
            </>}
          </div>

          {privilage.read &&
            <div className="supplier_360_view">
              {compresent == false ? <div className="row">
                <div className="col-12">
                  <div className="py-2 d-md-flex justify-content-start align-items-center">
                    {/* <FontAwesomeIcon
                      onClick={() => openNav()}
                      className="cursor_pointer"
                      style={{
                        color: "Black",
                        marginRight: "5px",
                      }}
                      icon={faList}
                      size="lg"
                    /> */}
                    <Tooltip id={"open-tooltip"} place="left" />
                    <img
                      src={Slide}
                      height="32"
                      width="32"
                      className="cursor_pointer my-1"
                      onClick={() => toggleNav()}
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-content={"Filters"}
                    />
                    <p className="roboto-bold m-0 ms-3" style={{ fontSize: "24px" }}>
                      {selectedCust.organization}
                    </p>
                  </div>
                  <div className="card border-0">
                    <div className="card-body p-0">
                      <div className="col-12 col-md-12 p-4">
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <div className="row">
                              <div className="col-12 col-md-3">
                                <p className="mb-0 form-check-label">{constLabel?.lbl_from_date ? constLabel.lbl_from_date : "From Date"}</p>
                              </div>
                              <div className="col-12 col-md-3">
                                <div>
                                  <Controller
                                    control={control}
                                    name="fromDate"
                                    render={({ field }) => (
                                      <DatePicker
                                        id="fromDate"
                                        className="form-control form-control-sm"
                                        selected={startDate}
                                        onChange={(date) => {
                                          field.onChange(date);
                                          handleFromDateChange(date)
                                        }}
                                        showYearDropdown
                                        scrollableMonthYearDropdown
                                        dateFormat="dd/MM/yyyy"
                                        placeholderText="From Date"
                                        maxDate={new Date()}
                                        autoComplete="false"
                                      />
                                    )}
                                  />
                                  {errors.fromDate && (
                                    <span className="text-danger err-msg">
                                      {errors.fromDate.message}
                                    </span>
                                  )}
                                </div>
                              </div>
                              <div className="col-12 col-md-3">
                                <p className="mb-0">{constLabel?.lbl_to_date ? constLabel.lbl_to_date : "To Date"}</p>
                              </div>
                              <div className="col-12 col-md-3">
                                <p className="mb-0">
                                  <div>
                                    <Controller
                                      control={control}
                                      name="toDate"
                                      render={({ field }) => (
                                        <DatePicker
                                          id="toDate"
                                          className="form-control form-control-sm"
                                          selected={endDate}
                                          onChange={(date) => {
                                            field.onChange(date);
                                            handleToDateChange(date)

                                          }}
                                          showYearDropdown
                                          scrollableMonthYearDropdown
                                          dateFormat="dd/MM/yyyy"
                                          placeholderText="To Date"
                                          maxDate={new Date()}
                                          autoComplete="false"
                                        />
                                      )}
                                    />
                                    {errors.toDate && (
                                      <span className="text-danger err-msg">
                                        {errors.toDate.message}
                                      </span>
                                    )}
                                  </div>
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-md-6 text-end">
                            <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${dateFilterTab === 'Today' ? 'button-primary text-white' : ''}`} onClick={() => handleFilterTab('Today')}>
                              {constLabel?.lbl_today ? constLabel.lbl_today : "Today"}
                            </span>
                            <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${dateFilterTab === 'This Week' ? 'button-primary text-white' : ''}`} onClick={() => handleFilterTab('This Week')}>
                              {constLabel?.lbl_this_week ? constLabel.lbl_this_week : "This Week"}
                            </span>
                            <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center ${dateFilterTab === 'This Month' ? 'button-primary text-white' : ''}`} onClick={() => handleFilterTab('This Month')}>
                              {constLabel?.lbl_this_year ? constLabel.lbl_this_year : "This Year"}
                            </span>
                          </div>
                        </div>
                      </div>
                      {/* <div className="px-4">
                <Nav variant="underline">
                  <Nav.Item className="pr-2">
                    <Nav.Link
                      active={view == "overview"}
                      onClick={() => setViews("overview")}
                    >
                      Overview
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="px-2">
                    <Nav.Link
                      active={view == "quotation"}
                      onClick={() => setViews("quotation")}
                    >
                      Quotation
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="px-2">
                    <Nav.Link
                      active={view == "sales order"}
                      onClick={() => setViews("sales order")}
                    >
                      Sales Order
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </div> */}

                      {/* <div
                style={{
                  borderTop: "2px solid #c6c6c6",
                  marginTop: "-1px",
                  background: "white",
                }}
              >
                {view == "overview" ? (
                  <Overview selectedCust={selectedCust} />
                ) : view == "quotation" ? (
                  <CustomerQuoteList selectedCust={selectedCust} />
                ) : view == "sales order" ? (
                  <CustomerSOList selectedCust={selectedCust} />
                ) : null}
              </div> */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 col-md-3 mt-4">
                      <div className="card border-0">
                        <div className="card-body p-2">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="roboto-bold">{constLabel?.lbl_sales_order ? constLabel.lbl_sales_order : "Sales Order"}{" "}<span>(&#8377;)</span></p>
                            {/* <FontAwesomeIcon
                            className="pt-1"
                            icon={faCircleInfo}
                            size="sm"
                          /> */}
                          </div>

                          {/* <div className="col-12 col-md-12">
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${statusTab === 'Approved' ? 'button-primary text-white' : ''}`} onClick={() => handleOrderStatus('Approved')}>
                            {constLabel?.lbl_approved ? constLabel.lbl_approved : "Approved"}
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${statusTab === 'Cancelled' ? 'button-primary text-white' : ''}`} onClick={() => handleOrderStatus('Cancelled')}>
                            {constLabel?.lbl_cancelled ? constLabel.lbl_cancelled : "Cancelled"}
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center ${statusTab === 'Pending' ? 'button-primary text-white' : ''}`} onClick={() => handleOrderStatus('Pending')}>
                            {constLabel?.lbl_pending ? constLabel.lbl_pending : "Pending"}
                          </span>
                        </div> */}

                          <div className="roboto-bold text-center m-3">
                            <h1 className="bold">{so_value}</h1>
                            {/* <p>Orders Pending</p> */}
                            {/* <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center`}>
                            View All Sales Order
                          </span> */}

                          </div>

                        </div>
                      </div>
                    </div>

                    {/* <div className="col-12 col-md-3 mt-4">
                    <div className="card border-0">
                      <div className="card-body p-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="roboto-bold">{constLabel?.lbl_sales_invoice ? constLabel.lbl_sales_invoice : "Sales Invoice"}</p>
                          <FontAwesomeIcon
                            className="pt-1"
                            icon={faCircleInfo}
                            size="sm"
                          />
                        </div>
                        <div className="col-12 col-md-12">
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${invoiceStatus === 'Approved' ? 'button-primary text-white' : ''}`} onClick={() => handleInvoiceStatus('Approved')}>
                            {constLabel?.lbl_approved ? constLabel.lbl_approved : "Approved"}
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${invoiceStatus === 'Cancelled' ? 'button-primary text-white' : ''}`} onClick={() => handleInvoiceStatus('Cancelled')}>
                            {constLabel?.lbl_cancelled ? constLabel.lbl_cancelled : "Cancelled"}
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center ${invoiceStatus === 'Pending' ? 'button-primary text-white' : ''}`} onClick={() => handleInvoiceStatus('Pending')}>
                            {constLabel?.lbl_pending ? constLabel.lbl_pending : "Pending"}
                          </span>
                        </div>

                        <div className="roboto-bold text-center m-3">
                          <h1>10</h1>
                          <p>Sales invoice pending</p>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center`}>
                            View All Sales Invoices
                          </span>

                        </div>

                      </div>
                    </div>
                  </div> */}

                    {/* <div className="col-12 col-md-3 mt-4">
                    <div className="card border-0">
                      <div className="card-body p-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="roboto-bold">{constLabel?.lbl_total_payment_receive ? constLabel.lbl_total_payment_receive : "Total Payment Received"}</p>
                          <FontAwesomeIcon
                            className="pt-1"
                            icon={faCircleInfo}
                            size="sm"
                          />
                        </div>
                        <div className="col-12 col-md-12">
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${paymentStatus === 'Status1' ? 'button-primary text-white' : ''}`} onClick={() => handlePaymentStatus('Status1')}>
                            Status1
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${paymentStatus === 'Status2' ? 'button-primary text-white' : ''}`} onClick={() => handlePaymentStatus('Status2')}>
                            Status2
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center ${paymentStatus === 'Status3' ? 'button-primary text-white' : ''}`} onClick={() => handlePaymentStatus('Status3')}>
                            Status3
                          </span>
                        </div>

                        <div className="roboto-bold text-center m-3">
                          <h1>$ 10,000</h1>
                          <p className="invisible">payment</p>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center`}>
                            View All Transactions
                          </span>

                        </div>

                      </div>
                    </div>
                  </div> */}

                    <div className="col-12 col-md-3 mt-4">
                      <div className="card border-0">
                        <div className="card-body p-2">
                          <div className="d-flex justify-content-between align-items-center">
                            <p className="roboto-bold">{constLabel?.lbl_dispatch ? constLabel.lbl_dispatch : "Dispatch"}{" "}<span>(&#8377;)</span></p>
                            {/* <FontAwesomeIcon
                            className="pt-1"
                            icon={faCircleInfo}
                            size="sm"
                          /> */}
                          </div>
                          {/* <div className="col-12 col-md-12">
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${placeholderStatus === 'Status1' ? 'button-primary text-white' : ''}`} onClick={() => handlePlaceholderStatus('Status1')}>
                            Status1
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer me-2 text-center ${placeholderStatus === 'Status2' ? 'button-primary text-white' : ''}`} onClick={() => handlePlaceholderStatus('Status2')}>
                            Status2
                          </span>
                          <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center ${placeholderStatus === 'Status3' ? 'button-primary text-white' : ''}`} onClick={() => handlePlaceholderStatus('Status3')}>
                            Status3
                          </span>
                        </div> */}

                          <div className="roboto-bold text-center m-3">
                            <h1 className="bold">{disp_value}</h1>
                            {/* <p>Label</p> */}
                            {/* <span className={`roboto badge rounded-pill text-dark badge-outline cursor_pointer text-center`}>
                            Placeholder CTA
                          </span> */}

                          </div>

                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4 p-2" id="cust_tab_master">
                    <Tabs
                      variant="pills"
                      id="controlled-tab-example"
                      activeKey={key}
                      onSelect={(k) => {
                        setkey(k);

                      }}
                      className="mb-3"
                    // fill
                    >
                      <Tab
                        eventKey="customerOverview"
                        title={<span className="f-14 f-w-600">{"Customer Overview"}</span>}
                      >
                        <CustomerOverview setkey={setkey} data={selectedCust} />

                      </Tab>
                      <Tab
                        eventKey="contactDetail"
                        title={<span className="f-14 f-w-600">{"Contact Detail"}</span>}
                      >
                        <ContactDetail setkey={setkey} data={selectedCust} />
                      </Tab>

                      <Tab
                        eventKey="billing&ShippingAddress"
                        title={<span className="f-14 f-w-600">{"Billing & Shipping Address"}</span>}
                      >
                        <BillingShippingAddress setkey={setkey} data={selectedCust} />
                      </Tab>

                      <Tab
                        eventKey="taxDetail"
                        title={<span className="f-14 f-w-600">{"Tax Detail"}</span>}
                      >
                        <TaxDetail setkey={setkey} data={selectedCust} />
                      </Tab>

                      <Tab
                        eventKey="salesOrder"
                        title={<span className="f-14 f-w-600">{"Sales Order"}</span>}
                      >
                        <SalesOrder setkey={setkey} data={selectedCust} />

                      </Tab>

                      <Tab
                        eventKey="salesInvoice"
                        title={<span className="f-14 f-w-600">{"Sales Invoice"}</span>}
                      >
                        <div
                          className="alert alert-sm alert-danger mb-0 text-center my-1"
                          role="alert"
                          setkey={setkey}
                        >
                          {"Sales invoice list is empty !"}
                        </div>
                      </Tab>

                      <Tab
                        eventKey="transaction"
                        title={<span className="f-14 f-w-600">{"Transaction"}</span>}
                      >
                        <div
                          className="alert alert-sm alert-danger mb-0 text-center my-1"
                          role="alert"
                          setkey={setkey}
                        >
                          {"Transaction list is empty !"}
                        </div>
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div> :
                <div
                  className="alert alert-sm alert-danger mb-0 text-center my-1"
                  role="alert"
                >
                  {"Customer not found !"}
                </div>
              }
            </div>
          }
        </div>
      }
    </React.Fragment>
  );
}

export default NewCustomer360;

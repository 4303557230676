import React, { useState } from "react";
import { Modal, OverlayTrigger, Tooltip} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBank } from "@fortawesome/free-solid-svg-icons";

function ViewBankDetail(props) {
  const [visible, setVisible] = useState(false);

  return (
    <React.Fragment>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">{"Bank Details"}</Tooltip>}
      >
      <button
        className="btn border roboto-bold"
        type="button"
        // style={{ padding: "0rem 0.5rem" }}
        onClick={() => {
          setVisible(true);
        }}
      >
        <FontAwesomeIcon style={{ color: "#000" }} icon={faBank} size="lg" />{" "}
        {/* {" Bank"} */}
      </button>
      </OverlayTrigger>
      <Modal
        show={visible} onHide={() => setVisible(false)} size={"lg"}
        className="modal-dialog-centered"
      >
        <Modal.Header>
          {/* <div className="d-flex justify-content-between align-items-center"> */}
            <h1 className="bold">{"Bank Details"}</h1>
            <button
              type="button"
              className="btn btn-outline-secondary roboto-bold"
              onClick={() => setVisible(false)}
            >
              Close
            </button>
          {/* </div> */}
        </Modal.Header>
        <Modal.Body>
          <form action="">
            {props.data && props.data.length > 0 ? (
              <div className="table-responsive">
                <table
                  className="table table-bordered mt-2 table-bordered"
                  width={"100%"}
                >
                  <thead className="thead-light text-ceter">
                    <tr className="">
                      <th scope="col">{"Name"}</th>
                      <th scope="col">{"A/c No"}</th>
                      <th scope="col">{"IFSC"}</th>
                      <th scope="col">{"MICR"}</th>
                    </tr>
                  </thead>
                  <tbody className="text-center">
                    {props.data.map((val, i) => (
                      <tr key={i} className="f-14">
                        <td>{val.bankId}</td>
                        <td>{val.bankAccNo}</td>
                        <td>{val.bankIfscCode}</td>
                        <td>{val.bankMicrCode}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            ) : (
              <p className="text-danger mb-0 f-22 text-center mt-4">
                {"Bank Details list is empty"}
              </p>
            )}
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default ViewBankDetail;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faClose } from "@fortawesome/free-solid-svg-icons";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import Accordion from "react-bootstrap/Accordion";
// import { get_document_type } from "../../../../../api/index";
import { Typeahead } from "react-bootstrap-typeahead";
import { getLabels } from "../../../common/Functions/CommonFunctions";

const imageFileTypes = ["image/jpeg", "image/jpg", "image/png", "application/pdf"];

const schema = yup
  .object()
  .shape({
    image: yup
      .mixed()
      .test(
        "fileSize",
        "File can be uploaded upto 10 MB",
        (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)
      )
      .test("fileType", "Only image and pdf files are allowed", (value) => {
        if (!value) return true; // If no file is selected, it's considered valid.
        // return value[0]?.type.startsWith('image/');
        return imageFileTypes.includes(value[0]?.type);
        })
      .required("Required"),
    fileName: yup.
      string()
      .required("File name is required !"),
  })
  .required();

function Gallary(props) {
  const [constLabel, setConstLabel] = useState({});

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;


  useEffect(() => {
   setConstLabel(getLabels('Master', 'Organization'));
    reset({
      image: null,
      fileName: "",
    });
  }, [props])


  const onsubmit = (data) => {
    
   


    let obj = {}
    obj.name = data.fileName.trim();
    obj.file = data.image[0];
    obj.type=props.name_gal.type
    
    props.setFiles({
      ...props.files,
      [`${props.name_gal.type}`]: obj
    })
    reset({
      image: null,
      fileName: "",
    });

  };



  return (
    <React.Fragment>
      <Modal
        show={props.visible}
        onHide={() => props.setVisible(false)}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{`${props.name_gal.name}`}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-12 col-md-4">
              <label style={{ fontSize: "14px" }} className="col-form-label">
                {constLabel?.lbl_file ? constLabel.lbl_file : "File"}
              </label>
              <input
                className={
                  errors.image
                    ? "form-control form-control-sm is-invalid"
                    : "form-control form-control-sm"
                }
                type="file"
                {...register("image")}
                // accept=".pdf, .jpg, .jpeg, .png"
                accept="image/*, application/pdf"                
              />
              {errors.image && (
                <span className="text-danger err-msg">
                  {errors.image.message}
                </span>
              )}
            </div>
            <div className="col-12 col-md-4">
              <label style={{ fontSize: "14px" }} className="col-form-label">
                {constLabel?.lbl_file_name ? constLabel.lbl_file_name : "Filename"}
              </label>
              <input
                className={
                  errors.fileName
                    ? "form-control form-control-sm is-invalid"
                    : "form-control form-control-sm"
                }
                type="text"
                {...register("fileName")}
                placeholder="Enter filename"
              />
              {errors.fileName && (
                <span className="text-danger err-msg">
                  {errors.fileName.message}
                </span>
              )}
            </div>

            <div className="col-12 col-md-3">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label invisible d-block"
              >
                {constLabel?.lbl_file_name ? constLabel.lbl_file_name : "Filename"}
              </label>

              <button
                className="btn btn-sm btn-yellow px-3"
                onClick={handleSubmit(onsubmit)}
              >
                {constLabel?.lbl_submit ? constLabel.lbl_submit : "Submit"}
              </button>
            </div>
          </div>

          <div className="row mt-4 drawing_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
            {
              props.files[`${props.name_gal.type}`]?.name.length > 0 && props.files[`${props.name_gal.type}`].file?.type?.includes("image") == true ? (
                <div className="col text-center" >
                  <div className="image-container">
                    <img
                      src={URL.createObjectURL(props.files[`${props.name_gal.type}`]?.file)}
                      className="img-fluid"
                      alt="Image 1"
                    />


                  </div>

                  <span className="image-name cursor_pointer">
                    {props.files[`${props.name_gal.type}`]?.name}
                  </span>
                </div>
              ) : (props.files[`${props.name_gal.type}`]?.name.length > 0 &&
                <div className="col text-center" >
                  <div className="image-container cursor_pointer">
                    <div
                      onClick={() => window.open(props.files[`${props.name_gal.type}`].file, "_blank")}
                      className="other_doc d-flex justify-content-center align-items-center"
                    >
                      <FontAwesomeIcon
                        className="icon-file-pdf"
                        style={{ color: "#fff" }}
                        icon={faFileAlt}
                        size="3x"
                      />


                    </div>
                  </div>

                  <span className="image-name cursor_pointer">
                    {props.files[`${props.name_gal.type}`]?.name.length > 0 && props.files[`${props.name_gal.type}`].name}
                  </span>
                </div>
              )
            }
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-grey px-4"
            onClick={() => props.setVisible(false)}
          >
            {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Gallary;

import React, { useState, useEffect, useReducer } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faBan,
  faFilter,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal, Spinner } from "react-bootstrap";
import { Download, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Tooltip } from "react-tooltip";
import {
  get_challan_type,
  create_challan_type,
  delete_challan_type,
} from "../../../api";
import {
  calMaxPage,
  calMaxPage_new,
  getLabels,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import EditChallanType from "./EditChallanType";
import { useSelector } from "react-redux";
import { Challan_Type_Col_Name } from "../../../common/Labels/labelConstant";
import List from "../../../assets/images/icons/project_menu/checklist.png"
import AccessDenied from "../../Common/AccessDenied";

const schema = yup
  .object()
  .shape({
    cType: yup
      .array()
      .required("Please select challan type !")
      .min(1, "Please select challan type !"),
    typename: yup
      .string()
      .required("Name is required !"),
    idprefix: yup
      .string()
      .required("ID prefix is required !")
      .matches(/^[a-zA-Z]*$/, "Enter valid id prefix !"),
    // outoperation: yup.boolean().oneOf([true], "Operation detail is required!"),
  })
  .required();

function ChallanType() {
  const [showLabel, setShowLabel] = useState(Challan_Type_Col_Name);
  const [visible, setVisible] = useState(false);
  const [outOpr, setOutOpr] = useState(false);
  const [sameLocation, setSameLocation] = useState(false);
  const [checkWip, setCheckWip] = useState(false);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });

  const [sea_val, setSea_val] = useState(false);
  const [filterConfig, setFilterConfig] = useState({ searchVal: "" });
  const [constLabel, setConstLabel] = useState({});

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });

  const [typChallan, setTypChallan] = useState({
    data: [
      { id: 1, name: "Inward" },
      { id: 2, name: "Outward" },
    ],
    state: false,
  });

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;


  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Challan Type"));
  }, []);

  useEffect(() => {
    setConstLabel(getLabels("Master", "Challan Type"));
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchChallanType(page, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function clearFilter() {
    setFilterConfig({ ...filterConfig, challanType: "" });
    setSrNo(0);
    setPage(1);
  }

  function fetchChallanType(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    if (filterConfig.challanType != "") {
      payload.challanType = filterConfig.challanType;

      if (
        filterConfig.challanType?.length > 0 &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;
        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.challanType;
    }

    get_challan_type(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });

        // if (
        //   filterConfig.challanType?.length > 0 &&
        //   filterConfig.challanType?.[0]?.length > 0
        // ) {
        //   setSea_val(true);
        // } else {
        //   setSea_val(false);
        // }

        if (
          filterConfig.challanType != "" &&
          filterConfig.challanType?.length > 0
        ) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }

        //  console.log('calMaxPage_new(res.data.total)', calMaxPage_new(res.data.total,epp));
        setMaxPage(calMaxPage_new(res.data.total, epp));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      const payload = {
        typename: data.typename,
        cType: data.cType?.[0]?.name,
        idprefix: data.idprefix,
        outoperation: outOpr,
        sameLocation: sameLocation,
        wip: checkWip,
        remark: data.remark,
      };

      create_challan_type(payload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Challan type created successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            fetchChallanType(page, entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );

    } else {
      errors.showMessages();
    }
  };

  // Deleting challan type
  const delChallan_Type = (val) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
        };
        delete_challan_type(payload).then(
          (res) => {
            toast.success("Challan type deleted successfully !", {
              autoClose: 3000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchChallanType(page - 1, entriesPerPage);
              setSrNo(parseInt(srNo - entriesPerPage));
              setPage(page - 1);
            } else {
              fetchChallanType(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      {!privilage.read ?
        <AccessDenied />
        :
        <div className="min-vh-100" id="root_div_main">
          <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
            <Modal.Header
              style={{ background: "#2A3643", color: "white" }}
              closeButton
            >
              <Modal.Title>
                {constLabel?.lbl_add_challan_type
                  ? constLabel?.lbl_add_challan_type
                  : "Add Challan Type"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form action="">
                <div className="form-group">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_challan_type
                      ? constLabel?.lbl_challan_type
                      : "Challan Type"}
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    name="cType"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        options={
                          typChallan.data !== undefined ? typChallan.data : []
                        }
                        isLoading={typChallan.state}
                        placeholder="Select challan type..."
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.cType ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger err-msg">{errors.cType?.message}</span>
                </div>

                <div className="form-group mt-2">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_name ? constLabel?.lbl_name : "Name"}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className={
                      errors.typename
                        ? "form-control  is-invalid"
                        : "form-control"
                    }
                    id="typename"
                    name="typename"
                    placeholder="Enter challan name"
                    {...register("typename")}
                  />
                  <span className="text-danger err-msg">
                    {errors.typename?.message}
                  </span>
                </div>

                <div className="form-group mt-2">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_id_prefix ? constLabel?.lbl_id_prefix : "ID Prefix"}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className={
                      errors.idprefix
                        ? "form-control  is-invalid"
                        : "form-control"
                    }
                    id="idprefix"
                    name="idprefix"
                    placeholder="Enter id prefix"
                    {...register("idprefix")}
                  />
                  <span className="text-danger err-msg">
                    {errors.idprefix?.message}
                  </span>
                </div>

                <div className="form-group mt-2">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_wip ? constLabel?.lbl_wip : "WIP"}
                  </label>
                  <div className="form-control">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="wip"
                      name="wip"
                      onChange={() => setCheckWip(!checkWip)}
                      checked={checkWip}
                    />
                    <label className="form-check-label form_label ms-2">
                      {" "}
                      {" Is WIP Required ?"}
                    </label>
                  </div>
                </div>

                <div className="form-group mt-2">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_out_operation ? constLabel?.lbl_out_operation : "Out Operation"}
                  </label>
                  <div className="form-control">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="outoperation"
                      name="outoperation"
                      onChange={() => setOutOpr(!outOpr)}
                      checked={outOpr}
                    />
                    <label className="form-check-label form_label ms-2">
                      {" "}
                      {" Is Output Operation Required ?"}
                    </label>
                  </div>
                </div>
                <div className="form-group mt-2">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_same_location ? constLabel?.lbl_same_location : "Same Location"}
                  </label>
                  <div className="form-control">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="sameLocation"
                      name="sameLocation"
                      onChange={() => setSameLocation(!sameLocation)}
                      checked={sameLocation}
                    />
                    <label className="form-check-label form_label ms-2">
                      {" "}
                      {" Is Same location Required ?"}
                    </label>
                  </div>
                </div>

                <div className="form-group mt-2">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_remark ? constLabel?.lbl_remark : "Remark"}
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="remark"
                    name="remark"
                    placeholder="Enter remark"
                    {...register("remark")}
                  />
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <button
                onClick={handleSubmit(onSubmit)}
                className="f-16 btn btn-yellow "
              >
                {constLabel?.lbl_save ? constLabel?.lbl_save : "Save"}
              </button>
            </Modal.Footer>
          </Modal>

          {privilage.read && (
            <div id="mySidebar" className="customsidebar ">
              <a className="closebtn" onClick={() => closeNav()}>
                ×
              </a>

              <div className=" content">
                <div>
                  <label
                    className="filterLabel mb-1 roboto-bold"
                    style={{ fontSize: "14px" }}
                  >
                    {constLabel?.lbl_search ? constLabel.lbl_search : "Search"}
                  </label>
                  <input
                    className="form-control form-control-sm"
                    type="text"
                    placeholder="Search by name"
                    value={filterConfig.challanType}
                    onChange={(val) => {
                      const inputValue = val.target.value;
                      if (!inputValue.trim()) {
                        // Input contains only whitespace, don't trigger the search.
                        if (filterConfig.challanType.length == 1) {
                          setFilterConfig({ ...filterConfig, challanType: "" });
                        }
                      } else {
                        setFilterConfig({
                          ...filterConfig,
                          challanType: inputValue,
                        });
                      }
                    }}
                  />
                </div>
                <div className="my-3 d-flex justify-content-end">
                  <button
                    onClick={() => clearFilter()}
                    className="btn btn-sm btn-yellow "
                  >
                    <FontAwesomeIcon
                      style={{ color: "#344454" }}
                      icon={faBan}
                      size="sm"
                    />{" "}
                    {constLabel?.lbl_clear_filter
                      ? constLabel.lbl_clear_filter
                      : "Clear Filter"}
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start align-items-center col-12 col-md-6">
                    {/* <FontAwesomeIcon
                      onClick={() => toggleNav()}
                      className="cursor_pointer mb-2"
                      style={{
                        color: "Black",
                        marginRight: "10px",
                      }}
                      icon={faBars}
                      size="lg"
                    /> */}
                    <h1 className="page-heading">
                      {constLabel?.lbl_challan_type
                        ? constLabel?.lbl_challan_type
                        : "Challan Type"}
                    </h1>
                  </div>
                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                    <div className="col-12 col-md-6 me-md-2 me-md-0">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search by challan type"
                        value={filterConfig.challanType}
                        onChange={(val) => {
                          const inputValue = val.target.value;
                          if (!inputValue.trim()) {
                            // Input contains only whitespace, don't trigger the search.
                            if (filterConfig.challanType.length == 1) {
                              setFilterConfig({ ...filterConfig, challanType: "" });
                            }
                          } else {
                            setFilterConfig({
                              ...filterConfig,
                              challanType: inputValue,
                            });
                          }
                        }}
                      />
                    </div>
                    {privilage.write && (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => {
                          setVisible(true);
                          reset();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_add_type
                          ? constLabel?.lbl_add_type
                          : "Add Type"}
                      </button>
                    )}

                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img src={List} height='32' width='32' className="cursor_pointer" />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {dataList.loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : dataList && dataList.data?.length > 0 ? (
                    <>
                      <div className="">
                        <table
                          className="table table-responsive table-bordered"
                          width={"100%"}
                        >
                          <thead className="table-grey roboto">
                            <tr className="">
                              <th scope="col" width="5%">
                                {"Sr.No"}
                              </th>
                              {showLabel.map((v, i) =>
                                v.label === "Name" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_name
                                      ? constLabel?.lbl_name
                                      : "Name"}
                                  </th>
                                ) : v.label === "Challan Type" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_challan_type
                                      ? constLabel?.lbl_challan_type
                                      : "Challan Type"}
                                  </th>
                                ) : v.label === "ID Prefix" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_id_prefix
                                      ? constLabel?.lbl_id_prefix
                                      : "ID Prefix"}
                                  </th>
                                ) : v.label === "Out Operation" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_out_operation
                                      ? constLabel?.lbl_out_operation
                                      : "Out Operation"}
                                  </th>
                                ) : v.label === "Same Location" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_same_location
                                      ? constLabel?.lbl_same_location
                                      : "Same Location"}
                                  </th>
                                ) : v.label === "WIP" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_wip
                                      ? constLabel?.lbl_wip
                                      : "WIP"}
                                  </th>
                                ) : v.label === "Remark" && v.flag ? (
                                  <th key={i} scope="col">
                                    {constLabel?.lbl_remark
                                      ? constLabel?.lbl_remark
                                      : "Remark"}
                                  </th>
                                ) : null
                              )}
                              <th scope="col" width="15%">
                                {"Action"}
                              </th>
                            </tr>
                          </thead>
                          <tbody className="roboto">
                            {dataList.data?.map((val, i) => (
                              <tr key={i} className="">
                                {/* <td>{val.NCReasonId}</td> */}
                                <td>{i + parseInt(srNo) + 1}</td>
                                {showLabel.map((item, idx) =>
                                  item.label === "Name" && item.flag ? (
                                    <td key={idx}>
                                      {val.typename !== undefined &&
                                        val.typename !== null &&
                                        val.typename != ""
                                        ? val.typename
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Challan Type" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      {val.cType !== undefined &&
                                        val.cType !== null &&
                                        val.cType != ""
                                        ? val.cType
                                        : "NA"}
                                    </td>
                                  ) : item.label === "ID Prefix" && item.flag ? (
                                    <td key={idx}>
                                      {val.idprefix !== undefined &&
                                        val.idprefix !== null &&
                                        val.idprefix !== ""
                                        ? val.idprefix
                                        : "NA"}
                                    </td>
                                  ) : item.label === "Out Operation" &&
                                    item.flag ? (
                                    <td key={idx}>
                                      <input
                                        className="form-check-input"
                                        type="checkbox"
                                        style={{ width: "20px", height: "20px" }}
                                        checked={
                                          (val.outoperation !== undefined &&
                                            val.outoperation !== null &&
                                            val.outoperation !== false) ? true : false
                                        }
                                        disabled
                                      />
                                    </td>
                                  )
                                    : item.label === "Same Location" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          style={{ width: "20px", height: "20px" }}
                                          checked={
                                            (val.sameLocation !== undefined &&
                                              val.sameLocation !== null &&
                                              val.sameLocation !== false) ? true : false
                                          }
                                          disabled
                                        />
                                      </td>
                                    ) : item.label === "WIP" &&
                                      item.flag ? (
                                      <td key={idx}>
                                        <input
                                          className="form-check-input"
                                          type="checkbox"
                                          style={{ width: "20px", height: "20px" }}
                                          checked={
                                            (val.wip !== undefined &&
                                              val.wip !== null &&
                                              val.wip !== false) ? true : false
                                          }
                                          disabled
                                        />
                                      </td>
                                    ) : item.label === "Remark" && item.flag ? (
                                      <td key={idx}>
                                        {val.remark !== undefined &&
                                          val.remark !== null &&
                                          val.remark !== ""
                                          ? val.remark
                                          : "NA"}
                                      </td>
                                    ) : null
                                )}
                                <td>
                                  {privilage.write && (
                                    <EditChallanType
                                      data={val}
                                      constLabel={constLabel}
                                      fetchChallanType={fetchChallanType}
                                      page={page}
                                      entriesPerPage={entriesPerPage}
                                    />
                                  )}

                                  {privilage.delete && (
                                    <>
                                      <Tooltip id={"delete-tooltip" + i} place="top" />
                                      <Trash2
                                        data-tooltip-id={"delete-tooltip" + i}
                                        data-tooltip-content={
                                          constLabel?.lbl_delete_type
                                            ? constLabel?.lbl_delete_type
                                            : "Delete Type"
                                        }
                                        className="menu_icon_red cursor_pointer"
                                        size={20}
                                        onClick={() => delChallan_Type(val)}
                                      />
                                    </>
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-2">
                        <p className="mb-0">
                          Show
                          <select
                            className="mx-1"
                            defaultValue={entriesPerPage}
                            onChange={(e) => {
                              setEntriesPerPage(e.target.value);
                              fetchChallanType(1, e.target.value);
                            }}
                          >
                            <option value={"10"}>10</option>
                            <option value="25">25</option>
                            <option value="50">50</option>
                            <option value="100">100</option>
                          </select>
                          Entries
                        </p>
                        <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                          } to ${Math.min(
                            parseInt(entriesPerPage) + parseInt(srNo),
                            dataList.totalRecords
                          )} of ${dataList.totalRecords} entries`}</p>
                        <Pagination className="my-2">
                          <Pagination.Prev
                            disabled={page === 1 ? true : false}
                            onClick={() => {
                              fetchChallanType(
                                page - 1,
                                entriesPerPage,
                                parseInt(srNo) - parseInt(entriesPerPage)
                              );
                              setSrNo((prevC) =>
                                page - 1 == 1
                                  ? 0
                                  : prevC - parseInt(entriesPerPage)
                              );
                              setPage(page - 1);
                            }}
                          >
                            {"Prev"}
                          </Pagination.Prev>

                          <Pagination.Item active>{page}</Pagination.Item>

                          <Pagination.Next
                            disabled={
                              page === maxPage || maxPage === 0 ? true : false
                            }
                            onClick={() => {
                              fetchChallanType(
                                page + 1,
                                entriesPerPage,
                                parseInt(srNo) + parseInt(entriesPerPage)
                              );
                              setSrNo(
                                (prevC) => prevC + parseInt(entriesPerPage)
                              );
                              setPage(page + 1);
                            }}
                          >
                            {"Next"}
                          </Pagination.Next>
                        </Pagination>
                      </div>
                    </>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"Challan type data not found !"}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default ChallanType;

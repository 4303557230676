import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faAdd,
    faEye,
    faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import {
    convertDateYYMMDD,
    getLabels, formatDateString4, formatDateString,
    getShortReadableId
} from "../../common/Functions/CommonFunctions";
import {
    getProductionData_Accumalation,
} from "../../api/index";


function ViewProductionData() {

    let navigate = useNavigate();
    let location = useLocation();

    const locationData = location.state;


    const [constLabel, setConstLabel] = useState({});
    const [productionData, setProductionData] = useState({ loading: true, data: [] });
    const [activeCard, setActiveCard] = useState(0);
    const [err, setErr] = useState(null);

    useEffect(() => {
        setConstLabel(getLabels('Production', 'Production Data'));



        getProductionData_Accumalation({
            product_id: locationData.product_id.uuid,
            operation_id: locationData.operation_id.uuid,
            workorder_id: locationData.work_order_id
        }).then((res) => {
            console.log("res", res);
            setProductionData({ ...productionData, loading: false, data: res.data });
        }, (err) => {
            console.log("err", err);
            setErr(err.response.data.message);
            // toast.error(err.response.data.message, {
            //     autoClose: 2000,
            // });
            setProductionData({ ...productionData, loading: false, data: [] })
        })
    }, []);


    return (
        <React.Fragment>
            <ToastContainer />

            <div className="view_production_data mb-4">
                <div className="card">
                    <div className="card-header bg-grey d-flex align-items-center">
                        <button
                            className="btn btn-yellow"
                            onClick={() => navigate(-1)}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} size="sm" />
                        </button>

                        <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
                            {constLabel?.lbl_view_production_data ? constLabel.lbl_view_production_data : "Production Data Details"}
                        </p>
                    </div>
                    <div className="card-body">
                        {productionData.loading ?
                            <div className="text-center">
                                <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>

                            :
                            <div className="row">
                                {productionData.data.data !== undefined ?
                                    <>
                                        <div className="col-12 col-md-4">
                                            <div class="timeline">
                                                <ul>
                                                    {productionData.data?.data?.map((val, ind) => (
                                                        <li key={val.uuid}>
                                                            {/* <div className="card active shadow-sm"> */}
                                                            <div className={`card ${ind == activeCard ? "active shadow-sm" : ""}`}
                                                                onClick={() => {
                                                                    if (ind !== activeCard) {
                                                                        const ele = document.getElementsByClassName("selected_prod_data");
                                                                        ele[0].classList.remove("slide-up");

                                                                        setTimeout(() => {
                                                                            ele[0].classList.add("slide-up");
                                                                        }, 100);

                                                                        setActiveCard(ind);
                                                                    }
                                                                }}>
                                                                <div className="card-body">
                                                                    <div class="content row">
                                                                        <div className="col-12 col-md-6">
                                                                            <p className="prod_data_label m-0">Workorder</p>
                                                                            <p className="roboto-bold mb-2 mt-0">{getShortReadableId(val.work_order_no)}</p>
                                                                        </div>
                                                                        <div className="col-12 col-md-6">
                                                                            <p className="prod_data_label m-0">Jobcard</p>
                                                                            <p className="roboto-bold mb-2 mt-0">{getShortReadableId(val.job_card_no)}</p>
                                                                        </div>
                                                                        <div className="col-12">
                                                                            <p className="prod_data_label m-0">Product</p>
                                                                            <p className="roboto-bold mb-2 mt-0">{val.productName}</p>
                                                                        </div>

                                                                        <div className="col-12 d-flex justify-content-between mt-1">
                                                                            <i><p className="prod_data_label m-0">Created at - {formatDateString(new Date(val.created_at))}</p></i>

                                                                            {ind !== activeCard ?
                                                                                <a className="prod_data_label" href="javascript:void(0)">{"More Details"}</a>
                                                                                : null}
                                                                        </div>


                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </li>
                                                    ))}



                                                    <div style={{ clear: "both" }}></div>
                                                </ul>
                                            </div>

                                        </div>

                                        <div className="col-12 col-md-8" style={{ marginTop: "14px" }}>
                                            <div className="d-flex" id="total_prod_qty">
                                                <i><span>{`Total Production Quantity - `}</span>
                                                    <span className="roboto-bold ms-1">{productionData.data.total_accumulated_qty}</span></i>
                                            </div>


                                            <div className="selected_prod_data slide-up mt-3 row p-3 me-3">
                                                <div className="col-12 col-md-3">
                                                    <p className="prod_data_label m-0">Part No</p>
                                                    <p className="roboto-bold mb-2 mt-0">{productionData.data.data[activeCard].part_no}</p>
                                                </div>
                                                <div className="col-12 col-md-3">
                                                    <p className="prod_data_label m-0">Lotsize</p>
                                                    <p className="roboto-bold mb-2 mt-0">{productionData.data.data[activeCard].lot_size}</p>
                                                </div>
                                                <div className="col-12 col-md-3">
                                                    <p className="prod_data_label m-0">Planned Quantity</p>
                                                    <p className="roboto-bold mb-2 mt-0">{productionData.data.data[activeCard].std_report_qty}</p>
                                                </div>
                                                <div className="col-12 col-md-3">
                                                    <p className="prod_data_label m-0">Produced Quantity</p>
                                                    <p className="roboto-bold mb-2 mt-0">{productionData.data.data[activeCard].produced_qty}</p>
                                                </div>

                                                <div className="col-12 col-md-3">
                                                    <p className="prod_data_label m-0">Machine Name</p>
                                                    <p className="roboto-bold mb-2 mt-0">
                                                        {productionData.data.data[activeCard].machineprod?.machineName} - {productionData.data.data[activeCard].machineprod?.serialNo}</p>
                                                </div>

                                                {/* <div className="col-12 col-md-3">
                                                    <p className="prod_data_label m-0">Machine Serial Number</p>
                                                    <p className="roboto-bold mb-2 mt-0"></p>
                                                </div> */}
                                                <div className="col-12 col-md-3">
                                                    <p className="prod_data_label m-0">Operation</p>
                                                    <p className="roboto-bold mb-2 mt-0">{productionData.data.data[activeCard].operation}</p>
                                                </div>


                                                <div className="col-12 col-md-3">
                                                    <p className="prod_data_label m-0">Total Working Hours</p>
                                                    <p className="roboto-bold mb-2 mt-0">{productionData.data.data[activeCard].totalworking_hours}</p>
                                                </div>
                                                <div className="col-12 col-md-3">
                                                    <p className="prod_data_label m-0">Operator Name</p>
                                                    <p className="roboto-bold mb-2 mt-0">
                                                        {productionData.data.data[activeCard].employeeprod.FirstName
                                                            + " " + productionData.data.data[activeCard].employeeprod.LastName}
                                                    </p>
                                                </div>

                                                <div className="col-12 col-md-3">
                                                    <p className="prod_data_label m-0">Start Time</p>
                                                    <p className="roboto-bold mb-2 mt-0">{
                                                        formatDateString4(productionData.data.data[activeCard].startDate_time)}
                                                    </p>
                                                </div>
                                                <div className="col-12 col-md-3">
                                                    <p className="prod_data_label m-0">End Time</p>
                                                    <p className="roboto-bold mb-2 mt-0">{
                                                        formatDateString4(productionData.data.data[activeCard].endDate_time)}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </> :

                                    <div className="col-12 px-2">
                                        <div
                                            className="alert alert-danger text-center"
                                            role="alert mb-0"
                                        >
                                            {err}
                                        </div>
                                    </div>
                                }
                            </div>
                        }
                    </div>

                    {/* <div className="card-footer py-3">
                        
                    </div> */}
                </div>
            </div>


        </React.Fragment>
    );
}

export default ViewProductionData;

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from "react-tooltip";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import {
  getLocation,
  getLoctionType,
  updateLocationMapping,
} from "../../../../api";

const schema = yup
  .object()
  .shape({
    locationName: yup
      .array()
      .required("Please select location !")
      .min(1, "Please select location !"),
    locationType: yup
      .array()
      .required("Please location type !")
      .min(1, "Please select location type !"),
    
  })
  .required();

function EditLocationMapping(props) {
  const [visible, setVisible] = useState(false);

  const [loc_name, setLoc_Name] = useState({ data: [], state: false });
  const [loc_type, setLoc_Type] = useState({ data: [], state: false });

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    if (visible) {
      fetchLocations();
      fetchLocationType();
    }
  }, [visible]);

  function fetchLocationType() {
    setLoc_Type({ ...loc_type, state: true, data: [] });
    getLoctionType().then(
      (res) => {
        
        setLoc_Type({...loc_type, state: false, data: res.data.data,
        });
      },
      (err) => {
        //
        setLoc_Type({ ...loc_type, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  function fetchLocations() {
    setLoc_Name({ ...loc_name, state: true, data: [] });
    getLocation().then(
      (res) => {
       
        setLoc_Name({...loc_name, state: false, data: res.data.data});
      },
      (err) => {
        //
        setLoc_Name({ ...loc_name, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  const onSubmit = (data) => {
    

    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        typename: data.locationType[0]?.typename,
        location: data.locationName[0]?.locationname,
      };

      

      updateLocationMapping(reqPayload).then(
          (res) => {
            
            if (res.status === 200 || res.status === 201) {
              toast.success("Location mapping updated successfully !", {
                autoClose: 3000,
              });
              setVisible(false);
              props.fetchLocationMapping();
            }
          },
          (err) => {
            
            if (err.response.status === 400) {
              
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
              // reset();
            }
          }
        )
        .catch((error) => {
          
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
        <div
          data-tooltip-id={"edit-tooltip"}
          data-tooltip-content={props.constLabel?.lbl_edit_mapping ? props.constLabel.lbl_edit_mapping : "Edit Mapping"}
          className="editIcon me-2">
          <Edit2
            color="#FFFFFF"
            size={18}
            className="cursor-pointer"
            onClick={() => {
              // setUpdateForm(val)
              setVisible(true);
            }}
          />
        </div>
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{props.constLabel?.lbl_update_location_mapping ? props.constLabel.lbl_update_location_mapping : "Update Location Mapping"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-md-12 roboto-bold">
                {props.constLabel?.lbl_location_type ? props.constLabel.lbl_location_type : "Location Type"}
                <span>
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-12">
                <Controller
                  name="locationType"
                  control={control}
                  defaultValue={props.data !== undefined ? [{typename: props.data?.typename}] : []}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      defaultSelected={props.data !== undefined ? [{typename: props.data?.typename}] : []}
                      labelKey="typename"
                   
                      multiple={false}
                      options={loc_type.data !== undefined ? loc_type.data : []}
                      isLoading={loc_type.state}
                      placeholder="Select location type"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.locationType ? true : false}
                    />
                  )}
                />
                <span className="text-danger err-msg">
                  {errors.locationType?.message}
                </span>
              </div>
            </div>

            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-md-12 roboto-bold">
                {props.constLabel?.lbl_location_name ? props.constLabel.lbl_location_name : "Location Name"}
                <span>
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-12">
                <Controller
                  name="locationName"
                  control={control}
                  defaultValue={props.data !== undefined ? [{locationname: props.data.location}] : []}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      defaultSelected={props.data !== undefined ? [{locationname: props.data.location}] : []}
                      labelKey="locationname"
                 
                      multiple={false}
                      options={loc_name.data !== undefined ? loc_name.data : []}
                      isLoading={loc_name.state}
                      placeholder="Select location"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.locationName ? true : false}
                    />
                  )}
                />
                 <span className="text-danger err-msg">
                    {errors.locationName?.message}
                  </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditLocationMapping;

import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { getProdList, } from "../../../api/index";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getBOM } from "../../../api";

const schema = yup
    .object()
    .shape({
        product: yup.array().required(),
        // bom: yup.array().required(),
        plan_to_req_qty: yup.string().required('Plan to req qty is required')
            .test('is-positive-number', 'Value should be greater than 0', value => {
                // Check if the value is a valid number and greater than 0
                return !isNaN(value) && parseFloat(value) > 0;
            })
    })
    .required();


function AddProduct(props) {
    const { visible, setVisible, fetchSubBomItems, ignoreQty, subprodListWithBom } = props;
    const [disableBtn, setDisableBtn] = useState(false);
    const [prodList, setProdList] = useState({ data: [], loading: false });
    const [bomList, setBomList] = useState({ data: [], loading: false });

    const {
        register,
        handleSubmit,
        formState,
        control,
        reset,
        watch,
        setValue,
        getValues,
        setError,
        clearErrors,
    } = useForm({
        resolver: yupResolver(schema),
        defaultValues: { product: [], plan_to_req_qty: 0 },
        mode: "onChange"
    });

    const { errors } = formState;
    // const product = watch('product');

    useEffect(() => {
        fetchProdList();
    }, []);

    // useEffect(() => {
    //     if (product && product.length > 0) {
    //         console.log("product", product);
    //         fetchBom(product[0].uuid)
    //     }
    // }, [product]);

    function fetchBom(product_uuid) {
        setBomList({ ...bomList, data: [], loading: true });

        getBOM({ product_from_lineitem: product_uuid }).then((res) => {
            console.log("res", res);
            setBomList({ ...bomList, data: res.data.data, loading: false });

        }, (err) => {
            console.log("err", err);
            setBomList({ ...bomList, data: [], loading: false });
        })
    }

    function fetchProdList() {
        setProdList({ ...prodList, data: [], loading: true });

        getProdList().then(
            (res) => {
                let arr = res.data.data.filter(
                    (item) => item.productName !== null || item.productName !== undefined
                );
                setProdList({ ...prodList, data: arr, loading: false });
            },
            (err) => {
                setProdList({ ...prodList, data: [], loading: false });
            }
        );
    }



    const onSubmit = (data) => {
        console.log("data", data);
        // const product_to_append = { ...data.bom[0] };
        // product_to_append.lineItemDetails = data.bom[0].bom_line_item.find((item) =>
        //     item.product_id == data.product[0].uuid);
        // delete product_to_append.bom_line_item;
        const obj = {
            productId: data.product[0].productId,
            productType: data.product[0].product_type_id.productType,
            product_family_id_id: data.product[0].prod_family_id.uuid,
            product_id: data.product[0].uuid,
            product_type_id: data.product[0].product_type_id.uuid,
            productname: data.product[0].productName,
            qty: 0,
            qtyRequiredAsPerBom: 0,
            planToRequestQty: data.plan_to_req_qty
        }

        const product_to_append = { qty: 0, so_item_quantity: 0, addhoc_item: true };
        product_to_append.lineItemDetails = obj;

        console.log("product_to_append", product_to_append);
        console.log("visible", visible);

        const prodExist = visible.data.find((val) => val.lineItemDetails.product_id == obj.product_id);
        console.log("prodExist", prodExist);
        if (prodExist) {
            toast.error("Product already exist in bom items", {
                autoClose: 3000,
            });
            return
        }

        console.log("subprodListWithBom", subprodListWithBom);
        console.log("obj.product_id", obj.product_id);

        const prodExist2 = subprodListWithBom.data.filter((val) => {
            // if (val.product_id) {
            return val.lineItemDetails.product_id == obj.product_id
            // }
        });

        console.log("prodExist2", prodExist2);
        if (prodExist2.length > 0) {
            toast.error("Product already exist in bom items", {
                autoClose: 3000,
            });
            return
        }


        visible.state = false;
        visible.data.push(product_to_append);

        setVisible(visible);
        reset();
        fetchSubBomItems(ignoreQty);
    };

    return (
        <React.Fragment>
            <Modal show={visible.state} onHide={() => {
                setVisible({ state: false, data: [...visible.data] })
                reset();
            }} size={"md"}>
                <Modal.Header
                    style={{ background: "#2A3643", color: "white" }}
                    closeButton>
                    <Modal.Title>
                        {"Add Product"}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className="col-12 col-md-6">
                            <label
                                style={{ fontSize: "14px" }}
                                className="col-form-label roboto-bold"
                            >
                                {"Product "}
                                <span className="text-danger">*</span>
                            </label>

                            <div className="">
                                <Controller
                                    name={`product`}
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="productName"
                                            multiple={false}
                                            options={prodList.data}
                                            isLoading={prodList.loading}
                                            placeholder="Select Product"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.product ? true : false}
                                        />
                                    )}
                                />
                                {errors.product && (
                                    <span className="text-danger err-msg">
                                        {errors.product.message}
                                    </span>
                                )}
                            </div>
                        </div>


                        {/* <div className="col-12 col-md-6">
                            <label
                                style={{ fontSize: "14px" }}
                                className="col-form-label roboto-bold"
                            >
                                {"BOM "}
                                <span className="text-danger">*</span>
                            </label>

                            <div className="">
                                <Controller
                                    name={`bom`}
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="bomId"
                                            multiple={false}
                                            options={bomList.data}
                                            isLoading={bomList.loading}
                                            placeholder="Select BOM"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.bom ? true : false}
                                            disabled={product == null || product == undefined}
                                        />
                                    )}
                                />
                                {errors.bom && (
                                    <span className="text-danger err-msg">
                                        {errors.bom.message}
                                    </span>
                                )}
                            </div>
                        </div> */}


                        <div className="col-12 col-md-6">
                            <label
                                style={{ fontSize: "14px" }}
                                className="col-form-label roboto-bold"
                            >
                                {"Plan to request qty"}
                                <span className="text-danger">*</span>
                            </label>

                            <div className="">
                                <input
                                    className={
                                        errors.plan_to_req_qty
                                            ? "form-control is-invalid"
                                            : "form-control"
                                    }
                                    type="text"
                                    name={`plan_to_req_qty`}
                                    {...register(`plan_to_req_qty`)}
                                    // onChange={(e) => handleFileChange(e, index)}
                                    placeholder="Enter quantity"
                                />

                                {errors.plan_to_req_qty && (
                                    <span className="text-danger err-msg">
                                        {errors.plan_to_req_qty.message}
                                    </span>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={handleSubmit(onSubmit)}
                        className="f-16 btn btn-yellow px-3"
                        disabled={disableBtn}
                    >
                        {"Save"}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default AddProduct
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Modal, Spinner } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { getLoctionType, getInternalCompany, saveLocation, updateLocation } from "../../../api/index";

const schema = yup
    .object()
    .shape({
        locationType: yup
            .array()
            .required("Location type name is required !")
            .min(1, "Please select location type"),
        locationname: yup
            .string()
            .required("Location name is required !")
            .matches(/^[a-zA-Z0-9 ]*$/, "Enter valid location name"),
        locationaddress: yup
            .string()
            .required("Location address is required !"),
    })
    .required();


function EditLocation({ locationModal2, setLocationModal2, selLoc, company_data, handleClick }) {
    const [loc_Type, setLoc_Type] = useState({ data: [], state: false });
    const [internalCompList, setInternalComp] = useState({ data: [], loading: false });

    const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    }); // initialise the hook

    let { errors } = formState;

    useEffect(() => {
        
        if (locationModal2) {
            
            fetchLoctionType();
            fetchInternalComp();
            setValue("company", [{ uuid: selLoc.internal_company_id, comName: selLoc.locationname }]);
            setValue("locationType", [{ uuid: selLoc.location_type_id.uuid, typename: selLoc.locationtype }]);
            setValue("locationname", selLoc.locationname);
            setValue("locationaddress", selLoc.locationaddress);
        }
    }, [locationModal2])


    function fetchLoctionType() {
        setLoc_Type({ ...loc_Type, data: [], state: true });

        getLoctionType().then((res) => {
            
            setLoc_Type({ ...loc_Type, data: res.data.data, state: false });
        }, (err) => {
            
            setLoc_Type({ ...loc_Type, data: [], state: false });
        })
    }


    function fetchInternalComp() {
        setInternalComp({ ...internalCompList, data: [], loading: true });

        getInternalCompany().then(
            (res) => {
                
                setInternalComp({
                    ...internalCompList,
                    data: res.data.data,
                    loading: false,
                });
            },
            (err) => {
                
                setInternalComp({ ...internalCompList, data: [], loading: false });
            }
        );
    }


    const onSubmit = (data) => {
        

        if (data !== "") {
            const reqPayload = {
                uuid: selLoc?.uuid,
                locationId: selLoc.locationId,
                locationtype: data.locationType[0]?.typename,
                location_type_id: data.locationType[0]?.uuid,
                locationname: data.locationname,
                locationaddress: data.locationaddress,
                // partycode: data.party[0]?.partyCode,
                internal_company_id: data.company[0]?.uuid,
            };

            

            updateLocation(reqPayload)
                .then(
                    (res) => {
                        
                        if (res.status === 200 || res.status === 201) {
                            toast.success("Location updated Successfully !", {
                                autoClose: 3000,
                            });
                            setLocationModal2(false);
                            handleClick(company_data);
                        }
                    },
                    (err) => {
                        
                        if (err.response.status === 400) {
                            
                            toast.error(err.response.data.message, {
                                autoClose: 2000,
                            });
                            reset();
                        }
                    }
                )
        } else {
            errors.showMessages();
        }
    };

    return (
        <React.Fragment>
            <ToastContainer />
            <Modal show={locationModal2} onHide={() => setLocationModal2(false)} size={"md"} backdrop="static">
                <Modal.Header
                    style={{ background: "#2A3643", color: "white" }}
                    closeButton
                >
                    <Modal.Title>{"Update Location"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        <div className="form-group row mt-2">
                            <label
                                style={{ fontSize: "14px" }}
                                className="col-form-label col-md-12 roboto-bold"
                            >
                                Organisation<span>
                                    <i style={{ color: "red" }}>*</i>
                                </span>
                            </label>
                            <div className="col-md-12">
                                <Controller
                                    name="company"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="comName"
                                            multiple={false}
                                         
                                            options={internalCompList.data !== undefined ? internalCompList.data : []}
                                            isLoading={internalCompList.loading}
                                            placeholder="Select organisation"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.company ? true : false}
                                            disabled
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className="form-group row mt-2">
                            <label style={{ fontSize: "14px" }} className="col-form-label col-md-12 roboto-bold">
                                Type
                                <span>
                                    <i style={{ color: "red" }}>*</i>
                                </span>
                            </label>
                            <div className="col-md-12">
                                <Controller
                                    name="locationType"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="typename"
                                            
                                            multiple={false}
                                            options={loc_Type.data !== undefined ? loc_Type.data : []}
                                            isLoading={loc_Type.state}
                                            placeholder="Select location type"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.locationType ? true : false}
                                        />
                                    )}
                                />
                                <span className="text-danger err-msg">
                                    {errors.locationType?.message}
                                </span>
                            </div>
                        </div>

                        <div className="form-group row mt-2">
                            <label style={{ fontSize: "14px" }} className="col-form-label col-md-12 roboto-bold">
                                Name
                                <span>
                                    <i style={{ color: "red" }}>*</i>
                                </span>
                            </label>
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className={errors.locationname ? "form-control  is-invalid"
                                        : "form-control "}
                                    id="locationname"
                                    name="locationname"
                                    placeholder="Enter location name"
                                    {...register("locationname")}
                                />
                                <span className="text-danger err-msg">
                                    {errors.locationname?.message}
                                </span>
                            </div>
                        </div>

                        <div className="form-group row mt-2">
                            <label style={{ fontSize: "14px" }} className="col-form-label col-md-12 roboto-bold">
                                Address
                                <span>
                                    <i style={{ color: "red" }}>*</i>
                                </span>
                            </label>
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className={errors.locationaddress ? "form-control  is-invalid"
                                        : "form-control "}
                                    id="locationaddress"
                                    name="locationaddress"
                                    placeholder="Enter address"
                                    {...register("locationaddress")}
                                />
                                <span className="text-danger err-msg">
                                    {errors.locationaddress?.message}
                                </span>
                            </div>
                        </div>


                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={handleSubmit(onSubmit)}
                        className="btn btn-yellow px-3"
                    >
                        {"Save Changes"}
                    </button>
                    <button
                        onClick={() => setLocationModal2(false)}
                        className="btn btn-grey px-3"
                    >
                        {"Cancel"}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default EditLocation
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {
    getProdCategory,
    getCustomer,
    getProduct_Family,
    createRawMaterialProduct, getUOM
} from "../../../../api";

const schema = yup.object().shape({
    unit: yup
        .array()
        .min(1, "Please select unit")
        .required("Product unit is required"),
    hsn: yup
        .string()
        .matches(/^[a-zA-Z0-9]*$/, "Enter valid HSN")
        .nullable(true),
    openingStock: yup
        .string()
        .matches(/^[0-9]+$/, 'Enter valid opening stock')
        .nullable(true),
    diameter: yup
        .string()
        .matches(/^\d+(\.\d+)?$/, "Enter valid diameter")
        .nullable(true),
    cutLength: yup
        .string()
        .matches(/^\d+(\.\d+)?$/, "Enter valid cut length")
        .nullable(true),
    cutWeight: yup
        .string()
        .matches(/^\d+(\.\d+)?$/, "Enter valid cut weight")
        .nullable(true),
    productName: yup
        .string()
        .required("Product name is required"),
    machineCost: yup
        .string()
        .required("Cost is required !")
        .matches(/^\d+(\.\d+)?$/, "Enter valid cost"),
    selling_price: yup
        .string()
        .required("Selling price is required !")
        .matches(/^\d+(\.\d+)?$/, "Enter valid selling price"),
    purchase_price: yup
        .string()
        .required("Purchase price is required !")
        .matches(/^\d+(\.\d+)?$/, "Enter valid purchase price"),
    can_be_sold: yup
        .string()
        .required("Required"),
    can_be_purchased: yup
        .string()
        .required("Required"),
    can_be_expensed: yup
        .string()
        .required("Required"),

})
    .required();

function CloneEditRawMaterial(props) {
    let navigate = useNavigate();

    const [prodCategory, setProdCategory] = useState({ data: [], loading: false });
    const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
    const [partyList, setPartyList] = useState({ data: [], loading: false });
    const [uomList, setUOMList] = useState({ data: [], loading: false });
    const [btnDisable, setBtnDisable] = useState(false);

    const { register, handleSubmit, formState, control, reset, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    }); // initialise the hook

    let { errors } = formState;

    useEffect(() => {

        
        fetchProductCategory();
        fetchProductFamily();
        fetchParty();
        fetchUOM();
    }, []);


    function fetchUOM() {
        setUOMList({ ...uomList, data: [], loading: true });
        getUOM({pageNo:1,entriesPerPage:100}).then(
            (res) => {
                
                setUOMList({ ...uomList, data: res.data.data, loading: false });
            },
            (err) => {
                
                setUOMList({ ...uomList, data: [], loading: false });
            }
        );
    }

    function fetchProductCategory() {
        setProdCategory({ ...prodCategory, data: [], loading: true });
        getProdCategory().then(
            (res) => {
                
                setProdCategory({
                    ...prodCategory,
                    data: res.data.data,
                    loading: false,
                });
            },
            (err) => {
                
                setProdCategory({ ...prodCategory, data: [], loading: false });
            }
        );
    }

    function fetchProductFamily() {
        setProdFamily({ ...prodFamily, data: [], loading: true });
        getProduct_Family().then(
            (res) => {
                
                setProdFamily({
                    ...prodFamily,
                    data: res.data.data,
                    loading: false,
                });
            },
            (err) => {
                
                setProdFamily({ ...prodFamily, data: [], loading: false });
            }
        );
    }

    function fetchParty() {
        setPartyList({ ...partyList, data: [], loading: true });
        getCustomer({partyType: "Customer"}).then(
            (res) => {
                
                const result = res.data.data.filter((item) => item.name !== null);
                setPartyList({ ...partyList, data: result, loading: false });
            },
            (err) => {
                
                setPartyList({ ...partyList, data: [], loading: false });
            }
        );
    }

    // const onSubmit = (data) => {
    //   props.setCurrentStep(props.currentStep + 1);
    // };

    const onSubmit = (data) => {
        
        setBtnDisable(true);
        if (data !== "") {
            const reqPayload = {
                productType: props.product_type[0]?.prodTypeId,
                productTypename: props.product_type[0]?.productType,
                product_type_id: props.product_type[0]?.uuid,
                category: data.category ? data.category[0]?.categoryname : "",
                prodfamily: data.productFamily ? data.productFamily[0]?.familyId : "",
                prod_family_id: data.productFamily ? data.productFamily[0]?.uuid : "",
                productName: data.productName,
                openingstock: data.openingStock,
                hsn: data.hsn,

                material: data.material,
                diameter: data.diameter,
                cutlength: data.cutLength,
                cutweight: data.cutWeight,

                // partNo: data.partNo,
                machineCost: data.machineCost,
                can_be_sold: data.can_be_sold,
                can_be_purchased: data.can_be_purchased,
                can_be_expensed: data.can_be_expensed,
                beacon_id: data.beacon_id,
                qr_code_id: data.qr_code_id,
                selling_price: data.selling_price,
                purchase_price: data.purchase_price,
                unit_id: data.unit ? data.unit[0]?.uuid : "",
                remark: data.remark,
                party: []

            };

            

            createRawMaterialProduct(reqPayload).then(
                (res) => {
                    setBtnDisable(false);
                    
                    if (res.status === 200 || res.status === 201) {
                        toast.success("Raw material product saved successfully !", {
                            autoClose: 3000,
                        });
                        props.setProductDataLocal(res.data);
                        localStorage.setItem(
                            "prod_res",
                            JSON.stringify(res.data)
                        );

                        props.setCurrentStep((prevStep) => prevStep + 1);

                    }
                },
                (err) => {
                    setBtnDisable(false);
                    
                    if (err.response.status === 400) {
                        
                        toast.error(err.response.data.non_field_errors[0], {
                            autoClose: 2000,
                        });
                        reset();
                    }
                }
            )
        } else {
            errors.showMessages();
        }
    };

    return (
        <React.Fragment>
            <ToastContainer />

            <div className="card shadow-sm rounded-0 p-2">
                <div className="card-body">
                    <p className="pc_card_title roboto-bold">Product Summary</p>
                    <div className="row">
                        <div className="col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label className="lbl-style roboto-bold">
                                    {"Category"}
                                </label>
                                <Controller
                                    name="category"
                                    control={control}
                                    defaultValue={props.data?.category !== null ? [{ categoryname: props.data?.category }] : []}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            defaultSelected={props.data?.category !== null ? [{ categoryname: props.data?.category }] : []}
                                            labelKey="categoryname"
                                            className="mt-2"
                                            multiple={false}
                                            placeholder="Select product category"
                                            options={prodCategory.data}
                                            isLoading={prodCategory.loading}
                                            onChange={onChange}
                                            selected={value}
                                            onBlur={onBlur}
                                        />
                                    )}
                                />
                            </div>
                        </div>


                        <div className="col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label className="lbl-style roboto-bold">
                                    {"Product Family"}
                                </label>
                                <Controller
                                    name="productFamily"
                                    control={control}
                                    defaultValue={props.data.prod_family_id !== null ? [props.data.prod_family_id] : []}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            defaultSelected={props.data.prod_family_id !== null ? [props.data.prod_family_id] : []}
                                            labelKey="familyname"
                                            className="mt-2"
                                            multiple={false}
                                            options={prodFamily.data}
                                            isLoading={prodFamily.loading}
                                            placeholder="Select product family"
                                            onChange={onChange}
                                            selected={value}
                                            onBlur={onBlur}
                                        />
                                    )}
                                />
                            </div>
                        </div>


                        <div className="col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label className="lbl-style roboto-bold">
                                    {"Product Name"}
                                    <span className="text-danger">*</span>
                                </label>
                                <input
                                    className={
                                        errors.productName
                                            ? "form-control mt-2 is-invalid"
                                            : "form-control mt-2"
                                    }
                                    type="text"
                                    defaultValue={props.data?.productName !== null ? props.data?.productName : ""}
                                    name="productName"
                                    placeholder="Enter product name"
                                    {...register("productName")}
                                />
                                {errors.productName && (
                                    <span className="text-danger err-msg">
                                        {errors.productName.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label className="lbl-style roboto-bold">{"Opening Stock"}</label>
                                <input
                                    className="form-control mt-2"
                                    type="text"
                                    defaultValue={props.data?.openingstock !== null ? props.data?.openingstock : 0}
                                    name="openingStock"
                                    placeholder="Enter opening stock"
                                    {...register("openingStock")}
                                />
                                {errors.openingStock && (
                                    <span className="text-danger err-msg">
                                        {errors.openingStock.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-md-3 mt-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label className="lbl-style roboto-bold">{"HSN"}</label>
                                <input
                                    className="form-control mt-2"
                                    type="text"
                                    defaultValue={props.data?.hsn !== null ? props.data?.hsn : ""}
                                    name="hsn"
                                    placeholder="Enter hsn"
                                    {...register("hsn")}
                                />
                                {errors.hsn && (
                                    <span className="text-danger err-msg">
                                        {errors.hsn.message}
                                    </span>
                                )}
                            </div>
                        </div>



                        <div className="col-md-3 mt-3">
                            <div className="form-group mt-md-0 mt-3">
                                <label className="lbl-style roboto-bold">
                                    {"Unit"}
                                    <span className="text-danger">*</span>
                                </label>
                                <Controller
                                    name="unit"
                                    control={control}
                                    defaultValue={props.data.unit_id !== null ? [props.data.unit_id] : []}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            defaultSelected={props.data.unit_id !== null ? [props.data.unit_id] : []}
                                            className="mt-2"
                                            labelKey="uom"
                                            multiple={false}
                                            options={uomList.data}
                                            isLoading={uomList.loading}
                                            placeholder="Select unit"
                                            onChange={onChange}
                                            selected={value}
                                            onBlur={onBlur}
                                            isInvalid={errors.unit ? true : false}
                                        />
                                    )}
                                />

                                {errors.unit && (
                                    <span className="text-danger err-msg">
                                        {errors.unit.message}
                                    </span>
                                )}
                            </div>
                        </div>

                    </div>

                    <hr className="my-4" />
                    <p className="pc_card_title roboto-bold">Product Details</p>
                    <div className="row col-12">



                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="lbl-style roboto-bold">{"Material Name"}</label>
                                <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter material name"
                                    defaultValue={props.data?.material !== null ? props.data?.material : ""}
                                    name="material"
                                    {...register("material")}
                                />
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="lbl-style roboto-bold">{"Diameter"}</label>
                                <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter diameter"
                                    defaultValue={props.data?.diameter !== null ? props.data.diameter : 0}
                                    name="diameter"
                                    {...register("diameter")}
                                />
                                {errors.diameter && (
                                    <span className="text-danger err-msg">
                                        {errors.diameter.message}
                                    </span>
                                )}
                            </div>
                        </div>


                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="lbl-style roboto-bold">{"Cut Length"}</label>
                                <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter cut length"
                                    defaultValue={props.data?.cutlength !== null ? props.data.cutlength : 0}
                                    name="cutLength"
                                    {...register("cutLength")}
                                />
                                {errors.cutLength && (
                                    <span className="text-danger err-msg">
                                        {errors.cutLength.message}
                                    </span>
                                )}
                            </div>
                        </div>


                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="lbl-style roboto-bold">{"Cut Weight"}</label>
                                <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter cut weight"
                                    defaultValue={props.data?.cutweight !== null ? props.data.cutweight : 0}
                                    name="cutWeight"
                                    {...register("cutWeight")}
                                />
                                {errors.cutWeight && (
                                    <span className="text-danger err-msg">
                                        {errors.cutWeight.message}
                                    </span>
                                )}
                            </div>
                        </div>


                        <div className="col-md-3 mt-3">
                            <div className="form-group">
                                <label className="lbl-style roboto-bold">{"Beacon Id"}</label>
                                <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter beacon id"
                                    defaultValue={props.data?.beacon_id !== null ? props.data?.beacon_id : ""}
                                    name="beacon_id"
                                    {...register("beacon_id")}
                                />
                            </div>
                        </div>

                        <div className="col-md-3 mt-3">
                            <div className="form-group">
                                <label className="lbl-style roboto-bold">{"QR Code Id"}</label>
                                <input
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter qr code id"
                                    defaultValue={props.data?.qr_code_id !== null ? props.data?.qr_code_id : ""}
                                    name="qr_code_id"
                                    {...register("qr_code_id")}
                                />
                            </div>
                        </div>



                    </div>
                    <div className="row">

                        <div className="col-md-3 mt-3">
                            <label className="lbl-style roboto-bold invisible">
                                {"Can be sold"}
                            </label>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="can_be_sold"
                                    defaultChecked={props.data.can_be_sold !== null ? props.data.can_be_sold : false}
                                    {...register("can_be_sold")}
                                />
                                <label className="form-check-label lbl-style roboto-bold">
                                    Can be Sold
                                </label>
                            </div>
                        </div>

                        <div className="col-md-3 mt-3">
                            <label className="lbl-style roboto-bold invisible">
                                {"Can be Purchase"}
                            </label>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="can_be_purchased"
                                    defaultChecked={props.data.can_be_purchased !== null ? props.data.can_be_purchased : false}
                                    {...register("can_be_purchased")}
                                />
                                <label className="form-check-label lbl-style roboto-bold">
                                    Can be Purchase
                                </label>
                            </div>
                        </div>

                        <div className="col-md-3 mt-3">
                            <label className="lbl-style roboto-bold invisible">
                                {"Can be Expense"}
                            </label>
                            <div className="form-check">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    name="can_be_expensed"
                                    defaultChecked={props.data.can_be_expensed !== null ? props.data.can_be_expensed : false}
                                    {...register("can_be_expensed")}
                                />
                                <label className="form-check-label lbl-style roboto-bold">
                                    Can be Expense
                                </label>
                            </div>
                        </div>
                    </div>

                    <hr className="my-4" />
                    <p className="pc_card_title roboto-bold">Product Price</p>
                    <div className="row col-12">
                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="lbl-style roboto-bold">
                                    {"Cost"}
                                    <span className="text-danger">*</span>
                                </label>
                                <input
                                    className={
                                        errors.machineCost
                                            ? "form-control mt-2 is-invalid"
                                            : "form-control mt-2"
                                    }
                                    type="text"
                                    defaultValue={props.data?.machineCost !== null ? props.data.machineCost : 0.0}
                                    name="machineCost"
                                    placeholder="Enter cost"
                                    {...register("machineCost")}
                                />
                                {errors.machineCost && (
                                    <span className="text-danger err-msg">
                                        {errors.machineCost.message}
                                    </span>
                                )}
                            </div>
                        </div>




                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="lbl-style roboto-bold">{"Selling Price"}
                                    <span className="text-danger">*</span></label>
                                <input
                                    className={
                                        errors.selling_price
                                            ? "form-control mt-2 is-invalid"
                                            : "form-control mt-2"
                                    }
                                    type="text"
                                    name="selling_price"
                                    placeholder="Enter selling price"
                                    defaultValue={props.data?.selling_price !== null ? props.data.selling_price : 0.0}
                                    {...register("selling_price")}
                                />
                                {errors.selling_price && (
                                    <span className="text-danger err-msg">
                                        {errors.selling_price.message}
                                    </span>
                                )}
                            </div>
                        </div>



                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="lbl-style roboto-bold">{"Purchase Price"}
                                    <span className="text-danger">*</span></label>
                                <input
                                    className={
                                        errors.purchase_price
                                            ? "form-control mt-2 is-invalid"
                                            : "form-control mt-2"
                                    }
                                    type="text"
                                    name="purchase_price"
                                    placeholder="Enter purchase price"
                                    defaultValue={props.data?.purchase_price !== null ? props.data.purchase_price : 0.0}
                                    {...register("purchase_price")}
                                />
                                {errors.purchase_price && (
                                    <span className="text-danger err-msg">
                                        {errors.purchase_price.message}
                                    </span>
                                )}
                            </div>
                        </div>

                        <div className="col-md-3">
                            <div className="form-group">
                                <label className="lbl-style roboto-bold">{"Remark"}</label>
                                <textarea
                                    className="form-control mt-2"
                                    type="text"
                                    placeholder="Enter remark"
                                    defaultValue={props.data?.remark !== null ? props.data?.remark : ""}
                                    name="remark"
                                    {...register("remark")}
                                >
                                </textarea>
                            </div>
                        </div>
                    </div>
                    <hr className="my-4" />
                    <div className="d-flex justify-content-end">
                        <button
                            className="btn button-primary px-4 me-2"
                            onClick={handleSubmit(onSubmit)}
                            disabled={btnDisable}
                        // onClick={() => props.setCurrentStep(props.currentStep + 1)}
                        >
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default CloneEditRawMaterial
import React, { useState, useEffect } from "react";
import "../../Customer/Customer/NewCustomer.css"; // Import your CSS file for styling
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLabels } from "../../../../common/Functions/CommonFunctions";
import NewSupplierDetail from "./SupplierDetail/NewSupplierDetail";
import NewAddressDetail from "./AddressDetail/NewAddressDetail";
import NewContactDetail from "./ContactDetail/NewContactDetail";
import Preview from "./Preview";

const NewSupplier = () => {
  let navigate = useNavigate();
  const [constLabel, setConstLabel] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const steps_array = [
    "Supplier Detail",
    "Address Detail",
    "Contact Detail",
  ];
  const numSteps = 3;
  const [main_obj, setMain_obj] = useState({
    party_contact: []
  });
  const nextStep = () => {
    let next = currentStep + 1;
    // if (next > numSteps) {
    //     next = 1;
    // }
    setCurrentStep(next);
  };

  // const previousStep = () => {
  //     let prev = currentStep - 1;
  //     if (prev < 1) {
  //         prev = numSteps;
  //     }
  //     setCurrentStep(prev);
  // };

  const previousStep = (stepNumber) => {
    if (stepNumber !== undefined && stepNumber >= 1 && stepNumber <= numSteps) {
      setCurrentStep(stepNumber);
    } else {
      let prev = currentStep - 1;
      if (prev < 1) {
        prev = numSteps;
      }
      setCurrentStep(prev);
    }
  };

  useEffect(() => {
    setConstLabel(getLabels("Party Master", "Supplier"));
  }, []);

  return (
    <>
      <div className="mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                SweetAlert.fire({
                  title: "Are you sure?",
                  text: "Your present data will be lost!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, go back!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.onpopstate = undefined;

                    navigate("/pcms/supplier");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_add_supplier
                ? constLabel.lbl_add_supplier
                : "Add Supplier"}
            </p>
          </div>
          <div className="card-body" id="party_master">
            <div className="stepper-horizontal" id="stepper1">
              {[...Array(numSteps)].map((_, index) => {
                const stepNum = index + 1;
                const isEditing = stepNum === currentStep ? "editing" : "";
                const isDone = stepNum < currentStep ? "done" : "";

                return (
                  <div className={`step ${isEditing} ${isDone}`} key={index}>
                    <div className="step-circle">
                      <span>{stepNum}</span>
                    </div>
                    <div className="step-title">{steps_array[stepNum - 1]}</div>
                    <div className="step-bar-left"></div>
                    <div className="step-bar-right"></div>
                  </div>
                );
              })}
            </div>

            {currentStep == 1 && (
              <NewSupplierDetail
                nextStep={nextStep}
                main_obj={main_obj}
                setMain_obj={setMain_obj}
              />
            )}
            {currentStep == 2 && (
              <NewAddressDetail
                nextStep={nextStep}
                previousStep={previousStep}
                main_obj={main_obj}
                setMain_obj={setMain_obj}
              />
            )}
            {currentStep == 3 && (
              <NewContactDetail
                nextStep={nextStep}
                previousStep={previousStep}
                main_obj={main_obj}
                setMain_obj={setMain_obj}
              />
            )}
            {currentStep == 4 && (
              <Preview previousStep={previousStep} main_obj={main_obj} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSupplier;

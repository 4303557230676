import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    StepOne: null,  // State for Component One
    StepTwo: null,  // State for Component Two
    StepThree: null,// State for Component Three
    StepFour: {}, // State for Component Four
};

const stepSlice = createSlice({
    name: 'steps',
    initialState,
    reducers: {
        // Update state for Component One
        updateStepOne: (state, action) => {
            console.log("state", state);
            console.log("action", action);
            state.StepOne = { ...state.StepOne, ...action.payload };
        },
        // Update state for Component Two
        updateStepTwo: (state, action) => {
            state.StepTwo = { ...state.StepTwo, ...action.payload };
        },
        // Update state for Component Three
        updateStepThree: (state, action) => {
            state.StepThree = { ...state.StepThree, ...action.payload };
        },
        // Update state for Component Four
        updateStepFour: (state, action) => {
            state.StepFour = { ...action.payload };
        },
        resetState: (state, action) => {
            state.StepOne = null;
            state.StepTwo = null;
            state.StepThree = null;
            state.StepFour = {};
        },
    },
});

export const { updateStepOne, updateStepTwo, updateStepThree, updateStepFour, resetState } = stepSlice.actions;
export default stepSlice.reducer;

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import {
  updateLocation,
  getLoctionType,
  getCustomer,
  getInternalCompany,
  updateShift,
} from "../../../api";
import { Tooltip } from "react-tooltip";
import DatePicker from "react-datepicker";

const schema = yup
  .object()
  .shape({
    shiftName: yup.string().required("Shift name is required!"),
    startTime: yup
      .date()
      .typeError("Please select start time!")
      .required("Please select start time!"),
    endTime: yup
      .date()
      .typeError("Please select end time!")
      .required("Please select end time!"),
  })
  .required();

function EditShiftMaster(props) {
  const [visible, setVisible] = useState(false);

  const [startTime, setStartTime] = useState(null);
  const [endTime, setEndTime] = useState(null);

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    clearErrors,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    if (startTime && endTime) {
      const startDateTime = normalizeTime(startTime); 
      const endDateTime = normalizeTime(endTime, startDateTime);
  
      // console.log("startDateTime", startDateTime);
      // console.log("endDateTime", endDateTime);
  
      if (endDateTime < startDateTime) {
        setStartTime(null);
        setEndTime(null);
        setValue("startTime", null);
        setValue("endTime", null);
        setError("endTime", {
          type: "manual",
          message: "End time cannot be before start time",
        });
      } else {
        clearErrors("endTime");
      }
    } else if (!startTime && endTime) {
      setError("endTime", {
        type: "manual",
        message: "Please select start time first",
      });
    } else {
      clearErrors("endTime");
    }
  }, [startTime, endTime, setValue, setError, clearErrors]);
  
  useEffect(() => {
    if (visible) {
      setValue("shiftName", props.data?.name);
      const start = timeToDate(props.data?.start_time);
      const end = timeToDate(props.data?.end_time);
  
      setStartTime(start);
      setValue("startTime", start);
      setEndTime(end);
      setValue("endTime", end);
    }
  }, [visible]);
  
  function timeToDate(timeStr) {
    if (!timeStr || typeof timeStr !== "string") {
      // console.error("Invalid time string:", timeStr);
      return null; // Return null if the input is invalid
    }
  
    const today = new Date();
    const [hours, minutes, seconds] = timeStr.split(":").map(Number);
    today.setHours(hours, minutes, seconds || 0, 0);
    return today;
  }
  
  function normalizeTime(timeStr, referenceDate = new Date()) {
    const dateTime = timeToDate(timeStr); // Reuse the timeToDate function
  
    if (!dateTime) {
      // console.error("Failed to parse time:", timeStr);
      return new Date(referenceDate); // Return the reference date if parsing fails
    }
  
    // If the time is earlier than the reference time, assume it's the next day
    if (dateTime < referenceDate) {
      dateTime.setDate(dateTime.getDate() + 1);
    }
  
    return dateTime;
  }
  
  
  
  const onSubmit = (data) => {
    // console.log("data", data);

    if (data !== "") {
      const reqPayload = {
        uuid: props.data?.uuid,
        name: data.shiftName,
        start_time: formatTime(new Date(data.startTime)),
        end_time: formatTime(new Date(data.endTime)),
      };
      // console.log("reqPayload", reqPayload);
      updateShift(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Shift updated Successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchShift(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  function formatTime(date) {
    let hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';

    hours = hours % 12 || 12; // Convert 0 or 12 to 12, 13-23 to 1-11
    hours = String(hours).padStart(2, '0'); // Ensure hours are 2 digits
  
    return `${hours}:${minutes}:${seconds} ${period}`;
  }

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_shift
            ? props.constLabel?.lbl_edit_shift
            : "Edit Shift"
        }
        className="menu_icon_grey cursor_pointer me-2"
        size={20}
        onClick={() => {
          setVisible(true);
          reset();
        }}
      />
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_shift
              ? props.constLabel?.lbl_update_shift
              : "Update Shift"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
          <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  {props.constLabel?.lbl_shift_name
                    ? props.constLabel.lbl_shift_name
                    : "Shift Name"}
                  <span>
                    <i style={{ color: "red" }}> *</i>
                  </span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    className={
                      errors.shiftName
                        ? "form-control  is-invalid"
                        : "form-control "
                    }
                    id="shiftName"
                    name="shiftName"
                    placeholder="Enter shift name"
                    {...register("shiftName")}
                  />
                  <span className="text-danger err-msg">
                    {errors.shiftName?.message}
                  </span>
                </div>
              </div>

              <div className="form-group mt-md-0 mt-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {props.constLabel?.lbl_start_time
                      ? props.constLabel?.lbl_start_time
                      : "Start Time"}
                    <span className="text-danger"> *</span>
                  </label>

                  {/* DatePicker for startTime */}
                  <Controller
                    control={control}
                    name="startTime"
                    render={({ field }) => (
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption="Time"
                        className={
                          errors.startTime ? "form-control is-invalid" : "form-control"
                        }
                        placeholderText="--:--:--"
                        selected={startTime}
                        onChange={(time) => {
                          setStartTime(time);
                          field.onChange(time);
                        }}
                        dateFormat="hh:mm:ss aa"
                        autoComplete="false"
                      />
                    )}
                  />

                  {errors.startTime && (
                    <span className="text-danger err-msg">
                      {errors.startTime.message}
                    </span>
                  )}
              </div>

              <div className="form-group mt-md-0 mt-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {props.constLabel?.lbl_end_time
                      ? props.constLabel?.lbl_end_time
                      : "End Time"}
                    <span className="text-danger"> *</span>
                  </label>

                  {/* DatePicker for endTime */}
                  <Controller
                    control={control}
                    name="endTime"
                    render={({ field }) => (
                      <DatePicker
                        showTimeSelect
                        showTimeSelectOnly
                        timeIntervals={5}
                        timeCaption="Time"
                        className={errors.endTime ? "form-control is-invalid" : "form-control"}
                        placeholderText="--:--:--"
                        selected={endTime}
                        onChange={(time) => {
                          setEndTime(time);
                          field.onChange(time);
                        }}
                        dateFormat="hh:mm:ss aa"
                        autoComplete="false"
                      />
                    )}
                  />

                  {errors.endTime && (
                    <span className="text-danger err-msg">
                      {errors.endTime.message}
                    </span>
                  )}
              </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditShiftMaster;

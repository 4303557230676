import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faClose } from "@fortawesome/free-solid-svg-icons";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import Accordion from 'react-bootstrap/Accordion';
import { get_document_type, uploadFiles, delProdImages } from "../../../../api/index";
import { Typeahead } from "react-bootstrap-typeahead";
import { convertUrl } from "../../../../common/Functions/CommonFunctions";

const imageFileTypes = ["image/jpeg", "image/jpg", "image/png"];

const schema = yup
    .object()
    .shape({
        image: yup
            .mixed()
            .test('fileSize', 'Image can be uploaded upto 10 MB',
                (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024))
            .test("fileType", "Only image files are allowed", (value) => {
                if (!value) return true; // If no file is selected, it's considered valid.
                // return value[0]?.type.startsWith('image/');
                return imageFileTypes.includes(value[0]?.type);
            })
            .required("Required"),
        fileName: yup
            .string()
            .required("Image name is required !"),
    })
    .required();

function CloneDrawingGallery(props) {
    const [docTypeList, setDocTypeList] = useState({ data: [], loading: false });

    const { register, handleSubmit, formState, control, reset, watch, setValue, setError } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange", defaultValues: {
            image: "",
            fileName: ""
        }
    });

    let { errors } = formState;



    useEffect(() => {
        
        setDocTypeList({ ...docTypeList, data: [], loading: true });

        get_document_type().then((res) => {
            
            setDocTypeList({ ...docTypeList, data: res.data.data, loading: false });
            const filData = res.data.data.filter((val) => val.name == "Operation Drawing");

            setValue('docType', filData);
        }, (err) => {
            
            setDocTypeList({ ...docTypeList, data: [], loading: false });
        })

        
    }, []);


    const onsubmit = (data) => {
        
        

        if (props.action == "update") {

            if ((props.currIndex + 1) <= props.operList.data.length) {

                const payload = {
                    name: data.fileName,
                    attachment: data.image[0],
                    product_operation: props.fields[props.currIndex].po_uuid,
                    doc_type_id: data.docType[0].uuid,
                    doc_type: data.docType[0].name,
                    isActive: "True"
                }

                
                const formDataToSend = new FormData();

                for (const [key, value] of Object.entries(payload)) {
                    formDataToSend.append(key, value);
                }


                uploadFiles(formDataToSend).then((res) => {
                    
                    reset();
                    props.fetchImages(props.currIndex);
                    toast.success("File uploaded successfully", {
                        autoClose: 2000,
                    });

                }, (err) => {
                    toast.error("Something went wrong", {
                        autoClose: 2000,
                    });
                    
                })
            } else {

                
                let tempImg = [...props.drawingFiles];
                tempImg[props.currIndex].img.push({ filename: data.fileName, file: data.image[0], docType: data.docType });
                props.setDrawingFiles(tempImg);

                
                reset();
            }
        } else {
            
            let tempImg = [...props.drawingFiles];
            tempImg[props.currIndex].img.push({ filename: data.fileName, file: data.image[0], docType: data.docType });
            props.setDrawingFiles(tempImg);

            
            reset();
        }
        reset();
    }

    function removeFile(img) {
        
        if (props.action == "update") {
            if (Object.keys(props.fields[props.currIndex]).length > 1) {
                SweetAlert.fire({
                    title: "Are you sure to delete file",
                    // text: "Once deleted, you will not be able to recover this record !",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Ok",
                    cancelButtonText: "cancel",
                    reverseButtons: true,
                }).then((result) => {
                    if (result.value) {
                        delProdImages({ uuid: img.uuid }).then((res) => {
                            
                            toast.success("File deleted successfully", {
                                autoClose: 2000,
                            });
                            props.fetchImages(props.currIndex);
                        }, (err) => {
                            toast.error("Something went wrong", {
                                autoClose: 2000,
                            });
                            

                        })
                    }
                });
            } else {
                let tempImg = [...props.drawingFiles];

                tempImg[props.currIndex].img.splice(img, 1);
                props.setDrawingFiles(tempImg);
                
            }
        } else {
            let tempImg = [...props.drawingFiles];

            tempImg[props.currIndex].img.splice(img, 1);
            props.setDrawingFiles(tempImg);
            

        }

    }

    return (
        <React.Fragment>
            <Modal show={props.galVisible} onHide={() => props.setGalVisible(false)} size='lg' backdrop="static">
                <Modal.Header style={{ background: "#2A3643", color: "white" }} closeButton>
                    <Modal.Title>Drawing Gallery</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className='col-12 col-md-4'>
                            <label style={{ fontSize: "14px" }}
                                className="col-form-label">Doc Type</label>
                            <Controller
                                name={`docType`}
                                control={control}
                                render={({
                                    field: { onChange, onBlur, value, ref },
                                }) => (
                                    <Typeahead
                                        size='sm'
                                        id="basic-typeahead"
                                        labelKey="name"
                                        multiple={false}
                                        disabled
                                        options={docTypeList.data}
                                        isLoading={docTypeList.loading}
                                        placeholder="Select operation"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        selected={value}
                                        isInvalid={
                                            errors.docType ? true : false
                                        }
                                    />
                                )}
                            />
                            {errors.docType && (
                                <span className="text-danger err-msg">
                                    {errors.docType.message}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-4'>
                            <label style={{ fontSize: "14px" }}
                                className="col-form-label">File</label>
                            <input
                                className={errors.image ? "form-control form-control-sm is-invalid" : 'form-control form-control-sm'}
                                type='file'
                                {...register('image')}
                                accept="image/*,.pdf"
                            />
                            {errors.image && (
                                <span className="text-danger err-msg">
                                    {errors.image.message}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-4'>
                            <label style={{ fontSize: "14px" }}
                                className="col-form-label">Filename</label>
                            <input
                                className={errors.fileName ? "form-control form-control-sm is-invalid" : 'form-control form-control-sm'}
                                type='text'
                                {...register('fileName')}
                                placeholder='Enter filename'
                            />
                            {errors.fileName && (
                                <span className="text-danger err-msg">
                                    {errors.fileName.message}
                                </span>
                            )}
                        </div>

                        <div className='col-12 col-md-3 mt-3'>
                            <button className='btn btn-sm btn-yellow px-3'
                                onClick={handleSubmit(onsubmit)}>Submit</button>
                        </div>
                    </div>


                    <div className="row mt-4 drawing_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">

                        {props.images.data.map((val, i) => (
                            val.attachment !== null ?
                                convertUrl(val.attachment).includes('.jpg') == true ?

                                    <div className="col text-center" key={i}>
                                        <div className="image-container cursor_pointer">
                                            <img src={convertUrl(val.attachment)}
                                                onClick={() => window.open(val.attachment, '_blank')}
                                                className="img-fluid" alt="Image 1" />

                                            <FontAwesomeIcon
                                                onClick={() => removeFile(val)}
                                                className='icon-close'
                                                style={{ color: "#000" }} icon={faClose} size="lg" />
                                        </div>

                                        <span className="image-name cursor_pointer">{val.name}</span>
                                    </div>
                                    :
                                    convertUrl(val.attachment).includes('.png') == true ?

                                        <div className="col text-center" key={i}>
                                            <div className="image-container cursor_pointer">
                                                <img src={convertUrl(val.attachment)}
                                                    onClick={() => window.open(val.attachment, '_blank')}
                                                    className="img-fluid" alt="Image 1" />

                                                <FontAwesomeIcon
                                                    onClick={() => removeFile(val)}
                                                    className='icon-close'
                                                    style={{ color: "#000" }} icon={faClose} size="lg" />
                                            </div>

                                            <span className="image-name cursor_pointer">{val.name}</span>
                                        </div>
                                        :
                                        <div className="col text-center" key={i}>
                                            <div className="image-container ">
                                                <div

                                                    className='other_doc d-flex justify-content-center align-items-center'>
                                                    <FontAwesomeIcon
                                                        onClick={() => window.open(convertUrl(val.attachment), '_blank')}
                                                        className='icon-file-pdf cursor_pointer'
                                                        style={{ color: "#fff" }}
                                                        icon={faFileAlt} size="3x"
                                                    />

                                                    <FontAwesomeIcon
                                                        onClick={() => removeFile(val)}
                                                        className='icon-close'
                                                        style={{ color: "#000" }} icon={faClose} size="lg" />
                                                </div>
                                            </div>

                                            <span className="image-name cursor_pointer">{val.name}</span>
                                        </div>
                                : null
                        ))}

                        {

                            props.drawingFiles[props.currIndex]?.img?.map((val, i) => (
                                val.file.type.includes('image') == true ?

                                    <div className="col text-center" key={i}>
                                        <div className="image-container">
                                            <img src={URL.createObjectURL(val.file)}
                                                className="img-fluid" alt="Image 1" />

                                            <FontAwesomeIcon
                                                onClick={() => removeFile(i)}
                                                className='icon-close'
                                                style={{ color: "#000" }} icon={faClose} size="lg" />
                                        </div>

                                        <span className="image-name cursor_pointer">{val.filename}</span>
                                    </div>
                                    :
                                    <div className="col text-center" key={i}>
                                        <div className="image-container cursor_pointer">
                                            <div
                                                onClick={() => window.open(val.file, '_blank')}
                                                className='other_doc d-flex justify-content-center align-items-center'>
                                                <FontAwesomeIcon
                                                    className='icon-file-pdf'
                                                    style={{ color: "#fff" }}
                                                    icon={faFileAlt} size="3x"
                                                />

                                                <FontAwesomeIcon
                                                    onClick={() => removeFile(i)}
                                                    className='icon-close'
                                                    style={{ color: "#000" }} icon={faClose} size="lg" />
                                            </div>
                                        </div>

                                        <span className="image-name cursor_pointer">{val.filename}</span>
                                    </div>
                            ))

                        }

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-grey px-4" onClick={() => props.setGalVisible(false)}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default CloneDrawingGallery
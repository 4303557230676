import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { updateCustomer_Contact, createCustomer_Contact, get_party_contact, delete_party_contact } from "../../../../../api/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";

const schema = yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      firstName: yup.string().required("First name is required !").matches(/^[a-zA-Z ]*$/, "Enter valid name"),
      mobileNo: yup.string().matches(/^[0-9]*$/, 'Enter valid mobile number').required("Mobile number is required !"),
      alternateMobileNo: yup.string().matches(/^[0-9]*$/, 'Enter valid mobile number').notRequired(),
      landlineExtentionNo: yup.string().matches(/^[0-9]*$/, 'Enter valid landline extension number').notRequired(),
      landlineNo: yup.string().matches(/^[0-9]*$/, 'Enter valid landline number').notRequired(),
      alternateLandlineExtentionNo: yup.string().matches(/^[0-9]*$/, 'Enter valid alternate landline extension number').notRequired(),
      alternateLandlineNo: yup.string().matches(/^[0-9]*$/, 'Enter valid alternate landline number').notRequired(),
      emailid: yup.string().email("Enter valid email id").notRequired(),
    })
  ),
});

function EditContactDetail(props) {
  
  const [constLabel, setConstLabel] = useState({});
  const { register, handleSubmit, formState, control, reset, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: { items: [{}] },
    }); // initialise the hook

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  useEffect(() => {
    setConstLabel(getLabels('Party Master', 'Customer'));
    if (props.data?.uuid) {
      const payload = {
        partyId: props.data?.uuid,
      };
      get_party_contact(payload).then(
        (res) => {
          
          var contactArr = [];
          if (res.data.data?.length > 0) {
            for (let i = 0; i < res.data.data.length; i++) {
              contactArr.push({
                uuid: res.data.data[i].uuid,
                firstName: res.data.data[i].firstName,
                lastName: res.data.data[i].lastName,
                mobileNo: res.data.data[i].mobileNo,
                landlineNo: res.data.data[i].landlineNo,
                landlineExtentionNo: res.data.data[i].landlineExtentionNo,
                alternateMobileNo: res.data.data[i].alternateMobileNo,
                alternateLandlineExtentionNo: res.data.data[i].alternateLandlineExtentionNo,
                alternateLandlineNo: res.data.data[i].alternateLandlineNo,
                // contactId: res.data.data[i].contactId,
                emailid: res.data.data[i].emailid,
                party: res.data.data[i].party,
                partyId: res.data.data[i].partyId,
                designation: res.data.data[i].designation,
                remark: res.data.data[i].remark
              });
            }
            
            // reset({ items: contactArr });
            setValue("items", contactArr);
          }
        },
        (err) => {
          
          toast.error(err.response.data.message, {
            autoClose: 3000,
          });
        }
      );
    }
  }, []);

 
  const onSubmit = (data) => {
    
    if (data !== "" || data.items.length !== 0) {

      const contacts = data.items;

      localStorage.setItem("contacts", JSON.stringify(contacts));
      props.setKey("billingAddress");

    } else {
      errors.showMessages();
    }
  };

  return (
    <>
      <a className="btn btn-yellow roboto-bold" onClick={() => append({})}>
        <FontAwesomeIcon style={{ color: "#000" }} icon={faPlus} size="sm" />{" "}
        {constLabel?.lbl_contact ? constLabel.lbl_contact : "Contact"}
      </a>

      {fields.map(({ id }, index) => (
        <div className="row mt-2" key={id}>
          <div className="col-md-6">
            <div className="form-group row mt-2">
              <label style={{fontSize : "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_first_name ? constLabel.lbl_first_name : "First Name"}
                <span>
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className={
                    errors.items?.[index]?.firstName
                      ? "form-control form-control-sm mt-2 is-invalid"
                      : "form-control form-control-sm mt-2"
                  }
                  id="firstName"
                  name={`items[${index}].firstName`}
                  placeholder="Enter first name"
                  // defaultValue={props.data?.[index]?.firstName}
                  {...register(`items[${index}].firstName`)}
                />
                <span className="text-danger err-msg">
                  {errors.items?.[index]?.firstName?.message}
                </span>
              </div>
            </div>

            <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
            {constLabel?.lbl_mobile_number ? constLabel.lbl_mobile_number : "Mobile Number"} <span>
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className={
                    errors.items?.[index]?.mobileNo
                      ? "form-control form-control-sm mt-2 is-invalid"
                      : "form-control form-control-sm mt-2"
                  }
                  id="mobileNo"
                  placeholder="Enter mobile no"
                  maxLength={10}
                  name={`items[${index}].mobileNo`}
                  {...register(`items[${index}].mobileNo`)}
                />
                <span className="text-danger err-msg">
                  {errors.items?.[index]?.mobileNo?.message}
                </span>
              </div>
            </div>

            <div className="form-group row mt-2">
              <label style={{fontSize : "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_alt_mobile_no ? constLabel.lbl_alt_mobile_no : "Alt. Mobile No"}
                {/* <span>
                                <i style={{ color: "red" }}>*</i>
                            </span> */}
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="alternateMobileNo"
                  maxLength={10}
                  name={`items[${index}].alternateMobileNo`}
                  placeholder="Enter alternate mobile"
                  {...register(`items[${index}].alternateMobileNo`)}
                />
                <span className="text-danger err-msg">
                  {errors.items?.[index]?.alternateMobileNo?.message}
                </span>
              </div>
            </div>

            <div className="form-group row mt-2">
              <label style={{fontSize : "14px"}} className="col-form-label col-md-4">
                {constLabel?.lbl_email_id ? constLabel.lbl_email_id : "Email Id"}</label>
              <div className="col-md-8">
                <input
                  type="email"
                  className="form-control form-control-sm"
                  id="emailid"
                  name={`items[${index}].emailid`}
                  placeholder="Enter email id"
                  {...register(`items[${index}].emailid`)}
                />
                <span className="text-danger err-msg">
                  {errors.items?.[index]?.emailid?.message}
                </span>
              </div>
            </div>

            <div className="form-group row mt-2">
              <label style={{fontSize : "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_remark ? constLabel.lbl_remark : "Remark"}
              </label>
              <div className="col-md-8">
                <textarea
                  type="text"
                  className="form-control form-control-sm"
                  id="remark"
                  name={`items[${index}].remark`}
                  placeholder="Enter remark"
                  {...register(`items[${index}].remark`)}
                ></textarea>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group row mt-2">
              <label style={{fontSize : "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_last_name ? constLabel.lbl_last_name : "Last Name"}
                {/* <span>
                                <i style={{ color: "red" }}>*</i>
                            </span> */}
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="lastName"
                  name={`items[${index}].lastName`}
                  placeholder="Enter last name"
                  {...register(`items[${index}].lastName`)}
                />
              </div>
            </div>

            <div className="form-group row mt-2">
              <label style={{fontSize : "14px"}} className="col-form-label col-md-4">
                {constLabel?.lbl_landline_number ? constLabel.lbl_landline_number : "Landline Number"}</label>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="landlineExtentionNo"
                  maxLength={5}
                  name={`items[${index}].landlineExtentionNo`}
                  placeholder="Extension"
                  {...register(`items[${index}].landlineExtentionNo`)}
                />
                <span className="text-danger err-msg">
                  {errors.items?.[index]?.landlineExtentionNo?.message}
                </span>
              </div>
              <div className="col-md-5">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="landlineNo"
                  maxLength={8}
                  placeholder="Enter Landline"
                  name={`items[${index}].landlineNo`}
                  {...register(`items[${index}].landlineNo`)}
                />
                <span className="text-danger err-msg">
                  {errors.items?.[index]?.landlineNo?.message}
                </span>
              </div>
            </div>

            <div className="form-group row mt-2">
              <label style={{fontSize : "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_alt_landline_no ? constLabel.lbl_alt_landline_no : "Alt. Landline No"}
                {/* <span>
                                <i style={{ color: "red" }}>*</i>
                            </span> */}
              </label>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="alternateLandlineExtentionNo"
                  maxLength={5}
                  placeholder="Extension"
                  name={`items[${index}].alternateLandlineExtentionNo`}
                  {...register(`items[${index}].alternateLandlineExtentionNo`)}
                />
                <span className="text-danger err-msg">
                  {errors.items?.[index]?.alternateLandlineExtentionNo?.message}
                </span>
              </div>
              <div className="col-md-5">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="alternateLandlineNo"
                  maxLength={8}
                  placeholder="Alternate Landline"
                  name={`items[${index}].alternateLandlineNo`}
                  {...register(`items[${index}].alternateLandlineNo`)}
                />
                <span className="text-danger err-msg">
                  {errors.items?.[index]?.alternateLandlineNo?.message}
                </span>
              </div>
            </div>

            <div className="form-group row mt-2">
              <label style={{fontSize : "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_designation ? constLabel.lbl_designation : "Designation"}</label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  id="designation"
                  name={`items[${index}].designation`}
                  placeholder="Enter designation"
                  {...register(`items[${index}].designation`)}
                />
              </div>
            </div>
          </div>
          <div className="mt-3">
            <a
              className={
                index > 0 ? "btn btn-default border roboto-bold" : "cursor_banned btn btn-default border roboto-bold"
              }
              onClick={
                index > 0
                  ? () => {
                      remove(index);
                      
                      if(fields[index].uuid){
                      delete_party_contact({ uuid: fields[index].uuid }).then((res) => {
                        // toast.success("Customer contact deleted successfully !", {
                        //   autoClose: 1000
                        // });
                        
                      }, (err) => {
                        // toast.error("Something went wrong ")
                        
                      })
                    }
                    }
                  : null
              }
            >
              <FontAwesomeIcon
                style={{ color: "#000", border: "1px" }}
                icon={faMinus}
                size="sm"
              />{" "}
              {constLabel?.lbl_remove ? constLabel.lbl_remove : "Remove"}
            </a>
          </div>
        </div>
      ))}

      <div className="row justify-content-between mt-2">
        <div className="col-md-4">
          <span>
            <i style={{ color: "red" }}>*</i>
            <u> indicate Mandatory fields</u>
          </span>
        </div>
        <div className="col-sm-2 text-end">
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {constLabel?.lbl_next ? constLabel.lbl_next : "Next"}
            {/* <FontAwesomeIcon
              style={{ color: "#000" }}
              icon={faPlay}
              size="sm"
            /> */}
          </button>
        </div>
      </div>
    </>
  );
}

export default EditContactDetail;

import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { FileText } from "react-feather";
import { Modal } from "react-bootstrap";
import { operationRegister, getRegisterList } from "../../../../api";

const schema = yup
  .object()
  .shape({
    ERegister: yup
      .array()
      .min(1, "Select atleast one")
      .required("E Register is required !"),
    FRegister: yup
      .array()
      .min(1, "Select atleast one")
      .required("F Register is required !"),
  })
  .required();

function RegisterMapping(props) {


  const [regOne, setRegOne] = useState({ data: [], state: false });
  const [regTwo, setRegTwo] = useState({ data: [], state: false });

  const [regData, setRegData] = useState({});

  const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    // resetting the form
    
    if (props.visible2) {
      setRegOne({ ...regOne, state: true, data: [] });
      setRegTwo({ ...regTwo, state: true, data: [] });

      getRegisterList().then(
        (res) => {
          
          setRegOne({
            ...regOne,
            state: false,
            data: res.data.registerList,
          });
          setRegTwo({
            ...regTwo,
            state: false,
            data: res.data.registerList,
          });
        },
        (err) => {
          //
          setRegOne({ ...regOne, state: false, data: [] });
          setRegTwo({ ...regTwo, state: false, data: [] });
          toast.error(err.response.data.message, {
            autoClose: 3000,
          });
        }
      );
    }
  }, [props.visible2]);

  useEffect(() => {
    if (props.data !== undefined && props.visible2 == true) {
      fetchOp_Register()
    }
  }, [props])


  function fetchOp_Register() {
    // setRegData({});
    const payload = {
      action: "viewbyid",
      operationID: props.data.operationID
    }
    operationRegister(payload).then(
      (res) => {
        
        setValue("ERegister", [{ name: res.data.obj.E_register }])
        setValue("FRegister", [{ name: res.data.obj.F_register }])
        setRegData(res.data);
      },
      (err) => {
        //
        setRegData({});
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }



  const onSubmit = (data) => {
    

    if (data !== "") {
      const reqPayload = {
        action: "create",
        operationID: props.data.OpId,
        E_register: data.ERegister[0]?.name,
        F_register: data.FRegister[0]?.name
      };

      

      operationRegister(reqPayload).then(
        (res) => {
          
          if (res.status === 200 || res.status === 201) {
            toast.success("Operation register saved successfully !", {
              autoClose: 3000,
            });
            props.fetchOperation();
            props.setVisible2(false);
          }
        },
        (err) => {
          
          if (err.response.status === 400) {
            
            toast.error("Operation register already exist ...", {
              autoClose: 2000,
            });
            reset();
          }
        }
      )
        .catch((error) => {
          
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>

      <Modal show={props.visible2} onHide={() => props.setVisible2(false)} size={"lg"}>
        <Modal.Header style={{ background: "#2A3643", color: "white" }} closeButton>
          <Modal.Title>{"Register Mapping"}- [{regData !== undefined ? regData.oprname : ''}]</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="row">
              <div className="col-12 col-sm-6">
                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-4">E_register</label>
                  <div className="col-md-8">
                    <Controller
                      name="ERegister"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          size="sm"
                          multiple={false}
                          options={regOne.data}
                          // options={regOne.data !== undefined ? regOne.data : []}
                          isLoading={regOne.state}
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.ERegister ? true : false}
                        />
                      )}
                    />
                    <span className="text-danger">
                      {errors.ERegister?.message}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-12 col-sm-6">
                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-4">F_register</label>
                  <div className="col-md-8">
                    <Controller
                      name="FRegister"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="name"
                          size="sm"
                          multiple={false}
                          options={regOne.data}
                          // options={regOne.data !== undefined ? regOne.data : []}
                          isLoading={regOne.state}
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.FRegister ? true : false}
                        />
                      )}
                    />
                    <span className="text-danger">
                      {errors.FRegister?.message}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end my-4">
              <button
                onClick={handleSubmit(onSubmit)}
                className="f-16 btn btn-yellow "
              >
                {"Save"}
              </button>
            </div>
            <div className="row my-2">
              <div className="col-12 col-sm-4 offset-2 text-center">
                <input type="text" readOnly className="form-control form-control-sm roboto-bold text-center" value={"E-66 Register"} />
                <input type="text" readOnly className="form-control form-control-sm text-center" value={regData?.obj?.E_register} />
              </div>

              <div className="col-12 col-sm-4">
                <input type="text" readOnly className="form-control form-control-sm roboto-bold text-center" value={"F-10 Register"} />
                <input type="text" readOnly className="form-control form-control-sm text-center" value={regData?.obj?.F_register} />
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default RegisterMapping;

import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  calMaxPage,
  getLabels,
} from "../../../common/Functions/CommonFunctions";
import {
  getProductImage,
  getProductAttribute,
  getProdConfig,
  getProdImages,
} from "../../../api";

function ViewGeneralItem() {
  const [galVisible, setGalVisible] = useState(false);
  const [docList, setDocList] = useState({ data: [], loading: true });
  const [prod_img, setProd_Img] = useState({ data: [], loading: true });
  const [prod_attr, setProd_Attr] = useState({ data: [], loading: false });
  const [prodConfig, setProdConfig] = useState({
    data: [],
    loading: false,
    totalRecords: "",
  });

  const [constLabel, setConstLabel] = useState({});
  let location = useLocation();

  useEffect(() => {
    setConstLabel(getLabels('Master', 'Product'));
  }, []);

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="">
        <div className="d-flex justify-content-between mb-4">
          <h1 className="bold">
            {constLabel?.lbl_product_configurator ? constLabel?.lbl_product_configurator : "Product Configurator"}
          </h1>
        </div>

        <hr className="mb-2" />
        <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
          {constLabel?.lbl_product_information ? constLabel?.lbl_product_information : "Product Information"}
        </p>
        <hr className="mt-2" />

        <div className="">
          <div
            className="row"
            style={{ paddingTop: "10px", paddingBottom: "25px" }}
          >
            <div className="col-12">
              <div className="row">
                <div className="col-12 col-sm-4">
                  <div className="card shadow-sm rounded-0 p-2">
                    <div className="card-body">
                      <p className="pc_card_title roboto-bold">
                        {constLabel?.lbl_product_summary ? constLabel?.lbl_product_summary : "Product Summary"}
                      </p>
                      <div className="form-group">
                        <label className="lbl-style roboto-bold">
                          {constLabel?.lbl_product_type ? constLabel?.lbl_product_type : "Product Type"}
                        </label>
                        <input
                          className="form-control mt-2 "
                          disabled
                          type="text"
                          defaultValue={location.state?.productTypename !== "" ? location.state?.productTypename : "NA"}
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label className="lbl-style roboto-bold">
                          {constLabel?.lbl_product_category ? constLabel?.lbl_product_category : "Product Category"}
                        </label>
                        <input
                          className="form-control mt-2"
                          disabled
                          type="text"
                          defaultValue={location.state?.category !== "" ? location.state?.category : "NA"}
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label className="lbl-style roboto-bold">
                          {constLabel?.lbl_product_name ? constLabel?.lbl_product_name : "Product Name"}
                        </label>
                        <input
                          className="form-control mt-2"
                          disabled
                          type="text"
                          defaultValue={location.state?.productName !== "" ? location.state?.productName : "NA"}
                        />
                      </div>
                      <div className="form-group mt-3">
                        <label className="lbl-style roboto-bold">{constLabel?.lbl_hsn ? constLabel?.lbl_hsn : "HSN"}</label>
                        <input
                          className="form-control mt-2"
                          disabled
                          type="text"
                          defaultValue={location.state?.hsn !== "" ? location.state?.hsn : "NA"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="card shadow-sm rounded-0 p-2">
                    <div className="card-body">
                      <p className="pc_card_title roboto-bold">
                        {constLabel?.lbl_product_detail ? constLabel?.lbl_product_detail : "Product Detail"}
                      </p>
                      {/* <div className="form-group">
                        <label className="lbl-style roboto-bold">
                          {"Drawing No."}
                        </label>
                        <input
                          className="form-control mt-2"
                          disabled
                          type="text"
                          defaultValue={location.state?.drawingno}
                        />
                      </div> */}
                      <div className="mt-2">
                        <label className="lbl-style roboto-bold invisible">
                        {constLabel?.lbl_can_be_sold ? constLabel?.lbl_can_be_sold : "Can be Sold"}
                        </label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={location.state.can_be_sold}
                            disabled
                          />
                          <label className="form-check-label lbl-style roboto-bold">
                            {constLabel?.lbl_can_be_sold ? constLabel?.lbl_can_be_sold : "Can be Sold"}
                          </label>
                        </div>
                      </div>

                      <div className="mt-2">
                        <label className="lbl-style roboto-bold invisible">
                        {constLabel?.lbl_can_be_pruchase ? constLabel?.lbl_can_be_pruchase : "Can be Purchased"}
                        </label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={location.state.can_be_purchased}
                            disabled
                          />
                          <label className="form-check-label lbl-style roboto-bold">
                            {constLabel?.lbl_can_be_pruchase ? constLabel?.lbl_can_be_pruchase : "Can be Purchased"}
                          </label>
                        </div>
                      </div>

                      <div className="mt-2">
                        <label className="lbl-style roboto-bold invisible">
                        {constLabel?.lbl_can_be_expense ? constLabel?.lbl_can_be_expense : "Can be Expense"}
                        </label>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            defaultChecked={location.state.can_be_expensed}
                            disabled
                          />
                          <label className="form-check-label lbl-style roboto-bold">
                          {constLabel?.lbl_can_be_expense ? constLabel?.lbl_can_be_expense : "Can be Expense"}
                          </label>
                        </div>
                      </div>

                      <div className="form-group mt-3">
                        <label className="lbl-style roboto-bold">
                          {constLabel?.lbl_beacon_id ? constLabel?.lbl_beacon_id : "BeaconId"}
                        </label>
                        <input
                          className="form-control mt-2"
                          disabled
                          type="text"
                          defaultValue={location.state?.beacon_id !== "" ? location.state?.beacon_id : "NA"}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <label className="lbl-style roboto-bold">
                          {constLabel?.lbl_qr_code_id ? constLabel?.lbl_qr_code_id : "QR Code Id"}
                        </label>
                        <input
                          className="form-control mt-2"
                          disabled
                          type="text"
                          defaultValue={location.state?.qr_code_id !== "" ? location.state?.qr_code_id : "NA"}
                        />
                      </div>

                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-4">
                  <div className="card shadow-sm rounded-0 p-2">
                    <div className="card-body">
                      <p className="pc_card_title roboto-bold">{constLabel?.lbl_product_price ? constLabel?.lbl_product_price : "Product Price"}</p>
                      <div className="form-group">
                        <label className="lbl-style roboto-bold">
                          {constLabel?.lbl_cost ? constLabel?.lbl_cost : "Cost"}
                        </label>
                        <input
                          className="form-control mt-2"
                          disabled
                          type="text"
                          defaultValue={location.state?.machineCost !== "" && location.state?.machineCost !== null ? location.state?.machineCost : "NA"}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <label className="lbl-style roboto-bold">
                          {constLabel?.lbl_sales_price ? constLabel?.lbl_sales_price : "Sales Price"}
                        </label>
                        <input
                          className="form-control mt-2 "
                          disabled
                          type="text"
                          defaultValue={location.state?.salesPrice !== "" && location.state?.salesPrice !== null ? location.state?.salesPrice : "NA"}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <label className="lbl-style roboto-bold">
                          {constLabel?.lbl_remark ? constLabel?.lbl_remark : "Remark"}
                        </label>
                        <input
                          className="form-control mt-2"
                          disabled
                          type="text"
                          defaultValue={location.state?.remark !== "" ? location.state?.remark : "NA"}
                        />
                      </div>

                      <div className="form-group mt-3">
                        <div>
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_check_process ? constLabel?.lbl_check_process : "Check Process"}
                          </label>
                        </div>
                        <button className="btn button-primary px-3 mt-2">
                          {constLabel?.lbl_process_flow ? constLabel?.lbl_process_flow : "Process Flow"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> 
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewGeneralItem;

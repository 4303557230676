import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { saveSupplier, updateSupplier } from "../../../../api";

import { ToastContainer, toast } from "react-toastify";
import { getLabels } from "../../../../common/Functions/CommonFunctions";

function Preview(props) {
  let navigate = useNavigate();
  const location = useLocation();
  const {
    supp_type,
    name,
    contactPersonDesignation,
    contactPersonContact,
    contactPersonName,
    CSTNO,
    VATNO,
    PANNO,
    gst_treatment,
    gst,
    description,
    email,
    address,
    address2,
    country_name,
    state_name,
    city_name,
    postalCode,
    party_contact,
    faxNo
   
  } = props.main_obj;

  const p_contact_array = [];

  const [constLabel, setConstLabel] = useState({});

  if (party_contact.length > 0) {
    for (let i = 0; i < party_contact.length; i++) {
      let obj = {};
      obj["First Name"] = party_contact[i].firstName;
      obj["Last Name"] = party_contact[i].lastName;
      obj["Mobile No"] = party_contact[i].mobileNo;
      obj["Alt. Mobile No"] = party_contact[i].alternateMobileNo;
      obj["Landline Number"] =
        party_contact[i].landlineExtentionNo.length > 0 &&
        party_contact[i].landlineNo.length > 0
          ? `${party_contact[i].landlineExtentionNo}-${party_contact[i].landlineNo}`
          : "";
      obj["Alt. Landline No"] =
        party_contact[i].alternateLandlineExtentionNo.length > 0 &&
        party_contact[i].alternateLandlineNo.length > 0
          ? `${party_contact[i].alternateLandlineExtentionNo}-${party_contact[i].alternateLandlineNo}`
          : "";
      obj["Email"] = party_contact[i].emailid;
      obj["Designation"] = party_contact[i].designation;
      obj["Additional Information"] = party_contact[i].remark;

      p_contact_array.push(obj);
    }
  }

  useEffect(() => {
    setConstLabel(getLabels('Party Master', 'Supplier'));
  }, [])

  const onSubmit = () => {
    
    if (location.state == null && location.pathname == "/pcms/addSupplier") {
      saveSupplier(props.main_obj).then(
        (res) => {
          
          if (res.status === 200 || res.status === 201) {
            toast.success("Supplier detail created successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              navigate(`/pcms/supplier`);
            }, 2200);
          }
        },
        (err) => {
          
          if (err.response.status === 400) {
            
            toast.error(err.response.data.message, {
              autoClose: 3000,
            });
          }
        }
      );
    } else {
      updateSupplier(props.main_obj).then(
        (res) => {

          if (res.status === 200 || res.status === 201) {
            toast.success("Supplier detail updated successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              navigate(`/pcms/supplier`);
            }, 2200);
          }
        },
        (err) => {
          
          if (err.response.status === 400) {
            
            toast.error(err.response.data.message, { autoClose: 3000 });
          }
        }
      );
    }
  };

  return (
    <>
      <ToastContainer />
      <div className="row d-flex justify-content-between">
        <div className="col-sm-6 text-start">
          {" "}
          <h4 className="mb-4 roboto-bold">{constLabel?.lbl_supplier_detail ? constLabel?.lbl_supplier_detail : "Supplier Detail"}</h4>
        </div>
        <div className="col-sm-2 text-end">
          <button
            onClick={() => {
              props.previousStep(1);
            }}
            className="px-3 btn btn-sm btn-grey "
          >
            {constLabel?.lbl_edit ? constLabel?.lbl_edit : "Edit"}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_supplier_type ? constLabel?.lbl_supplier_type : `Supplier Type`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {supp_type || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_supplier_name ? constLabel?.lbl_supplier_name : `Supplier Name`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {name || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_contact_person_name ? constLabel?.lbl_contact_person_name : `Contact Person Name`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {contactPersonName || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_contact_no ? constLabel?.lbl_contact_no : `Contact No`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {contactPersonContact || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_contact_person_designation ? constLabel?.lbl_contact_person_designation : `Contact Person Designation`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {contactPersonDesignation || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_email ? constLabel?.lbl_email_id : `Email`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {email || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_gst_treatment ? constLabel?.lbl_gst_treatment : `GST Registration Type`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {gst_treatment || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_gst_no ? constLabel?.lbl_gst_no : `GST No`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {gst || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_cst_no ? constLabel?.lbl_cst_no : `CST No`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {CSTNO || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_vat ? constLabel?.lbl_vat : `VAT`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {VATNO || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_pan ? constLabel?.lbl_pan : `PAN`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {PANNO || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_description ? constLabel?.lbl_description : `Description`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {description || "NA"}
            </p>
          </div>
        </div> 
      </div>

      <hr className="my-3 mx-2" style={{ width: "98%" }} />
      <div className="row d-flex justify-content-between">
        <div className="col-sm-6 text-start">
          {" "}
          <h4 className="mb-4 roboto-bold">{constLabel?.lbl_address_detail ? constLabel?.lbl_address_detail : "Address Detail"}</h4>
        </div>
        <div className="col-sm-2 text-end">
          <button
            onClick={() => {
              props.previousStep(2);
            }}
            className="px-3 btn btn-sm btn-grey "
          >
            {constLabel?.lbl_edit ? constLabel?.lbl_edit : "Edit"}
          </button>
        </div>
      </div>
      <div className="row">
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_address ? constLabel?.lbl_address : `Address`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {address || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_address1 ? constLabel?.lbl_address1 : `Address1`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {address2 || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_country ? constLabel?.lbl_country : `Country`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {country_name || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_state ? constLabel?.lbl_state : `State`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {state_name || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_city ? constLabel?.lbl_city : `City`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {city_name || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_postal_code ? constLabel?.lbl_postal_code : `Postal Code`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {postalCode || "NA"}
            </p>
          </div>
        </div>
        <div className="col-12 col-md-4 d-flex">
          <div className="col-12 col-md-6">
            <p
              className="m-0  roboto-bold"
              style={{ fontSize: "14px" }}
            >{constLabel?.lbl_fax_no ? constLabel?.lbl_fax_no : `Fax No`}</p>
          </div>
          <div className="col-12 col-md-6">
            <p className="mb-2 nc_detail_label" style={{ fontSize: "18px" }}>
              {faxNo || "NA"}
            </p>
          </div>
        </div>
      </div>

      <hr className="my-3 mx-2" style={{ width: "98%" }} />
      <div className="row d-flex justify-content-between">
        <div className="col-sm-6 text-start">
          {" "}
          <h4 className="mb-4 roboto-bold">{constLabel?.lbl_contact_detail ? constLabel?.lbl_contact_detail : "Contact Detail"}</h4>
        </div>
        <div className="col-sm-2 text-end">
          <button
            onClick={() => {
              props.previousStep(3);
            }}
            className="px-3 btn btn-sm btn-grey "
          >
            {constLabel?.lbl_edit ? constLabel?.lbl_edit : "Edit"}
          </button>
        </div>
      </div>

      {party_contact.length > 0
        ? p_contact_array.map((item, ind) => {
            return (
              <div className={ind > 0 ? "row mt-4" : "row "}>
                {Object.keys(item).map((key) => (
                  <div className="col-12 col-md-4 d-flex" key={key}>
                    <div className="col-12 col-md-6">
                      <p
                        className="m-0 roboto-bold"
                        style={{ fontSize: "14px" }}
                      >
                        {key}
                      </p>
                    </div>
                    <div className="col-12 col-md-6">
                      <p
                        className="mb-2 nc_detail_label"
                        style={{ fontSize: "18px" }}
                      >
                        {item[key] || "NA"}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            );
          })
        : null}

      <hr className="my-3 mx-2" style={{ width: "98%" }} />
      <div className="row  mt-0 ">
        <div className="d-md-flex justify-content-end">
          <button
            onClick={() => {
              props.previousStep();
            }}
            className="btn btn-grey px-4 me-2"
          >
            {"Previous"}
          </button>
          <button
              className="btn button-primary px-4 me-2"
              onClick={onSubmit}
            >
              {constLabel?.lbl_submit ? constLabel?.lbl_submit : "Submit"}
            </button>
        </div>
      </div>
    </>
  );
}

export default Preview;

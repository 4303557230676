import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateProdCategory } from "../../../api";

const schema = yup
  .object()
  .shape({
    categoryname: yup
      .string()
      .trim() // Trim leading and trailing spaces
      .required("Product category name is required!"),
  })
  .required();

function EditProductCategory(props) {
  const [visible, setVisible] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        categoryname: data.categoryname.trim(),
      };

      updateProdCategory(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Product category updated successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchProd_Category(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_product_category
            ? props.constLabel?.lbl_edit_product_category
            : "Edit Product Category"
        }
        className="menu_icon_grey cursor-pointer me-2"
        size={20}
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true);
        }}
      />
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          reset();
        }}
        size={"md"}
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_product_category
              ? props.constLabel?.lbl_update_product_category
              : "Update Product Category"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_category_name
                  ? props.constLabel?.lbl_category_name
                  : "Category Name"}
                <span className="text-danger">*</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={
                    errors.categoryname
                      ? "form-control  is-invalid"
                      : "form-control "
                  }
                  id="categoryname"
                  name="categoryname"
                  placeholder="Enter category name"
                  defaultValue={props.data?.categoryname}
                  {...register("categoryname")}
                />
                <span className="text-danger err-msg">
                  {errors.categoryname?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditProductCategory;

import React, { useState, useEffect } from 'react';
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Trash2 } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from 'react-router-dom';
import {
    getProdTypeList, getProdCatList, getCurrList, getToolGradeList, getToolFamilyList,
    getMarketGrp, getProdFamily, getPartyList, createProductApi, createPartyAgaintProdApi
} from "../../../../api/index";
import { ToastContainer, toast } from "react-toastify";

const schema = yup
    .object()
    .shape({
        productType: yup.array().min(1, 'Product type is required !').required('Product type is required !'),
        category: yup.array().min(1, 'Category is required !').required('Category is required !'),
        marketGroup: yup.array().min(1, 'Verifier is required !').required('Verifier is required !'),
        productFamily: yup.array().min(1, 'Verifier is required !').required('Verifier is required !'),
        productName: yup.string().required("Name is required !"),
        oem: yup.string().required("OEM part no is required !"),
        prodCost: yup.string().required("Production cost no is required !"),
        salePrice2: yup.string().required("Sale price is required !"),
        items: yup.array().of(
            yup.object().shape({
                partno: yup.string().required("Required !"),
                salePrice: yup.string().required("Required !"),
                partyNo: yup.array().min(1, 'Required !').required('Required !'),
                currency: yup.array().min(1, 'Required !').required('Required !'),
            })
        ),
    })
    .required();

function ManfGoodForm() {

    const [prodTypeList, setProdTypeList] = useState({ data: [], loading: false });
    const [prodCatList, setProdCatList] = useState({ data: [], loading: false });
    const [prodCurrList, setCurrList] = useState({ data: [], loading: false });
    const [toolGradeList, setToolGradeList] = useState({ data: [], loading: false });
    const [toolFamList, setToolFamList] = useState({ data: [], loading: false });
    const [marktetGrpList, setMarketGrpList] = useState({ data: [], loading: false });
    const [prodFamList, setProdFamList] = useState({ data: [], loading: false });
    const [partyList, setPartyList] = useState({ data: [], loading: false });

    const { register, handleSubmit, formState, control, reset, setValue, getValues } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        defaultValues: {
            productType: [], category: [], marketGroup: [], productFamily: [], subCategory: "",
            subCategory: "", productName: "", hsn: "", openingStock: "", drawingNo: "", oem: "",
            material: "", diameter: "", cutLength: "", finishLength: "", cncWeight: "", htWeight: "", finishWeight: "",
            prodCost: "", salePrice2: "", remarks: "", file: "",
            items: [{ partyNo: [], partno: "", salePrice: "", currency: "" }]
        },
    }); // initialise the hook

    let { errors } = formState;

    let navigate = useNavigate();

    const { fields, append, remove } = useFieldArray({
        control,
        name: "items",
    });


    useEffect(() => {
        window.history.pushState(null, "", window.location.href);
        window.onpopstate = function () {
            
            window.history.pushState(null, "", window.location.href);
        };

        fetchProdType();
        fetchProdCatList();
        fetchCurr();
        fetchToolGList();
        fetchToolFList();
        fetchMarketGrp();
        fetchProdFamGrp();
        fetchPartyList();
    }, []);


    function fetchToolGList() {
        setToolGradeList({ ...toolGradeList, data: [], loading: true });

        getToolGradeList({
            "action": "alllist"
        }).then((res) => {
            
            setToolGradeList({ ...toolGradeList, data: res.data.TGlist, loading: false });
        }, (err) => {
            
            setToolGradeList({ ...toolGradeList, data: [], loading: false });
        })
    }
    function fetchToolFList() {
        setToolFamList({ ...toolFamList, data: [], loading: true });

        getToolFamilyList({
            "action": "alllist"
        }).then((res) => {
            
            setToolFamList({ ...toolFamList, data: res.data.ToolFamilyList, loading: false });
        }, (err) => {
            
            setToolFamList({ ...toolFamList, data: [], loading: false });
        })
    }

    function fetchCurr() {
        setCurrList({ ...prodCurrList, data: [], loading: true });

        getCurrList().then((res) => {
            
            setCurrList({ ...prodCurrList, data: res.data.currencyList, loading: false });
        }, (err) => {
            
            setCurrList({ ...prodCurrList, data: [], loading: false });
        })
    }

    function fetchProdCatList() {
        setProdCatList({ ...prodCatList, data: [], loading: true });
        getProdCatList().then((res) => {
            
            setProdCatList({ ...prodCatList, data: res.data.ProductCategoryList, loading: false });
        }, (err) => {
            
            setProdCatList({ ...prodCatList, data: [], loading: false });
        })
    }

    function fetchProdType() {
        setProdTypeList({ ...prodTypeList, data: [], loading: true });

        getProdTypeList({
            "action": "alllist"
        }).then((res) => {
            
            setProdTypeList({ ...prodTypeList, data: res.data.ProductTypeList, loading: false });
        }, (err) => {
            
            setProdTypeList({ ...prodTypeList, data: [], loading: false });
        })
    }
    function fetchMarketGrp() {
        setMarketGrpList({ ...marktetGrpList, data: [], loading: true });

        getMarketGrp({
            "action": "alllist"
        }).then((res) => {
            
            setMarketGrpList({ ...marktetGrpList, data: res.data.marketGrp, loading: false });
        }, (err) => {
            
            setMarketGrpList({ ...marktetGrpList, data: [], loading: false });
        })
    }

    function fetchProdFamGrp() {
        setProdFamList({ ...prodFamList, data: [], loading: true });

        getProdFamily().then((res) => {
            
            setProdFamList({ ...prodFamList, data: res.data.prodfam, loading: false });
        }, (err) => {
            
            setProdFamList({ ...prodFamList, data: [], loading: false });
        })
    }

    function fetchPartyList() {
        setPartyList({ ...partyList, data: [], loading: true });

        getPartyList({
            "action": "alllist"
        }).then((res) => {
            
            setPartyList({ ...partyList, data: res.data.PartyList, loading: false });
        }, (err) => {
            
            setPartyList({ ...partyList, data: [], loading: false });
        })
    }

    const onSubmit = (data) => {
        

        // Need to find out how to send created by id

        const payload = {
            action: "create",
            category: data.category[0].categoryname,
            drawingno: data.drawingNo,
            hsn: data.hsn,
            machineCost: data.prodCost,
            market_group: data.marketGroup[0].grpId,
            openingstock: data.openingStock,
            partNo: data.oem,
            partyId: "",
            productName: data.productName,
            productType: data.productType[0].ProductTypeId,
            product_family: data.productFamily[0].familyId,
            remark: data.remarks,
            salesPrice: data.salePrice2,
            subcategory: data.subCategory,
            OEMnumber: data.oem,
            productId: "",
            createdBy_id: 1,
            modifiedBy_id: "",
            material: data.material,
            diameter: data.diameter,
            clength: data.cutLength,
            wpkpm: 0,
            wpp: 0,
            fl: data.finishLength,
            cncweight: data.cncWeight,
            htweight: data.htWeight,
            finishweight: data.finishWeight,
            doc_image: data.file.length > 0 ? data.file[0] : "",
            operations: "",
        }

        const formDataToSend = new FormData();

        for (const [key, value] of Object.entries(payload)) {
            formDataToSend.append(key, value);
        }


        createProductApi(formDataToSend).then((res) => {
            

            var payload2 = [];

            for (let i = 0; i < data.items.length; i++) {
                payload2.push({
                    currency: data.items[i]?.currency[0]?.currency,
                    customerPartNo: data.items[i].partno,
                    id: "proddetail",
                    partyId: data.items[i].partyNo[0].PartyId,
                    prodcustId: "",
                    productId: res.data.productId,
                    salsePrice: data.items[i].salePrice

                })
            }

            createPartyAgaintProdApi({ party: payload2 }).then((res) => {
                

                toast.success('Product created successfully !', {
                    position: "top-right",
                    autoClose: 2000,
                    closeOnClick: true,
                    theme: "light",
                });

                reset();

            }, (err) => {
                
            })

        }, (err) => {
            

            toast.error('Something went wrong !', {
                position: "top-right",
                autoClose: 2000,
                closeOnClick: true,
                theme: "light",
            });

        })
    }



    return (
        <React.Fragment>
            <ToastContainer
                position="top-right"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            {/* Same as */}
            <ToastContainer />



            <div className='manufac_goods_form mb-4'>
                <div className='card'>

                    <div className='card-header bg-grey d-flex align-items-center'>
                        <button className='btn btn-yellow' onClick={() => {
                            if (window.confirm("Are you sure to go back, your present data will be lost !")) {
                                window.onpopstate = undefined;
                                window.history.back();

                                setTimeout(() => {
                                    navigate(-1);
                                }, [1000])

                            }
                        }}><FontAwesomeIcon icon={faArrowLeft} size="sm" /></button>

                        <p className='mb-0 ms-3' style={{ fontSize: '24px' }}>Create Manufactured Goods</p>
                    </div>
                    <div className='card-body'>

                        <div>
                            <hr className='my-2' />
                            <p className='roboto-bold mb-0' style={{ fontSize: '20px' }}>Product Summary</p>
                            <hr className='my-2' />


                            <div className='row py-3'>
                                <div className='col-12 col-md-6 row mb-3 me-0 me-md-1'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>Product Type
                                        <span className='text-danger'>*</span></label>

                                    <div className='col-12 col-md-9'>

                                        <Controller
                                            name="productType"
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (

                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="ProductTypeName"
                                                    className=''
                                                    multiple={false}
                                                    size='sm'
                                                    options={prodTypeList.data}
                                                    isLoading={prodTypeList.loading}
                                                    placeholder="Select"
                                                    onChange={onChange}
                                                    selected={value}
                                                    onBlur={onBlur}
                                                    isInvalid={errors.productType ? true : false}
                                                />
                                            )}
                                        />

                                        {errors.productType
                                            && <span className='text-danger err-msg'>{errors.productType.message}</span>}
                                    </div>
                                </div>

                                <div className='col-12 col-md-6 row mb-3'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>Category
                                        <span className='text-danger'>*</span></label>

                                    <div className='col-12 col-md-9'>

                                        <Controller
                                            name="category"
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (

                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="categoryname"
                                                    multiple={false}
                                                    size='sm'
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    options={prodCatList.data}
                                                    isLoading={prodCatList.loading}
                                                    placeholder="Select"
                                                    isInvalid={errors.category ? true : false}
                                                />

                                            )}
                                        />

                                        {errors.category
                                            && <span className='text-danger err-msg'>{errors.category.message}</span>}
                                    </div>
                                </div>

                                <div className='col-12 col-md-6 row mb-3  me-0 me-md-1'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>Market Group
                                        <span className='text-danger'>*</span></label>

                                    <div className='col-12 col-md-9'>

                                        <Controller
                                            name="marketGroup"
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (

                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="groupname"
                                                    className=''
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    multiple={false}
                                                    size='sm'
                                                    selected={value}
                                                    options={marktetGrpList.data}
                                                    isLoading={marktetGrpList.loading}
                                                    placeholder="Select"
                                                    isInvalid={errors.marketGroup ? true : false}
                                                />

                                            )}
                                        />


                                        {errors.marketGroup
                                            && <span className='text-danger err-msg'>{errors.marketGroup.message}</span>}
                                    </div>
                                </div>

                                <div className='col-12 col-md-6 row mb-3'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>Product Family
                                        <span className='text-danger'>*</span></label>

                                    <div className='col-12 col-md-9'>

                                        <Controller
                                            name="productFamily"
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (

                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="familyname"
                                                    multiple={false}
                                                    size='sm'
                                                    options={prodFamList.data}
                                                    isLoading={prodFamList.loading}
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    placeholder="Select"
                                                    isInvalid={errors.productFamily ? true : false}
                                                />

                                            )}
                                        />

                                        {errors.productFamily
                                            && <span className='text-danger err-msg'>{errors.productFamily.message}</span>}
                                    </div>

                                </div>

                                <div className='col-12 col-md-6 row mb-3  me-0 me-md-1'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>Sub Category</label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter product subcategory'
                                            type="text"
                                            {...register(`subCategory`)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6 row mb-3'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>Product Name
                                        <span className='text-danger'>*</span></label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter product name'
                                            type="text"
                                            {...register(`productName`)}
                                            className={errors.productName ?
                                                "form-control form-control-sm is-invalid" : "form-control form-control-sm"} />

                                        {errors.productName
                                            && <span className='text-danger err-msg'>{errors.productName.message}</span>}

                                    </div>
                                </div>

                                <div className='col-12 col-md-6 row  me-0 me-md-1'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>HSN</label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter HSN'
                                            type="text"
                                            {...register(`hsn`)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6 row '>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>Opening Stock</label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter opening stock'
                                            type="text"
                                            {...register(`openingStock`)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>
                            </div>

                            <hr className='my-2' />
                            <p className='roboto-bold mb-0' style={{ fontSize: '20px' }}>Product Details</p>
                            <hr className='my-2' />


                            <div className='row py-3'>

                                <div className='col-12 col-md-6 row mb-3  me-0 me-md-1'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>Drawing No</label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter drawing no'
                                            type="text"
                                            {...register(`drawingNo`)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6 row mb-3'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>OEM Part No
                                        <span className='text-danger'>*</span></label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter OEM part no'
                                            type="text"
                                            {...register(`oem`)}
                                            className={errors.oem ?
                                                "form-control form-control-sm is-invalid" : "form-control form-control-sm"}
                                        />

                                        {errors.oem
                                            && <span className='text-danger err-msg'>{errors.oem.message}</span>}
                                    </div>
                                </div>

                                <div className='col-12 col-md-6 row mb-3  me-0 me-md-1'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>Material</label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter material'
                                            type="text"
                                            {...register(`material`)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6 row mb-3'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>Ø in mm</label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter diameter'
                                            type="text"
                                            {...register(`diameter`)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6 row mb-3  me-0 me-md-1'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>Cut Length in mm.</label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter cut length'
                                            type="text"
                                            {...register(`cutLength`)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6 row mb-3'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>Finish Length</label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter Finish length'
                                            type="text"
                                            {...register(`finishLength`)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6 row mb-3  me-0 me-md-1'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>CNC Weight</label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter cnc weight'
                                            type="text"
                                            {...register(`cncWeight`)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6 row'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>HT Weight</label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter ht weight'
                                            type="text"
                                            {...register(`htWeight`)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>

                                <div className='col-12 col-md-6 row  me-0 me-md-1'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>Finish Weight</label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter Finish weight'
                                            type="text"
                                            {...register(`finishWeight`)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>
                            </div>

                            <hr className='my-2' />
                            <p className='roboto-bold mb-0' style={{ fontSize: '20px' }}>Product Price</p>
                            <hr className='my-2' />


                            <div className='row py-3'>
                                <div className='col-12 col-md-6 row mb-3  me-0 me-md-1'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>Production Cost
                                        <span className='text-danger'>*</span></label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter machine cost'
                                            type="text"
                                            {...register(`prodCost`)}
                                            className={errors.prodCost ?
                                                "form-control form-control-sm is-invalid" : "form-control form-control-sm"} />

                                        {errors.prodCost
                                            && <span className='text-danger err-msg'>{errors.prodCost.message}</span>}
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 row mb-3'>
                                    <label
                                        style={{ fontSize: '14px' }}

                                        className='col-form-label col-12 col-md-3'>Sale Price
                                        <span className='text-danger'>*</span></label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            {...register(`salePrice2`)}
                                            placeholder='Enter sales price'
                                            type="text"
                                            className={errors.salePrice2 ?
                                                "form-control form-control-sm is-invalid" : "form-control form-control-sm"} />

                                        {errors.salePrice2
                                            && <span className='text-danger err-msg'>{errors.salePrice2.message}</span>}
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 row  me-0 me-md-1'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>Remark</label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter remark'
                                            type="text"
                                            {...register(`remarks`)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>
                                <div className='col-12 col-md-6 row'>
                                    <label
                                        style={{ fontSize: '14px' }}
                                        className='col-form-label col-12 col-md-3'>File Upload</label>

                                    <div className="col-12 col-md-9">
                                        <input
                                            placeholder='Enter drawing no'
                                            type="file"
                                            {...register(`file`)}
                                            className="form-control form-control-sm" />
                                    </div>
                                </div>
                            </div>

                            <hr className='my-2' />

                            <div className="table-responsive py-3" style={{ overflowX: 'unset' }}>
                                <table className="table table-bordered">
                                    <thead className="thead-light">
                                        <tr>
                                            <th scope="col">{"Party No"}</th>
                                            <th scope="col">{"Customer Part Number"}</th>
                                            <th scope="col">{"Sale Price"}</th>
                                            <th scope="col">{"Currency"}</th>
                                            <th scope="col">{"Action"}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {fields.map(({ id }, index) => (
                                            <tr key={id} className="f-18">
                                                <td>
                                                    <Controller
                                                        name={`items[${index}].partyNo`}
                                                        control={control}
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <Typeahead
                                                                size="sm"
                                                                id="basic-typeahead"
                                                                labelKey="PartyName"
                                                                multiple={false}
                                                                options={partyList.data}
                                                                isLoading={partyList.loading}
                                                                placeholder="Select"
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                selected={value}
                                                                isInvalid={errors.items?.[index]?.partyNo ? true : false}
                                                            />
                                                        )}
                                                    />
                                                    {errors.items?.[index]?.partyNo
                                                        && <span className='text-danger err-msg'>{errors.items?.[index]?.partyNo.message}</span>}

                                                    {index + 1 == fields.length ?
                                                        <div>
                                                            <button
                                                                style={{ fontSize: "16px" }}
                                                                className="btn btn-sm button-primary px-2 mt-2"
                                                                onClick={() => append({})}
                                                            >
                                                                <FontAwesomeIcon
                                                                    style={{ marginRight: "5px" }}
                                                                    icon={faAdd}
                                                                    size="sm"
                                                                /> Add
                                                            </button>
                                                        </div>
                                                        : null}
                                                </td>
                                                <td style={{ verticalAlign: 'top' }}>
                                                    <input
                                                        className={errors.items?.[index]?.partno ?
                                                            "form-control form-control-sm is-invalid" :
                                                            "form-control form-control-sm"}
                                                        type="text"
                                                        name={`items[${index}].partno`}
                                                        {...register(`items[${index}].partno`)}
                                                        placeholder="Enter part number"
                                                    />

                                                    {errors.items?.[index]?.partno
                                                        && <span className='text-danger err-msg'>{errors.items?.[index]?.partno.message}</span>}
                                                </td>
                                                <td style={{ verticalAlign: 'top' }}>
                                                    <input
                                                        className={errors.items?.[index]?.salePrice ?
                                                            "form-control form-control-sm is-invalid" :
                                                            "form-control form-control-sm"}
                                                        type="text"
                                                        name={`items[${index}].salePrice`}
                                                        {...register(`items[${index}].salePrice`)}
                                                        placeholder="Enter sale price"
                                                    />

                                                    {errors.items?.[index]?.salePrice
                                                        && <span className='text-danger err-msg'>{errors.items?.[index]?.salePrice.message}</span>}
                                                </td>
                                                <td style={{ verticalAlign: 'top' }}>
                                                    <Controller
                                                        name={`items[${index}].currency`}
                                                        control={control}
                                                        render={({ field: { onChange, onBlur, value, ref } }) => (
                                                            <Typeahead
                                                                size="sm"
                                                                id="basic-typeahead"
                                                                labelKey="currency"
                                                                multiple={false}
                                                                options={prodCurrList.data}
                                                                isLoading={prodCurrList.loading}
                                                                placeholder="Select"
                                                                onChange={onChange}
                                                                onBlur={onBlur}
                                                                selected={value}
                                                                isInvalid={errors.items?.[index]?.currency ? true : false}
                                                            />
                                                        )}
                                                    />

                                                    {errors.items?.[index]?.currency
                                                        && <span className='text-danger err-msg'>{errors.items?.[index]?.currency.message}</span>}
                                                </td>


                                                <td style={{ width: "70px", textAlign: 'center', verticalAlign: "top" }}>
                                                    <div
                                                        className="deleteIcon cursor_pointer"
                                                        onClick={index > 0 ? () => remove(index) : null}
                                                    >
                                                        <Trash2 color="#FFFFFF" size={18} />
                                                    </div>

                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>


                            </div>





                        </div>
                    </div>

                    <div className='card-footer py-3'>
                        <button
                            onClick={handleSubmit(onSubmit)}
                            className='btn btn-grey px-3 float-end'>Submit</button>
                    </div>
                </div>
            </div>
        </React.Fragment >
    )
}

export default ManfGoodForm
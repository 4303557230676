import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Trash2 } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from "sweetalert2";
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import {
  getAttribute,
  getUOM,
  createProductAttribute,
  getProductAttribute,
  deleteProductAttribute,
  updateProductAttribute, getProdConfig
} from "../../../../api";
import { getLabels } from "../../../../common/Functions/CommonFunctions";
import { useNavigate } from "react-router-dom";
import CloneBom from "./CloneBom";

const schema = yup
  .object()
  .shape({
    items: yup.array().of(
      yup.object().shape({
        name: yup
          .string()
          .required("Name is required !")
          .matches(
            /^[a-zA-Z0-9 ]*$/,
            "Only alphabets, number and space Allowed !"
          ),
        attribute: yup
          .array()
          .min(1, "Please select attribute")
          .required("Attribute is required !"),
        value: yup
          .string()
          .required("Value is required !")
          .matches(/^\d+(\.\d+)?$/, "Value must be a number !"),
        minValue: yup
          .string()
          .required("Min value is required !")
          .matches(/^\d+(\.\d+)?$/, "Min value must be a number !")
          .test(
            "minValueLessThanValue",
            "Min value must be less than value !",
            function (value) {
              const val = this.resolve(yup.ref("value"));
              return parseFloat(value) <= parseFloat(val);
            }
          ),
        maxValue: yup
          .string()
          .required("Max value is required !")
          .matches(/^\d+(\.\d+)?$/, "Max value must be a number !")
          .test(
            "maxValueGreaterThanValue",
            "Max value must be greater than value !",
            function (value) {
              const val = this.resolve(yup.ref("value"));
              return parseFloat(value) >= parseFloat(val);
            }
          ),
        unit: yup
          .array()
          .min(1, "Please select uni")
          .required("Unit is required !"),
      })
    ),
    // selfile: yup
    //     .mixed()
    //     .test('fileSize', 'Image can be uploaded upto 10 MB',
    //         (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)),
  })
  .required();

function CloneProductAttribute({ nextStep, previousStep, main_obj, setMain_obj }) {

  let navigate = useNavigate();

  const [attributeList, setAttribList] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [prod_attr, setProd_Attr] = useState({ data: [], loading: false });
  const [action, setAction] = useState("create");
  const prod_data = JSON.parse(localStorage.getItem("prod_res"));
  const [constLabel, setConstLabel] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [bomShow, setBomShow] = useState(false);
  const [oprList, setOprList] = useState({ data: [], loading: false });

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [{}],
    },
  });

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  useEffect(() => {
    var attrArr = [];
    if (prod_attr.data.length > 0) {
      for (let i = 0; i < prod_attr.data.length; i++) {
        let temp = {
          productId: prod_attr.data[i].productId,
          product_id: prod_attr.data[i].product_id,
          name: prod_attr.data[i].name,
          attribute: [
            {
              name: prod_attr.data[i].attname,
              attId: prod_attr.data[i].attId,
              attribute_id: prod_attr.data[i].uuid,
            },
          ],
          value: prod_attr.data[i].value,
          minValue: prod_attr.data[i].minValue,
          maxValue: prod_attr.data[i].maxValue,
          unit: [
            {
              uom: prod_attr.data[i].unitname,
              unit: prod_attr.data[i].uomid,
              unit_id: prod_attr.data[i].uuid,
            },
          ],
          product_operation: prod_attr.data[i].product_operation !== null ?
            [prod_attr.data[i].product_operation] : [],
          remark: prod_attr.data[i].remark,
          uuid: prod_attr.data[i].uuid,
        }
        
        
        if (action == 'create') {
          
          delete temp.uuid;
        }
        attrArr.push(temp)
      }
      
      // setValue("items", legArr);
      reset({
        items: attrArr,
      });
    } else {
      reset({
        items: [{}],
      });
    }
  }, [prod_attr, action]);

  useEffect(() => {
    setConstLabel(getLabels("Master", "Product"));
    fetchAttribute();
    fetchUOM();
    // fetching product attributes of new created product
    if (main_obj.prod_overview?.uuid !== undefined) {
      fetchProductSpecifications();
    }
    fetchProdOperations();
    // else {
    //   toast.error("Created product uuid not found !", {
    //     autoClose: 2000,
    //   });
    // }
  }, []);

  function fetchProdOperations() {
    setOprList({ ...oprList, loading: true, data: [] });
    getProdConfig({ product_id: main_obj?.prod_overview?.uuid }).then((res) => {
      

      setOprList({ ...oprList, loading: false, data: res.data.data });
    }, (err) => {

      
      setOprList({ ...oprList, loading: false, data: [] });
    });
  }

  function fetchAttribute() {
    setAttribList({ ...attributeList, data: [], loading: true });
    getAttribute().then(
      (res) => {
        
        setAttribList({
          ...attributeList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setAttribList({ ...attributeList, data: [], loading: false });
      }
    );
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM().then(
      (res) => {
        
        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchProductSpecifications() {
    setProd_Attr({ ...prod_attr, data: [], loading: true });
    const payload = {
      pageNo: 1,
      entriesPerPage: 1000,
      product_id: main_obj && main_obj.prod_overview?.uuid
    };
    getProductAttribute(payload).then(
      (res) => {
        
        setProd_Attr({ ...prod_attr, data: res.data.data, loading: false });

        // fetching product attributes of product from which we are cloning
        if (res.data.data.length == 0) {
          
          payload.product_id = main_obj && main_obj.old_prod_uuid;
          getProductAttribute(payload).then(
            (res) => {
              
              setProd_Attr({ ...prod_attr, data: res.data.data, loading: false });
              setAction("create");
            },
            (err) => {
              
              setProd_Attr({ ...prod_attr, data: [], loading: false });
            }
          );

        } else {

          setAction("update");
        }
      },
      (err) => {
        
        setProd_Attr({ ...prod_attr, data: [], loading: false });
      }
    );
  }

  // const onSubmit = (data) => {
  //   props.setCurrentStep(props.currentStep+1)
  // }

  const onSubmit = (data) => {
    setBtnDisable(true);
    
    let reqPayload = [];
    let updatePayload = [];
    if (data !== "") {
      if (data.items.length > 0) {
        for (let i = 0; i < data.items.length; i++) {
          const payload = {
            productId: main_obj && main_obj?.prod_overview?.productId,
            product_id: main_obj && main_obj?.prod_overview?.uuid,
            // productId: "Prod_ID-1171",
            // product_id: "43045592-2755-43f7-b18e-f3520aa4d398",
            name: data.items[i].name,
            attId: data.items[i].attribute[0]?.attId,
            attname: data.items[i].attribute[0]?.name,
            attribute_id: data.items[i].attribute[0]?.uuid,
            value: data.items[i].value,
            minValue: data.items[i].minValue,
            maxValue: data.items[i].maxValue,
            unit: data.items[i].unit[0]?.uomid,
            unitname: data.items[i].unit[0]?.uom,
            unit_id: data.items[i].unit[0]?.uuid,
            remark: data.items[i].remark,
          };

          if (data.items[i].product_operation !== null && data.items[i].product_operation.length > 0) {
            payload.product_operation = data.items[i].product_operation[0].uuid;
            payload.product_operation_name = data.items[i].product_operation[0].operationname;
          }


          if (!data.items[i].uuid) {
            reqPayload.push(payload);
          } else {
            payload.uuid = data.items[i].uuid;
            updatePayload.push(payload);
          }
        }

        
        // nextStep();

        createProductAttribute({ data: reqPayload }).then(
          (res) => {
            
            setBtnDisable(false);
            if (res.status === 200 || res.status === 201) {
              toast.success("Product attribute created successfully !", {
                autoClose: 3000,
              });

              if (updatePayload.length > 0) {
                updateProductAttribute({ data: updatePayload }).then(
                  (res) => {
                    
                    if (res.status === 200 || res.status === 201) {
                      toast.success("Product attribute updated successfully !", {
                        autoClose: 3000,
                      });
                    }
                  },
                  (err) => {
                    
                    if (err.response.status === 400) {
                      
                      toast.error(err.response, {
                        autoClose: 2000,
                      });

                    }
                  }
                )
              }
              if (!main_obj?.obj_bom?.flag) {
                // setTimeout(() => {
                //   navigate("/pcms/product");
                //   localStorage.removeItem("prod_res");
                // }, [2200]);
                setMain_obj({ ...main_obj, prod_specs: res.data.data });
                setTimeout(() => {
                  nextStep();
                }, [2300]);
              } else {
                setMain_obj({ ...main_obj, prod_specs: res.data.data });
                setBomShow(true);
                // nextStep();
              }

              // props.setCurrentStep((prevStep) => prevStep + 1);
              // setMain_obj({...main_obj, prod_attrib: res.data});

              // reset();
            }
          },
          (err) => {
            setBtnDisable(false);
            
            if (err.response.status === 400) {
              
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
            }
          }
        )

      } else {
        // props.setCurrentStep((prevStep) => prevStep + 1);
        setTimeout(() => {
          nextStep();
        }, [2300]);
      }
    } else {
      errors.showMessages();
    }
  };

  function deleteAttri(index) {
    

    SweetAlert.fire({
      title: "Are you sure to delete",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        if (fields[index].uuid !== null && fields[index].uuid !== undefined) {
          deleteProductAttribute({ uuid: fields[index].uuid }).then(
            (res) => {
              toast.success("Attribute Deleted Successfully !", {
                autoClose: 1000,
              });
              fetchProductSpecifications();
            },
            (err) => {
              toast.error("Something went wrong ");
            }
          );
        } else {
          remove(index);
        }
      }
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />

      {bomShow === false ? (
        <div className="card shadow-sm rounded-0 p-2">
          <div className="card-body">
            <div className="d-flex justify-content-between align-items-center">
              <p className="pc_card_title roboto-bold">
                {constLabel?.lbl_product_attribute
                  ? constLabel?.lbl_product_attribute
                  : "Product Attribute"}
              </p>
              <button
                style={{ fontSize: "16px" }}
                className="btn btn-sm button-primary px-2 mb-2"
                onClick={() => {
                  append({});
                }}
              >
                <FontAwesomeIcon
                  style={{
                    color: "#ffffff",
                  }}
                  icon={faAdd}
                  size="sm"
                />{" "}
              </button>
            </div>

            <div className="col-12">
              {fields.length > 0 ? (
                <div className="">
                  <table className="table table-responsive table-bordered my-2">
                    <thead className="table-grey">
                      <tr>
                        <th>{"Sr.No"}</th>
                        <th>
                          {constLabel?.lbl_name ? constLabel?.lbl_name : "Name"}
                        </th>
                        <th>
                          {constLabel?.lbl_attribute
                            ? constLabel?.lbl_attribute
                            : "Attribute"}
                        </th>
                        <th>
                          {constLabel?.lbl_value
                            ? constLabel?.lbl_value
                            : "Value"}
                        </th>
                        <th>
                          {constLabel?.lbl_min_value
                            ? constLabel?.lbl_min_value
                            : "Min Value"}
                        </th>
                        <th>
                          {constLabel?.lbl_max_value
                            ? constLabel?.lbl_max_value
                            : "Max Value"}
                        </th>
                        <th>
                          {constLabel?.lbl_unit ? constLabel?.lbl_unit : "Unit"}
                        </th>
                        <th>
                          {constLabel?.lbl_operation_name
                            ? constLabel.lbl_operation_name
                            : "Operation"}
                        </th>
                        <th>
                          {constLabel?.lbl_remark
                            ? constLabel?.lbl_remark
                            : "Remark"}
                        </th>
                        <th>{"Action"}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {fields.map(({ id }, index) => (
                        <tr key={id}>
                          <td width="50px" style={{ verticalAlign: "top" }}>
                            <input
                              className="form-control form-control-sm"
                              type="text"
                              readOnly
                              placeholder={index + 1}
                            />
                          </td>
                          <td width="200px" style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.name
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              name={`items[${index}].name`}
                              {...register(`items[${index}].name`)}
                              placeholder="Enter attribute name"
                            />

                            {errors.items?.[index]?.name && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.name.message}
                              </span>
                            )}
                          </td>
                          <td width="200px" style={{ verticalAlign: "top" }}>
                            <Controller
                              name={`items[${index}].attribute`}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  size="sm"
                                  id="basic-typeahead"
                                  labelKey="name"
                                  multiple={false}
                                  options={attributeList.data}
                                  isLoading={attributeList.loading}
                                  placeholder="Select attribute"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={
                                    errors.items?.[index]?.attribute
                                      ? true
                                      : false
                                  }
                                />
                              )}
                            />
                            {errors.items?.[index]?.attribute && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.attribute.message}
                              </span>
                            )}
                          </td>

                          <td width="120px" style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.value
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              name={`items[${index}].value`}
                              {...register(`items[${index}].value`)}
                              placeholder="Enter value"
                            />

                            {errors.items?.[index]?.value && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.value.message}
                              </span>
                            )}
                          </td>
                          <td width="120px" style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.minValue
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              name={`items[${index}].minValue`}
                              {...register(`items[${index}].minValue`)}
                              placeholder="Enter min value"
                            />

                            {errors.items?.[index]?.minValue && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.minValue.message}
                              </span>
                            )}
                          </td>
                          <td width="120px" style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.maxValue
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              name={`items[${index}].maxValue`}
                              {...register(`items[${index}].maxValue`)}
                              placeholder="Enter max value"
                            />

                            {errors.items?.[index]?.maxValue && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.maxValue.message}
                              </span>
                            )}
                          </td>
                          <td width="120px" style={{ verticalAlign: "top" }}>
                            <Controller
                              name={`items[${index}].unit`}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  size="sm"
                                  id="basic-typeahead"
                                  labelKey="uom"
                                  multiple={false}
                                  options={uomList.data}
                                  isLoading={uomList.loading}
                                  placeholder="Select unit"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={
                                    errors.items?.[index]?.unit ? true : false
                                  }
                                />
                              )}
                            />
                            {errors.items?.[index]?.attribute && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.attribute.message}
                              </span>
                            )}
                          </td>

                          <td width="200px" style={{ verticalAlign: "top" }} >
                            <Controller
                              name={`items[${index}].product_operation`}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  size="sm"
                                  id="basic-typeahead"
                                  labelKey="operationname"
                                  multiple={false}
                                  options={oprList.data}
                                  isLoading={oprList.loading}
                                  placeholder="Select operation"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={
                                    errors.items?.[index]?.product_operation
                                      ? true
                                      : false
                                  }
                                />
                              )}
                            />
                            {errors.items?.[index]?.product_operation && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.product_operation.message}
                              </span>
                            )}
                          </td>

                          <td width="200px" style={{ verticalAlign: "top" }}>
                            <input
                              className={
                                errors.items?.[index]?.remark
                                  ? "form-control form-control-sm is-invalid"
                                  : "form-control form-control-sm"
                              }
                              type="text"
                              name={`items[${index}].remark`}
                              {...register(`items[${index}].remark`)}
                              placeholder="Enter remark"
                            />

                            {errors.items?.[index]?.remark && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.remark.message}
                              </span>
                            )}
                          </td>
                          <td>
                            <div
                              className="deleteIcon cursor_pointer"
                              onClick={() => deleteAttri(index)}
                            >
                              <Trash2 color="#FFFFFF" size={18} />
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <div
                  className="alert alert-danger mb-0 text-center my-4"
                  role="alert"
                >
                  {"No specifications found !"}
                </div>
              )}
              {/* <div className="d-flex justify-content-end">
              <button
                style={{ fontSize: "16px" }}
                className="btn btn-sm btn-yellow px-2 mt-2"
                onClick={() => append({})}
              >
                <FontAwesomeIcon
                  style={{
                    color: "#000000",
                    marginRight: "5px",
                  }}
                  icon={faAdd}
                  size="sm"
                />{" "}
                {constLabel?.lbl_add_more
                  ? constLabel?.lbl_add_more
                  : "Add More"}
              </button>
            </div> */}
            </div>

            <hr className="my-4" />
            <div className="d-flex justify-content-end">
              {/* <button
              className="btn btn-yellow px-4 me-2"
              onClick={() => navigate("/pcms/product")}
            >
              {constLabel?.lbl_cancel ? constLabel?.lbl_cancel : ""}
            </button> */}
              <button
                className="btn btn-grey px-4 me-2"
                onClick={() => previousStep()}
              >
                {constLabel?.lbl_previous ? constLabel?.lbl_previous : "Previous"}
              </button>
              <button
                className="btn button-primary px-4 me-0 me-md-2"
                onClick={handleSubmit(onSubmit)}
                disabled={btnDisable}
              >
                {constLabel?.lbl_save_continue ? constLabel?.lbl_save_continue : "Save & Continue"}
              </button>
              {main_obj?.obj_bom?.flag === true ? (
                <button
                  className="btn btn-grey px-4 "
                  // onClick={() => props.setCurrentStep(props.currentStep + 1)}
                  onClick={() => {
                    if (!main_obj?.obj_bom?.flag) {
                      nextStep();
                    } else {
                      setBomShow(true);
                      // nextStep();
                    }
                  }}
                >
                  {constLabel?.lbl_skip ? constLabel?.lbl_skip : "Skip"}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      ) : (

        <CloneBom
          main_obj={main_obj}
          setMain_obj={setMain_obj}
          nextStep={nextStep}
          setBomShow={setBomShow}
          bomShow={bomShow}
        />
      )}

    </React.Fragment>
  );
}

export default CloneProductAttribute;

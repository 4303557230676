import React, { useState, useEffect, useReducer } from "react";
import { Controller, useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faBan,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal, Spinner} from "react-bootstrap";
import { Download, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import axios from "axios";
import {  deletTransactionMaster, getTransactionMaster,  saveTransactionMaster } from "../../../api";
import { calMaxPage, getLabels } from "../../../common/Functions/CommonFunctions";
import { Typeahead } from "react-bootstrap-typeahead";
import EditMovementMaster from "./EditMovementMaster";
import { Tooltip } from "react-tooltip";

const schema = yup
  .object()
  .shape({
    movementCode:yup
    .string()
    .required("Please enter movement code !")
    .matches(/^[0-9]+$/, 'Only numeric characters are allowed'),
    transaction: yup
      .array()
      .min(1, "Please select any one transaction !")
      .required("Please select any one transaction !"),
  })
  .required();

function MovementMaster() {

  
  const [visible, setVisible] = useState(false);

  const [apiBySearch, setApiBySearch] = useState(false);
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const [transaction,setTransactionMaster]=useState({data:[],loading:false});

  const [constLabel, setConstLabel] = useState({});

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const initialState = {
    loading: false,
    error: "",
    movementList: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "HANDLE_ACTION":
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          movementList: action.movementList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    
    setConstLabel(getLabels('Master', 'Movement'));
    dispatch({
      type: "HANDLE_ACTION",
      page: state.page,
      loading: false,
      error: state.error,
      movementList: state.movementList,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchTransactions();
  }, [state.page, state.itemsPerPage]);

  function fetchTransactions() {
    setTransactionMaster({data:[],loading:true})
  
    getTransactionMaster().then(
      (res) => {
       
       setTransactionMaster({data:res.data.data,loading:false})
      },
      (err) => {
        

        setTransactionMaster({data:[],loading:false})
        if (err.response.status === 500) {
          toast.error("Something went wrong !", { autoClose: 5000 });
        }
      }
    );
  }

  const onSubmit = (data) => {
    


    return
    if (data !== "") {
      const reqPayload = {
        transaction_name: data.transaction_name,
        transaction_code: data.transaction_code,
        action: data.action[0].name,
        description: data.description
      };

      


      saveTransactionMaster(reqPayload)
        .then(
          (res) => {
            
            if (res.status === 200 || res.status === 201) {
              toast.success("Transaction master created Successfully !", {
                autoClose: 3000,
              });
              setVisible(false);
              fetchTransactions();
              reset();
            }
          },
          (err) => {
            
            if (err.response.status === 400) {
              
              toast.error(err.response.data.non_field_errors[0], {
                autoClose: 2000,
              });

            }
          }
        )

    } else {
      errors.showMessages();
    }
  };

  // Deleting Trasaction master 
  const delTransactionMaster = (val) => {
    
    SweetAlert.fire({
      title: "Are you sure ?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
        };
        deletTransactionMaster(payload).then(
          (res) => {
            
            toast.success("Transaction master deleted successfully !", {
              autoClose: 3000,
            });

            if (state.page > 1 && state.movementList.length == 1) {
              
              fetchTransactions(null, null, "change");
            }
            else {
              fetchTransactions();
            }

          },
          (err) => {
            
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }


  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <div className="min-vh-100" id="root_div_main">
        <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>{constLabel?.lbl_add_movement ? constLabel?.lbl_add_movement : "Add Movement"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="">
              
            <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">
                  {constLabel?.lbl_movement_code ? constLabel?.lbl_movement_code : "Movement Code"} <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>

                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control form-control-sm"
                    id="movementCode"
                    name="movementCode"
                    placeholder="Enter movement code "
                    {...register("movementCode")}
                  />
                  <span className="text-danger">
                    {errors.movementCode?.message}
                  </span>
                </div>
              </div>
            
              <div className="form-group row mt-2">
                <label style={{ fontSize: "14px" }} className="col-form-label col-md-4">
                 {constLabel?.lbl_transaction_name ? constLabel?.lbl_transaction_name : "Transaction Name"}
                  <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-8">

                  <Controller
                    name={`transaction`}
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="transaction_name"
                        size="sm"
                        multiple={false}
                        options={transaction.data}
                        // isLoading={orgtype.loading}
                        placeholder="Select transaction"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.transaction ? true : false}
                      />
                    )}
                  />
                  {errors.transaction && (
                    <span className="text-danger err-msg">
                      {errors.transaction.message}
                    </span>
                  )}
                </div>
              </div>
              
            </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {constLabel?.lbl_save ? constLabel?.lbl_save : "Save"}
            </button>
          </Modal.Footer>
        </Modal>
        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div className="filter row">
              <label
                className="filterLabel mb-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_search ? constLabel?.lbl_search : "Search"}
              </label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control form-control-sm"
                  placeholder="Search by name"
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      fetchTransactions("search", e.target.value);
                      setApiBySearch(true);
                    } else {
                      fetchTransactions();
                    }
                  }}
                />
              </div>
            </div>
            <div className="my-3 d-flex justify-content-end">
              <button className="btn btn-sm btn-yellow">
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                {constLabel?.lbl_clear_filter ? constLabel?.lbl_clear_filter : "Clear Filter"}
              </button>
              {/* <button
                          className="btn btn-sm btn-grey ms-0 ms-3 px-4"
                      >
                          <FontAwesomeIcon style={{ color: "#fff" }} icon={faSearch} size="sm" />{" "}
                          Search
                      </button> */}
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="bold">{"Movement Master"}</h1>
            <div>
              {/* <button
                className="btn btn-grey px-3 me-0 me-md-2"
                onClick={() => {
                  openNav();
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faFilter}
                  size="sm"
                />
                Filter Menu
              </button> */}

              <button
                className="btn button-primary px-4"
                onClick={() => {
                  setVisible(true);
                  reset();
                }}
              >
                <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faPlus}
                  size="sm"
                />{" "}
                {constLabel?.lbl_add_movement ? constLabel?.lbl_add_movement : "Add Movement"}
              </button>
            </div>
          </div>
          <div className="card border-0 mt-3">
            <div className="card-body">
              <div className="d-flex justify-content-start mb-3 me-3">
                <div>
                  <p className="mb-0">
                    Show
                    <select
                      className="mx-1"
                      defaultValue={"10"}
                      // onChange={(e) => {
                      //   setEntriesPerPage(e.target.value);
                      //   fetchProdConfig(page, e.target.value);
                      // }}
                      onChange={(e) => {
                        dispatch({
                          type: "HANDLE_ACTION",
                          page: 1,
                          loading: state.loading,
                          error: state.error,
                          movementList: state.movementList,
                          itemsPerPage: e.target.value,
                          maxPage: state.maxPage,
                        })
                        setSrNo(0);
                      }

                      }
                    >
                      <option value={"10"}>10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    Entries
                  </p>
                </div>
              </div>
              <div className="table-responsive">
                {state.loading ? (
                  <div className="loader-box justify-content-center">
                    <Spinner />
                  </div>
                ) : state.movementList && state.movementList.length > 0 ? (
                  <div className="table-responsive">
                    <table
                      className="table table-bordered table-sm"
                      width={"100%"}
                    >
                      <thead className="table-grey roboto">
                        <tr className="">
                          <th scope="col" width="15%">
                            {"Sr. No"}
                          </th>
                          <th scope="col">{constLabel?.lbl_movement_code ? constLabel?.lbl_movement_code : "Movement Code"}</th>
                          <th scope="col">{constLabel?.lbl_transaction_name ? constLabel?.lbl_transaction_name : "Transaction Name"}</th>
                          <th scope="col" width="15%">
                            {"Action"}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="roboto">
                        {state.movementList.map((val, i) => (
                          <tr key={i} className="">
                            <td>{i + srNo + 1}</td>
                            <td>{val.transaction_name}</td>
                            <td>{val.transaction_code}</td>
                            <td>
                              <EditMovementMaster
                                data={val}
                                constLabel={constLabel} 
                                fetchTransactions={fetchTransactions}
                              />
                              <Tooltip id={"delele-tooltip" + i} place="top" />
                                <div
                                  data-tooltip-id={"delete-tooltip" + i}
                                  data-tooltip-content={constLabel?.lbl_movement_code ? constLabel?.lbl_movement_code : "Delete Movement"}
                                  className="deleteIcon me-2"
                                  onClick={() => delTransactionMaster(val)}
                                >
                                  <Trash2 color="#FFFFFF" size={18} />
                                </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : apiBySearch ? (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"No Movement Master found !"}
                  </p>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"Movement master  is empty"}
                  </p>
                )}

                {state.loading ? null : (
                  <div className="mt-4 d-flex justify-content-between">
                    <p className='mb-0'>{`Showing ${parseInt(srNo) + 1} to ${Math.min(parseInt(state.itemsPerPage) + parseInt(srNo), totalRecords)} of ${totalRecords} entries`}</p>
                    <Pagination className="my-2">
                      <Pagination.Prev
                        disabled={state.page === 1 ? true : false}
                        onClick={() => {
                          dispatch({
                            type: "HANDLE_ACTION",
                            page: state.page - 1,
                            loading: state.loading,
                            error: state.error,
                            movementList: state.movementList,
                            itemsPerPage: state.itemsPerPage,
                            maxPage: state.maxPage,
                          });
                          setSrNo((prevC) => prevC - parseInt(state.itemsPerPage));
                        }}
                      >
                        {"Prev"}
                      </Pagination.Prev>

                      <Pagination.Item active>{state.page}</Pagination.Item>

                      <Pagination.Next
                        disabled={
                          state.page === state.maxPage || state.maxPage === 0
                            ? true
                            : false
                        }
                        onClick={() => {
                          dispatch({
                            type: "HANDLE_ACTION",
                            page: state.page + 1,
                            loading: state.loading,
                            error: state.error,
                            movementList: state.movementList,
                            itemsPerPage: state.itemsPerPage,
                            maxPage: state.maxPage,
                          });
                          setSrNo((prevC) => prevC + parseInt(state.itemsPerPage));
                        }}
                      >
                        {"Next"}
                      </Pagination.Next>
                    </Pagination>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default MovementMaster
import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray, set } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { get_country, get_state, get_city } from "../../../../../api/index";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";

const schema = yup.object().shape({
  addressLine1: yup.string().required("Address is required !"),
  cState: yup
    .array()
    .min(1, "Please select state !")
    .required("Please select state !"),
  cCountry: yup
    .array()
    .min(1, "Please select country !")
    .required("Please select country !"),
  cCity: yup
    .array()
    .min(1, "Please select city !")
    .required("Please select city !"),
  postalCode: yup
    .string()
    .required("Postal code is required !")
    .matches(/^[0-9]*$/, "Enter valid postal code"),
  addressLine1_ship: yup.string().required("Address is required !"),
  cState_ship: yup
    .array()
    .min(1, "Please select state !")
    .required("Please select state !"),
  cCountry_ship: yup
    .array()
    .min(1, "Please select country !")
    .required("Please select country !"),
  cCity_ship: yup
    .array()
    .min(1, "Please select city !")
    .required("Please select city !"),
  postalCode_ship: yup
    .string()
    .required("Postal code is required !")
    .matches(/^[0-9]*$/, "Enter valid postal code"),
});

function NewCreateBillingAddress(props) {
  let navigate = useNavigate();
  let location = useLocation();
  const [constLabel, setConstLabel] = useState({});
  const { billingAddresse, shippingAddresse } = props.main_obj;

  const {
    country,
    country_id,
    country_name,
    city,
    city_id,
    city_name,
    state,
    state_id,
    state_name,
  } = billingAddresse?.length > 0 && billingAddresse?.[0];

  const {
    country: country_s,
    country_id: country_id_s,
    country_name: country_name_s,
    city: city_s,
    city_id: city_id_s,
    city_name: city_name_s,
    state: state_s,
    state_id: state_id_s,
    state_name: state_name_s,
  } = shippingAddresse?.length > 0 && shippingAddresse?.[0];

  const [countryList, setCountryList] = useState({ data: [], state: false });
  const [stateList, setStateList] = useState({ data: [], state: false });
  const [cityList, setCityList] = useState({ data: [], state: false });

  const [countryList_ship, setCountryList_ship] = useState({
    data: [],
    state: false,
  });
  const [stateList_ship, setStateList_ship] = useState({
    data: [],
    state: false,
  });
  const [cityList_ship, setCityList_ship] = useState({
    data: [],
    state: false,
  });

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    getValues,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  const selectedCountry = watch("cCountry");
  const selectedState = watch("cState");

  const selectedCountry_ship = watch("cCountry_ship");
  const selectedState_ship = watch("cState_ship");

  let { errors } = formState;

  useEffect(() => {
    console.log("props", props);
    setConstLabel(getLabels("Party Master", "Customer"));
    setCountryList({ ...countryList, state: true, data: [] });

    get_country().then(
      (res) => {
        setCountryList({
          ...countryList,
          state: false,
          data: res.data.data,
        });

        if (location && location?.pathname !== "/pcms/neweditCustomer") {
          let result = res.data.data.filter(
            (item) => item.countryName === "India"
          );
          setValue("cCountry", result);
        }
      },
      (err) => {
        //
        setCountryList({ ...countryList, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }, []);

  useEffect(() => {
    // fetching states for selected country through api
    if (selectedCountry !== undefined && selectedCountry.length !== 0) {
      setStateList({ ...stateList, data: [], state: true });

      const payload = {
        country_id: selectedCountry[0]?.uuid,
      };
      get_state(payload).then(
        (res) => {
          setStateList({
            ...stateList,
            data: res.data.data,
            state: false,
          });
        },
        (err) => {
          setStateList({ ...stateList, data: [], state: false });
          // toast.error("Something went wrong in fetching states !", { autoClose: 5000 })
        }
      );
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedState !== undefined && selectedState.length > 0) {
      setCityList({ ...cityList, data: [], state: true });

      const payload = {
        state_id: selectedState[0]?.uuid,
      };

      get_city(payload).then(
        (res) => {
          setCityList({ ...cityList, data: res.data.data, state: false });
        },
        (err) => {
          setCityList({ ...cityList, data: [], state: false });
          // toast.error("Something went wrong in fetching cities !", { autoClose: 5000 })
        }
      );
    }
  }, [selectedState]);

  useEffect(() => {
    setCountryList_ship({ ...countryList_ship, state: true, data: [] });

    get_country().then(
      (res) => {
        setCountryList_ship({
          ...countryList_ship,
          state: false,
          data: res.data.data,
        });

        if (location && location?.pathname !== "/pcms/neweditCustomer") {
          let result = res.data.data.filter(
            (item) => item.countryName === "India"
          );
          setValue("cCountry_ship", result);
        }
      },
      (err) => {
        //
        setCountryList_ship({ ...countryList_ship, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }, []);

  useEffect(() => {
    // fetching states for selected country through api
    if (
      selectedCountry_ship !== undefined &&
      selectedCountry_ship.length !== 0
    ) {
      setStateList_ship({ ...stateList_ship, data: [], state: true });

      const payload = {
        country_id: selectedCountry_ship[0]?.uuid,
      };
      get_state(payload).then(
        (res) => {
          setStateList_ship({
            ...stateList_ship,
            data: res.data.data,
            state: false,
          });
        },
        (err) => {
          setStateList_ship({ ...stateList_ship, data: [], state: false });
          // toast.error("Something went wrong in fetching states !", { autoClose: 5000 })
        }
      );
    }
  }, [selectedCountry_ship]);

  useEffect(() => {
    if (selectedState_ship !== undefined && selectedState_ship.length > 0) {
      setCityList_ship({ ...cityList_ship, data: [], state: true });

      const payload = {
        state_id: selectedState_ship[0]?.uuid,
      };

      get_city(payload).then(
        (res) => {
          setCityList_ship({
            ...cityList_ship,
            data: res.data.data,
            state: false,
          });
        },
        (err) => {
          setCityList_ship({ ...cityList_ship, data: [], state: false });
          // toast.error("Something went wrong in fetching cities !", { autoClose: 5000 })
        }
      );
    }
  }, [selectedState_ship]);

  const onSubmit = (data) => {
    if (data !== "") {
      let billing_obj = {};
      billing_obj.country = (data?.cCountry[0]?.id).toString();
      billing_obj.state = (data?.cState[0]?.id).toString();
      billing_obj.city = (data?.cCity[0]?.id).toString();
      billing_obj.country_name = data?.cCountry[0]?.countryName;
      billing_obj.state_name = data?.cState[0]?.stateName;
      billing_obj.city_name = data?.cCity[0]?.cityName;
      billing_obj.country_id = data?.cCountry[0]?.uuid;
      billing_obj.state_id = data?.cState[0]?.uuid;
      billing_obj.city_id = data?.cCity[0]?.uuid;
      billing_obj.addressType = data.addressType;
      billing_obj.addressLine1 = data.addressLine1;
      billing_obj.addressLine2 = data.addressLine2;
      billing_obj.postalCode = data.postalCode;

      let shipping_obj = {};
      shipping_obj.country = (data?.cCountry_ship[0]?.id).toString();
      shipping_obj.state = (data?.cState_ship[0]?.id).toString();
      shipping_obj.city = (data?.cCity_ship[0]?.id).toString();
      shipping_obj.country_name = data?.cCountry_ship[0]?.countryName;
      shipping_obj.state_name = data?.cState_ship[0]?.stateName;
      shipping_obj.city_name = data?.cCity_ship[0]?.cityName;
      shipping_obj.country_id = data?.cCountry_ship[0]?.uuid;
      shipping_obj.state_id = data?.cState_ship[0]?.uuid;
      shipping_obj.city_id = data?.cCity_ship[0]?.uuid;
      shipping_obj.addressType = data.addressType_ship;
      shipping_obj.addressLine1 = data.addressLine1_ship;
      shipping_obj.addressLine2 = data.addressLine2_ship;
      shipping_obj.postalCode = data.postalCode_ship;

      // localStorage.setItem("billing", JSON.stringify([billing_obj]));
      // localStorage.setItem("shipping", JSON.stringify([shipping_obj]));

      if (location.pathname === "/pcms/neweditCustomer") {
        billing_obj.uuid = props.main_obj.billingAddresse?.[0]?.uuid;
        shipping_obj.uuid = props.main_obj.shippingAddresse?.[0]?.uuid;
      }

      props.setMain_obj({
        ...props.main_obj,
        billingAddresse: [billing_obj],
        shippingAddresse: [shipping_obj],
      });
      props.nextStep();
    } else {
      errors.showMessages();
    }
  };

  const handleCheck = (e) => {
    if (e.target.checked) {
      let coun = getValues("cCountry");
      let state = getValues("cState");
      let city = getValues("cCity");
      let addline1 = getValues("addressLine1");
      let addline2 = getValues("addressLine2");
      let post = getValues("postalCode");

      if (coun.length > 0) {
        setValue("cCountry_ship", coun, { shouldValidate: true });
      }
      if (state.length > 0) {
        setValue("cState_ship", state, { shouldValidate: true });
      }
      if (city.length > 0) {
        setValue("cCity_ship", city, { shouldValidate: true });
      }
      if (addline1 !== "") {
        setValue("addressLine1_ship", addline1, { shouldValidate: true });
      }
      if (addline2 !== "") {
        setValue("addressLine2_ship", addline2, { shouldValidate: true });
      }
      if (post !== "") {
        setValue("postalCode_ship", post, { shouldValidate: true });
      }
    } else {
      setValue("cCountry_ship", []);
      setValue("cState_ship", []);
      setValue("cCity_ship", []);
      setValue("addressLine1_ship", "");
      setValue("addressLine2_ship", "");
      setValue("postalCode_ship", "");
    }
  };

  return (
    <>
      <h4 className="mb-4 roboto-bold">
        {constLabel?.lbl_billing_address
          ? constLabel?.lbl_billing_address
          : "Billing Address"}
      </h4>

      <div className="row mt-3">
        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_address_line1
                ? constLabel.lbl_address_line1
                : "Address Line1"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <input
              type="hidden"
              className={
                errors.addressLine1
                  ? "form-control  is-invalid"
                  : "form-control "
              }
              id="addressType"
              name={`addressType`}
              defaultValue={"billing"}
              {...register(`addressType`)}
            />
            <input
              type="text"
              className={
                errors.addressLine1
                  ? "form-control  is-invalid"
                  : "form-control "
              }
              id="addressLine1"
              name={`addressLine1`}
              required
              placeholder="Enter address line1"
              defaultValue={
                props.main_obj.billingAddresse?.length > 0
                  ? props.main_obj.billingAddresse[0].addressLine1 ?? ""
                  : props.main_obj.address
              }
              {...register(`addressLine1`)}
            />

            <span className="text-danger err-msg">
              {errors.addressLine1?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_address_line2
                ? constLabel.lbl_address_line2
                : "Address Line2"}
            </label>
            <input
              type="text"
              className="form-control "
              id="addressLine2"
              name={`addressLine2`}
              placeholder="Enter address line2"
              defaultValue={
                props.main_obj.billingAddresse?.length > 0 &&
                props.main_obj.billingAddresse[0].addressLine2 !== "" &&
                props.main_obj.billingAddresse[0].addressLine2 !== null
                  ? props.main_obj.billingAddresse[0].addressLine2 ?? ""
                  : ""
              }
              {...register(`addressLine2`)}
            />
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_country ? constLabel.lbl_country : "Country"}{" "}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <Controller
              name={`cCountry`}
              control={control}
              defaultValue={
                billingAddresse?.length > 0 && country_name !== null
                  ? [
                      {
                        id: country,
                        countryName: country_name,
                        uuid: country_id,
                      },
                    ]
                  : [
                      {
                        id: props.main_obj?.country,
                        countryName: props.main_obj?.country_name,
                        uuid: props.main_obj?.country_id,
                      },
                    ]
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="countryName"
                  multiple={false}
                  options={countryList.data}
                  isLoading={countryList.state}
                  placeholder="Select country"
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  defaultSelected={
                    billingAddresse?.length > 0 && country_name !== null
                      ? [
                          {
                            id: country,
                            countryName: country_name,
                            uuid: country_id,
                          },
                        ]
                      : [
                          {
                            id: props.main_obj?.country,
                            countryName: props.main_obj?.country_name,
                            uuid: props.main_obj?.country_id,
                          },
                        ]
                  }
                  isInvalid={errors.cCountry ? true : false}
                />
              )}
            />

            <span className="text-danger err-msg">
              {errors.cCountry?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_state ? constLabel.lbl_state : "State"}{" "}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <Controller
              name={`cState`}
              control={control}
              // rules={{ validate: validateCState }}
              defaultValue={
                billingAddresse?.length > 0 && state_name
                  ? [{ id: state, stateName: state_name, uuid: state_id }]
                  : [
                      {
                        id: props.main_obj?.state,
                        stateName: props.main_obj?.state_name,
                        uuid: props.main_obj?.state_id,
                      },
                    ]
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="stateName"
                  multiple={false}
                  onChange={onChange}
                  isLoading={stateList.state}
                  options={stateList.data.length > 0 ? stateList.data : []}
                  placeholder="Select state"
                  selected={value}
                  defaultSelected={
                    billingAddresse?.length > 0 && state_name
                      ? [{ id: state, stateName: state_name, uuid: state_id }]
                      : [
                          {
                            id: props.main_obj?.state,
                            stateName: props.main_obj?.state_name,
                            uuid: props.main_obj?.state_id,
                          },
                        ]
                  }
                  isInvalid={errors.cState ? true : false}
                />
              )}
            />

            <span className="text-danger err-msg">
              {errors.cState?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_city ? constLabel.lbl_city : "City"}{" "}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <Controller
              name={`cCity`}
              control={control}
              // rules={{ validate: validateCCity }}
              defaultValue={
                billingAddresse?.length > 0 && city_name !== null
                  ? [{ id: city, cityName: city_name, uuid: city_id }]
                  : props.main_obj?.city_name !== "" && props.main_obj?.city_name !== null ? [
                      {
                        id: props.main_obj?.city,
                        cityName: props.main_obj?.city_name,
                        uuid: props.main_obj?.city_id,
                      },
                    ] : []
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="cityName"
                  multiple={false}
                  options={cityList.data.length > 0 ? cityList.data : []}
                  isLoading={cityList.state}
                  placeholder="Select city"
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  defaultSelected={
                    billingAddresse?.length > 0 && city_name !== null
                      ? [{ id: city, cityName: city_name, uuid: city_id }]
                      : props.main_obj?.city_name !== "" && props.main_obj?.city_name !== null ? [
                        {
                          id: props.main_obj?.city,
                          cityName: props.main_obj?.city_name,
                          uuid: props.main_obj?.city_id,
                        },
                      ] : []
                  }
                  isInvalid={errors.cCity ? true : false}
                />
              )}
            />

            <span className="text-danger err-msg">{errors.cCity?.message}</span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_postal_code
                ? constLabel.lbl_postal_code
                : "Postal code"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <input
              type="text"
              className="form-control "
              id="postalCode"
              name="postalCode"
              maxLength={6}
              placeholder="Enter postal code"
              defaultValue={
                props.main_obj.billingAddresse?.length > 0 &&
                props.main_obj.billingAddresse[0].postalCode !== null
                  ? props.main_obj.billingAddresse[0].postalCode ?? ""
                  : props.main_obj.postalCode
              }
              {...register(`postalCode`)}
            />

            <span className="text-danger err-msg">
              {errors.postalCode?.message}
            </span>
          </div>
        </div>
      </div>

      <hr className="my-3 mx-2" style={{ width: "98%" }} />
      <h4 className="mb-4 roboto-bold">Shipping Address</h4>

      <div className="form-check mt-3">
        <input
          className="form-check-input"
          type="checkbox"
          defaultChecked={
            props.main_obj?.address &&
            (props.main_obj?.billingAddresse?.[0]?.addressLine1 || props.main_obj?.billingAddresses?.[0]?.addressLine1) &&
            (props.main_obj?.shippingAddresse?.[0]?.addressLine1 || props.main_obj?.shippingAddresses?.[0]?.addressLine1) &&
            (
              props.main_obj.address == props.main_obj.address ||
              props.main_obj.billingAddresse[0].addressLine1 == 
              props.main_obj.shippingAddresse[0].addressLine1 ||
              props.main_obj.billingAddresses[0].addressLine1 == 
              props.main_obj.shippingAddresses[0].addressLine1
            )
          }
          // checked={
          //   props.main_obj?.address &&
          //   (props.main_obj?.billingAddresse?.[0]?.addressLine1 || props.main_obj?.billingAddresses?.[0]?.addressLine1) &&
          //   (props.main_obj?.shippingAddresse?.[0]?.addressLine1 || props.main_obj?.shippingAddresses?.[0]?.addressLine1) &&
          //   (
          //     props.main_obj.address == props.main_obj.address ||
          //     props.main_obj.billingAddresse[0].addressLine1 == 
          //     props.main_obj.shippingAddresse[0].addressLine1 ||
          //     props.main_obj.billingAddresses[0].addressLine1 == 
          //     props.main_obj.shippingAddresses[0].addressLine1
          //   )
          // }
          onChange={(e) => {
            handleCheck(e);
          }}
        />
        <label className="form-check-label roboto">
          {" "}
          {" Same as billing Address"}
        </label>
      </div>

      <div className="row mt-3">
        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label roboto-bold"
            >
              {constLabel?.lbl_address_line1
                ? constLabel.lbl_address_line1
                : "Address Line1"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <input
              type="hidden"
              className={
                errors.addressLine1
                  ? "form-control  is-invalid"
                  : "form-control "
              }
              id="addressType_ship"
              name={`addressType_ship`}
              defaultValue={"shipping"}
              {...register(`addressType_ship`)}
            />
            <input
              type="text"
              className={
                errors.addressLine1_ship
                  ? "form-control  is-invalid"
                  : "form-control "
              }
              id="addressLine1_ship"
              name={`addressLine1_ship`}
              required
              placeholder="Enter address line1"
              defaultValue={
                props.main_obj.shippingAddresse?.length > 0
                  ? props.main_obj.shippingAddresse[0].addressLine1 ?? ""
                  : props.main_obj?.address
              }
              {...register(`addressLine1_ship`)}
            />

            <span className="text-danger err-msg">
              {errors.addressLine1_ship?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_address_line2
                ? constLabel.lbl_address_line2
                : "Address Line2"}
            </label>
            <input
              type="text"
              className="form-control "
              id="addressLine2_ship"
              name={`addressLine2_ship`}
              placeholder="Enter address line2"
              defaultValue={
                props.main_obj.shippingAddresse?.length > 0 &&
                props.main_obj.shippingAddresse[0].addressLine2 !== "" &&
                props.main_obj.shippingAddresse[0].addressLine2 !== null
                  ? props.main_obj.shippingAddresse[0].addressLine2 ?? ""
                  : ""
              }
              {...register(`addressLine2_ship`)}
            />
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_country ? constLabel.lbl_country : "Country"}{" "}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <Controller
              name={`cCountry_ship`}
              control={control}
              defaultValue={
                shippingAddresse?.length > 0 && country_name !== null
                  ? [
                      {
                        id: country_s,
                        countryName: country_name_s,
                        uuid: country_id_s,
                      },
                    ]
                  : [
                      {
                        id: props.main_obj?.country,
                        countryName: props.main_obj?.country_name,
                        uuid: props.main_obj?.country_id,
                      },
                    ]
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="countryName"
                  multiple={false}
                  options={countryList_ship.data}
                  isLoading={countryList_ship.state}
                  placeholder="Select country"
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  defaultSelected={
                    shippingAddresse?.length > 0 && country_name !== null
                      ? [
                          {
                            id: country_s,
                            countryName: country_name_s,
                            uuid: country_id_s,
                          },
                        ]
                      : [
                          {
                            id: props.main_obj?.country,
                            countryName: props.main_obj?.country_name,
                            uuid: props.main_obj?.country_id,
                          },
                        ]
                  }
                  isInvalid={errors.cCountry_ship ? true : false}
                />
              )}
            />

            <span className="text-danger err-msg">
              {errors.cCountry_ship?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_state ? constLabel.lbl_state : "State"}{" "}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <Controller
              name={`cState_ship`}
              control={control}
              // rules={{ validate: validateCState_ship }}
              defaultValue={
                shippingAddresse?.length > 0 && state_name !== null
                  ? [
                      {
                        id: state_s,
                        stateName: state_name_s,
                        uuid: state_id_s,
                      },
                    ]
                  : [
                      {
                        id: props.main_obj?.state,
                        stateName: props.main_obj?.state_name,
                        uuid: props.main_obj?.state_id,
                      },
                    ]
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="stateName"
                  multiple={false}
                  onChange={onChange}
                  isLoading={stateList_ship.state}
                  options={
                    stateList_ship.data.length > 0 ? stateList_ship.data : []
                  }
                  placeholder="Select state"
                  selected={value}
                  defaultSelected={
                    shippingAddresse?.length > 0 && state_name !== null
                      ? [
                          {
                            id: state_s,
                            stateName: state_name_s,
                            uuid: state_id_s,
                          },
                        ]
                      : [
                          {
                            id: props.main_obj?.state,
                            stateName: props.main_obj?.state_name,
                            uuid: props.main_obj?.state_id,
                          },
                        ]
                  }
                  isInvalid={errors.cState_ship ? true : false}
                />
              )}
            />

            <span className="text-danger err-msg">
              {errors.cState_ship?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_city ? constLabel.lbl_city : "City"}{" "}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <Controller
              name={`cCity_ship`}
              control={control}
              // rules={{ validate: validateCCity_ship }}
              defaultValue={
                shippingAddresse?.length > 0 && city_name !== null
                  ? [{ id: city_s, cityName: city_name_s, uuid: city_id_s }]
                  : props.main_obj?.city_name !== "" && props.main_obj?.city_name !== null ? [
                    {
                      id: props.main_obj?.city,
                      cityName: props.main_obj?.city_name,
                      uuid: props.main_obj?.city_id,
                    },
                  ] : []
              }
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Typeahead
                  id="basic-typeahead-single"
                  labelKey="cityName"
                  multiple={false}
                  options={
                    cityList_ship.data.length > 0 ? cityList_ship.data : []
                  }
                  isLoading={cityList_ship.state}
                  placeholder="Select city"
                  onChange={onChange}
                  onBlur={onBlur}
                  selected={value}
                  defaultSelected={
                    shippingAddresse?.length > 0 && city_name !== null
                      ? [
                          {
                            id: city_s,
                            cityName: city_name_s,
                            uuid: city_id_s,
                          },
                        ]
                      : props.main_obj?.city_name !== "" && props.main_obj?.city_name !== null ? [
                        {
                          id: props.main_obj?.city,
                          cityName: props.main_obj?.city_name,
                          uuid: props.main_obj?.city_id,
                        },
                      ] : []
                  }
                  isInvalid={errors.cCity_ship ? true : false}
                />
              )}
            />

            <span className="text-danger err-msg">
              {errors.cCity_ship?.message}
            </span>
          </div>
        </div>

        <div className="col-12 col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_postal_code
                ? constLabel.lbl_postal_code
                : "Postal code"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <input
              type="text"
              className="form-control "
              id="postalCode_ship"
              name="postalCode_ship"
              maxLength={6}
              placeholder="Enter postal code"
              defaultValue={
                props.main_obj.shippingAddresse?.length > 0 &&
                props.main_obj.shippingAddresse[0].postalCode !== null
                  ? props.main_obj.shippingAddresse[0].postalCode ?? ""
                  : props.main_obj.postalCode
              }
              {...register(`postalCode_ship`)}
            />

            <span className="text-danger err-msg">
              {errors.postalCode_ship?.message}
            </span>
          </div>
        </div>
      </div>
      <hr className="my-3 mx-2" style={{ width: "98%" }} />

      <div className="d-md-flex justify-content-end">
        <button
          className="btn btn-grey px-4 me-2"
          onClick={() => props.previousStep()}
        >
          {constLabel?.lbl_previous ? constLabel?.lbl_previous : "Previous"}
        </button>
        <button
          className="btn button-primary px-4 me-2"
          onClick={handleSubmit(onSubmit)}
        >
          {constLabel?.lbl_save_continue
            ? constLabel?.lbl_save_continue
            : "Save & Continue"}
        </button>
        {/* <button
          className="btn btn-grey px-4 me-2"
          onClick={() => props.nextStep()}
        >
          {constLabel?.lbl_skip ? constLabel?.lbl_skip : "Skip"}
        </button> */}
      </div>
    </>
  );
}

export default NewCreateBillingAddress;

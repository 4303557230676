import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { getCountry, getState, getCity, get_country, get_state, get_city } from "../../../../../api/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";

const schema = yup.object().shape({
    address1: yup.string().required("Address is required !"),
    cCountry: yup.array().min(1, "Please select country").required("Please select country"),
    cState: yup.array().min(1, "Please select state").required("Please select state"),
    cCity: yup.array().min(1, "Please select city").required("Please select city"),
    postalCode: yup.string().matches(/^[0-9]*$/, 'Enter valid postal code').notRequired(),
    faxNo: yup.string().matches(/^[0-9() -]*$/, 'Enter valid fax number').notRequired(),
  })
  .required();

function CreateAddressDetail(props) {
  const [countryList, setCountryList] = useState({ data: [], state: false });
  const [stateList, setStateList] = useState({ data: [], state: false });
  const [cityList, setCityList] = useState({ data: [], state: false });

  const [constLabel, setConstLabel] = useState({});

  var FormData = require("form-data");
  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook


  const selectedCountry = watch("cCountry");
  const selectedState = watch("cState");

  let { errors } = formState;

  useEffect(() => {
    // resetting the form
    setConstLabel(getLabels('Party Master', 'Supplier'));
    reset();
    setCountryList({ ...countryList, state: true, data: [] });

    //axios.post("https://masters.ravicam.in/api/master/country/",{payload}).then(
    get_country().then(
      (res) => {
        
        setCountryList({
          ...countryList,
          state: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setCountryList({ ...countryList, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }, []);

  useEffect(() => {
    

    // fetching states for selected country through api
    if (selectedCountry !== undefined && selectedCountry.length !== 0) {
      setStateList({ ...stateList, data: [], state: true });
      
      const payload = {
        country_id: selectedCountry[0]?.uuid,
      };
      get_state(payload).then(
        (res) => {
          
          setStateList({
            ...stateList,
            data: res.data.data,
            state: false,
          });
        },
        (err) => {
          
          setStateList({ ...stateList, data: [], state: false });
          // toast.error("Something went wrong in fetching states !", { autoClose: 5000 })
        }
      );
    }
  }, [selectedCountry]);

  useEffect(() => {
    
    if (selectedState !== undefined && selectedState.length > 0) {
      setCityList({ ...cityList, data: [], state: true });
      

      const payload = {
        state_id: selectedState[0]?.uuid,
      };

      get_city(payload).then(
        (res) => {
          
          setCityList({ ...cityList, data: res.data.data, state: false });
        },
        (err) => {
          
          setCityList({ ...cityList, data: [], state: false });
          // toast.error("Something went wrong in fetching cities !", { autoClose: 5000 })
        }
      );
    }
  }, [selectedState]);

  const onSubmit = (data) => {

    

    if (data !== "") {
      const address = {
        address: data.address1,
        address2: data.address2,
        country: data.cCountry[0]?.id,
        state: data.cState[0]?.id,
        city: data.cCity[0]?.id,
        country_name: data.cCountry[0]?.countryName,
        state_name: data.cState[0]?.stateName,
        city_name: data.cCity[0]?.cityName,
        country_id: data.cCountry[0]?.uuid,
        state_id: data.cState[0]?.uuid,
        city_id: data.cCity[0]?.uuid,
        faxNo: data.faxNo,
        postalCode: data.postalCode,
      };
      
      

      localStorage.setItem("address", JSON.stringify(address));
      props.setKey("contactDetail");

    } else {
      errors.showMessages();
    }
  };

  return (
    <>
      <div className="row mt-2">
        <div className="col-md-6">
          <div className="form-group row">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
              {constLabel?.lbl_address1 ? constLabel.lbl_address1 : "Address1"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <div className="col-md-8">
              <textarea
                type="text"
                className={errors.address1 ? "form-control  is-invalid"
                        : "form-control "}
                id="address1"
                name="address1"
                placeholder="Enter address 1"
                {...register("address1")}
              ></textarea>
              <span className="text-danger err-msg">{errors.address1?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
              {constLabel?.lbl_country ? constLabel.lbl_country : "Country"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <div className="col-md-8">
              <Controller
                name="cCountry"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="countryName"
                    multiple={false}
                    // size="sm"
                    options={
                      countryList.data !== undefined ? countryList.data : []
                    }
                    isLoading={countryList.state}
                    placeholder="Select country"
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid={errors.cCountry ? true : false}
                    
                  />
                )}
              />
              <span className="text-danger">{errors.cCountry?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
              {constLabel?.lbl_city ? constLabel.lbl_city : "City"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <div className="col-md-8">
              <Controller
                name="cCity"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="cityName"
                    // size="sm"
                    multiple={false}
                    options={cityList.data !== undefined ? cityList.data : []}
                    isLoading={cityList.state}
                    placeholder="Select city"
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid={errors.cCity ? true : false}
                    
                  />
                )}
              />
              <span className="text-danger">{errors.cCity?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
              {constLabel?.lbl_fax_no ? constLabel.lbl_fax_no : "Fax No"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control "
                id="faxNo"
                name="faxNo"
                placeholder="Enter fax no"
                {...register("faxNo")}
              />
              <span className="text-danger">{errors.faxNo?.message}</span>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group row">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
              {constLabel?.lbl_address2 ? constLabel.lbl_address2 : "Address2"}
              {/* <span>
                <i style={{ color: "red" }}>*</i>
              </span> */}
            </label>
            <div className="col-md-8">
              <textarea
                type="text"
                className="form-control "
                id="address2"
                name="address2"
                placeholder="Enter address 2"
                {...register("address2")}
              ></textarea>
              {/* <span className="text-danger">{errors.address2?.message}</span> */}
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
              {constLabel?.lbl_state ? constLabel.lbl_state : "State"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <div className="col-md-8">
              <Controller
                name="cState"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="stateName"
                    multiple={false}
                    // size="sm"
                    options={stateList.data !== undefined ? stateList.data : []}
                    isLoading={stateList.state}
                    placeholder="Select state"
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid={errors.cState ? true : false}
                    
                  />
                )}
              />
              <span className="text-danger">{errors.cState?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
              {constLabel?.lbl_postal_code ? constLabel.lbl_postal_code : "Postal Code"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control "
                id="postalCode"
                name="postalCode"
                placeholder="Enter postal code"
                {...register("postalCode")}
                maxLength={6}
              />
              <span className="text-danger err-msg">{errors.postalCode?.message}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-between mt-3">
        <div className="col-md-4">
          <span>
            <i style={{ color: "red" }}>*</i>
            <u> indicate Mandatory fields</u>
          </span>
        </div>
        <div className="col-sm-2 text-end">
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {constLabel?.lbl_next ? constLabel.lbl_next : "Next"}
            {/* <FontAwesomeIcon
              style={{ color: "#000" }}
              icon={faPlay}
              // size="sm"
            /> */}
          </button>
        </div>
      </div>
    </>
  )
}

export default CreateAddressDetail
// features/users/userSlice.js
import { createSlice } from '@reduxjs/toolkit';
import { getUsers } from '../../api/userManagement';

const userSlice = createSlice({
    name: 'users',
    initialState: {
        page: 1,
        entriesPerPage: 10,
        maxPage: "",
        data: [],
        loading: false,
        error: null,
        totalPages: ""
    },
    reducers: {
        setUsers: (state, action) => {
            
            
            state.data = action.payload.da.data.data.response.results;
            state.loading = false;
            state.totalPages = parseInt(action.payload.da.data.data.response.count.totalData);
            state.maxPage = Math.ceil(action.payload.da.data.data.response.count.totalData / action.payload.ep);
            
            
            
        },
        setLoading: (state, action) => {
            state.loading = true;
        },
        setErr: (state, action) => {
            state.error = action.payload;
            state.data = [];
            state.loading = false;
        },
        setCurrentPage: (state, action) => {
            
            state.page = action.payload.page;
            state.entriesPerPage = action.payload.entriesPerPage;
        },
    },
});

export const { setUsers, setCurrentPage, setLoading, setErr } = userSlice.actions;

export const fetchUsers = (page, entriesPerPage) => async (dispatch) => {
    dispatch(setLoading());
    try {
        
        

        getUsers({ offset: page !== 0 ? page - 1 : page, limit: entriesPerPage }).then((res) => {
            
            dispatch(setUsers({ da: res, ep: entriesPerPage }));
        }, (err) => {
            
            dispatch(setUsers(err.response));
        });

    } catch (error) {
        // Handle error
    }
};

export default userSlice.reducer;

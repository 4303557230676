import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { update_challan_type } from "../../../api";

const schema = yup
  .object()
  .shape({
    cType: yup
      .array()
      .required("Please select challan type !")
      .min(1, "Please select challan type !"),
    typename: yup.string().required("Name is required !"),
    idprefix: yup
      .string()
      .required("ID prefix is required !")
      .matches(/^[a-zA-Z]*$/, "Enter valid id prefix !"),
  })
  .required();

function EditChallanType(props) {
  const [visible, setVisible] = useState(false);
  const [outOpr, setOutOpr] = useState(props.data.outoperation ? true : false);
  const [sameLocation, setSameLocation] = useState(
    props.data.sameLocation ? true : false
  );

  const [checkWip, setCheckWip] = useState(props.data.wip ? true : false);
  const [typChallan, setTypChallan] = useState({
    data: [
      { id: 1, name: "Inward" },
      { id: 2, name: "Outward" },
    ],
    state: false,
  });

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const onSubmit = (data) => {
    if (data !== "") {
      const payload = {
        uuid: props.data?.uuid,
        typename: data.typename,
        cType: data.cType?.[0]?.name,
        idprefix: data.idprefix,
        outoperation: outOpr,
        sameLocation: sameLocation,
        wip: checkWip,
        remark: data.remark,
      };

      update_challan_type(payload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Challan type updated successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchChallanType(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };
  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_type
            ? props.constLabel?.lbl_edit_type
            : "Edit Type"
        }
        className="menu_icon_grey cursor_pointer me-2"
        size={20}
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true);
          reset();
        }}
      />
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          reset();
        }}
        size={"md"}
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_challan_type
              ? props.constLabel?.lbl_update_challan_type
              : "Update Challan Type"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {props.constLabel?.lbl_challan_type
                  ? props.constLabel?.lbl_challan_type
                  : "Challan Type"}
                <span className="text-danger"> *</span>
              </label>
              <Controller
                name="cType"
                control={control}
                defaultValue={[{ name: props.data?.cType }]}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    multiple={false}
                    defaultSelected={[{ name: props.data?.cType }]}
                    options={
                      typChallan.data !== undefined ? typChallan.data : []
                    }
                    isLoading={typChallan.state}
                    placeholder="Select challan type..."
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid={errors.cType ? true : false}
                  />
                )}
              />
              <span className="text-danger err-msg">
                {errors.cType?.message}
              </span>
            </div>

            <div className="form-group mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {props.constLabel?.lbl_name
                  ? props.constLabel?.lbl_name
                  : "Name"}
                <span className="text-danger"> *</span>
              </label>
              <input
                type="text"
                className={
                  errors.typename ? "form-control  is-invalid" : "form-control"
                }
                id="typename"
                name="typename"
                defaultValue={props.data?.typename}
                placeholder="Enter challan name"
                {...register("typename")}
              />
              <span className="text-danger err-msg">
                {errors.typename?.message}
              </span>
            </div>

            <div className="form-group mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {props.constLabel?.lbl_id_prefix
                  ? props.constLabel?.lbl_id_prefix
                  : "ID Prefix"}
                <span className="text-danger"> *</span>
              </label>
              <input
                type="text"
                className={
                  errors.idprefix ? "form-control  is-invalid" : "form-control"
                }
                id="idprefix"
                name="idprefix"
                defaultValue={props.data?.idprefix}
                placeholder="Enter id prefix"
                {...register("idprefix")}
              />
              <span className="text-danger err-msg">
                {errors.idprefix?.message}
              </span>
            </div>

            <div className="form-group mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {props.constLabel?.lbl_wip ? props.constLabel?.lbl_wip : "WIP"}
              </label>
              <div className="form-control">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="wip"
                  name="wip"
                  onChange={() => setCheckWip(!checkWip)}
                  checked={checkWip}
                />
                <label className="form-check-label form_label ms-2">
                  {" "}
                  {" Is WIP Required ?"}
                </label>
              </div>
            </div>

            <div className="form-group mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {props.constLabel?.lbl_out_operation
                  ? props.constLabel?.lbl_out_operation
                  : "Out Operation"}
              </label>
              <div className="form-control">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="outoperation"
                  name="outoperation"
                  onChange={() => setOutOpr(!outOpr)}
                  checked={outOpr}
                />
                <label className="form-check-label form_label ms-2">
                  {" "}
                  {" Is Output Operation Required ?"}
                </label>
              </div>
            </div>

            <div className="form-group mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {props.constLabel?.lbl_same_location
                  ? props.constLabel?.lbl_same_location
                  : "Same Location"}
              </label>
              <div className="form-control">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="sameLocation"
                  name="sameLocation"
                  onChange={() => setSameLocation(!sameLocation)}
                  checked={sameLocation}
                />
                <label className="form-check-label form_label ms-2">
                  {" "}
                  {" Is Same location Required ?"}
                </label>
              </div>
            </div>

            <div className="form-group mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label roboto-bold"
              >
                {props.constLabel?.lbl_remark
                  ? props.constLabel?.lbl_remark
                  : "Remark"}
              </label>
              <input
                type="text"
                className="form-control"
                id="remark"
                name="remark"
                defaultValue={props.data?.remark}
                placeholder="Enter remark"
                {...register("remark")}
              />
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditChallanType;

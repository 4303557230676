import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Trash2, Eye } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAdd,
  faEye,
  faArrowLeft,
  faCloudUploadAlt,
  faUpload,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import DrawingGallery2 from "./ImageGallery/DrawingGallery2";
import {
  getOperationApi,
  getRoutesApi,
  getMachineList,
  createProdConfig,
  uploadMultiImage,
  getProdConfig,
  getProdMachines,
  getProdImages,
  createProdMachines,
  deleteProdMachine,
  updateProdConfig,
  deleteProdOperation,
} from "../../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import EditMachineModal from './EditMachineModal';
import { getLabels } from "../../../../common/Functions/CommonFunctions";

const schema = yup
  .object()
  .shape({
    items: yup.array().of(
      yup.object().shape({
        sequenceNo: yup
          .string()
          .matches(/^\d+$/, 'Enter valid sequence')
          .required("Please enter sequence"),
        operation: yup
          .array()
          .required("Please select operation")
          .min(1, "Please select operation"),
        oprcost: yup.string().required("Operation cost is required").matches(/^\d+(\.\d+)?$/, "Enter valid operation cost"),
        route: yup.array().required("Please select route").min(1, "Please select route"),
      })
    ),
  })
  .required();

function EditProductSequence(props) {
  const [images, setImages] = useState({ loading: false, data: [] });
  const [drawingFiles, setDrawingFiles] = useState([{ img: [] }]);
  const [machines, setMachines] = useState([{ data: [] }]);
  const [operationList, setOperationList] = useState({
    data: [],
    loading: false,
  });
  const [routesList, setRoutesList] = useState({ data: [], loading: false });
  const [galVisible, setGalVisible] = useState(false);
  const [currIndex, setCurrIndex] = useState(0);
  const [machineList, setMachineList] = useState({ data: [], loading: false });
  const [operlist, setOperList] = useState({ data: [], loading: false });
  const [machineModal, setMachineModal] = useState({ state: false, mode: 'create', selMachine: {}, macInd: "" });

  const [selecMachineData, setselecMachineData] = useState({
    cycleTime: "",
    machine: [],
    settingTime: "",
    toolChangeTime: "",
    machineRate: "",
  });

  const [constLabel, setConstLabel] = useState({});

  let navigate = useNavigate();

  const productData = JSON.parse(localStorage.getItem("prod_res"));

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [{}],
    },
  });

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  function handleMachineSubmit(e, ind) {
    e.preventDefault();
    


    if (
      selecMachineData.cycleTime == '' ||
      selecMachineData.cycleTime == null ||
      selecMachineData.cycleTime == undefined ||
      selecMachineData.cycleTime.length == 0
    ) {
      toast.error("Please enter cycle time", {
        autoClose: 2000,
      });
      return;
    }
    if (
      selecMachineData.machine == [] ||
      selecMachineData.machine == null ||
      selecMachineData.machine == undefined ||
      selecMachineData.machine.length == 0
    ) {
      toast.error("Please select machine", {
        autoClose: 2000,
      });
      return;
    }
    if (
      selecMachineData.settingTime == '' ||
      selecMachineData.settingTime == null ||
      selecMachineData.settingTime == undefined ||
      selecMachineData.settingTime.length == 0
    ) {
      toast.error("Please enter setting time", {
        autoClose: 2000,
      });
      return;
    }
    if (
      selecMachineData.toolChangeTime == '' ||
      selecMachineData.toolChangeTime == null ||
      selecMachineData.toolChangeTime == undefined ||
      selecMachineData.toolChangeTime.length == 0
    ) {
      toast.error("Please enter tool change time", {
        autoClose: 2000,
      });
      return;
    }

    
    



    if (Object.keys(fields[ind]).length > 1) {
      const payload = {
        product_id: props.data.productId,
        operation_id: fields[ind].operation[0].operationID,
        machine_id: selecMachineData.machine[0].machineId,
        name: selecMachineData.machine[0].machineName,
        // "serialNo": "AL03",
        cycle_time: selecMachineData.cycleTime,
        settings_time: selecMachineData.settingTime,
        tool_change_time: selecMachineData.toolChangeTime,
        machine_rate: selecMachineData.machineRate,
        product_id_id: props.data.uuid,
        operation_id_id: fields[ind].po_uuid,
        machine_id_id: selecMachineData.machine[0].uuid,
      };

      createProdMachines(payload).then(
        (res) => {
          
          toast.success("Machine created successfully", {
            autoClose: 2000,
          });
          getchProdConfig();
        },
        (err) => {
          
          toast.error(err.response.data.message, {
            autoClose: 2000,
          });
        }
      );
    } else {
      

      let tempMachines = [...machines];
      tempMachines[ind].data.push(selecMachineData);
      
      setMachines(tempMachines);
    }

    setselecMachineData({
      ...selecMachineData,
      cycleTime: "",
      machine: [],
      settingTime: "",
      toolChangeTime: "",
      machineRate: "",
    });

  }

  function removeMachine(machine) {
    
    SweetAlert.fire({
      title: "Are you sure to delete \n" + machine.name,
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteProdMachine({ uuid: machine.uuid }).then(
          (res) => {
            
            toast.success("Machine deleted successfully", {
              autoClose: 2000,
            });
            getchProdConfig();
          },
          (err) => {
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
            
          }
        );
      }
    });
  }

  function removeMac(index, maci) {
    
    let tempMachines = [...machines];

    tempMachines[index].data.splice(maci, 1);
    setMachines(tempMachines);
    
  }

  function getchProdConfig() {
    setOperList({ ...operlist, data: [], loading: true });

    getProdConfig({ product_id: props.data.uuid }).then(
      (res) => {
        
        setOperList({ ...operlist, data: res.data.data, loading: false });

        const tempList = [];
        let tempFiles = [...drawingFiles];

        let tempMachines = [...machines];

        for (let i = 0; i < res.data.data.length; i++) {
          tempList.push({
            sequenceNo: res.data.data[i].sequenceNo,
            po_uuid: res.data.data[i].uuid,
            operation: [
              {
                ProdOperationId: res.data.data[i].ProdOperationId,
                uuid: res.data.data[i].operation_id,
                operationName: res.data.data[i].operationname,
                operationID: res.data.data[i].ProdOperationId,
              },
            ],
            oprcost: res.data.data[i].oprcost,
            route: [
              {
                routesId: res.data.data[i].routes1,
                routesType: res.data.data[i].routesname,
                uuid: res.data.data[i].routes_id,
              },
            ],
          });

          if (i !== 0) {
            tempFiles.push({ img: [] });
            tempMachines.push({ data: [] });
          }
        }
        setDrawingFiles(tempFiles);
        setMachines(tempMachines);

        
        
        reset({
          items: tempList,
        });
      },
      (err) => {
        
        setOperList({ ...operlist, data: [], loading: false });
      }
    );
  }

  function fetchImages(index) {
    
    

    if (index + 1 <= operlist.data.length) {
      setImages({ ...images, loading: true, data: [] });
      getProdImages({ product_operation_id: fields[index].po_uuid }).then(
        (res) => {
          

          setImages({ ...images, loading: false, data: res.data.data });
        },
        (err) => {
          
          setImages({ ...images, loading: false, data: [] });
        }
      );
    }
  }

  useEffect(() => {
    setConstLabel(getLabels('Master', 'Product'));
    
    getchProdConfig();

    setRoutesList({ ...routesList, data: [], loading: true });
    setOperationList({ ...operationList, data: [], loading: true });

    getRoutesApi().then(
      (res) => {
        
        setRoutesList({ ...routesList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setRoutesList({ ...routesList, data: [], loading: false });
      }
    );

    getOperationApi({pageNo:1,entriesPerPage:100}).then(
      (res) => {
        
        setOperationList({
          ...operationList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setOperationList({ ...operationList, data: [], loading: false });
      }
    );

    setMachineList({ ...machineList, data: [], loading: true });
    getMachineList().then(
      (res) => {
        
        const result = res.data.data.filter(item => item.machineName !== "" && item.machineName !== null && item.machineName !== undefined);
        
        setMachineList({ ...machineList, data: result, loading: false });
      },
      (err) => {
        
        setMachineList({ ...machineList, data: [], loading: false });
      }
    );
  }, []);

  const onSubmit = (data) => {
    

    

    let primaryData = [];
    let drawingData = [];
    let machineData = [];

    for (let i = 0; i < data.items.length; i++) {
      let temp = {
        ProdOperationId: data.items[i].operation[0].ProdOperationId,
        uuid: data.items[i].po_uuid,
        productid: props.data.productId,
        productname: props.data.productName,
        prodType1: props.data.productType,
        product_id: props.data.uuid,
        product_type_id: props.data.product_type_id.uuid,
        operation_id: data.items[i].operation[0].uuid,
        operationname: data.items[i].operation[0].operationName,
        operationid: data.items[i].operation[0].operationID,
        oprcost: data.items[i].oprcost,
        sequenceNo: data.items[i].sequenceNo,
        routes1: data.items[i].route[0].routesId,
        routesname: data.items[i].route[0].routesType,
        routes_id: data.items[i].route[0].uuid,
      };
      if (data.items[i].operation[0].ProdOperationId == undefined) {
        delete temp.ProdOperationId;
      }
      if (data.items[i].po_uuid == undefined) {
        delete temp.uuid;
      }
      primaryData.push(temp);
    }

    

    for (let j = 0; j < machines.length; j++) {
      machineData.push([]);
      for (let k = 0; k < machines[j].data.length; k++) {
        machineData[j].push({
          productid: props.data.productId,
          operation_id: data.items[j].operation[0].operationID,
          machine_id: machines[j].data[k].machine[0].machineId,
          name: machines[j].data[k].machine[0].machineName,
          serialNo: machines[j].data[k].machine[0].serialNo,
          cycle_time: machines[j].data[k].cycleTime,
          settings_time: machines[j].data[k].settingTime,
          tool_change_time: machines[j].data[k].toolChangeTime,
          machine_rate: machines[j].data[k].machineRate,
          product_id_id: props.data.uuid,
          // operation_id_id: data.items[j].operation[0].uuid,
          machine_id_id: machines[j].data[k].machine[0].uuid,
        });
      }
    }

    for (let j = 0; j < operlist.data.length; j++) {
      primaryData[j].product_machine = operlist.data[j].machine;
      primaryData[j].operation_drawing = [];
    }

    
    for (let a = operlist.data.length; a < primaryData.length; a++) {
      
      
      

      primaryData[a].product_machine = machineData[a];
      primaryData[a].operation_drawing = [];
    }

    for (let x = 0; x < drawingFiles.length; x++) {
      drawingData.push([]);
      for (let y = 0; y < drawingFiles[x].img.length; y++) {
        drawingData[x].push({
          name: drawingFiles[x].img[y].filename,
          attachment: drawingFiles[x].img[y].file,
          doc_type: drawingFiles[x].img[y].docType[0].name,
          // product_operation: data.items[x].operation[0].uuid,
          doc_type_id: drawingFiles[x].img[y].docType[0].uuid,
        });
      }
    }

    

    

    const payload = {
      product_operation: primaryData,
    };
    

    updateProdConfig(payload).then(
      (res) => {
        
        // setMachines([{ data: [] }]);
        let imgArr = {};
        let imgArr2 = {};

        for (let i = 0; i < drawingData.length; i++) {
          if (drawingData[i].length > 0) {
            for (let b = 0; b < drawingData[i].length; b++) {
              
              imgArr["attachment" + (b + 1)] = drawingData[i][b].attachment;
              drawingData[i][b].product_operation =
                res.data.data[i].prod_operation.uuid;
              imgArr["obj" + (b + 1)] = JSON.stringify(drawingData[i][0]);
              imgArr["img_count"] = drawingData[i].length;
            }
          }
        }
        

        let formDataToSend = new FormData();

        for (const [key, value] of Object.entries(imgArr)) {
          formDataToSend.append(key, value);
        }


        
        if (Object.keys(imgArr).length > 0) {

          uploadMultiImage(formDataToSend).then(
            (res) => {
              

              toast.success("Product operation's updated successfully", {
                autoClose: 2000,
              });
              // getchProdConfig();
              setTimeout(() => {
                navigate("/pcms/product");
              }, [2200]);
            },
            (err) => {
              

              toast.error("Something went wrong in saving images", {
                autoClose: 2000,
              });
            }
          );
        } else {

          toast.success("Product operation's updated successfully", {
            autoClose: 2000,
          });

          setTimeout(() => {
            navigate("/pcms/product");
          }, [2200]);
        }
        imgArr = {};
      },
      (err) => {
        
      }
    );
  };

  function delProdOper(index) {
    

    SweetAlert.fire({
      title: "Are you sure to delete",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteProdOperation({ uuid: fields[index].po_uuid }).then(
          (res) => {
            
            getchProdConfig();
          },
          (err) => {
            
          }
        );
      }
    });
  }

  function checkValidation(e, idxx, para1) {

    const reg = /^\d*$/;
    // Check validation for Cycle Time
    if (para1 == 'CycleTime') {
      if (reg.test(e.target.value)) {
        setError(`items[${idxx}].cycleTime`, ''); // Clear the error if input is valid
        setselecMachineData({ ...selecMachineData, cycleTime: e.target.value })
      } else {
        setError(`items[${idxx}].cycleTime`, {
          type: "custom",
          message: "Enter valid cycle time !"
        })
      }
    }

    // Check validation for Setting Time
    if (para1 == 'SettingTime') {
      if (reg.test(e.target.value)) {
        setError(`items[${idxx}].settingTime`, ''); // Clear the error if input is valid
        setselecMachineData({ ...selecMachineData, settingTime: e.target.value })
      } else {
        setError(`items[${idxx}].settingTime`, {
          type: "custom",
          message: "Enter valid setting time !"
        })
      }
    }

    // Check validation for Tool Change Time
    if (para1 == 'ToolChangeTime') {
      if (reg.test(e.target.value)) {
        setError(`items[${idxx}].toolChangeTime`, ''); // Clear the error if input is valid
        setselecMachineData({ ...selecMachineData, toolChangeTime: e.target.value })
      } else {
        setError(`items[${idxx}].toolChangeTime`, {
          type: "custom",
          message: "Enter valid tool change time !"
        })
      }
    }

    // Check validation for Tool Change Time
    if (para1 == 'MachineRate') {
      if (reg.test(e.target.value)) {
        setError(`items[${idxx}].machineRate`, ''); // Clear the error if input is valid
        setselecMachineData({ ...selecMachineData, machineRate: e.target.value })
      } else {
        setError(`items[${idxx}].machineRate`, {
          type: "custom",
          message: "Enter valid machine rate !"
        })
      }
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <DrawingGallery2
        galVisible={galVisible}
        setGalVisible={setGalVisible}
        setDrawingFiles={setDrawingFiles}
        drawingFiles={drawingFiles}
        operlist={operlist}
        images={images}
        currIndex={currIndex}
        fetchImages={fetchImages}
        fields={fields}
      />


      {machineModal.state ?
        <EditMachineModal
          machineList={machineList}
          machineModal={machineModal}
          setMachineModal={setMachineModal}
          machines={machines}
          setMachines={setMachines}
          currIndex={currIndex}
          propsData={props.data}
          fields={fields}
          getchProdConfig={getchProdConfig}
        />
        : null}

      <div className="prod_configurator card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <div className="d-flex justify-content-start">
            <p className="pc_card_title roboto-bold">
              {constLabel?.lbl_product_specification ? constLabel.lbl_product_specification : "Product Specification"}
            </p>
          </div>

          {fields.length > 0 ?
            fields.map(({ id }, index) => (
              <React.Fragment key={index}>
                <div className="col-12 row me-0 me-md-1 vf-danger">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      {operlist.data[index] !== null &&
                        operlist.data[index] !== undefined ? (
                        <>
                          <label className="lbl-style col-form-label roboto-bold">
                          {constLabel?.lbl_product_name ? constLabel.lbl_product_name : "Product Name"}
                          </label>

                          <label
                            className="col-form-label roboto-bold ms-0 ms-md-2"
                            style={{ color: "#EEAE2D" }}
                          >

                            {operlist.data[index]?.productname}
                          </label>
                        </>
                      ) : null}
                    </div>
                    <div>
                      <FontAwesomeIcon
                        style={{
                          color: "black",
                          cursor: "pointer",
                        }}
                        icon={faClose}
                        size="lg"
                        onClick={() => {
                          setCurrIndex((prevCurr) => parseInt(prevCurr) - 1);
                          

                          if (fields[index].po_uuid !== undefined) {
                            delProdOper(index);
                          } else {
                            remove(index);
                            let tempMachines = [...machines];
                            tempMachines.splice(index, 1);
                            setMachines(tempMachines);
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>

                <div className="row mt-3 mb-5">
                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-5">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_sequence_no ? constLabel.lbl_sequence_no : "Sequence No."}
                          </label>
                          <input
                            className={
                              errors.items?.[index]?.sequenceNo
                                ? "form-control mt-2 is-invalid"
                                : "form-control mt-2 "
                            }
                            type="text"
                            {...register(`items[${index}].sequenceNo`)}
                            placeholder="Enter sequence no."
                          />

                          {errors.items?.[index]?.sequenceNo && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.sequenceNo.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-7">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_operation_name ? constLabel.lbl_operation_name : "Operation Name"}
                          </label>
                          <Controller
                            name={`items[${index}].operation`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                className="mt-2"
                                id="basic-typeahead"
                                labelKey="operationName"
                                multiple={false}
                                options={operationList.data}
                                isLoading={false}
                                placeholder="Select operation"
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.operation ? true : false
                                }
                              />
                            )}
                          />

                          {errors.items?.[index]?.operation && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.operation.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_operation_cost ? constLabel.lbl_operation_cost : "Operation Cost "}<span>&#8377;</span>
                          </label>
                          <input
                            className={
                              errors.items?.[index]?.oprcost
                                ? "form-control mt-2 is-invalid"
                                : "form-control mt-2 "
                            }
                            type="text"
                            placeholder="Enter operation cost"
                            {...register(`items[${index}].oprcost`)}
                          />

                          {errors.items?.[index]?.oprcost && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.oprcost.message}
                            </span>
                          )}
                        </div>
                      </div>

                      <div className="col-md-6">
                        <div className="form-group">
                          <label className="lbl-style roboto-bold">
                            {constLabel?.lbl_route ? constLabel.lbl_route : "Route"}
                          </label>
                          <Controller
                            name={`items[${index}].route`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                className="mt-2"
                                id="basic-typeahead"
                                labelKey="routesType"
                                multiple={false}
                                options={routesList.data}
                                isLoading={routesList.loading}
                                placeholder="Select route"
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.route ? true : false
                                }
                              />
                            )}
                          />

                          {errors.items?.[index]?.route && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.route.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4 ">
                    <div className="d-flex">
                      <div>
                        <div>
                          <label className="invisible mt-2">Dropdown</label>
                        </div>
                        <button
                          className="btn btn-grey px-3"
                          onClick={() => {
                            setCurrIndex(index);
                            setMachineModal({ ...machineModal, state: true, mode: "create", })
                          }}>
                          <FontAwesomeIcon
                            style={{
                              color: "#fff",
                            }}
                            icon={faAdd}
                            size="sm"
                            className="me-2"
                          />
                          {constLabel?.lbl_add_machine ? constLabel.lbl_add_machine : "Add machine"}</button>
                      </div>
                      <div className="ms-0 ms-md-3">
                        <div className="mt-2">
                          <label className="invisible">Dropdown</label>
                        </div>
                        <button
                          className="btn btn-yellow-wt px-3"
                          onClick={() => {
                            setGalVisible(true);
                            setCurrIndex(index);
                            fetchImages(index);
                          }}
                        >
                          <FontAwesomeIcon
                            style={{
                              color: "#fff",
                              marginRight: "5px",
                            }}
                            icon={faUpload}
                            size="sm"
                          />{" "}
                          {constLabel?.lbl_drawing ? constLabel.lbl_drawing : "Drawing"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {
                  operlist.data.length < fields.length + 1 ? (
                    operlist.data[index]?.machine?.length > 0 ? (
                      <div className="pill-container">
                        {operlist.data[index]?.machine?.map((mac, maci) => (
                          <div className="custom-pill" key={maci}>
                            <u style={{ color: 'blue' }} onClick={() => {
                              setCurrIndex(index);
                              setMachineModal({
                                ...machineModal, state: true,
                                mode: 'update', selMachine: mac, macInd: maci
                              })
                            }}>  {mac.name + " - " + mac.cycle_time}</u>

                            <FontAwesomeIcon
                              style={{
                                color: "#fff",
                                cursor: "pointer",
                                marginLeft: "20px",
                              }}
                              icon={faClose}
                              size="lg"
                              onClick={() => removeMachine(mac)}
                            />
                          </div>
                        ))}
                      </div>
                    ) : null
                  ) : null
                }

                {
                  operlist.data.length < fields.length &&
                    machines[index]?.data?.length > 0 ? (
                    <div className="pill-container">
                      {machines[index]?.data?.map((mac, maci) => (
                        <div className="custom-pill" key={maci}>

                          <u style={{ color: 'blue' }} onClick={() => {
                            
                            setCurrIndex(index);
                            setMachineModal({
                              ...machineModal, state: true,
                              mode: 'update',
                              selMachine: {
                                machine_id: mac.machine[0].machineId,
                                name: mac.machine[0].machineName,
                                machine_id_id: mac.machine[0].uuid,
                                cycle_time: mac.cycleTime,
                                settings_time: mac.settingTime,
                                tool_change_time: mac.toolChangeTime,
                                machineRate: mac.machineRate,
                              }, macInd: maci
                            })
                          }}>
                            {mac.machine[0]?.machineName + " - " + mac.cycleTime}
                          </u>

                          <FontAwesomeIcon
                            style={{
                              color: "#fff",
                              cursor: "pointer",
                              marginLeft: "20px",
                            }}
                            icon={faClose}
                            size="lg"
                            onClick={() => removeMac(index, maci)}
                          />
                        </div>
                      ))}
                    </div>
                  ) : null
                }

                < hr className="my-4" />
              </React.Fragment>
            )) : (
              <div
                className="alert alert-danger mb-0 text-center my-4"
                role="alert"
              >
                {"No product operation's found !"}
              </div>
            )}
          <div>
            <span
              onClick={() => {
                append({});
                let tempFiles = [...drawingFiles];
                tempFiles.push({ img: [] });
                setDrawingFiles(tempFiles);

                let tempmachine = [...machines];
                tempmachine.push({ data: [] });
                

                setMachines(tempmachine);
                setDrawingFiles(tempFiles);

                setCurrIndex((prevCurr) => parseInt(prevCurr) + 1);
              }}
              className="mt-4 mb-0 text_gold roboto-bold cursor_pointer"
              style={{ fontSize: "20px" }}
            >
              {"+"} {constLabel?.lbl_add_sequence ? constLabel?.lbl_add_sequence : "Add Sequence"}
            </span>
          </div>

          <div className="d-flex justify-content-end">
            <button
              className="btn btn-grey px-4 me-2"
              onClick={() => props.setCurrentStep(props.currentStep - 1)}
            >
              <FontAwesomeIcon
                style={{
                  color: "#fff",
                }}
                icon={faArrowLeft}
                size="sm"
                className="me-2"
              />
              {constLabel?.lbl_back ? constLabel.lbl_back : "Back"}
            </button>
            <button
              className="btn button-primary px-4"
              onClick={handleSubmit(onSubmit)}
            >
              {constLabel?.lbl_update ? constLabel.lbl_update : "Update"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}

export default EditProductSequence;

import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getPO } from "../../../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faCheck,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import Modal from "react-bootstrap/Modal";
import { Accordion, Spinner } from "react-bootstrap";
import { convertDate } from "../../../common/Functions/CommonFunctions";


function ViewPODetails() {
  let location = useLocation();
  let navigate = useNavigate();
  const POdata = location.state;

  const [show2, setShow2] = useState(false);

  const [total, setTotal] = useState("");
  const [poList, setpoList] = useState({ data: [], loading: false });

  const [activeEvent, setActiveEvent] = useState(null);

  const [indexx, setIndexx] = useState(0);

  function showRecord(ind) {
    setIndexx(ind);
  }

  const handleEventClick = (event) => {
    setActiveEvent(event);
  };

  useEffect(() => {
    
    setpoList({ ...poList, data: [], loading: true });

    getPO({ uuid: POdata.uuid, po_history: 1}).then(
      (res) => {
        
        // Below logic for sort the array and return sorted array in ascending order 
        // let latestArr = res.data.data.sort(
        //   (a, b) => new Date(a.created_at) - new Date(b.created_at)
        // );
        setpoList({ ...poList, data: res.data.data, loading: false });
        // set the latest last index of array
        // showRecord(latestArr.length - 1);
        showRecord(0);
      },
      (err) => {
        
        setpoList({ ...poList, data: [], loading: false });
      }
    );

    const tot = POdata?.PO_line_item.reduce((accumulator, currentValue) => {
      // Extract the 'number' property from each object and add it to the accumulator
      return parseFloat(accumulator) + parseFloat(currentValue.amount);
    }, 0);

    setTotal(tot);
  }, []);

  return (
    <React.Fragment>
      <div className="PO_Details">
        <div className="card">
          <div className="card-header bg-grey d-flex justify-content-between align-items-center">
            <div className="d-flex align-items-center">
              <button
                className="btn btn-yellow"
                onClick={() => {
                  navigate("/pcms/purchase-order");
                }}
              >
                <FontAwesomeIcon icon={faArrowLeft} size="sm" />
              </button>

              <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
                {"Purchase Order Details"}
              </p>
            </div>
            {/* <div>
                            <button className='btn btn-yellow px-3' onClick={() => setShow2(true)}>Revision History</button>
                        </div> */}
          </div>

          <div className="card-body">
            {poList.loading ? (
              <div className="text-center">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            ) : (
              <div className="po_revision_history">
                <div className="po_line"></div>

                <div
                  className="row mx-auto"
                  style={{
                    marginTop: "-10px",
                    width: "100%",
                    overflowX: "auto",
                    fontSize: "14px",
                  }}
                >
                  <div className="d-flex pb-2">
                    {poList.data.map((val, ind, arr) => (
                      <div
                        className="mx-4 text-center "
                        key={ind}
                        style={{ width: "300px" }}
                      >
                        <FontAwesomeIcon
                          icon={faCircle}
                          style={{
                            fontSize: ind === indexx ? "18px" : "14px",
                            color: ind === indexx ? "green" : "#4E7DAE",
                          }}
                          onClick={() => showRecord(ind)}
                        />

                        <div
                          className={
                            ind === indexx
                              ? "card shadow-sm border-0 mt-2 cursor_pointer"
                              : "card border-0 mt-3 cursor_pointer"
                          }
                          style={{
                            background: ind === indexx ? "honeydew" : "",
                            minWidth: "300px"
                          }}
                          onClick={() => showRecord(ind)}
                        >
                          <div className="card-body">
                            <div className="row">
                              <p
                                className="col roboto-bold"
                                style={{
                                  textAlign: "left",
                                  fontSize: "12px",
                                  lineHeight: "5px",
                                }}
                              >
                                {convertDate(val.po_date, '-')}
                              </p>
                              <p
                                className={`col roboto-bold ${val.po_status === "Open"
                                    ? "text-primary"
                                    : val.po_status == "Pending"
                                      ? "text-warning"
                                      : val.po_status == "Cancelled"
                                        ? "text-danger"
                                        : val.po_status == "Approved"
                                          ? "text-success"
                                          : ""
                                  }`}
                                style={{
                                  textAlign: "left",
                                  fontSize: "12px",
                                  lineHeight: "5px",
                                }}
                              >
                                {val.po_status}
                              </p>
                              {/* <p className='col-sm-8'>{val.po_date}</p> */}
                            </div>
                            <div style={{ textAlign: "left" }}>
                              <p
                                className="roboto-bold"
                                style={{ fontSize: "16px", lineHeight: "10px" }}
                              >
                                {val.poid}
                              </p>
                              {/* <p className='col-sm-8'>{val.po_date}</p> */}
                            </div>
                            <div style={{ textAlign: "left" }}>
                              <p
                                className="roboto-bold"
                                style={{
                                  fontSize: "10px",
                                  color: "#394867",
                                  lineHeight: "10px",
                                }}
                              >
                                Invoice To
                              </p>
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "#9BA4B4",
                                  lineHeight: "5px",
                                }}
                              >
                                {val.invoice_toname}
                              </p>
                            </div>
                            <div style={{ textAlign: "left" }}>
                              <p
                                className="roboto-bold"
                                style={{
                                  fontSize: "10px",
                                  color: "#394867",
                                  lineHeight: "10px",
                                }}
                              >
                                Dispatch To
                              </p>
                              <p
                                style={{
                                  fontSize: "12px",
                                  color: "#9BA4B4",
                                  lineHeight: "5px",
                                }}
                              >
                                {val.despatch_toname}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            )}
            <hr className="my-2" />
            <div className="container-fluid">            
              <div className="bg-lightgrey" style={{ padding: "12px", borderRadius: "12px" }}>
                <div className="row my-2">
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"PO Id"}
                    </p>
                    <p className="mb-2 roboto-bold">{poList.data[indexx]?.poid}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Date"}
                    </p>
                    <p className="mb-2 roboto-bold">{convertDate(poList.data[indexx]?.po_date, '-')}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Invoice To"}
                    </p>
                    <p className="mb-2 roboto-bold">{poList.data[indexx]?.invoice_to_id?.comName}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Dispatch To"}
                    </p>
                    <p className="mb-2 roboto-bold">{poList.data[indexx]?.despatch_to_id?.comName}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Supplier"}
                    </p>
                    <p className="mb-2 roboto-bold">{poList.data[indexx]?.supplier_id?.name}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Quote Ref."}
                    </p>
                    <p className="mb-2 roboto-bold">{poList.data[indexx]?.quote_ref == null
                        ? "NA"
                        : poList.data[indexx]?.quote_ref}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Credit Period"}
                    </p>
                    <p className="mb-2 roboto-bold">{poList.data[indexx]?.credit_period}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Status"}
                    </p>
                    <p className="mb-2 roboto-bold">{poList.data[indexx]?.po_status}</p>
                  </div>
                  <div className="col-12 col-md-3">
                    <p className="m-0 nc_detail_label">
                      {"Remark"}
                    </p>
                    <p className="mb-2 roboto-bold">{poList.data[indexx]?.remark}</p>
                  </div>
                </div>
              </div>
              <hr className="my-2" />
              <h4 className="roboto-bold mb-0">
                {"Product Details"}
              </h4>
              <hr className="my-2" />

              <div className="my-3">
                <div
                  className="table-responsive"
                  style={{ maxHeight: "300px", overflowY: "auto" }}
                >
                  <table className="table table-bordered table-sm">
                    <thead className="table-grey roboto-bold">
                      <tr>
                        <th scope="col">{"Product Type"}</th>
                        <th scope="col">{"Product"}</th>
                        <th scope="col">{"Quantity"}</th>
                        <th scope="col">{"Unit"}</th>
                        <th scope="col">
                          {"Rate"}{" "} <span>&#8377;</span>
                        </th>
                        <th scope="col">{"GST "}(%)</th>
                        <th scope="col">{"Discount "}(%)</th>
                        <th scope="col">
                          {"Amount"}{" "} <span>&#8377;</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {poList.data[indexx]?.PO_line_item?.map((val, ind) => (
                        <tr key={ind}>
                          <td>{val.producttype}</td>

                          <td style={{ verticalAlign: "top" }}>
                            {val.productname}
                          </td>

                          <td style={{ verticalAlign: "top" }}>
                            {val.quantity}
                          </td>

                          <td style={{ verticalAlign: "top" }}>{val.unit}</td>

                          <td style={{ verticalAlign: "top" }}>{val.rate}</td>
                          <td style={{ verticalAlign: "top" }}>{val.gst_rate}</td>
                          <td style={{ verticalAlign: "top" }}>{val.discount}</td>

                          <td style={{ verticalAlign: "top" }}>
                            {parseFloat(val.amount).toFixed(2)}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div
                className="p-3 ms-auto col-12 col-md-3"
                style={{
                  border: "1px solid #c6c6c6",
                  borderRadius: "2px",
                  fontSize: "14px",
                }}
              >
                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label className="col-12 col-md-6 roboto-bold">
                      {"Sub Total"} <span>&#8377;</span>
                    </label>

                    <div className="col-12 col-md-6">
                      {parseFloat(total).toFixed(2)}
                    </div>
                  </div>
                </div>

                {/* <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label className="col-12 col-md-3 roboto-bold">
                      Discount (%)
                    </label>

                    <div className="col-12 col-md-4">
                      {poList.data[indexx]?.discount}
                    </div>

                    <div className="col-12 col-md-4 d-flex align-items-center">
                      <span>&#8377;</span>
                      {parseFloat(
                        (total * poList.data[indexx]?.discount) / 100
                      ).toFixed(2)}
                    </div>
                  </div>
                </div> */}

                {/* <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label className="col-12 col-md-3 roboto-bold">
                      Amount after discount <span>&#8377;</span>
                    </label>

                    <div className="col-12 col-md-4 ">
                      {parseFloat(poList.data[indexx]?.sub_total).toFixed(2)}
                    </div>
                  </div>
                </div> */}

                <hr />
                {poList.data && poList.data[indexx]?.cgst_cost !== 0 ? (
                  <>
                    <div className="row mb-2">
                      <div className="col-12 row ms-auto">
                        <label className="col-12 col-md-6 roboto-bold">
                          {"CGST "} &#8377;
                        </label>

                        {/* <div className="col-12 col-md-4">
                          {poList.data[indexx]?.cgst}
                        </div> */}
                        <div className="col-12 col-md-6 d-flex align-items-center">
                         
                          {parseFloat(poList.data[indexx]?.cgst_cost).toFixed(
                            2
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="row mb-2">
                      <div className="col-12 row ms-auto">
                        <label className="col-12 col-md-6 roboto-bold">
                          {"SGST "} &#8377;
                        </label>

                        {/* <div className="col-12 col-md-4">
                          {poList.data[indexx]?.sgst}
                        </div> */}
                        <div className="col-12 col-md-6 d-flex align-items-center">
                          
                          {parseFloat(poList.data[indexx]?.sgst_cost).toFixed(
                            2
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {poList.data && poList.data[indexx]?.igst_cost !== 0 ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label className="col-12 col-md-6 roboto-bold">
                        {"IGST "} &#8377;
                      </label>

                      {/* <div className="col-12 col-md-4">
                        {poList.data[indexx]?.igst}
                      </div> */}
                      <div className="col-12 col-md-6 d-flex align-items-center">
                        
                        {parseFloat(poList.data[indexx]?.igst_cost).toFixed(2)}
                      </div>
                    </div>
                  </div>
                ) : null}

                {poList.data && poList.data[indexx]?.ugst_cost !== 0 ? (
                  <div className="row mb-2">
                    <div className="col-12 row ms-auto">
                      <label className="col-12 col-md-6 roboto-bold">
                        {"UGST "} &#8377;
                      </label>

                      {/* <div className="col-12 col-md-4">
                        {poList.data[indexx]?.ugst}
                      </div> */}
                      <div className="col-12 col-md-6">
                        
                        {parseFloat(poList.data[indexx]?.ugst_cost).toFixed(2)}
                      </div>
                    </div>
                  </div>
                ) : null}

                <div className="row mb-2">
                  <div className="col-12 row ms-auto">
                    <label className="col-12 col-md-6 roboto-bold">
                      {"Total Amount "} &#8377;
                    </label>

                    <div className="col-12 col-md-6">
                      {parseFloat(poList.data[indexx]?.net_amount).toFixed(2)}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal
        show={show2}
        onHide={() => setShow2(false)}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>PO Revision History</Modal.Title>
        </Modal.Header>
        <Modal.Body></Modal.Body>
        <Modal.Footer>
          <button className="btn btn-grey px-4" onClick={() => setShow2(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default ViewPODetails;

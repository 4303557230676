import React, { useEffect } from 'react';
import image1 from '../../assets/images/image1.png'
import Group from '../../assets/images/Group 1.png'
import Layer from '../../assets/images/Layer_1.png'
import { useNavigate } from 'react-router';
import logo from "../../assets/images/proditm.png"

function LandingPage() {
    let navigate = useNavigate();

  useEffect(()=>{
    let token=localStorage.getItem("access");
    if(token){
        navigate("/pcms/customer")
    }
  },[])
    
    return (
        <React.Fragment>
            <div className='landing_page'>
                <div className='d-md-flex justify-content-between align-items-center mx-5 my-3'>
                    {/* <p className='landing_page_title roboto-bold'>Producus</p> */}
                    <img className="landing_page_title" src={logo} height={60} width={140}></img>
                    <ul className="nav landing_page_nav">
                        <li className="nav-item">
                            <a className="nav-link active" aria-current="page" href="#">About</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Pricing</a>
                        </li>
                        <li className="nav-item">
                            <a className="nav-link" href="#">Clients</a>
                        </li>
                        <li className="nav-item">
                            <button onClick={() => navigate("/login")} className='btn button-primary px-3 ms-0 ms-md-3'>Login / Sign up</button>
                            {/* <a className="nav-link disabled" aria-disabled="true">Disabled</a> */}
                        </li>
                    </ul>
                </div>
                <div>
                    <div className='landing_page_tag_line mx-auto'>
                        <p className='mb-0 roboto-bold'>Elevate Your Production Standards
                            with our integrated production control and quality management solution</p>
                        <button className='btn btn-yellow-wt px-3 mt-3'>Request Demo</button>
                    </div>

                </div>

                <div className='row mt-5'>
                    <div className='col-12 col-md-8 p-md-0'>
                        <img src={image1} className='img-fluid' />
                    </div>
                    <div
                        className='col-12 col-md-4 p-md-0 d-flex d-flex align-items-center justify-content-center' style={{ background: "#EEF1F4", position: 'relative' }}>
                        <div style={{ padding: '30px' }}>
                            <div className='card border-0 text-center'
                                style={{ width: '190px', height: '190px', borderRadius: '2px' }}>
                                <div className='card-body d-flex align-items-center justify-content-center'>
                                    <div>
                                        <img src={Group} className='img-fluid' />
                                        <p className='roboto-bold mb-0 mt-3'>Try It Yourself</p>
                                        <p className='roboto-bold mb-0' style={{ color: '#FF134E', fontSize: '14px' }}>Click Here</p>
                                    </div>
                                </div>
                            </div>
                            <div className='card mt-3 border-0 text-center'
                                style={{ width: '190px', height: '190px', borderRadius: '2px' }}>
                                <div className='card-body d-flex align-items-center justify-content-center'>
                                    <div>
                                        <img src={Layer} className='img-fluid' />
                                        <p className='roboto-bold mb-0 mt-3'>Try It Yourself</p>
                                        <p className='roboto-bold mb-0' style={{ color: '#FF134E', fontSize: '14px' }}>Click Here</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default LandingPage
import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
import DatePicker from "react-datepicker";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { format } from "date-fns";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  machineCategory,
  getLocation,
  machineAsset,
} from "../../../../api/index";

const schema = yup
  .object()
  .shape({
    category: yup
      .array()
      .min(1, "Selct atleast one category")
      .required("Machine category is required !"),
    location: yup
      .array()
      .min(1, "Selct atleast one location")
      .required("Location is required !"),
    machineName: yup
      .string()
      .required("Machine name is required !")
      .matches(/^[a-zA-Z ]*$/, "Only alphabets and space Allowed !"),
    serialNo: yup.string().required("Serial number is required !"),
    nextServiceDate: yup
      .date()
      .when("lastServiceDate", (lastServiceDate, schema) => {
        // Check if lastServiceDate is undefined or not a valid date
        if (!lastServiceDate || isNaN(new Date(lastServiceDate))) {
          return schema.notRequired();
        }
        return schema.min(
          lastServiceDate,
          "Next service date should be greater than last service date"
        );
      })
      .required("Next service date is required"),
    expDate: yup
      .date()
      .when("lastServiceDate", (lastServiceDate, schema) => {
        // Check if lastServiceDate is undefined or not a valid date
        if (!lastServiceDate || isNaN(new Date(lastServiceDate))) {
          return schema.notRequired();
        }
        return schema.min(
          lastServiceDate,
          "Expiry date must be future date"
        );
      })
      .required("Expiry date is required"),
  })
  .required();

function EditMachineAsset(props) {
  const [visible, setVisible] = useState(false);

  const [machine_cat, setMachine_Cat] = useState({ data: [], state: false });
  const [locData, setLocData] = useState({ data: [], state: false });

  const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    if (visible) {
      setLocData({ state: true, data: [] });

      getLocation().then(
        (res) => {
          
          setLocData({
            state: false,
            data: res.data.LocationList,
          });
        },
        (err) => {
          
          setLocData({ state: false, data: [] });
          toast.error(err.response.data.message, {
            autoClose: 3000,
          });
        }
      );

      fetchMachineCategory();
    }
  }, [visible]);

  function fetchMachineCategory() {
    setMachine_Cat({ data: [], state: true });
    const payload = {
      action: "alllist",
    };
    machineCategory(payload).then(
      (res) => {
        
        setMachine_Cat({
          state: false,
          data: res.data.CategoryDetails,
        });

        if(props.data?.machineCatId){
          let m_Cat = res.data?.CategoryDetails.find((item) => item.id === props.data?.machineCatId);
          setValue("category", [{categoryname : m_Cat.categoryname, id: m_Cat.id}]);
        }
      },
      (err) => {
        
        setMachine_Cat({ state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }

  const selCategory = watch("category");
  const selLocation = watch("location");

  const onSubmit = (data) => {
    

    if (data !== "") {
      const reqPayload = {
        id: props.data.id,
        action: "edit",
        machineId: props.data.id,
        machineCatId: selCategory !== undefined && selCategory.length > 0 ? selCategory[0]?.id : props.data.machineCatId,
        locationId: selLocation !== undefined && selLocation.length > 0 ? selLocation[0]?.id : props.data.locationId,
        machineName: data.machineName,
        mantainanceFrequency: data.mantainanceFrequency,
        lastServiceDate: format(new Date(data.lastServiceDate), "d-MMMM-y"),
        nextServiceDate: format(new Date(data.nextServiceDate), "d-MMMM-y"),
        vendorName: data.vendorName,
        cost: data.cost,
        expDate: format(new Date(data.expDate), "d-MMMM-y"),
        machineModel: data.machineModel,
        serialNo: data.serialNo,
        remark: data.remark,
        createdBy: 1,
        modifiedBy: "",
      };

      

      machineAsset(reqPayload)
        .then(
          (res) => {
            
            if (res.status === 200 || res.status === 201) {
              toast.success("Machine asset updated Successfully !", {
                autoClose: 3000,
              });
              setVisible(false);
              props.fetchMachine();
            }
          },
          (err) => {
            
            if (err.response.status === 400) {
              
              toast.error("Machine asset details already exist ...", {
                autoClose: 2000,
              });
              reset();
            }
          }
        )
        .catch((error) => {
          
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">{"Edit Machine"}</Tooltip>}
      >
        <div className="editIcon me-2">
          <Edit2
            color="#FFFFFF"
            size={18}
            className="cursor-pointer"
            onClick={() => {
              // setUpdateForm(val)
              setVisible(true);
            }}
          />
        </div>
      </OverlayTrigger>
      <Modal show={visible} onHide={() => setVisible(false)} size="xl">
        <Modal.Header style={{ background: "#2A3643", color: "white" }} closeButton>
          <Modal.Title>{"Edit Machine"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="row mt-2">
              <div className="col-md-6">
                <div className="form-group row">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
                    Category
                    <span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>
                  <div className="col-md-8">
                    <Controller
                      name="category"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="categoryname"
                          multiple={false}
                          size="sm"
                          options={
                            machine_cat.data !== undefined
                              ? machine_cat.data
                              : []
                          }
                          isLoading={machine_cat.state}
                          placeholder="Select category here..."
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.category ? true : false}
                          
                        />
                      )}
                    />
                    <span className="text-danger">
                      {errors.category?.message}
                    </span>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
                    Location
                    <span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>
                  <div className="col-md-8">
                    <Controller
                      name="location"
                      control={control}
                      defaultValue={[{locationname : props.data?.locationId, id: props.data?.locationId }]}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="locationname"
                          size="sm"
                          defaultSelected={[{locationname : props.data?.locationId, id: props.data?.locationId}]}
                          multiple={false}
                          options={
                            locData.data !== undefined ? locData.data : []
                          }
                          isLoading={locData.state}
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.location ? true : false}
                          
                        />
                      )}
                    />
                    <span className="text-danger">
                      {errors.location?.message}
                    </span>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
                    Last Service Date
                  </label>
                  <div className="col-md-8">
                    <Controller
                      id="lastServiceDate"
                      name="lastServiceDate"
                      defaultValue={props.data ? new Date(props.data.lastServiceDate) : ''}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DatePicker
                          dateFormat="d-MMMM-y"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          className={
                            errors.lastServiceDate
                              ? "form-control form-control-sm is-invalid"
                              : "form-control form-control-sm"
                          }
                        />
                      )}
                    />
                    <span className="text-danger">
                      {errors.lastServiceDate?.message}
                    </span>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-4">Vendor Name</label>
                  <div className="col-md-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="vendorName"
                      name="vendorName"
                      defaultValue={props.data.vendorName}
                      {...register("vendorName")}
                    />
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-4">Exp. Date</label>
                  <div className="col-md-8">
                    <Controller
                      id="expDate"
                      name="expDate"
                      defaultValue={props.data ? new Date(props.data.expDate) : ''}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DatePicker
                          dateFormat="d-MMMM-y"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          className={
                            errors.expDate
                              ? "form-control form-control-sm is-invalid"
                              : "form-control form-control-sm"
                          }
                        />
                      )}
                    />
                    <span className="text-danger">
                      {errors.expDate?.message}
                    </span>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
                    Serial No.
                    <span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>
                  <div className="col-md-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="serialNo"
                      name="serialNo"
                      defaultValue={props.data.serialNo}
                      {...register("serialNo")}
                    />
                    <span className="text-danger">
                      {errors.serialNo?.message}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group row">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
                    Name
                    <span>
                      <i style={{ color: "red" }}>*</i>
                    </span>
                  </label>
                  <div className="col-md-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="machineName"
                      name="machineName"
                      defaultValue={props.data.machineName}
                      {...register("machineName")}
                    />
                    <span className="text-danger">
                      {errors.machineName?.message}
                    </span>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-4">Frequency</label>
                  <div className="col-md-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="mantainanceFrequency"
                      name="mantainanceFrequency"
                      defaultValue={props.data.mantainanceFrequency}
                      {...register("mantainanceFrequency")}
                    />
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
                    Next Service Date
                  </label>
                  <div className="col-md-8">
                    <Controller
                      id="nextServiceDate"
                      name="nextServiceDate"
                      defaultValue={props.data ? new Date(props.data.nextServiceDate) : ''}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <DatePicker
                          dateFormat="d-MMMM-y"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          className={
                            errors.nextServiceDate
                              ? "form-control form-control-sm is-invalid"
                              : "form-control form-control-sm"
                          }
                        />
                      )}
                    />
                    <span className="text-danger">
                      {errors.nextServiceDate?.message}
                    </span>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-4">Cost</label>
                  <div className="col-md-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="cost"
                      name="cost"
                      defaultValue={props.data.cost}
                      {...register("cost")}
                    />
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-4">Model</label>
                  <div className="col-md-8">
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      id="machineModel"
                      name="machineModel"
                      defaultValue={props.data.machineModel}
                      {...register("machineModel")}
                    />
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-4">Remark</label>
                  <div className="col-md-8">
                    <textarea
                      type="text"
                      className="form-control form-control-sm"
                      id="remark"
                      name="remark"
                      defaultValue={props.data.remark}
                      {...register("remark")}
                    ></textarea>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditMachineAsset;

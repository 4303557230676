import axios from "axios";
// import { RefreshToken } from "./index";
import { toast } from "react-toastify";

const client1 = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

// function saveToken(access_token) {
//   localStorage.setItem("access", access_token);
//   // localStorage.setItem('refresh', refresh_token);
// }

// function destroyToken() {
//   localStorage.removeItem("access");
//   localStorage.removeItem("refresh");
// }

// function refresh() {
//   return new Promise((resolve, reject) => {
//     client1
//       .post(RefreshToken, {
//         refresh: localStorage.getItem("refresh"),
//       })
//       .then((response) => {
//         saveToken(response.data.access, response.data.refresh_token);
//         return resolve(response.data.access_token);
//       })
//       .catch((error) => {
//         destroyToken();
//         window.location.replace(`${process.env.PUBLIC_URL}/login`);
//         return reject(error);
//       });
//   });
// }

client1?.interceptors.request.use((config) => {

  let congnito = localStorage.getItem("congnito");
  // let normal = localStorage.getItem("normal");
  if (congnito == "true") {
    var authToken = localStorage.getItem("access");
    var idtoken = localStorage.getItem('id');

    config.headers = {
      "X-AUTH-TOKEN": `${authToken}`,
      'X-ID-TOKEN': `${idtoken}`
    };
    //  console.log(config);

    //  if request doest receive response under 60 seconds it ll get cancelled
    config.timeout = 60000;

    return config;
  }
  else {
    var authToken = localStorage.getItem("access");
    config.headers = { Auth: `Bearer ${authToken}` };
    console.log("con", config);

    return config;
  }
});

client1?.interceptors.response.use(
  (res) => res,
  (error) => {
    // 
    // console.log("error.response", error.response);
    const status = error.response ? error.response.status : null;
    switch (status) {
      case 401:

        localStorage.clear();
        window.location.replace(`${process.env.PUBLIC_URL}/login`);
        break;

      case 403:

        localStorage.clear();
        window.location.replace(`${process.env.PUBLIC_URL}/login`);
        break;
      //localStorage.removeItem('refresh');

      case 400:
        break;

      case 500:
        toast.error("Internal Server Error", {
          autoClose: 2000,
        });
        break;

      default:
        // return null;
        break;
    }

    if (status === 401 || status === 403) {
      //window.location.replace('/login');
      // localStorage.removeItem("access");
      // window.location.replace(`${process.env.PUBLIC_URL}/login`);
      //localStorage.removeItem('refresh');
    }

    // status might be undefined
    if (!status) {
      // refresh();
    }
    return Promise.reject(error);
  }
);

export default client1;

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { get_store, getInternalCompany, updateNCStatus } from "../../../api";

const schema = yup
  .object()
  .shape({
    statusname: yup
      .string()
      .required("Disposal action is required !")
      .matches(/^[a-zA-Z ]*$/, "Only alphabets and space Allowed !"),
    fromStore: yup
      .array()
      .min(1, "Please select from store!")
      .required("Please select from store!")
      .test(
        "quarantine-check",
        "Either 'fromStore' or 'toStore' must have 'Quarantine' !",
        function (value) {
          const fromStore = value?.[0]?.name;
          const toStore = this.parent.toStore?.[0]?.name; // access toStore from the parent object
          // If either store is not selected, skip the test (no error shown)
          if (!fromStore || !toStore) {
            return true;
          }

          // Otherwise, ensure one of them is "Quarantine"
          return fromStore === "Quarantine" || toStore === "Quarantine";
        }
      ),
    toStore: yup
      .array()
      .min(1, "Please select to store!")
      .required("Please select to store!")
      .test(
        "quarantine-check",
        "Either 'fromStore' or 'toStore' must have 'Quarantine' !",
        function (value) {
          const toStore = value?.[0]?.name;
          const fromStore = this.parent.fromStore?.[0]?.name; // access fromStore from the parent object
          // If either store is not selected, skip the test (no error shown)
          if (!fromStore || !toStore) {
            return true;
          }

          // Otherwise, ensure one of them is "Quarantine"
          return fromStore === "Quarantine" || toStore === "Quarantine";
        }
      ),
  })
  .required();

function EditNCStatus(props) {
  const [visible, setVisible] = useState(false);

  const [f_store, setF_Store] = useState({ data: [], loading: true });
  const [t_store, setT_Store] = useState({ data: [], loading: true });

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    if(visible){
      fetchInternal_company();
    }
  }, [visible])
  

  function fetchInternal_company(){

    setF_Store({loading: true, data: [] });
    setT_Store({loading: true, data: [] });

    getInternalCompany({with_location : 1})
      .then((res) => {
        // console.log("res", res);
        const ress = res.data.data.filter((item) => item.default_org == true);
        // console.log("ress", ress);
        if(ress.length > 0){

          const loc = ress?.[0]?.location_list.filter((itm) => itm.default_location == true);
          // console.log("loc", loc);
          if(loc.length > 0 ){
            get_store({location_id : loc?.[0]?.uuid}).then(
              (resp) => {
                // console.log("resp", resp);
                const storeFirst = resp.data.data?.filter((item) =>  item.uuid == props.data?.from_store);
                if(storeFirst){
                  setValue("fromStore", storeFirst);
                }

                const storeTo = resp.data.data?.filter((item) =>  item.uuid == props.data?.to_store);
                if(storeTo){
                  setValue("toStore", storeTo);
                }

                setF_Store({loading: false, data: resp.data.data });
                setT_Store({loading: false, data: resp.data.data });
              },
              (err) => {
                setF_Store({loading: false, data: [] });
                setT_Store({loading: false, data: [] });
              }
            );
          } else {
            toast.success("Default location not found !", {
              autoClose: 3000,
            });
            setF_Store({loading: false, data: [] });
            setT_Store({loading: false, data: [] });
          }

        } else {
          toast.success("Default organization not found !", {
            autoClose: 3000,
          });
          setF_Store({loading: false, data: [] });
          setT_Store({loading: false, data: [] });
        }
  
      })
      .catch((err) => {
            setF_Store({loading: false, data: [] });
            setT_Store({loading: false, data: [] });
      });
  };

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        statusname: data.statusname,
        from_store: data.fromStore?.[0]?.uuid,
        to_store: data.toStore?.[0]?.uuid
      };

      updateNCStatus(reqPayload)
        .then(
          (res) => {
            if (res.status === 200 || res.status === 201) {
              toast.success("Disposal action updated Successfully !", {
                autoClose: 3000,
              });
              setVisible(false);
              props.fetchNCStatus(props.page, props.entriesPerPage);
            }
          },
          (err) => {
            if (err.response.status === 405) {
              toast.error(err.response.data.messsage, {
                autoClose: 2000,
              });
              // reset();
            }
          }
        )
        .catch((error) => {
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_disposal_action
            ? props.constLabel?.lbl_edit_disposal_action
            : "Edit Disposal Action"
        }
        className="menu_icon_grey cursor_pointer me-2"
        size={20}
        onClick={() => {
          setVisible(true);
          reset();
        }}
      />
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_disposal_action
              ? props.constLabel?.lbl_update_disposal_action
              : "Update Disposal Action"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_disposal_action
                  ? props.constLabel?.lbl_disposal_action
                  : "Disposal Action"}
                <span className="text-danger">*</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={
                    errors.statusname
                      ? "form-control  is-invalid"
                      : "form-control "
                  }
                  id="statusname"
                  name="statusname"
                  defaultValue={
                    props.data !== undefined && props.data !== ""
                      ? props.data.statusname
                      : ""
                  }
                  {...register("statusname")}
                />
                <span className="text-danger err-msg">
                  {errors.statusname?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  {props.constLabel?.lbl_from_store
                    ? props.constLabel.lbl_from_store
                    : "From Store"}
                    <span className="text-danger"> *</span>
                </label>
                <div className="col-md-12">
                  <Controller
                    name="fromStore"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        options={
                          f_store.data !== undefined
                            ? f_store.data
                            : []
                        }
                        isLoading={f_store.loading}
                        placeholder="Select from store"
                        // onChange={onChange}
                        onChange={(selected) => {
                          onChange(selected);
                          const tt_store = getValues("toStore");
                          if (selected && selected.length > 0 && tt_store?.[0]?.name === selected?.[0]?.name) {
                            setValue("fromStore", []); // Clear toStore if they match
                          } 
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.fromStore ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger err-msg">
                    {errors.fromStore?.message}
                  </span>
                </div>
              </div>

              <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  {props.constLabel?.lbl_to_store
                    ? props.constLabel.lbl_to_store
                    : "To Store"}
                    <span className="text-danger"> *</span>
                </label>
                <div className="col-md-12">
                  <Controller
                    name="toStore"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        options={
                          t_store.data !== undefined
                            ? t_store.data
                            : []
                        }
                        isLoading={t_store.loading}
                        placeholder="Select to store"
                        // onChange={onChange}
                        onChange={(selected) => {
                          onChange(selected);
                          const fr_store = getValues("fromStore");
                          if (selected && selected.length > 0 && fr_store?.[0]?.name === selected?.[0]?.name) {
                            setValue("toStore", []); // Clear toStore if they match
                          } 
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.toStore ? true : false}
                      />
                    )}
                  />
                  <span className="text-danger err-msg">
                    {errors.toStore?.message}
                  </span>
                </div>
              </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditNCStatus;

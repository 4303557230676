import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { update_org_type } from "../../../api";

const schema = yup
  .object()
  .shape({
    name: yup
      .string()
      .required("Organisation type name is required!"),
  })
  .required();

function EditOrganisationType(props) {
    const [visible, setVisible] = useState(false);

    const { register, handleSubmit, formState, control, reset, watch } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        }); // initialise the hook
    
        let { errors } = formState;
    
        const onSubmit = (data) => {
            
        
            if (data !== "") {
              const reqPayload = {
                uuid:props.data.uuid,
                name: data.name.trim(),
              };
        
              
        
              update_org_type(reqPayload).then(
                  (res) => {
                    
                    if (res.status === 200 || res.status === 201) {
                      toast.success("Organisation type updated successfully !", {
                        autoClose: 3000,
                      });
                      setVisible(false);
                      props.fetchOrgnisationTypeList(props.page,props.entriesPerPage);
                    }
                  },
                  (err) => {
                    
                    if (err.response.status === 400) {
                      
                      toast.error(err.response.data.message, {
                        autoClose: 2000,
                      });
                      // reset();
                    }
                  }
                )
            } else {
              errors.showMessages();
            }
          };

  return (
    <React.Fragment>
    <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={props.constLabel?.lbl_edit_organization_type ? props.constLabel.lbl_edit_organization_type : "Edit Organization Type"} 
        className="menu_icon_grey cursor-pointer me-2 "
        size={18}
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true);
          reset();
        }}
        />
    <Modal show={visible} onHide={() => {setVisible(false); reset();}} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{props.constLabel?.lbl_update_organization_type ? props.constLabel.lbl_update_organization_type : "Update Organization Type"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
          <div className="form-group row mt-2">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label col-md-12 roboto-bold"
                >
                  {props.constLabel?.lbl_organization_type ? props.constLabel.lbl_organization_type : "Organization Type"}
                  <span className="text-danger">*</span>
                </label>
                <div className="col-md-12 ">
                  <input
                    type="text" 
                    className={
                        errors.name
                          ? "form-control  is-invalid"
                          : "form-control "
                      }
                    id="name"
                    name="name"
                    placeholder="Enter organisation type name"
                    defaultValue={props.data?.name}
                    {...register("name")}
                  />
                  <span className="text-danger err-msg">
                    {errors.name?.message}
                  </span>
                </div>
              </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>

  </React.Fragment>
  )
}

export default EditOrganisationType
import React, { useState, useEffect } from "react";
import { useForm, useFieldArray } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SweetAlert from "sweetalert2";
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faClose,
  faEye,
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import {
  getProductImage,
  deleteProductImage,
  createProductImage,
} from "../../../../../api";
import {
  convertBase64,
  getLabels,
} from "../../../../../common/Functions/CommonFunctions";
import { Trash2 } from "react-feather";

const imageFileTypes = ["image/jpeg", "image/jpg", "image/png"];

const schema = yup
  .object()
  .shape({
    items: yup.array().of(
      yup.object().shape({
        image: yup
          .mixed()
          .test(
            "fileSize",
            "Image can be uploaded upto 10 MB",
            (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)
          )
          .test("fileType", "Only image files are allowed", (value) => {
            if (!value) return true; // If no file is selected, it's considered valid.
            // return value[0]?.type.startsWith('image/');
            return imageFileTypes.includes(value[0]?.type);
          })
          .required("Required"),
        // .test(
        //   'fileType',
        //   'Only image files are allowed',
        //   (value) => !value || (value && /^image\/(jpeg|png|gif|jpg)$/.test(value.type))
        // ),
        fileName: yup.string().required("Image name is required !").matches(/\.(png|jpeg|jpg)$/, "Image name must end with .png, .jpeg, or .jpg"),
      })
    ),
  })
  .required();

function UploadProductImages({
  nextStep,
  previousStep,
  main_obj,
  setMain_obj,
}) {
  let navigate = useNavigate();
  const [prod_img, setProd_Img] = useState({ data: [], loading: true });
  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [{ image: null, fileName: "" }],
    },
  });

  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  const [constLabel, setConstLabel] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);
  const [product_type_name, setproduct_type_name]=useState("product")
  let { errors } = formState;

  // const prod_data = JSON.parse(localStorage.getItem("prod_res"));

  useEffect(() => {
    setConstLabel(getLabels("Master", "Product"));
    // console.log("main_obj",main_obj)
    //   if (prod_data !== undefined) {
    fetchProductImage();
    //   }
  }, []);

  function fetchProductImage() {
    // setProd_Img({ ...prod_img, data: [], loading: true });
    const payload = {
      // product_uuid: "75af1425-2151-4bf1-b8da-9ab22d95a285", //prod_data.uuid,
      product_uuid: main_obj && main_obj?.prod_overview?.uuid,
    };

    if(main_obj?.prod_overview.product_type_id?.productType=="Tools"){
      setproduct_type_name("tools")
    }else if(main_obj?.prod_overview.product_type_id?.productType=="Fixtures"){
      setproduct_type_name("fixtures")
    }else if(main_obj?.prod_overview.product_type_id?.productType=="Gauges"){
      setproduct_type_name("gauges")
    }

   
   
    getProductImage(payload).then(
      (res) => {
        // setProd_Img({ ...prod_img, data: res.data.data, loading: false });
        setMain_obj({ ...main_obj, prod_image: res.data.data });
      },
      (err) => {
        setMain_obj({ ...main_obj, prod_image: [] });

        // setProd_Img({ ...prod_img, data: [], loading: false });
      }
    );
  }

  const onSubmit = async (data) => {
    
    setBtnDisable(true);
    let counter = 0;
    // nextStep();
    for (const element of data.items) {
      const payload = {
        attachment: await convertBase64(element.image[0]),
        name: element.fileName,
        productId: main_obj && main_obj?.prod_overview?.productId,
        product_id: main_obj && main_obj?.prod_overview?.uuid,
        // productId: "Prod_ID-1171",
        // product_id: "43045592-2755-43f7-b18e-f3520aa4d398",
      };

      // const formDataToSend = new FormData();

      // for (const [key, value] of Object.entries(payload)) {
      //   formDataToSend.append(key, value);
      // }

      createProductImage(payload).then(
        (res) => {
          setBtnDisable(false);
          // Increment the counter
          counter++;

          // Check if it's the last iteration
        },
        (err) => {
          setBtnDisable(false);

          toast.error(err.response.data.message, {
            autoClose: 2000,
          });
        }
      );
    }
    fetchProductImage();

    toast.success("Image uploaded successfully", {
      autoClose: 2000,
    });

    reset({ items: [{ image: null, fileName: "" }] });
    setTimeout(() => {
      nextStep();
    }, [2300]);
  };

  function deleteImg(val) {
    SweetAlert.fire({
      title: "Are you sure to delete image ? \n",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = { uuid: val };
        deleteProductImage(payload).then(
          (res) => {
            toast.success("Image deleted successfully", {
              autoClose: 2000,
            });
            fetchProductImage();
          },
          (err) => {
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function deleteImgItem(index) {
    SweetAlert.fire({
      title: "Are you sure to delete image row ? \n",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        if (fields[index].uuid !== null && fields[index].uuid !== undefined) {
          deleteProductImage({ uuid: fields[index].uuid }).then(
            (res) => {
              toast.success("Image Deleted Successfully !", {
                autoClose: 1000,
              });
              fetchProductImage();
            },
            (err) => {
              toast.error("Something went wrong ");
            }
          );
        } else {
          remove(index);
        }
      }
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <div className="d-flex justify-content-between align-items-center">
            <p className="pc_card_title roboto-bold form-label mt-2 col-md-2">
              {constLabel?.lbl_add_images
                ? constLabel.lbl_add_images
                : "Add Images"}
            </p>
            <button
              style={{ fontSize: "16px" }}
              className="btn btn-sm button-primary px-2 mt-2"
              onClick={() => {
                append({});
              }}
            >
              <FontAwesomeIcon
                style={{
                  color: "#ffffff",
                }}
                icon={faAdd}
                size="sm"
              />{" "}
            </button>
          </div>
          <div className="py-2">
            <table className="table table-responsive table-bordered">
              <thead className="table-grey roboto-bold">
                <tr>
                  <th scope="col" width="30%">
                    {constLabel?.lbl_upload_image
                      ? constLabel.lbl_upload_image
                      : "Upload Image"}
                  </th>
                  <th scope="col" width="30%">
                    {constLabel?.lbl_image_name
                      ? constLabel.lbl_image_name
                      : "Image Name"}
                  </th>
                  <th scope="col" width="40%">
                    {"Action"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {fields.map(({ id }, index) => (
                  <tr key={id}>
                    <td style={{ verticalAlign: "top" }}>
                      <input
                        className={
                          errors.items?.[index]?.image
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        type="file"
                        accept="image/*"
                        name={`items[${index}].image`}
                        {...register(`items[${index}].image`)}
                        // onChange={(e) => handleFileChange(e, index)}
                        placeholder="Please select image"
                      />

                      {errors.items?.[index]?.image && (
                        <span className="text-danger err-msg">
                          {errors.items?.[index]?.image.message}
                        </span>
                      )}
                    </td>

                    <td>
                      <input
                        className={
                          errors.items?.[index]?.fileName
                            ? "form-control form-control-sm is-invalid"
                            : "form-control form-control-sm"
                        }
                        type="text"
                        name={`items[${index}].fileName`}
                        {...register(`items[${index}].fileName`)}
                        placeholder="Enter file name"
                      />

                      {errors.items?.[index]?.fileName && (
                        <span className="text-danger err-msg">
                          {errors.items?.[index]?.fileName.message}
                        </span>
                      )}
                    </td>

                    <td
                      style={{
                        verticalAlign: "top",
                        width: "10%",
                      }}
                    >
                      <div
                        className="deleteIcon cursor_pointer"
                        onClick={
                          index > 0
                            ? () => {
                                deleteImgItem(index);
                              }
                            : null
                        }
                      >
                        <Trash2 color="#FFFFFF" size={18} />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <hr />

          <div
            className="row image_gallery2 "
            // style={{ paddingTop: "10px", paddingBottom: "25px" }}
          >
            <div className="col-12">
              <div className="card border-0">
                <div className="card-body">
                  {/* <Slider {...sliderSetting}> */}
                  <div className="row">
                    {main_obj.prod_image.length > 0 ? (
                      main_obj.prod_image.map((imgSrc, index) => (
                        <div className="col-12 col-md-3" key={index}>
                          <div className="image-container">
                            <img
                              src={
                                process.env.REACT_APP_IMAGE_URL +
                                imgSrc.attachment
                              }
                              className="img-fluid"
                              alt="Image 1"
                            />

                            <div className="image-overlay">
                              <FontAwesomeIcon
                                onClick={() =>
                                  window.open(
                                    process.env.REACT_APP_IMAGE_URL +
                                      imgSrc.attachment,
                                    "_blank"
                                  )
                                }
                                className="icon"
                                style={{ color: "#fff" }}
                                icon={faEye}
                                size="xl"
                              />

                              <FontAwesomeIcon
                                onClick={() => deleteImg(imgSrc.uuid)}
                                className="icon-close"
                                style={{ color: "#fff" }}
                                icon={faClose}
                                size="lg"
                              />

                              <span className="image-name cursor_pointer">
                                {imgSrc.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div
                        className="alert alert-danger mb-0 text-center my-2"
                        role="alert"
                      >
                        {`No ${product_type_name} image found !`}
                      </div>
                    )}
                  </div>
                  {/* </Slider> */}
                </div>
              </div>
            </div>
          </div>

          <hr />
          <div className="d-flex justify-content-end">
            {/* <button
              className="btn btn-yellow px-4 me-2"
              onClick={() => navigate("/pcms/product")}
            >
              {constLabel?.lbl_cancel ? constLabel?.lbl_cancel : ""}
            </button> */}
            <button
              className="btn btn-grey px-4 me-2"
              onClick={() => previousStep()}
            >
              {constLabel?.lbl_previous ? constLabel?.lbl_previous : "Previous"}
            </button>
            <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
              disabled={btnDisable}
            >
              {constLabel?.lbl_save_continue
                ? constLabel?.lbl_save_continue
                : "Save & Continue"}
            </button>
            <button
              className="btn btn-grey px-4 me-2"
              onClick={() => nextStep()}
            >
              {constLabel?.lbl_skip ? constLabel?.lbl_skip : "Skip"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default UploadProductImages;

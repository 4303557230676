import React, { useEffect, useState } from "react";
import "./NewCustomer.css"; // Import your CSS file for styling
import { useLocation, useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewCreateBasicDetail from "./BasicDetail/NewBasicDetails";
import NewCreateContactDetails from "./ContactDetail/NewCreateContactDetails";
import NewCreateBillingAddress from "./BillingAddress/NewCreateBillingAddress";
import NewBillingDetail from "./BillingDetail/NewBillingDetails";
import Preview from "./Preview";
import { get_party_contact } from "../../../../api";
import { ToastContainer } from "react-toastify";
import { getLabels } from "../../../../common/Functions/CommonFunctions";
const NewEditCustomer = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [constLabel, setConstLabel] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const steps_array = [
    "Customer Overview",
    "Contact Details",
    "Billing/Shiping Address",
    // "Tax Details",
  ];
  const numSteps = 3;
  const {
    partyType,
    customerType,
    customer_type_id,
    org_id,
    name,
    primaryContactNo,
    secondaryContactNo,
    email,
    website,
    country,
    country_id,
    country_name,
    state,
    state_id,
    state_name,
    city,
    city_id,
    city_name,
    postalCode,
    address,
    organization,
    party_contact,
    billingAddresse,
    shippingAddresse,
    gst_treatment,
    TIN,
    gst,
    serviceTaxNo,
    CSTNO,
    VATNO,
    PANNO,
    TAN,
    createdBy,
    modifiedBy,
  } = location.state || {};

  let obj = {
    partyType,
    customerType,
    customer_type_id,
    org_id,
    name,
    primaryContactNo,
    secondaryContactNo,
    email,
    website,
    country,
    country_id,
    country_name,
    state,
    state_id,
    state_name,
    city,
    city_id,
    city_name,
    postalCode,
    address,
    organization,
    party_contact,
    billingAddresse,
    shippingAddresse,
    gst_treatment,
    TIN,
    gst,
    serviceTaxNo,
    CSTNO,
    VATNO,
    PANNO,
    TAN,
    createdBy,
    modifiedBy,
  };

  const [filteredBilling, setFilteredBilling] = useState(
    location?.state?.billingAddresses.map(
      ({
        createdDate,
        modifiedDate,
        isActive,
        createdBy_id,
        modifiedBy_id,
        id,
        addressId,
        ...rest
      }) => rest
    )
  );

  const [filteredShipping, setFilteredShipping] = useState(
    location?.state?.shippingAddresses.map(
      ({
        createdDate,
        modifiedDate,
        isActive,
        createdBy_id,
        modifiedBy_id,
        id,
        addressId,
        ...rest
      }) => rest
    )
  );

  const [main_obj, setMain_obj] = useState({ ...location.state });
  const nextStep = () => {
    let next = currentStep + 1;
    // if (next > numSteps) {
    //     next = 1;
    // }
    setCurrentStep(next);
  };

  // const previousStep = () => {
  //     let prev = currentStep - 1;
  //     if (prev < 1) {
  //         prev = numSteps;
  //     }
  //     setCurrentStep(prev);
  // };

  const previousStep = (stepNumber) => {
    if (stepNumber !== undefined && stepNumber >= 1 && stepNumber <= numSteps) {
      setCurrentStep(stepNumber);
    } else {
      let prev = currentStep - 1;
      if (prev < 1) {
        prev = numSteps;
      }
      setCurrentStep(prev);
    }
  };

  useEffect(() => {
    setConstLabel(getLabels("Party Master", "Customer"));
  }, []);

  useEffect(() => {
    if (location.state?.uuid) {
      const payload = {
        partyId: location.state?.uuid,
      };
      get_party_contact(payload).then(
        (res) => {
          var contactArr = [];
          if (res.data.data?.length > 0) {
            for (let i = 0; i < res.data.data.length; i++) {
              contactArr.push({
                uuid: res.data.data[i].uuid,
                firstName: res.data.data[i].firstName,
                lastName: res.data.data[i].lastName,
                mobileNo: res.data.data[i].mobileNo,
                landlineNo: res.data.data[i].landlineNo,
                landlineExtentionNo: res.data.data[i].landlineExtentionNo,
                alternateMobileNo: res.data.data[i].alternateMobileNo,
                alternateLandlineExtentionNo:
                  res.data.data[i].alternateLandlineExtentionNo,
                alternateLandlineNo: res.data.data[i].alternateLandlineNo,
                // contactId: res.data.data[i].contactId,
                emailid: res.data.data[i].emailid,
                party: res.data.data[i].party,
                // partyId: res.data.data[i].partyId,
                designation: res.data.data[i].designation,
                remark: res.data.data[i].remark,
              });
            }

            let reqpayload = {
              ...obj,
              uuid: location.state.uuid,
              party_contact: contactArr,
              billingAddresse: filteredBilling,
              shippingAddresse: filteredShipping,
            };
            delete reqpayload.billingAddresses;
            delete reqpayload.shippingAddresses;

            console.log("reqpayload", reqpayload);
            setMain_obj(reqpayload);
          }
        },
        (err) => {}
      );
    }
  }, [location.state?.uuid]);
  return (
    <>
      <ToastContainer />
      <div className="mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                SweetAlert.fire({
                  title: "Are you sure?",
                  text: "Your present data will be lost!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, go back!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.onpopstate = undefined;

                    navigate("/pcms/customer");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_update_customer
                ? constLabel?.lbl_update_customer
                : "Update Customer"}
            </p>
          </div>
          <div className="card-body" id="party_master">
            <div className="stepper-horizontal" id="stepper1">
              {[...Array(numSteps)].map((_, index) => {
                const stepNum = index + 1;
                const isEditing = stepNum === currentStep ? "editing" : "";
                const isDone = stepNum < currentStep ? "done" : "";

                return (
                  <div className={`step ${isEditing} ${isDone}`} key={index}>
                    <div className="step-circle">
                      <span>{stepNum}</span>
                    </div>
                    <div className="step-title">{steps_array[stepNum - 1]}</div>
                    <div className="step-bar-left"></div>
                    <div className="step-bar-right"></div>
                  </div>
                );
              })}
            </div>

            {currentStep == 1 && (
              <NewCreateBasicDetail
                nextStep={nextStep}
                main_obj={main_obj}
                setMain_obj={setMain_obj}
              />
            )}
            {currentStep == 2 && (
              <NewCreateContactDetails
                nextStep={nextStep}
                previousStep={previousStep}
                main_obj={main_obj}
                setMain_obj={setMain_obj}
              />
            )}
            {currentStep == 3 && (
              <NewCreateBillingAddress
                nextStep={nextStep}
                previousStep={previousStep}
                main_obj={main_obj}
                setMain_obj={setMain_obj}
              />
            )}
            {/* {currentStep == 4 && (
              <NewBillingDetail
                nextStep={nextStep}
                previousStep={previousStep}
                main_obj={main_obj}
                setMain_obj={setMain_obj}
              />
            )} */}
            {currentStep == 4 && (
              <Preview previousStep={previousStep} main_obj={main_obj} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewEditCustomer;

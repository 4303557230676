import React, { useState, useEffect} from 'react'
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd } from "@fortawesome/free-solid-svg-icons";
import { Trash2, Upload } from 'react-feather';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Edit2, Tool } from 'react-feather';
import { Modal } from 'react-bootstrap';
// import { updateLocationType } from '../../../../api';

const schema = yup.object().shape({
    items: yup.array().of(
      yup.object().shape({
        docType: yup
          .array()
          .min(1,"Select atleast one type")
          .required("Document type is required !"),
        drawingNo: yup
          .number()
          .typeError("Please enter a valid number")
          .required("Drawing no is required !"),
      })
    ),
    // selfile: yup
    //     .mixed()
    //     .test('fileSize', 'Image can be uploaded upto 10 MB',
    //         (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)),
  })
  .required();

function DrawingFile() {
    const [typeList, setTypeList] = useState({ data:[{name: "type1"}, {name: "type2"}], loading: false});
    const [visible, setVisible] = useState(false);

    const { register, handleSubmit, formState, control, reset, watch } = useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
      defaultValues: {
        items: [{ docType: [], drawingNo:"", selFile: ""}],
      },
    }); // initialise the hook
    
    const { fields, append, remove } = useFieldArray({
        control,
        name: "items",
      });
    
    let { errors } = formState;
    
    const onSubmit = (data) => {
      
    }

  return (
    <React.Fragment>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">{"Upload File"}</Tooltip>}
      >
        <div className="downloadIcon me-2">
          <Upload
            color="#FFFFFF"
            size={18}
            className="cursor-pointer"
            onClick={() => {
              // setUpdateForm(val)
              setVisible(true);
              reset();
            }}
          />
        </div>
      </OverlayTrigger>
      <Modal show={visible} onHide={() => setVisible(false)} size={"xl"}>
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>{"Add Drawing File"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form action="">
          <div
                className="table-responsive py-3"
                style={{ overflowX: "scroll" }}
              >
                <table className="table table-bordered">
                  <thead className="thead-light text-center">
                    <tr>
                      <th scope="col">{"Document Type"}</th>
                      <th scope="col">{"Drawing No"}</th>
                      <th scope="col">{"Drawing File"}</th>
                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id} className="f-18">
                        <td>
                        <Controller
                            name={`items[${index}].docType`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                size="sm"
                                id="basic-typeahead"
                                labelKey="name"
                                multiple={false}
                                options={typeList.data}
                                isLoading={typeList.loading}
                                placeholder="Select document type"
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                                isInvalid={
                                  errors.items?.[index]?.docType ? true : false
                                }
                              />
                            )}
                          />
                          {errors.items?.[index]?.docType && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.docType.message}
                            </span>
                          )}

                          {index + 1 == fields.length ? (
                            <div>
                              <button
                                style={{ fontSize: "16px" }}
                                className="btn btn-sm button-primary px-2 mt-2"
                                onClick={() => append({}) }
                              >
                                <FontAwesomeIcon
                                  style={{
                                    color: "#ffffff",
                                    marginRight: "5px",
                                  }}
                                  icon={faAdd}
                                  size="sm"
                                />{" "}
                                Add
                              </button>
                            </div>
                          ) : null}
                        </td>
                        <td style={{ verticalAlign: "top" }}>

                        <input
                            className="form-control form-control-sm"
                            type="text"
                            name={`items[${index}].drawingNo`}
                            {...register(`items[${index}].drawingNo`)}
                            // onChange={(e) => handleFileChange(e, index)}
                            placeholder="Enter drawing no"
                          />

                          {errors.items?.[index]?.drawingNo && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.drawingNo.message}
                            </span>
                          )}
                          
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                        <input
                            className={
                              errors.items?.[index]?.selFile
                                ? "form-control form-control-sm is-invalid"
                                : "form-control form-control-sm"
                            }
                            type="file"
                            name={`items[${index}].selFile`}
                            {...register(`items[${index}].selFile`)}
                            placeholder="Select file"
                          />

                          {errors.items?.[index]?.selFile && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.selFile.message}
                            </span>
                          )}
                        </td>

                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                            width: "10%",
                          }}
                        >
                          <div
                            className="deleteIcon cursor_pointer"
                            onClick={index > 0 ? () => remove(index) : null }
                          >
                            <Trash2 color="#FFFFFF" size={18} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
          </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {"Save"}
            </button>
          </Modal.Footer>
        </Modal>

    </React.Fragment>
  )
}

export default DrawingFile
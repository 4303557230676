import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import {
  getInternalCompany,
  save_internal_company,
  get_company_type,
  get_org_type,
  get_gst_treatment,
  get_industry_type,
} from "../../../api/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { getLabels } from "../../../common/Functions/CommonFunctions";
import { Modal, Spinner } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

const schema = yup
  .object()
  .shape({
    orgnisation_type: yup
      .array()
      .min(1, "Please select orgnisation type !")
      .required("Please select orgnisation type !"),
    company_type: yup
      .array()
      .min(1, "Please select company type !")
      .required("Please select company type !"),
    gst_type: yup
      .array()
      .min(1, "Please select gst registration type !")
      .required("Please select gst registration type !"),
    comName: yup.string().required("Please enter company name !"),
    comaddress: yup.string().required("Please enter company address !"),
    comGSTnumber: yup.string().when(["gst_type"], (gst_type, schema) => {
      // Check if 'machine_' exists in any of the selected categories

      if (gst_type !== undefined && gst_type.length > 0) {
        const gst_typeSelected = gst_type[0].name == "Regular";

        // If 'machine_' is in the category, make 'supplier' mandatory
        return gst_typeSelected
          ? schema
              .required("Please enter GST number !")
              .max(15, "GST number must be 15 characters only !")
              .matches(/^[A-Z0-9]*$/, "Enter valid GST number !")
          : schema;
      } else {
        return schema;
      }
    }),
    first_name: yup
      .string()
      .matches(/^[a-zA-Z ]*$/, "Enter valud first name!")
      .notRequired(),
    middle_name: yup
      .string()
      .matches(/^[a-zA-Z ]*$/, "Enter valud middle name!")
      .notRequired(),
    last_name: yup
      .string()
      .matches(/^[a-zA-Z ]*$/, "Enter valud last name!")
      .notRequired(),
    mail: yup
      .string()
      .email("Invalid email address !")
      .required("Please enter mail !"),
    phone: yup
      .string()
      .required("Please enter phone number !")
      .matches(/^[0-9]+(\.[0-9]+)?$/, "Enter valid phone number !"),
    landline: yup
      .string()
      .max(10, "Landline number must be 10 characters only")
      .matches(/^[0-9]*$/, "Please enter a valid landline number")
      .notRequired()
  })
  .required();

function AddOrganization(props) {
  let location = useLocation();

  const [visible, setVisible] = useState(false);
  const [parentlist, setParentlist] = useState({
    data: [],
    loading: false,
  });
  const [companytype, setCompanytype] = useState({
    data: [],
    loading: false,
  });
  const [orgtype, setOrgtype] = useState({
    data: [],
    loading: false,
  });
  const [gstType, setGstType] = useState({
    data: [],
    loading: false,
  });

  const [industryType, setIndustryType] = useState({
    data: [],
    loading: false,
  });
  

  var FormData = require("form-data");
  const { register, handleSubmit, formState, control, reset, watch, setValue, setError, clearErrors } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook
  let navigate = useNavigate();
  

  const [constLabel, setConstLabel] = useState({});

  let { errors } = formState;
  let gst_Type = watch("gst_type");

  useEffect(() => {
    setConstLabel(getLabels("Master", "Organization"));
    if(visible){
        fetchInternal_company();
        fetchCompanyType();
        fetchOrgType();
        fetchGstType();
        fetchIndustryType();
    }
  }, [visible]);

  const fetchInternal_company = () => {
    setParentlist({
      data: [],
      loading: true,
    });

    getInternalCompany({})
      .then((res) => {
        setParentlist({
          data: res.data.data,
          loading: false,
        });
      })
      .catch((err) => {
        setParentlist({
          data: [],
          loading: false,
        });
      });
  };

  function fetchCompanyType() {
    setCompanytype({ loading: true, data: [] });

    get_company_type().then(
      (res) => {
        setCompanytype({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setCompanytype({ loading: false, data: [] });
      }
    );
  }

  function fetchOrgType() {
    setOrgtype({ loading: true, data: [] });

    get_org_type().then(
      (res) => {
        setOrgtype({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setOrgtype({ loading: false, data: [] });
      }
    );
  }

  function fetchGstType() {
    setGstType({ loading: true, data: [] });

    get_gst_treatment().then(
      (res) => {
        const result = res.data.data.find((item) => item.name === "Regular");
        if (result) {
          setValue("gst_type", [result]);
        }

        setGstType({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setGstType({ loading: false, data: [] });
      }
    );
  }

  function fetchIndustryType() {
    setIndustryType({ loading: true, data: [] });

    get_industry_type().then(
      (res) => {
        setIndustryType({
          loading: false,
          data: res.data.data,
        });
      },
      (err) => {
        //
        setIndustryType({ loading: false, data: [] });
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {

        const payload = {
            ...data,
            parent_id:
              data.parent_id !== undefined && data.parent_id.length !== 0
                ? data.parent_id[0].uuid
                : null,
            company_type: data.company_type[0].name,
            orgnisation_type: data.orgnisation_type[0].name,
            gst_type: data.gst_type[0].name,
            industry: data.industry?.[0]?.type_name ?? ""
          };

      save_internal_company(payload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Organization created successfully !", {
              autoClose: 2000,
            });

            setTimeout(() => {
              props.fetchInternal_company();
              setVisible(false);
            }, 2200);
           
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };


  return (
    <React.Fragment>
    <Tooltip id={"add-tooltip"} place="top" />
      <div
        data-tooltip-id={"add-tooltip"}
        data-tooltip-content={
          constLabel?.lbl_add_organization
            ? constLabel?.lbl_add_organization
            : "Add Organization"
        }
        className="cursor_pointer me-2"
       
    ><a
        className="px-2 mt-2 cursor_pointer"
        onClick={(e) => {
            // setUpdateForm(val)
            e.preventDefault();
            setVisible(true);
            reset();
        }}
    >
        {constLabel?.lbl_add_organization ? constLabel.lbl_add_organization : "Add Organization"}
    </a>
    </div>
    <Modal show={visible} onHide={() => setVisible(false)} size={"xl"}>
    <Modal.Header
      style={{ background: "#2A3643", color: "white" }}
      closeButton
    >
      <Modal.Title>{constLabel?.lbl_add_organization ? constLabel.lbl_add_organization : "Add Organization"}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
    <div className="container-fluid">
              <div className="row">
                <div className="col-12 col-sm-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_parent
                        ? constLabel?.lbl_parent
                        : "Parent"}
                      {/* <span className="text-danger">*</span> */}
                    </label>
                    <Controller
                      name="parent_id"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="comName"
                          multiple={false}
                          options={parentlist.data}
                          isLoading={parentlist.loading}
                          placeholder="Select Parent "
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.parent_id ? true : false}
                        />
                      )}
                    />
                    {!parentlist.loading && <span className="arrow-icon"></span>}
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_company_name
                        ? constLabel?.lbl_company_name
                        : "Company Name"}
                      <span className="text-danger">*</span>
                    </label>
                    <input
                      className={
                        errors.comName
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="comName"
                      name="comName"
                      placeholder="Enter company name"
                      {...register("comName")}
                    />
                    <span className="text-danger err-msg">
                      {errors.comName?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_organization_type
                        ? constLabel?.lbl_organization_type
                        : "Organization Type"}
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name={`orgnisation_type`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="name"
                          multiple={false}
                          options={orgtype.data}
                          isLoading={orgtype.loading}
                          placeholder="Select organization type"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.orgnisation_type ? true : false}
                        />
                      )}
                    />
                    {!orgtype.loading && !errors.orgnisation_type && <span className="arrow-icon"></span>}
                    {errors.orgnisation_type && (
                      <span className="text-danger err-msg">
                        {errors.orgnisation_type.message}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 col-sm-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_company_type
                        ? constLabel?.lbl_company_type
                        : "Company Type"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>
                    <Controller
                      name="company_type"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="name"
                          multiple={false}
                          options={companytype.data}
                          isLoading={companytype.loading}
                          placeholder="Select company type "
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.company_type ? true : false}
                        />
                      )}
                    />
                    {!companytype.loading && !errors.company_type && <span className="arrow-icon"></span>}
                    <span className="text-danger err-msg">
                      {errors.company_type?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_gst_type
                        ? constLabel?.lbl_gst_type
                        : "GST Registration Type"}
                      <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name={`gst_type`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="name"
                          multiple={false}
                          options={gstType.data}
                          isLoading={gstType.loading}
                          placeholder="Select gst registration type"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          isInvalid={errors.gst_type ? true : false}
                        />
                      )}
                    />
                    {!gstType.loading && !errors.gst_type && <span className="arrow-icon"></span>}
                    {errors.gst_type && (
                      <span className="text-danger err-msg">
                        {errors.gst_type.message}
                      </span>
                    )}
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_gst_number
                        ? constLabel.lbl_gst_number
                        : "GST Number"}
                        { gst_Type && gst_Type?.[0]?.name == "Regular" ? <span className="text-danger" > *</span> : null }
                    </label>

                    <input
                      className={
                        errors.comGSTnumber
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="comGSTnumber"
                      name="comGSTnumber"
                      maxLength={15}
                      placeholder="Enter GST number"
                      {...register("comGSTnumber")}
                    />
                    {errors.comGSTnumber && gst_Type && gst_Type?.[0]?.name == "Regular" ? 
                      <span className="text-danger err-msg">
                        {errors.comGSTnumber?.message}
                      </span> : null
                    }
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_company_address
                        ? constLabel?.lbl_company_address
                        : "Company Address"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>

                    <input
                      className={
                        errors.comaddress
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="comaddress"
                      name="comaddress"
                      placeholder="Enter company address"
                      {...register("comaddress")}
                    />
                    <span className="text-danger err-msg">
                      {errors.comaddress?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_phone_number
                        ? constLabel?.lbl_phone_number
                        : "Phone Number"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>

                    <input
                      className={
                        errors.phone
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="phone"
                      name="phone"
                      maxLength={10}
                      placeholder="Enter phone number"
                      {...register("phone")}
                    />
                    <span className="text-danger err-msg">
                      {errors.phone?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_mail ? constLabel?.lbl_mail : "Mail"}
                      <span>
                        <i style={{ color: "red" }}>*</i>
                      </span>
                    </label>

                    <input
                      className={
                        errors.mail
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="mail"
                      name="mail"
                      placeholder="Enter mail"
                      {...register("mail")}
                    />
                    <span className="text-danger err-msg">
                      {errors.mail?.message}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_location_name
                        ? constLabel?.lbl_location_name
                        : "Location Name"}
                    </label>

                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="location_name"
                      name="location_name"
                      placeholder="Enter location"
                      {...register("location_name")}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group typeahead-container">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_industry_type
                        ? constLabel?.lbl_industry_type
                        : "Industry Type"}
                     
                    </label>
                    <Controller
                      name="industry"
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          className={"mt-2"}
                          id="basic-typeahead-single"
                          labelKey="type_name"
                          multiple={false}
                          options={industryType.data}
                          isLoading={industryType.loading}
                          placeholder="Select industry type "
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                          // isInvalid={errors.industry ? true : false}
                        />
                      )}
                    />
                    {!industryType.loading && <span className="arrow-icon"></span>}
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_first_name
                        ? constLabel?.lbl_first_name
                        : "First Name"}
                      {/* <span>
                        <i style={{ color: "red" }}>*</i>
                      </span> */}
                    </label>

                    <input
                      className={
                        errors.first_name
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="first_name"
                      name="first_name"
                      placeholder="Enter first name"
                      {...register("first_name")}
                    />
                    <span className="text-danger err-msg">
                      {errors.first_name?.message}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row mt-2">
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_middle_name
                        ? constLabel?.lbl_middle_name
                        : "Middle Name"}
                      {/* <span>
                        <i style={{ color: "red" }}>*</i>
                      </span> */}
                    </label>

                    <input
                      className={
                        errors.middle_name
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="middle_name"
                      name="middle_name"
                      placeholder="Enter middle name"
                      {...register("middle_name")}
                    />
                    <span className="text-danger err-msg">
                      {errors.middle_name?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_last_name
                        ? constLabel?.lbl_last_name
                        : "Last Name"}
                      {/* <span>
                        <i style={{ color: "red" }}>*</i>
                      </span> */}
                    </label>

                    <input
                      className={
                        errors.last_name
                          ? "form-control mt-2 is-invalid"
                          : "form-control mt-2"
                      }
                      type="text"
                      id="last_name"
                      name="last_name"
                      placeholder="Enter last name"
                      {...register("last_name")}
                    />
                    <span className="text-danger err-msg">
                      {errors.last_name?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_landline_number
                        ? constLabel?.lbl_landline_number
                        : "Landline Number"}
                      {/* <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span> */}
                    </label>

                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="landline"
                      name="landline"
                      maxLength={10}
                      placeholder="Enter landline number"
                      {...register("landline")}
                    />
                    <span className="text-danger err-msg">
                      {errors.landline?.message}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_website
                        ? constLabel?.lbl_website
                        : "Website"}
                      {/* <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span> */}
                    </label>

                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="website"
                      name="website"
                      placeholder="Enter website"
                      {...register("website")}
                    />
                    {/* <span className="text-danger err-msg">
                                            {errors.website?.message}
                                        </span> */}
                  </div>
                </div>
                <div className="col-12 col-sm-4">
                  <div className="form-group">
                    <label className="lbl-style roboto-bold">
                      {constLabel?.lbl_linkedIn
                        ? constLabel?.lbl_linkedIn
                        : "Linkedln"}
                      {/* <span>
                                                <i style={{ color: "red" }}>*</i>
                                            </span> */}
                    </label>

                    <input
                      className={"form-control mt-2"}
                      type="text"
                      id="linkedin"
                      name="linkedin"
                      placeholder="Enter linkedln url "
                      {...register("linkedin")}
                    />
                    {/* <span className="text-danger err-msg">
                                            {errors.website?.message}
                                        </span> */}
                  </div>
                </div>
              </div>
            </div>
            <hr className="m-3" />
    
            <div className="d-flex justify-content-end mt-4">
              <div>
                <button
                  className="btn btn-grey px-3 mx-3"
                  onClick={handleSubmit(onSubmit)}
                >
                  {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
                </button>
              </div>
            </div>
      
    </Modal.Body>
  </Modal>
    </React.Fragment>
  );
}

export default AddOrganization;

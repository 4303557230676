import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faDownload,
  faSearch,
  faFilter,
  faBan,
  faPlus,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import {
  getRoles,
  deleteRoleApi,
  get_role_api,
  delete_role_api,
} from "../../../api/index";
import { Pagination } from "react-bootstrap";
import { Edit2, Eye, Trash2, Image } from "react-feather";
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { Tooltip } from "react-tooltip";
import { getLabels } from "../../../common/Functions/CommonFunctions";
import { Roles_Col_Name } from "../../../common/Labels/labelConstant";

function Roles() {
  const [showLabel, setShowLabel] = useState(Roles_Col_Name);
  let navigate = useNavigate();
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [roleList, setRoleList] = useState({ data: [], loading: true });
  const login_data = useSelector((state) => state.auth);
  const [currMod, setCurrMod] = useState({});

  const [constLabel, setConstLabel] = useState({});

  useEffect(() => {
    setConstLabel(getLabels("Role Management", "Roles"));
  }, []);

  useEffect(() => {
    

    const resultMod = login_data.loginData.data.response.module_list.filter(
      (val) => {
        return val.module == "Role Management";
      }
    );
    
    setCurrMod(resultMod[0]);

    fetchRoles();
  }, []);

  function fetchRoles() {
    setRoleList({ ...roleList, data: [], loading: true });

    get_role_api().then(
      (res) => {
        
        setRoleList({
          ...roleList,
          data: res.data.data.response,
          loading: false,
        });
      },
      (err) => {
        setRoleList({ ...roleList, data: [], loading: false });
        
      }
    );
  }

  const delRole = (val) => {
    
    SweetAlert.fire({
      title: "Are you sure to delete \n" + val.name + " ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        delete_role_api({ role_id: val.role_id }).then(
          (res) => {
            
            toast.success("Role deleted successfully", {
              autoClose: 2000,
            });
            fetchRoles();
            // if (roleList.data.length == 1) {

            // } else {

            // }
          },
          (err) => {
            
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  };

  function handleClick(ind) {
    

    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="roles">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="bold">
            {constLabel?.lbl_roles ? constLabel.lbl_roles : "Roles"}
          </h1>
          <button
            className="btn button-primary px-4"
            onClick={() => {
              navigate("/pcms/createrole", { state: constLabel });
            }}
          >
            <FontAwesomeIcon
              style={{ color: "#fff" }}
              icon={faPlus}
              size="sm"
            />{" "}
            {constLabel?.lbl_add_role ? constLabel.lbl_add_role : "Add Role"}
          </button>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="card border-0 mt-3 mb-4">
              <div className="card-body">
                <div className="entriesPerPage roboto-bold d-flex justify-content-between align-items-end">
                  <p className="mb-0 invisible">
                    Show
                    <select
                      className="mx-1"
                      defaultValue={"10"}
                      onChange={(e) => {
                        setEntriesPerPage(e.target.value);
                        fetchRoles(page, e.target.value);
                      }}
                    >
                      <option value={"10"}>10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                    Entries
                  </p>

                  <div className="dropdown">
                    <div
                      className="settignIcon_custom_nc d-flex justify-content-center align-items-center"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <FontAwesomeIcon
                        style={{ color: "#fff" }}
                        icon={faCog}
                        size="sm"
                      />
                    </div>

                    <ul className="dropdown-menu px-2">
                      {showLabel !== undefined && showLabel.length > 0
                        ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                        : null}
                    </ul>
                  </div>
                </div>
                {roleList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : roleList.data?.length > 0 ? (
                  <div className="table-responsive">
                    <table className="table mt-2 table-bordered table-sm">
                      <thead className="table-grey  roboto-bold">
                        <tr className="">
                          <th scope="col">{"SrNo"}</th>
                        {showLabel.map((v, i) => 
                          v.label === "Role Name" && v.flag ? (<th key={i} scope="col">
                            {constLabel?.lbl_role_name
                              ? constLabel.lbl_role_name
                              : "Role Name"}
                          </th>) : null
                          )}
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody className=" roboto">
                        {roleList.data.map((val, i) => (
                          <tr key={i}>
                            <td style={{ verticalAlign: "middle" }}>
                              {parseInt(i) + 1 + parseInt(srNo)}
                            </td>
                            {showLabel.map((item, idx) => 
                              item.label === "Role Name" && item.flag ? (<td key={idx}>{val.name != "" && val.name !== null ? val.name : "Na"}</td>) : null
                            )}
                            <td className="">
                              <Tooltip id={"edit-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"edit-tooltip" + i}
                                data-tooltip-content={
                                  constLabel?.lbl_edit_role
                                    ? constLabel.lbl_edit_role
                                    : "Edit Role"
                                }
                                onClick={() =>
                                  navigate("/pcms/updaterole", {
                                    state: {
                                      locVal: val,
                                      constLabel: constLabel,
                                    },
                                  })
                                }
                                className="editIcon m-1 cursor_pointer"
                              >
                                <Edit2 color="#FFFFFF" size={18} />
                              </div>

                              <Tooltip id={"delete-tooltip" + i} place="top" />
                              <div
                                data-tooltip-id={"delete-tooltip" + i}
                                data-tooltip-content={
                                  constLabel?.lbl_delete_role
                                    ? constLabel.lbl_delete_role
                                    : "Delete Role"
                                }
                                onClick={() => delRole(val)}
                                className="deleteIcon m-1 cursor_pointer"
                              >
                                <Trash2 color="#FFFFFF" size={18} />
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                ) : (
                  <p className="text-center text-danger mb-0">No Roles Found</p>
                )}

                {/* {ncList.loading ? null :

                                    ncList.data?.length > 0 ?
                                        (
                                            <div className="mt-4 d-flex justify-content-between">
                                                <p className='mb-0'>{`Showing ${parseInt(srNo) + 1} to ${ncList.data.length + (entriesPerPage * page) - entriesPerPage} of ${ncList.totalRecords} entries`}</p>
                                                <Pagination className="my-2">
                                                    <Pagination.Prev
                                                        disabled={page === 1 ? true : false}
                                                        onClick={() => {
                                                            fetchNCList(page - 1, entriesPerPage, parseInt(srNo) - parseInt(entriesPerPage))
                                                            setSrNo((prevC) => page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage));
                                                            setPage(page - 1)
                                                        }}
                                                    >
                                                        {"Prev"}
                                                    </Pagination.Prev>

                                                    <Pagination.Item active>{page}</Pagination.Item>

                                                    <Pagination.Next
                                                        disabled={
                                                            page === maxPage || maxPage === 0 || entriesPerPage > ncList.data.length
                                                                ? true
                                                                : false
                                                        }
                                                        onClick={() => {
                                                            
                                                            fetchNCList(page + 1, entriesPerPage, parseInt(srNo) + parseInt(entriesPerPage))
                                                            setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                                                            setPage(page + 1)
                                                        }}
                                                    >
                                                        {"Next"}
                                                    </Pagination.Next>
                                                </Pagination>
                                            </div>
                                        ) : null} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Roles;

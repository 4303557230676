import React from "react";
import "react-toastify/dist/ReactToastify.css";


function OTPInput(props) {
  // console.log("props", props);

  const handleChangeOtp = (e, index) => {
    const { value } = e.target;

    // Only allow single digit input
    if (value.match(/^\d$/)) {
      const newOtp = [...props.otp];
      newOtp[index] = value;
      props.setOtp(newOtp);
      // props.setMob_Otp(newOtp.join(""));
      // Move focus to the next input
      if (index < props.length - 1) {
        props.otpInputs.current[index + 1].focus();
      }
    }

    // Move focus to previous input on backspace
    if (value === "" && index > 0) {
      props.otpInputs.current[index - 1].focus();
    }
  };

  const handleKeyDownOtp = (e, index) => {
    if (e.key === "Backspace") {
      const newOtp = [...props.otp];

      // If current input is not empty, just clear it
      if (props.otp[index] !== "") {
        newOtp[index] = "";
        props.setOtp(newOtp);
      } else if (index > 0) {
        // If current input is empty, move to the previous input and clear it
        props.otpInputs.current[index - 1].focus();
        newOtp[index - 1] = "";
        props.setOtp(newOtp);
      }
    }
  };

  return (
    <div className="d-flex flex-column justify-content-between align-items-start mt-2">
      {/* Mobile OTP Section */}
      <div className="form-group mb-3">
        <label className="col-form-label body2 roboto-bold me-3">
          Enter OTP
        </label>
        <div className="d-flex justify-content-center">
          {props.otp?.map((_, index) => (
            <input
              key={index}
              type="text"
              maxLength="1"
              value={props.otp[index]}
              onChange={(e) => handleChangeOtp(e, index)}
              onKeyDown={(e) => handleKeyDownOtp(e, index)}
              ref={(el) => (props.otpInputs.current[index] = el)}
              className="form-control text-center mx-1"
              style={{ width: "40px", height: "40px", fontSize: "18px" }} // Remove this if you replace with custom CSS
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default OTPInput;

import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect} from 'react';

function BillingShippingAddress(props) {
    const {billingAddresses, shippingAddresses} = props.data;

    const {addressLine1, addressLine2, country_name, state_name, city_name, postalCode } = billingAddresses !== undefined && billingAddresses.length > 0 ? billingAddresses[0] : [];

    const {addressLine1: addressLine1_s, addressLine2: addressLine2_s, country_name: country_name_s, state_name : state_name_s, city_name: city_name_s, postalCode: postalCode_s  } = shippingAddresses !== undefined && shippingAddresses.length > 0 ? shippingAddresses[0] : [];

  return (
    <React.Fragment>
        <div className='row'>
        <div className="col-12 col-md-6">
                  <div className="card border-0">
                    <div className="card-body p-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="roboto-bold">Billing Address</h5>
                        <FontAwesomeIcon
                          className="pt-1"
                          icon={faCircleInfo}
                          size="sm"
                        />
                      </div>

                    <div className='bg-lightgrey'>
                        <div className="row p-2">
                            <div className="col-12 col-md-6">
                                <div>
                                    <p className="m-0 nc_detail_label">{`Address Line1`}</p>
                                    <p className="mb-2 roboto-bold">{addressLine1 !== "" && addressLine1 !== null ? addressLine1 : "NA"}</p>
                                </div>
                            
                                <div>
                                    <p className="m-0 nc_detail_label">{`Country Name`}</p>
                                    <p className="mb-2 roboto-bold">{country_name !== "" && country_name !== null ? country_name : "NA"}</p>
                                </div>
                                
                                <div>
                                    <p className="m-0 nc_detail_label">{`City Name`}</p>
                                    <p className="mb-2 roboto-bold">{city_name !== "" && city_name !== null ? city_name : "NA"}</p>
                                </div>
            
                            </div>
                            <div className="col-12 col-md-6">
                                <div>
                                    <p className="m-0 nc_detail_label">{`Address Line2`}</p>
                                    <p className="mb-2 roboto-bold">{addressLine2 !== "" && addressLine2 !== null ? addressLine2 : "NA"}</p>
                                </div>
                                <div>
                                    <p className="m-0 nc_detail_label">{`State Name`}</p>
                                    <p className="mb-2 roboto-bold">{state_name !== "" && state_name !== null ? state_name : "NA"}</p>
                                </div>
                                <div>
                                    <p className="m-0 nc_detail_label">{`Postal Code`}</p>
                                    <p className="mb-2 roboto-bold">{postalCode !== "" && postalCode !== null ? postalCode : "NA"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6">
                  <div className="card border-0">
                    <div className="card-body p-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="roboto-bold">Shipping Address</h5>
                        <FontAwesomeIcon
                          className="pt-1"
                          icon={faCircleInfo}
                          size="sm"
                        />
                      </div>

                    <div className='bg-lightgrey'>
                        <div className="row p-2">
                            <div className="col-12 col-md-6">
                                <div>
                                    <p className="m-0 nc_detail_label">{`Address Line1`}</p>
                                    <p className="mb-2 roboto-bold">{addressLine1_s !== "" && addressLine1_s !== null ? addressLine1_s : "NA"}</p>
                                </div>
                            
                                <div>
                                    <p className="m-0 nc_detail_label">{`Country Name`}</p>
                                    <p className="mb-2 roboto-bold">{country_name_s !== "" && country_name_s !== null ? country_name_s : "NA"}</p>
                                </div>
                                
                                <div>
                                    <p className="m-0 nc_detail_label">{`City Name`}</p>
                                    <p className="mb-2 roboto-bold">{city_name_s !== "" && city_name_s !== null ? city_name_s : "NA"}</p>
                                </div>
            
                            </div>
                            <div className="col-12 col-md-6">
                                <div>
                                    <p className="m-0 nc_detail_label">{`Address Line2`}</p>
                                    <p className="mb-2 roboto-bold">{addressLine2_s !== "" && addressLine2_s !== null ? addressLine2_s : "NA"}</p>
                                </div>
                                <div>
                                    <p className="m-0 nc_detail_label">{`State Name`}</p>
                                    <p className="mb-2 roboto-bold">{state_name_s !== "" && state_name_s !== null ? state_name_s : "NA"}</p>
                                </div>
                                <div>
                                    <p className="m-0 nc_detail_label">{`Postal Code`}</p>
                                    <p className="mb-2 roboto-bold">{postalCode_s !== "" && postalCode_s !== null ? postalCode_s : "NA"}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                        
                    </div>
                  </div>
                </div>
        </div>

    </React.Fragment>
  )
}

export default BillingShippingAddress
// MyCalendar.js
import React from 'react';
import { Calendar, dateFnsLocalizer } from 'react-big-calendar';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { format, parse, startOfWeek, getDay, addHours } from 'date-fns';
import enIN from 'date-fns/locale/en-IN';

const locales = {
  'en-IN': enIN,
};

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek,
  getDay,
  locales,
});

// Define the operations and operators
const operations = [
    { operationname: 'OP-1' },
    { operationname: 'OP-2' },
    { operationname: 'OP-3' },
  ];
  
  const operators = [
    { operatorname: 'OPR-1' },
    { operatorname: 'OPR-2' },
    { operatorname: 'OPR-3' },
  ];
  
  // Generate job card events for each operation-operator combination
  const generateEvents = () => {
    const events = [];
    let startTime = new Date();
  
    operations.forEach((operation, opIndex) => {
      operators.forEach((operator, oprIndex) => {
        const start = addHours(startTime, opIndex * 2 + oprIndex);
        const end = addHours(start, 1); // each job lasts 1 hour
        events.push({
          title: `${operation.operationname} - ${operator.operatorname}`,
          start,
          end,
        });
      });
    });
  
    return events;
  };

function MyCalendar() {
    const events = generateEvents();
  return (
    <Calendar
      localizer={localizer}
      events={events}
      startAccessor="start"
      endAccessor="end"
      style={{ height: 500 }}
      popup
    />
  );
}

export default MyCalendar;

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faBan,
  faFilter,
  faPercentage,
  faPercent,
  faCog,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal, Spinner } from "react-bootstrap";
import { Download, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Tooltip } from "react-tooltip";
import {
  get_store,
  get_store2,
  save_store,
  delete_store,
  get_store_type,
  getLocation,
  getInternalCompany,
  getCustomer,
} from "../../../api";
import {
  calMaxPage,
  getLabels,
  setAccessPrivilage,
} from "../../../common/Functions/CommonFunctions";
import EditStoreMaster from "./EditStoreMaster";
import { Store_Col_Name } from "../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import List from "../../../assets/images/icons/project_menu/checklist.png";
import AccessDenied from "../../Common/AccessDenied";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Store name is required!"),
    store_type: yup
      .array()
      .min(1, "Please select store type !")
      .required("Please select store type !"),
    location: yup
      .array()
      .min(1, "Please select location !")
      .required("Please select location !"),
    root_store: yup
      .array()
      .notRequired()
  })
  .required()
// .test('at-least-one', 'Please select root store!', function (value) {
//   const { store_type } = value;
//   return (store_type && store_type[0].name == "Rack")
// });

function StoreMaster() {
  const [showLabel, setShowLabel] = useState(Store_Col_Name);
  const [visible, setVisible] = useState(false);
  const [page, setPage] = useState(1);
  const [srNo, setSrNo] = useState(0);
  const [maxPage, setMaxPage] = useState(0);
  const [entriesPerPage, setEntriesPerPage] = useState(10);
  const [dataList, setDataList] = useState({
    data: [],
    loading: true,
    totalRecords: "",
  });
  const [storeTypes, setStoreTypes] = useState({ data: [], loading: false });
  const [locList, setLocList] = useState({ data: [], loading: false });
  const [partyList, setPartyList] = useState({ data: [], loading: false });
  const [intCompList, setIntCompList] = useState({ data: [], loading: false });
  const [storeList, setStoreList] = useState({ data: [], loading: false });
  const [parentstoreList, setParentstoreList] = useState({
    data: [],
    loading: false,
  });
  const [sea_val, setSea_val] = useState(false);
  const [filterConfig, setFilterConfig] = useState({ searchVal: [] });

  const loginData = useSelector(
    (state) => state.auth?.loginData?.data?.response
  );
  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
    no_access: false
  });

  const [constLabel, setConstLabel] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch, setValue, setError } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;

  const selected_store_type = watch('store_type');
  const selected_location = watch('location');
  const selected_internal_company = watch('internal_company');

  useEffect(() => {
    setPrivilage(setAccessPrivilage(loginData, "Store"));
  }, []);

  useEffect(() => {
    setConstLabel(getLabels("Master", "Store"));
    if (visible == true) {
      fetchStoreTypes();
      // fetchLocations();
      fetchInternalCompany();
      fetchParties();
      fetchStores();
    }
  }, [visible]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      setPage(1);
      fetchStoreList(page, entriesPerPage);
    }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)

    return () => {
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [filterConfig]);

  function clearFilter() {
    setFilterConfig({ ...filterConfig, searchVal: [] });
  }

  function fetchStoreTypes() {
    setStoreTypes({ ...storeTypes, data: [], loading: true });

    get_store_type().then(
      (res) => {
        setStoreTypes({ ...storeTypes, data: res.data.data, loading: false });
      },
      (err) => {
        setStoreTypes({ ...storeTypes, data: [], loading: false });
      }
    );
  }

  function fetchLocations(org) {
    setLocList({ ...locList, data: [], loading: true });

    const payload = { company_id: org[0].uuid };

    getLocation(payload).then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.locationname != null
        );

        setLocList({ ...locList, data: result, loading: false });
      },
      (err) => {
        setLocList({ ...locList, data: [], loading: false });
      }
    );
  }

  function fetchInternalCompany() {
    setIntCompList({ ...intCompList, data: [], loading: true });

    getInternalCompany().then(
      (res) => {
        setIntCompList({ ...intCompList, data: res.data.data, loading: false });
      },
      (err) => {
        setIntCompList({ ...intCompList, data: [], loading: false });
      }
    );
  }

  function fetchParties() {
    setPartyList({ ...partyList, data: [], loading: true });

    getCustomer().then(
      (res) => {
        const result = res.data.data.filter(
          (item) => item.organization !== "" && item.organization !== null
        );
        setPartyList({ ...partyList, data: result, loading: false });
      },
      (err) => {
        setPartyList({ ...partyList, data: [], loading: false });
      }
    );
  }

  function fetchStores(location_id) {
    setStoreList({ ...storeList, data: [], loading: true });
    setParentstoreList({ ...parentstoreList, data: [], loading: true });

    get_store2({ parent_list: true, location_id: location_id }).then(
      (res) => {
        setStoreList({ ...storeList, data: res.data.data, loading: false });

        const storesWithNoRootStore = res.data.data.filter((val) => val.root_store_id == null);
        setParentstoreList({ ...parentstoreList, data: storesWithNoRootStore, loading: false });

      },
      (err) => {
        setStoreList({ ...storeList, data: [], loading: false });
        setParentstoreList({ ...parentstoreList, data: [], loading: false });
      }
    );
  }

  function fetchStoreList(pg, epp) {
    setDataList({ ...dataList, loading: true, data: [] });

    const payload = {
      pageNo: pg,
      entriesPerPage: epp,
    };

    if (filterConfig.searchVal !== "") {
      // payload.productName_list = filterConfig.productName;
      payload.searchVal = filterConfig.searchVal;
      if (
        filterConfig.searchVal?.length > 0 &&
        page !== 1 &&
        sea_val == false
      ) {
        payload.pageNo = 1;
        payload.entriesPerPage = entriesPerPage;

        setPage(1);
        setSrNo(0);
      }
    } else {
      delete payload.searchVal;
    }

    get_store(payload).then(
      (res) => {
        setDataList({
          ...dataList,
          loading: false,
          data: res.data.data,
          totalRecords: res.data.total,
        });

        if (
          filterConfig.searchVal !== "" &&
          filterConfig.searchVal?.length > 0
        ) {
          setSea_val(true);
        } else {
          setSea_val(false);
        }

        setMaxPage(calMaxPage(res.data.total));
        if (pg == 1) {
          setSrNo(0);
          setPage(1);
        }
      },
      (err) => {
        setDataList({ ...dataList, loading: false, data: [] });
      }
    );
  }

  const onSubmit = (data) => {
    setDisableBtn(true);

    console.log("data", data);
    if (data !== "") {

      if ((data.store_type[0].name == "Rack" || data.store_type[0].name == "Shelf")
        && (!data.root_store || data.root_store?.length == 0)) {
        setError(`root_store`, {
          type: "custom",
          message: "Please select root store",
        });
        return
      }


      const reqPayload = {
        name: data.name.trim(),
        store_types: data.store_type[0]?.name,
        location_id: data.location[0]?.uuid,
        // internal_company_id: data.internal_company[0]?.uuid,
        // internal_company_name: data.internal_company[0]?.comName,
        parent_id_id: data.parent_store?.[0]?.uuid,
        // party_id: data.party[0]?.uuid,
        // party_name: data.party[0]?.organization
      };

      if (data.root_store_id && data.root_store_id.length > 0) {
        reqPayload.root_store_id = data.root_store?.[0]?.uuid;
      } else {
        reqPayload.root_store_id = null;
      }


      if (data.party !== undefined && data.party?.length > 0) {
        reqPayload.party_id = data.party?.[0]?.uuid;
        reqPayload.party_name = data.party?.[0]?.organization;
      } else {
        reqPayload.internal_company_id = data.internal_company?.[0]?.uuid;
        reqPayload.internal_company_name = data.internal_company?.[0]?.comName;
      }

      console.log("reqPayload", reqPayload);

      save_store(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Store created successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            fetchStoreList(page, entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            setDisableBtn(false);
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  // Deleting Store
  const delStore = (val) => {
    SweetAlert.fire({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
          uuid: val.uuid,
        };
        delete_store(payload).then(
          (res) => {
            toast.success("Store deleted successfully !", {
              autoClose: 3000,
            });
            if (dataList.data.length == 1 && page > 1) {
              fetchStoreList(page - 1, entriesPerPage);
            } else {
              fetchStoreList(page, entriesPerPage);
            }
          },
          (err) => {
            toast.error("Something went wrong !", { autoClose: 3000 });
          }
        );
      }
    });
  };

  function openNav() {
    document.getElementById("mySidebar").style.width = "320px";
    document.getElementById("root_div_main").style.marginLeft = "320px";
    setTimeout(() => {
      document.getElementById("mySidebar").style.overflowX = "visible";
    }, [200]);
  }

  function closeNav() {
    document.getElementById("mySidebar").style.width = "0";
    document.getElementById("root_div_main").style.marginLeft = "0";

    document.getElementById("mySidebar").style.overflowX = "hidden";
  }

  function handleClick(ind) {
    setShowLabel((showLabel) => {
      const updatedCardShow = showLabel.map((val, i) => {
        if (ind === i) {
          // Toggle the flag for the clicked checkbox
          return { ...val, flag: !val.flag };
        }
        return val;
      });

      return updatedCardShow;
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}

      {!privilage.read ?
        <AccessDenied />
        :
        <div className="min-vh-100" id="root_div_main">
          <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
            <Modal.Header
              style={{ background: "#2A3643", color: "white" }}
              closeButton
            >
              <Modal.Title>
                {constLabel?.lbl_add_store
                  ? constLabel.lbl_add_store
                  : "Add Store"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form action="">
                {/* Orgnazation */}
                <div className="form-group typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_internal_company
                      ? constLabel.lbl_internal_company
                      : "Internal Company"}
                  </label>
                  <Controller
                    name="internal_company"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        className={""}
                        id="basic-typeahead-single"
                        labelKey="comName"
                        multiple={false}
                        options={
                          intCompList.data !== undefined ? intCompList.data : []
                        }
                        isLoading={intCompList.state}
                        placeholder="Select internal company"
                        onChange={(selected) => {
                          onChange(selected);
                          if (selected && selected.length > 0) {
                            setValue("party", []);
                            setValue("location", []);
                            console.log("selected", selected);
                            if (selected.length > 0) {
                              fetchLocations(selected);
                            }
                          } else {
                            setLocList({ ...locList, data: [], loading: false });
                          }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.internal_company ? true : false}
                      />
                    )}
                  />
                  {!intCompList.loading && !errors.internal_company && <span className="arrow-icon"></span>}
                  <span className="text-danger err-msg">
                    {errors.internal_company?.message}
                  </span>
                </div>

                {/* Party */}
                <div className="form-group mt-2 typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_party ? constLabel?.lbl_party : "Party"}
                    {/* <span className="text-danger"> *</span> */}
                  </label>
                  <Controller
                    name="party"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="organization"
                        multiple={false}
                        // size="sm"
                        options={
                          partyList.data !== undefined ? partyList.data : []
                        }
                        isLoading={partyList.loading}
                        placeholder="Select party"
                        onChange={(selected) => {
                          onChange(selected);
                          if (selected && selected.length > 0) {
                            setValue("internal_company", []);
                            setValue("location", []);
                            console.log("selected", selected);
                            if (selected.length > 0) {
                              fetchLocations(selected);
                            }
                          } else {
                            setLocList({ ...locList, data: [], loading: false });
                          }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.party ? true : false}
                        disabled={!selected_internal_company}
                      />
                    )}
                  />
                  {!partyList.loading && !errors.party && <span className="arrow-icon"></span>}
                  <span className="text-danger err-msg">
                    {errors.party?.message}
                  </span>
                </div>

                {/* Location */}
                <div className="form-group mt-2 typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_location_name
                      ? constLabel.lbl_location_name
                      : "Location Name"}
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    name="location"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="locationname"
                        multiple={false}
                        options={locList.data !== undefined ? locList.data : []}
                        // options={[{name : null}, {name: "null"}]}
                        isLoading={locList.loading}
                        placeholder="Select location"
                        onChange={(selected) => {
                          onChange(selected);
                          if (selected && selected.length > 0) {
                            // let arr = storeList.data.filter(
                            //   (item) => item.location_id == selected[0].uuid
                            // );
                            // setParentstoreList({ data: arr, loading: false });
                            fetchStores(selected[0].uuid);
                            setValue("parent_store", []);
                          } else {
                            // setParentstoreList({ data: [], loading: false });
                            setValue("parent_store", []);
                          }
                        }}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.location ? true : false}
                      />
                    )}
                  />
                  {!locList.loading && !errors.location && <span className="arrow-icon"></span>}
                  <span className="text-danger err-msg">
                    {errors.location?.message}
                  </span>
                </div>

                {/* Parent Store */}
                <div className="form-group mt-2 typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_parent_store
                      ? constLabel.lbl_parent_store
                      : "Parent Store"}
                  </label>
                  <Controller
                    name="parent_store"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        options={
                          storeList.data !== undefined
                            ? storeList.data
                            : []
                        }
                        // options={[{name : null}, {name: "null"}]}
                        isLoading={storeList.loading}
                        placeholder="Select parent store"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        disabled={selected_location == null || selected_location == undefined}
                      // isInvalid={errors.parent_store ? true : false}
                      />
                    )}
                  />
                  {!storeList.loading && <span className="arrow-icon"></span>}
                  {/* <span className="text-danger err-msg">
                    {errors.parent_store?.message}
                  </span> */}
                </div>






                {/* Store Type */}
                <div className="form-group mt-2 typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_store_type
                      ? constLabel.lbl_store_type
                      : "Store Type"}
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    name="store_type"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        options={
                          storeTypes.data !== undefined ? storeTypes.data : []
                        }
                        // options={[{name : null}, {name: "null"}]}
                        isLoading={storeTypes.loading}
                        placeholder="Select store type"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.store_type ? true : false}
                      />
                    )}
                  />
                  {!storeTypes.loading && !errors.store_type && <span className="arrow-icon"></span>}
                  <span className="text-danger err-msg">
                    {errors.store_type?.message}
                  </span>
                </div>





                <div className="form-group mt-2 typeahead-container">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_root_store
                      ? constLabel.lbl_root_store
                      : "Root Store"}
                    <span className="text-danger"> *</span>
                  </label>
                  <Controller
                    name="root_store"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        options={
                          parentstoreList.data !== undefined
                            ? parentstoreList.data
                            : []
                        }
                        // options={[{name : null}, {name: "null"}]}
                        isLoading={parentstoreList.loading}
                        placeholder="Select root store"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.root_store ? true : false}
                        disabled={!selected_store_type ||
                          (selected_store_type && selected_store_type[0]?.name !== "Rack" &&
                            selected_store_type[0]?.name !== "Shelf")}
                      />
                    )}
                  />
                  {!parentstoreList.loading && !errors.root_store &&
                    <span className="arrow-icon"></span>}

                  <span className="text-danger err-msg">
                    {errors.root_store?.message}
                  </span>
                </div>




                {/* Store name */}
                <div className="form-group mt-2">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold"
                  >
                    {constLabel?.lbl_store_name
                      ? constLabel.lbl_store_name
                      : "Store Name"}
                    <span className="text-danger"> *</span>
                  </label>
                  <input
                    type="text"
                    className={
                      errors.name ? "form-control  is-invalid" : "form-control "
                    }
                    id="name"
                    name="name"
                    placeholder="Enter store name"
                    {...register("name")}
                  />
                  <span className="text-danger err-msg">
                    {errors.name?.message}
                  </span>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer>
              <button
                disable={disableBtn}
                onClick={handleSubmit(onSubmit)}
                className="f-16 btn btn-yellow "
              >
                {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
              </button>
            </Modal.Footer>
          </Modal>

          {privilage.read && (
            <div id="mySidebar" className="customsidebar ">
              <a className="closebtn" onClick={() => closeNav()}>
                ×
              </a>

              <div className=" content">
                <div>
                  <label
                    className="filterLabel mb-1 roboto-bold"
                    style={{ fontSize: "14px" }}
                  >
                    {constLabel?.lbl_search ? constLabel.lbl_search : "Search"}
                  </label>
                  <input
                    className="form-control form-control-sm"
                    type="text"
                    placeholder="Search by name"
                    value={filterConfig.searchVal}
                    onChange={(val) => {
                      const inputValue = val.target.value;
                      if (!inputValue.trim()) {
                        // Input contains only whitespace, don't trigger the search.
                        if (filterConfig.searchVal.length == 1) {
                          setFilterConfig({ ...filterConfig, searchVal: "" });
                        }

                        if (inputValue.length == 0) {
                          setFilterConfig({ ...filterConfig, searchVal: "" });
                        }
                      } else {
                        setFilterConfig({
                          ...filterConfig,
                          searchVal: inputValue,
                        });
                      }
                    }}
                  />
                </div>
                <div className="my-3 d-flex justify-content-end">
                  <button
                    onClick={() => clearFilter()}
                    className="btn btn-sm btn-yellow "
                  >
                    <FontAwesomeIcon
                      style={{ color: "#344454" }}
                      icon={faBan}
                      size="sm"
                    />{" "}
                    {constLabel?.lbl_clear_filter
                      ? constLabel.lbl_clear_filter
                      : "Clear Filter"}
                  </button>
                </div>
              </div>
            </div>
          )}

          <div className="content-wrapper">
            <div className="card border-0">
              <div className="card-body">
                <div className="row align-items-center">
                  <div className="d-md-flex justify-content-start align-items-center col-12 col-md-6">
                    {/* <FontAwesomeIcon
                      onClick={() => toggleNav()}
                      className="cursor_pointer mb-2"
                      style={{
                        color: "Black",
                        marginRight: "10px",
                      }}
                      icon={faBars}
                      size="lg"
                    /> */}
                    <h1 className="bold">
                      {constLabel?.lbl_store ? constLabel.lbl_store : "Store"}
                    </h1>
                  </div>
                  <div className="d-md-flex justify-content-end align-items-center col-12 col-md-6">
                    <div className="col-12 col-md-6 me-md-2 me-md-0">
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Search by name"
                        value={filterConfig.searchVal}
                        onChange={(val) => {
                          const inputValue = val.target.value;
                          if (!inputValue.trim()) {
                            // Input contains only whitespace, don't trigger the search.
                            if (filterConfig.searchVal.length == 1) {
                              setFilterConfig({ ...filterConfig, searchVal: "" });
                            }

                            if (inputValue.length == 0) {
                              setFilterConfig({ ...filterConfig, searchVal: "" });
                            }
                          } else {
                            setFilterConfig({
                              ...filterConfig,
                              searchVal: inputValue,
                            });
                          }
                        }}
                      />
                    </div>
                    {privilage.write && (
                      <button
                        className="btn button-primary me-0 me-md-2 mt-2 mt-md-0"
                        onClick={() => {
                          setVisible(true);
                          reset();
                        }}
                      >
                        <FontAwesomeIcon
                          style={{ color: "#fff" }}
                          icon={faPlus}
                          size="sm"
                        />{" "}
                        {constLabel?.lbl_add_store
                          ? constLabel.lbl_add_store
                          : "Add Store"}
                      </button>
                    )}

                    <Tooltip id={"show-tooltip"} place="top" />
                    <div
                      className="dropdown"
                      data-tooltip-id={"show-tooltip"}
                      data-tooltip-content={"Show / Hide Columns"}
                    >
                      <div
                        className="d-md-flex justify-content-start align-items-center"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {/* <FontAwesomeIcon icon={faCog} size="lg" /> */}
                        {/* <List size={24} className="menu_icon_grey cursor_pointer"/> */}
                        <img
                          src={List}
                          height="32"
                          width="32"
                          className="cursor_pointer"
                        />
                      </div>

                      <ul className="dropdown-menu px-2">
                        {showLabel !== undefined && showLabel.length > 0
                          ? showLabel.map((val, ind, arr) => (
                            <li key={ind}>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  style={{ width: "20px", height: "20px" }}
                                  onChange={() => handleClick(ind)}
                                  value=""
                                  checked={val.flag}
                                />
                                <label
                                  className=""
                                  style={{ fontSize: "13px" }}
                                  htmlFor="flexCheckDefault"
                                >
                                  {val.label}
                                </label>
                              </div>
                            </li>
                          ))
                          : null}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  {dataList.loading ? (
                    <div className="text-center">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : dataList && dataList.data?.length > 0 ? (
                    <div className="table-responsive">
                      <table className="table table-bordered">
                        <thead className="table-grey roboto">
                          <tr className="">
                            <th scope="col" width="10%">
                              {"Sr.No"}
                            </th>
                            {showLabel.map((v, i) =>
                              v.label === "Store Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_store_name
                                    ? constLabel.lbl_store_name
                                    : "Store Name"}
                                </th>
                              ) : v.label === "Store Type" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_store_type
                                    ? constLabel.lbl_store_type
                                    : "Store Type"}
                                </th>
                              ) : v.label === "Internal Company" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_internal_company
                                    ? constLabel?.lbl_internal_company
                                    : "Internal Company"}
                                </th>
                              ) : v.label === "Party Name" && v.flag ? (
                                <th key={i} scope="col">
                                  {constLabel?.lbl_party_name
                                    ? constLabel.lbl_party_name
                                    : "Party Name"}
                                </th>
                              ) : null
                            )}
                            {/* <th scope="col">{"Party"}</th> */}
                            <th scope="col" width="10%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="roboto">
                          {dataList.data?.map((val, i) => (
                            <tr key={i} className="">
                              {/* <td>{val.NCReasonId}</td> */}
                              <td>{i + parseInt(srNo) + 1}</td>
                              {showLabel.map((item, idx) =>
                                item.label === "Store Name" && item.flag ? (
                                  <td key={idx}>
                                    {val.name !== "" && val.name !== null
                                      ? val.name
                                      : "NA"}
                                  </td>
                                ) : item.label === "Store Type" && item.flag ? (
                                  <td key={idx}>
                                    {val.store_types !== "" &&
                                      val.store_types !== null
                                      ? val.store_types
                                      : "NA"}
                                  </td>
                                ) : item.label === "Internal Company" &&
                                  item.flag ? (
                                  <td key={idx}>
                                    {val.internal_company_name !== "" &&
                                      val.internal_company_name !== null
                                      ? val.internal_company_name
                                      : "NA"}
                                  </td>
                                ) : item.label === "Party Name" && item.flag ? (
                                  <td key={idx}>
                                    {val.party_name !== "" &&
                                      val.party_name !== null
                                      ? val.party_name
                                      : "NA"}
                                  </td>
                                ) : null
                              )}
                              {/* <td>{val.party_name}</td> */}
                              <td>
                                {privilage.write && (
                                  <EditStoreMaster
                                    data={val}
                                    constLabel={constLabel}
                                    page={page}
                                    entriesPerPage={entriesPerPage}
                                    fetchStoreList={fetchStoreList}
                                  />
                                )}

                                {privilage.delete && (
                                  <>
                                    <Tooltip id={"delete-tooltip" + i} place="top" />
                                    <Trash2
                                      data-tooltip-id={"delete-tooltip" + i}
                                      data-tooltip-content={
                                        constLabel?.lbl_delete_store
                                          ? constLabel.lbl_delete_store
                                          : "Delete Store"
                                      }
                                      className="menu_icon_red cursor_pointer"
                                      size={20}
                                      onClick={() => delStore(val)}
                                    />
                                  </>
                                )}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"Store list is empty"}
                    </p>
                  )}

                  {dataList.loading ? null : (
                    <div className="d-flex justify-content-between align-items-center mt-2">
                      <p className="mb-0">
                        Show
                        <select
                          className="mx-1"
                          defaultValue={entriesPerPage}
                          onChange={(e) => {
                            setEntriesPerPage(e.target.value);
                            fetchStoreList(1, e.target.value);
                          }}
                        >
                          <option value={"10"}>10</option>
                          <option value="25">25</option>
                          <option value="50">50</option>
                          <option value="100">100</option>
                        </select>
                        Entries
                      </p>
                      <p className="mb-0">{`Showing ${parseInt(srNo) + 1} to ${dataList.data.length +
                        entriesPerPage * page -
                        entriesPerPage
                        } of ${dataList.totalRecords} entries`}</p>
                      <Pagination className="my-2">
                        <Pagination.Prev
                          disabled={page === 1 ? true : false}
                          onClick={() => {
                            fetchStoreList(
                              page - 1,
                              entriesPerPage,
                              parseInt(srNo) - parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) =>
                              page - 1 == 1 ? 0 : prevC - parseInt(entriesPerPage)
                            );
                            setPage(page - 1);
                          }}
                        >
                          {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                          disabled={
                            page === maxPage ||
                              maxPage === 0 ||
                              entriesPerPage > dataList.data.length
                              ? true
                              : false
                          }
                          onClick={() => {
                            fetchStoreList(
                              page + 1,
                              entriesPerPage,
                              parseInt(srNo) + parseInt(entriesPerPage)
                            );
                            setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                            setPage(page + 1);
                          }}
                        >
                          {"Next"}
                        </Pagination.Next>
                      </Pagination>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    </React.Fragment>
  );
}

export default StoreMaster;

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import {
  getProdCategory,
  getCustomer,
  getMarket_Group,
  getProduct_Family,
  getUOM,
  saveProduct,
  getBOM,
} from "../../../../api";

const schema = yup
  .object()
  .shape({
    productName: yup.string().required("Product name is required !"),
    hsn: yup
      .string()
      .matches(/^[a-zA-Z0-9]*$/, "Enter valid hsn")
      .nullable(true),
    unit: yup
      .array()
      .min(1, "Please select unit")
      .required("Unit is required !"),
    openingStock: yup
      .string()
      .matches(/^[0-9]+$/, 'Enter valid opening stock')
      .nullable(true),
    machineCost: yup
      .string()
      .matches(/^\d+(\.\d+)?$/, "Enter valid cost ")
      .nullable(true),
    salesPrice: yup
      .string()
      .matches(/^\d+(\.\d+)?$/, "Enter valid sales price ")
      .nullable(true),
    selling_price: yup
      .string()
      .required("Selling price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid selling price"),
    purchase_price: yup
      .string()
      .required("Purchase price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid purchase price"),
    can_be_sold: yup
      .string()
      .required("Required"),
    can_be_purchased: yup
      .string()
      .required("Required"),
    can_be_expensed: yup
      .string()
      .required("Required"),
    // has_bom: yup
    //   .string()
    //   .required("Required"),
  })
  .required();

function SemiFinishedGoods(props) {
  let navigate = useNavigate();

  const [prodCategory, setProdCategory] = useState({
    data: [],
    loading: false,
  });
  const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
  const [partyList, setPartyList] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [bomList, setBomList] = useState({ data: [], loading: false });
  const [createBom, setCreateBom] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);

  localStorage.setItem('bomStatus', createBom);

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    fetchProductCategory();
    fetchProductFamily();
    fetchParty();
    fetchUOM();
    fetchBoms();
  }, []);

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM({pageNo:1,entriesPerPage:100}).then(
      (res) => {
        
        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchBoms() {
    setBomList({ ...bomList, data: [], loading: true });

    getBOM().then(
      (res) => {
        
        setBomList({ ...bomList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setBomList({ ...bomList, data: [], loading: false });
      }
    );
  }

  function fetchProductCategory() {
    setProdCategory({ ...prodCategory, data: [], loading: true });
    getProdCategory().then(
      (res) => {
        
        setProdCategory({
          ...prodCategory,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setProdCategory({ ...prodCategory, data: [], loading: false });
      }
    );
  }

  function fetchProductFamily() {
    setProdFamily({ ...prodFamily, data: [], loading: true });
    getProduct_Family().then(
      (res) => {
        
        setProdFamily({
          ...prodFamily,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setProdFamily({ ...prodFamily, data: [], loading: false });
      }
    );
  }

  function fetchParty() {
    setPartyList({ ...partyList, data: [], loading: true });
    getCustomer({partyType: "Customer"}).then(
      (res) => {
        
        const result = res.data.data.filter((item) => item.name !== null);
        setPartyList({ ...partyList, data: result, loading: false });
      },
      (err) => {
        
        setPartyList({ ...partyList, data: [], loading: false });
      }
    );
  }

  // const onSubmit = (data) => {
  //   props.setCurrentStep(props.currentStep + 1);
  // };

  const onSubmit = (data) => {
    
    setBtnDisable(true);

    if (data !== "") {
      const reqPayload = {
        productType: props.product_type?.prodTypeId,
        productTypename: props.product_type?.productType,
        product_type_id: props.product_type?.uuid,
        category: data.category ? data.category[0]?.categoryname : "",
        prodfamily: data.productFamily ? data.productFamily[0]?.familyId : "",
        prod_family_id: data.productFamily ? data.productFamily[0]?.uuid : "",
        productName: data.productName,
        openingstock: data.openingStock,
        hsn: data.hsn,
        // has_bom: createBom,
        machineCost: data.machineCost,
        salesPrice: data.salesPrice,
        can_be_sold: data.can_be_sold,
        can_be_purchased: data.can_be_purchased,
        can_be_expensed: data.can_be_expensed,
        beacon_id: data.beacon_id,
        qr_code_id: data.qr_code_id,
        selling_price: data.selling_price,
        purchase_price: data.purchase_price,
        unit_id: data.unit ? data.unit[0]?.uuid : "",
        remark: data.remark,
        party: [],
      };

      

      saveProduct(reqPayload)
        .then(
          (res) => {
            setBtnDisable(false);
            
            if (res.status === 200 || res.status === 201) {
              toast.success("Semi finish goods product saved successfully !", {
                autoClose: 3000,
              });

              localStorage.setItem(
                "prod_res",
                JSON.stringify(res.data.product)
              );
              props.setProductDataLocal(res.data.product);
              props.setCurrentStep((prevStep) => prevStep + 1);
              if (createBom == true) {
                props.setBomStat(true);
              }
            }
          },
          (err) => {
            setBtnDisable(false);
            
            if (err.response.status === 400) {
              
              toast.error(err.response.data.non_field_errors[0], {
                autoClose: 2000,
              });
            }
          }
        )
        .catch((error) => {
          
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <p className="pc_card_title roboto-bold">Product Summary</p>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{"Category"}</label>
                <Controller
                  name="category"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="categoryname"
                      className="mt-2"
                      multiple={false}
                      options={prodCategory.data}
                      isLoading={prodCategory.loading}
                      placeholder="Select product category"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {"Product Family"}
                </label>
                <Controller
                  name="productFamily"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="familyname"
                      className="mt-2"
                      multiple={false}
                      options={prodFamily.data}
                      isLoading={prodFamily.loading}
                      placeholder="Select product family"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {"Product Name"}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.productName
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter product name"
                  name="productName"
                  {...register("productName")}
                />
                {errors.productName && (
                  <span className="text-danger err-msg">
                    {errors.productName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {"Opening Stock"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter opening stock"
                  defaultValue={0}
                  name="openingStock"
                  {...register("openingStock")}
                />
                {errors.openingStock && (
                  <span className="text-danger err-msg">
                    {errors.openingStock.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{"HSN"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter hsn"
                  name="hsn"
                  {...register("hsn")}
                />
                {errors.hsn && (
                  <span className="text-danger err-msg">
                    {errors.hsn.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {"Unit"}
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name="unit"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="uom"
                      className="mt-2"
                      multiple={false}
                      options={uomList.data}
                      isLoading={uomList.loading}
                      placeholder="Select unit"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.unit ? true : false}
                    />
                  )}
                />

                {errors.unit && (
                  <span className="text-danger err-msg">
                    {errors.unit.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <label className="lbl-style roboto-bold invisible">
                {"Unit"}
                <span className="text-danger">*</span>
              </label>
              <div className="form-check mt-4">
                <input
                  className="form-check-input"
                  type="checkbox"

                  name="has_bom"
                  onChange={(e) => setCreateBom(e.target.checked)}
                  checked={createBom}
                // {...register("has_bom")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  Create BOM
                </label>
              </div>
            </div>

            {watch("has_bom") ? (
              <div className="col-md-3 mt-3">
                <div className="form-group mt-md-0 mt-3">
                  <label className="lbl-style roboto-bold">
                    {"Bill of Material"}
                    <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="bom"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="productname"
                        className="mt-2"
                        multiple={false}
                        options={bomList.data}
                        isLoading={bomList.loading}
                        placeholder="Select bom"
                        onChange={onChange}
                        selected={value}
                        onBlur={onBlur}
                      />
                    )}
                  />
                </div>
              </div>
            ) : null}
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">Product Details</p>
          <div className="row col-12">
            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {"Can be sold"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={false}
                  name="can_be_sold"
                  {...register("can_be_sold")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  Can be Sold
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {"Can be purchased"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={false}
                  name="can_be_purchased"
                  {...register("can_be_purchased")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  Can be Purchase
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {"Can be expensed"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={false}
                  name="can_be_expensed"
                  {...register("can_be_expensed")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  Can be Expense
                </label>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{"Beacon Id"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter BeaconId"
                  name="beacon_id"
                  {...register("beacon_id")}
                />
                {errors.beacon_id && (
                  <span className="text-danger err-msg">
                    {errors.beacon_id.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{"QR Code Id"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter QRCodeId"
                  name="qr_code_id"
                  {...register("qr_code_id")}
                />
                {errors.qr_code_id && (
                  <span className="text-danger err-msg">
                    {errors.qr_code_id.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">Product Price</p>
          <div className="row col-12">
            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {"Cost"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.productionCost
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter cost"
                  name="machineCost"
                  defaultValue={0}
                  {...register("machineCost")}
                />
                {errors.machineCost && (
                  <span className="text-danger err-msg">
                    {errors.machineCost.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {"Sales Price"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.salesPrice
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter sales price"
                  name="salesPrice"
                  defaultValue={0}
                  {...register("salesPrice")}
                />
                {errors.salesPrice && (
                  <span className="text-danger err-msg">
                    {errors.salesPrice.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {"Selling Price"}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.selling_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter selling price"
                  name="selling_price"
                  defaultValue={0}
                  {...register("selling_price")}
                />
                {errors.selling_price && (
                  <span className="text-danger err-msg">
                    {errors.selling_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {"Purchase Price"}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.purchase_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter purchase price"
                  name="purchase_price"
                  defaultValue={0}
                  {...register("purchase_price")}
                />
                {errors.purchase_price && (
                  <span className="text-danger err-msg">
                    {errors.purchase_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-12 col-md-6 mt-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">{"Remark"}</label>
                <textarea
                  className="form-control mt-2"
                  id="remark"
                  type="text"
                  placeholder="Enter remark"
                  name="remark"
                  {...register("remark")}
                ></textarea>
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <div className="d-flex justify-content-end">
            <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
              disabled={btnDisable}
            // onClick={() => props.setCurrentStep(props.currentStep + 1)}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SemiFinishedGoods;

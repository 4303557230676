import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SweetAlert from "sweetalert2";
import * as yup from "yup";
import {
    faClose,
} from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { ToastContainer, toast } from "react-toastify";
// import { useNavigate } from "react-router-dom";
// import { Tooltip } from "react-tooltip";
import {
    get_client_campaign, create_client_user_campaign,
    get_client_user_campaign, update_client_user_campaign, delete_client_user_campaign
} from "../../../api/index";
import { Edit2,  } from "react-feather";

const schema = yup
    .object()
    .shape({
        campaign: yup
            .array()
            .min(1, "Please select role")
            .required("Please select role"),
        channel: yup
            .string()
            .required("Channel is required"),
    })
    .required();

function LinkCampaign({ visible3, setVisible3, constLabel, selItem }) {
    const localData = JSON.parse(localStorage.getItem('localData'));
    const [camList, setCamList] = useState({ data: [], loading: true });
    const [linkedCamList, setLinkedCamList] = useState({ data: [], loading: true });
    const [action, setAction] = useState({ act: "create", data: {} });

    const { register, handleSubmit, formState, control, watch, setValue } =
        useForm({
            resolver: yupResolver(schema),
            mode: "onChange",
            defaultValues: {
                userType: "Employee",
            },
        }); // initialise the hook

    let { errors } = formState;

    const wcampaign = watch("campaign");

    useEffect(() => {
        if (wcampaign !== undefined && wcampaign.length > 0) {

            setValue("channel", wcampaign[0].channels.channel_domain_url);
        } else {
            setValue("channel", "");
        }
    }, [wcampaign]);



    useEffect(() => {
        if (visible3 == true) {
            fetchUserCam();

            get_client_campaign({ client_id: localData.client_id }).then((res) => {

                if (res.data.data.length > 0) {
                    setCamList({
                        ...camList, loading: false, data: res.data.data.map((val) => {
                            val.campaign_name = val.cammp.campaign_name;
                            return val
                        })
                    });
                }
            }, (err) => {
                setCamList({
                    ...camList, loading: false, data: []
                });

            });
        }
    }, [visible3]);


    function fetchUserCam() {
        get_client_user_campaign({ user_id: selItem.user_id }).then((res) => {

            setLinkedCamList({ ...linkedCamList, data: res.data.data, loading: false });

        }, (err) => {


            setLinkedCamList({ ...linkedCamList, data: [], loading: false });
        });

    }

    const onSubmit = (data) => {




        if (action.act == "create") {

            const payload = {
                "campaign_id": data.campaign[0].campaign_id,
                "user_id": selItem.user_id,
                "campaign_client_id": data.campaign[0].uuid

            }

            create_client_user_campaign(payload).then((res) => {

                toast.success("campaign linked with user successfully !", {
                    autoClose: 3000,
                });

                setVisible3(false);
            }, (err) => {

                toast.error(err.response.data.message, {
                    autoClose: 3000,
                });
            });
        } else {
            const payload = {
                "campaign_id": data.campaign[0].campaign_id,
                "user_id": selItem.user_id,
                "campaign_client_id": data.campaign[0].campaign_client_id == undefined ?
                    data.campaign[0].uuid : data.campaign[0].campaign_client_id,
            }

            update_client_user_campaign(payload, { uuid: action.data.uuid }).then((res) => {

                toast.success("campaign linked with user successfully !", {
                    autoClose: 3000,
                });

                setVisible3(false);
            }, (err) => {

                toast.error(err.response.data.message, {
                    autoClose: 3000,
                });
            });

        }
    }

    function delCamp(val) {
        SweetAlert.fire({
            title: "Are you sure to delete \n" + val.campaign.campaign_name + " ?",
            // text: "Once deleted, you will not be able to recover this record !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "cancel",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                delete_client_user_campaign({ uuid: val.uuid }).then(
                    (res) => {

                        toast.success("Campaign deleted successfully", {
                            autoClose: 2000,
                        });
                        fetchUserCam();
                        setAction({ act: "create", data: {} });
                        setValue("campaign", []);
                        setValue("channel", "");
                    },
                    (err) => {

                        toast.error("Something went wrong !", {
                            autoClose: 2000,
                        });
                    }
                );
            }
        });
    }

    return (
        <React.Fragment>
            < ToastContainer />

            <Modal
                show={visible3}
                onHide={() => setVisible3(false)}
                size="lg"
                backdrop="static"
            >
                <Modal.Header
                    style={{ background: "#2A3643", color: "white" }}
                    closeButton
                >
                    <Modal.Title>{constLabel?.lbl_create_user ? constLabel?.lbl_create_user : "Link Campaign"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="" autoComplete="off">
                        <div className="row mt-2">
                            <div className="col-12 col-md-6">
                                <div className="form-group row">

                                    <label
                                        style={{ fontSize: "14px" }}
                                        className="form-label roboto-bold"
                                    >
                                        {constLabel?.lbl_campaign ? constLabel.lbl_campaign : "Campaign"}
                                    </label>

                                    <div className="">
                                        <Controller
                                            name={`campaign`}
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="campaign_name"
                                                    multiple={false}
                                                    options={camList.data}
                                                    isLoading={camList.loading}
                                                    placeholder="Select Campaign"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    isInvalid={errors.campaign ? true : false}
                                                />
                                            )}
                                        />
                                        {errors.campaign && (
                                            <span className="text-danger err-msg">
                                                {errors.campaign.message}
                                            </span>
                                        )}
                                    </div>

                                </div>
                            </div>


                            <div className="col-12 col-md-6">
                                <div className="form-group row">

                                    <label
                                        style={{ fontSize: "14px" }}
                                        className="form-label roboto-bold"
                                    >
                                        {constLabel?.lbl_campaign ? constLabel.lbl_campaign : "Channel"}
                                    </label>

                                    <div className="">
                                        <input disabled className="form-control" type="text" {...register("channel")} placeholder="Channel" />
                                    </div>
                                </div>
                            </div>


                        </div>
                    </form>

                    {linkedCamList.loading ?
                        <div className="text-center  mt-2">
                            <div className="spinner-border" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div> :
                        <div className="link-camp-pill-container mt-1">
                            <div className="row pt-3" style={{ maxHeight: '300px', overflowY: "auto" }}>


                                {linkedCamList.data.length > 0 ?
                                    linkedCamList.data.map((val, ind) => (


                                        <div className=" col-12 col-md-3 mb-2" key={ind}>
                                            <div className="pill card" key={ind} style={{ borderRadius: "20px" }}>
                                                <div className="edit_icon_div"
                                                    onClick={() => {
                                                        setAction({ act: "update", data: val });


                                                        const temp = val.campaign;
                                                        temp.campaign_id = val.campaign_id;
                                                        temp.campaign_client_id = val.campaign_client_id;
                                                        temp.channels = val.client.channels;


                                                        setValue("campaign", [temp])
                                                    }}>
                                                    <Edit2 color="#FFFFFF" size={14} />
                                                </div>
                                                <div className="icon_div">
                                                    <FontAwesomeIcon
                                                        onClick={() => {
                                                            delCamp(val);

                                                        }}
                                                        className='icon-close' style={{ color: "#fff" }} icon={faClose} size="sm" />
                                                </div>

                                                <div>
                                                    <span className="text-muted">Campaign</span>
                                                    <p className="mb-0">{`${val.campaign.campaign_name}`}</p>
                                                </div>
                                                <div className="mt-1">
                                                    <span className="text-muted">Channel</span>
                                                    <p className="mb-0">{`${val.client?.channels?.channel_domain_url}`}</p>
                                                </div>
                                            </div>
                                        </div>


                                    ))

                                    :


                                    <div
                                        className="alert alert-danger mb-0 text-center px-2"
                                        role="alert"
                                    >
                                        No Campaigns Found
                                    </div>
                                }



                            </div>
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <button
                        className="btn btn-yellow px-4"
                        onClick={handleSubmit(onSubmit)}
                    >
                        {constLabel?.lbl_save ? constLabel?.lbl_save : "Save"}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment >
    )
}

export default LinkCampaign
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { createRoutesApi } from "../../../api/index";
import { useLocation, useNavigate } from "react-router-dom";
import { getLabels } from "../../../common/Functions/CommonFunctions";
import { Modal, Spinner } from "react-bootstrap";
import { Tooltip } from "react-tooltip";

const schema = yup
  .object()
  .shape({
    routesType: yup
        .string()
        .required("Routes type is required !")
        .matches(/^[a-zA-Z ]*$/, "Enter valid routes type"),
  })
  .required();

function AddRoute(props) {
  let location = useLocation();
  let navigate = useNavigate();
  
  const [visible, setVisible] = useState(false);
  const [constLabel, setConstLabel] = useState({});
  
  const { register, handleSubmit, formState, reset } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    setConstLabel(getLabels("Master", "Route"));
  }, []);


  const onSubmit = (data) => {
    if (data !== "") {
      const payload = {
        routesType: data.routesType,
      };

      createRoutesApi(payload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Route created successfully !", {
              autoClose: 2000,
            });
           
            setTimeout(() => {
              props.fetchRoutes();
              setVisible(false);
            }, 2200);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 3000,
            });
          }
        }
      );


    } else {
      errors.showMessages();
    }
  };

  return (
   <React.Fragment>
    <Tooltip id={"add-tooltip"} place="top" />
      <div
        data-tooltip-id={"add-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_add_route
            ? props.constLabel?.lbl_add_route
            : "Add Route"
        }
        className="cursor_pointer me-2"
       
    ><a
        className="px-2 mt-2 cursor_pointer"
        onClick={(e) => {
            // setUpdateForm(val)
            e.preventDefault();
            setVisible(true);
            reset();
        }}
    >
        {constLabel?.lbl_add_route ? constLabel.lbl_add_route : "Add Route"}
    </a>
    </div>
    <Modal show={visible} onHide={() => setVisible(false)} size={"sm"}>
    <Modal.Header
      style={{ background: "#2A3643", color: "white" }}
      closeButton
    >
      <Modal.Title>{constLabel?.lbl_add_route ? constLabel.lbl_add_route : "Add Route"}</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className="row">
        <div className="col-12 col-md-12">
          <div className="form-group mt-md-0 mt-3">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_routes_type
                ? constLabel?.lbl_routes_type
                : "Routes Type"}
              <span className="text-danger"> * </span>
            </label>
            <input
              type="text"
              className={
                errors.routesType
                  ? "form-control is-invalid"
                  : "form-control"
              }
              id="routesType"
              name="routesType"
              placeholder="Enter routes type"
              {...register("routesType")}
            />
            <span className="text-danger err-msg">
              {errors.routesType?.message}
            </span>
          </div>
        </div>
      </div>
      <hr className="my-3" />
      <div className="row mt-0 ">
        <div className="d-md-flex justify-content-end">
          <button
            className="btn button-primary px-4 me-2"
            onClick={handleSubmit(onSubmit)}
          >
            {constLabel?.lbl_save
              ? constLabel?.lbl_save
              : "Save"}
          </button>
        </div>
      </div>
    </Modal.Body>
  </Modal>
  </React.Fragment>
  );
}

export default AddRoute;

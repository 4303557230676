import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { Card, Tabs, Tab, Nav, Navbar } from "react-bootstrap";
import logo from "../../assets/images/TechniciousInital.png";
import { useDispatch, useSelector } from "react-redux";
import routes from "../../Routes/routes";
import Group from "../../assets/images/icons/project_menu/group.png";
import Back from "../../assets/images/icons/project_menu/back-button.png";
import Check from "../../assets/images/icons/project_menu/check.png";
import Bag from "../../assets/images/icons/project_menu/brifcase.png";
import Sales from "../../assets/images/icons/project_menu/invoice.png";
import Box from "../../assets/images/icons/project_menu/carton-box.png";
import Party from "../../assets/images/icons/project_menu/supplier.png";
import Party_Type from "../../assets/images/icons/project_menu/supplier-type.png";
import Party360 from "../../assets/images/icons/project_menu/supplier360.png";
import PO from "../../assets/images/icons/project_menu/list.png";
import PI from "../../assets/images/icons/project_menu/paper.png";
import Machine from "../../assets/images/icons/project_menu/robotic-arm.png";
import MachineCat from "../../assets/images/icons/project_menu/settings.png";
import Operation from "../../assets/images/icons/project_menu/setting.png";
import OperationCat from "../../assets/images/icons/project_menu/gears.png";
import Loc from "../../assets/images/icons/project_menu/location.png";
import Locations from "../../assets/images/icons/project_menu/locations.png";
import Store from "../../assets/images/icons/project_menu/store.png";
import Stores from "../../assets/images/icons/project_menu/shop.png";
import Employee from "../../assets/images/icons/project_menu/man.png";
import Corrective from "../../assets/images/icons/project_menu/checked.png";
import Inspection from "../../assets/images/icons/project_menu/compliance.png";
import Insp_Master from "../../assets/images/icons/project_menu/search.png";
import Tool_Type from "../../assets/images/icons/project_menu/tool-type.png";
import HSN_Code from "../../assets/images/icons/project_menu/hsn_code.png";
import Routine from "../../assets/images/icons/project_menu/routine.png";

function MastersMenu() {
  let navigate = useNavigate();
  let url = useLocation();
  const login_data = useSelector((state) => state.auth);
  const [tiles, setTiles] = useState([]);

  useEffect(() => {
    const res = login_data.loginData.data.response.module_list.filter(
      (val) => val.module.toLowerCase() == "master"
    );

    // console.log("res", res);
    setTiles(res[0].screens);

    // const result = res[0].screens.map(item1 => {
    //   const matchingItem = routes.find(item2 => item2.screenForMaster == item1.screen_name);
    //   return {
    //     ...item1,
    //     route: matchingItem ? matchingItem.path : ''
    //   };
    // });

    // setTiles(result);
  }, []);

  function findRoute(scr_name) {
    const resRoute = routes.filter((rout) => rout.name == scr_name);
    if (resRoute.length > 0) {
      navigate(`${process.env.PUBLIC_URL}${resRoute[0].path}`);
    }
  }
  // This component renders only for client admin or admin
  // For other users generic grid component is used

  const [activeTab, setActiveTab] = useState("party");

  const tabs = [
    { eventKey: "party", label: "Party" },
    // { eventKey: "sales", label: "Sales" },
    // { eventKey: "purchase", label: "Purchase" },
    { eventKey: "organization", label: "Organization" },
    { eventKey: "quality", label: "Quality" },
    { eventKey: "inventory", label: "Inventory" },
    { eventKey: "production", label: "Production" },
    { eventKey: "miscellaneous", label: "Miscellaneous" },
  ];

  const renderTabContent = () => {
    switch (activeTab) {
      case "party":
        return  <div className="d-md-flex flex-md-wrap justify-content-start">
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/customer/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Party}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Customer"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/customer360/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Party360}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Customer 360"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/customerType/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Party_Type}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Customer Type"}</p>
            {/* <div className="card-body text-center">
              <h4 className="bold">Customer Type</h4>
            </div> */}
          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/supplier/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Party}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Supplier"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/supplier360/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Party360}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Supplier 360"}</p>
            {/* <div className="card-body text-center">
              <h4 className="bold">Customer Type</h4>
            </div> */}
          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/supplierType/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Party_Type}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Supplier Type"}</p>

          </div>
        </div>
      </div>;
      // case "sales":
      //   return  <div className="d-md-flex flex-md-wrap justify-content-start">
        
       
      // </div>;
      // case "purchase":
      //   return <div className="d-md-flex flex-md-wrap justify-content-start">
        
       
      // </div>;
      case "organization":
        return <div className="d-md-flex flex-md-wrap justify-content-start">
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/machineAsset/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Machine}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Machine"}</p>
            {/* <div className="card-body text-center">
              <h4 className="bold">Customer Type</h4>
            </div> */}
          </div>
        </div> 
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/machineCategory/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={MachineCat}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Machine Category"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/operation/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Operation}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Operation"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/operationCategory/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={OperationCat}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Operation Category"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/companyType/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Bag}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Company Type"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/orgnization/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Organization"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/orgnisationType/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Organization Type"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/location/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Loc}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Location"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/locationType/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Locations}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Location Type"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/storeMaster/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Store}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Store"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/storeType/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Stores}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Store Type"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/employee_list/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Employee}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Employee Master"}</p>

          </div>
        </div>
      </div>;
      case "quality":
        return <div className="d-md-flex flex-md-wrap justify-content-start">
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/inspectionType/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Inspection}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Inspection Type"}</p>
            {/* <div className="card-body text-center">
              <h4 className="bold">Customer Type</h4>
            </div> */}
          </div>
        </div> 
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            // navigate('/pcms/machineCategory/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Insp_Master}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Inspection Outcome Master"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/corrective_action/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Corrective}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Corrective Action"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/ncStatus/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Disposal Action"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/ncReason/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"NC Reason"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/preventive_action/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Preventive Action"}</p>

          </div>
        </div>
      </div>;
      case "inventory":
        return <div className="d-md-flex flex-md-wrap justify-content-start">
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/product/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Product"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/product-category/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Product Category"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/product-family/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Product Family"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/challanType/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Challan Type"}</p>
            {/* <div className="card-body text-center">
              <h4 className="bold">Customer Type</h4>
            </div> */}
          </div>
        </div> 
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/bill-of-material/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Bill Of Material"}</p>
            {/* <div className="card-body text-center">
              <h4 className="bold">Customer Type</h4>
            </div> */}
          </div>
        </div> 
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/hsn-master/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={HSN_Code}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"HSN Master"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/tool-grade/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Tool Grade"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/tool-sub-type/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Tool Sub-Type"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/tool-type/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Tool_Type}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Tool Type"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/adjutment-reason/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Adjustment Reason"}</p>
            {/* <div className="card-body text-center">
              <h4 className="bold">Customer Type</h4>
            </div> */}
          </div>
        </div> 
      </div>;
      case "miscellaneous":
        return <div className="d-md-flex flex-md-wrap justify-content-start">        
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/document_type/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Document Type"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/gstTreatment/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"GST Registration Type"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/gstSlab/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"GST Slab"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/inventoryMaster/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Inventory"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/manufacturing/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Manufacturing"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/material_type/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Material Type"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/statusMaster/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Status"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/taskMaster/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Task"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/task-category/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Task Category"}</p>

          </div>
        </div>
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/shiftMaster/');
          }}
        >
          <div className="position-relative">
            
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            <img
              src={Box}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            <p className="card-body text-center mb-0 d-block">{"Shift Master"}</p>

          </div>
        </div>
      </div>;
      case "production":
        return <div className="d-md-flex flex-md-wrap justify-content-start">
        
        <div className="card shadow-sm border-0 col-12 col-md-2 m-3 cursor_pointer" 
          onClick={(e) => {
            e.preventDefault();
            navigate('/pcms/global/routing/');
          }}
        >
          <div className="position-relative">
            {/* <Info className="position-absolute top-0 end-0 m-2" /> */}
            <img
              src={Check}
              className="position-absolute top-0 end-0 m-2"
              alt="Card"
              width={25}
              height={25}
            />

            {/* Card Image */}
            <img
              src={Routine}
              className="mx-auto d-block mt-4 svg-img"
              alt="Card"
              width={50}
              height={50}
            />

            {/* Card Title */}
            <p className="card-body text-center mb-0 d-block">{"Routing Master"}</p>

          </div>
        </div>
      </div>;
        default: return null;
      }
  };

  return (
    <React.Fragment>
      <div className="min-vh-100" id="root_div_main">
        <div className="content-wrapper">
          <div className="card border-0 " id="production_planning">
            <div className="card-body">
              <Tabs
                id="controlled-tab-example"
                activeKey={activeTab}
                onSelect={(k) => setActiveTab(k)}
                className="my-2"
              >
                {tabs.map((tab) => (
                  <Tab
                    key={tab.eventKey}
                    eventKey={tab.eventKey}
                    title={tab.label}
                  >
                    <div className="p-2">
                      <div className="card-body roboto-bold body1 py-0" style={{maxHeight: "70vh", overflow: "auto"}}>
                          {renderTabContent()}
                      </div>
                    </div>
                  </Tab>
                ))}
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default MastersMenu;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faClose } from "@fortawesome/free-solid-svg-icons";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  uploadNCImageApi,
  deleteNCImageApi,
  updateNCImageApi,
  getNCList,
  updateNCAPI,
  getNCImageApi,
  get_WorkOrder,
} from "../../api/index";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import Accordion from "react-bootstrap/Accordion";
import {
    convertBase64,
  convertDateYYMMDD,
  getLabels,
  splitStringFromQuestionMark,
} from "../../common/Functions/CommonFunctions";

const schema = yup
  .object()
  .shape({
    image: yup
      .mixed()
      .test(
        "fileSize",
        "Image can be uploaded upto 10 MB",
        (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)
      )
      .required("Required"),
    fileName: yup.string().required("Image name is required !"),
  })
  .required();

function ImageGallery(props) {
  const [imagePreview, setImagePreview] = useState(null);
  const [ncData, setNcData] = useState({ data: [], loading: [] });
  const [blkImg, setBlkImg] = useState({ data: [], loading: [] });
  const [edit, setEdit] = useState({ status: false, data: {}, who: "" });
  const [fileName, setFileName] = useState("");

  const [constLabel, setConstLabel] = useState({});
  const [isLot, setIsLot] =  useState(props.val?.lot_no_id !== null ? true : false);

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      image: "",
      fileName: "",
    },
  });

  let { errors } = formState;

  let file = watch("image");

  useEffect(() => {
    if (file?.length > 0) {
      setImagePreview(URL.createObjectURL(file[0]));
      setValue("fileName", file[0].name);
    } else {
      setImagePreview(null);
      setValue("fileName", "");
    }
  }, [file]);

  useEffect(() => {
    console.log("props", props);
    setConstLabel(getLabels("Quality", "NC"));
    fetchImages();
    fetchBulkImage();
  }, []);

  function fetchImages() {
    setNcData({ ...ncData, data: [], loading: true });

    // setNcData
    getNCList({
      // startDate: convertDateYYMMDD(new Date('2000-01-01'), "-"),
      // endDate: convertDateYYMMDD(new Date(), "-"),
      uuid: props.val.uuid,
    }).then(
      (res) => {
        if (res.data.data.length > 0) {
          if(props.val.lot_no_id !== null){

            setNcData({ ...ncData, data: res.data.data[0], loading: false });
          } else {
            const {work_order_id, ...rest} = res.data.data[0];
            get_WorkOrder({ work_order_no: props.val?.work_order_id?.work_order_no }).then(
              (ress) => {
                // Replace work_order_id with ress.data.data[0]
                const newData = { ...rest, work_order_id: ress.data.data[0] };
                console.log("newData", newData);
                setNcData({ ...ncData, data: newData, loading: false });
              },
              (errr) => {
                
              }
            );
          }
        }
      },
      (err) => {
        setNcData({ ...ncData, data: [], loading: false });
      }
    );
  }

  function fetchBulkImage() {
    setBlkImg({ ...blkImg, data: [], loading: true });
    getNCImageApi({
        ncMaster: props.val.uuid,
    }).then(
      (res) => {
        console.log("res", res);
        if (res.data.data.length > 0) {
          setBlkImg({ ...blkImg, data: res.data.data, loading: false });
        }
      },
      (err) => {
        setBlkImg({ ...blkImg, data: [], loading: false });
      }
    );
  }

  const onSubmit = async (data) => {
    const payload = {
    //   attachment: data.image[0],
      name: data.fileName,
      ncId: props.val.uuid,
    };

    if (data.image?.length > 0) {
        const getExt = data.image?.[0]?.name?.split(".").pop();
  
        let dataimage = await convertBase64(data.image[0]);
        let newImage = dataimage.replace(
          /data:image\/[a-zA-Z]+;/,
          "data:image/" + getExt + ";"
        );
  
        payload.attachment = newImage;
    } else {
        payload.attachment = "";
    }

    console.log("payload", payload);

    // const formDataToSend = new FormData();

    // for (const [key, value] of Object.entries(payload)) {
    //   formDataToSend.append(key, value);
    // }

    uploadNCImageApi(payload).then(
      (res) => {
        toast.success("Image uploaded successfully", {
          autoClose: 2000,
        });

        fetchImages();
        fetchBulkImage();
        reset({
          image: "",
          fileName: "",
        });
        setImagePreview(null);
        // props.setGalVisible(false);
      },
      (err) => {
        toast.error("Something went wrong", {
          autoClose: 2000,
        });
      }
    );
  };

  function deleteImage(val) {
    SweetAlert.fire({
      title: "Are you sure to delete image ? \n",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        deleteNCImageApi({ uuid: val.uuid }).then(
          (res) => {
            toast.success("Image deleted successfully", {
              autoClose: 2000,
            });
            fetchImages();
            fetchBulkImage();
            // props.setGalVisible(false);
          },
          (err) => {
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function deleteImg(val) {
    SweetAlert.fire({
      title: "Are you sure to delete image ? \n",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = {
            camshaft_type_id: ncData.data?.camshaft_type_id?.uuid,
            operation_inwhich_id: ncData.data?.operation_inwhich_id?.uuid,
            operation_id_id: ncData.data?.operation_id_id?.uuid,
            machine_id_id: ncData.data?.machine_id_id?.uuid,
            ncstatus: ncData.data?.ncstatus,
            ncstatus_id: ncData.data?.ncstatus_id?.uuid,
            ncreason: ncData.data?.ncreason,
            ncreason_id: ncData.data?.ncreason_id?.uuid,
            operator_id: ncData.data?.operator_id?.uuid,
            // lot_no: ncData.data?.lot_no,
            // lot_no_id: ncData.data?.lot_no_id?.uuid,
            nc_tag: ncData.data?.nc_tag,
            job_no: ncData.data?.job_no,
            qty: ncData.data?.qty,
            nc_date: ncData.data?.nc_date,
            ncjobimage: "",
            ncjobimgname: "", 
            uuid: val.uuid,
            isLot: isLot 
            };

            if(isLot && isLot === true){
              payload.lot_no = ncData.data?.lot_no;
              payload.lot_no_id = ncData.data?.lot_no_id?.uuid;
              payload.work_order_no = null;
              payload.work_order_id = null;
              payload.work_order_status = null;
            } else {
              payload.lot_no = null;
              payload.lot_no_id = null;
              payload.work_order_no = ncData.data?.work_order_id?.work_order_no;
              payload.work_order_id = ncData.data?.work_order_id?.uuid;
              payload.work_order_status = ncData.data?.work_order_id?.work_order_status;
              payload.product_id = ncData.data?.work_order_id?.product_info?.productId;
            }
        // const formDataToSend = new FormData();

        // for (const [key, value] of Object.entries(payload)) {
        //   formDataToSend.append(key, value);
        // }
        updateNCAPI(payload).then(
          (res) => {
            toast.success("Image deleted successfully", {
              autoClose: 2000,
            });
            fetchImages();
            fetchBulkImage();
          },
          (err) => {
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function editName() {
    if (edit.who == "multiple") {
      const payload = { name: fileName, id: edit.data.uuid };
    //   const formDataToSend = new FormData();

    //   for (const [key, value] of Object.entries(payload)) {
    //     formDataToSend.append(key, value);
    //   }
      updateNCImageApi(payload).then(
        (res) => {
          toast.success("Image name updated successfully", {
            autoClose: 2000,
          });
          fetchImages();
          fetchBulkImage();
          setFileName("");
          setEdit({ ...edit, status: false });
          // props.setGalVisible(false);
        },
        (err) => {
          toast.error("Something went wrong", {
            autoClose: 2000,
          });
        }
      );
    }

    if (edit.who == "single") {
      const payload = {
        camshaft_type_id: ncData.data?.camshaft_type_id?.uuid,
        operation_inwhich_id: ncData.data?.operation_inwhich_id?.uuid,
        operation_id_id: ncData.data?.operation_id_id?.uuid,
        machine_id_id: ncData.data?.machine_id_id?.uuid,
        ncstatus: ncData.data?.ncstatus,
        ncstatus_id: ncData.data?.ncstatus_id?.uuid,
        ncreason: ncData.data?.ncreason,
        ncreason_id: ncData.data?.ncreason_id?.uuid,
        operator_id: ncData.data?.operator_id?.uuid,
        // lot_no: ncData.data?.lot_no,
        // lot_no_id: ncData.data?.lot_no_id?.uuid,
        nc_tag: ncData.data?.nc_tag,
        job_no: ncData.data?.job_no,
        qty: ncData.data?.qty,
        nc_date: ncData.data?.nc_date,

        ncjobimgname: fileName, 
        uuid: edit.data.uuid,
        isLot: isLot 
        };

        if(isLot && isLot === true){
          payload.lot_no = ncData.data?.lot_no;
          payload.lot_no_id = ncData.data?.lot_no_id?.uuid;
          payload.work_order_no = null;
          payload.work_order_id = null;
          payload.work_order_status = null;
        } else {
          payload.lot_no = null;
          payload.lot_no_id = null;
          payload.work_order_no = ncData.data?.work_order_id?.work_order_no;
          payload.work_order_id = ncData.data?.work_order_id?.uuid;
          payload.work_order_status = ncData.data?.work_order_id?.work_order_status;
          payload.product_id = ncData.data?.work_order_id?.product_info?.productId;
        }
    //   const formDataToSend = new FormData();

    //   for (const [key, value] of Object.entries(payload)) {
    //     formDataToSend.append(key, value);
    //   }
      updateNCAPI(payload).then(
        (res) => {
          toast.success("Image name updated successfully", {
            autoClose: 2000,
          });
          fetchImages();
          fetchBulkImage();
          setFileName("");
          setEdit({ ...edit, status: false });
          // props.setGalVisible(false);
        },
        (err) => {
          console.log("err", err);
          toast.error("Something went wrong", {
            autoClose: 2000,
          });
        }
      );
    }
  }

  return (
    <React.Fragment>
      <Modal
        show={props.galVisible}
        onHide={() => props.setGalVisible(false)}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_image_gallary
              ? constLabel.lbl_image_gallary
              : "Image Gallery"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion defaultActiveKey={"0"} className="mb-4">
            <Accordion.Item eventKey={"0"}>
              <Accordion.Header className="roboto-bold">
                {constLabel?.lbl_upload_image
                  ? constLabel.lbl_upload_image
                  : "Upload Image"}
              </Accordion.Header>
              <Accordion.Body>
                <div className="row">
                  <div className="col-12">
                    {/* <div style={{ border: '1px solid #dee2e6', borderRadius: '2px', padding: '16px' }}> */}
                    {/* <label className="form-label form_label roboto-bold">Upload Image</label> */}

                    <div className="row">
                      <div className="col-12 col-md-6">
                        <input
                          className={
                            errors.image
                              ? "form-control form-control-sm is-invalid"
                              : "form-control form-control-sm"
                          }
                          type="file"
                          accept="image/*"
                          {...register("image")}
                          placeholder="Upload Image"
                        />
                        {errors.image && (
                          <span className="text-danger err-msg">
                            {errors.image.message}
                          </span>
                        )}
                      </div>

                      <div className="col-12 col-md-6">
                        <input
                          className={
                            errors.fileName
                              ? "form-control form-control-sm is-invalid"
                              : "form-control form-control-sm"
                          }
                          type="text"
                          {...register("fileName")}
                          placeholder="Enter image name"
                        />
                        {errors.fileName && (
                          <span className="text-danger err-msg">
                            {errors.fileName.message}
                          </span>
                        )}
                      </div>

                      {imagePreview && !errors.image && (
                        <div className="col-12 col-md-6 mt-2">
                          <label className="form-label form_label roboto-bold d-block">
                            {constLabel?.lbl_image_preview
                              ? constLabel.lbl_image_preview
                              : "Image Preview"}
                          </label>
                          <div
                            className="text-center"
                            style={{
                              border: "1px solid #dee2e6",
                              borderRadius: "2px",
                            }}
                          >
                            <img
                              src={imagePreview}
                              alt="Preview"
                              style={{
                                maxWidth: "200px",
                                margin: "10px",
                                borderRadius: "8px",
                              }}
                            />
                          </div>
                        </div>
                      )}
                    </div>

                    <div className="d-flex justify-content-end">
                      <button
                        className="btn btn-sm btn-yellow px-3 mt-3 "
                        onClick={handleSubmit(onSubmit)}
                      >
                        {constLabel?.lbl_update
                          ? constLabel.lbl_update
                          : "Upload"}
                      </button>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>

          {edit.status ? (
            <div className="card mb-3">
              <div className="card-body">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <label className="form-label form_label roboto-bold">
                      {constLabel?.lbl_name ? constLabel.lbl_name : "Name"}
                    </label>
                    <input
                      type="text"
                      className="form-control form-control-sm"
                      placeholder="Enter image name"
                      onChange={(e) => {
                        setFileName(e.target.value);
                      }}
                      value={fileName}
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <label className="form-label form_label roboto-bold invisible d-block">
                      {constLabel?.lbl_name ? constLabel.lbl_name : "Name"}
                    </label>
                    <button
                      onClick={() => editName()}
                      className="btn btn-sm btn-yellow px-3"
                      style={{ marginTop: "2px" }}
                    >
                      {constLabel?.lbl_update
                        ? constLabel.lbl_update
                        : "Upload"}
                    </button>
                    <button
                      onClick={() => setEdit({ ...edit, status: false })}
                      className="btn btn-sm btn-grey px-3 ms-2"
                      style={{ marginTop: "2px" }}
                    >
                      {constLabel?.lbl_cancel
                        ? constLabel.lbl_cancel
                        : "Cancel"}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : null}

          <div className="row image_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
            {ncData.data?.ncjobimage !== null ? (
              <div className="col">
                <div className="image-container">
                  <img
                    src={
                      process.env.REACT_APP_IMAGE_URL + ncData.data?.ncjobimage
                    }
                    className="img-fluid"
                    alt="Image 1"
                  />

                  <div className="image-overlay">
                    <FontAwesomeIcon
                      onClick={() =>
                        window.open(
                          process.env.REACT_APP_IMAGE_URL +
                            ncData.data?.ncjobimage,
                          "_blank"
                        )
                      }
                      className="icon"
                      style={{ color: "#fff" }}
                      icon={faEye}
                      size="xl"
                    />

                    <FontAwesomeIcon
                      onClick={() => deleteImg(ncData.data)}
                      className="icon-close"
                      style={{ color: "#fff" }}
                      icon={faClose}
                      size="lg"
                    />

                    <span
                      className="image-name cursor_pointer"
                      onClick={() => {
                        setEdit({
                          ...edit,
                          status: true,
                          data: ncData.data,
                          who: "single",
                        });
                        setFileName("");
                      }}
                    >
                      {ncData.data?.ncjobimgname == null
                        ? "NA"
                        : ncData.data?.ncjobimgname}
                    </span>
                  </div>
                </div>
              </div>
            ) : null}

            {blkImg.data?.map((val, i) => (
              <div className="col" key={i}>
                <div className="image-container">
                  <img
                    src={process.env.REACT_APP_IMAGE_URL + val.attachment}
                    className="img-fluid"
                    alt="Image 1"
                  />

                  <div className="image-overlay">
                    <FontAwesomeIcon
                      onClick={() => window.open(process.env.REACT_APP_IMAGE_URL + val.attachment, "_blank")}
                      className="icon"
                      style={{ color: "#fff" }}
                      icon={faEye}
                      size="xl"
                    />

                    <FontAwesomeIcon
                      onClick={() => deleteImage(val)}
                      className="icon-close"
                      style={{ color: "#fff" }}
                      icon={faClose}
                      size="lg"
                    />
                    <span
                      className="image-name cursor_pointer"
                      onClick={() => {
                        setEdit({
                          ...edit,
                          status: true,
                          data: val,
                          who: "multiple",
                        });
                        setFileName("");
                      }}
                    >
                      {val.name}
                    </span>
                    {/* 
                        <i className="icon fas fa-search"></i>
                        <i className="icon icon-close fas fa-times"></i> 
                    */}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-grey px-4"
            onClick={() => props.setGalVisible(false)}
          >
            {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default React.memo(ImageGallery);

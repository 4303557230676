import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { data } from '../SampleData'
import { Pagination } from "react-bootstrap";
import { screen_list } from "../../../common/Labels/screenList";
import { useLocation } from 'react-router-dom';
import { fetch_privilages, create_privilages, update_privilages } from "../../../api/index";
import { ToastContainer, toast } from "react-toastify";

function ScreenAccess() {
    const [constLabel, setConstLabel] = useState({});
    const [entriesPerPage, setEntriesPerPage] = useState(10); // Default items per page
    const [currentPage, setCurrentPage] = useState(1);
    const [srNo, setSrNo] = useState(0);
    const [searchValue, setSearchValue] = useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [privilage_uuid, set_privilage_uuid] = useState(null);

    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        // Reset page when data changes
        setCurrentPage(1);
        console.log("location", location);
        get_privilages();

    }, [location.state.user_id]); // Only depend on user_id



    function get_privilages() {
        const payload = {
            user_id: location.state.user_id
        };

        // Start loading before API call
        fetch_privilages(payload).then((res) => {
            console.log("res", res);
            if (res.data.data.length > 0) {
                if (res.data.data[0].screen_list.length > 0) {
                    set_privilage_uuid({
                        uuid: res.data.data[0].uuid,
                    });

                    const result_list = screen_list.map(item => {
                        const permission = res.data.data[0].screen_list.find(perm => perm.name === item.name);
                        if (permission) {
                            // If matching name found, replace the permission fields
                            return {
                                name: item.name,
                                read: permission.read,
                                write: permission.write,
                                no_access: permission.no_access
                            };
                        }
                        // If no permission is found, return the original item
                        return item;
                    });

                    console.log("result_list", result_list);
                    setFilteredData(result_list);
                } else {
                    setFilteredData(screen_list);
                }
            } else {
                set_privilage_uuid(null);
                setFilteredData(screen_list);
            }
        }).catch((err) => {
            console.log("err", err);
            if (err.response.status == 404) {
                setFilteredData(screen_list);
            }
        }).finally(() => {
            setLoading(false);
            // Stop loading after API call is done
        });
    }

    const totalEntries = filteredData.length;
    const totalPages = Math.ceil(totalEntries / entriesPerPage);

    // Calculate indices for current page's items
    const indexOfLastItem = currentPage * entriesPerPage;
    const indexOfFirstItem = indexOfLastItem - entriesPerPage;
    const currentData = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const showingFrom = indexOfFirstItem + 1;
    const showingTo = Math.min(indexOfLastItem, totalEntries);

    // Case-insensitive search function
    const handleSearch = () => {
        setLoading(true);
        if (searchValue.trim()) {
            const newFilteredData = screen_list.filter(item =>
                item.name.toLowerCase().includes(searchValue.toLowerCase()));

            setFilteredData(newFilteredData);
            setLoading(false);
        } else {
            setFilteredData(screen_list);
            setLoading(false);// Reset to original data when search is cleared
        }
        setCurrentPage(1); // Reset to first page after search
    };

    // Handle page change (Previous and Next)
    const handlePageChange = (newPage) => {
        if (newPage >= 1 && newPage <= totalPages) {
            setCurrentPage(newPage);
        }
    };

    // Handle entries per page change
    const handleEntriesPerPageChange = (e) => {
        setEntriesPerPage(Number(e.target.value)); // Update items per page
        setCurrentPage(1); // Reset to first page when changing items per page
    };

    const handleCheckboxChange = (ind, type) => {
        const updatedData = filteredData.map((item, index) => {
            if (index === ind) {
                let updatedItem = { ...item };

                if (type === 'read') {
                    updatedItem.read = !updatedItem.read;
                    updatedItem.no_access = false;
                    if (!updatedItem.read && !updatedItem.write && !updatedItem.no_access) {
                        updatedItem.read = updatedItem.write = updatedItem.no_access = null;
                    }
                } else if (type === 'write') {
                    updatedItem.write = !updatedItem.write;
                    if (updatedItem.write) {
                        updatedItem.read = true; // If write is checked, read should also be true
                        updatedItem.no_access = false; // If write is checked, read should also be true
                    }
                    if (!updatedItem.read && !updatedItem.write && !updatedItem.no_access) {
                        updatedItem.read = updatedItem.write = updatedItem.no_access = null;
                    }
                } else if (type === 'no_access') {
                    updatedItem.no_access = !updatedItem.no_access;
                    if (updatedItem.no_access) {
                        updatedItem.read = false;
                        updatedItem.write = false; // If no_access is checked, set both read and write to false
                    }
                    if (!updatedItem.read && !updatedItem.write && !updatedItem.no_access) {
                        updatedItem.read = updatedItem.write = updatedItem.no_access = null;
                    }
                }

                return updatedItem;
            }
            return item;
        });

        setFilteredData(updatedData);
        setLoading(false);
    };

    function onSubmit() {
        console.log("currentData", currentData);
        console.log("filteredData", filteredData);

        const payload = {
            user_id: location.state.user_id,
            screen_list: filteredData
        }



        if (privilage_uuid) {
            payload.uuid = privilage_uuid.uuid;

            console.log("payload", payload);
            update_privilages(payload).then((res) => {
                console.log("res", res);
                toast.success("Permission updated successfully", {
                    autoClose: 2000,
                });
                // get_privilages();
                setTimeout(() => {
                    navigate(-1);
                }, [2000]);
            }, (err) => {
                console.log("err", err);
                toast.error(err.response.data.message, {
                    autoClose: 2000,
                });
            });

        } else {
            console.log("payload", payload);
            create_privilages(payload).then((res) => {
                console.log("res", res);
                toast.success("Permission created successfully", {
                    autoClose: 2000,
                });
                setTimeout(() => {
                    navigate(-1);
                }, [2000]);
                // get_privilages();
            }, (err) => {
                console.log("err", err);
                toast.error(err.response.data.message, {
                    autoClose: 2000,
                });
            });

        }
    }


    return (
        <React.Fragment>
            <ToastContainer />

            <div className="ScreenAccess mb-4">
                <div className="card">
                    <div className="card-header bg-grey d-flex align-items-center">
                        <button
                            className="btn btn-yellow"
                            onClick={() => {
                                navigate(-1);
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} size="sm" />
                        </button>

                        <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
                            {constLabel?.lbl_revise_supplier_quotation
                                ? constLabel.lbl_revise_supplier_quotation
                                : "Screen Access"}
                        </p>
                    </div>
                    <div className="card-body">
                        <div className="container-fluid px-4 py-2">
                            <div className='row'>

                                <div className='col-12 col-md-5'>
                                    <input
                                        id="search_input"
                                        className='form-control'
                                        placeholder='Search Screen'
                                        onChange={(e) => {
                                            console.log("e", e);
                                            setSearchValue(e.target.value);
                                            setCurrentPage(1);
                                        }}
                                        value={searchValue}
                                    />
                                </div>

                                <div className='col-12 col-md-3'>
                                    <button
                                        onClick={() => handleSearch()}
                                        className='btn button-primary px-4'>Search</button>
                                </div>




                                {loading == true ? (
                                    <div className="text-center  mt-4">
                                        <div className="spinner-border" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                ) : currentData?.length > 0 ? (
                                    <div className="table-responsive mt-4">
                                        <table className="table table-bordered">
                                            <thead className="table-grey">
                                                <tr className="">
                                                    <th>{"Sr. No"}</th>
                                                    <th>{"Screen Name"}</th>
                                                    <th>{"Read"}</th>
                                                    <th>{"Write"}</th>
                                                    <th>{"No Access"}</th>
                                                </tr>
                                            </thead>
                                            <tbody className=" roboto">
                                                {currentData?.map((item, ind) => (
                                                    <tr key={ind}>
                                                        <td>{ind + srNo + 1}</td>
                                                        <td>{item.name}</td>
                                                        <td>
                                                            {/* <label> */}
                                                            <input
                                                                className="form-check-input 
                                                                    me-2 "
                                                                type="checkbox"
                                                                checked={item.read ?? false}
                                                                onChange={() =>
                                                                    handleCheckboxChange(ind + srNo, 'read')}
                                                            />
                                                            {/* read</label> */}
                                                        </td>
                                                        <td>
                                                            {/* <label> */}
                                                            <input
                                                                className="form-check-input 
                                                                    me-2 "
                                                                type="checkbox"
                                                                checked={item.write ?? false}
                                                                onChange={() =>
                                                                    handleCheckboxChange(ind + srNo, 'write')}
                                                            />
                                                            {/* write</label> */}
                                                        </td>
                                                        <td>
                                                            {/* <label> */}
                                                            <input
                                                                className="form-check-input 
                                                                    me-2 "
                                                                type="checkbox"
                                                                checked={item.no_access ?? false}
                                                                onChange={() =>
                                                                    handleCheckboxChange(ind + srNo, 'no_access')}
                                                            />
                                                            {/* no access</label> */}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <p className="text-danger text-center mb-0">
                                        {" No Screens Found"}
                                    </p>
                                )}

                                {/* List of items */}
                                {/* <ul className='mt-4'>
                                    {currentData.map((item, ind) => (
                                        <React.Fragment key={ind}>
                                            <div className=''>
                                                <div className='permission row'>
                                                    <div className='col-12 col-md-5'>
                                                        <p className='mt-2 mb-0'>{`${item.name}`}</p>
                                                    </div>


                                                    <div className='col-12 col-md-7 
                                                    d-flex justify-content-around align-items-center'>
                                                        <label>
                                                            <input
                                                                className="form-check-input 
                                                                    me-2 "
                                                                type="checkbox"
                                                                checked={item.read ?? false}
                                                                onChange={() => handleCheckboxChange(ind + srNo, 'read')}
                                                            />read</label>
                                                        <label>
                                                            <input
                                                                className="form-check-input 
                                                                    me-2 "
                                                                type="checkbox"
                                                                checked={item.write ?? false}
                                                                onChange={() => handleCheckboxChange(ind + srNo, 'write')}
                                                            />write</label>
                                                        <label>
                                                            <input
                                                                className="form-check-input 
                                                                    me-2 "
                                                                type="checkbox"
                                                                checked={item.no_access ?? false}
                                                                onChange={() => handleCheckboxChange(ind + srNo, 'no_access')}
                                                            />no access</label>
                                                    </div>
                                                </div>

                                            </div>
                                            <hr />
                                        </React.Fragment>
                                    ))}
                                </ul> */}





                                <div className="d-flex justify-content-between align-itmes-center mt-2">
                                    <p className="mb-0">
                                        Show
                                        <select
                                            className="mx-1"
                                            value={entriesPerPage}
                                            onChange={handleEntriesPerPageChange}
                                        >
                                            <option value="10">10</option>
                                            <option value="25">25</option>
                                            <option value={"50"}>50</option>
                                        </select>
                                        Entries
                                    </p>
                                    <p className="mb-0">{`Showing ${showingFrom} 
                                    to ${showingTo
                                        } of ${totalEntries} entries`}</p>
                                    <Pagination className="my-2">
                                        <Pagination.Prev
                                            disabled={currentPage === 1}
                                            onClick={() => {
                                                {
                                                    handlePageChange(currentPage - 1);
                                                    setSrNo((prevC) => prevC - parseInt(entriesPerPage));
                                                }
                                            }}
                                        >
                                            {"Prev"}
                                        </Pagination.Prev>

                                        <Pagination.Item active>{currentPage}</Pagination.Item>

                                        <Pagination.Next
                                            disabled={currentPage === totalPages}
                                            onClick={() => {
                                                handlePageChange(currentPage + 1);
                                                setSrNo((prevC) => prevC + parseInt(entriesPerPage));
                                            }}
                                        >
                                            {"Next"}
                                        </Pagination.Next>
                                    </Pagination>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="card-footer d-flex justify-content-end py-3">

                        <button
                            className="btn btn-grey px-4 ms-0 ms-md-2"
                            onClick={() => onSubmit()}
                        >
                            {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
                        </button>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
}

export default ScreenAccess
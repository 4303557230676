import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { Tab, Tabs } from "react-bootstrap";
import ViewBankDetail from "./BankDetail/ViewBankDetail";
import ViewBillingAddress from "./BillingAddress/ViewBillingAddress";
import ViewShippingAddress from "./ShippingAddress/ViewShippingAddress";
import ContactDetailList from "./TableList/ContactDetailList";
import LotDetailList from "./TableList/LotDetailList";
import ProductDetailList from "./TableList/ProductDetailList";
import EditCustomer from "./EditCustomer";
import { getLabels } from "../../../../common/Functions/CommonFunctions";

function ViewCustomer() {
  let location = useLocation();
  const [key, setKey] = useState("lotDetails");
  const [customerDetail, setCustomerDetail] = useState({ data: {} });
  const { partyCode } = useParams();
  const [constLabel, setConstLabel] = useState({});
  // const { val } = location.state;

  
  
  // let location = useLocation();
  

  
  // var partyCode = location.state.val.partyCode;
  localStorage.setItem("partyCode", partyCode);

  useEffect(() => {
    setConstLabel(getLabels('Party Master', 'Customer'));
    
    // const payload = {
    //   action: "viewbyid",
    //   custPartyCode: partyCode
    // };
    // getCustomerDetail(payload).then(
    //   (res) => {
    
    //     setCustomerDetail({
    //       ...customerDetail,
    //       state: false,
    //       data: res.data.customerDetailsObj,
    //     });
    //   },
    //   (err) => {
    
    //     setCustomerDetail({ ...customerDetail, state: false, data: {} });
    //     toast.error(err.response.data.message, {
    //       autoClose: 3000,
    //     });
    //   }
    // );
  }, []);

  return (
    <React.Fragment>
      <div className="content-wrapper">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="bold">
          <FontAwesomeIcon style={{ color: "#000" }} icon={faBuilding} size="sm" />{" "}
            {location.state?.organization}
          </h1>
          {/* <EditCustomer data={location.state} /> */}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-12">
          <div className="row p-t-20">
            
            <div className="col-12 col-sm-6">
              <div className="card shadow rounded-0">
                <div className="card-header">
                  <label className="label-text">{constLabel?.lbl_primary_detail ? constLabel.lbl_primary_detail : "Primary Detail"}{":"}</label>
                </div>
                <div className="card-body">
                  <form className="">
                    <div className="form-group row mt-2">
                      <label
                        className="col-sm-4 lbl-style"
                        htmlFor="inputEmail3"
                      >
                        {constLabel?.lbl_name ? constLabel.lbl_name : "Name"}
                      </label>
                      <div className="col-sm-8 txt-control">
                        {location.state?.name != "" && location.state?.name != null ? location.state?.name : "NA" }
                      </div>
                    </div>
                    <div className="form-group row mt-2">
                      <label
                        className="col-sm-4 lbl-style"
                        htmlFor="inputPassword3"
                      >
                        {constLabel?.lbl_customer_id ? constLabel.lbl_customer_id : "Cust Id"}
                      </label>
                      <div className="col-sm-8 txt-control">
                        {location.state?.partyCode && location.state?.partyCode != null ? location.state?.partyCode : "NA" }
                      </div>
                    </div>
                    <div className="form-group row mt-2">
                      <label
                        className="col-sm-4 lbl-style"
                        htmlFor="inputPassword3"
                      >
                        {constLabel?.lbl_mobile_number ? constLabel.lbl_mobile_number :  "Mobile Number"}
                      </label>
                      <div className="col-sm-8 txt-control">
                        {location.state?.primaryContactNo && location.state?.primaryContactNo != null ? location.state?.primaryContactNo : "NA"}
                      </div>
                    </div>
                    <div className="form-group row mt-2">
                      <label
                        className="col-sm-4 lbl-style"
                        htmlFor="inputPassword3"
                      >
                        {constLabel?.lbl_email_id ? constLabel.lbl_email_id : "Email Id"}
                      </label>
                      <div className="col-sm-8 txt-control">
                        {location.state?.email && location.state?.email != null ? location.state?.email : "NA" }
                      </div>
                    </div>

                    <div className="form-group row mt-2">
                      <label
                        className="col-sm-4 lbl-style"
                        htmlFor="inputPassword3"
                      >
                        {constLabel?.lbl_website ? constLabel.lbl_website : "Website"}
                      </label>
                      <div className="col-sm-8 txt-control">
                        {location.state?.website && location.state?.website != null ? location.state?.website : "NA"}
                      </div>
                    </div>
                    <div className="form-group row mt-2">
                      <label
                        className="col-sm-4 lbl-style"
                        htmlFor="inputPassword3"
                      >
                        {constLabel?.lbl_address ? constLabel.lbl_address : "Address"}
                      </label>
                      <div className="col-sm-8">
                        <ViewBillingAddress
                          data={location.state?.billingAddresses}
                        />
                        <ViewShippingAddress
                          data={location.state?.shippingAddresses}
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-12 col-sm-6">
              <div className="card shadow rounded-0">
                <div className="card-header">
                  <label className="label-text">{"Other Details:"}</label>
                </div>
                <div className="card-body">
                  <form className="">
                    <div className="form-group row mt-2">
                      <label
                        className="col-sm-4 lbl-style"
                        htmlFor="inputEmail3"
                      >
                        {constLabel?.lbl_tin ? constLabel.lbl_tin : "TIN"}
                      </label>
                      <div className="col-sm-8 txt-control">
                        {location.state?.TIN && location.state?.TIN != null ? location.state?.TIN : "NA"}
                      </div>
                    </div>
                    <div className="form-group row mt-2">
                      <label
                        className="col-sm-4 lbl-style"
                        htmlFor="inputPassword3"
                      >
                        {constLabel?.lbl_tan ? constLabel.lbl_tan : "TAN"}
                      </label>
                      <div className="col-sm-8 txt-control">
                        {location.state?.TAN && location.state?.TAN != null ? location.state?.TAN : "NA"}
                      </div>
                    </div>
                    <div className="form-group row mt-2">
                      <label
                        className="col-sm-4 lbl-style"
                        htmlFor="inputPassword3"
                      >
                        {constLabel?.lbl_gst ? constLabel.lbl_gst : "GST No"}
                      </label>
                      <div className="col-sm-8 txt-control">
                        {location.state?.gst && location.state?.gst != null ? location.state?.gst : "NA"}
                      </div>
                    </div>

                    <div className="form-group row mt-2">
                      <label
                        className="col-sm-4 lbl-style"
                        htmlFor="inputPassword3"
                      >
                        {constLabel?.lbl_cst ? constLabel.lbl_cst : "CST"}
                      </label>
                      <div className="col-sm-8 txt-control">
                        {location.state?.CSTNO && location.state?.CSTNO != null ? location.state?.CSTNO : "NA"}
                      </div>
                    </div>
                    <div className="form-group row mt-2">
                      <label
                        className="col-sm-4 lbl-style"
                        htmlFor="inputPassword3"
                      >
                        {"Service Tax No."}
                      </label>
                      <div className="col-sm-8 txt-control">
                        {location.state?.serviceTaxNo && location.state?.serviceTaxNo != null ? location.state?.serviceTaxNo : "NA"}
                      </div>
                    </div>
                    <div className="form-group row mt-2">
                      <label
                        className="col-sm-4 lbl-style text-light"
                        htmlFor="inputPassword3"
                      >
                        {constLabel?.lbl_detail ? constLabel.lbl_detail : "Detail"}
                      </label>
                      <div className="col-sm-8 txt-control">
                        {/* <ViewBankDetail data={customerDetail.data?.customerBanks} /> */}
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>

          </div>

          <div className="row mt-4">
            <div className="card rounded-0" id="party_master">
              <div className="card-body">
                <Tabs
                  variant="pills"
                  id="controlled-tab-example"
                  activeKey={key}
                  onSelect={(k) => {
                    setKey(k);
                    
                  }}
                  className="mb-3"
                  fill
                >
                  <Tab
                    eventKey="lotDetails"
                    title={<span className="f-16 f-w-600">{constLabel?.lbl_lot_detail ? constLabel.lbl_lot_detail : "Lot Detail"}</span>}
                  >
                    <LotDetailList data={location.state}/>
                  </Tab>

                  <Tab
                    eventKey="productDetails"
                    title={
                      <span className="f-16 f-w-600">{constLabel?.lbl_product_detail ? constLabel.lbl_product_detail : "Product Detail"}</span>
                    }
                  >
                    <ProductDetailList data={location.state} />
                  </Tab>

                  <Tab
                    eventKey="contactDetails"
                    title={
                      <span className="f-16 f-w-600">{constLabel?.lbl_contact_detail ? constLabel.lbl_contact_detail : "Contact Detail"}</span>
                    }
                  >
                    <ContactDetailList data={location.state}
                    />
                  </Tab>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewCustomer;

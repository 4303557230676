import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle as faCheckCircleSolid } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import CloneProductDetailConfigure from "./CloneProductDetailConfigure";
import CloneImageConfigure from "./CloneImageConfigure";
import CloneProductSpecifications from "./CloneProductSpecifications";
import CloneProductSequence from "./CloneProductSequence";
import { getProductType } from "../../../../api";
import { useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import CloneRawMaterial from "./CloneRawMaterial";
import CloneSemiFinishGoods from "./CloneSemiFinishGoods";
// import BOMCreation from "../ProductConfigurator/BOMCreation";
import CloneBOM from "./CloneBOM";

function CloneProduct() {
  const [prodType, setProdType] = useState({ data: [], loading: false });
  const [currentStep, setCurrentStep] = useState(1);
  const [completedSteps, setCompletedSteps] = useState([1]);
  const [productDataLocal, setProductDataLocal] = useState({});
  const [bomStat, setBomStat] = useState(false);
  const bom_status = localStorage.getItem('bomStatus');
  let temp = [1]
  const totalSteps = 4; // Set the total number of steps

  const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const selProductType = watch("type");

  let location = useLocation();
  useEffect(() => {
    
    
    if (location.state.product_type_id == null) {
      toast.error("product_type_id found null", {
        autoClose: 3000,
      });
    }
    // setValue("type", [{ productType: location.state.product_type_id.productType }]);
    fetchProductType();
  }, [])

  useEffect(() => {
    temp = [...completedSteps];
    temp.push(parseInt(currentStep));
    
    setCompletedSteps(temp);
  }, [currentStep])

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        
        setProdType({ ...prodType, data: res.data.data, loading: false });
      },
      (err) => {
        
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="">
        <div className="row justify-content-start">
          <div className="col-md-6">
            <div className="steps position-relative">

              <div className="d-md-flex">
                <span className={"active"}>Step 1<FontAwesomeIcon
                  className="icon ms-1"
                  style={{ color: "#000000" }}
                  icon={faCheckCircleSolid}
                  size={"lg"}
                /></span>
                <hr className="my-auto mx-2" style={{ width: '50px' }} />
                <span className={"active"}>Step 2<FontAwesomeIcon
                  className="icon ms-1"
                  style={{ color: "#000000" }}
                  icon={faCheckCircleSolid}
                  size={"lg"}
                /></span>
                <hr className="my-auto mx-2" style={{ width: '50px' }} />
                <span className="active">Step 3<FontAwesomeIcon
                  className="icon ms-1"
                  style={{ color: "#000000" }}
                  icon={faCheckCircleSolid}
                  size={"lg"}
                /></span>
                <hr className="my-auto mx-2" style={{ width: '50px' }} />
                <span className="active">Step 4<FontAwesomeIcon
                  className="icon ms-1"
                  style={{ color: "#000000" }}
                  icon={faCheckCircleSolid}
                  size={"lg"}
                /></span>



                {Object.keys(productDataLocal).length > 0 ?

                  // productDataLocal.has_bom == true ?
                  bom_status == true || bomStat == true ?
                    <>
                      <hr className="my-auto mx-2" style={{ width: "50px" }} />
                      <span className={currentStep == 5 ? "active" : ""}>
                        Step 5
                        <FontAwesomeIcon
                          className="icon ms-1"
                          style={{ color: "#000000" }}
                          icon={
                            completedSteps.includes(5)
                              ? faCheckCircleSolid
                              : faCheckCircle
                          }
                          size={"lg"}
                        />
                      </span>
                    </> : null
                  : null}
              </div>
            </div>
          </div>
          <div className="step-content py-4">
            <div className="pc">
              <div className="d-flex justify-content-between mb-4">
                <h1 className="bold">
                  {"Clone Product Configurator"}
                </h1>
              </div>
              <div className="card shadow-sm rounded-0 p-2">
                <div className="card-body">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="form-group">
                        <label className="lbl-style roboto-bold">
                          {"Product Type"}
                          <span className="text-danger">*</span>
                        </label>
                        <Controller
                          name="type"
                          control={control}
                          defaultValue={location.state.product_type_id !== null ? [location.state.product_type_id] : []}

                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="productType"
                              defaultSelected={location.state.product_type_id !== null ? [location.state.product_type_id] : []}
                              className="mt-2"
                              multiple={false}
                              options={prodType.data}
                              isLoading={prodType.loading}
                              onChange={onChange}
                              selected={value}
                              onBlur={onBlur}
                              disabled
                              isInvalid={errors.type ? true : false}
                            />
                          )}
                        />

                        {errors.type && (
                          <span className="text-danger err-msg">
                            {errors.type.message}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {currentStep === 1 && (
                <>
                  {selProductType && selProductType[0]?.productType === 'Manufactured Goods' && (
                    <CloneProductDetailConfigure
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      data={location.state}
                      product_type={selProductType}
                      setProductDataLocal={setProductDataLocal}
                      setBomStat={setBomStat}
                    />
                  )}
                  {selProductType && selProductType[0]?.productType === 'Raw material' && (
                    <CloneRawMaterial
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      data={location.state}
                      product_type={selProductType}
                      setProductDataLocal={setProductDataLocal}
                      setBomStat={setBomStat}
                    />
                  )}
                  {selProductType && selProductType[0]?.productType === 'Semi Finish Goods' && (
                    <CloneSemiFinishGoods
                      currentStep={currentStep}
                      setCurrentStep={setCurrentStep}
                      data={location.state}
                      product_type={selProductType}
                      setProductDataLocal={setProductDataLocal}
                      setBomStat={setBomStat}
                    />
                  )}
                </>
              )}

              {currentStep === 2 &&
                <CloneImageConfigure
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  data={location.state}
                />}

              {currentStep === 3 &&
                <CloneProductSpecifications
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  data={location.state}
                  productDataLocal={productDataLocal}
                />}

              {currentStep === 4 &&
                <CloneProductSequence
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                  data={location.state}
                />}


              {(currentStep === 5 && JSON.parse(localStorage.getItem("bomStatus")) == true) && (
                <div>

                  <CloneBOM
                    productDataLocal={productDataLocal}
                    currentStep={currentStep}
                    setCurrentStep={setCurrentStep}
                    data={location.state}
                  />


                </div>
              )}            
            </div>
          </div>
        </div>
      </div>
    </React.Fragment >
  )
}

export default CloneProduct
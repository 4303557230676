import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faAdd,
  faEye,
  faArrowLeft,
} from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import { Modal, Spinner, Tab, Tabs } from "react-bootstrap";
import { Trash2 } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import DatePicker from "react-datepicker";
import {
  createProductionData,
  getLotNumList,
  getMachineList,
  getOperatorList,
  getProdConfig,
  updateProductionData,
  get_WorkOrder,
  get_job_card,
  getProductionData_Accumalation,
  check_job_card,
} from "../../api/index";
import {
  convertDateYYMMDD,
  getLabels,
  formatDateString3,
} from "../../common/Functions/CommonFunctions";
import { ToastContainer, toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import camshaft from "../../assets/images/camshaft.jpg";
import { formatHrs_Min } from "../../common/Functions/CommonFunctions";

const schema = yup
  .object()
  .shape({
    operation: yup
      .array()
      .min(1, "Please select operation")
      .required("Please select operation"),
    machine: yup
      .array()
      .min(1, "Please select machine !")
      .required("Please select machine !"),
    operator: yup
      .array()
      .min(1, "Please select operator")
      .required("Please select operator"),
    shift: yup
      .array()
      .min(1, "Please select shift")
      .required("Please select shift"),
    produced_qty: yup
      .string()
      // .transform((value, originalValue) => {
      //   if (originalValue === null) {
      //     return null;
      //   }
      //   return originalValue == '' ? null : value;
      // })
      .matches(/^\d+(\.\d{1,2})?$/, "Enter valid produced qty")
      .required("Enter produced qty"),
    rejected_qty: yup
      .string()
      .transform((value, originalValue) => {
        if (originalValue === null) {
          return null;
        }
        return originalValue == "" ? null : value;
      })
      .matches(/^\d+(\.\d{1,2})?$/, "Enter valid rejected qty")
      .nullable(),
    std_report_qty: yup
      .string()
      .transform((value, originalValue) => {
        if (originalValue === null) {
          return null;
        }
        return originalValue == "" ? null : value;
      })
      .matches(/^\d+(\.\d{1,2})?$/, "Enter valid std report qty")
      .nullable(),
    totalworking_hours: yup
      .string()
      // .transform((value, originalValue) => {
      //   if (originalValue === null) {
      //     return null;
      //   }
      //   return originalValue == '' ? null : value;
      // })
      .matches(/^[0-9]+$/, "Enter valid total working hours")
      .nullable(),
  })
  .required();

function EditProductionData() {
  let navigate = useNavigate();
  let location = useLocation();

  const [start_date, setStart_Date] = useState(
    new Date(formatDateString3(location?.state?.startDate_time))
  );
  const [end_date, setEnd_Date] = useState(
    new Date(formatDateString3(location?.state?.endDate_time))
  );
  const [visible, setVisible] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [lotList, setLotList] = useState({ data: [], loading: false });
  const [opList, setOpList] = useState({ data: [], loading: false });
  const [machineList, setMachineList] = useState({ data: [], loading: false });
  const [operatorList, setOperatorList] = useState({
    data: [],
    loading: false,
  });

  const [lockBtn, setLockBtn] = useState(false);

  const [lotNo, setLotNo] = useState(
    location?.state?.lotNo || location?.state?.work_order_no
  );
  const [isLot, setIsLot] = useState(
    location.state !== undefined && location.state?.lotNo === null
      ? false
      : true
  );

  const [workShift, setWorkShift] = useState({
    data: [
      { id: 1, shiftName: "1" },
      { id: 2, shiftName: "2" },
      { id: 3, shiftName: "3" },
    ],
  });

  const [woList, setWOList] = useState({ data: [], loading: false });
  const [jobCardList, setJobCardList] = useState({ data: [], loading: false });
  const [prod_data_check, setProd_Data_Check] = useState(false);
  const [constLabel, setConstLabel] = useState({});
  const [productionData, setProductionData] = useState({
    loading: true,
    data: [],
  });

  const userData = JSON.parse(localStorage.getItem("localData"));

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    setValue,
    setError,
    clearErrors,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let image = watch("selfile");
  let sd = watch("startDate");
  let { errors } = formState;

  useEffect(() => {
    if (image?.length > 0) {
      setImagePreview(URL.createObjectURL(image[0]));
    } else {
      setImagePreview(null);
    }
  }, [image]);

  useEffect(() => {
    console.log("location.state", location.state);
    if (location.state !== undefined && location.state !== null) {
      fetchLotList(location.state.lotNo || location.state.work_order_no);
    }
    setValue("startDate", start_date);
    setValue("endDate", end_date);
    setValue("std_report_qty", location?.state?.std_report_qty, {
      shouldValidate: true,
    });
    setValue("produced_qty", location?.state?.produced_qty ?? 0, {
      shouldValidate: true,
    });
    setConstLabel(getLabels("Production", "Production Data"));
    fetchOperatorList();
    fetchMachineList();
    calculateTotalWorkingHours(start_date, end_date);
    // }
    fetchAccQty();
  }, [location.state]);

  let selOperation = watch("operation");

  function fetchAccQty() {
    getProductionData_Accumalation({
      product_id: location.state.product_id.uuid,
      operation_id: location.state.operation_id.uuid,
      workorder_id: location.state.work_order_id,
    }).then(
      (res) => {
        // console.log("res", res);
        setProductionData({
          ...productionData,
          loading: false,
          data: res.data,
        });
      },
      (err) => {
        console.log("err", err);
        toast.error(err.response.data.message, {
          autoClose: 2000,
        });
        setProductionData({ ...productionData, loading: false, data: [] });
      }
    );
  }
  // useEffect(() => {
  //   if (selOperation !== undefined && selOperation.length > 0) {

  //     if (Array.isArray(selOperation) && selOperation.length > 0 && "machine" in selOperation[0]) {
  //       // const result = selOperation?.[0]?.machine;
  //       const result = selOperation[0]?.machine.map((item) => ({
  //         ...item,
  //         machineNameSerialNo: (item.serialNo !== null && item.serialNo !== undefined) ? `${item.name} - ${item.serialNo}` : item.name,
  //       }));
  //       if (result.length > 0) {
  //         const ress = result.find((item) => location?.state?.machine_id?.uuid === item.machine_id_id);
  //         if (ress) {
  //           setValue('machine', [ress]);
  //         } else {
  //           setValue('machine', []);
  //         }
  //       }
  //       setMachineList({ ...machineList, data: result, loading: false });
  //     } else {
  //       setValue("machine", [{
  //         name: location?.state?.machineId,
  //         machine_id: location?.state?.machineId,
  //         machineNameSerialNo: location?.state?.machineId

  //       }]);
  //       setMachineList({ ...machineList, data: [], loading: false });
  //     }

  //   }
  // }, [selOperation])

  function checkJobCardData(job_uuid) {
    check_job_card({ job_card_id: job_uuid }).then(
      (res) => {
        // console.log("res", res);
        if (res) {
          setProd_Data_Check(res.data?.production_data === true ? true : false);
        }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  function fetchMachineList() {
    setMachineList({ ...machineList, data: [], loading: true });
    getMachineList().then(
      (res) => {
        const result = res.data.data.map((item) => ({
          ...item,
          machineNameSerialNo: item.machineName + " - " + item.serialNo,
        }));

        if (result.length > 0) {
          const ress = result.find(
            (item) => location?.state?.machine_id?.uuid === item.uuid
          );
          if (ress) {
            setValue("machine", [ress]);
          } else {
            if (
              location?.state?.machineId !== null &&
              location?.state?.machineId !== undefined
            ) {
              const ress = result.filter(
                (item) => location?.state?.machineId === item.machineId
              );

              setValue("machine", ress);
            } else {
              setValue("machine", []);
            }
          }
        }

        setMachineList({ ...machineList, data: result, loading: false });
      },
      (err) => {
        setMachineList({ ...machineList, data: [], loading: false });
      }
    );
  }

  function fetchLotList(lotNum) {
    setLotList({ ...lotList, data: [], loading: true });
    if (isLot && isLot === true) {
      getLotNumList({ lotId: lotNum }).then(
        (res) => {
          setLotList({ ...lotList, data: res.data.data, loading: false });
          if (res.data.data.length == 0) {
            toast.error("Lot number not found", {
              autoClose: 2000,
            });
          } else {
            setValue("product_name", res.data.data[0]?.productname);
            setValue("part_no", res.data.data[0]?.oem);
            setValue("std_report_qty", res.data.data[0]?.qty, {
              shouldValidate: true,
            });
            // fetchProductOperations(res.data.data[0].product_id.uuid);
            if(location.state?.WO_details?.global_route_uuid !== null){
              setValue("operation", [{
                operationCategoryId : location.state?.operation_id?.operationCategoryId,
                operation_category_id : location.state?.operation_id?.operation_category_id,
                operation_id : location.state?.operation_id?.uuid,
                operationname : location.state?.operation_id?.operationName,
                oprationCategoryName : location.state?.operation_id?.oprationCategoryName,
              }]);
            } else {
              fetchProductOperations(res.data.data[0]?.productDetail?.uuid);
            }
              
           
          }
        },
        (err) => {
          setLotList({ ...lotList, data: [], loading: false });
        }
      );
    } else {
      // alert("Else call");
      // setJobCardList({ ...jobCardList, data: [], loading: true });
      get_WorkOrder({
        work_order_no: lotNum,
        work_order_status: "Released",
      }).then(
        (res) => {
          setWOList({ ...woList, data: res.data.data, loading: false });
          if (res.data.data.length == 0) {
            toast.error("Work order no not found", {
              autoClose: 2000,
            });
          } else {
            setJobCardList({ ...jobCardList, data: [], loading: true });
            // setValue('product_name', res.data.data[0]?.product_info?.productName);
            // setValue('part_no', res.data.data[0]?.product_info?.partNo);
            // fetchProductOperations(res.data.data[0]?.product_info?.uuid);
            // Job card api
            get_job_card({ work_order_id: res.data.data[0]?.uuid }).then(
              (ress) => {
                // console.log("ress", ress);
                setJobCardList({ ...jobCardList, data: [], loading: true });
                if (ress.data.data.length == 0) {
                  toast.error("Job card not found", {
                    autoClose: 2000,
                  });
                } else {
                  setJobCardList({
                    ...jobCardList,
                    data: ress.data.data,
                    loading: false,
                  });
                  let result = ress.data.data.filter(
                    (item) => item.uuid === location.state.job_card_id
                  );

                  if (result.length == 0) {
                    toast.error("Job card not found", {
                      autoClose: 2000,
                    });
                    setLockBtn(false);
                  }

                  // console.log("result", result);
                  setValue("job-card", result, { shouldValidate: true });
                  setValue(
                    "product_name",
                    result[0]?.productDetail?.productName
                  );
                  setValue("part_no", result[0]?.productDetail?.partNo);
                  if(location.state?.WO_details?.global_route_uuid !== null){
                    setValue("operation", [{
                      operationCategoryId : location.state?.operation_id?.operationCategoryId,
                      operation_category_id : location.state?.operation_id?.operation_category_id,
                      operation_id : location.state?.operation_id?.uuid,
                      operationname : location.state?.operation_id?.operationName,
                      oprationCategoryName : location.state?.operation_id?.oprationCategoryName,
                    }]);
                  } else {
                    fetchProductOperations(result[0]?.productDetail?.uuid);
                  }
                  checkJobCardData(result[0]?.uuid);
                  setLotList({ ...lotList, data: result, loading: false });
                }
              },
              (errr) => {
                setJobCardList({ ...jobCardList, data: [], loading: false });
              }
            );
            // Job card api
          }
        },
        (err) => {
          if (err.response.status == 404) {
            toast.error("Released work order not found !", {
              autoClose: 2000,
            });
          }
          setWOList({ ...woList, data: [], loading: false });
        }
      );
    }
  }

  function fetchProductOperations(prod_id) {
    if (prod_id == null) {
      toast.error("Product id not found in lot response", {
        autoClose: 2000,
      });
    } else {
      setOpList({ ...opList, data: [], loading: true });

      getProdConfig({ product_id: prod_id }).then(
        (res) => {
          // console.log("res get prod config", res);
          if (location?.state?.operation_id !== null) {
            const ress = res.data.data.find(
              (item) => location?.state.operation_id?.uuid === item.operation_id
            );
            // console.log("ress", ress);
            if (ress !== undefined && ress !== null) {
              setValue("operation", [ress]);
            } else {
              setValue("operation", [
                {
                  operationname: location?.state?.operation,
                  operationid: location?.state?.operationid,
                  operation_id: location?.state?.operation_id?.uuid,
                },
              ]);
            }
          } else {
            setValue("operation", [
              {
                operationname: location?.state?.operation,
                operationid: location?.state?.operationid,
                operation_id: location?.state?.operation_id?.uuid,
              },
            ]);
          }
          setOpList({ ...opList, data: res.data.data, loading: false });
        },
        (err) => {
          setOpList({ ...opList, data: [], loading: false });
        }
      );
    }
  }

  function fetchOperatorList() {
    setOperatorList({ ...operatorList, data: [], loading: true });
    getOperatorList({ designation: "Operator" }).then(
      (res) => {
        //

        if (res.data.data.length > 0) {
          const updatedPeopleList = res.data.data.map((person) => ({
            ...person,
            fullName:
              person.MiddleName !== null && person.MiddleName !== ""
                ? person.FirstName +
                  " " +
                  person.MiddleName +
                  " " +
                  person.LastName
                : person.FirstName + " " + person.LastName,
          }));

          if (location?.state?.employee_uuid !== null) {
            // const ress = updatedPeopleList.find((item) => location?.state?.employee_uuid?.uuid === item.uuid);
            const ress =
              updatedPeopleList.find(
                (item) => location?.state?.employee_uuid?.uuid === item.uuid
              ) || null;

            setValue(
              "operator",
              ress !== null
                ? [ress]
                : [
                    {
                      uuid: location?.state?.employee_uuid?.uuid,
                      fullName:
                        location?.state?.employee_uuid.MiddleName !== null &&
                        location?.state?.employee_uuid.MiddleName !== ""
                          ? location?.state?.employee_uuid.FirstName +
                            " " +
                            location?.state?.employee_uuid.MiddleName +
                            " " +
                            location?.state?.employee_uuid.LastName
                          : location?.state?.employee_uuid.FirstName +
                            " " +
                            location?.state?.employee_uuid.LastName,
                    },
                  ],
              { shouldValidate: true }
            );
          } else {
            setValue(
              "operator",
              location?.state?.workerName !== null
                ? [{ fullName: location?.state?.workerName }]
                : []
            );
          }

          setOperatorList({
            ...operatorList,
            data: updatedPeopleList,
            loading: false,
          });
        } else {
          setOperatorList({ ...operatorList, data: [], loading: false });
        }
      },
      (err) => {
        setOperatorList({ ...operatorList, data: [], loading: false });
      }
    );
  }

  const onSubmit = (data) => {
    // console.log("data", data);
    setLockBtn(true);
    if (data !== "") {
      const payload = {
        uuid: location?.state?.uuid,
        machineId: data.machine?.[0]?.machineId,
        part_no: data.part_no,
        // lotNo: lotList.data?.[0]?.lotId,
        productName:
          location?.state?.product_id !== null
            ? location?.state?.product_id?.productName
            : location?.state?.productName,
        lot_size:
          location?.state?.lot_size !== null
            ? location?.state?.lot_size
            : parseInt(lotList.data?.[0]?.qty),
        productid:
          location?.state?.productid !== "" &&
          location?.state?.productid !== null
            ? location?.state?.productid
            : data.operation?.[0]?.productid,
        operation: data.operation?.[0]?.operationname,
        operationid: data.operation?.[0]?.operationid,
        produced_qty: data.produced_qty,
        rejected_qty: data.rejected_qty !== null ? data.rejected_qty : "0",
        std_report_qty:
          data.std_report_qty !== null ? data.std_report_qty : null,
        startDate_time: formatHrs_Min(data.startDate, "-"),
        endDate_time: formatHrs_Min(data.endDate, "-"),
        jobNos: [],
        totalworking_hours:
          data.totalworking_hours !== null ? data.totalworking_hours : null,
        shift: data.shift?.[0]?.shiftName,
        remark: data.remark,
        employee_uuid: data.operator?.[0]?.uuid,
        product_id:
          location?.state?.product_id !== null
            ? location?.state?.product_id?.uuid
            : lotList.data?.[0]?.product_id.uuid,
        // machine_id: location?.state?.machine_id !== null ? location?.state?.machine_id?.uuid : data.machine?.[0]?.machine_id_id,
        machine_id: data.machine?.[0]?.uuid,
        operation_id: data.operation?.[0]?.operation_id,

        isLot: isLot,
      };

      if (isLot && isLot === true) {
        payload.lotNo = lotList.data[0]?.lotId;
        payload.work_order_no = null;
        payload.work_order_id = null;
        payload.job_card_no = null;
        payload.job_card_id = null;
      } else {
        payload.lotNo = null;
        payload.work_order_no = woList.data[0]?.work_order_no;
        payload.work_order_id = woList.data[0]?.uuid;
        payload.job_card_no = lotList.data[0]?.job_card_no;
        payload.job_card_id = lotList.data[0]?.uuid;
      }

      // console.log("payload", payload);

      updateProductionData(payload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Production data updated successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              navigate("/pcms/production-data");
            }, 2200);
            setLockBtn(false);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
          setLockBtn(false);
        }
      );
    } else {
      errors.showMessages();
    }
  };

  const handleFromDateChange = (date) => {
    if (end_date && date.getTime() > end_date.getTime()) {
      return;
    }
    setStart_Date(date);
    calculateTotalWorkingHours(date, end_date);
  };

  const handleToDateChange = (date) => {
    if (start_date && date.getTime() < start_date.getTime()) {
      return;
    }
    setEnd_Date(date);
    calculateTotalWorkingHours(start_date, date);
  };

  const calculateTotalWorkingHours = (startDate, endDate) => {
    if (startDate && endDate) {
      const timeDifference = Math.abs(endDate.setSeconds(59) - startDate.setSeconds(59));
      const hoursDifference = timeDifference / (1000 * 60 * 60);
      setValue("totalworking_hours", Math.floor(hoursDifference));
      // You can also use your setValue function here if needed
      // setValue('totalWorkingHours', hoursDifference);
    }
  };

  function handleTargetQtyChange(value) {
    let validQty;
    if (isLot) {
      validQty = lotList && lotList.data.length > 0 ? lotList.data[0].qty : 0;
    } else {
      validQty = woList && woList.data.length > 0 ? woList.data[0].quantity : 0;
    }
    if (Number(value) > Number(validQty)) {
      if (isLot) {
        setError("produced_qty", {
          type: "manual",
          message: "Produced qty should not exceeds lot size",
        });
      } else {
        setError("produced_qty", {
          type: "manual",
          message: "Produced qty should not exceeds work order qty",
        });
      }
    } else {
      clearErrors("produced_qty");
      setValue("produced_qty", value, { shouldValidate: true });
    }
  }

  function debounce(func, wait) {
    let timeout;
    return function (...args) {
      clearTimeout(timeout);
      timeout = setTimeout(() => func.apply(this, args), wait);
    };
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => navigate("/pcms/production-data")}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_update_production_data
                ? constLabel.lbl_update_production_data
                : "Update Production Data"}
            </p>
          </div>
          <div className="card-body">
            <div className="createNc container-fluid">
              <div className="row mb-3">
                <div
                  className="form-check form-switch"
                  style={{ paddingLeft: "3rem" }}
                >
                  <label
                    className="form-check-label lbl-style roboto-bold"
                    htmlFor="flexSwitchCheckChecked"
                  >
                    {"Lot / Work Order"}
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckChecked"
                    checked={isLot}
                    disabled
                    // onChange={() => setIsLot(!isLot)}
                  />
                </div>
              </div>
              <div className="row mb-3">
                <div className="col-12 col-md-6 row me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label roboto-bold col-12 col-md-3"
                  >
                    {isLot && isLot === true
                      ? constLabel?.lbl_lot_no
                        ? constLabel.lbl_lot_no
                        : "Lot No"
                      : constLabel?.lbl_work_order_no
                      ? constLabel.lbl_work_order_no
                      : "Work Order No"}
                  </label>

                  <div className="col-12 col-md-9">
                    <input
                      className="form-control form-control-sm"
                      type="text"
                      disabled
                      placeholder={
                        isLot && isLot === true
                          ? "Search lot no"
                          : "Search work order no"
                      }
                      onChange={(e) => setLotNo(e.target.value)}
                      value={lotNo}
                    />
                  </div>
                </div>
              </div>

              {isLot === false ? (
                <div className="row mb-3">
                  <div className="col-12 col-md-6 row">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label roboto-bold col-12 col-md-3"
                    >
                      {constLabel?.lbl_job_card_no
                        ? constLabel.lbl_job_card_no
                        : "Job Card No"}
                    </label>

                    <div className="col-12 col-md-9">
                      <Controller
                        name={`job-card`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="job_card_no"
                            size="sm"
                            multiple={false}
                            options={jobCardList.data}
                            isLoading={jobCardList.loading}
                            placeholder="Select job card no"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                            disabled
                            // isInvalid={errors.job-card ? true : false}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              {lotList.data?.length > 0 &&
              lotList.data !== null &&
              lotList.data !== undefined ? (
                <>
                  <hr className="my-2" />
                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                    {constLabel?.lbl_product_detail
                      ? constLabel.lbl_product_detail
                      : "Product Detail"}
                  </p>
                  <hr className="my-2" />

                  <div className="row my-3" style={{ fontSize: "16px" }}>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-md-3">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_name
                              ? constLabel.lbl_name
                              : "Name"}
                          </p>
                          <p className="mb-0" style={{ color: "#FF134E" }}>
                            {lotList.data[0].productname ||
                              lotList.data[0]?.productDetail?.productName}
                          </p>
                        </div>
                        <div className="col-12 col-md-3">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_part_no
                              ? constLabel.lbl_part_no
                              : "Part No"}
                          </p>
                          <p className="mb-0">
                            {lotList.data[0].product_id.partNo ||
                              lotList.data[0]?.productDetail?.partNo}
                          </p>
                        </div>
                        <div className="col-12 col-md-3">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_lot_size
                              ? constLabel.lbl_lot_size
                              : "Lot Size"}
                          </p>
                          <p className="mb-0">
                            {lotList.data[0].qty ||
                              lotList.data[0]?.workorderDetails?.quantity}
                          </p>
                        </div>
                        <div className="col-12 col-md-3">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_family
                              ? constLabel.lbl_family
                              : "Family"}
                          </p>
                          <p className="mb-0">
                            {lotList.data[0].product_familyname ||
                              woList.data[0]?.product_info?.prodfamily1
                                ?.familyname}
                          </p>
                        </div>
                        <div className="col-12 col-md-3 mt-2">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_lot_start_no
                              ? constLabel.lbl_lot_start_no
                              : "Lot Start No"}
                          </p>
                          <p className="mb-0">
                            {lotList.data[0].lotstartnum ||
                              lotList.data[0]?.workorderDetails
                                ?.serial_number_start}
                          </p>
                        </div>
                        <div className="col-12 col-md-3 mt-2">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_lot_end_no
                              ? constLabel.lbl_lot_end_no
                              : "Lot End No"}
                          </p>
                          <p className="mb-0">
                            {lotList.data[0].lotendnum ||
                              lotList.data[0]?.workorderDetails
                                ?.serial_number_end}
                          </p>
                        </div>
                        <div className="col-12 col-md-3 mt-2">
                          <p className="mb-0 roboto-bold">
                            {constLabel?.lbl_finished_weight
                              ? constLabel.lbl_finished_weight
                              : "Finished Weight"}
                          </p>
                          <p className="mb-0">
                            {lotList.data[0].product_id?.finished_weight || 0}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <button
                            onClick={() => setVisible(true)}
                            className="btn btn-sm btn-grey px-3"
                          >
                            {constLabel?.lbl_view_product_image
                              ? constLabel.lbl_view_product_image
                              : "View Product Images"}
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <hr className="my-2" />
                  <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                    {constLabel?.lbl_production_detail
                      ? constLabel.lbl_production_detail
                      : "Production Detail"}
                  </p>
                  <hr className="my-2" />

                  <div className="row mt-3">
                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_product
                          ? constLabel.lbl_product
                          : "Product"}
                        <span className="text-danger"> *</span>
                      </label>

                      <div className="col-12 col-md-9">
                        <input
                          className={
                            errors.product_name
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          disabled
                          type="text"
                          {...register("product_name")}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_part_no
                          ? constLabel.lbl_part_no
                          : "Part No"}
                      </label>

                      <div className="col-12 col-md-9">
                        <input
                          className={
                            errors.part_no
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          type="text"
                          disabled
                          defaultValue={location?.state?.part_no}
                          {...register("part_no")}
                        />
                        {errors.part_no && (
                          <span className="text-danger err-msg">
                            {errors.part_no.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_operation
                          ? constLabel.lbl_operation
                          : "Operation"}
                        <span className="text-danger"> *</span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          name={`operation`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="operationname"
                              multiple={false}
                              options={opList.data}
                              isLoading={opList.loading}
                              placeholder="Select operation"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              disabled={isLot === true ? false : true}
                              isInvalid={errors.operation ? true : false}
                            />
                          )}
                        />
                        {errors.operation && (
                          <span className="text-danger err-msg">
                            {errors.operation.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_machine
                          ? constLabel.lbl_machine
                          : "Machine"}
                        <span className="text-danger"> *</span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          name={`machine`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="machineNameSerialNo"
                              multiple={false}
                              options={machineList.data}
                              isLoading={machineList.loading}
                              placeholder="Select machine"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              disabled={isLot === true ? false : true}
                              isInvalid={errors.machine ? true : false}
                            />
                          )}
                        />
                        {errors.machine && (
                          <span className="text-danger err-msg">
                            {errors.machine.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_cumulative
                          ? constLabel.lbl_cumulative
                          : "Operation Cumulative Quantity"}
                      </label>

                      <div className="col-12 col-md-9">
                        <input
                          className="form-control"
                          type="text"
                          defaultValue={
                            productionData.data?.total_accumulated_qty
                          }
                          disabled
                        />
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_operator
                          ? constLabel.lbl_operator
                          : "Operator"}
                        <span className="text-danger"> *</span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          name={`operator`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="fullName"
                              multiple={false}
                              options={operatorList.data}
                              isLoading={operatorList.loading}
                              placeholder="Select Operator"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              disabled={
                                isLot === true
                                  ? false
                                  : prod_data_check === true
                                  ? false
                                  : true
                              }
                              isInvalid={errors.operator ? true : false}
                            />
                          )}
                        />
                        {errors.operator && (
                          <span className="text-danger err-msg">
                            {errors.operator.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_planned_qty
                          ? constLabel.lbl_planned_qty
                          : "Planned Qty"}
                      </label>

                      <div className="col-12 col-md-9">
                        <input
                          className={
                            errors.std_report_qty
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          type="text"
                          name="std_report_qty"
                          placeholder="Enter std report qty"
                          // disabled={isLot === true ? false : true}
                          disabled
                          // defaultValue={location?.state?.std_report_qty}
                          {...register("std_report_qty")}
                        />
                        {errors.std_report_qty && (
                          <span className="text-danger err-msg">
                            {errors.std_report_qty.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_rejected_qty
                          ? constLabel.lbl_rejected_qty
                          : "Rejected Qty"}
                      </label>

                      <div className="col-12 col-md-9">
                        <input
                          className={
                            errors.rejected_qty
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          type="text"
                          placeholder="Enter rejected qty"
                          defaultValue={location?.state?.rejected_qty}
                          {...register("rejected_qty")}
                        />
                        {errors.rejected_qty && (
                          <span className="text-danger err-msg">
                            {errors.rejected_qty.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_produced_qty
                          ? constLabel.lbl_produced_qty
                          : "Produced Qty"}
                        <span className="text-danger"> *</span>
                      </label>

                      <div className="col-12 col-md-9">
                        <input
                          className={
                            errors.produced_qty
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          type="text"
                          placeholder="Enter produced qty"
                          // defaultValue={location?.state?.produced_qty}
                          {...register(`produced_qty`, {
                            onChange: debounce((e) => {
                              const value = e.target.value;
                              handleTargetQtyChange(value);
                            }, 300), // Adjust the debounce delay as needed (300ms in this example)
                          })}
                        />
                        {errors.produced_qty && (
                          <span className="text-danger err-msg">
                            {errors.produced_qty.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_start_date
                          ? constLabel.lbl_start_date
                          : "Start Date"}
                        {""}
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          control={control}
                          name="startDate"
                          render={({ field }) => (
                            <DatePicker
                              className={"form-control"}
                              placeholderText="Select start date"
                              showTimeSelect
                              selected={start_date}
                              onChange={(date) => {
                                field.onChange(date);
                                handleFromDateChange(date);
                              }}
                              maxDate={
                                prod_data_check === true ? null : start_date
                              }
                              minDate={
                                prod_data_check === true ? end_date : null
                              }
                              dateFormat="dd/MM/yyyy h:mm aa"
                              autoComplete="false"
                              disabled={
                                isLot === true
                                  ? false
                                  : prod_data_check === true
                                  ? false
                                  : true
                              }
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_end_date
                          ? constLabel.lbl_end_date
                          : "End Date"}
                        {""}
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          control={control}
                          name="endDate"
                          render={({ field }) => (
                            <DatePicker
                              className={"form-control"}
                              placeholderText="Select end date"
                              showTimeSelect
                              selected={end_date}
                              onChange={(date) => {
                                field.onChange(date);
                                handleToDateChange(date);
                              }}
                              minDate={sd !== undefined ? sd : new Date()}
                              dateFormat="dd/MM/yyyy h:mm aa"
                              autoComplete="false"
                              disabled={
                                isLot === true
                                  ? false
                                  : prod_data_check === true
                                  ? false
                                  : true
                              }
                            />
                          )}
                        />
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_shift ? constLabel.lbl_shift : "Shift"}
                        <span className="text-danger"> *</span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          name={`shift`}
                          control={control}
                          defaultValue={[{ shiftName: location?.state?.shift }]}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="shiftName"
                              defaultSelected={[
                                { shiftName: location?.state?.shift },
                              ]}
                              multiple={false}
                              options={workShift.data}
                              placeholder="Select shift"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={errors.shift ? true : false}
                            />
                          )}
                        />
                        {errors.shift && (
                          <span className="text-danger err-msg">
                            {errors.shift.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_total_working_hours
                          ? constLabel.lbl_total_working_hours
                          : "Total Working Hours"}
                      </label>

                      <div className="col-12 col-md-9">
                        <input
                          className={
                            errors.totalworking_hours
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          type="text"
                          placeholder="Enter total working hours"
                          defaultValue={location?.state?.totalworking_hours}
                          disabled={isLot === true ? false : true}
                          {...register("totalworking_hours")}
                        />
                        {errors.totalworking_hours && (
                          <span className="text-danger err-msg">
                            {errors.totalworking_hours.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_remark
                          ? constLabel.lbl_remark
                          : "Remark"}
                      </label>

                      <div className="col-12 col-md-9">
                        <textarea
                          className={"form-control"}
                          id="remark"
                          name="remark"
                          defaultValue={location?.state?.remark}
                          placeholder="Enter remark"
                          {...register("remark")}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div className="text-center">
                  <div className="spinner-border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="card-footer py-3">
            <button
              className="btn btn-yellow px-4 float-end"
              onClick={handleSubmit(onSubmit)}
              disabled={
                lotList.data?.length > 0 &&
                lotList.data !== null &&
                lotList.data !== undefined
                  ? false
                  : true
              }
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}{" "}
              {lockBtn ? <Spinner animation="border" size="sm" /> : null}{" "}
            </button>
          </div>
        </div>
      </div>

      <Modal
        show={visible}
        onHide={() => setVisible(false)}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_product_images
              ? constLabel.lbl_product_images
              : "Product Images"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row image_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
            {lotList.data?.length > 0 &&
            lotList.data !== null &&
            lotList.data !== undefined
              ? (
                  lotList.data[0]?.product_id?.product_img ||
                  lotList.data[0]?.work_order_files
                )?.map((val, i) => (
                  <div className="col" key={i}>
                    <div className="image-container">
                      <img
                        src={process.env.REACT_APP_IMAGE_URL + val.attachment}
                        className="img-fluid"
                        alt="Image 1"
                      />

                      <div className="image-overlay">
                        <FontAwesomeIcon
                          onClick={() =>
                            window.open(
                              process.env.REACT_APP_IMAGE_URL + val.attachment,
                              "_blank"
                            )
                          }
                          className="icon"
                          style={{ color: "#fff" }}
                          icon={faEye}
                          size="xl"
                        />
                      </div>
                    </div>
                  </div>
                ))
              : null}

            {lotList.data?.length > 0 &&
            lotList.data !== null &&
            lotList.data !== undefined ? (
              lotList.data[0]?.product_id?.product_img?.length == 0 ||
              lotList.data[0]?.work_order_files?.length == 0 ? (
                <div className="col">
                  <div className="image-container">
                    <img src={camshaft} className="img-fluid" alt="Image 1" />

                    <div className="image-overlay">
                      <FontAwesomeIcon
                        onClick={() => window.open(camshaft, "_blank")}
                        className="icon"
                        style={{ color: "#fff" }}
                        icon={faEye}
                        size="xl"
                      />
                    </div>
                  </div>
                </div>
              ) : null
            ) : null}
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-grey px-4"
            onClick={() => setVisible(false)}
          >
            {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditProductionData;

import React, { useState } from "react";
import logo from "../../../assets/images/proditm.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Spinner } from "react-bootstrap";
import { onboard_client_reg } from "../../../constant";
import axios from "axios";

const schema = yup
  .object()
  .shape({
    password: yup
      .string()
      .required("Please enter password")
      .matches(
        /^(?=.*[A-Z])(?=.*[0-9])(?=.*[@$&])(?=.{8,})/,
        "1 uppercase, 1 number, 1 special character and atleast 8 character"
      ),
    confirmPassword: yup
      .string()
      .required("Please enter the same password")
      .oneOf([yup.ref("password"), null], "Password and confirm password does not match !"),
  })
  .required();

function SetCredential(props) {
  let navigate = useNavigate();
  const [lockbtn, setLockBtn] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);

  const [goBack, setGoBack] = useState(false);

  const { register, handleSubmit, formState, setValue, clearErrors, setError } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { errors } = formState;

  const onSubmit = (data) => {
    setLockBtn(true);
    if(data !== ""){
      let base_url = process.env.REACT_APP_COGNITO_AUTH_URL
      const url = `${base_url}${onboard_client_reg}`;

      const payload ={
        ...props.main_obj?.company_detail,
        password: data.password,
        // pan_no : "",
        // website : "",
      }

      axios.post(url, payload)
      .then((res) => {
        // console.log("Client register", res);
        toast.success("Client registered successfully !", {
          autoClose: 2000,
        });
        setTimeout(() => {
          navigate(`${process.env.PUBLIC_URL}/login`);
        }, 2200);
       
        setLockBtn(false);
      })
      .catch((err) => {
        console.log("err", err);

      if(err.response.data.message == "Invalid or expired token"){
        setError("customError", {
          type: "manual",
          message: "Session expired, please try again !",
        });
      } else{
        setError("customError", {
          type: "manual",
          message: err.response.data.message,
        });
      }
        setGoBack(true);
        setLockBtn(false);
      });
    }
    props.setMain_obj({ ...props.main_obj, company_detail: {...props.main_obj?.company_detail, contact_no: data.contact_no, email : data.email, password: data.password,} });
  };

  return (
    <>
      <ToastContainer />
          <div className="col-12 col-md-6 pt-5 align-items-center d-flex flex-column pb-2 mt-30">
            <div className="text-center">
              <img
                src={logo}
                className="img-fluid mx-auto"
                alt="Logo"
                style={{ height: "70px", width: "150px" }} // Adjust image size
              />
              <h3 className="bold w-100 mt-2">Welcome to PRODi !</h3>
              <h4 className="w-100 my-0 mt-2">Experience the seamless</h4>
              <h4 className="w-100">onboarding process</h4>

              <p className="bold w-100 my-0 mt-3">Setup credentials.</p>
            </div>

            

            {/* <div className="d-flex justify-content-center"> */}
              <div className="row col-10 mt-4">
                <div className="col-12 col-md-6 mt-2">
                  <div className="form-group mt-md-0 mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label body3 roboto-bold"
                    >
                      {"Email"}<span className="text-danger"> *</span>
                    </label>

                    <input
                      type="email"
                      className={
                        errors.email
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm "
                      }
                      id="email"
                      name="email"
                      placeholder="Enter email"
                      defaultValue={
                        props.main_obj?.company_detail?.email &&
                        (props.main_obj?.company_detail?.email !== "" ||
                          props.main_obj?.company_detail?.email !== null)
                          ? props.main_obj?.company_detail?.email
                          : ""
                      }
                      {...register("email")}
                      disabled
                    />
                    <span className="text-danger err-msg">{errors.email?.message}</span>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-2">
                  <div className="form-group mt-md-0 mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label body3 roboto-bold"
                    >
                      {"Contact No"}<span className="text-danger"> *</span>
                    </label>

                    <input
                      type="text"
                      className={
                        errors.contact_no
                          ? "form-control form-control-sm is-invalid"
                          : "form-control form-control-sm "
                      }
                      id="contact_no"
                      name="contact_no"
                      placeholder="Enter contact no"
                      maxLength={10}
                      defaultValue={
                        props.main_obj?.company_detail?.contact_no &&
                        (props.main_obj?.company_detail?.contact_no !== "" ||
                          props.main_obj?.company_detail?.contact_no !== null)
                          ? props.main_obj?.company_detail?.contact_no
                          : ""
                      }
                      {...register("contact_no")}
                      disabled
                    />
                    <span className="text-danger err-msg">
                      {errors.contact_no?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-2">
                  <div className="form-group mt-md-0 mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label body3 col-md-12 roboto-bold"
                    >
                      {"Password"}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="input-group">
                    <input
                      type={showPassword ? "text" : "password"}
                      className={errors.password ? "form-control form-control-sm is-invalid"
                        : " form-control form-control-sm"}
                      id="password"
                      name="password"
                      placeholder="Enter password"
                      {...register("password")}
                    />
                    <span className="input-group-text">
                          {showPassword ?
                            <FontAwesomeIcon
                              style={{ color: "#212529", cursor: 'pointer' }}
                              icon={faEyeSlash}
                              size="sm"
                              onClick={() => setShowPassword(false)}
                            />
                            :
                            <FontAwesomeIcon
                              style={{ color: "#212529", cursor: 'pointer' }}
                              icon={faEye}
                              size="sm"
                              onClick={() => setShowPassword(true)}
                            />
                          }
                        </span>
                        </div>
                    <span className="text-danger err-msg">
                      {errors.password?.message}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-2">
                  <div className="form-group mt-md-0 mt-3">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label body3 col-md-12 roboto-bold"
                    >
                      {"Confirm Password"}
                      <span className="text-danger"> *</span>
                    </label>
                    <div className="input-group">
                    <input
                      type={showPassword2 ? "text" : "password"}
                      className={errors.confirmPassword ? "form-control form-control-sm is-invalid"
                        : " form-control form-control-sm"}
                      id="confirmPassword"
                      name="confirmPassword"
                      placeholder="Enter confirm password"
                      {...register("confirmPassword")}
                    />
                    <span className="input-group-text">
                          {showPassword2 ?
                            <FontAwesomeIcon
                              style={{ color: "#212529", cursor: 'pointer' }}
                              icon={faEyeSlash}
                              size="sm"
                              onClick={() => setShowPassword2(false)}
                            />
                            :
                            <FontAwesomeIcon
                              style={{ color: "#212529", cursor: 'pointer' }}
                              icon={faEye}
                              size="sm"
                              onClick={() => setShowPassword2(true)}
                            />
                          }
                        </span>
                        </div>
                    <span className="text-danger err-msg">
                      {errors.confirmPassword?.message}
                    </span>
                  </div>
                </div>
              </div>

                {/* Span to display error */}
                {errors.customError && (
                  <span className="text-danger">{errors.customError.message}</span>
                )}

              { goBack ? 
              <div className="d-flex justify-content-center">
                  <a href="#" className="body2" onClick={(e) => {
                    e.preventDefault();
                    setGoBack(false); 
                    clearErrors(); 
                    props.setCurrStep(3);
                  }}> Try with another email </a>
              </div>
              : null }

             {!goBack ? <div className="w-50 d-flex justify-content-end">
                <button
                  className="btn button-primary w-100 mt-4 roboto-bold"
                  type="submit"
                  onClick={handleSubmit(onSubmit)}
                  disabled={lockbtn}
                >
                  Proceed{" "}
                  {lockbtn ? <Spinner animation="border" size="sm" /> : null}{" "}
                </button>
              </div>
             :null  
            }
            {/* </div> */}
          </div>
    </>
  );
}

export default SetCredential;

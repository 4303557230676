import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getProdCategory,
  getProdList,
  getCustomer,
  getMarket_Group,
  getProduct_Family,
  getUOM,
  saveProduct,
  getBOM,
  deleteBOM,
} from "../../../../api";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import { Modal, Spinner } from "react-bootstrap";
import { Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";

const schema = yup
  .object()
  .shape({
    // category: yup
    //   .array()
    //   .required("Product category is required !")
    //   .min(1, "Select atleast one type !"),
    marketGroup: yup
      .array()
      .required("Market group is required !")
      .min(1, "Please select market group !"),
    productFamily: yup
      .array()
      .required("Product family is required !")
      .min(1, "Please select product family!"),
    // OEMpartNo: yup
    //   .string()
    //   .required("OEM part no is required !"),
    openingStock: yup
      .string()
      .matches(/^[0-9]+$/, 'Enter valid opening stock')
      .nullable(true),
    productName: yup.string().required("Product name is required !"),
    hsn: yup
      .string()
      .matches(/^[a-zA-Z0-9]*$/, "Enter valid hsn")
      .nullable(true),
    unit: yup
      .array()
      .required("Unit is required !")
      .min(1, "Please select unit !"),
    machineCost: yup
      .string()
      .required("Cost is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid cost"),
    salesPrice: yup
      .string()
      .required("Sales price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid sales price"),
    selling_price: yup
      .string()
      .required("Selling price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid selling price"),
    purchase_price: yup
      .string()
      .required("Purchase price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid purchase price"),
    can_be_sold: yup.string().required("Required"),
    can_be_purchased: yup.string().required("Required"),
    can_be_expensed: yup.string().required("Required"),
    // has_bom: yup
    //   .string()
    //   .required("Required"),
  })
  .required();

function CloneProductDetailConfigure(props) {
  let navigate = useNavigate();
  let location = useLocation();

  const [prodCategory, setProdCategory] = useState({
    data: [],
    loading: false,
  });
  const [marketGroup, setMarketGroup] = useState({ data: [], loading: false });
  const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
  const [partyList, setPartyList] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [bomList, setBomList] = useState({ data: [], loading: false });
  const [particularBomList, setParticularBomList] = useState({
    data: [],
    loading: false,
  });
  const [createBom, setCreateBom] = useState(props.data.has_bom);
  const [modal, setModal] = useState(false);
  const [action, setAction] = useState('create');
  const prod_res = JSON.parse(localStorage.getItem("prod_res"));
  const [btnDisable, setBtnDisable] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    
    
    
    fetchProductCategory();
    fetchMarketGroup();
    fetchProductFamily();
    fetchParty();
    fetchUOM();
    fetchBoms();
    // setDefaultValues();
    fetchProd();

    localStorage.setItem('bomStatus', props.data.has_bom);
  }, []);


  function fetchProd() {
    if (prod_res !== undefined && prod_res !== null) {
      setAction("update");
      getProdList({ uuid: prod_res.uuid }).then((res) => {
        
        if (res.data.data.length == 0) {
          toast.error("Error fetching details of created products !", {
            autoClose: 2000,
          });
        } else {
          setDefaultValues(res.data.data[0]);

        }
      }, (err) => {
        
      });
    } else {
      setAction("create");
      setDefaultValues(props.data);
    }
  }

  function setDefaultValues(value) {

    if (value?.category !== null && value?.category_id !== null) {
      setValue("category", [{ categoryname: value?.category, uuid: value?.category_id?.uuid }]);
    }

    if (value.market_grp_id !== null) {
      setValue("marketGroup", [value.market_grp_id]);
    }

    if (value.prod_family_id !== null) {
      setValue("productFamily", [value.prod_family_id]);
    }

    if (value.productName !== null) {
      setValue("productName", value.productName);
    }

    if (value.openingstock !== null) {
      setValue("openingStock", value.openingstock);
    }
    if (value.hsn !== null) {
      setValue("hsn", value.hsn);
    }
    if (value?.unit_id !== null) {
      setValue("unit", [value?.unit_id]);
    }
    if (value?.can_be_sold !== null) {
      setValue("can_be_sold", value.can_be_sold);
    }
    if (value?.can_be_purchased !== null) {
      setValue("can_be_purchased", value.can_be_purchased);
    }
    if (value?.can_be_expensed !== null) {
      setValue("can_be_expensed", value.can_be_expensed);
    }
    if (value?.beacon_id !== null) {
      setValue("beacon_id", value.beacon_id);
    }
    if (value?.qr_code_id !== null) {
      setValue("qr_code_id", value.qr_code_id);
    }
    if (value?.machineCost !== null) {
      setValue("machineCost", value.machineCost);
    } else {
      setValue("machineCost", 0.0);
    }
    if (value?.salesPrice !== null) {
      setValue("salesPrice", value.salesPrice);
    } else {
      setValue("salesPrice", 0.0);
    }
    if (value?.selling_price !== null) {
      setValue("selling_price", value.selling_price);
    } else {
      setValue("selling_price", 0.0);
    }
    if (value?.purchase_price !== null) {
      setValue("purchase_price", value.purchase_price);
    } else {
      setValue("purchase_price", 0.0);
    }
    if (value?.remark !== null) {
      setValue("remark", value.remark);
    }
  }

  function fetchBoms() {
    setBomList({ ...bomList, data: [], loading: true });

    getBOM().then(
      (res) => {
        
        setBomList({ ...bomList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setBomList({ ...bomList, data: [], loading: false });
      }
    );
  }

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM({pageNo:1,entriesPerPage:100}).then(
      (res) => {
        
        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchProductCategory() {
    setProdCategory({ ...prodCategory, data: [], loading: true });
    getProdCategory().then(
      (res) => {
        
        setProdCategory({
          ...prodCategory,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setProdCategory({ ...prodCategory, data: [], loading: false });
      }
    );
  }

  function fetchMarketGroup() {
    setMarketGroup({ ...marketGroup, data: [], loading: true });
    getMarket_Group().then(
      (res) => {
        
        setMarketGroup({
          ...marketGroup,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setMarketGroup({ ...marketGroup, data: [], loading: false });
      }
    );
  }

  function fetchProductFamily() {
    setProdFamily({ ...prodFamily, data: [], loading: true });
    getProduct_Family().then(
      (res) => {
        
        setProdFamily({
          ...prodFamily,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setProdFamily({ ...prodFamily, data: [], loading: false });
      }
    );
  }

  function fetchParty() {
    setPartyList({ ...partyList, data: [], loading: true });
    getCustomer({partyType: "Customer"}).then(
      (res) => {
        
        const result = res.data.data.filter((item) => item.name !== null);
        setPartyList({ ...partyList, data: result, loading: false });
      },
      (err) => {
        
        setPartyList({ ...partyList, data: [], loading: false });
      }
    );
  }

  // const onSubmit = (data) => {
  //   props.setCurrentStep(props.currentStep + 1);
  // };

  const onSubmit = (data) => {
    
    setBtnDisable(true);

    if (data !== "") {
      const reqPayload = {
        productType: props.product_type[0]?.prodTypeId,
        productTypename: props.product_type[0]?.productType,
        product_type_id: props.product_type[0]?.uuid,
        category: data.category ? data.category[0]?.categoryname : "",
        category_id: data.category ? data.category[0]?.uuid : "",
        marketgrp: data.marketGroup[0]?.grpId,
        market_grp_id: data.marketGroup[0]?.uuid,
        prodfamily: data.productFamily[0]?.familyId,
        prod_family_id: data.productFamily[0]?.uuid,
        // subcategory: data.subCategory,
        productName: data.productName,
        openingstock: data.openingStock,
        hsn: data.hsn,
        machineCost: data.machineCost,
        salesPrice: data.salesPrice,
        can_be_sold: data.can_be_sold,
        can_be_purchased: data.can_be_purchased,
        can_be_expensed: data.can_be_expensed,
        beacon_id: data.beacon_id,
        qr_code_id: data.qr_code_id,
        selling_price: data.selling_price,
        purchase_price: data.purchase_price,
        unit_id: data.unit ? data.unit[0]?.uuid : "",
        remark: data.remark,
        party: [],
      };
      



      saveProduct(reqPayload).then(
        (res) => {
          
          setBtnDisable(false);
          if (res.status === 200 || res.status === 201) {
            toast.success("Manufactured goods product saved successfully !", {
              autoClose: 3000,
            });
            props.setProductDataLocal(res.data.product);
            localStorage.setItem(
              "prod_res",
              JSON.stringify(res.data.product)
            );

            props.setCurrentStep((prevStep) => prevStep + 1);
            if (createBom == true) {
              props.setBomStat(true);
            }
          }
        },
        (err) => {
          setBtnDisable(false);
          
          if (err.response.status === 400) {
            
            toast.error(err.response.data.non_field_errors[0], {
              autoClose: 2000,
            });
          }
        }
      )
    } else {
      errors.showMessages();
    }
  };

  function delete_bom(val) {
    
    SweetAlert.fire({
      title: "Are you sure to delete \n" + val.bom_type + " ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteBOM({ uuid: val.uuid }).then(
          (res) => {
            
            toast.success("Bill of material deleted successfully !", {
              autoClose: 2000,
            });
            fetchParticularBoms();
          },
          (err) => {
            
            toast.error("Something went wrong !", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function fetchParticularBoms() {
    setParticularBomList({ ...particularBomList, data: [], loading: true });
    getBOM({ product_id: location.state.uuid }).then(
      (res) => {
        
        setParticularBomList({
          ...particularBomList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setParticularBomList({
          ...particularBomList,
          data: [],
          loading: false,
        });
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <p className="pc_card_title roboto-bold">Product Summary</p>

          <div className="row">
            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {"Category"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <Controller
                  name="category"
                  control={control}
                  // defaultValue={props.data?.category !== null && props.data?.category_id !== null ? [
                  //   { categoryname: props.data?.category, uuid: props.data?.category_id?.uuid },
                  // ] : []}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      // defaultSelected={props.data?.category !== null && props.data?.category_id !== null ? [
                      //   { categoryname: props.data?.category, uuid: props.data?.category_id?.uuid },
                      // ] : []}
                      labelKey="categoryname"
                      className="mt-2"
                      multiple={false}
                      options={prodCategory.data}
                      isLoading={prodCategory.loading}
                      placeholder="Select product category"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {"Market group"}
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name="marketGroup"
                  control={control}
                  // defaultValue={props.data.market_grp_id !== null ? [props.data.market_grp_id] : []}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      // defaultSelected={props.data.market_grp_id !== null ? [props.data.market_grp_id] : []}
                      labelKey="groupname"
                      className="mt-2"
                      multiple={false}
                      options={marketGroup.data}
                      isLoading={marketGroup.loading}
                      placeholder="Select market group"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.marketGroup ? true : false}
                    />
                  )}
                />

                {errors.marketGroup && (
                  <span className="text-danger err-msg">
                    {errors.marketGroup.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {"Product Family"}
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name="productFamily"
                  control={control}
                  // defaultValue={props.data.prod_family_id !== null ? [props.data.prod_family_id] : []}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      // defaultSelected={props.data.prod_family_id !== null ? [props.data.prod_family_id] : []}
                      labelKey="familyname"
                      className="mt-2"
                      multiple={false}
                      options={prodFamily.data}
                      isLoading={prodFamily.loading}
                      placeholder="Select product family"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.productFamily ? true : false}
                    />
                  )}
                />

                {errors.productFamily && (
                  <span className="text-danger err-msg">
                    {errors.productFamily.message}
                  </span>
                )}
              </div>
            </div>

            {/* <div className="col-md-3 mt-3">
          <div className="form-group mt-md-0 mt-3">
            <label className="lbl-style roboto-bold">{"Sub Category"}</label>
            <input
              className="form-control mt-2"
              type="text"
              defaultValue={props.data && props.data?.subcategory}
              name="subCategory"
              {...register("subCategory")}
            />
          </div>
        </div> */}

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {"Product Name"}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.productName
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter product name"
                  // defaultValue={props.data?.productName !== null ? props.data?.productName : ""}
                  name="productName"
                  {...register("productName")}
                />
                {errors.productName && (
                  <span className="text-danger err-msg">
                    {errors.productName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {"Opening Stock"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter opening stock"
                  defaultValue={props.data?.openingstock !== null ? props.data?.openingstock : ""}
                  name="openingStock"
                  {...register("openingStock")}
                />
                {errors.openingStock && (
                  <span className="text-danger err-msg">
                    {errors.openingStock.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{"HSN"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter hsn"
                  defaultValue={props.data?.hsn !== null ? props.data?.hsn : ""}
                  name="hsn"
                  {...register("hsn")}
                />
                {errors.hsn && (
                  <span className="text-danger err-msg">
                    {errors.hsn.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {"Unit"}
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name="unit"
                  control={control}
                  // defaultValue={props.data?.unit_id !== null ? [props.data?.unit_id] : []}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      // defaultSelected={props.data?.unit_id !== null ? [props.data?.unit_id] : []}
                      labelKey="uom"
                      className="mt-2"
                      multiple={false}
                      options={uomList.data}
                      isLoading={uomList.loading}
                      placeholder="Select unit"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.uomList ? true : false}
                    />
                  )}
                />

                {errors.uomList && (
                  <span className="text-danger err-msg">
                    {errors.uomList.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">{"Unit"}</label>
              <div className="d-flex align-items-center">
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    onChange={(e) => {
                      setCreateBom(e.target.checked);
                      localStorage.setItem('bomStatus', e.target.checked);
                    }}
                    checked={createBom}
                  />
                  <label className="form-check-label lbl-style roboto-bold">create bom</label>
                </div>

              </div>
            </div>
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">Product Details</p>
          <div className="row col-12">
            {/* <div className="col-md-3">
          <div className="form-group">
            <label className="lbl-style roboto-bold">{"Drawing No."}</label>
            <input
              className="form-control mt-2"
              type="text"
              defaultValue={props.data && props.data?.drawingno}
              name="drawingNo"
              {...register("drawingNo")}
            />
          </div>
        </div> */}

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {"Drawing No."}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={props.data.can_be_sold !== null ? props.data.can_be_sold : false}
                  name="can_be_sold"
                  {...register("can_be_sold")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  Can be Sold
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {"Drawing No."}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={props.data.can_be_purchased !== null ? props.data.can_be_purchased : ""}
                  name="can_be_purchased"
                  {...register("can_be_purchased")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  Can be Purchase
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {"Drawing No."}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={props.data.can_be_expensed !== null ? props.data.can_be_expensed : ""}
                  name="can_be_expensed"
                  {...register("can_be_expensed")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  Can be Expense
                </label>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{"Beacon Id"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  name="beacon_id"
                  placeholder="Enter BeaconId"
                  defaultValue={props.data.beacon_id !== null ? props.data.beacon_id : ""}
                  {...register("beacon_id")}
                />
                {errors.beacon_id && (
                  <span className="text-danger err-msg">
                    {errors.beacon_id.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{"QR Code Id"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter QRCodeId"
                  name="qr_code_id"
                  defaultValue={props.data.qr_code_id !== null ? props.data.qr_code_id : ""}
                  {...register("qr_code_id")}
                />
                {errors.qr_code_id && (
                  <span className="text-danger err-msg">
                    {errors.qr_code_id.message}
                  </span>
                )}
              </div>
            </div>


          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">Product Price</p>
          <div className="row col-12">
            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {"Cost "}<span>&#8377;</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.machineCost
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter cost"
                  // defaultValue={
                  //   props.data?.machineCost !== null ? props.data.machineCost : 0.0
                  // }
                  name="machineCost"
                  {...register("machineCost")}
                />
                {errors.machineCost && (
                  <span className="text-danger err-msg">
                    {errors.machineCost.message}
                  </span>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {"Sales Price "}<span>&#8377;</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.salesPrice
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter sales price"
                  // defaultValue={
                  //   props.data?.salesPrice !== null ? props.data.salesPrice : 0.0
                  // }
                  name="salesPrice"
                  {...register("salesPrice")}
                />
                {errors.salesPrice && (
                  <span className="text-danger err-msg">
                    {errors.salesPrice.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {"Selling Price "}<span>&#8377;</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.selling_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter selling price"
                  name="selling_price"
                  // defaultValue={
                  //   props.data?.selling_price !== null ? props.data.selling_price : 0.0
                  // }
                  {...register("selling_price")}
                />
                {errors.selling_price && (
                  <span className="text-danger err-msg">
                    {errors.selling_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {"Purchase Price "}<span>&#8377;</span>
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.purchase_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter purchase price"
                  name="purchase_price"
                  // defaultValue={
                  //   props.data?.purchase_price !== null ? props.data.purchase_price : 0.0
                  // }
                  {...register("purchase_price")}
                />
                {errors.purchase_price && (
                  <span className="text-danger err-msg">
                    {errors.purchase_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-12 col-md-6 mt-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">{"Remark"}</label>
                <textarea
                  className="form-control mt-2"
                  type="text"
                  // defaultValue={props.data?.remark !== null ? props.data?.remark : ""}
                  name="remark"
                  {...register("remark")}
                ></textarea>
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <div className="d-flex justify-content-end">
            <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
              disabled={btnDisable}
            // onClick={() => props.setCurrentStep(props.currentStep + 1)}
            >
              Save
            </button>
          </div>
        </div>
      </div>

    </React.Fragment>
  );
}

export default CloneProductDetailConfigure;

import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faClose } from "@fortawesome/free-solid-svg-icons";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import Accordion from "react-bootstrap/Accordion";
import { Typeahead } from "react-bootstrap-typeahead";
import { createProdMachines, updateProdMachines } from "../../../../api/index";
import { getLabels } from "../../../../common/Functions/CommonFunctions";

const schema = yup
  .object()
  .shape({
    cycleTime: yup
      .string()
      .required("Cycle time is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid cycle time !"),
      settingTime: yup
      .string()
      .matches(/^$|^\d+(\.\d+)?$/, "Enter valid setting time !")
      .notRequired(),
  toolChangeTime: yup
      .string()
      .matches(/^$|^\d+(\.\d+)?$/, "Enter valid tool change time !")
      .notRequired(),
  machineRate: yup
      .string()
      .matches(/^$|^\d+(\.\d+)?$/, "Enter valid machine rate !")
      .notRequired(),
    machine: yup
      .array()
      .required("Please select machine !")
      .min(1, "Please select machine !"),
  })
  .required();

function EditMachineModal({
  machineList,
  machineModal,
  setMachineModal,
  machines,
  setMachines,
  currIndex,
  macInd,
  propsData,
  fields,
  getchProdConfig,
}) {
  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const [constLabel, setConstLabel] = useState({});

  let { errors } = formState;

  useEffect(() => {
    setConstLabel(getLabels("Master", "Product"));
    if (machineModal.state) {
      

      if (machineModal.mode == "create") {
        
        reset({
          cycleTime: "",
          settingTime: "",
          toolChangeTime: "",
          machineRate: "",
          machine: [],
        });
      } else {
        

                reset({
                    cycleTime: machineModal.selMachine.cycle_time,
                    settingTime: machineModal.selMachine.settings_time,
                    toolChangeTime: machineModal.selMachine.tool_change_time,
                    machineRate: machineModal.selMachine.machineRate == undefined ?
                        machineModal.selMachine.machine_rate : machineModal.selMachine.machineRate,
                    machine: [{
                        machineId: machineModal.selMachine.machine_id,
                        machineName: machineModal.selMachine.name,
                        uuid: machineModal.selMachine.machine_id_id,
                        opmachine_id: machineModal.selMachine.opmachine_id
                    }],
                });
            }
        }
    }, [machineModal.state]);


  const onsubmit = (data) => {
    
    
    
    

    if (Object.keys(fields[currIndex]).length > 1) {
      if (fields[currIndex].po_uuid !== undefined) {
        if (machineModal.mode == "create") {
          const payload = {
            product_id: propsData.productId,
            operation_id: fields[currIndex].operation[0].operationID,
            machine_id: data.machine[0].machineId,
            name: data.machine[0].machineName,
            cycle_time: data.cycleTime,
            settings_time: data.settingTime,
            tool_change_time: data.toolChangeTime,
            machine_rate: data.machineRate,
            product_id_id: propsData.uuid,
            operation_id_id: fields[currIndex].po_uuid,
            machine_id_id: data.machine[0].uuid,
          };
          
          createProdMachines(payload).then(
            (res) => {
              
              toast.success("Machine created successfully", {
                autoClose: 2000,
              });
              setMachineModal(false);
              getchProdConfig();
            },
            (err) => {
              
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
            }
          );
        } else {
          const payload = {
            opmachine_id: data.machine[0].opmachine_id,
            product_id: propsData.productId,
            machine_id_id: data.machine[0].uuid,
            machine_id: data.machine[0].machineId,
            name: data.machine[0].machineName,
            cycle_time: data.cycleTime,
            settings_time: data.settingTime,
            tool_change_time: data.toolChangeTime,
            machine_rate: data.machineRate,
            uuid: machineModal.selMachine.uuid,
            product_id_id: propsData.uuid,
          };

          

          updateProdMachines(payload).then(
            (res) => {
              

                        getchProdConfig();
                        setMachineModal({ ...machineModal, state: false });
                        toast.success("Machine updated successfully", {
                            autoClose: 2000,
                        });
                    }, (err) => {
                        
                    });
                }

            } else {
                let tempMachines = [...machines];
                
                tempMachines[currIndex].data.push(data);
                // tempMachines[currIndex].data.splice(machineModal.macInd, 0, data);
                
                setMachines(tempMachines);
                setMachineModal({ ...machineModal, state: false });
            }

        } else {
            
            let tempMachines = [...machines];
            if (machineModal.mode == "update") {
                tempMachines[currIndex].data.splice(machineModal.macInd, 1);
            }

            // tempMachines[currIndex].data.push(data);
            tempMachines[currIndex].data.splice(machineModal.macInd, 0, data);

            
            setMachines(tempMachines);
            setMachineModal(false);
        }

    }

    return (
        <React.Fragment>
            <Modal show={machineModal.state} onHide={() => setMachineModal({ ...machineModal, state: false })} size='lg' backdrop="static">
                <Modal.Header style={{ background: "#2A3643", color: "white" }} closeButton>
                    <Modal.Title>{`${machineModal.mode == "create" ? (constLabel?.lbl_add ? constLabel.lbl_add + " " : "Add") : (constLabel?.lbl_update ? constLabel.lbl_update + " " : "Update")}`}{constLabel?.lbl_machine ? constLabel.lbl_machine : "Machine"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className='col-12 col-md-6'>
                            <label
                                className="lbl-style roboto-bold"
                                style={{ fontSize: "14px" }}
                            >
                                {constLabel?.lbl_machine_name ? constLabel.lbl_machine_name : "Machine Name"}<span className='text-danger'>*</span>
                            </label>

              <Controller
                name={`machine`}
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    className="mt-2"
                    id="basic-typeahead"
                    labelKey="machineName"
                    multiple={false}
                    options={machineList.data}
                    isLoading={machineList.loading}
                    placeholder="Select machine"
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid={errors.machine ? true : false}
                  />
                )}
              />
              {errors.machine && (
                <span className="text-danger err-msg">
                  {errors.machine.message}
                </span>
              )}
            </div>
            <div className="col-12 col-md-6">
              <label
                className="lbl-style roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_cycle_time
                  ? constLabel.lbl_cycle_time
                  : "Cycle Time"}
              </label>
              <span style={{ fontSize: "12px", color: "gray" }}>
                {"(Minutes)"}
              </span>
              <input
                className={`form-control mt-2 ${
                  errors.cycleTime && "is-invalid"
                }`}
                {...register("cycleTime")}
                type="text"
                name="cycleTime"
                placeholder="Enter cycle time"
              />
              {errors.cycleTime && (
                <span className="text-danger err-msg">
                  {errors.cycleTime.message}
                </span>
              )}
            </div>

                        <div className='col-12 col-md-6 mt-2'>
                            <label
                                className="lbl-style roboto-bold"
                                style={{ fontSize: "14px" }}
                            >
                                {constLabel?.lbl_setting_time ? constLabel.lbl_setting_time : "Setting Time"}
                                <span
                                    style={{ fontSize: "12px", color: "gray" }}
                                >
                                    {"(Minutes)"}
                                </span>
                            </label>
                            <input
                                className={`form-control mt-2 ${errors.settingTime && "is-invalid"}`}
                                type="text"
                                name="settingTime"
                                {...register('settingTime')}
                                placeholder="Enter setting time"

                            />
                            {errors.settingTime && (
                                <span className="text-danger err-msg">
                                    {errors.settingTime.message}
                                </span>
                            )}
                        </div>

            <div className="col-12 col-md-6 mt-2">
              <label
                className="lbl-style roboto-bold"
                style={{ fontSize: "14px" }}
              >
                {constLabel?.lbl_tool_change_time
                  ? constLabel.lbl_tool_change_time
                  : "Tool Change Time"}
              </label>
              <span style={{ fontSize: "12px", color: "gray" }}>
                {"(Minutes)"}
              </span>
              <input
                className={`form-control mt-2 ${
                  errors.toolChangeTime && "is-invalid"
                }`}
                type="text"
                name="toolChangeTime"
                {...register("toolChangeTime")}
                placeholder="Enter tool change time"
              />
              {errors.toolChangeTime && (
                <span className="text-danger err-msg">
                  {errors.toolChangeTime.message}
                </span>
              )}
            </div>

                        <div className='col-12 col-md-6 mt-2'>
                            <label
                                className="lbl-style roboto-bold"
                                style={{ fontSize: "14px" }}
                            >
                                {constLabel?.lbl_machine_rate ? constLabel.lbl_machine_rate : "Machine Rate"}
                            </label>
                            <span
                                style={{ fontSize: "12px", color: "gray" }}
                            >
                                {"(Rate/Hr)"}
                            </span>
                            <input
                                className={`form-control mt-2 ${errors.machineRate && "is-invalid"}`}
                                type="text"
                                name="machineRate"
                                {...register('machineRate')}
                                placeholder="Enter machine rate"
                            />
                            {errors.machineRate && (
                                <span className="text-danger err-msg">
                                    {errors.machineRate.message}
                                </span>
                            )}
                        </div>

                    </div>

                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-yellow px-4" onClick={handleSubmit(onsubmit)}>
                        {constLabel?.lbl_submit ? constLabel.lbl_submit : "Submit"}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default EditMachineModal;

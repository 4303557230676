import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faSearch,
    faPlus,
    faBan,
    faFilter,
    faPercentage,
    faPercent,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal, Spinner } from "react-bootstrap";
import { Download, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Tooltip } from "react-tooltip";
import { get_store, save_store, delete_store, get_store_type, getLocation, getInternalCompany, getCustomer } from "../../../api";
import { calMaxPage } from "../../../common/Functions/CommonFunctions";

const schema = yup
    .object()
    .shape({
        name: yup
            .string()
            .required("Store name is required!"),
        store_type: yup
            .array()
            .min(1, "Please select store type !")
            .required("Please select store type !"),
        location: yup
            .array()
            .min(1, "Please select location !")
            .required("Please select location !"),
        internal_company: yup
            .array()
            .min(1, "Please select organisation !")
            .required("Please select organisation !"),
    })
    .required();


function CreateStore({ storeModal, setStoreModal, selLoc, company_data, fetchStoreList }) {

    const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    }); // initialise the hook

    let { errors } = formState;

    const [intCompList, setIntCompList] = useState({ data: [], loading: false });
    const [locList, setLocList] = useState({ data: [], loading: false });
    const [storeTypes, setStoreTypes] = useState({ data: [], loading: false });


    useEffect(() => {
        if (storeModal) {
            fetchStoreTypes();
            fetchLocations();
            fetchInternalCompany();

            
            

            setValue("location", [{ uuid: selLoc.uuid, locationname: selLoc.locationname }]);
            setValue("internal_company", [{ uuid: company_data.uuid, comName: company_data.comName }]);
        }
    }, [storeModal]);


    function fetchStoreTypes() {
        setStoreTypes({ ...storeTypes, data: [], loading: true });

        get_store_type().then((res) => {
            
            setStoreTypes({ ...storeTypes, data: res.data.data, loading: false });
        }, (err) => {
            
            setStoreTypes({ ...storeTypes, data: [], loading: false });
        })
    }


    function fetchLocations() {
        setLocList({ ...locList, data: [], loading: true });

        getLocation().then((res) => {
            
            setLocList({ ...locList, data: res.data.data, loading: false });
        }, (err) => {
            
            setLocList({ ...locList, data: [], loading: false });
        })
    }


    function fetchInternalCompany() {
        setIntCompList({ ...intCompList, data: [], loading: true });

        getInternalCompany().then((res) => {
            
            setIntCompList({ ...intCompList, data: res.data.data, loading: false });
        }, (err) => {
            
            setIntCompList({ ...intCompList, data: [], loading: false });
        })
    }

    const onSubmit = (data) => {
        

        if (data !== "") {
            const reqPayload = {
                name: data.name.trim(),
                store_types: data.store_type[0]?.name.trim(),
                location_id: data.location[0]?.uuid,
                internal_company_id: data.internal_company[0]?.uuid,
                internal_company_name: data.internal_company[0]?.comName,
            };

            

            save_store(reqPayload)
                .then(
                    (res) => {
                        
                        if (res.status === 200 || res.status === 201) {
                            toast.success("Store created successfully !", {
                                autoClose: 3000,
                            });
                            setStoreModal(false);
                            fetchStoreList(selLoc);
                        }
                    },
                    (err) => {
                        
                        if (err.response.status === 400) {
                            
                            toast.error(err.response.data.message, {
                                autoClose: 2000,
                            });
                        }
                    }
                )
        } else {
            errors.showMessages();
        }
    };


    const delStore = (val) => {
        
        SweetAlert.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "cancel",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                // Firing delete api
                const payload = {
                    uuid: val.uuid,
                };
                delete_store(payload).then(
                    (res) => {
                        
                        toast.success("Store deleted successfully !", {
                            autoClose: 3000,
                        });


                    },
                    (err) => {
                        
                        toast.error("Something went wrong !", { autoClose: 3000 });
                    }
                );
            }
        });
    };


    return (
        <React.Fragment>
            <Modal show={storeModal} onHide={() => setStoreModal(false)} size={"md"}>
                <Modal.Header
                    style={{ background: "#2A3643", color: "white" }}
                    closeButton
                >
                    <Modal.Title>{"Add Store"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form action="">
                        {/* Orgnazation */}
                        <div className="form-group row mt-2">
                            <label style={{ fontSize: "14px" }} className="col-form-label col-md-12 roboto-bold">
                                Organisation<span className="text-danger">*</span>
                            </label>
                            <div className="col-md-12">
                                <Controller
                                    name="internal_company"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="comName"
                                            multiple={false}
                                      
                                            options={intCompList.data !== undefined ? intCompList.data : []}
                                            isLoading={intCompList.state}
                                            placeholder="Select organisation"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.internal_company ? true : false}
                                            disabled
                                        />
                                    )}
                                />
                                <span className="text-danger err-msg">
                                    {errors.internal_company?.message}
                                </span>
                            </div>
                        </div>
                        {/* Location */}
                        <div className="form-group row mt-2">
                            <label style={{ fontSize: "14px" }} className="col-form-label col-md-12 roboto-bold">
                                Location<span className="text-danger">*</span>
                            </label>
                            <div className="col-md-12">
                                <Controller
                                    name="location"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="locationname"
                                            multiple={false}
                                           
                                            options={locList.data}
                                            isLoading={locList.loading}
                                            placeholder="Select location"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.location ? true : false}
                                            disabled
                                        />
                                    )}
                                />
                                <span className="text-danger err-msg">
                                    {errors.location?.message}
                                </span>
                            </div>
                        </div>
                        {/* Store Type */}
                        <div className="form-group row mt-2">
                            <label style={{ fontSize: "14px" }} className="col-form-label col-md-12 roboto-bold">
                                Store Type<span className="text-danger">*</span>
                            </label>
                            <div className="col-md-12">
                                <Controller
                                    name="store_type"
                                    control={control}
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <Typeahead
                                            id="basic-typeahead-single"
                                            labelKey="name"
                                            multiple={false}
                                           
                                            options={storeTypes.data !== undefined ? storeTypes.data : []}
                                            // options={[{name : null}, {name: "null"}]}
                                            isLoading={storeTypes.loading}
                                            placeholder="Select store type"
                                            onChange={onChange}
                                            onBlur={onBlur}
                                            selected={value}
                                            isInvalid={errors.store_type ? true : false}
                                        />
                                    )}
                                />
                                <span className="text-danger err-msg">
                                    {errors.store_type?.message}
                                </span>
                            </div>
                        </div>
                        {/* Store name */}
                        <div className="form-group row mt-2">
                            <label
                                style={{ fontSize: "14px" }}
                                className="col-form-label col-md-12 roboto-bold"
                            >
                                Store Name<span className="text-danger">*</span>
                            </label>
                            <div className="col-md-12">
                                <input
                                    type="text"
                                    className={
                                        errors.name
                                            ? "form-control  is-invalid"
                                            : "form-control "
                                    }
                                    id="name"
                                    name="name"
                                    placeholder="Enter store name"
                                    {...register("name")}
                                />
                                <span className="text-danger err-msg">
                                    {errors.name?.message}
                                </span>
                            </div>
                        </div>


                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={handleSubmit(onSubmit)}
                        className="f-16 btn btn-yellow "
                    >
                        {"Save"}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default CreateStore
import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faClose } from "@fortawesome/free-solid-svg-icons";
import { Modal, Tab, Tabs } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
import Accordion from 'react-bootstrap/Accordion';
// import { get_document_type } from "../../../../../api/index";
import { Typeahead } from "react-bootstrap-typeahead";

const imageFileTypes = ["image/jpeg", "image/jpg", "image/png"];

const schema = yup
    .object()
    .shape({
        image: yup
            .mixed()
            .test('fileSize', 'Image can be uploaded upto 10 MB',
                (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024))
            .test("fileType", "Only image files are allowed", (value) => {
                if (!value) return true; // If no file is selected, it's considered valid.
                // return value[0]?.type.startsWith('image/');
                return imageFileTypes.includes(value[0]?.type);
                })
            .required("Required"),
        fileName: yup
            .string()
            .required("Image name is required !"),
    })
    .required();

function TaskGallery(props) {
    const [docTypeList, setDocTypeList] = useState({ data: [], loading: false });

    const { register, handleSubmit, formState, control, reset, watch, setValue, setError } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });

    let { errors } = formState;



    useEffect(() => {

    }, []);


    const onsubmit = (data) => {
        

        let tempImg = [...props.drawingFiles];
        tempImg.push({ filename: data.fileName, file: data.image[0], });
        props.setDrawingFiles(tempImg);
        
        reset({
            image: null,
            fileName: ""
        });
    }

    function removeFile(i) {
        let tempImg = [...props.drawingFiles];

        tempImg.splice(i, 1);
        props.setDrawingFiles(tempImg);
        
    }



    return (
        <React.Fragment>
            <Modal show={props.galVisible} onHide={() => props.setGalVisible(false)} size='lg' backdrop="static">
                <Modal.Header style={{ background: "#2A3643", color: "white" }} closeButton>
                    <Modal.Title>{props.constLabel?.lbl_image_gallary ? props.constLabel.lbl_image_gallary : "Image Gallery"}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="row">
                        <div className='col-12 col-md-4'>
                            <label style={{ fontSize: "14px" }}
                                className="col-form-label">
                                    {props.constLabel?.lbl_file ? props.constLabel.lbl_file : "File"}
                                <span className='text-danger'>*</span>
                            </label>
                            <input
                                className={errors.image ? "form-control form-control-sm is-invalid" : 'form-control form-control-sm'}
                                type='file'
                                {...register('image')}
                                accept="image/*"
                            />
                            {errors.image && (
                                <span className="text-danger err-msg">
                                    {errors.image.message}
                                </span>
                            )}
                        </div>
                        <div className='col-12 col-md-4'>
                            <label style={{ fontSize: "14px" }}
                                className="col-form-label">
                                    {props.constLabel?.lbl_file_name ? props.constLabel.lbl_file_name : "File Name"}
                                <span className='text-danger'>*</span>
                                </label>
                            <input
                                className={errors.fileName ? "form-control form-control-sm is-invalid" : 'form-control form-control-sm'}
                                type='text'
                                {...register('fileName')}
                                placeholder='Enter filename'
                            />
                            {errors.fileName && (
                                <span className="text-danger err-msg">
                                    {errors.fileName.message}
                                </span>
                            )}
                        </div>

                        <div className='col-12 col-md-3'>
                            <label style={{ fontSize: "14px" }}
                                className="col-form-label invisible d-block">
                                    {props.constLabel?.lbl_file_name ? props.constLabel.lbl_file_name : "File Name"}
                                <span className='text-danger'>*</span>
                                </label>

                            <button className='btn btn-sm btn-yellow px-3'
                                onClick={handleSubmit(onsubmit)}>Submit</button>
                        </div>
                    </div>


                    <div className="row mt-4 drawing_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">

                        {props.drawingFiles.map((val, i) => (
                            val.file.type.includes('image') == true ?

                                <div className="col text-center" key={i}>
                                    <div className="image-container">
                                        <img src={URL.createObjectURL(val.file)}
                                            className="img-fluid" alt="Image 1" />

                                        <FontAwesomeIcon
                                            onClick={() => removeFile(i)}
                                            className='icon-close'
                                            style={{ color: "#000" }} icon={faClose} size="lg" />
                                    </div>

                                    <span className="image-name cursor_pointer">{val.filename}</span>
                                </div>
                                :
                                <div className="col text-center" key={i}>
                                    <div className="image-container cursor_pointer">
                                        <div
                                            onClick={() => window.open(val.file, '_blank')}
                                            className='other_doc d-flex justify-content-center align-items-center'>
                                            <FontAwesomeIcon
                                                className='icon-file-pdf'
                                                style={{ color: "#fff" }}
                                                icon={faFileAlt} size="3x"
                                            />

                                            <FontAwesomeIcon
                                                onClick={() => removeFile(i)}
                                                className='icon-close'
                                                style={{ color: "#000" }} icon={faClose} size="lg" />
                                        </div>
                                    </div>

                                    <span className="image-name cursor_pointer">{val.filename}</span>
                                </div>
                        ))}

                    </div>


                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-grey px-4" onClick={() => props.setGalVisible(false)}>
                    {props.constLabel?.lbl_file_name ? props.constLabel.lbl_file_name : "Close"}
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default TaskGallery
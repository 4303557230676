import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faPlus,
    faBan,
    faFilter,
    faCog,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination } from "react-bootstrap";
import { Trash2, Image } from "react-feather";
import SweetAlert from "sweetalert2";
import { Modal, } from "react-bootstrap";
import { getCutlot, createCutlot, deleteCutlot, get_rmi, RMI_list_for_Cutlot, get_product_by_rmi, get_document_type } from "../../../api";
import { getMaterialType } from "../../../api/index";
import { calMaxPage_new, getLabels, setAccessPrivilage, splitString } from "../../../common/Functions/CommonFunctions";
import EditCutlot from "./EditCutlot";
import { Tooltip } from "react-tooltip";
import { Cutlot_Col_Name } from "../../../common/Labels/labelConstant";
import { useSelector } from "react-redux";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { faAdd, } from "@fortawesome/free-solid-svg-icons";
import CutlotGallery from './CutlotGallery';
import AccessDenied from "../../Common/AccessDenied";

const imageFileTypes = ["image/jpeg", "image/jpg", "image/png"];
const schema = yup
    .object()
    .shape({
        rawtype: yup
            .array()
            .min(1, "Material type is required !")
            .required("Material type is required !"),
        rmi: yup
            .array()
            .min(1, "Material code is required !")
            .required("Material code is required !"),
        qty: yup
            .string()
            .max(200, "Maximum 200 characters allowed !")
            .required("Quantity is required !"),
        cutpiece_qty: yup
            .string()
            .max(200, "Maximum 200 characters allowed !")
            .required("Quantity is required !"),
        cutpiece_length: yup
            .string()
            .max(200, "Maximum 200 characters allowed !")
            .required("Quantity is required !"),
        items: yup.array().of(
            yup.object().shape({
                doc_type: yup
                    .array()
                    .min(1, "Doc type is required")
                    .required("Doc type is required !"),
                image: yup
                    .mixed()
                    .test('fileSize', 'Image can be uploaded upto 10 MB',
                        (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024))
                    .test("fileType", "Only image files are allowed", (value) => {
                        if (!value) return true; // If no file is selected, it's considered valid.
                        // return value[0]?.type.startsWith('image/');
                        return imageFileTypes.includes(value[0]?.type);
                    })
                    .required("Required"),
            })
        ),
    })
    .required();

function Cutlot() {

    const [showLabel, setShowLabel] = useState(Cutlot_Col_Name);
    const [visible, setVisible] = useState(false);
    const [galleryModal, setGalleryModal] = useState({ mode: false, data: {} });

    const [page, setPage] = useState(1);
    const [srNo, setSrNo] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [entriesPerPage, setEntriesPerPage] = useState(10);
    const [dataList, setDataList] = useState({
        data: [],
        loading: true,
        totalRecords: "",
    });
    const [sea_val, setSea_val] = useState(false);

    const [filterConfig, setFilterConfig] = useState({ searchVal: [], toolinvenId: [] });

    const [constLabel, setConstLabel] = useState({});

    const loginData = useSelector(
        (state) => state.auth?.loginData?.data?.response
    );

    const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });
    const [rmilist, setRMIList] = useState({ data: [], loading: false });
    const [materialTypelist, setMaterialTypelist] = useState({ data: [], loading: false });
    const [rmilistForm, setRMIListForm] = useState({ data: [], loading: false });
    const [docType, setDocType] = useState({ data: [], loading: false });

    const { register, handleSubmit, formState, control, reset, watch, setValue } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
        // defaultValues: { items: [{}] },
    }); // initialise the hook

    const { fields, append, remove } = useFieldArray({ control, name: "items" });
    let { errors } = formState;

    const material_typeChange = watch("rawtype");
    const rmiChange = watch("rmi");
    const [productDetails, setProductDetails] = useState({});

    useEffect(() => {
        setConstLabel(getLabels("Production", "Cutlot"));
        // console.log(getLabels("Cutlot", "Cutlot"));

        const debounceTimeout = setTimeout(() => {
            setPage(1);
            fetchCutlot(page, entriesPerPage);
        }, 1000);

        return () => {
            clearTimeout(debounceTimeout);
        };
    }, [filterConfig]);


    useEffect(() => {
        setPrivilage(setAccessPrivilage(loginData, "Cutlot"));
        setDocType({ ...docType, loading: true, data: [] });
        fetchRMIList();
        fetchMaterialTypelist();
        fetchDoctype();
    }, []);

    useEffect(() => {
        if (material_typeChange && material_typeChange.length > 0) {

            console.log("material_typeChange", material_typeChange);
            setRMIListForm({ ...rmilistForm, data: [], loading: true });

            RMI_list_for_Cutlot({ materialtype: material_typeChange[0].material_type }).then((res) => {
                console.log("res", res);
                const data = res.data.data.map((val) => {
                    if (!val.material_code) {
                        val.material_code = "";
                    }
                    return val
                })
                setRMIListForm({ ...rmilistForm, data: data, loading: false });
            }, (err) => {
                console.log("err", err);
                setRMIListForm({ ...rmilistForm, data: [], loading: false });
            })
        }


    }, [material_typeChange]);


    useEffect(() => {
        if (rmiChange && rmiChange.length > 0) {
            get_product_by_rmi({ toolinvenId: rmiChange[0].toolinvenId }).then((res) => {
                console.log("res", res);

                if (res.data.data.length > 0) {

                    setValue("productName", res.data.data[0].productname);
                    setValue("availableqty", res.data.data[0].acceptyqty);
                    setProductDetails(res.data.data[0]);
                } else {
                    setProductDetails({});
                }
            }, (err) => {
                console.log("err", err);
            })
        }
    }, [rmiChange]);

    function fetchDoctype() {
        get_document_type().then((res) => {

            console.log("res", res);
            setDocType({ ...docType, loading: false, data: res.data.data });
        }, (err) => {
            console.log("err", err);
            setDocType({ ...docType, loading: false, data: [] });
        })
    }

    function fetchRMIList() {
        setRMIList({ ...rmilist, loading: true, data: [] });
        get_rmi().then((res) => {
            console.log("res", res);
            setRMIList({ ...rmilist, loading: false, data: res.data.data });

        }, (err) => {
            console.log("err", err);
            setRMIList({ ...rmilist, loading: false, data: [] });
        });
    }
    function fetchMaterialTypelist() {
        setMaterialTypelist({ ...materialTypelist, data: [], loading: true });
        getMaterialType().then((res) => {
            console.log("res", res);
            setMaterialTypelist({ ...materialTypelist, data: res.data.data, loading: false });
        }, (err) => {
            setMaterialTypelist({ ...materialTypelist, data: [], loading: false });
            console.log("err", err);
        })
    }

    function clearFilter() {
        setFilterConfig({ ...filterConfig, searchVal: [], toolinvenId: [] });
        setSrNo(0);
        setPage(1);
    }

    function fetchCutlot(pg, epp) {
        setDataList({ ...dataList, loading: true, data: [] });

        const payload = {
            pageNo: pg,
            entriesPerPage: epp,
        };

        if (filterConfig.searchVal.length > 0) {
            // payload.productName_list = filterConfig.productName;

            payload.cutlotId = filterConfig.searchVal[0];

            if (
                filterConfig.searchVal[0].length > 0 &&
                page !== 1 &&
                sea_val == false
            ) {
                payload.pageNo = 1;
                payload.entriesPerPage = entriesPerPage;

                setPage(1);
                setSrNo(0);
            }
        } else {
            delete payload.cutlotId;
        }

        if (filterConfig.toolinvenId.length > 0) {
            payload.rmi = filterConfig.toolinvenId[0].toolinvenId
        }

        getCutlot(payload).then(
            (res) => {

                setDataList({
                    ...dataList,
                    loading: false,
                    data: res.data.data,
                    totalRecords: res.data.total,
                });
                if (
                    filterConfig.searchVal.length > 0 &&
                    filterConfig.searchVal[0].length > 0
                ) {
                    setSea_val(true);
                } else {
                    setSea_val(false);
                }
                setMaxPage(calMaxPage_new(res.data.total, epp));
                if (pg == 1) {
                    setSrNo(0);
                    setPage(1);
                }
            },
            (err) => {

                setDataList({ ...dataList, loading: false, data: [] });
            }
        );
    }


    function convertBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = reject;
            if (file) {
                reader.readAsDataURL(file);
            } else {
                reject(new Error("File is empty"));
            }
        });
    }

    const onSubmit = async (data) => {

        console.log("data", data);
        console.log("productDetails", productDetails);

        if (data !== "") {
            const reqPayload = {
                "rawtype": data.rawtype[0].material_type,
                "rmi": data.rmi[0].toolinvenId,
                "semiproductid": "",
                "productId": productDetails.actualprodid,
                "qty": data.qty,
                "processedqty": data.qty,
                "destructivetestingqty": "0",
                "productName": productDetails.productname,
                "cutpiece_qty": data.cutpiece_qty,
                "cutpiece_length": data.cutpiece_length,
                "availqty": data.availableqty
                // availqty is the limit
            }

            const imageData = [];
            if (data.items !== null && data.items?.length > 0) {
                for (let i = 0; i < data.items?.length; i++) {
                    const getExt = data.items[i].image[0].name?.split('.').pop();

                    let dataimage = await convertBase64(data.items[i].image[0]);
                    let newImage = dataimage.replace(/data:image\/[a-zA-Z]+;/, "data:image/" + getExt + ";");

                    imageData.push({
                        doc_type: data.items[i].doc_type[0].name,
                        image: newImage,
                        filename: data.items[i].image[0].name,
                    });
                }
            }

            if (imageData.length > 0) {
                reqPayload.imagelist = imageData;
            }

            console.log("imageData", imageData);
            createCutlot(reqPayload).then((res) => {
                console.log("res", res);
                toast.success("Cutlot created Successfully !", {
                    autoClose: 3000,
                });
                setVisible(false);
                fetchCutlot(page, entriesPerPage);
            }, (err) => {
                console.log("err", err);
                if (err.response.status === 400) {
                    toast.error(err.response.data.message, {
                        autoClose: 2000,
                    });

                }
            })

        } else {
            errors.showMessages();
        }
    };

    // Deleting customer type
    const delRecord = (val) => {

        SweetAlert.fire({
            title: "Are you sure?",
            text: "Once deleted, you will not be able to recover this record !",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Ok",
            cancelButtonText: "cancel",
            reverseButtons: true,
        }).then((result) => {
            if (result.value) {
                // Firing delete api
                const payload = {
                    uuid: val.uuid,
                };
                deleteCutlot(payload).then(
                    (res) => {

                        toast.success("Cutlot deleted successfully !", {
                            autoClose: 3000,
                        });
                        if (dataList.data.length == 1 && page > 1) {
                            fetchCutlot(page - 1, entriesPerPage);
                            setSrNo(parseInt(srNo - entriesPerPage));
                            setPage(page - 1);
                        } else {
                            fetchCutlot(page, entriesPerPage);
                        }
                    },
                    (err) => {

                        toast.error("Something went wrong !", { autoClose: 3000 });
                    }
                );
            }
        });
    };

    function openNav() {
        document.getElementById("mySidebar").style.width = "320px";
        document.getElementById("root_div_main").style.marginLeft = "320px";
        setTimeout(() => {
            document.getElementById("mySidebar").style.overflowX = "visible";
        }, [200]);
    }

    function closeNav() {
        document.getElementById("mySidebar").style.width = "0";
        document.getElementById("root_div_main").style.marginLeft = "0";

        document.getElementById("mySidebar").style.overflowX = "hidden";
    }

    function handleClick(ind) {


        setShowLabel((showLabel) => {
            const updatedCardShow = showLabel.map((val, i) => {
                if (ind === i) {
                    // Toggle the flag for the clicked checkbox
                    return { ...val, flag: !val.flag };
                }
                return val;
            });

            return updatedCardShow;
        });
    }



    return (
        <React.Fragment>
            <ToastContainer />

            {galleryModal.mode && <CutlotGallery galleryModal={galleryModal} setGalleryModal={setGalleryModal} />}

            {!privilage.read ?
                <AccessDenied />
                :
                <div className="min-vh-100" id="root_div_main">
                    <Modal show={visible} onHide={() => setVisible(false)} size={"lg"}>
                        <Modal.Header
                            style={{ background: "#2A3643", color: "white" }}
                            closeButton
                        >
                            <Modal.Title>{constLabel?.lbl_add_cutlot ? constLabel.lbl_add_cutlot : "Add Cutlot"}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>

                            <div className='row'>
                                <div className='col-12 col-md-6'>
                                    <div className="form-group mt-2">
                                        <label style={{ fontSize: "14px" }} className="col-form-label  roboto-bold">
                                            {constLabel?.lbl_material_type ? constLabel.lbl_material_type : "~ Material Type"}
                                            <span className="text-danger">*</span>
                                        </label>

                                        <Controller
                                            name="rawtype"
                                            control={control}
                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <Typeahead
                                                    id="basic-typeahead-single"
                                                    labelKey="material_type"
                                                    multiple={false}

                                                    options={materialTypelist.data}
                                                    isLoading={materialTypelist.loading}
                                                    placeholder="Select Material Type"
                                                    onChange={onChange}
                                                    onBlur={onBlur}
                                                    selected={value}
                                                    isInvalid={errors.rawtype ? true : false}
                                                />
                                            )}
                                        />

                                        <span className="text-danger err-msg">
                                            {errors.rawtype?.message}
                                        </span>
                                    </div>
                                </div>

                                {material_typeChange && material_typeChange.length > 0 &&
                                    <div className='col-12 col-md-6'>
                                        <div className="form-group mt-2">
                                            <label style={{ fontSize: "14px" }} className="col-form-label  roboto-bold">
                                                {constLabel?.lbl_materialcode ? constLabel.lbl_materialcode : "~ Material Code"}
                                                <span className="text-danger">*</span>
                                            </label>

                                            <Controller
                                                name="rmi"
                                                control={control}
                                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                                    <Typeahead
                                                        id="basic-typeahead-single"
                                                        labelKey="material_code"
                                                        multiple={false}
                                                        options={rmilistForm.data}
                                                        isLoading={rmilistForm.loading}
                                                        placeholder="Select Material Code"
                                                        onChange={onChange}
                                                        onBlur={onBlur}
                                                        selected={value}
                                                        isInvalid={errors.rmi ? true : false}
                                                    />
                                                )}
                                            />

                                            <span className="text-danger err-msg">
                                                {errors.rmi?.message}
                                            </span>
                                        </div>
                                    </div>
                                }

                                {material_typeChange && material_typeChange.length > 0 &&
                                    <div className='col-12 col-md-6'>
                                        <div className="form-group mt-2">
                                            <label style={{ fontSize: "14px" }} className="col-form-label  roboto-bold">
                                                {constLabel?.lbl_productname ? constLabel.lbl_productname : "~ Product Name"}
                                                <span className="text-danger">*</span>
                                            </label>

                                            <input
                                                type="text"
                                                className={`form-control ${errors.productName ? "is-invalid" : null
                                                    }`}
                                                {...register("productName")}
                                                disabled
                                            />

                                            <span className="text-danger err-msg">
                                                {errors.productName?.message}
                                            </span>
                                        </div>
                                    </div>
                                }


                                <div className='col-12 col-md-6'>
                                    <div className="form-group mt-2">
                                        <label style={{ fontSize: "14px" }} className="col-form-label  roboto-bold">
                                            {constLabel?.lbl_available_qty ? constLabel.lbl_available_qty : "~ Available Qty"}
                                            <span className="text-danger">*</span>
                                        </label>

                                        <input
                                            type="text"
                                            className={`form-control ${errors.availableqty ? "is-invalid" : null
                                                }`}
                                            {...register("availableqty")}
                                            disabled
                                        />

                                        <span className="text-danger err-msg">
                                            {errors.qty?.availableqty}
                                        </span>
                                    </div>
                                </div>
                                <div className='col-12 col-md-6'>
                                    <div className="form-group mt-2">
                                        <label style={{ fontSize: "14px" }} className="col-form-label  roboto-bold">
                                            {constLabel?.lbl_quantity ? constLabel?.lbl_quantity : "~ Quantity"}
                                            <span className="text-danger">*</span>
                                        </label>

                                        <input
                                            type="text"
                                            className={`form-control ${errors.qty ? "is-invalid" : null
                                                }`}
                                            placeholder='Enter quantity'
                                            {...register("qty")}

                                        />

                                        <span className="text-danger err-msg">
                                            {errors.qty?.message}
                                        </span>
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className="form-group mt-2">
                                        <label style={{ fontSize: "14px" }} className="col-form-label  roboto-bold">
                                            {constLabel?.lbl_cutpiece_qty ? constLabel?.lbl_cutpiece_qty : "~ Cut piece qty"}
                                            <span className="text-danger">*</span>
                                        </label>

                                        <input
                                            type="text"
                                            className={`form-control ${errors.cutpiece_qty ? "is-invalid" : null
                                                }`}
                                            placeholder='Enter cutpiece quantity'
                                            {...register("cutpiece_qty")}
                                        />

                                        <span className="text-danger err-msg">
                                            {errors.cutpiece_qty?.message}
                                        </span>
                                    </div>
                                </div>

                                <div className='col-12 col-md-6'>
                                    <div className="form-group mt-2">
                                        <label style={{ fontSize: "14px" }} className="col-form-label  roboto-bold">
                                            {constLabel?.lbl_cutpiece_length ? constLabel?.lbl_cutpiece_length : "~ Cut piece length"}
                                            <span className="text-danger">*</span>
                                        </label>

                                        <input
                                            type="text"
                                            className={`form-control ${errors.cutpiece_length ? "is-invalid" : null
                                                }`}
                                            placeholder='Enter cutpiece length'
                                            {...register("cutpiece_length")}
                                        />

                                        <span className="text-danger err-msg">
                                            {errors.cutpiece_length?.message}
                                        </span>
                                    </div>
                                </div>


                                {fields.length == 0 ?
                                    <div className='col-12 d-flex justify-content-end'>
                                        <button
                                            style={{ fontSize: "16px" }}
                                            className="btn btn-sm button-primary px-2 mt-2"
                                            onClick={() => {
                                                if (fields.length < 2) {
                                                    append({});
                                                } else {
                                                    toast.error("Maximum 2 images allowed", {
                                                        autoClose: 2000,
                                                    });
                                                }
                                            }}
                                        >
                                            <FontAwesomeIcon
                                                style={{
                                                    color: "#ffffff",
                                                }}
                                                icon={faAdd}
                                                size="sm"
                                            />{constLabel?.lbl_add_image ? constLabel?.lbl_add_image : "Add Image"}
                                        </button>
                                    </div>
                                    : null}

                                {fields.map(({ id }, index) => (
                                    <div className="py-2 mt-3 table-responsive" key={id}>
                                        <table className="table table-bordered">
                                            <thead className="table-grey roboto-bold">
                                                <tr>
                                                    <th scope="col" width="40%">
                                                        {constLabel?.lbl_select_image
                                                            ? constLabel.lbl_select_image
                                                            : "Select Image"}
                                                    </th>
                                                    <th scope="col" width="40%">
                                                        {constLabel?.lbl_select_doc_type
                                                            ? constLabel.lbl_select_doc_type
                                                            : "Select Doc Type"}
                                                    </th>

                                                    <th scope="col" width="20%">
                                                        {"Action"}
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                <tr>


                                                    <td style={{ verticalAlign: "top" }}>
                                                        <input
                                                            className={
                                                                errors.items?.[index]?.image
                                                                    ? "form-control form-control-sm is-invalid"
                                                                    : "form-control form-control-sm"
                                                            }
                                                            type="file"
                                                            accept="image/*"
                                                            name={`items[${index}].image`}
                                                            {...register(`items[${index}].image`)}
                                                            // onChange={(e) => handleFileChange(e, index)}
                                                            placeholder="Please select image"
                                                        />

                                                        {errors.items?.[index]?.image && (
                                                            <span className="text-danger err-msg">
                                                                {errors.items?.[index]?.image.message}
                                                            </span>
                                                        )}

                                                        {index + 1 == fields.length &&
                                                            <button
                                                                style={{ fontSize: "16px" }}
                                                                className="btn btn-sm button-primary px-2 mt-2"
                                                                onClick={() => {
                                                                    if (fields.length < 2) {
                                                                        append({});
                                                                    } else {
                                                                        toast.error("Maximum 2 images allowed", {
                                                                            autoClose: 2000,
                                                                        });
                                                                    }
                                                                }}
                                                            >
                                                                <FontAwesomeIcon
                                                                    style={{
                                                                        color: "#ffffff",
                                                                    }}
                                                                    icon={faAdd}
                                                                    size="sm"
                                                                />{constLabel?.lbl_add_image ? constLabel?.lbl_add_image : "Add Image"}
                                                            </button>
                                                        }
                                                    </td>

                                                    <td style={{ verticalAlign: "top" }}>
                                                        <Controller
                                                            name={`items[${index}].doc_type`}
                                                            control={control}
                                                            render={({ field: { onChange, onBlur, value, ref } }) => (
                                                                <Typeahead
                                                                    id="basic-typeahead-single"
                                                                    labelKey="name"
                                                                    multiple={false}
                                                                    size='sm'
                                                                    options={docType.data}
                                                                    isLoading={docType.loading}
                                                                    placeholder="Select doc Type"
                                                                    onChange={onChange}
                                                                    onBlur={onBlur}
                                                                    selected={value}
                                                                    isInvalid={errors.items?.[index]?.doc_type ? true : false}
                                                                />
                                                            )}
                                                        />

                                                        {errors.items?.[index]?.doc_type && (
                                                            <span className="text-danger err-msg">
                                                                {errors.items?.[index]?.doc_type.message}
                                                            </span>
                                                        )}
                                                    </td>

                                                    <td
                                                        style={{
                                                            verticalAlign: "top",
                                                        }}
                                                    >
                                                        <div
                                                            className="deleteIcon cursor_pointer"
                                                            onClick={
                                                                () => {
                                                                    remove(index);
                                                                }
                                                            }
                                                        >
                                                            <Trash2 color="#FFFFFF" size={18} />
                                                        </div>
                                                    </td>
                                                </tr>


                                            </tbody>
                                        </table>
                                    </div>
                                ))}
                            </div>


                        </Modal.Body>
                        <Modal.Footer>
                            <button
                                onClick={handleSubmit(onSubmit)}
                                className="f-16 btn btn-yellow "
                            >
                                {constLabel?.lbl_save ? constLabel?.lbl_save : "Save"}
                            </button>
                        </Modal.Footer>
                    </Modal>
                    <div id="mySidebar" className="customsidebar ">
                        <a className="closebtn" onClick={() => closeNav()}>
                            ×
                        </a>

                        <div className=" content">
                            <div className="filter row">
                                <label
                                    className="filterLabel mb-2 roboto-bold"
                                    style={{ fontSize: "14px" }}
                                >
                                    {constLabel?.lbl_cutlot_id ? constLabel?.lbl_cutlot_id : "Cutlot Id"}
                                </label>
                                <div className="col-12">
                                    <input
                                        type="text"
                                        id="searchField"
                                        className="form-control form-control-sm"
                                        placeholder="Search by cutlot id"
                                        value={
                                            filterConfig.searchVal.length > 0
                                                ? filterConfig.searchVal
                                                : ""
                                        }
                                        onChange={(val) => {
                                            const inputValue = val.target.value; // Trim leading and trailing whitespaces

                                            if (inputValue.trim() !== "") {
                                                setFilterConfig({
                                                    ...filterConfig,
                                                    searchVal: [inputValue],
                                                });
                                            }
                                        }}
                                    />
                                </div>
                            </div>

                            <div>
                                <label
                                    className="filterLabel my-2 roboto-bold"
                                    style={{ fontSize: "14px" }}
                                >
                                    {constLabel?.lbl_selRMI
                                        ? constLabel.lbl_selRMI
                                        : "Select RMI"}
                                </label>

                                <Typeahead
                                    id="basic-typeahead-single"
                                    labelKey="toolinvenId"
                                    name="rmi"
                                    multiple={false}
                                    size="sm"
                                    options={rmilist.data}
                                    isLoading={rmilist.loading}
                                    selected={filterConfig.toolinvenId}
                                    onChange={(val) => {
                                        if (val.length > 0) {

                                            setFilterConfig({ ...filterConfig, toolinvenId: val });
                                        } else {
                                            setFilterConfig({ ...filterConfig, toolinvenId: [] });
                                        }
                                    }}
                                    placeholder="Select RMI Id"
                                />

                            </div>

                            <div className="my-3 d-flex justify-content-end">
                                <button className="btn btn-sm btn-yellow" onClick={() => clearFilter()}>
                                    <FontAwesomeIcon
                                        style={{ color: "#344454" }}
                                        icon={faBan}
                                        size="sm"
                                    />{" "}
                                    {constLabel?.lbl_clear_filter ? constLabel?.lbl_clear_filter : "Clear Filter"}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="content-wrapper">
                        <div className="d-flex justify-content-between align-items-center">
                            <h1 className="bold">{constLabel?.lbl_cut_lot ? constLabel?.lbl_cut_lot : "~Cut Lot"}</h1>
                            <div>
                                <span
                                    className="cursor_pointer px-3 me-0 me-md-2"
                                    onClick={() => {
                                        openNav();
                                    }}
                                >
                                    <FontAwesomeIcon
                                        style={{ marginRight: "5px" }}
                                        icon={faFilter}
                                        size="sm"
                                    />
                                    {constLabel?.lbl_filter_menu ? constLabel?.lbl_filter_menu : "~Filter Menu"}
                                </span>

                                {privilage.write &&
                                    <button
                                        className="btn button-primary px-4"
                                        onClick={() => {
                                            setVisible(true);
                                            reset();
                                        }}
                                    >
                                        <FontAwesomeIcon
                                            style={{ color: "#fff" }}
                                            icon={faPlus}
                                            size="sm"
                                        />{" "}
                                        {constLabel?.lbl_add_cutlot ? constLabel?.lbl_add_cutlot : "~Add Cutlot"}
                                    </button>
                                }
                            </div>
                        </div>
                        <div className="card border-0 mt-3">
                            <div className="card-body">
                                <div className="d-flex justify-content-between align-items-center mt-1">

                                    <p className="mb-0">
                                        Show
                                        <select
                                            className="mx-1"
                                            defaultValue={"10"}
                                            onChange={(e) => {
                                                setEntriesPerPage(e.target.value);
                                                fetchCutlot(1, e.target.value);
                                            }}
                                        >
                                            <option value={"10"}>10</option>
                                            <option value="25">25</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                        Entries
                                    </p>
                                    <div className="dropdown">


                                        <div
                                            className="settignIcon_custom_nc d-flex justify-content-center align-items-center"
                                            type="button"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                        >
                                            <FontAwesomeIcon
                                                style={{ color: "#fff" }}
                                                icon={faCog}
                                                size="sm"
                                            />
                                        </div>

                                        <ul className="dropdown-menu px-2">
                                            {showLabel !== undefined && showLabel.length > 0
                                                ? showLabel.map((val, ind, arr) => (
                                                    <li key={ind}>
                                                        <div className="form-check">
                                                            <input
                                                                className="form-check-input"
                                                                type="checkbox"
                                                                style={{ width: "20px", height: "20px" }}
                                                                onChange={() => handleClick(ind)}
                                                                value=""
                                                                checked={val.flag}
                                                            />
                                                            <label
                                                                className=""
                                                                style={{ fontSize: "13px" }}
                                                                htmlFor="flexCheckDefault"
                                                            >
                                                                {val.label}
                                                            </label>
                                                        </div>
                                                    </li>
                                                ))
                                                : null}
                                        </ul>
                                    </div>



                                </div>
                                <div className="mt-3">
                                    {dataList.loading ? (
                                        <div className="text-center">
                                            <div className="spinner-border" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    ) : dataList && dataList.data?.length > 0 ? (
                                        <>
                                            <div className="table-responsive">
                                                <table
                                                    className="table mt-1 table-bordered table-sm"
                                                    width={"100%"}
                                                >
                                                    <thead className="table-grey roboto">
                                                        <tr className="f-14 ">
                                                            <th scope="col">
                                                                {"Sr. No"}
                                                            </th>
                                                            {showLabel.map((v, i) =>
                                                                v.label === "Cutlot Id" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_cutlot ? constLabel.lbl_cutlot : "~Cutlot Id"}</th>) :
                                                                    v.label === "Material Code" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_materialcode ? constLabel.lbl_materialcode : "~Material Code"}</th>) :
                                                                        v.label === "Raw Material Id" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_rawmaterialid ? constLabel.lbl_rawmaterialid : "~Raw Material Id"}</th>) :
                                                                            v.label === "Type" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_type ? constLabel.lbl_type : "~Type"}</th>) :
                                                                                v.label === "Product Name" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_productname ? constLabel.lbl_productname : "~Product Name"}</th>) :
                                                                                    v.label === "Cutlot Available" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_available_qty ? constLabel.lbl_available_qty : "~Cutlot Available Qty"}</th>) :
                                                                                        v.label === "Processed Qty" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_processed_qty ? constLabel.lbl_processed_qty : "~Processed Qty"}</th>) :
                                                                                            v.label === "Cut piece qty" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_cutpiece_qty ? constLabel.lbl_cutpiece_qty : "~Cut piece qty"}</th>) :
                                                                                                v.label === "Cut piece length" && v.flag ? (<th key={i} scope="col">{constLabel?.lbl_cutpiece_length ? constLabel.lbl_cutpiece_length : "~Cut piece length"}</th>) :
                                                                                                    null
                                                            )}
                                                            <th scope="col" style={{ width: '15%' }}>
                                                                {"Action"}
                                                            </th>
                                                        </tr>
                                                    </thead>
                                                    <tbody className="roboto">
                                                        {dataList.data?.map((val, i) => (
                                                            <tr key={i} className="">
                                                                <td>{i + parseInt(srNo) + 1}</td>
                                                                {showLabel.map((item, idx) =>
                                                                    item.label === "Cutlot Id" && item.flag ? (<td key={idx}>{val.cutlotId != "" && val.cutlotId !== null ? splitString(val.cutlotId) : "NA"}</td>) :
                                                                        item.label === "Material Code" && item.flag ? (<td key={idx}>{val.RMIDetails?.material_code != "" && val.RMIDetails?.material_code !== null ? val.RMIDetails?.material_code : "NA"}</td>) :
                                                                            item.label === "Raw Material Id" && item.flag ? (<td key={idx}>{val.rmi != "" && val.rmi !== null ? splitString(val.rmi) : "NA"}</td>) :
                                                                                item.label === "Type" && item.flag ? (<td key={idx}>{val.rawtype != "" && val.rawtype !== null ? val.rawtype : "NA"}</td>) :
                                                                                    item.label === "Product Name" && item.flag ? (<td key={idx}>{val.productInfo?.productName != "" && val.productInfo?.productName !== null ? val.productInfo?.productName : "NA"}</td>) :
                                                                                        item.label === "Cutlot Available" && item.flag ? (<td key={idx}>{val.qty != "" && val.qty !== null ? val.qty : "NA"}</td>) :
                                                                                            item.label === "Processed Qty" && item.flag ? (<td key={idx}>{val.processedqty != "" && val.processedqty !== null ? val.processedqty : "NA"}</td>) :
                                                                                                item.label === "Cut piece qty" && item.flag ? (<td key={idx}>{val.cutpiece_qty != "" && val.cutpiece_qty !== null ? val.cutpiece_qty : "NA"}</td>) :
                                                                                                    item.label === "Cut piece length" && item.flag ? (<td key={idx}>{val.cutpiece_length != "" && val.cutpiece_length !== null ? val.cutpiece_length : "NA"}</td>) :
                                                                                                        null
                                                                )}
                                                                <td>

                                                                    {privilage.write &&
                                                                        <EditCutlot
                                                                            data={val}
                                                                            constLabel={constLabel}
                                                                            fetchCutlot={fetchCutlot}
                                                                            page={page}
                                                                            entriesPerPage={entriesPerPage}
                                                                            materialTypelist={materialTypelist}
                                                                            docType={docType}
                                                                        />
                                                                    }

                                                                    {privilage.delete &&
                                                                        <>
                                                                            <Tooltip id={"delete-tooltip" + i} place="top" />
                                                                            <div
                                                                                data-tooltip-id={"delete-tooltip" + i}
                                                                                data-tooltip-content={constLabel?.lbl_delete_cutlot ? constLabel.lbl_delete_cutlot : "~Delete Cutlot"}
                                                                                className="deleteIcon me-2"
                                                                                onClick={() => delRecord(val)}
                                                                            >
                                                                                <Trash2 color="#FFFFFF" size={18} />
                                                                            </div>
                                                                        </>
                                                                    }

                                                                    {privilage.read &&
                                                                        <>
                                                                            <Tooltip id={"gallery-tooltip" + i} place="top" />
                                                                            <div
                                                                                data-tooltip-id={"gallery-tooltip" + i}
                                                                                data-tooltip-content={constLabel?.lbl_cut_lot_gallery ? constLabel.lbl_cut_lot_gallery : "~Cutlot Gallery"}
                                                                                className="pdrIcon me-2"
                                                                                onClick={() => setGalleryModal({ ...galleryModal, mode: true, data: val })}
                                                                            >
                                                                                <Image color="#FFFFFF" size={18} />
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="d-flex justify-content-between align-items-center mt-2">
                                                <p className="mb-0">{`Showing ${parseInt(srNo) + 1
                                                    } to ${Math.min(
                                                        parseInt(entriesPerPage) + parseInt(srNo),
                                                        dataList.totalRecords
                                                    )} of ${dataList.totalRecords} entries`}</p>
                                                <Pagination className="my-2">
                                                    <Pagination.Prev
                                                        disabled={page === 1 ? true : false}
                                                        onClick={() => {
                                                            fetchCutlot(
                                                                page - 1,
                                                                entriesPerPage,
                                                                parseInt(srNo) - parseInt(entriesPerPage)
                                                            );
                                                            setSrNo((prevC) =>
                                                                page - 1 == 1
                                                                    ? 0
                                                                    : prevC - parseInt(entriesPerPage)
                                                            );
                                                            setPage(page - 1);
                                                        }}
                                                    >
                                                        {"Prev"}
                                                    </Pagination.Prev>

                                                    <Pagination.Item active>{page}</Pagination.Item>

                                                    <Pagination.Next
                                                        disabled={
                                                            page === maxPage ||
                                                                maxPage === 0 ||
                                                                entriesPerPage > dataList.data.length
                                                                ? true
                                                                : false
                                                        }
                                                        onClick={() => {
                                                            fetchCutlot(
                                                                page + 1,
                                                                entriesPerPage,
                                                                parseInt(srNo) + parseInt(entriesPerPage)
                                                            );
                                                            setSrNo(
                                                                (prevC) => prevC + parseInt(entriesPerPage)
                                                            );
                                                            setPage(page + 1);
                                                        }}
                                                    >
                                                        {"Next"}
                                                    </Pagination.Next>
                                                </Pagination>
                                            </div>
                                        </>
                                    ) : (
                                        <p className="text-danger mb-0 f-22 text-center mt-4">
                                            {"Cutlot not found !"}
                                        </p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </React.Fragment>
    )
}

export default Cutlot
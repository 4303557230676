import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useLocation, useNavigate } from "react-router-dom";
import { Accordion, Modal, Spinner } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import SweetAlert from "sweetalert2";
import {
  getProdCategory,
  getCustomer,
  getProduct_Family,
  getUOM,
  saveProduct,
  getBOM,
  deleteBOM,
} from "../../../../api";
import { Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";

const schema = yup
  .object()
  .shape({
    productName: yup.string().required("Product name is required !"),
    hsn: yup
      .string()
      .matches(/^[a-zA-Z0-9]*$/, "Enter valid hsn")
      .nullable(true),
    unit: yup
      .array()
      .min(1, "Please select unit !")
      .required("Unit is required !"),
    openingStock: yup
      .string()
      .matches(/^[0-9]+$/, 'Enter valid opening stock')
      .nullable(true),
    machineCost: yup
      .string()
      .matches(/^\d+(\.\d+)?$/, "Enter valid cost ")
      .nullable(true),
    salesPrice: yup
      .string()
      .matches(/^\d+(\.\d+)?$/, "Enter valid sales price ")
      .nullable(true),
    selling_price: yup
      .string()
      .required("Selling price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid selling price"),
    purchase_price: yup
      .string()
      .required("Purchase price is required !")
      .matches(/^\d+(\.\d+)?$/, "Enter valid purchase price"),
    can_be_sold: yup
      .string()
      .required("Required"),
    can_be_purchased: yup
      .string()
      .required("Required"),
    can_be_expensed: yup
      .string()
      .required("Required"),
    // has_bom: yup
    //   .string()
    //   .required("Required"),
  })
  .required();

function CloneSemiFinishGoods(props) {
  let navigate = useNavigate();
  let location = useLocation();

  const [prodCategory, setProdCategory] = useState({
    data: [],
    loading: false,
  });
  const [prodFamily, setProdFamily] = useState({ data: [], loading: false });
  const [partyList, setPartyList] = useState({ data: [], loading: false });
  const [uomList, setUOMList] = useState({ data: [], loading: false });
  const [bomList, setBomList] = useState({ data: [], loading: false });
  const [particularBomList, setParticularBomList] = useState({ data: [], loading: false });
  const [createBom, setCreateBom] = useState(false);
  const [modal, setModal] = useState(false);
  const [btnDisable, setBtnDisable] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  useEffect(() => {
    fetchProductCategory();
    fetchProductFamily();
    fetchParty();
    fetchUOM();
    fetchBoms();
  }, []);

  function fetchUOM() {
    setUOMList({ ...uomList, data: [], loading: true });
    getUOM({pageNo:1,entriesPerPage:100}).then(
      (res) => {
        
        setUOMList({ ...uomList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setUOMList({ ...uomList, data: [], loading: false });
      }
    );
  }

  function fetchBoms() {
    setBomList({ ...bomList, data: [], loading: true });

    getBOM().then(
      (res) => {
        
        setBomList({ ...bomList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setBomList({ ...bomList, data: [], loading: false });
      }
    );
  }

  function fetchProductCategory() {
    setProdCategory({ ...prodCategory, data: [], loading: true });
    getProdCategory().then(
      (res) => {
        
        setProdCategory({
          ...prodCategory,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setProdCategory({ ...prodCategory, data: [], loading: false });
      }
    );
  }

  function fetchProductFamily() {
    setProdFamily({ ...prodFamily, data: [], loading: true });
    getProduct_Family().then(
      (res) => {
        
        setProdFamily({
          ...prodFamily,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setProdFamily({ ...prodFamily, data: [], loading: false });
      }
    );
  }

  function fetchParty() {
    setPartyList({ ...partyList, data: [], loading: true });
    getCustomer({partyType: "Customer"}).then(
      (res) => {
        
        const result = res.data.data.filter((item) => item.name !== null);
        setPartyList({ ...partyList, data: result, loading: false });
      },
      (err) => {
        
        setPartyList({ ...partyList, data: [], loading: false });
      }
    );
  }

  function fetchParticularBoms() {
    setParticularBomList({ ...particularBomList, data: [], loading: true });
    getBOM({ product_id: location.state.uuid }).then(
      (res) => {
        
        setParticularBomList({ ...particularBomList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setParticularBomList({ ...particularBomList, data: [], loading: false });
      }
    );
  }

  // const onSubmit = (data) => {
  //   props.setCurrentStep(props.currentStep + 1);
  // };

  const onSubmit = (data) => {
    
    setBtnDisable(true);

    if (data !== "") {
      const reqPayload = {
        productType: props.product_type[0]?.prodTypeId,
        productTypename: props.product_type[0]?.productType,
        product_type_id: props.product_type[0]?.uuid,
        category: data.category ? data.category[0]?.categoryname : "",
        prodfamily: data.productFamily && data.productFamily.length > 0 ? data.productFamily[0]?.familyId : "",
        prod_family_id: data.productFamily && data.productFamily.length > 0 ? data.productFamily[0]?.uuid : "",
        productName: data.productName,
        openingstock: data.openingStock,
        hsn: data.hsn,
        // has_bom: data.has_bom,
        machineCost: data.machineCost,
        salesPrice: data.salesPrice,
        can_be_sold: data.can_be_sold,
        can_be_purchased: data.can_be_purchased,
        can_be_expensed: data.can_be_expensed,
        beacon_id: data.beacon_id,
        qr_code_id: data.qr_code_id,
        selling_price: data.selling_price,
        purchase_price: data.purchase_price,
        unit_id: data.unit ? data.unit[0]?.uuid : "",
        remark: data.remark,
        party: [],
      };

      

      saveProduct(reqPayload)
        .then(
          (res) => {
            setBtnDisable(false);

            
            if (res.status === 200 || res.status === 201) {
              toast.success("Semi finish goods product saved successfully !", {
                autoClose: 3000,
              });

              localStorage.setItem(
                "prod_res",
                JSON.stringify(res.data.product)
              );
              props.setProductDataLocal(res.data.product);
              props.setCurrentStep((prevStep) => prevStep + 1);
              if (createBom == true) {
                props.setBomStat(true);
              }
            }
          },
          (err) => {
            
            setBtnDisable(false);
            if (err.response.status === 400) {
              
              toast.error(err.response.data.non_field_errors[0], {
                autoClose: 2000,
              });
              reset();
            }
          }
        )

    } else {
      errors.showMessages();
    }
  };

  function delete_bom(val) {
    
    SweetAlert.fire({
      title: "Are you sure to delete \n" + val.bom_type + " ?",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteBOM({ uuid: val.uuid }).then((res) => {
          
          toast.error("Bill of material deleted successfully !", {
            autoClose: 2000,
          });
          fetchParticularBoms();
        }, (err) => {

          
          toast.error("Something went wrong !", {
            autoClose: 2000,
          });
        })
      }
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <p className="pc_card_title roboto-bold">Product Summary</p>
          <div className="row">
            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{"Category"}</label>
                <Controller
                  name="category"
                  control={control}
                  defaultValue={[{ categoryname: props.data?.category, uuid: props.data?.category_id }]}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      defaultSelected={[{ categoryname: props.data?.category, uuid: props.data?.category_id }]}
                      labelKey="categoryname"
                      className="mt-2"
                      multiple={false}
                      placeholder="Select product category"
                      options={prodCategory.data}
                      isLoading={prodCategory.loading}
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {"Product Family"}
                </label>
                <Controller
                  name="productFamily"
                  control={control}
                  defaultValue={props.data.prod_family_id !== null ? [props.data.prod_family_id] : []}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      defaultSelected={props.data.prod_family_id !== null ? [props.data.prod_family_id] : []}
                      labelKey="familyname"
                      placeholder="Select product family"
                      className="mt-2"
                      multiple={false}
                      options={prodFamily.data}
                      isLoading={prodFamily.loading}
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {"Product Name"}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.productName
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  defaultValue={props.data?.productName !== null ? props.data?.productName : ""}
                  name="productName"
                  placeholder="Enter product name"
                  {...register("productName")}
                />
                {errors.productName && (
                  <span className="text-danger err-msg">
                    {errors.productName.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {"Opening Stock"}
                </label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter opening stock"
                  defaultValue={props.data?.openingstock !== null ? props.data?.openingstock : 0}
                  name="openingStock"
                  {...register("openingStock")}
                />
                {errors.openingStock && (
                  <span className="text-danger err-msg">
                    {errors.openingStock.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{"HSN"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  placeholder="Enter hsn"
                  defaultValue={props.data?.hsn !== null ? props.data?.hsn : ""}
                  name="hsn"
                  {...register("hsn")}
                />
                {errors.hsn && (
                  <span className="text-danger err-msg">
                    {errors.hsn.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">
                  {"Unit"}
                  <span className="text-danger">*</span>
                </label>
                <Controller
                  name="unit"
                  control={control}
                  defaultValue={props.data?.unit_id !== null ? [props.data?.unit_id] : []}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      defaultSelected={props.data?.unit_id !== null ? [props.data?.unit_id] : []}
                      labelKey="uom"
                      className="mt-2"
                      multiple={false}
                      options={uomList.data}
                      isLoading={uomList.loading}
                      placeholder="Select unit"
                      onChange={onChange}
                      selected={value}
                      onBlur={onBlur}
                      isInvalid={errors.unit ? true : false}
                    />
                  )}
                />

                {errors.unit && (
                  <span className="text-danger err-msg">
                    {errors.unit.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <label className="lbl-style roboto-bold invisible">
                {"Unit"}
                <span className="text-danger">*</span>
              </label>
              <div className="form-check mt-4">
                <input
                  className="form-check-input"
                  type="checkbox"

                  name="has_bom"
                  onChange={(e) => {
                    setCreateBom(e.target.checked);
                    localStorage.setItem('bomStatus', e.target.checked);
                  }}
                  checked={createBom}
                // {...register("has_bom")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  Create BOM
                </label>
              </div>
            </div>
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">Product Details</p>
          <div className="row col-12">
            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {"Can be sold"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={props.data.can_be_sold !== null ? props.data.can_be_sold : false}
                  name="can_be_sold"
                  {...register("can_be_sold")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  Can be Sold
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {"Can be purchased"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={props.data.can_be_purchased !== null ? props.data.can_be_purchased : false}
                  name="can_be_purchased"
                  {...register("can_be_purchased")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  Can be Purchase
                </label>
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <label className="lbl-style roboto-bold invisible">
                {"Can be expensed"}
              </label>
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="checkbox"
                  defaultChecked={props.data.can_be_expensed !== null ? props.data.can_be_expensed : false}
                  name="can_be_expensed"
                  {...register("can_be_expensed")}
                />
                <label className="form-check-label lbl-style roboto-bold">
                  Can be Expense
                </label>
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{"Beacon Id"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  defaultValue={props.data.beacon_id !== null ? props.data.beacon_id : ""}
                  name="beacon_id"
                  placeholder="Enter BeaconId"
                  {...register("beacon_id")}
                />
                {errors.beacon_id && (
                  <span className="text-danger err-msg">
                    {errors.beacon_id.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group mt-md-0 mt-3">
                <label className="lbl-style roboto-bold">{"QR Code Id"}</label>
                <input
                  className="form-control mt-2"
                  type="text"
                  defaultValue={props.data.qr_code_id !== null ? props.data.qr_code_id : ""}
                  name="qr_code_id"
                  placeholder="Enter QRCodeId"
                  {...register("qr_code_id")}
                />
                {errors.qr_code_id && (
                  <span className="text-danger err-msg">
                    {errors.qr_code_id.message}
                  </span>
                )}
              </div>
            </div>
          </div>

          <hr className="my-4" />
          <p className="pc_card_title roboto-bold">Product Price</p>
          <div className="row col-12">
            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {"Cost"}
                  {/* <span className="text-danger">*</span> */}
                </label>
                <input
                  className={
                    errors.machineCost
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  name="machineCost"
                  placeholder="Enter cost"
                  defaultValue={props.data?.machineCost !== null ? props.data.machineCost : 0}
                  {...register("machineCost")}
                />
                {errors.machineCost && (
                  <span className="text-danger err-msg">
                    {errors.machineCost.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {"Sales Price"}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.salesPrice
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  placeholder="Enter sales price"
                  name="salesPrice"
                  defaultValue={props.data?.salesPrice !== null ? props.data.salesPrice : 0}
                  {...register("salesPrice")}
                />
                {errors.salesPrice && (
                  <span className="text-danger err-msg">
                    {errors.salesPrice.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {"Selling Price"}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.selling_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  defaultValue={props.data?.selling_price !== null ? props.data.selling_price : 0.0}
                  name="selling_price"
                  placeholder="Enter selling price"
                  {...register("selling_price")}
                />
                {errors.selling_price && (
                  <span className="text-danger err-msg">
                    {errors.selling_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">
                  {"Purchase Price"}
                  <span className="text-danger">*</span>
                </label>
                <input
                  className={
                    errors.purchase_price
                      ? "form-control mt-2 is-invalid"
                      : "form-control mt-2"
                  }
                  type="text"
                  name="purchase_price"
                  placeholder="Enter purchase price"
                  defaultValue={props.data?.purchase_price !== null ? props.data.purchase_price : 0.0}
                  {...register("purchase_price")}
                />
                {errors.purchase_price && (
                  <span className="text-danger err-msg">
                    {errors.purchase_price.message}
                  </span>
                )}
              </div>
            </div>

            <div className="col-md-3 mt-3">
              <div className="form-group">
                <label className="lbl-style roboto-bold">{"Remark"}</label>
                <textarea
                  className="form-control mt-2"
                  id="remark"
                  type="text"
                  placeholder="Enter remark"
                  defaultValue={props.data?.remark !== null ? props.data?.remark : ""}
                  name="remark"
                  {...register("remark")}
                ></textarea>
              </div>
            </div>
          </div>
          <hr className="my-4" />
          <div className="d-flex justify-content-end">
            <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
              disabled={btnDisable}
            // onClick={() => props.setCurrentStep(props.currentStep + 1)}
            >
              Save
            </button>
          </div>
        </div>
      </div>

      <Modal show={modal} onHide={() => setModal(false)} size='xl' backdrop="static">
        <Modal.Header style={{ background: "#2A3643", color: "white" }} closeButton>
          <Modal.Title>Bill Of Material List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Accordion>
            <div className="row prod_bom_accor">
              {particularBomList.loading ?
                <div className="loader-box justify-content-center">
                  <Spinner />
                </div>

                :
                particularBomList.data.length > 0 ?
                  particularBomList.data.map((val, ind) => (

                    <div className="col-12 col-md-6 mb-3" key={ind}>

                      <Accordion.Item eventKey={ind}>
                        <Accordion.Header className="position-relative">
                          <div className="d-flex justify-content-between align-items-center">
                            {val.bom_type}

                          </div>

                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip">{"Delete BOM"}</Tooltip>}
                          >
                            <FontAwesomeIcon
                              onClick={() => delete_bom(val)}
                              className="icon ms-3"
                              style={{ color: "#fff", position: 'absolute', top: '17px', right: '60px' }}
                              icon={faTrashAlt}
                            // size={"lg"}
                            />
                          </OverlayTrigger>

                        </Accordion.Header>
                        <Accordion.Body>

                          <div className="row">
                            <div className="col-12 col-md-8">
                              <p className="mb-1">
                                <span className="roboto-bold ">Remarks : </span>{`${val.remarks}`}
                              </p>
                            </div>
                            <div className="col-12 col-md-4">
                              <p><span className="roboto-bold">Quantity : </span>{`${val.qty}`}</p>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-12 col-md-4 roboto-bold">
                              Product Family
                            </div>
                            <div className="col-12 col-md-4 roboto-bold">
                              Product Name
                            </div>
                            <div className="col-12 col-md-2 roboto-bold">
                              Qty
                            </div>
                            <div className="col-12 col-md-2 roboto-bold">
                              Unit
                            </div>
                            {val.bom_line_item.map((item, ind) => (

                              <>

                                <div className="col-12 col-md-4">
                                  {item.product_family_id !== null ?
                                    item.product_family_id?.familyname : "NA"}
                                </div>
                                <div className="col-12 col-md-4">
                                  {item.productname !== null ? item.productname : "NA"}
                                </div>
                                <div className="col-12 col-md-2">
                                  {item.qty}
                                </div>
                                <div className="col-12 col-md-2">
                                  {item.unitname}
                                </div>
                              </>

                            ))}
                          </div>



                        </Accordion.Body>
                      </Accordion.Item>
                    </div>

                  ))
                  :
                  <div>
                    <div
                      className="alert alert-danger text-center p-4"
                      role="alert"
                    >
                      {"No Bill Of Masters Found"}
                    </div>
                  </div>

              }

            </div>
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-grey px-4" onClick={() => setModal(false)}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default CloneSemiFinishGoods
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import DatePicker from "react-datepicker";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { getRawMaterialType } from "../../../../api";
import CustomerSupplied from "./CustomerSupplied";
import EditProcured from "./EditProcured";
import EditCustomerSupplied from "./EditCustomerSupplied";
import { getLabels } from "../../../../common/Functions/CommonFunctions";

const schema = yup
  .object()
  .shape({
    rmType: yup
      .array()
      .min(1, "Please select raw material type !")
      .required("Please select raw material type !"),
  })
  .required();

function EditRMI(props) {
  const {
    rmiModal,
    setRMIModal,
    poList,
    poWithItem,
    currIndex,
    rmi_data,
    setRMI_Data,
    inwLineItem,
  } = props;
  const { control, watch, setValue, setError, clearErrors, formState, reset } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });
  let { errors } = formState;

  const [rmTypeList, setRMTypeList] = useState({ data: [], loading: false });
  const [rm_type, setRM_Type] = useState();
  const [constLabel, setConstLabel] = useState({});

  const selType = watch("rmType");

  useEffect(() => {
    setConstLabel(getLabels("Purchase", "RMI"));
    if(rmiModal){
      fetchRawMaterialType();
      // reset();
      console.log("rmi_data edit rmi", rmi_data);
      const existingIndex = rmi_data?.findIndex((item) => item.ind === currIndex);
      console.log("existingIndex", existingIndex);
      if(rmi_data && rmi_data?.length > 0 && existingIndex !== -1){
         setValue("rmType",[{typename:rmi_data?.[existingIndex]?.srmt}]);
         setRM_Type([{ typename: rmi_data?.[existingIndex]?.srmt }]);
      } else {
        setValue("rmType",[]);
      }
    }

  }, [rmiModal]);

  function fetchRawMaterialType() {
    setRMTypeList({ ...rmTypeList, data: [], loading: true });
    getRawMaterialType().then(
      (res) => {
        setRMTypeList({ ...rmTypeList, state: false, data: res.data.data });
      },
      (err) => {
        setRMTypeList({ ...rmTypeList, state: false, data: [] });
      }
    );
  }

  return (
    <React.Fragment>
      <Modal
        show={rmiModal}
        onHide={() => {setRMIModal(false); localStorage.removeItem("form-data");}}
        size="xl"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{constLabel?.lbl_update_rmi ? constLabel?.lbl_update_rmi :"Update RMI"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            <h5 className="roboto-bold ">{constLabel?.lbl_rmi_detail ? constLabel?.lbl_rmi_detail : "RMI Detail"}</h5>
            <div className="row mt-3">
              <div className="col-12 col-md-3">
                <div className="form-group">
                  <label className="lbl-style roboto-bold">
                    {constLabel?.lbl_raw_material_type ? constLabel?.lbl_raw_material_type : "Raw Material Type"}
                  </label>
                  <Controller
                    name={"rmType"}
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        className="mt-2"
                        id="basic-typeahead"
                        labelKey="typename"
                        multiple={false}
                        options={rmTypeList.data}
                        isLoading={rmTypeList.loading}
                        placeholder="Select raw meterial type"
                        onChange={(e) => {onChange(e); setRM_Type(e);}}
                        selected={value}
                        disabled={value?.length > 0 ? true : false}
                        isInvalid={errors.rmType ? true : false}
                      />
                    )}
                  />

                  {errors.rmType && (
                    <span className="text-danger err-msg">
                      {errors.rmType.message}
                    </span>
                  )}
                </div>
              </div>
              <hr className="my-4" />
              {selType && selType?.[0]?.typename == "Procured" ? (
                <EditProcured
                  rm_type={rm_type}
                  poList={poList}
                  poWithItem={poWithItem}
                  currIndex={currIndex}
                  rmi_data={rmi_data}
                  setRMI_Data={setRMI_Data}
                  setRMIModal={setRMIModal}
                  inwLineItem={inwLineItem}
                />
              ) : selType && selType?.[0]?.typename == "Customer Supplied" ? (
                <EditCustomerSupplied
                  rm_type={rm_type}
                  poList={poList}
                  poWithItem={poWithItem}
                  currIndex={currIndex}
                  rmi_data={rmi_data}
                  setRMI_Data={setRMI_Data}
                  setRMIModal={setRMIModal}
                  inwLineItem={inwLineItem}
                />
              ) : (
                <div
                  className="alert alert-danger mb-0 text-center my-4"
                  role="alert"
                >
                  {"Please select raw material type !"}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default EditRMI;

import React, { useEffect, useState } from "react";
import SweetAlert from "sweetalert2";
import logo from "../../../assets/images/sample.png";
import { useNavigate, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Inter from "../../../assets/fonts/Inter-Regular.ttf";
import Inter600 from "../../../assets/fonts/Inter-SemiBold.ttf";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  PDFViewer,
  Document,
  Text,
  View,
  Image,
  Page,
  Link,
  StyleSheet,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";
import {
  amountInWords,
  convertDate,
} from "../../../common/Functions/CommonFunctions";
import numWords from "num-words";

function PurchaseOrderPDF() {
  let navigate = useNavigate();
  let location = useLocation();

  const data = location.state;
  const [total, setTotal] = useState("");

  useEffect(() => {
    console.log("location.state", location.state);

    const tot = location.state.PO_line_item.reduce(
      (accumulator, currentValue) => {
        // Extract the 'number' property from each object and add it to the accumulator
        return parseFloat(accumulator) + parseFloat(currentValue.amount);
      },
      0
    );

    setTotal(tot);
  }, []);

  Font.register({
    family: "Inter, sans-serif",
    fonts: [
      {
        src: Inter,
      },
      {
        src: Inter600,
        fontWeight: 600,
      },
    ],
  });

  const userData = [
    { name: "User 1", age: 25 },
    { name: "User 2", age: 30 },
    { name: "User 3", age: 30 },
    { name: "User 4", age: 30 },
    { name: "User 5", age: 30 },
    { name: "User 6", age: 30 },
    { name: "User 7", age: 30 },
    { name: "User 8", age: 30 },
    { name: "User 9", age: 30 },
    { name: "User 10", age: 30 },
    { name: "User 11", age: 30 },
    { name: "User 12", age: 30 },
    { name: "User 13", age: 30 },
    { name: "User 14", age: 30 },
    { name: "User 15", age: 30 },
    { name: "User 16", age: 30 },
    { name: "User 17", age: 30 },
    { name: "User 18", age: 30 },
    { name: "User 19", age: 30 },
    { name: "User 20", age: 30 },
    { name: "User 21", age: 30 },
    { name: "User 22", age: 30 },
    { name: "User 23", age: 30 },

    // Add more user data as needed
  ];

  const styles = StyleSheet.create({
    viewer: {
      width: "100%",
      height: window.innerHeight,
    },
    page: {
      paddingTop: 15,
      paddingBottom: 40,
      paddingHorizontal: 15,
      color: "black",
      fontFamily: "Inter, sans-serif",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 28,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
    },
    footer: {
      position: "absolute",
      fontSize: 12,
      bottom: 0,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "black",
      paddingTop: "50px",
      paddingBottom: "10",
      paddingLeft: "15",
      paddingRight: "15",
      // borderTop:'1px solid black',
    },
    row1: {
      flexDirection: "row",
      border: "1px solid black",
    },
    row2: {
      flexDirection: "row",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
    },
    row3: {
      flexDirection: "row",
      fontSize: "10px",
      width: "100%",
    },
    tablColumn1: {
      width: "5%",
      fontSize: "10px",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn1Data: {
      width: "5%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn2: {
      width: "30%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn2Data: {
      width: "30%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn3: {
      width: "10%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn3Data: {
      width: "10%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn4: {
      width: "10%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn4Data: {
      width: "10%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn5: {
      width: "15%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn5Data: {
      width: "15%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn6: {
      width: "8%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn6Data: {
      width: "8%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
    },
    tablColumn7: {
      width: "10%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn7Data: {
      width: "10%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
      textAlign: "right",
    },
    tablColumn8: {
      width: "8%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn8Data: {
      width: "8%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
      textAlign: "right",
    },
    tablColumn9: {
      width: "14%",
      border: "1px solid black",
      padding: "5px",
      fontWeight: 600,
    },
    tablColumn9Data: {
      width: "14%",
      borderLeft: "1px solid black",
      borderRight: "1px solid black",
      borderBottom: "1px solid black",
      padding: "5px",
      textAlign: "right",
    },
    subDivRow: {
      flexDirection: "row",
    },
    column: {
      width: "50%",
      // borderRight: '1px solid black',
      // backgroundColor: 'red',
      fontSize: "10px",
    },
    title: {
      textAlign: "center",
      fontWeight: 600,
    },
    companyName: {
      fontWeight: "600",
      fontSize: "12px",
    },
    signatureBlock: {
      width: "100%",
      marginLeft: "auto",
      marginTop: "100px",
      borderTop: "1px solid black",
      borderLeft: "1px solid black",
      padding: "5px",
    },
  });

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                navigate("/pcms/purchase-order");
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {" Purchase Order PDF"}
            </p>
          </div>
          <div className="card-body">
            <div className="createNc container-fluid">
              <PDFViewer style={styles.viewer}>
                <Document title={`Purchase Order - ${location.state?.poid}`}>
                  <Page style={styles.page}>
                    <Text style={styles.title}>{"Purchase Order"}</Text>
                    <View style={[styles.row1, { marginTop: "10px" }]}>
                      <View style={styles.column}>
                        <View style={{ padding: "10px" }}>
                          <Text>Invoice To</Text>
                          <Text style={styles.companyName}>
                            {data.invoice_to_id?.comName}
                          </Text>
                          <Text>{data.invoice_to_id?.comaddress}</Text>
                          <Text>GST : {data.invoice_to_id?.comGSTnumber}</Text>
                        </View>
                      </View>
                      <View
                        style={[
                          styles.column,
                          { borderLeft: "1px solid black" },
                        ]}
                      >
                        <View style={[styles.subDivRow]}>
                          <View style={[styles.column, { padding: "5px" }]}>
                            <Text>Voucher Number</Text>
                            <Text>{data.voucher_no}</Text>
                          </View>
                          <View
                            style={[
                              styles.column,
                              { padding: "5px", borderLeft: "1px solid black" },
                            ]}
                          >
                            <Text>Date</Text>
                            <Text>{convertDate(data.po_date, "-")}</Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.subDivRow,
                            { borderTop: "1px solid black" },
                          ]}
                        >
                          <View style={[styles.column, { padding: "5px" }]}>
                            <Text></Text>
                          </View>
                          <View
                            style={[
                              styles.column,
                              { padding: "5px", borderLeft: "1px solid black" },
                            ]}
                          >
                            <Text>Mode / Terms of Payment</Text>
                            <Text>{data.credit_period}</Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.subDivRow,
                            { borderTop: "1px solid black" },
                          ]}
                        >
                          <View
                            style={[
                              styles.column,
                              { padding: "5px", padding: "5px" },
                            ]}
                          >
                            <Text>Reference No & Date</Text>
                          </View>
                          <View
                            style={[
                              styles.column,
                              { padding: "5px", borderLeft: "1px solid black" },
                            ]}
                          >
                            <Text>Other References</Text>
                          </View>
                        </View>
                        <View
                          style={[
                            styles.subDivRow,
                            { borderTop: "1px solid black" },
                          ]}
                        >
                          <View
                            style={[
                              styles.column,
                              { padding: "5px", padding: "5px" },
                            ]}
                          >
                            <Text>Despatch through</Text>
                          </View>
                          <View
                            style={[
                              styles.column,
                              { padding: "5px", borderLeft: "1px solid black" },
                            ]}
                          >
                            <Text>Destination</Text>
                          </View>
                        </View>
                      </View>
                    </View>
                    <View style={[styles.row2]}>
                      <View style={styles.column}>
                        <View style={{ padding: "10px" }}>
                          <Text>Dispatch To</Text>
                          <Text style={styles.companyName}>
                            {data.despatch_to_id?.comName}
                          </Text>
                          <Text>{data.despatch_to_id?.comaddress}</Text>
                          <Text>GST : {data.despatch_to_id?.comGSTnumber}</Text>
                        </View>
                      </View>

                      <View
                        style={[
                          styles.column,
                          { borderLeft: "1px solid black", padding: "10px" },
                        ]}
                      >
                        <Text>Terms of Delivery</Text>
                      </View>
                    </View>

                    <View style={[styles.row2]}>
                      <View style={styles.column}>
                        <View style={{ padding: "10px" }}>
                          <Text>Supplier (Bill From)</Text>
                          <Text style={styles.companyName}>
                            {data.supplier_id.name}
                          </Text>
                          <Text>{data.supplier_id.address}</Text>
                          <Text>{data.supplier_id.email}</Text>
                          <Text>GST : {data.supplier_id.gst}</Text>
                        </View>
                      </View>
                    </View>

                    <View style={[styles.row3, { marginTop: "10px" }]}>
                      <View style={styles.tablColumn1}>
                        <Text>Sr No</Text>
                      </View>
                      <View style={styles.tablColumn2}>
                        <Text>Description of Goods</Text>
                      </View>

                      <View style={styles.tablColumn4}>
                        <Text>Qty</Text>
                      </View>
                      <View style={styles.tablColumn5}>
                        <Text>{"\u20B9"} Unit Price</Text>
                      </View>
                      <View style={styles.tablColumn6}>
                        <Text>Unit</Text>
                      </View>
                      <View style={styles.tablColumn7}>
                        <Text>GST (%)</Text>
                      </View>
                      <View style={styles.tablColumn8}>
                        <Text>Discount</Text>
                      </View>
                      <View style={styles.tablColumn9}>
                        <Text> {"\u20B9"} Amount</Text>
                      </View>
                    </View>

                    {data.PO_line_item.map((val, i) => (
                      <View key={i} style={[styles.row3]}>
                        <View style={styles.tablColumn1Data}>
                          <Text>{i + 1}</Text>
                        </View>
                        <View style={styles.tablColumn2Data}>
                          <Text>{val.productname}</Text>
                        </View>
                        <View style={styles.tablColumn4Data}>
                          <Text>{val.quantity}</Text>
                        </View>
                        <View style={styles.tablColumn5Data}>
                          <Text>{val.rate}</Text>
                        </View>
                        <View style={styles.tablColumn6Data}>
                          <Text>{val.unit}</Text>
                        </View>
                        <View style={styles.tablColumn7Data}>
                          <Text>{val.gst_rate}</Text>
                        </View>
                        <View style={styles.tablColumn8Data}>
                          <Text>{val.discount}</Text>
                        </View>
                        <View style={styles.tablColumn9Data}>
                          <Text>{Number(val.amount).toFixed(2)}</Text>
                        </View>
                      </View>
                    ))}

                    <View style={[styles.row3]}>
                      <View style={styles.tablColumn1Data}>
                        <Text></Text>
                      </View>
                      <View style={styles.tablColumn2Data}>
                        <Text>Total</Text>
                      </View>

                      <View style={styles.tablColumn4Data}>
                        <Text></Text>
                      </View>
                      <View style={styles.tablColumn5Data}>
                        <Text></Text>
                      </View>
                      <View style={styles.tablColumn6Data}>
                        <Text></Text>
                      </View>
                      <View style={styles.tablColumn7Data}>
                        <Text></Text>
                      </View>
                      <View style={styles.tablColumn8Data}>
                        <Text></Text>
                      </View>
                      <View style={styles.tablColumn9Data}>
                        <Text>{Number(total).toFixed(2)}</Text>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.row3,
                        {
                          padding: "10px",

                          borderLeft: "1px solid black",
                          borderRight: "1px solid black",
                          fontWeight: 600,
                          display: "flex",
                          justifyContent: "flex-end",
                        },
                      ]}
                    >
                      <View style={{ width: "45%" }}>
                        <Text>Remark :</Text>
                        <Text style={{ marginTop: "5px" }}>
                          {data.remark !== "" && data.remark !== null
                            ? data.remark
                            : "N/A"}
                        </Text>
                      </View>
                      <View style={{ width: "5%" }}></View>

                      <View style={{ width: "30%" }}>
                        {/* <Text>Discount :</Text> */}
                        {/* <Text style={{ marginTop: '5px' }}>Amount After Discount :</Text> */}
                        {data.cgst_cost !== 0 ? (
                          <Text style={{ marginTop: "5px" }}>CGST :</Text>
                        ) : null}
                        {data.sgst_cost !== 0 ? (
                          <Text style={{ marginTop: "5px" }}>SGST :</Text>
                        ) : null}
                        {data.igst_cost !== 0 ? (
                          <Text style={{ marginTop: "5px" }}>IGST :</Text>
                        ) : null}
                        {data.ugst_cost !== 0 ? (
                          <Text style={{ marginTop: "5px" }}>UGST :</Text>
                        ) : null}
                        <Text style={{ marginTop: "5px" }}>Final Amount :</Text>
                        {/* <Text></Text> */}
                      </View>
                      <View style={{ width: "20%", textAlign: "right" }}>
                        {/* <Text>{data.discount + " %      "}{((total * data.discount) / 100).toFixed(2) + " \u20B9"}</Text> */}
                        {/* <Text style={{ marginTop: '5px' }}>{data.sub_total + " \u20B9"}</Text> */}
                        {data.cgst_cost !== 0 ? (
                          <Text style={{ marginTop: "5px" }}>
                            {data.cgst_cost + " \u20B9"}
                          </Text>
                        ) : null}
                        {data.sgst_cost !== 0 ? (
                          <Text style={{ marginTop: "5px" }}>
                            {data.sgst_cost + " \u20B9"}
                          </Text>
                        ) : null}
                        {data.igst_cost !== 0 ? (
                          <Text style={{ marginTop: "5px" }}>
                            {data.igst_cost + " \u20B9"}
                          </Text>
                        ) : null}
                        {data.ugst_cost !== 0 ? (
                          <Text style={{ marginTop: "5px" }}>
                            {data.ugst_cost + " \u20B9"}
                          </Text>
                        ) : null}
                        <Text style={{ marginTop: "5px" }}>
                          {data.net_amount + " \u20B9"}
                        </Text>
                        {/* <Text></Text> */}
                      </View>
                    </View>

                    <View style={[styles.row2]}>
                      <View style={{ padding: "10px" }}>
                        <Text
                          style={{ fontSize: "12px" }}
                        >{`Amount (In Words)`}</Text>
                        <Text
                          style={{
                            fontSize: "12px",
                            fontWeight: "600",
                            textTransform: "capitalize",
                          }}
                        >
                          {numWords(parseInt(data.net_amount)) + " Rupees Only"}
                        </Text>
                      </View>

                      <View style={styles.signatureBlock}>
                        <Text
                          style={{
                            textAlign: "right",
                            fontSize: "12px",
                            padding: "5px",
                          }}
                        >
                          For{" "}
                          <Text style={{ fontWeight: "600" }}>
                            {data.invoice_to_id?.comName}
                          </Text>
                        </Text>

                        <View
                          style={{
                            flexDirection: "row",
                            fontSize: "12px",
                            marginTop: "50px",
                          }}
                        >
                          <View style={{ width: "30%" }}>
                            <Text>Prepared By</Text>
                          </View>
                          <View style={{ width: "30%" }}>
                            <Text>Verified By</Text>
                          </View>
                          <View style={{ width: "40%" }}>
                            <Text>Authorised Signatory</Text>
                          </View>
                        </View>
                      </View>
                    </View>

                    <Text
                      style={styles.pageNumber}
                      render={({ pageNumber, totalPages }) =>
                        `${pageNumber} / ${totalPages}`
                      }
                      fixed
                    />
                    <Text style={styles.footer} fixed>
                      This is a Computer Generated Document
                    </Text>
                  </Page>
                </Document>
              </PDFViewer>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default PurchaseOrderPDF;

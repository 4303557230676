import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { updateTaskCategory } from "../../../api";
import { Tooltip } from "react-tooltip";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Task category is required !"),
  })
  .required();

function EditTaskCategory(props) {
  const [visible, setVisible] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        name: data.name,
        remark: data.remark,
      };

      updateTaskCategory(reqPayload)
        .then(
          (res) => {
            if (res.status === 200 || res.status === 201) {
              toast.success("Task category updated Successfully !", {
                autoClose: 3000,
              });
              setVisible(false);
              props.fetchTaskCategory(props.page, props.entriesPerPage);
            }
          },
          (err) => {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        )
        .catch((error) => {
          toast.error("Something went wrong, please try again !", {
            autoClose: 2000,
          });
        });
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_category
            ? props.constLabel.lbl_edit_category
            : "Edit Category"
        }
        className="menu_icon_grey cursor_pointer me-2"
        size={20}
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true);
          reset();
        }}
      />
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_task_category
              ? props.constLabel.lbl_update_task_category
              : "Update Task Category"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_category_name
                  ? props.constLabel?.lbl_category_name
                  : "Category Name"}
                <span>
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={
                    errors.name ? "form-control  is-invalid" : "form-control "
                  }
                  id="name"
                  name="name"
                  defaultValue={
                    props.data !== undefined && props.data !== ""
                      ? props.data.name
                      : ""
                  }
                  {...register("name")}
                />
                <span className="text-danger err-msg">
                  {errors.name?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_remark
                  ? props.constLabel?.lbl_remark
                  : "Remark"}
              </label>
              <div className="col-md-12">
                <textarea
                  type="text"
                  className="form-control "
                  id="remark"
                  name="remark"
                  placeholder="Enter remark"
                  defaultValue={
                    props.data !== undefined && props.data !== ""
                      ? props.data.remark
                      : ""
                  }
                  rows={2}
                  {...register("remark")}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditTaskCategory;

import React, {useState, useEffect, useReducer} from "react";
import { getLotsByCustomer } from "../../../../../api";
import { calMaxPage } from "../../../../../common/Functions/CommonFunctions";
import { ToastContainer, toast } from "react-toastify";
import { Pagination } from "react-bootstrap";

function LotDetailList(props) {

  

  const [apiBySearch, setApiBySearch] = useState(false);
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");

  const initialState = {
    loading: false,
    error: "",
    MOLots_List: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "HANDLE_ACTION":
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          MOLots_List: action.MOLots_List,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    
    dispatch({
      type: "HANDLE_ACTION",
      page: state.page,
      loading: true,
      error: state.error,
      MOLots_List: state.MOLots_List,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchMOLotsList();
  }, [state.page, state.itemsPerPage]);

  function fetchMOLotsList(para1, para2) {
    dispatch({
      type: "HANDLE_ACTION",
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      MOLots_List: state.MOLots_List,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
      partyId : props.partyCode !== undefined ? props.partyCode : ''
    };

    //

    if (para1 === "search") {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchval: para2,
      };
    }

    // getLotsByCustomer(reqPayload).then(
    //   (res) => {
        
        
    //     setTotalRecords(res.data.ManufacturingOrderDetail.length);

    //     dispatch({
    //       type: "HANDLE_ACTION",
    //       page: state.page,
    //       loading: false,
    //       error: state.error,
    //       MOLots_List: res.data.ManufacturingOrderDetail,
    //       itemsPerPage: state.itemsPerPage,
    //       maxPage:
    //         para1 === "search"
    //           ? calMaxPage(res.data.ManufacturingOrderDetail.length)
    //           : calMaxPage(res.data.totalMOCount),
    //     });
    //   },
    //   (err) => {
        

    //     if (err.response.status === 500) {
    //       toast.error("Something went wrong !", { autoClose: 5000 });
    //     }
    //   }
    // );
  }

  return (
    <>
      <form action="">
        {state.MOLots_List && state.MOLots_List.length > 0 ? (
          <div className="table-responsive">
            <table
              className="table table-bordered mt-2 table-bordered table-sm"
              width={"100%"}
            >
              <thead className="thead-light text-ceter">
                <tr className="">
                  <th scope="col">{"ID"}</th>
                  <th scope="col">{"Template Header"}</th>
                  <th scope="col">{"Actual Start Date"}</th>
                  <th scope="col">{"Actual End Date"}</th>
                  <th scope="col">{"Planning Start Date"}</th>
                  <th scope="col">{"Planning End Date"}</th>
                  <th scope="col">{"Production Manager"}</th>
                  <th scope="col">{"Remark"}</th>
                  <th scope="col">{"Status"}</th>
                  <th scope="col">{"Action"}</th>
                </tr>
              </thead>
              <tbody className="text-center">
                {/* {state.MOLots_List.map((val, i) => (
                  <tr key={i} className="f-14">
                    <td>{val.contactId}</td>
                    <td>{val.firstName}</td>
                    <td>{val.lastName}</td>
                    <td>{val.mobileNo}</td>
                    <td>{val.emailid}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                ))} */}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-danger mb-0 f-22 text-center mt-4">
            {"MO Lots Detail list is empty"}
          </p>
        )}
      </form>
      <div className="mt-2">
        <Pagination
          className="justify-content-center mb-0"
        >
          <Pagination.Prev
            disabled={state.page === 1 ? true : false}
            onClick={() => {
              dispatch({
                type: "HANDLE_ACTION",
                page: state.page - 1,
                loading: state.loading,
                error: state.error,
                MOLots_List: state.MOLots_List,
                itemsPerPage: state.itemsPerPage,
                maxPage: state.maxPage,
              });
              setSrNo((prevC) => prevC - 10);
            }}
          >
            {"Previous"}
          </Pagination.Prev>

          <Pagination.Item active>{state.page}</Pagination.Item>

          <Pagination.Next
            disabled={
              state.page === state.maxPage || state.maxPage === 0 ? true : false
            }
            onClick={() => {
              dispatch({
                type: "HANDLE_ACTION",
                page: state.page + 1,
                loading: state.loading,
                error: state.error,
                MOLots_List: state.MOLots_List,
                itemsPerPage: state.itemsPerPage,
                maxPage: state.maxPage,
              });
              setSrNo((prevC) => prevC + 10);
            }}
          >
            {"Next"}
          </Pagination.Next>
        </Pagination>
      </div>
    </>
  )
}

export default LotDetailList;
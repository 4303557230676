import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { getLabels } from "../../../common/Functions/CommonFunctions";
import { useNavigate, useLocation } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import {
  createJobCard,
  getWorkOrder,
  getProdConfig,
  getOperatorList,
  getLotNumList,
} from "../../../api";
import DatePicker from "react-datepicker";

const schema = yup
  .object()
  .shape({
    startDate: yup
      .date()
      .typeError("Please select start date!")
      .required("Please select a start date!"),
    startTime: yup
      .date()
      .typeError("Please select start time!")
      .required("Please select a start time!"),
    endDate: yup
      .date()
      .typeError("Please select end date!")
      .required("Please select a end date!"),
    endTime: yup
      .date()
      .typeError("Please select end time!")
      .required("Please select a end time!"),
    actual_qty: yup
      .string()
      .transform((value, originalValue) =>
        originalValue.trim() === "" ? null : value
      )
      .matches(/^[0-9]+$/, "Enter valid actual quantity")
      .nullable(),
    // .matches(/^[0-9]+$/, 'Enter valid actual quantity'),
    // .required("Please enter actual quantity !"),
    planned_qty: yup
      .string()
      .matches(/^[0-9]+$/, "Enter valid planned quantity")
      .required("Please enter planned quantity !"),
    operator: yup
      .array()
      .min(1, "Please select operator !")
      .required("Please select operator !"),
    machine: yup
      .array()
      .min(1, "Please select machine !")
      .required("Please select machine !"),
  })
  .required();

export const CreateJobCard = () => {
  let navigate = useNavigate();
  let location = useLocation();
  const [constLabel, setConstLabel] = useState({});
  const [workOrders, setWorkOrders] = useState({ data: [], loading: false });
  const [operations, setOperations] = useState({ data: [], loading: false });
  const [oprtList, setOprtList] = useState({ data: [], loading: false });
  const [start_date, setStart_Date] = useState(new Date());
  const [end_date, setEnd_Date] = useState(new Date());
  const [start_time, setStart_Time] = useState(new Date());
  const [end_time, setEnd_Time] = useState(new Date());

  const [machineList, setMachineList] = useState({ data: [], loading: false });

  const [lotList, setLotList] = useState({ data: [], loading: false });
  const [lotNo, setLotNo] = useState("");

  const { handleSubmit, register, control, formState, watch, reset, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

  let { errors } = formState;

  const selWorkOrder = watch("work_order");
  const selProdOpr = watch("prod_operation");
  const selMachine = watch("machine");

  useEffect(() => {
    console.log("location.state", location.state);
    setConstLabel(getLabels("Production", "Job Card"));
    // fetchWorkOrder();
    fetchOperatorList();
    if (location.state) {
      setValue("work_order", [location.state]);
    }
  }, []);

  useEffect(() => {
    setMachineList({ ...machineList, data: [], loading: true });
    // if(selWorkOrder !== undefined && selWorkOrder.length > 0){
    if (lotList !== undefined && lotList.data?.length > 0) {
      // fetchProductOperations(selWorkOrder?.[0]?.product_id);
      setValue("startDate", start_date);
      setValue("startTime", start_time);
      setValue("endDate", end_date);
      setValue("endTime", end_time);
    }

    const ress = selProdOpr?.[0]?.machine?.map((item) => ({
      ...item,
      // machineNameSerialNo: item.name + " - " + item.serialNo,
      machineNameSerialNo:
        item.serialNo && item.serialNo !== ""
          ? item.name + " - " + item.serialNo
          : item.name,
    }));

    if (ress) {
      setMachineList({ ...machineList, data: ress, loading: false });
    } else {
      setMachineList({ ...machineList, data: [], loading: false });
    }

    // if(selMachine !== undefined && selMachine.length > 0){

    //   setValue('planned_qty',calculatePlannedQuantity(start_time, end_time, selMachine?.[0]?.settings_time, selMachine?.[0]?.cycle_time));
    // } else {
    //   setValue('planned_qty', 0);
    // }
  }, [selProdOpr, lotList.data]); //selMachine selWorkOrder

  useEffect(() => {
    const calculatePlannedQuantity = (
      st_tm,
      ed_tm,
      setting_time,
      cycle_time
    ) => {
      setting_time =
        setting_time !== "" && setting_time !== null ? setting_time : 0;

      const timeDiffInMinutes = (ed_tm - st_tm) / (60 * 1000);

      if (timeDiffInMinutes > 0 && cycle_time > 0) {
        const plannedQuantity = Math.ceil(
          (timeDiffInMinutes - parseFloat(setting_time)) /
            parseFloat(cycle_time)
        );
        return plannedQuantity;
      } else {
        return 0;
      }
    };

    if (
      selMachine === null ||
      selMachine === undefined ||
      start_date === null ||
      start_date === undefined ||
      start_time === null ||
      start_time === undefined ||
      end_date === null ||
      end_date === undefined ||
      end_time === null ||
      end_time === undefined
    ) {
      // Handle the case where any of the dependencies are null or undefined
      setValue("planned_qty", 0);
      return;
    }

    const plannedQty = calculatePlannedQuantity(
      // start_date.getTime() + start_time.getTime(),
      start_date,
      // end_date.getTime() + end_time.getTime(),
      end_date,
      selMachine?.[0]?.settings_time,
      selMachine?.[0]?.cycle_time
    );

    setValue("planned_qty", plannedQty);
  }, [start_date, start_time, end_date, end_time, selMachine]);

  // function calculatePlannedQuantity(st_tm, ed_tm, setting_time, cycle_time) {

  //   const timeDiffInMinutes = (ed_tm - st_tm) / (60 * 1000);

  //   if (timeDiffInMinutes > 0 && cycle_time > 0) {
  //     const plannedQuantity = Math.ceil((timeDiffInMinutes - parseFloat(setting_time)) / parseFloat(cycle_time));
  //     return plannedQuantity;
  //   } else {
  //     return 0; // Return 0 for invalid inputs or if cycle_time is not positive
  //   }
  // }

  function fetchWorkOrder() {
    setWorkOrders({ ...workOrders, data: [], loading: true });

    getWorkOrder().then(
      (res) => {
        setWorkOrders({ ...workOrders, data: res.data.data, loading: false });
      },
      (err) => {
        setWorkOrders({ ...workOrders, data: [], loading: false });
      }
    );
  }

  function fetchProductOperations(prod_id) {
    setOperations({ ...operations, data: [], loading: true });

    getProdConfig({ product_id: prod_id }).then(
      (res) => {
        setOperations({ ...operations, data: res.data.data, loading: false });
      },
      (err) => {
        setOperations({ ...operations, data: [], loading: false });
      }
    );
  }

  function fetchOperatorList() {
    setOprtList({ ...oprtList, data: [], loading: true });
    getOperatorList().then(
      (res) => {
        const result = res.data.data.map((item) => ({
          ...item,
          fullName:
            item.MiddleName !== null && item.MiddleName !== ""
              ? item.FirstName + " " + item.MiddleName + " " + item.LastName
              : item.FirstName + " " + item.LastName,
        }));
        setOprtList({ ...oprtList, data: result, loading: false });
      },
      (err) => {
        setOprtList({ ...oprtList, data: [], loading: false });
      }
    );
  }

  function newDateTimeForm(date, time) {
    let year = date.getFullYear();
    let month = (date.getMonth() + 1).toString().padStart(2, "0");
    let day = date.getDate().toString().padStart(2, "0");

    // Extracting time components
    let hours = time.getHours().toString().padStart(2, "0");
    let minutes = time.getMinutes().toString().padStart(2, "0");
    let seconds = time.getSeconds().toString().padStart(2, "0");

    // Creating start_time in the desired format (assuming UTC time for the example)
    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
  }

  const onSubmit = (data) => {
    const fromDate = newDateTimeForm(data.startDate, data.startTime);
    const toDate = newDateTimeForm(data.endDate, data.endTime);

    const timeDiff = Math.abs(new Date(fromDate) - new Date(toDate));

    if (data !== "") {
      const reqPayload = {
        // work_order: selWorkOrder?.[0]?.uuid,
        lot_no_id: lotList.data?.[0]?.uuid,
        // product_id: selWorkOrder?.[0]?.product_id,
        product_id: lotList.data?.[0]?.product_id?.uuid,
        product_operation_id: data.prod_operation?.[0]?.uuid,
        cycle_time: data.machine?.[0]?.cycle_time,
        operator: data.operator?.[0]?.uuid,
        start_time: fromDate,
        end_time: toDate,
        total_time_hrs: Math.floor(timeDiff / 3600000),
        total_time_mins: Math.floor(timeDiff / 60000),
        total_time_sec: Math.floor(timeDiff / 1000),
        planned_qty: data.planned_qty,
        actual_qty: data.actual_qty,
        remarks: data.remark,
        machine: data.machine?.[0]?.machine_id_id,
      };

      console.log("reqPayload", reqPayload);

      createJobCard(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Job card created successfully !", {
              autoClose: 2000,
            });
            setTimeout(() => {
              if (location?.state?.jobCardBoard) {
                navigate("/pcms/job-card-board", {
                  state: { ...location.state },
                });
              } else {
                navigate("/pcms/job-card");
              }
            }, 2200);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  const handleFromDateChange = (date) => {
    if (end_date && date > end_date && end_time && date > end_time) {
      return;
    }
    setStart_Date(date);
    setStart_Time(date);
  };

  const handleToDateChange = (date) => {
    if (start_date && date < start_date && start_time && date < start_time) {
      return;
    }
    setEnd_Date(date);
    setEnd_Time(date);
  };

  // For temporary purpose lot no used for the create job card

  function searchLotNoDetails() {
    if (lotNo && lotNo?.length > 0) {
      fetchLotList();
      reset();
      setStart_Date(new Date());
      setStart_Time(new Date());
      setEnd_Date(new Date());
      setEnd_Time(new Date());
    } else {
      toast.error("Please enter lot no", {
        autoClose: 2000,
      });
    }
  }

  function fetchLotList() {
    setLotList({ ...lotList, data: [], loading: true });

    getLotNumList({ lotId: lotNo }).then(
      (res) => {
        setLotList({ ...lotList, data: res.data.data, loading: false });
        fetchProductOperations(res.data?.data?.[0]?.product_id?.uuid);
        if (res.data.data.length == 0) {
          toast.error("Lot number not found", {
            autoClose: 2000,
          });
        }
      },
      (err) => {
        setLotList({ ...lotList, data: [], loading: false });
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="purchase_order mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                SweetAlert.fire({
                  title: "Are you sure?",
                  text: "Your present data will be lost!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, go back!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.onpopstate = undefined;

                    navigate("/pcms/job-card");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_create_job_card
                ? constLabel.lbl_create_job_card
                : "Create Job Card"}
            </p>
          </div>
          <div className="card-body">
            <div className="createPO container-fluid">
              <p className="roboto-bold mb-0 mt-2" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_job_card_detail
                  ? constLabel.lbl_job_card_detail
                  : "Job Card Detail"}
              </p>

              {/* <div className="row my-4">
                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_work_order_no
                      ? constLabel.lbl_work_order_no
                      : "Work Order No"}
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name={`work_order`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="work_order_no"
                          multiple={false}
                          options={workOrders.data}
                          isLoading={workOrders.loading}
                          placeholder="Select work order"
                          disabled={location.state ? true : false}
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                        />
                      )}
                    />
                  </div>
                </div>

                {selWorkOrder && selWorkOrder.length > 0 ? (
                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-12 roboto-bold"
                    >
                      {constLabel?.lbl_product_operation
                        ? constLabel.lbl_product_operation
                        : "Product Operation"}
                    </label>

                    <div className="col-12 col-md-9">
                      <Controller
                        name={`prod_operation`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="operationname"
                            multiple={false}
                            options={operations.data}
                            isLoading={operations.loading}
                            placeholder="Select product operation"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : null}
              </div> */}

              <div className="row my-4">
                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_lot_no ? constLabel.lbl_lot_no : "Lot No"}
                  </label>
                  <div className="col-12 col-md-9">
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Search lot no"
                      onChange={(e) => {
                        if (/^[0-9]*$/.test(e.target.value)) {
                          setLotNo(e.target.value);
                        }

                        if(e.target.value.length > 0){
                          setValue("prod_operation",[]);
                          setOperations({data: [], loading: false });
                          setLotList({data: [], loading: false});
                        }
                      }}
                      value={lotNo}
                    />
                  </div>
                  <div className="col-12 col-md-3">
                    <button
                      className="btn btn-grey px-3"
                      onClick={() => searchLotNoDetails()}
                    >
                      {constLabel?.lbl_search
                        ? constLabel.lbl_search
                        : "Search"}
                    </button>
                  </div>
                </div>

                {/* {selWorkOrder && selWorkOrder.length > 0 ? ( */}
                {lotList && lotList.data?.length > 0 && lotNo.length > 0 ? (
                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-12 roboto-bold"
                    >
                      {constLabel?.lbl_product_operation
                        ? constLabel.lbl_product_operation
                        : "Product Operation"}
                    </label>

                    <div className="col-12 col-md-9">
                      <Controller
                        name={`prod_operation`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="operationname"
                            multiple={false}
                            options={operations.data}
                            isLoading={operations.loading}
                            placeholder="Select product operation"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              {selProdOpr !== undefined && selProdOpr.length > 0 ? (
                <>
                  <hr className="my-2" />

                  <div className="row my-4">
                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_start_date
                          ? constLabel.lbl_start_date
                          : "Start Date"}
                        {""}
                        <span className="text-danger">*</span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          control={control}
                          name="startDate"
                          render={({ field }) => (
                            <DatePicker
                              className={
                                errors.startDate
                                  ? "form-control  is-invalid"
                                  : "form-control  "
                              }
                              placeholderText="Select start date"
                              selected={start_date}
                              onChange={(date) => {
                                field.onChange(date);
                                handleFromDateChange(date);
                              }}
                              minDate={
                                start_date?.getTime() - 30 * 24 * 60 * 60 * 1000
                              }
                              dateFormat="dd/MM/yyyy"
                              autoComplete="false"
                            />
                          )}
                        />

                        {errors.startDate && (
                          <span className="text-danger err-msg">
                            {errors.startDate.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_start_time
                          ? constLabel.lbl_start_time
                          : "Start Time"}
                        {""}
                        <span className="text-danger">*</span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          control={control}
                          name="startTime"
                          render={({ field }) => (
                            <DatePicker
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              className={
                                errors.startTime
                                  ? "form-control  is-invalid"
                                  : "form-control  "
                              }
                              placeholderText="Select start time"
                              selected={start_time}
                              onChange={(date) => {
                                field.onChange(date);
                                handleFromDateChange(date);
                              }}
                              // minTime={start_time}
                              // maxTime={(new Date(), 1439)}
                              dateFormat="h:mm aa"
                              autoComplete="false"
                            />
                          )}
                        />

                        {errors.startTime && (
                          <span className="text-danger err-msg">
                            {errors.startTime.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_end_date
                          ? constLabel.lbl_end_date
                          : "End Date"}
                        {""}
                        <span className="text-danger">*</span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          control={control}
                          name="endDate"
                          render={({ field }) => (
                            <DatePicker
                              className={
                                errors.endDate
                                  ? "form-control  is-invalid"
                                  : "form-control  "
                              }
                              placeholderText="Select end date"
                              selected={end_date}
                              onChange={(date) => {
                                field.onChange(date);
                                handleToDateChange(date);
                              }}
                              // minDate={new Date()}
                              minDate={
                                end_date?.getTime() - 30 * 24 * 60 * 60 * 1000
                              }
                              dateFormat="dd/MM/yyyy"
                              autoComplete="false"
                            />
                          )}
                        />

                        {errors.endDate && (
                          <span className="text-danger err-msg">
                            {errors.endDate.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_end_time
                          ? constLabel.lbl_end_time
                          : "End Time"}
                        {""}
                        <span className="text-danger">*</span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          control={control}
                          name="endTime"
                          render={({ field }) => (
                            <DatePicker
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={30}
                              timeCaption="Time"
                              className={
                                errors.endTime
                                  ? "form-control  is-invalid"
                                  : "form-control  "
                              }
                              placeholderText="Select start time"
                              selected={end_time}
                              onChange={(date) => {
                                field.onChange(date);
                                handleToDateChange(date);
                              }}
                              // minTime={end_time}
                              // maxTime={(new Date(), 1439)}
                              dateFormat="h:mm aa"
                              autoComplete="false"
                            />
                          )}
                        />

                        {errors.endTime && (
                          <span className="text-danger err-msg">
                            {errors.endTime.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_machine
                          ? constLabel.lbl_machine
                          : "Machine"}
                        <span className="text-danger">*</span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          name={`machine`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="machineNameSerialNo"
                              multiple={false}
                              // options={selProdOpr?.[0]?.machine}
                              options={machineList.data}
                              isLoading={machineList.loading}
                              placeholder="Select machine"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={errors.machine ? true : false}
                            />
                          )}
                        />
                        {errors.machine && (
                          <span className="text-danger err-msg">
                            {errors.machine.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_actual_quantity
                          ? constLabel.lbl_actual_quantity
                          : "Planned Quantity"}
                        <span className="text-danger">*</span>
                      </label>

                      <div className="col-12 col-md-9">
                        <input
                          className={
                            errors.planned_qty
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          id="planned_qty"
                          name="planned_qty"
                          type="text"
                          placeholder="Enter planned quantity"
                          {...register("planned_qty")}
                        />
                        {errors.planned_qty && (
                          <span className="text-danger err-msg">
                            {errors.planned_qty.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_actual_quantity
                          ? constLabel.lbl_actual_quantity
                          : "Actual Quantity"}
                        {/* <span className="text-danger">*</span> */}
                      </label>

                      <div className="col-12 col-md-9">
                        <input
                          className={
                            errors.actual_qty
                              ? "form-control  is-invalid"
                              : "form-control "
                          }
                          id="actual_qty"
                          name="actual_qty"
                          type="text"
                          placeholder="Enter actual quantity"
                          {...register("actual_qty")}
                        />
                        {errors.actual_qty && (
                          <span className="text-danger err-msg">
                            {errors.actual_qty.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_operator
                          ? constLabel.lbl_operator
                          : "Operator"}
                        <span className="text-danger">*</span>
                      </label>

                      <div className="col-12 col-md-9">
                        <Controller
                          name={`operator`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="fullName"
                              multiple={false}
                              options={oprtList.data}
                              isLoading={oprtList.loading}
                              placeholder="Select operator"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={errors.operator ? true : false}
                            />
                          )}
                        />
                        {errors.operator && (
                          <span className="text-danger err-msg">
                            {errors.operator.message}
                          </span>
                        )}
                      </div>
                    </div>

                    <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                      <label
                        style={{ fontSize: "14px" }}
                        className="col-form-label col-12 col-md-12 roboto-bold"
                      >
                        {constLabel?.lbl_remark
                          ? constLabel.lbl_remark
                          : "Remark"}
                      </label>

                      <div className="col-12 col-md-9">
                        <input
                          className={"form-control"}
                          id="remark"
                          name="remark"
                          type="text"
                          placeholder="Enter remark"
                          {...register("remark")}
                        />
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <div
                  className="alert alert-danger mb-0 text-center my-4"
                  role="alert"
                >
                  {"Please search lot no and select product operation ! "}
                </div>
              )}
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-3">
            <button
              className="btn btn-grey px-4 ms-0 ms-md-2"
              onClick={handleSubmit(onSubmit)}
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

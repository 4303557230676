import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faBuilding } from "@fortawesome/free-solid-svg-icons";
import { Tab, Tabs } from "react-bootstrap";
import { getSupplierDetail } from "../../../../api/index";
import ViewContactDetail from "./ContactDetail/ViewContactDetail";
import EditSupplier from "./EditSupplier";
import { getLabels } from "../../../../common/Functions/CommonFunctions";

function ViewSupplier() {
  let location = useLocation();
  const [supplierDetail, setSupplierDetail] = useState({ data: {} });
  const { partyCode } = useParams();
  const [constLabel, setConstLabel] = useState({});
  
  
  // let location = useLocation();
  

  // 
  // var partyCode = location.state.val.partyCode;
  localStorage.setItem("partyCode", partyCode);
  

  useEffect(() => {
    setConstLabel(getLabels('Party Master', 'Supplier'));
    
    // const payload = {
    //   action: "viewbyid",
    //   partyCode: partyCode
    // };
    // getSupplierDetail(payload).then(
    //   (res) => {
    
    //     setSupplierDetail({
    //       ...supplierDetail,
    //       state: false,
    //       data: res.data,
    //     });
    //   },
    //   (err) => {
    //     
    //     setSupplierDetail({ ...supplierDetail, state: false, data: {} });
    //     toast.error(err.response.data.message, {
    //       autoClose: 3000,
    //     });
    //   }
    // );
  }, []);

  return (
    <React.Fragment>
    <div className="content-wrapper">
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="bold">
        <FontAwesomeIcon style={{ color: "#000" }} icon={faBuilding} size="sm" />{" "}
          {location.state?.name}
        </h1>
        {/* <EditSupplier data={location.state} /> */}
      </div>
    </div>
    <div className="row mt-4">
      <div className="col-12">
        <div className="row p-t-20">
          
          <div className="col-12 col-sm-6">
            <div className="card shadow rounded-0">
              <div className="card-header">
                <label className="label-text">{constLabel?.lbl_primary_detail ? constLabel.lbl_primary_detail : "Primary Detail"}{" "}{":"}</label>
              </div>
              <div className="card-body">
                <form className="">
                  <div className="form-group row mt-2">
                    <label
                      className="col-sm-4 lbl-style"
                      htmlFor="inputEmail3"
                    >
                      {constLabel?.lbl_supplier_name ? constLabel.lbl_supplier_name : "Supplier Name"}
                    </label>
                    <div className="col-sm-8 txt-control">
                      {location.state?.name}
                    </div>
                  </div>
                  <div className="form-group row mt-2">
                    <label
                      className="col-sm-4 lbl-style"
                      htmlFor="inputPassword3"
                    >
                      {constLabel?.lbl_supplier_id ? constLabel.lbl_supplier_id : "Supplier Id"}
                    </label>
                    <div className="col-sm-8 txt-control">
                      {location.state?.partyCode}
                    </div>
                  </div>
                  <div className="form-group row mt-2">
                    <label
                      className="col-sm-4 lbl-style"
                      htmlFor="inputPassword3"
                    >
                      {constLabel?.lbl_supplier_type ? constLabel.lbl_supplier_type : "Supplier Type"}
                    </label>
                    <div className="col-sm-8 txt-control">
                      {location.state?.supp_type}
                    </div>
                  </div>
                  <div className="form-group row mt-2">
                    <label
                      className="col-sm-4 lbl-style"
                      htmlFor="inputPassword3"
                    >
                      {constLabel?.lbl_contact_no ? constLabel.lbl_contact_no : "Contact No"}
                    </label>
                    <div className="col-sm-8 txt-control">
                      {location.state?.contactPersonContact != "" ? location.state?.contactPersonContact : "NA"}
                    </div>
                  </div>
                  <div className="form-group row mt-2">
                    <label
                      className="col-sm-4 lbl-style"
                      htmlFor="inputPassword3"
                    >
                      {constLabel?.lbl_email ? constLabel.lbl_email : "Email"}
                    </label>
                    <div className="col-sm-8 txt-control">
                      {location.state?.email != "" ? location.state?.email : "NA"}
                    </div>
                  </div>
                  <div className="form-group row mt-2">
                    <label
                      className="col-sm-4 lbl-style"
                      htmlFor="inputPassword3"
                    >
                      {constLabel?.lbl_address ? constLabel.lbl_address : "Address"}
                    </label>
                    <div className="col-sm-8">
                      {location.state?.address != "" ? location.state?.address : "NA"}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="col-12 col-sm-6">
            <div className="card shadow rounded-0">
              <div className="card-header">
                <label className="label-text">{constLabel?.lbl_other_detail ? constLabel.lbl_other_detail : "Other Detail"}{":"}</label>
              </div>
              <div className="card-body">
                <form className="">
                  <div className="form-group row mt-2">
                    <label
                      className="col-sm-4 lbl-style"
                      htmlFor="inputEmail3"
                    >
                      {constLabel?.lbl_cst_no ? constLabel.lbl_cst_no : "CST No"}
                    </label>
                    <div className="col-sm-8 txt-control">
                      {location.state?.CSTNO != "" ? location.state?.CSTNO : "NA"}
                    </div>
                  </div>
                  <div className="form-group row mt-2">
                    <label
                      className="col-sm-4 lbl-style"
                      htmlFor="inputPassword3"
                    >
                      {constLabel?.lbl_pan_no ? constLabel.lbl_pan_no : "PAN No"}
                    </label>
                    <div className="col-sm-8 txt-control">
                      {location.state?.PANNO != "" ? location.state?.PANNO : "NA"}
                    </div>
                  </div>
                  <div className="form-group row mt-2">
                    <label
                      className="col-sm-4 lbl-style"
                      htmlFor="inputPassword3"
                    >
                      {constLabel?.lbl_vat_no ? constLabel.lbl_vat_no : "VAT No"}
                    </label>
                    <div className="col-sm-8 txt-control">
                      {location.state?.VATNO != "" ? location.state?.VATNO : "NA"}
                    </div>
                  </div>

                  <div className="form-group row mt-2">
                    <label
                      className="col-sm-4 lbl-style"
                      htmlFor="inputPassword3"
                    >
                      {constLabel?.lbl_fax_no ? constLabel.lbl_fax_no : "Fax No"}
                    </label>
                    <div className="col-sm-8 txt-control">
                      {location.state?.faxNo != "" ? location.state?.faxNo : "NA"}
                    </div>
                  </div>
                  <div className="form-group row mt-2">
                    <label
                      className="col-sm-4 lbl-style"
                      htmlFor="inputPassword3"
                    >
                      {constLabel?.lbl_postal_code ? constLabel.lbl_postal_code : "Postal Code"}
                    </label>
                    <div className="col-sm-8 txt-control">
                      {location.state?.postalCode != "" && location.state?.postalCode != null ? location.state?.postalCode : "NA"}
                    </div>
                  </div>
                  <div className="form-group row mt-2">
                    <label
                      className="col-sm-4 lbl-style"
                      htmlFor="inputPassword3"
                    >
                      {constLabel?.lbl_detail ? constLabel.lbl_detail : "Detail"}
                    </label>
                    <div className="col-sm-8 txt-control">
                      <ViewContactDetail data={location.state} />
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </React.Fragment>
  )
}

export default ViewSupplier
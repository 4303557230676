import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { update_corrective_action, updateMaterialType } from "../../../api";

const schema = yup
  .object()
  .shape({
    material_type: yup
      .string()
      .trim()
      .required("Material type is required!"),
  })
  .required();

function EditMaterialType(props) {
  const [visible, setVisible] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        material_type: data.material_type.trim(),
        material_description: data.material_description !== "" ? data.material_description : null,
      };

      updateMaterialType(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Material type updated successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchCorrective_Action(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_type
            ? props.constLabel?.lbl_edit_type
            : "Edit Type"
        }
        className="menu_icon_grey cursor_pointer me-2"
        size={20}
        onClick={() => {
          setVisible(true);
          reset();
        }}
      />
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          reset();
        }}
        size={"md"}
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_material_type
              ? props.constLabel?.lbl_update_material_type
              : "Update Material Type"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_material_type
                  ? props.constLabel?.lbl_material_type
                  : "Material Type"}
                <span className="text-danger"> *</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={
                    errors.material_type ? "form-control  is-invalid" : "form-control "
                  }
                  id="material_type"
                  name="material_type"
                  placeholder="Enter material type"
                  defaultValue={props.data?.material_type}
                  {...register("material_type")}
                />
                <span className="text-danger err-msg">
                  {errors.material_type?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_material_description ? props.constLabel?.lbl_material_description : "Material Description"}
              </label>
              <div className="col-md-12">
                <textarea
                  type="text"
                  className={"form-control"}
                  id="material_description"
                  name="material_description"
                  placeholder="Enter material description"
                  defaultValue={props.data?.material_description}
                  {...register("material_description")}
                ></textarea>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditMaterialType;

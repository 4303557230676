import React, { useState, useEffect, useMemo } from 'react'
import mqtt from 'mqtt';
import { getMachineList } from '../../api/index';
import Badge from 'react-bootstrap/Badge';
import { ToastContainer, toast } from "react-toastify";

function MachineMQTT() {

  const [client, setClient] = useState(null);
  const [machineList, setMachineList] = useState({ loading: true, data: [] });

  const mqttConnect = () => {
    // setConnectStatus('Connecting')
    /**
     * if protocol is "ws", connectUrl = "ws://broker.emqx.io:8083/mqtt"
     * if protocol is "wss", connectUrl = "wss://broker.emqx.io:8084/mqtt"
     *
     * /mqtt: MQTT-WebSocket uniformly uses /path as the connection path,
     * which should be specified when connecting, and the path used on EMQX is /mqtt.
     *
     * for more details about "mqtt.connect" method & options,
     * please refer to https://github.com/mqttjs/MQTT.js#mqttconnecturl-options
     */
    setClient(mqtt.connect("mqtts://mqttdev.prodi.app:8083", {
      protocol: "mqtts",
      username: "prodi",
      password: "prodi@technicious2506",
      port: 8083,
      clientId: "mqttjs_" + Math.random().toString(16).substr(2, 8),
    }))
  }

  const mqttSub = (subscription) => {
    if (client) {


      client.subscribe("machine/data", {
        label: '0',
        value: 0,
      }, (error) => {
        if (error) {
          console.log('Subscribe to topics error', error)
          return
        }
        // console.log(`Subscribe to topics: machine/data`)

      })
    }
  }

  // const memoizedMachineList = useMemo(() => machineList, [machineList]);

  function fetchMachines() {
    getMachineList({ iot_enabled: "True" }).then((res) => {
      // console.log("res", res);
      let machines = res.data.data.map((val) => {
        val.cycle_time = null;
        val.ip_address = null;
        val.machine_mode = null;
        val.machine_status = null;
        val.operating_time = null;
        val.poweron_time = null;
        val.production_count = null;
        val.program_code = null;
        val.program_name = null;
        return val;
      })
      setMachineList({ ...machineList, loading: false, data: machines });
      mqttConnect();

    }, (err) => {
      console.log("err", err);
      setMachineList({ ...machineList, loading: false, data: [] });
    })
  }

  useEffect(() => {
    fetchMachines();
  }, []);

  useEffect(() => {
    if (client) {

      client.on('connect', () => {
        // setConnectStatus('Connected')
        console.log('connection successful');
        toast.success("Connection successful !", {
          autoClose: 1400,
        });
        mqttSub();
      })


      client.on('error', (err) => {
        console.error('Connection error: ', err);
        toast.error("Connection failed !", {
          autoClose: 1400,
        });
        client.end()
      })


      client.on('reconnect', () => {
        console.log("reconnect");
        toast.success("Reconnection initiated !", {
          autoClose: 1400,
        });
        // setConnectStatus('Reconnecting')
      })

      client.on('message', (topic, message) => {
        const payload = { topic, message: message.toString() }
        // setPayload(payload)
        // console.log("message", JSON.parse(message.toString()));
        // console.log(machineList);

        const machineDataMQTT = JSON.parse(message.toString());

        const updatedMachineData = machineList.data.map((val) => {
          // console.log(val.serialNo.trim() + machineDataMQTT.machine.trim());
          if (val.serialNo.trim() == machineDataMQTT.machine.trim()) {
            val.cycle_time = machineDataMQTT.cycle_time;
            val.ip_address = machineDataMQTT.ip_address;
            val.machine_mode = machineDataMQTT.machine_mode;
            val.machine_status = machineDataMQTT.machine_status;
            val.operating_time = machineDataMQTT.operating_time;
            val.poweron_time = machineDataMQTT.poweron_time;
            val.production_count = machineDataMQTT.production_count;
            val.program_code = machineDataMQTT.program_code;
            val.program_name = machineDataMQTT.program_name;
          }
          return val
        })
        // console.log("updatedMachineData", updatedMachineData);
        setMachineList({ ...machineList, data: updatedMachineData, loading: false });

        // console.log("payload", payload);
        // console.log(`received message: ${message} from topic: ${topic}`)
      })
    }

    // return () => {
    //   if (client !== null)
    //     client.end();
    // };
    // }, [client, memoizedMachineList]);
  }, [client]);

  function convertSeconds(ms) {
    // Handle negative milliseconds
    // if (ms < 0) {
    //   return "00, 00:00:00";
    // }
    ms = Math.abs(ms);
    const millisecondsPerSecond = 1000;
    const millisecondsPerMinute = millisecondsPerSecond * 60;
    const millisecondsPerHour = millisecondsPerMinute * 60;
    const millisecondsPerDay = millisecondsPerHour * 24;

    const days = Math.floor(ms / millisecondsPerDay);
    const hours = Math.floor((ms % millisecondsPerDay) / millisecondsPerHour);
    const minutes = Math.floor((ms % millisecondsPerHour) / millisecondsPerMinute);
    const seconds = Math.floor((ms % millisecondsPerMinute) / millisecondsPerSecond);

    // Prefixing with 0 if any unit is less than 10
    const paddedDays = days < 10 ? '0' + days : days;
    const paddedHours = hours < 10 ? '0' + hours : hours;
    const paddedMinutes = minutes < 10 ? '0' + minutes : minutes;
    const paddedSeconds = seconds < 10 ? '0' + seconds : seconds;

    // return {
    //     days: days,
    //     hours: hours,
    //     minutes: minutes,
    //     seconds: seconds
    // };

    if (days > 0) {
      return paddedDays + "D, " + paddedHours + "H : " + paddedMinutes + "M : " + paddedSeconds + "S";
  } else if (hours > 0) {
      return paddedHours + "H : " + paddedMinutes + "M : " + paddedSeconds + "S";
  } else if (minutes > 0) {
      return paddedMinutes + "M : " + paddedSeconds + "S";
  } else if(seconds > 0) {
      return paddedSeconds + "S";
  } else {
    return 0;
  }

    // return paddedDays + "D, " + paddedHours + "H : " + paddedMinutes + "M : " + paddedSeconds + "S";
  }



  return (
    <div className='machine_dashboard'>
      <ToastContainer />
      <h1 className='roboto-bold '>Machine Dashboard</h1>
      <div className='' style={{ height: '100vh', overflowY: 'auto', overflowX: 'hidden' }}>
        <div className='row mt-2 px-2' >
          {machineList.data.map((val) => (
            <div className='col-12 col-md-4 mb-4' key={val.uuid}>
              <div className={`card border-0 ${val.machine_status == "active" ? "shadow" : "opacity_low"}`}>
                <div className='card-header d-flex justify-content-between position-relative' id="machine_dashboard_card_header">
                  <h5 className='mb-0'>{`${val.machineName} - ${val.serialNo}`}</h5>

                  <Badge className='px-3 py-1' style={{ position: 'absolute', top: '26x', right: '15px', textTransform: 'capitalize', letterSpacing: "1px" }}
                    bg={val.machine_status == "active" ? "success" : "danger"}>{val.machine_status}</Badge>
                </div>
                <div className='card-body'>
                  <div className='row'>
                    <div className='col-6'>
                      <span className='text-muted body3 bold' >Production Count</span>
                      <p className='mb-0'>{val.poweron_time !== null ? val.production_count : "NA"}</p>
                    </div>


                    {/* <div className='col-6'>
                      <span className='text-muted body3 bold' >Serial No</span>
                      <p className='mb-0'>{val.serialNo !== null ? val.serialNo : "NA"}</p>
                    </div> */}

                    <div className='col-6 '>
                      <span className='text-muted body3 bold' >Mode</span>
                      <p className='mb-0'>{val.machine_mode !== null ? val.machine_mode : "NA"}</p>
                    </div>

                    <div className='col-6 mt-1'>
                      <span className='text-muted body3 bold' >IP Address</span>
                      <p className='mb-0'>{val.ip_address !== null ? val.ip_address : "NA"}</p>
                    </div>


                    <div className='col-6 mt-1'>
                      <span className='text-muted body3 bold' >Operating Time</span>
                      <p className='mb-0'>{val.operating_time !== null ? convertSeconds(val.operating_time) : "NA"}</p>
                    </div>
                    <div className='col-6 mt-1'>
                      <span className='text-muted body3 bold' >Power On Time </span><em className='caption bold'>(Days, HH:MM:SS)</em>
                      <p className='mb-0'>{val.poweron_time !== null ? convertSeconds(val.poweron_time) : "NA"}</p>
                    </div>


                    <div className='col-6 mt-1'>
                      <span className='text-muted body3 bold' >Cycle Time</span>
                      <p className='mb-0'>{val.poweron_time !== null ? convertSeconds(val.cycle_time) : "NA"}</p>
                    </div>

                    <div className='col-6 mt-1'>
                      <span className='text-muted body3 bold' >Program Name</span>
                      <p className='mb-0'>{val.program_name !== null ? val.program_name : "NA"}</p>
                    </div>
                    <div className='col-6 mt-1'>
                      <span className='text-muted body3 bold' >Program Code</span>
                      <p className='mb-0'>{val.program_code !== null ? val.program_code : "NA"}</p>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          ))}
        </div>
      </div>
      {/* <button onClick={() => { mqttConnect() }}>Connect</button> */}
      {/* <button onClick={() => { mqttSub() }}>Click</button> */}
    </div>
  )
}

export default React.memo(MachineMQTT)
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SweetAlert from "sweetalert2";
import {
  faArrowLeft,
  faArrowRight, faClose, faEye
} from "@fortawesome/free-solid-svg-icons";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import {
  getProductImage,
  deleteProductImage,
  createProductImage,
} from "../../../../api";
import { getLabels } from "../../../../common/Functions/CommonFunctions";

const imageFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
const schema = yup
  .object()
  .shape({
    image: yup
      .mixed()
      .test(
        "fileSize",
        "Image can be uploaded upto 10 MB",
        (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)
      )
      .test('fileType', 'Only image files are allowed', (value) => {
        if (!value) return true; // If no file is selected, it's considered valid.
        // return value[0]?.type.startsWith('image/');
        return imageFileTypes.includes(value[0]?.type);
      })
      .required("Required"),
    // .test(
    //   'fileType',
    //   'Only image files are allowed',
    //   (value) => !value || (value && /^image\/(jpeg|png|gif|jpg)$/.test(value.type))
    // ),
    fileName: yup.string().required("Image name is required !"),
  })
  .required();

function ImageConfigure(props) {
  let navigate = useNavigate();
  const [prod_img, setProd_Img] = useState({ data: [], loading: true });
  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      image: null,
      fileName: "",
    },
  });

  const [constLabel, setConstLabel] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);

  let { errors } = formState;

  const prod_data = JSON.parse(localStorage.getItem("prod_res"));

  useEffect(() => {
    setConstLabel(getLabels('Master', 'Product'));
    if (prod_data !== undefined) {
      fetchProductImage();
    }
  }, []);

  function fetchProductImage() {
    setProd_Img({ ...prod_img, data: [], loading: true });
    const payload = {
      product_uuid: prod_data.uuid,
    };
    getProductImage(payload).then(
      (res) => {
        
        setProd_Img({ ...prod_img, data: res.data.data, loading: false });
      },
      (err) => {
        
        setProd_Img({ ...prod_img, data: [], loading: false });
      }
    );
  }


  const onSubmit = (data) => {
    
    setBtnDisable(true);

    const payload = {
      attachment: data.image[0],
      name: data.fileName,
      productId_id: prod_data && prod_data.productId,
      product_id: prod_data && prod_data.uuid,
    };

    const formDataToSend = new FormData();

    for (const [key, value] of Object.entries(payload)) {
      formDataToSend.append(key, value);
    }
    

    createProductImage(formDataToSend).then(
      (res) => {
        
        setBtnDisable(false);
        toast.success("Image uploaded successfully", {
          autoClose: 2000,
        });

        fetchProductImage();
        reset({ image: "", fileName: "" });
        // props.setCurrentStep((prevStep) => prevStep + 1);
      },
      (err) => {
        setBtnDisable(false);
        
        toast.error("Something went wrong", {
          autoClose: 2000,
        });
      }
    );
  };

  function deleteImg(val) {
    SweetAlert.fire({
      title: "Are you sure to delete image ? \n",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        const payload = { uuid: val };
        deleteProductImage(payload).then(
          (res) => {
            
            toast.success("Image deleted successfully", {
              autoClose: 2000,
            });
            fetchProductImage();
          },
          (err) => {
            
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="card shadow-sm rounded-0 p-2">
        <div className="card-body">
          <div className="d-flex justify-content-start">
            <p className="pc_card_title roboto-bold form-label mt-2 col-md-2">
              {constLabel?.lbl_add_images ? constLabel.lbl_add_images : "Add Images"}
            </p>
            <div className="col-md-4 px-4">
              <input
                className={
                  errors.image ? "form-control is-invalid" : "form-control"
                }
                type="file"
                accept="image/*"
                {...register("image")}
                placeholder="Upload Image"
              />
              {errors.image && (
                <span className="text-danger err-msg">
                  {errors.image.message}
                </span>
              )}
            </div>
            <div className="col-md-3 px-4">
              <input
                className={
                  errors.fileName ? "form-control is-invalid" : "form-control"
                }
                type="text"
                {...register("fileName")}
                placeholder="Enter image name"
              />
              {errors.fileName && (
                <span className="text-danger err-msg">
                  {errors.fileName.message}
                </span>
              )}
            </div>
            {/* <div className="col-md-3">
            <button
              className="btn btn-yellow px-4"
              onClick={handleSubmit(onSubmit)}
            >
              <FontAwesomeIcon
                style={{ color: "#00000" }}
                icon={faPlus}
                size="sm"
              />{" "}
              Add Images
            </button>
          </div> */}
          </div>
          <hr className="my-4" />

          <div
            className="row image_gallery2 "
            style={{ paddingTop: "10px", paddingBottom: "25px" }}
          >
            <div className="col-12">
              <div className="card border-0">
                <div className="card-body">
                  {/* <Slider {...sliderSetting}> */}
                  <div className="row">
                    {prod_img.data !== undefined && prod_img.data.length > 0 ? (
                      prod_img.data.map((imgSrc, index) => (
                        <div className="col-12 col-md-3">
                          <div className="image-container" key={index}>
                            <img
                              src={imgSrc.attachment}
                              className="img-fluid"
                              alt="Image 1"
                            />

                            <div className="image-overlay">
                              <FontAwesomeIcon
                                onClick={() =>
                                  window.open(imgSrc.attachment, "_blank")
                                }
                                className="icon"
                                style={{ color: "#fff" }}
                                icon={faEye}
                                size="xl"
                              />

                              <FontAwesomeIcon
                                onClick={() => deleteImg(imgSrc.uuid)}
                                className="icon-close"
                                style={{ color: "#fff" }}
                                icon={faClose}
                                size="lg"
                              />

                              <span className="image-name cursor_pointer">
                                {imgSrc.name}
                              </span>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div
                        className="alert alert-danger mb-0 text-center my-4"
                        role="alert"
                      >
                        {"No image found !"}
                      </div>
                    )}
                  </div>
                  {/* </Slider> */}
                </div>
              </div>
            </div>
          </div>

          <hr className="my-4" />
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-grey px-4 me-2"
              onClick={() => props.setCurrentStep(props.currentStep - 1)}
            >
              <FontAwesomeIcon
                style={{
                  color: "#fff",
                }}
                icon={faArrowLeft}
                size="sm"
                className="me-2"
              />
              {constLabel?.lbl_back ? constLabel?.lbl_back : "Back"}
            </button>
            <button
              className="btn button-primary px-4 me-2"
              onClick={handleSubmit(onSubmit)}
              disabled={btnDisable}
            >
              {constLabel?.lbl_save ? constLabel?.lbl_save : "Save"}
            </button>
            <button
              className="btn btn-grey px-4"
              onClick={() => props.setCurrentStep(props.currentStep + 1)}
            >
              {constLabel?.lbl_next ? constLabel?.lbl_next : "Next"}
              <FontAwesomeIcon
                style={{
                  color: "#fff",
                }}
                icon={faArrowRight}
                size="sm"
                className="ms-2"
              />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment >
  );
}

export default ImageConfigure;

import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { getSupplierType, get_gst_treatment } from "../../../../../api/index";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";

const schema = yup
  .object()
  .shape({
    supplierType: yup
      .array()
      .min(1, "Please select supplier type")
      .required("Supplier type is required !"),
    supplierName: yup
      .string()
      .required("Supplier name is required !"),
    email: yup.string().email("Enter valid email id").notRequired(),
    contactPersonContact: yup.string().matches(/^[0-9]*$/, 'Enter valid contact number').notRequired(),
    gstTreat: yup
      .array()
      .min(1, "Please select gst registration type !")
      .required("Please select gst registration type !"),
    cst: yup.
      string()
      .matches(/^[A-Z0-9]*$/, 'Enter valid cst no!')
      .notRequired(),
    vat: yup.
      string()
      .matches(/^[A-Z0-9]*$/, 'Enter valid vat no!')
      .notRequired(),
    pan: yup.
      string()
      .matches(/^[A-Z0-9]*$/, 'Enter valid pan no!')
      .notRequired(),
    // gst: yup.
    //   string()
    //   .matches(/^[A-Z0-9]*$/, 'Enter valid gst no!')
    //   .notRequired(),
    gst: yup
    .string()
    .when(['gstTreat'], (gstTreat, schema) => {
        if (gstTreat !== undefined && gstTreat.length > 0) {

            const gst_typeSelected = gstTreat[0].name == 'Register';

            // If 'gst_tye' is in the category, make 'GST number' mandatory
            return gst_typeSelected
                ? schema
                    .required("Please enter GST number !")
                    .max(15, 'GST number must be 15 characters only !')
                    .matches(/^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})$/, 'Enter valid GST number!')
                : schema

        } else {
            return schema

        }
    }),

  })
  .required();

function EditSupplierDetail(props) {

    const [supType, setSupType] = useState({ data: [], state: false });
    const [gst_treat, setGST_Treat] = useState({ loading: false, data: [] });

    const [constLabel, setConstLabel] = useState({});

    var FormData = require("form-data");
    const { register, handleSubmit, formState, control, reset, watch } = useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook
  
    let { errors } = formState;
  
    useEffect(() => {
      setConstLabel(getLabels('Party Master', 'Supplier'));
      
      // resetting the form
      setSupType({ ...supType, state: true, data: [] });
  
        getSupplierType().then(
        (res) => {
          
          const result = res.data.data.filter((item) => item.name !== null);
          setSupType({
            ...supType,
            state: false,
            data: result,
          });
        },
        (err) => {
          //
          setSupType({ ...supType, state: false, data: [] });
          toast.error(err.response.data.message, {
            autoClose: 3000,
          });
        }
      );
      
      fetchGSTTreatment();

    }, []);

    function fetchGSTTreatment(){
      setGST_Treat({ ...gst_treat, state: true, data: [] });
  
      get_gst_treatment().then(
        (res) => {
          
          setGST_Treat({
            ...gst_treat,
            state: false,
            data: res.data.data,
          });
        },
        (err) => {
          //
          setGST_Treat({ ...gst_treat, state: false, data: [] });
          toast.error(err.response.data.message, {
            autoClose: 3000,
          });
        }
      );
    }
  
    const onSubmit = (data) => {
      
  
      if (data !== "") {
        const basic = {
          uuid: props.data?.uuid,
          partyType: "Supplier",
          party: props.data?.partyCode,
          supp_type: data.supplierType[0]?.name,
          name: data.supplierName,
          contactPersonDesignation: data.contactPersonDesignation,
          contactPersonContact: data.contactPersonContact,
          contactPersonName: data.contactPersonName,
          CSTNO: data.cst,
          VATNO: data.vat,
          PANNO: data.pan,
          gst_treatment: data.gstTreat[0]?.name,
          gst: data.gst,
          description: data.remark,
          email: data.email,
        };
  
        localStorage.setItem("basic", JSON.stringify(basic));
        props.setKey("addressDetail");
      } else {
        errors.showMessages();
      }
    };
    
  return (
    <React.Fragment>
      <div className="row mt-2">
        <div className="col-md-6">
          <div className="form-group row">
            <label style={{fontSize: "14px"}} className="col-form-label  col-md-12 roboto-bold">
            {constLabel?.lbl_supplier_type ? constLabel.lbl_supplier_type : "Supplier Type"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <div className="col-md-8">
              <Controller
                name="supplierType"
                control={control}
                defaultValue={[{name : props.data?.supp_type}]}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    defaultSelected={[{name: props.data?.supp_type}]}
                    multiple={false}
                    // size="sm"
                    placeholder="Select supplier type"
                    options={supType.data}
                    isLoading={supType.state}
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid={errors.supplierType ? true : false}
                  />
                )}
              />
              <span className="text-danger">
                {errors.supplierType?.message}
              </span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">{constLabel?.lbl_contact_person_name ? constLabel.lbl_contact_person_name : "Contact Person Name"}</label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control "
                id="contactPersonName"
                name="contactPersonName"
                placeholder="Enter contact person name"
                defaultValue={props.data !== undefined || props.data !== "" ? props.data?.contactPersonName : ""}
                {...register("contactPersonName")}
              />
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="form-label col-12 col-md-12 roboto-bold">
            {constLabel?.lbl_contact_person_designation ? constLabel.lbl_contact_person_designation : "Contact Person Designation"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control "
                id="contactPersonDesignation"
                name="contactPersonDesignation"
                placeholder="Enter contact person designation"
                maxLength={10}
                defaultValue={props.data !== undefined || props.data !== "" ? props.data?.contactPersonDesignation : ""}
                {...register("contactPersonDesignation")}
              />
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">{constLabel?.lbl_supplier_vat ? constLabel.lbl_supplier_vat : "Supplier VAT"}</label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control "
                id="vat"
                name="vat"
                maxLength={15}
                placeholder="Enter vat no"
                defaultValue={props.data !== undefined || props.data !== "" ? props.data?.VATNO : ""}
                {...register("vat")}
              />
              <span className="text-danger err-msg">{errors.vat?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">{constLabel?.lbl_email ? constLabel.lbl_email : "Email"}</label>
            <div className="col-md-8">
              <input
                type="email"
                className="form-control "
                id="email"
                name="email"
                placeholder="Enter email"
                defaultValue={props.data !== undefined || props.data !== "" ? props.data?.email : ""}
                {...register("email")}
              />
              <span className="text-danger err-msg">{errors.email?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">{constLabel?.lbl_email ? constLabel.lbl_email : "Remark"}</label>
            <div className="col-md-8">
              <textarea
                type="text"
                className="form-control "
                id="remark"
                name="remark"
                placeholder="Enter remark"
                defaultValue={props.data !== undefined || props.data !== "" ? props.data?.description : ""}
                {...register("remark")}
              ></textarea>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group row">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
                {constLabel?.lbl_supplier_name ? constLabel.lbl_supplier_name : "Supplier Name"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control "
                id="supplierName"
                name="supplierName"
                placeholder="Enter supplier name"
                defaultValue={props.data !== undefined || props.data !== "" ? props.data?.name : ""}
                {...register("supplierName")}
              />
              <span className="text-danger err-msg">{errors.supplierName?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
            {constLabel?.lbl_contact_no ? constLabel.lbl_contact_no : "Contact No"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control "
                id="contactPersonContact"
                name="contactPersonContact"
                placeholder="Enter contact no"
                defaultValue={props.data !== undefined || props.data !== "" ? props.data?.contactPersonContact : ""}
                maxLength={10}
                {...register("contactPersonContact")}
              />
              <span className="text-danger err-msg">{errors.contactPersonContact?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
            {constLabel?.lbl_cst_no ? constLabel.lbl_cst_no : "CST No"}</label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control "
                id="cst"
                name="cst"
                maxLength={11}
                placeholder="Enter cst no"
                defaultValue={props.data !== undefined || props.data !== "" ? props.data?.CSTNO : ""}
                {...register("cst")}
              />
              <span className="text-danger err-msg">{errors.cst?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
              {constLabel?.lbl_supplier_pan ? constLabel.lbl_supplier_pan : "Supplier PAN"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control "
                id="pan"
                name="pan"
                placeholder="Enter pan no"
                maxLength={10}
                defaultValue={props.data !== undefined || props.data !== "" ? props.data?.PANNO : ""}
                {...register("pan")}
              />
              <span className="text-danger err-msg">{errors.pan?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label
              style={{ fontSize: "14px" }}
              className="col-form-label col-md-12 roboto-bold"
            >
              {constLabel?.lbl_gst_treatment ? constLabel.lbl_gst_treatment : "GST Registration Type"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <div className="col-md-8">
              <Controller
                name="gstTreat"
                control={control}
                defaultValue={
                  props.data?.gst_treatment !== null
                    ? [{ name: props.data?.gst_treatment }]
                    : []
                }
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    // size="sm"
                    multiple={false}
                    defaultSelected={
                      props.data?.gst_treatment !== null
                        ? [{ name: props.data?.gst_treatment }]
                        : []
                    }
                    options={gst_treat.data !== undefined ? gst_treat.data : []}
                    isLoading={gst_treat.state}
                    placeholder="Select gst registration type"
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid={errors.gstTreat ? true : false}
                  />
                )}
              />
              <span className="text-danger err-msg">{errors.gstTreat?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
            {constLabel?.lbl_gst_no ? constLabel.lbl_gst_no : "GST No"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control "
                id="gst"
                name="gst"
                maxLength={15}
                placeholder="Enter gst no"
                defaultValue={props.data !== undefined || props.data !== "" ? props.data?.gst : ""}
                {...register("gst")}
              />
              <span className="text-danger err-msg">{errors.gst?.message}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-between mt-3">
        <div className="col-md-4 ">
          <span>
            <i style={{ color: "red" }}>*</i>
            <u> indicate Mandatory fields</u>
          </span>
        </div>
        <div className="col-sm-2 text-end">
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {constLabel?.lbl_next ? constLabel.lbl_next : "Next"}
            {/* <i className="fa fa-play"></i> */}
          </button>
        </div>
      </div>
    </React.Fragment>
  )
}

export default EditSupplierDetail
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Tooltip } from "react-tooltip";
import { Edit, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { update_tool_type_family } from "../../../api";

const schema = yup
  .object()
  .shape({
    name: yup.string().trim().required("Tool type name is required !"),
    code: yup.string().trim().required("Tool type code is required !"),
  })
  .required();

function EditToolTypeFamily(props) {
  const [visible, setVisible] = useState(false);

  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  const onSubmit = (data) => {
    if (data !== "") {
      const reqPayload = {
        uuid: props.data.uuid,
        name: data.name.trim(),
        tool_family_code: data.code.trim(),
        category: data.category,
        description: data.description,
      };

      console.log("reqpayload", reqPayload);

      update_tool_type_family(reqPayload).then(
        (res) => {
          if (res.status === 200 || res.status === 201) {
            toast.success("Tool type updated successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchToolType_Family(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          if (err.response.status === 400) {
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_tool_type
            ? props.constLabel?.lbl_edit_tool_type
            : "Edit Tool Type"
        }
        className="menu_icon_grey cursor_pointer me-2"
        size={20}
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true);
        }}
      />
      <Modal
        show={visible}
        onHide={() => {
          setVisible(false);
          reset();
        }}
        size={"md"}
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_update_tool_type
              ? props.constLabel?.lbl_update_tool_type
              : "Update Tool Type"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_tool_type_code
                  ? props.constLabel?.lbl_tool_type_code
                  : "Tool Type Code"}
                <span className="text-danger">*</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={
                    errors.code ? "form-control  is-invalid" : "form-control "
                  }
                  id="code"
                  name="code"
                  placeholder="Enter tool type code"
                  defaultValue={
                    props.data.tool_family_code && props.data.tool_family_code
                  }
                  {...register("code")}
                />
                <span className="text-danger err-msg">
                  {errors.code?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_tool_name
                  ? props.constLabel?.lbl_tool_name
                  : "Tool Type Name"}
                <span className="text-danger">*</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={
                    errors.name ? "form-control  is-invalid" : "form-control "
                  }
                  id="name"
                  name="name"
                  placeholder="Enter tool type name"
                  defaultValue={props.data.name && props.data.name}
                  {...register("name")}
                />
                <span className="text-danger err-msg">
                  {errors.name?.message}
                </span>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_tool_category
                  ? props.constLabel?.lbl_tool_category
                  : "Tool Category"}
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={"form-control "}
                  id="category"
                  name="category"
                  placeholder="Enter tool category"
                  defaultValue={props.data.category && props.data.category}
                  {...register("category")}
                />
              </div>
            </div>
            <div className="form-group row mt-2">
              <label
                style={{ fontSize: "14px" }}
                className="col-form-label col-md-12 roboto-bold"
              >
                {props.constLabel?.lbl_tool_description
                  ? props.constLabel?.lbl_tool_description
                  : "Description"}
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={"form-control "}
                  id="description"
                  name="description"
                  placeholder="Enter tool description"
                  defaultValue={
                    props.data.description && props.data.description
                  }
                  {...register("description")}
                />
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {props.constLabel?.lbl_save ? props.constLabel?.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default EditToolTypeFamily;

import React, { useState, useEffect, useReducer } from "react";
import { get_party_contact } from "../../../../../api";
import { calMaxPage, getLabels } from "../../../../../common/Functions/CommonFunctions";
import { ToastContainer, toast } from "react-toastify";
import { Pagination } from "react-bootstrap";

function ContactDetailList(props) {
  

  const [apiBySearch, setApiBySearch] = useState(false);
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");

  const [constLabel, setConstLabel] = useState({});

  const initialState = {
    loading: false,
    error: "",
    contact_List: [],
    itemsPerPage: 10,
    page: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "HANDLE_ACTION":
        return {
          page: action.page,
          itemsPerPage: action.itemsPerPage,
          loading: action.loading,
          error: action.error,
          contact_List: action.contact_List,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // 
    setConstLabel(getLabels('Party Master', 'Customer'));
    dispatch({
      type: "HANDLE_ACTION",
      page: state.page,
      loading: true,
      error: state.error,
      contact_List: state.contact_List,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    fetchContactList();
  }, [state.page, state.itemsPerPage]);

  function fetchContactList(para1, para2) {
    dispatch({
      type: "HANDLE_ACTION",
      page: para1 === "refresh" ? 1 : state.page,
      loading: true,
      error: state.error,
      contact_List: state.contact_List,
      itemsPerPage: state.itemsPerPage,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      pageNo: state.page,
      entriesPerPage: state.itemsPerPage,
      partyId: props.data?.uuid !== undefined ? props.data?.uuid : "",
    };

    //

    if (para1 === "search") {
      reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
        searchval: para2,
      };
    }

    get_party_contact(reqPayload).then(
      (res) => {
        // 
        
        setTotalRecords(res.data.total);

        dispatch({
          type: "HANDLE_ACTION",
          page: state.page,
          loading: false,
          error: state.error,
          contact_List: res.data.data,
          itemsPerPage: state.itemsPerPage,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.data)
              : calMaxPage(res.data.total),
        });
      },
      (err) => {
        

        if (err.response.status === 500) {
          toast.error("Something went wrong !", { autoClose: 5000 });
        }
      }
    );
  }

  return (
    <>
      <form action="">
        {state.contact_List && state.contact_List.length > 0 ? (
          <div className="table-responsive">
            <table
              className="table table-bordered mt-2 table-sm"
              width={"100%"}
            >
              <thead className="table-grey roboto">
                <tr className="">
                  <th scope="col">{"Sr.No"}</th>
                  <th scope="col">{constLabel?.lbl_first_name ? constLabel.lbl_first_name : "First Name"}</th>
                  <th scope="col">{constLabel?.lbl_last_name ? constLabel.lbl_last_name : "Last Name"}</th>
                  <th scope="col">{constLabel?.lbl_contact ? constLabel.lbl_contact : "Contact"}</th>
                  <th scope="col">{constLabel?.lbl_email_id ? constLabel.lbl_email_id : "Email Id"}</th>
                  <th scope="col">{constLabel?.lbl_designation ? constLabel.lbl_designation : "Designation"}</th>
                  <th scope="col">{constLabel?.lbl_remark ? constLabel.lbl_remark : "Remark"}</th>
                  <th scope="col">{"Action"}</th>
                </tr>
              </thead>
              <tbody className="roboto">
                {state.contact_List.map((val, i) => (
                  <tr key={i} className="f-14">
                    <td>{i+1}</td>
                    <td>{val.firstName != "" &&  val.firstName != null ? val.firstName : "NA"}</td>
                    <td>{val.lastName && val.lastName != null ? val.lastName : "NA"}</td>
                    <td>{val.mobileNo && val.mobileNo != null ? val.mobileNo : "NA"}</td>
                    <td>{val.emailid && val.emailid != null ? val.emailid : "NA"}</td>
                    <td>{val.designation && val.designation != null ? val.designation : "NA"}</td>
                    <td>{val.remark && val.remark != null ? val.remark : "NA"}</td>
                    <td></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <p className="text-danger mb-0 f-22 text-center mt-4">
            {"Contact Details list is empty"}
          </p>
        )}
      </form>
      <div className="mt-2">
        <Pagination
          className="justify-content-center mb-0"
        >
          <Pagination.Prev
            disabled={state.page === 1 ? true : false}
            onClick={() => {
              dispatch({
                type: "HANDLE_ACTION",
                page: state.page - 1,
                loading: state.loading,
                error: state.error,
                contact_List: state.contact_List,
                itemsPerPage: state.itemsPerPage,
                maxPage: state.maxPage,
              });
              setSrNo((prevC) => prevC - 10);
            }}
          >
            {"Previous"}
          </Pagination.Prev>

          <Pagination.Item active>{state.page}</Pagination.Item>

          <Pagination.Next
            disabled={
              state.page === state.maxPage || state.maxPage === 0 ? true : false
            }
            onClick={() => {
              dispatch({
                type: "HANDLE_ACTION",
                page: state.page + 1,
                loading: state.loading,
                error: state.error,
                contact_List: state.contact_List,
                itemsPerPage: state.itemsPerPage,
                maxPage: state.maxPage,
              });
              setSrNo((prevC) => prevC + 10);
            }}
          >
            {"Next"}
          </Pagination.Next>
        </Pagination>
      </div>
    </>
  );
}

export default ContactDetailList;

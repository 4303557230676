import React, { useState, useEffect} from "react";
import DatePicker from "react-datepicker";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import { getJobCardEfficiency, getDispatch } from "../../api/index";
import {
  convertDateYYMMDD,
  getLabels,
  getFinancialYearStartDate
} from "../../common/Functions/CommonFunctions";
import { Tooltip } from "react-tooltip";
import List from "../../assets/images/icons/project_menu/checklist.png"


const schema = yup
  .object()
  .shape({
    // fromDate: yup.date().typeError("Please select from date !").required("Please select from date !"),
    fromDate: yup
      .date()
      .typeError("Please select From date!")
      .required("Please select a From date!"),
    toDate: yup
      .date()
      .typeError("Please select To date!")
      .required("Please select a To date!"),
  })
  .required();

function AdminDashboard() {
  let navigate = useNavigate();
  const app_env = process.env.REACT_APP_ENV == "development" ? "dev" : "prod";
  const { control, formState, setError } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  const percentages = [
    { value: 25, color: "#EEAE2D" },
    { value: 75, color: "#344454" },
    { value: 50, color: "#FF134E" },
  ];

  const [startDate, setStartDate] = useState(getFinancialYearStartDate(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  
  
  const [prodMonthly, setProdMonthly] = useState({ data: [], loading: true });
  const [costRevenue, setCostRevenue] = useState({data: [], loading: true });

  const loginData = useSelector((state) =>  state.auth?.loginData?.data?.response);
  const [privilage, setPrivilage] = useState({
    read: true,
    write: true,
    view: true,
    delete: true,
    report: true,
   no_access: false
  });
  const abortController = new AbortController();

  const [constLabel, setConstLabel] = useState({});

  const colors = [
    "#4E7DAE",
    "#FF134E",
    "#344454",
    "#EEAE2D",
    "#ABBCCC",
    "#8D8D8D",
    "#2ECC71",
    "#8E44AD",
    "#D35400",
    "#85C1E9",
    "#FF5733",
    "#8C94D6",
    "#D68FD6",
    "#6F1E51",
    "#00FF7F",
    "#FFD700",
    "#F08080",
    "#20B2AA",
    "#DA70D6",
    "#C71585",
    "#6495ED",
    "#7FFF00",
    "#B0E0E6",
    "#9370DB",
    "#F0E68C",
    "#4682B4",
    "#CD5C5C",
    "#32CD32",
    "#DB7093",
    "#FFA500",
    "#800080",
    "#FF4500",
    "#00CED1",
    "#808000",
    "#8B008B",
    "#FF6347",
    "#008080",
    "#556B2F",
    "#9932CC",
    "#FF8C00",
    "#008B8B",
    "#FFDAB9",
    "#000080",
    "#8B0000",
    "#20B2AA",
    "#FF1493",
    "#00FF00",
    "#FA8072",
    "#800000",
    "#BA55D3",
    "#F5FFFA",
    "#FF69B4",
    "#00BFFF",
    "#FFE4C4",
    "#8A2BE2",
    "#E6E6FA",
    "#FFB6C1",
    "#66CDAA",
    "#00FA9A",
    "#191970",
  ];

  const [cardShow, setCardShow] = useState([
    { name: "Monthly Production Efficiency Chart", flag: true },
    { name: "Cost & Revenue Breakup", flag: true },
  ]);

  function getPrvMonthDateFromToday() {
    const today = new Date();
    const endDate = new Date(today);
    endDate.setMonth(today.getMonth() - 1);
    return endDate;
  }

  useEffect(() => {
    setConstLabel(getLabels("Quality", "Quality Dashboard"));
    
    let isUserAdmin;
    if (
      loginData.role_name.trim() == "admin" ||
      loginData.role_name.trim() == "client_admin"
    ) {
      isUserAdmin = true;
    } else {
      isUserAdmin = false;
    }
    
    if (isUserAdmin) {
      setPrivilage({
        read: true,
        write: true,
        view: true,
        delete: true,
      });
    } else {
      for (let i = 0; i < loginData.module_list.length; i++) {
        if (loginData.module_list[i].module == "Quality Dashboard") {
          setPrivilage(loginData.module_list[i]);
        } else {
          for (let i = 0; i < loginData.module_list.length; i++) {
            if (loginData.module_list[i].module == "Quality Dashboard") {
              setPrivilage(loginData.module_list[i]);
            } else {
              if (loginData.module_list[i].screens !== undefined) {
                for (let j = 0; j < loginData.module_list[i].screens.length; j++) {
                  if (loginData.module_list[i].screens[j].screen_name == "Quality Dashboard") {
                    setPrivilage(loginData.module_list[i]);
                  }
                }
              }
            }
          }
        }
      }
    }
    
  }, []);

  useEffect(() => {
    
    
    let debounceTimeout;
    if (
      startDate !== undefined &&
      startDate !== null &&
      endDate !== undefined &&
      endDate !== null
    ) {
      
      debounceTimeout = setTimeout(() => {
        ;
        setProdMonthly({ ...prodMonthly, loading: true, data: [] });
        setCostRevenue({...costRevenue, data: [], loading: true });

        fetchProdMonthly();
        fetchCostRevenue();
        
      }, 1000); // Adjust the delay as needed (e.g., 500 milliseconds)
    }

    return () => {
      abortController.abort();
      clearTimeout(debounceTimeout); // Clear the timeout if searchTerm changes before the timeout expires
    };
  }, [startDate, endDate]);

 
  function fetchProdMonthly() {
    getJobCardEfficiency(
      {
        endDate: convertDateYYMMDD(endDate, "-"),
        env: app_env
        // type: "operation"
      }, { signal: abortController.signal }).then(
        (res) => {
          const mappedMonths = res?.data?.data[0]?.data.map(
            (entry) => `${entry.month}-${entry.year}`
          );

          const result = res?.data?.data?.map((item) => ({
            name: "Efficiency Ranking - " + item['Efficiency Ranking'],
            data: item.data.map((entry) => parseInt(entry.jc_count)),
          }));
          
          const state2 = {
            series: result,
            options: {
              chart: {
                height: 400,
                type: "line",
                dropShadow: {
                  enabled: true,
                  color: "#000",
                  top: 18,
                  left: 7,
                  blur: 10,
                  opacity: 0.2,
                },
                toolbar: {
                  show: false,
                  // autoSelected: 'pan'
                },
                zoom: {
                  enabled: false,
                },
              },
              colors: ["#008000", "#FFA500", "#FF0000"],
              dataLabels: {
                enabled: true,
              },
              stroke: {
                curve: "straight",
                width: 2,
              },
              grid: {
                borderColor: "#e7e7e7",
                row: {
                  colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
                  opacity: 0.5,
                },
              },
              markers: {
                size: 0.5,
              },
              xaxis: {
                categories: mappedMonths,
                title: {
                  text: 'Months'
                },
              },
              yaxis: {
                title: {
                  text: 'Job Card Count'
                },
                min: 10,
                max: 200
              },
              legend: {
                position: "bottom",
                markers: {
                  width: 10,
                  height: 10,
                },
                fontSize: "14px",
                fontWeight: "500",
                show: true,
               
              },
            },
          };

          

          setProdMonthly({ ...prodMonthly, loading: false, data: state2 });
        },
        (err) => {
          
          setProdMonthly({ ...prodMonthly, loading: true, data: [] });
        }
      );
  }

  function fetchCostRevenue() {
    getDispatch(
      {
        endDate: convertDateYYMMDD(endDate, "-"),
        env: app_env
        // type: "operation"
      }, { signal: abortController.signal }).then(
      (res) => {
        // console.log("res", res);
        const mappedMonths = res?.data?.data[0]?.data.map(
          (entry) => `${entry.month}-${entry.year}`
        );

        const result = [
          { name: 'Dispatched Value', data: [] },
          { name: 'Purchase Value', data: [] },
          { name: 'WIP-Production', data: [] },
          { name: 'Quarantine', data: [] }
        ];

        // Create a map to easily reference the correct result array
        const resultMap = {
          'Dispatched Value': result[0].data,
          'Purchase Value': result[1].data,
          'WIP-Production': result[2].data,
          'Quarantine': result[3].data
        };

        // Loop through each item in the res.data.data array and push values into the correct data array
        res?.data?.data?.forEach(item => {
          const targetArray = resultMap[item.name];
          
          if (targetArray) {
            item.data.forEach(entry => {
              targetArray.push(entry.value === null ? 0 : parseFloat(entry.value));
            });
          }
        });

        const state2 = {
          series: result,
          options: {
            chart: {
              type: 'bar',
              height: 350,
              toolbar: {
                show: false,
              },
              scrollable: true, // Enable scrolling
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '100%',
                endingShape: 'rounded'
              },
            },
            yaxis: {
              title: {
                text: 'Value in crore',
              },
              min: 10000,
              max: 50000000,
              labels: {
                formatter: function (value) {
                  return (value / 10000000).toFixed(2) + ' Cr';
                },
              },
            },
            colors: colors,
            dataLabels: {
              enabled: false,
              style: {
                fontSize: '10px',
              },
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: mappedMonths,
              title: {
                text: 'Months'
              },
              // min: 0, // Set minimum index for x-axis
              // max: mappedMonths.length - 1, // Set maximum index for x-axis
              range: 3, // Set range to show 4 months (index 0 to 3)
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              x: {
                formatter: function (value, { seriesIndex, dataPointIndex }) {
                  return `${mappedMonths[dataPointIndex]}`;
                },
              },
              y: {
                formatter: function (value) {
                  return `${(value / 10000000).toFixed(2)} Crore`;
                },
              },
            },
          },
        };

        // console.log("Lot produced quantity", state2);

        setCostRevenue({ ...costRevenue, loading: false, data: state2 });
      },
      (err) => {
        
        setCostRevenue({ ...costRevenue, loading: false, data: [] });
      }
    );
  }
  
  const handleFromDateChange = (date) => {
    if (endDate && date > endDate) {
      return;
    }
    setStartDate(date);
  };


  const handleToDateChange = (date) => {
    if (startDate && date < startDate) {
      return;
    }
    setEndDate(date);
  };

  function handleClick(ind, checkVal) {
    // 

    let trueCount = 0;
    cardShow.map((val) => {
      if (val.flag == true) {
        trueCount += 1;
      }
    });

    
    

    if (checkVal == true) {
      setCardShow((prevCardShow) => {
        return prevCardShow.map((val, vali) => {
          if (vali == ind) {
            val.flag = false;
          }
          return val;
        });
      });
    } else {
      const lastIndex = cardShow.lastIndexOf((item) => item.flag == true);
      

      setCardShow((prevCardShow) => {
        const res = prevCardShow.map((val, vali) => {
          if (vali == ind) {
            val.flag = true;
          }
          return val;
        });

        return res;
      });
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="nc_dashboard mb-4">
        <div className="mt-2">
          <h1 className="bold">
            {"Org Dashboard"}
          </h1>

          <div className="card border-0">
            <div className="card-body row">
              <div className="col-12 col-md-8">
                <div className="row">
                  <div
                    className="col-12 col-md-2 d-flex align-items-center"
                    style={{ maxWidth: "120px" }}
                  >
                    <p className="mb-0">{constLabel?.lbl_from_date ? constLabel?.lbl_from_date : "From Date"}</p>
                  </div>
                  <div className="col-12 col-md-4">
                    <div style={{ maxWidth: "250px" }}>
                      <Controller
                        control={control}
                        name="fromDate"
                        render={({ field }) => (
                          <DatePicker
                            id="fromDate"
                            className="form-control form-control-sm"
                            selected={startDate}
                            onChange={(date) => {
                              field.onChange(date);
                              handleFromDateChange(date)
                            }}
                            showYearDropdown
                            scrollableMonthYearDropdown
                            dateFormat="dd/MM/yyyy"
                            placeholderText="From Date"
                            maxDate={new Date()}
                            autoComplete="false"
                          />
                        )}
                      />
                      {errors.fromDate && (
                        <span className="text-danger err-msg">
                          {errors.fromDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                  <div
                    className="col-12 col-md-2 d-flex align-items-center"
                    style={{ maxWidth: "120px" }}
                  >
                    <p className="mb-0">{constLabel?.lbl_to_date ? constLabel?.lbl_to_date : "To Date"}</p>
                  </div>
                  <div className="col-12 col-md-4">
                    <div style={{ maxWidth: "250px" }}>
                      <Controller
                        control={control}
                        name="toDate"
                        render={({ field }) => (
                          <DatePicker
                            id="toDate"
                            className="form-control form-control-sm"
                            selected={endDate}
                            onChange={(date) => {
                              field.onChange(date);
                              handleToDateChange(date)

                            }}
                            showYearDropdown
                            scrollableMonthYearDropdown
                            dateFormat="dd/MM/yyyy"
                            placeholderText="To Date"
                            maxDate={new Date()}
                            autoComplete="false"
                          />
                        )}
                      />
                      {errors.toDate && (
                        <span className="text-danger err-msg">
                          {errors.toDate.message}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row mt-4">
          <div className="d-flex justify-content-between align-items-center">
            <p className="roboto-bold" style={{ fontSize: "18px" }}>
              {constLabel?.lbl_quick_stats ? constLabel?.lbl_quick_stats : "Quick stats"}
            </p>

            <Tooltip id={"show-tooltip"} place="top" />
            <div 
              className="dropdown"
              data-tooltip-id={"show-tooltip"}
              data-tooltip-content={"Show / Hide Columns"}
            >
              <div
                className="d-flex justify-content-center align-items-center"
                type="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                {/* <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faCog}
                  size="sm"
                /> */}
                <img src={List} height='32' width='32' className="cursor_pointer" />
              </div>

              <ul
                className="dropdown-menu px-2 shadow-sm"
                style={{
                  maxHeight: "300px",
                  overflowY: "scroll",
                  width: "200px",
                }}
              >
                {cardShow !== undefined && cardShow.length > 0
                  ? cardShow.map((val, ind, arr) => (
                    <li key={ind}>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          onChange={(e) => handleClick(ind, val.flag)}
                          value=""
                          checked={val.flag}
                          id={val.name}
                        />
                        <label
                          className="ms-1"
                          style={{ fontSize: "13px" }}
                          htmlFor={val.name}
                        >
                          {val.name}
                        </label>
                      </div>
                    </li>
                  ))
                  : null}
              </ul>
            </div>
          </div>

          {cardShow.map((card, index) =>
            card.name === "Monthly Production Efficiency Chart" && card.flag ? (
              <div key={index} className="col-12 col-md-12 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h4 className="roboto-bold">Monthly Production Efficiency Chart</h4>
                    <p className="roboto-light nc_detail_label">
                        Efficiency Chart
                    </p>
                    {prodMonthly.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : prodMonthly.data.options &&
                      prodMonthly.data.series &&
                      prodMonthly.data?.series?.length ? (
                      <Chart
                        options={prodMonthly.data?.options}
                        series={prodMonthly.data?.series}
                        type="line"
                        width={"100%"}
                        height={400}
                      />
                    ) : (
                      <div
                        className="alert alert-danger mb-0 text-center my-1"
                        role="alert"
                      >
                        {"Monthly NC products data not found !"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : card.name === "Cost & Revenue Breakup" && card.flag ? (
              <div key={index} className="col-12 col-md-12 mt-3">
                <div className="card border-0">
                  <div className="card-body">
                    <h4 className="roboto-bold">{"Cost & Revenue Breakup"}</h4>
                    <p className="roboto-light nc_detail_label">
                      {"Cost & Revenue Breakup"}
                    </p>
                    {costRevenue.loading ? (
                      <div className="text-center">
                        <div className="spinner-border" role="status">
                          <span className="visually-hidden">Loading...</span>
                        </div>
                      </div>
                    ) : costRevenue.data.options &&
                    costRevenue.data.series &&
                    costRevenue.data?.series?.length > 0 ? (
                      <Chart
                        options={costRevenue.data?.options}
                        series={costRevenue.data?.series}
                        type="bar"
                        width={"100%"}
                        height={500}
                      />
                    ) : (
                      <div
                        className="alert alert-danger mb-0 text-center my-1"
                        role="alert"
                      >
                        {"Data not found !"}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            ) : null
          )}

        </div>
      </div>

     
    </React.Fragment>
  );
}

export default AdminDashboard;

import React, { useState, useEffect } from "react";
import { useForm, useFieldArray, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

const schema = yup
  .object()
  .shape({
    items: yup.array().of(
      yup.object().shape({
        actual_observation: yup
          .string()
          .transform((value, originalValue) =>
            originalValue == "" ? null : value
          )
          .matches(/^\d+(\.\d+)?$/, "Enter valid value")
          .nullable(),
      })
    ),
  })
  .required();

function Inspection_Data(props) {
  const { prod_attr, constLabel, currInd, inspListobj, setInspListobj, action } = props;
  const [visible, setVisible] = useState(false);

  const { handleSubmit, register, control, formState, watch, reset, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

  let { errors } = formState;
  const { fields, append, remove } = useFieldArray({ control, name: "items" });

  useEffect(() => {
    // console.log("prod_attr.data --", prod_attr.data);
    var attibItems = [];
    if ((visible && action === "Create") && prod_attr !== undefined && prod_attr.data?.length > 0) {
      for (let i = 0; i < prod_attr.data?.length; i++) {
        attibItems.push({
          serial_no: i + 1,
          attribute: [
            {
              uuid: prod_attr.data?.[i]?.uuid,
              name: prod_attr.data?.[i]?.attname,
            },
          ],
          product_attribute_name: prod_attr.data?.[i]?.name,
          min_value: prod_attr.data?.[i]?.minValue,
          max_value: prod_attr.data?.[i]?.maxValue,
          actual_value: prod_attr.data?.[i]?.value,
        });
      }
      setValue("items", attibItems);
      // reset({ items: attibItems });
    }

    if((visible && action === "Update" || action === "View") && (inspListobj !== undefined && Object.keys(inspListobj).length > 0)){
      // console.log("inspListobj - update", inspListobj);
      // console.log("currInd - update", currInd);
      
        let arr = inspListobj[`${currInd}`]; 
        let set_arr=[]
        // console.log("arr",arr)
        if(arr){
          arr?.forEach((item)=>{
            attibItems.push({
              serial_no: item.serial_no,
              attribute: [
                {
                  uuid: item.product_attribute_id,
                  name: item.product_attribute,
                },
              ],
              product_attribute_name: item.product_attribute_name,
              min_value: item.min_value,
              max_value: item.max_value,
              actual_value: item.actual_value,
              actual_observation: item.actual_observation
            });
          })
          // console.log("attibItems", attibItems);
          set_arr = attibItems;
        }
        if(arr==undefined){
          let arr2=[]
          for (let i = 0; i < prod_attr.data?.length; i++) {
            arr2.push({
              serial_no: i + 1,
              attribute: [
                {
                  uuid: prod_attr.data?.[i]?.uuid,
                  name: prod_attr.data?.[i]?.attname,
                },
              ],
              product_attribute_name: prod_attr.data?.[i]?.name,
              min_value: prod_attr.data?.[i]?.minValue,
              max_value: prod_attr.data?.[i]?.maxValue,
              actual_value: prod_attr.data?.[i]?.value,
            });
          }

          set_arr=[...set_arr,...arr2]
          
        }
        // console.log("set_arr",set_arr);
        setValue("items", set_arr); 

      
      // console.log("attibItems -- 1",attibItems)
      // reset({ items: attibItems });
      
    } else if((visible && action === "Update" || action === "View") && prod_attr !== undefined && prod_attr.data?.length > 0){
      // console.log("without inspListobj and update");
      for (let i = 0; i < prod_attr.data?.length; i++) {
        attibItems.push({
          serial_no: i + 1,
          attribute: [
            {
              uuid: prod_attr.data?.[i]?.uuid,
              name: prod_attr.data?.[i]?.attname,
            },
          ],
          product_attribute_name: prod_attr.data?.[i]?.name,
          min_value: prod_attr.data?.[i]?.minValue,
          max_value: prod_attr.data?.[i]?.maxValue,
          actual_value: prod_attr.data?.[i]?.value,
        });
      }
      setValue("items", attibItems);
      // console.log("attibItems -- 2",attibItems)
    }else{
      // setValue("items", []);
    }

  }, [visible, prod_attr]);

  const onSubmit = (data) => {
    let paramData = [];
    if (data.items?.length > 0) {
      for (let i = 0; i < data.items?.length; i++) {
        paramData.push({
          product_attribute_id: data.items?.[i]?.attribute?.[0]?.uuid,
          product_attribute: data.items?.[i]?.attribute?.[0]?.name,
          product_attribute_name: data.items?.[i]?.product_attribute_name,
          serial_no: (data.items?.[i]?.serial_no).toString(),
          min_value: (data.items?.[i]?.min_value).toString(),
          max_value: (data.items?.[i]?.max_value).toString(),
          actual_value: data.items?.[i]?.actual_value,
          actual_observation: data.items?.[i]?.actual_observation,
        });
      }
    }

    let obj = { ...inspListobj };
    obj[`${currInd}`] = paramData;
    setInspListobj(obj);
    // console.log("obj", obj);
    setVisible(false);
  };

  return (
    <React.Fragment>
      <button
        className="btn btn-outline-secondary btn-sm me-1"
        onClick={() => setVisible(true)}
      >
        {action !== "View" ? constLabel?.lbl_add_inspection_data
          ? constLabel?.lbl_add_inspection_data
          : "Add Inspection Data" : constLabel?.lbl_view_inspection_data
          ? constLabel?.lbl_view_inspection_data
          : "View Inspection Data" }
      </button>
      <Modal show={visible} onHide={() => setVisible(false)} size={"xl"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_inspection_data
              ? constLabel?.lbl_inspection_data
              : "Inspection Data"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {action === "Create" && prod_attr.loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : ((action === "Create" && prod_attr.data?.length > 0) || Object.keys(inspListobj).length > 0 ||((action === "Update" && prod_attr.data?.length > 0)))  ?(
            <div className="col-12 col-md-12">
              <table className="table table-responsive table-bordered table-sm">
                <thead className="table-grey roboto-bold">
                  <tr>
                    <th>
                      {constLabel?.lbl_job_serial_no
                        ? constLabel?.lbl_job_serial_no
                        : "Job / Serial No"}
                    </th>
                    <th>
                      {constLabel?.lbl_attribute_name
                        ? constLabel?.lbl_attribute_name
                        : "Attribute"}
                    </th>
                    <th>
                      {constLabel?.lbl_name ? constLabel?.lbl_name : "Name"}
                    </th>
                    <th>
                      {constLabel?.lbl_min_value
                        ? constLabel?.lbl_min_value
                        : "Min Value"}
                    </th>
                    <th>
                      {constLabel?.lbl_max_value
                        ? constLabel?.lbl_max_value
                        : "Max Value"}
                    </th>
                    <th>
                      {constLabel?.lbl_actual_value
                        ? constLabel?.lbl_actual_value
                        : " Actual Value"}
                    </th>
                    <th>
                      {constLabel?.lbl_actual_observation
                        ? constLabel?.lbl_actual_observation
                        : "Actual Observation"}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map(({ id }, index) => (
                    <tr key={id}>
                      <td style={{ verticalAlign: "top" }} >
                        <input
                          className={"form-control form-control-sm"}
                          type="text"
                          name={`items[${index}].serial_no`}
                          {...register(`items[${index}].serial_no`)}
                          disabled
                        />
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                        <Controller
                          name={`items[${index}].attribute`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              id="basic-typeahead-single"
                              labelKey="name"
                              multiple={false}
                              size="sm"
                              placeholder="Select attribute"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              disabled
                            />
                          )}
                        />
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                        <input
                          className={"form-control form-control-sm"}
                          type="text"
                          name={`items[${index}].product_attribute_name`}
                          {...register(
                            `items[${index}].product_attribute_name`
                          )}
                          disabled
                        />
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                        <input
                          className={"form-control form-control-sm"}
                          type="text"
                          name={`items[${index}].min_value`}
                          {...register(`items[${index}].min_value`)}
                          disabled
                        />
                      </td>

                      <td style={{ verticalAlign: "top" }}>
                        <input
                          className={"form-control form-control-sm"}
                          type="text"
                          name={`items[${index}].max_value`}
                          {...register(`items[${index}].max_value`)}
                          disabled
                        />
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                        <input
                          className={"form-control form-control-sm"}
                          type="text"
                          name={`items[${index}].actual_value`}
                          {...register(`items[${index}].actual_value`)}
                          disabled
                        />
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                        <input
                          className={
                            errors.items?.[index]?.actual_observation
                              ? "form-control form-control-sm is-invalid"
                              : "form-control form-control-sm"
                          }
                          type="text"
                          name={`items[${index}].actual_observation`}
                          {...register(`items[${index}].actual_observation`)}
                          disabled={action === "View" ? true : false}
                        />
                        {errors.items?.[index]?.actual_observation && (
                          <span className="text-danger err-msg">
                            {errors.items?.[index]?.actual_observation.message}
                          </span>
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div
              className="alert alert-danger mb-0 text-center px-2"
              role="alert"
            >
              {"Inspection data not found !"}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-grey px-4"
            onClick={handleSubmit(onSubmit)}
            disabled={action === "View" ? true : false}
          >
            {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default Inspection_Data;

import React, { useState, useEffect } from 'react'
import { calMaxPage } from "../../../common/Functions/CommonFunctions";
import { Pagination, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Eye, Trash2, Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable } from "@fortawesome/free-solid-svg-icons";
import { getSemiFinishGoodsList } from "../../../api/index";

function SemiFinishList({ search, reset }) {
    const [page, setPage] = useState(1);
    const [srNo, setSrNo] = useState(0);
    const [maxPage, setMaxPage] = useState(0);
    const [dataList, setDataList] = useState({ data: [], loading: false, totalRecords: "" });
    useEffect(() => {

        fetchSFGList(page);
        setSrNo(0)

    }, []);

    useEffect(() => {
        if (reset == true) {
            fetchSFGList(page);
            setSrNo(0)
        }

    }, [reset]);

    useEffect(() => {
        if (search.status == true) {
            fetchSFGList(1);
            setPage(1);
            setSrNo(0);
        }
    }, [search])

    function fetchSFGList(pg) {

        setDataList({ ...dataList, loading: true, data: [] });

        const payload = {
            "action": "list",
            "pageNo": pg,
            "entriesPerPage": 10,
            "semiproductId": "",
            "prodTypeId": "ProdTypeID-28"
        }

        getSemiFinishGoodsList(payload).then((res) => {
            
            setDataList({ ...dataList, loading: false, data: res.data.semifinishedList, totalRecords: res.data.semifinishedCount });
            setMaxPage(calMaxPage(res.data.semifinishedCount));
        }, (err) => {
            

        })
    }

    return (
        <React.Fragment>
            {dataList.loading ?
                <div className="text-center">
                    <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
                :
                dataList.data.length > 0 ?

                    <div className="table-responsive" style={{ overflowX: 'scroll' }}>
                        <table
                            className="table mt-2 table-bordered table-sm"
                        >
                            <thead className="table-grey  roboto-bold">
                                <tr className="">
                                    <th scope="col">{"ID"}</th>
                                    <th scope="col">{"Name"}</th>
                                    <th scope="col">{"Cut Length"}</th>
                                    <th scope="col">{"Cut Weight"}</th>
                                    <th scope="col">{"Diameter"}</th>
                                    <th scope="col">{"Price"}</th>
                                    <th scope="col" width="15%">
                                        {"Action"}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className=" roboto">
                                {dataList.data.map((val, i) => (
                                    <tr key={i}>
                                        <td>{val.id}</td>
                                        <td>{val.cutpiecename}</td>
                                        <td>{val.cutlength}</td>
                                        <td>{val.cutweight}</td>
                                        <td>{val.diameter}</td>
                                        <td>{val.price}</td>
                                        <td className="">
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="tooltip">{"Update Product"}</Tooltip>}
                                            >
                                                <div
                                                    className="editIcon m-1 cursor_pointer"
                                                >
                                                    <Edit2 color="#FFFFFF" size={18} />
                                                </div>
                                            </OverlayTrigger>
                                            {/* <OverlayTrigger
                                                placement="top"
                                                overlay={<Tooltip id="tooltip">{"Delete NC"}</Tooltip>}
                                            >
                                                <div
                                                    className="deleteIcon m-1 cursor_pointer"
                                                >
                                                    <Trash2 color="#FFFFFF" size={18} />
                                                </div>
                                            </OverlayTrigger> */}

                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    : null
            }


            {dataList.loading ? null : (
                <div className="mt-4 d-flex justify-content-between">
                    <p className='mb-0'>{`Showing ${parseInt(srNo) + 1} to ${10 + parseInt(srNo)} of ${dataList.totalRecords} entries`}</p>
                    <Pagination className="my-2">
                        <Pagination.Prev
                            disabled={page === 1 ? true : false}
                            onClick={() => {
                                fetchSFGList(page - 1)
                                setSrNo((prevC) => page - 1 == 1 ? 0 : prevC - 10);
                                setPage(page - 1)
                            }}
                        >
                            {"Prev"}
                        </Pagination.Prev>

                        <Pagination.Item active>{page}</Pagination.Item>

                        <Pagination.Next
                            disabled={
                                page === maxPage || maxPage === 0
                                    ? true
                                    : false
                            }
                            onClick={() => {
                                // console.log("parseInt(srNo) + parseInt(entriesPerPage)", parseInt(srNo) + parseInt(entriesPerPage));
                                fetchSFGList(page + 1)
                                setSrNo((prevC) => prevC + 10);
                                setPage(page + 1)
                            }}
                        >
                            {"Next"}
                        </Pagination.Next>
                    </Pagination>
                </div>
            )}
        </React.Fragment>
    )
}

export default SemiFinishList
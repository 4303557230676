import React, { useState, useEffect, useReducer } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faPlus,
  faBan,
  faFilter,
} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination, Modal, Spinner } from "react-bootstrap";
import { Download, Trash2 } from "react-feather";
import SweetAlert from "sweetalert2";
import { Tooltip } from "react-tooltip";
// import { getNCReason, saveNCReason } from "../../../api";
import { calMaxPage } from "../../../common/Functions/CommonFunctions";
import EditPackagingMaterial from "./EditPackagingMaterial";

const schema = yup
  .object()
  .shape({
    box_type: yup
      .string()
      .required("Box type is required !")
      .matches(/^[a-zA-Z ]*$/, "Only alphabets and space Allowed !"),
    dimensions: yup
      .string()
      .required("Dimensions is required !")
      .matches(/^[a-zA-Z0-9 ]*$/, "Only alphabets and space Allowed !"),
    std_box_qty: yup
      .string()
      .required("Standard box qty is required !")
      .matches(/^[0-9]*$/, "Only number Allowed !"),
  })
  .required();

function PackagingMaterial() {

    const [visible, setVisible] = useState(false);

    const [apiBySearch, setApiBySearch] = useState(false);
    const [srNo, setSrNo] = useState(0);
    const [totalRecords, setTotalRecords] = useState("");
  
    const { register, handleSubmit, formState, control, reset, watch } = useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    }); // initialise the hook
  
    let { errors } = formState;
  
    const initialState = {
      loading: false,
      error: "",
      pkgMaterialList: [],
      itemsPerPage: 10,
      page: 1,
      maxPage: 0,
    };
  
    const reducer = (state, action) => {
      switch (action.type) {
        case "HANDLE_ACTION":
          return {
            page: action.page,
            itemsPerPage: action.itemsPerPage,
            loading: action.loading,
            error: action.error,
            pkgMaterialList: action.pkgMaterialList,
            maxPage: action.maxPage,
          };
  
        default:
          return state;
      }
    };
  
    const [state, dispatch] = useReducer(reducer, initialState);
  
    useEffect(() => {
      
      dispatch({
        type: "HANDLE_ACTION",
        page: state.page,
        loading: true,
        error: state.error,
        pkgMaterialList: state.pkgMaterialList,
        itemsPerPage: state.itemsPerPage,
        maxPage: state.maxPage,
      });
  
      fetchPkgMaterial();
    }, [state.page, state.itemsPerPage]);
  
    function fetchPkgMaterial(para1, para2) {
      dispatch({
        type: "HANDLE_ACTION",
        page: para1 === "refresh" ? 1 : state.page,
        loading: true,
        error: state.error,
        pkgMaterialList: state.pkgMaterialList,
        itemsPerPage: state.itemsPerPage,
        maxPage: state.maxPage,
      });
  
      var reqPayload = {
        pageNo: state.page,
        entriesPerPage: state.itemsPerPage,
      };
  
      //
  
      if (para1 === "search") {
        reqPayload = {
          pageNo: state.page,
          entriesPerPage: state.itemsPerPage,
          // searchval: para2,
        };
      }
  
      // getNCReason(reqPayload).then(
      //   (res) => {
      
      //     setTotalRecords(res.data.total);
  
      //     dispatch({
      //       type: "HANDLE_ACTION",
      //       page: state.page,
      //       loading: false,
      //       error: state.error,
      //       pkgMaterialList: res.data.data,
      //       itemsPerPage: state.itemsPerPage,
      //       maxPage:
      //         para1 === "search"
      //           ? calMaxPage(res.data.data.length)
      //           : calMaxPage(res.data.total),
      //     });
      //   },
      //   (err) => {
      //     
  
      //     if (err.response.status === 500) {
      //       toast.error("Something went wrong !", { autoClose: 5000 });
      //     }
      //   }
      // );
    }
  
    const onSubmit = (data) => {
      
  
      if (data !== "") {
        const reqPayload = {
            box_type: data.box_type,
            dimensions: data.dimensions,
            std_box_qty: data.std_box_qty,
          };
  
        
  
        // saveNCReason(reqPayload)
        //   .then(
        //     (res) => {
        //       
        //       if (res.status === 200 || res.status === 201) {
        //         toast.success("NC Reason created Successfully !", {
        //           autoClose: 3000,
        //         });
        //         setVisible(false);
        //         fetchPkgMaterial();
        //       }
        //     },
        //     (err) => {
        //       
        //       if (err.response.status === 400) {
        //         
        //         toast.error(err.response, {
        //           autoClose: 2000,
        //         });
        //         reset();
        //       }
        //     }
        //   )
        //   .catch((error) => {
        //     
        //     toast.error("Something went wrong, please try again !", {
        //       autoClose: 2000,
        //     });
        //   });
      } else {
        errors.showMessages();
      }
    };
  
    // Deleting location type
    const delLocationType = (val) => {
      
      SweetAlert.fire({
        title: "Are you sure? \n" + val.typename,
        text: "Once deleted, you will not be able to recover this record !",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Ok",
        cancelButtonText: "cancel",
        reverseButtons: true,
      }).then((result) => {
        if (result.value) {
          // Firing delete api
          const payload = {
            locationtypeId: val.locationtypeId,
          };
          // machineCategory(payload).then(
          //   (res) => {
          //     
          //     toast.success("Machine category deleted successfully !", {
          //       autoClose: 3000,
          //     });
          //     fetchPkgMaterial();
          //   },
          //   (err) => {
          //     
          //     toast.error("Something went wrong !", { autoClose: 3000 });
          //   }
          // );
        }
      });
    };
  
    function openNav() {
      document.getElementById("mySidebar").style.width = "320px";
      document.getElementById("root_div_main").style.marginLeft = "320px";
      setTimeout(() => {
        document.getElementById("mySidebar").style.overflowX = "visible";
      }, [200]);
    }
  
    function closeNav() {
      document.getElementById("mySidebar").style.width = "0";
      document.getElementById("root_div_main").style.marginLeft = "0";
  
      document.getElementById("mySidebar").style.overflowX = "hidden";
    }
  

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <div className="min-vh-100" id="root_div_main">
        <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>{"Add Packaging Material"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form action="">
              <div className="form-group row mt-2">
                <label style={{fontSize: "14px"}} className="col-form-label col-md-12 roboto-bold">
                  Box Type
                  <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control "
                    id="box_type"
                    name="box_type"
                    placeholder="Enter box type"
                    {...register("box_type")}
                  />
                  <span className="text-danger">
                    {errors.box_type?.message}
                  </span>
                </div>
              </div>

              <div className="form-group row mt-2">
                <label style={{fontSize: "14px"}} className="col-form-label col-md-12 roboto-bold">
                {"Dimensions"}
                  <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control "
                    id="dimensions"
                    name="dimensions"
                    placeholder="Enter dimensions"
                    {...register("dimensions")}
                  />
                  <span className="text-danger">
                    {errors.dimensions?.message}
                  </span>
                </div>
              </div>

              <div className="form-group row mt-2">
                <label style={{fontSize: "14px"}} className="col-form-label col-md-12 roboto-bold">
                   Standard Box Qty
                  <span>
                    <i style={{ color: "red" }}>*</i>
                  </span>
                </label>
                <div className="col-md-12">
                  <input
                    type="text"
                    className="form-control "
                    id="std_box_qty"
                    name="std_box_qty"
                    placeholder="Enter standard box qty"
                    {...register("std_box_qty")}
                  />
                  <span className="text-danger">
                    {errors.std_box_qty?.message}
                  </span>
                </div>
              </div>

            </form>
            <div className="row justify-content-start mt-3">
                <div>
                <span>
                    <i style={{ color: "red" }}>*</i>
                    <u> indicate Mandatory fields</u>
                </span>
                </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {"Save"}
            </button>
          </Modal.Footer>
        </Modal>
        <div id="mySidebar" className="customsidebar ">
          <a className="closebtn" onClick={() => closeNav()}>
            ×
          </a>

          <div className=" content">
            <div className="filter row">
              <label
                className="filterLabel mb-2 roboto-bold"
                style={{ fontSize: "14px" }}
              >
                Search
              </label>
              <div className="col-12">
                <input
                  type="text"
                  className="form-control "
                  placeholder="Search by name"
                  onChange={(e) => {
                    if (e.target.value.length > 0) {
                      fetchPkgMaterial("search", e.target.value);
                      setApiBySearch(true);
                    } else {
                      fetchPkgMaterial();
                    }
                  }}
                />
              </div>
            </div>
            <div className="my-3 d-flex justify-content-end">
              <button className="btn btn-sm btn-yellow">
                <FontAwesomeIcon
                  style={{ color: "#344454" }}
                  icon={faBan}
                  size="sm"
                />{" "}
                Clear Filter
              </button>
              {/* <button
                        className="btn btn-sm btn-grey ms-0 ms-3 px-4"
                    >
                        <FontAwesomeIcon style={{ color: "#fff" }} icon={faSearch} size="sm" />{" "}
                        Search
                    </button> */}
            </div>
          </div>
        </div>
        <div className="content-wrapper">
          <div className="d-flex justify-content-between align-items-center">
            <h1 className="bold">{"Packaging Material"}</h1>
            <div>
              <span
                className="cursor_pointer  px-3 me-0 me-md-2"
                onClick={() => {
                  openNav();
                }}
              >
                <FontAwesomeIcon
                  style={{ marginRight: "5px" }}
                  icon={faFilter}
                  size="sm"
                />
                Filter Menu
              </span>

              <button
                className="btn button-primary px-4"
                onClick={() => {
                  setVisible(true);
                  reset();
                }}
              >
                <FontAwesomeIcon
                  style={{ color: "#fff" }}
                  icon={faPlus}
                  size="sm"
                />{" "}
                Add Pkg Material
              </button>
            </div>
          </div>
          <div className="card border-0 mt-3">
            <div className="d-flex justify-content-end mt-1 me-3">
              {/* <div className="col-md-12 roboto-bold">
            <div className="input-group">
              <input
                type="text"
                className="form-control "
                placeholder="Search by category"
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    fetchPkgMaterial("search", e.target.value);
                    setApiBySearch(true);
                  } else {
                    fetchPkgMaterial();
                  }
                }}
              />
              <div className="input-group-append">
                <span className="input-group-text">
                  <FontAwesomeIcon
                    style={{ color: "#918c8c", padding: "2px" }}
                    icon={faSearch}
                    size="lg"
                  />
                </span>
              </div>
            </div>
          </div> */}
              {/* <OverlayTrigger
              placement="top"
              overlay={<Tooltip id="tooltip">{"Download CSV"}</Tooltip>}
            >
              <div className="downloadIcon">
                <Download
                  className="my-download-svg"
                  // onClick={() => ExportToExcel("xlsx")}
                  onClick={fetchDataAndDownloadCSV}
                />
              </div>
            </OverlayTrigger> */}
            </div>
            <div className="card-body">
              <div className="table-responsive">
                {/* {state.loading ? (
                  <div className="loader-box justify-content-center">
                    <Spinner />
                  </div>
                ) : state.pkgMaterialList && state.pkgMaterialList.length > 0 ? ( */}
                  <div className="table-responsive">
                    <table
                      className="table table-bordered table-sm"
                      width={"100%"}
                    >
                      <thead className="table-grey roboto">
                        <tr className="">
                          <th scope="col" width="15%">
                            {"Sr.No"}
                          </th>
                          <th scope="col">{"Box Type"}</th>
                          <th scope="col">{"Standard Box Quantity"}</th>
                          <th scope="col">{"Dimensions"}</th>
                          <th scope="col" width="15%">
                            {"Action"}
                          </th>
                        </tr>
                      </thead>
                      <tbody className="roboto">
                        {/* {state.pkgMaterialList.map((val, i) => (
                          <tr key={i} className=""> */}
                          <tr className="">
                            {/* <td>{val.locationId}</td>
                            <td>{val.locationtype}</td>
                            <td>{val.locationname}</td>
                            <td>{val.partyname}</td>
                            <td>{val.companyname}</td> */}
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>
                              <EditPackagingMaterial
                                // data={val}
                                fetchPkgMaterial={fetchPkgMaterial}
                              />
                              <Tooltip id={"delete-tooltip"} place="top" />
                                <div
                                  data-tooltip-id={"delete-tooltip"}
                                  data-tooltip-content="Delete Pkg Material"
                                  className="deleteIcon me-2"
                                  //   onClick={() => delLocationType(val)}
                                >
                                  <Trash2 color="#FFFFFF" size={18} />
                                </div>
                            </td>
                          </tr>
                        {/* ))} */}
                      </tbody>
                    </table>
                  </div>
                {/* ) : apiBySearch ? (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"No location Found !"}
                  </p>
                ) : (
                  <p className="text-danger mb-0 f-22 text-center mt-4">
                    {"NC location list is empty"}
                  </p>
                )} */}

                {state.loading ? null : (
                  <div className="mt-4 d-flex justify-content-between">
                    <p className="mb-0">{`Showing ${
                      parseInt(srNo) + 1
                    } to ${Math.min(
                      parseInt(state.itemsPerPage) + parseInt(srNo),
                      totalRecords
                    )} of ${totalRecords} entries`}</p>
                    <Pagination className="my-2">
                      <Pagination.Prev
                        disabled={state.page === 1 ? true : false}
                        onClick={() => {
                          dispatch({
                            type: "HANDLE_ACTION",
                            page: state.page - 1,
                            loading: state.loading,
                            error: state.error,
                            pkgMaterialList: state.pkgMaterialList,
                            itemsPerPage: state.itemsPerPage,
                            maxPage: state.maxPage,
                          });
                          setSrNo((prevC) => prevC - 10);
                        }}
                      >
                        {"Prev"}
                      </Pagination.Prev>

                      <Pagination.Item active>{state.page}</Pagination.Item>

                      <Pagination.Next
                        disabled={
                          state.page === state.maxPage || state.maxPage === 0
                            ? true
                            : false
                        }
                        onClick={() => {
                          dispatch({
                            type: "HANDLE_ACTION",
                            page: state.page + 1,
                            loading: state.loading,
                            error: state.error,
                            pkgMaterialList: state.pkgMaterialList,
                            itemsPerPage: state.itemsPerPage,
                            maxPage: state.maxPage,
                          });
                          setSrNo((prevC) => prevC + 10);
                        }}
                      >
                        {"Next"}
                      </Pagination.Next>
                    </Pagination>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PackagingMaterial
import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { saveSupplier } from "../../../../../api/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";

const schema = yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      firstName: yup.string().required("First name is required !").matches(/^[a-zA-Z ]*$/, "Enter valid name"),
      mobileNo: yup.string().matches(/^[0-9]*$/, 'Enter valid mobile number').required("Mobile number is required !"),
      alternateMobileNo: yup.string().matches(/^[0-9]*$/, 'Enter valid mobile number').notRequired(),
      landlineExtentionNo: yup.string().matches(/^[0-9]*$/, 'Enter valid landline extension number').notRequired(),
      landlineNo: yup.string().matches(/^[0-9]*$/, 'Enter valid landline number').notRequired(),
      // alternateLandlineExtentionNo: yup.string().matches(/^[0-9]*$/, 'Enter valid alternate landline extension number').notRequired(),
      // alternateLandlineNo: yup.string().matches(/^[0-9]*$/, 'Enter valid alternate landline number').notRequired(),
      emailid: yup.string().email("Enter valid email id").notRequired(),
    })
  ),
});

function CreateContactDetail(props) {
  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: { items: [{}] },
  }); // initialise the hook

  const [constLabel, setConstLabel] = useState({});

  useEffect(() => {
    setConstLabel(getLabels('Party Master', 'Supplier'));
  }, [])
  
  

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({
    control,
    name: "items",
  });

  const onSubmit = (data) => {
    
    // const partyCode = JSON.parse(localStorage.getItem("partyCode"));
    const basic = JSON.parse(localStorage.getItem("basic"));
    const address = JSON.parse(localStorage.getItem("address"));

    if (data !== "" || data.items.length !== 0 ) {
      for (let i = 0; i < data.items.length; i++) {
        data.items[i].alternateLandlineExtentionNo = "";
        data.items[i].alternateLandlineNo = "";
      }

      

      const reqPayload = {
        ...basic,
        ...address,
        party_contact: data.items
      };

      

      saveSupplier(reqPayload).then(
          (res) => {
            
            if (res.status === 200 || res.status === 201) {
              toast.success(
                "Supplier created Successfully !",{
                  autoClose: 3000,
                  onClose: () => {
                    localStorage.removeItem("address");
                    localStorage.removeItem("basic");
                    props.setVisible(false);
                    props.fetchSuppliers();
                  }
                }
              );
            }
          },
          (err) => {
            
            if (err.response.status === 400) {
              
              toast.error(err.response.data.message, {
                autoClose: 3000,
              });
              reset();
            }
          }
        )
    } else {
      errors.showMessages();
    }
  };

  return (
    <>
    <ToastContainer />
      <a
        href="#"
        className="btn btn-yellow roboto-bold"
        onClick={() => append({})}
      >
        <FontAwesomeIcon style={{ color: "#000" }} icon={faPlus} size="sm" />{" "}
        {constLabel?.lbl_contact ? constLabel.lbl_contact : "Contact"}
      </a>
      {fields.map(({ id }, index) => (
        <div className="row mt-3" key={id}>
          <div className="col-md-6">
            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
                {constLabel?.lbl_first_name ? constLabel.lbl_first_name : "First Name"}
                <span>
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className={
                    errors.items?.[index]?.firstName
                      ? "form-control  is-invalid"
                      : "form-control  "
                  }
                  id="firstName"
                  name={`items[${index}].firstName`}
                  placeholder="Enter first name"
                  {...register(`items[${index}].firstName`)}
                />
                <span className="text-danger err-msg">
                  {errors.items?.[index]?.firstName?.message}
                </span>
              </div>
            </div>

            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
                {constLabel?.lbl_mobile_number ? constLabel.lbl_mobile_number : "Mobile Number"} <span>
                  <i style={{ color: "red" }}>*</i>
                </span>
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  // className="form-control "
                  className={
                    errors.items?.[index]?.mobileNo
                      ? "form-control  is-invalid"
                      : "form-control "
                  }
                  id="mobileNo"
                  maxLength={10}
                  name={`items[${index}].mobileNo`}
                  placeholder="Enter mobile no"
                  {...register(`items[${index}].mobileNo`)}
                />
                <span className="text-danger err-msg">
                  {errors.items?.[index]?.mobileNo?.message}
                </span>
              </div>
            </div>

            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
              {constLabel?.lbl_alt_mobile_no ? constLabel.lbl_alt_mobile_no : "Alt. Mobile No"}
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control "
                  id="alternateMobileNo"
                  maxLength={10}
                  name={`items[${index}].alternateMobileNo`}
                  placeholder="Enter alternate mobile"
                  {...register(`items[${index}].alternateMobileNo`)}
                />
                <span className="text-danger err-msg">
                  {errors.items?.[index]?.alternateMobileNo?.message}
                </span>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
              {constLabel?.lbl_last_name ? constLabel.lbl_last_name : "Last Name"}
                {/* <span>
                                <i style={{ color: "red" }}>*</i>
                            </span> */}
              </label>
              <div className="col-md-8">
                <input
                  type="text"
                  className="form-control "
                  id="lastName"
                  name={`items[${index}].lastName`}
                  placeholder="Enter last name"
                  {...register(`items[${index}].lastName`)}
                />
              </div>
            </div>

            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
              {constLabel?.lbl_landline_number ? constLabel.lbl_landline_number : "Landline Number"}
              </label>
              <div className="col-md-3">
                <input
                  type="text"
                  className="form-control "
                  id="landlineExtentionNo"
                  maxLength={5}
                  name={`items[${index}].landlineExtentionNo`}
                  placeholder="Extension"
                  {...register(`items[${index}].landlineExtentionNo`)}
                />
                <span className="text-danger err-msg">
                  {errors.items?.[index]?.landlineExtentionNo?.message}
                </span>
              </div>
              <div className="col-md-5">
                <input
                  type="text"
                  className="form-control "
                  id="landlineNo"
                  maxLength={8}
                  name={`items[${index}].landlineNo`}
                  placeholder="Enter Landline"
                  {...register(`items[${index}].landlineNo`)}
                />
                <span className="text-danger err-msg">
                  {errors.items?.[index]?.landlineNo?.message}
                </span>
              </div>
            </div>

            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-12 col-md-12 roboto-bold">
              {constLabel?.lbl_email ? constLabel.lbl_email : "Email"}
              </label>
              <div className="col-md-8">
                <input
                  type="email"
                  className="form-control"
                  id="emailid"
                  name={`items[${index}].emailid`}
                  placeholder="Enter email id"
                  {...register(`items[${index}].emailid`)}
                />
                <span className="text-danger err-msg">
                  {errors.items?.[index]?.emailid?.message}
                </span>
              </div>
            </div>

          </div>
          <div className="mt-3">
            <a
              href="#"
              className={
                index > 0
                  ? "btn btn-default border roboto-bold"
                  : "cursor_banned btn btn-default border roboto-bold"
              }
              onClick={index > 0 ? () => remove(index) : null}
            >
              <FontAwesomeIcon
                style={{ color: "#000", border: "1px" }}
                icon={faMinus}
                size="sm"
              />{" "}
              {constLabel?.lbl_remove ? constLabel.lbl_remove : "Remove"}
            </a>
          </div>
        </div>
      ))}
      <div className="row justify-content-between mt-2">
        <div className="col-md-4">
          <span>
            <i style={{ color: "red" }}>*</i>
            <u> indicate Mandatory fields</u>
          </span>
        </div>
        <div className="col-sm-2 text-end">
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
          </button>
        </div>
      </div>
    </>
  )
}

export default CreateContactDetail
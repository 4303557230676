import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Pagination } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { convertDateYYMMDD, getLabels } from "../../../common/Functions/CommonFunctions";
import { useNavigate, useLocation } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { getSalesOrder, getBOM, createWorkOrder } from "../../../api";
import DatePicker from "react-datepicker";
const schema = yup
  .object()
  .shape({
    startDate: yup
      .date()
      .typeError("Please select start date!")
      .required("Please select a start date!"),
    endDate: yup
      .date()
      .typeError("Please select end date!")
      .required("Please select a end date!"),
    lotSize: yup
      .string()
      .transform((value, originalValue) => originalValue == '' ? null : value)
      .matches(/^[0-9]+$/, 'Enter valid lot size')
      .nullable(),
    jobFrom: yup
      .string()
      .transform((value, originalValue) => originalValue == '' ? null : value)
      .matches(/^[0-9]+$/, 'Enter valid job from')
      .nullable(),
    jobTo: yup
      .string()
      .transform((value, originalValue) => originalValue == '' ? null : value)
      .matches(/^[0-9]+$/, 'Enter valid job to')
      .nullable(),
    status: yup.string().required("Status is required !"),
  })
  .required();

function CreateWorkOrder() {
  let navigate = useNavigate();


  const [constLabel, setConstLabel] = useState({});
  const [start_date, setStart_Date] = useState(new Date());
  const [end_date, setEnd_Date] = useState(new Date());
  const [salesOrders, setSalesOrders] = useState({ data: [], loading: false });
  const [bomList, setBomList] = useState({data: [], loading: false});

  const { handleSubmit, register, control, formState, watch, reset, setValue } =
    useForm({
      resolver: yupResolver(schema),
      mode: "onChange",
    });

  let { errors } = formState;

  const selSalesOrder = watch("sales_order");
  const selProduct = watch("productName");

  useEffect(() => {
    setConstLabel(getLabels("Production", "Work Order"));
    fetchSalesOrder();
  }, []);

  useEffect(() => {
    

    if (selSalesOrder !== undefined && selSalesOrder.length > 0) {
      setValue("partyName", selSalesOrder[0]?.customer_name);
    } else {
      setValue("partyName", "");
      setValue("productName", "");
    }

    if(selProduct !== undefined && selProduct.length > 0){
      setValue("productFamily", selProduct[0].product_id?.prod_family_id?.familyname);
      setValue("oem_part_no", selProduct[0].product_id?.OEMnumber);
      setValue("display_quantity", selProduct[0]?.quantity);
      fetchBomList(selProduct[0]?.product_id?.uuid);
      setValue("startDate", start_date);
      setValue("endDate", end_date);
      setValue("status", "Open");
      setValue("lotSize", selProduct[0]?.quantity);
    } else {
      setValue("productFamily", "");
      setValue("oem_part_no", "");
      setValue("display_quantity", "");
      setValue("endDate", new Date());
      setValue("lotSize", "");
      setValue('jobFrom', "");
      setValue('jobTo', "");
    }
  }, [selProduct, selSalesOrder]);

  function fetchSalesOrder() {
    setSalesOrders({ ...salesOrders, data: [], loading: true });

    getSalesOrder().then(
      (res) => {
        
        setSalesOrders({ ...salesOrders, data: res.data.data, loading: false });
      },
      (err) => {
        
        setSalesOrders({ ...salesOrders, data: [], loading: false });
      }
    );
  }

  function fetchBomList(prod_uuid) {
    setBomList({ ...bomList, data: [], loading: true });
    getBOM({ product_id: prod_uuid }).then(
      (res) => {
        
        setBomList({
          ...bomList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setBomList({
          ...bomList,
          data: [],
          loading: false,
        });
      }
    );
  }

  // for temporary use of below function
  // function generateRandomString() {
  //   const year = new Date().getFullYear();
  //   const randomDigits = Math.floor(1000 + Math.random() * 9000); // Generate a random 4-digit number
  
  //   return `WO-${year}-${randomDigits}`;
  // }

  const onSubmit = (data) => {
    

    if (data !== "") {
      const reqPayload = {
        // work_order_no: generateRandomString(),
        part_no: selProduct.length > 0 ? selProduct[0].product_id?.OEMnumber : null,
        quantity: selProduct.length > 0 ? parseInt(selProduct?.[0]?.quantity) : null,
        actual_quantity: data.lotSize !== null ? parseInt(data.lotSize) : null,
        unit: selProduct.length > 0 ? selProduct?.[0]?.unit : null,
        remark: data.remark,
        product_name: selProduct.length > 0 ? selProduct?.[0]?.productname : null,
        start_date: convertDateYYMMDD(data.startDate, '-'),
        end_date: convertDateYYMMDD(data.endDate, '-'),
        work_order_status: data.status,
        serial_number_start: data.jobFrom !== null ? parseInt(data.jobFrom) : null,
        serial_number_end: data.jobTo !== null ? parseInt(data.jobTo) : null,
        // "lot_no": "79952fa4-e783-4d3c-89c6-612326c9ad28",
        product_id: selProduct.length > 0 ? selProduct?.[0]?.product_id?.uuid : null,                          // product uuid present in so line item's
        // "parent_work_order": "5fd40109-9851-4bc7-9d59-841636de1bfa",
        org_id: selProduct.length > 0 ? selSalesOrder?.[0]?.org_id?.uuid : null,
        so_id: selProduct.length > 0 ? selSalesOrder?.[0]?.uuid : null,                                        // sales order uuid
        so_line_id: selProduct.length > 0 ? selProduct?.[0]?.uuid : null,                                      // so line item's uuid
      };

      

      createWorkOrder(reqPayload).then(
          (res) => {
            
            if (res.status === 200 || res.status === 201) {
              toast.success("Work order created successfully !", {
                autoClose: 2000,
              });
              setTimeout(() => {
                navigate("/pcms/work-order");
              }, 2200);
            }
          },
          (err) => {
            
            if (err.response.status === 400) {
              
              toast.error("Some field may not be null or blank ", {
                autoClose: 2000,
              });
              toast.error(err.response.data.message, {
                autoClose: 2000,
              });
              // reset();
            }
          }
        )
    } else {
      errors.showMessages();
    }
  };

  const handleFromDateChange = (date) => {
    if (end_date && date > end_date) {
      return;
    }
    setStart_Date(date);
  };


  const handleToDateChange = (date) => {
    if (start_date && date < start_date) {
      return;
    }
    setEnd_Date(date);
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="purchase_order mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                SweetAlert.fire({
                  title: "Are you sure?",
                  text: "Your present data will be lost!",
                  icon: "warning",
                  showCancelButton: true,
                  confirmButtonColor: "#3085d6",
                  cancelButtonColor: "#d33",
                  confirmButtonText: "Yes, go back!",
                }).then((result) => {
                  if (result.isConfirmed) {
                    window.onpopstate = undefined;

                    navigate("/pcms/work-order");
                  }
                });
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_create_work_order
                ? constLabel.lbl_create_work_order
                : "Create Work Order"}
            </p>
          </div>
          <div className="card-body">
            <div className="createPO container-fluid">
              <p className="roboto-bold mb-0 mt-2" style={{ fontSize: "20px" }}>
                {constLabel?.lbl_customer_order_detail
                  ? constLabel.lbl_customer_order_detail
                  : "Work Order Detail"}
              </p>

              <div className="row my-4">
                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                    {constLabel?.lbl_sales_order
                      ? constLabel.lbl_sales_order
                      : "Sales Order"}
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      name={`sales_order`}
                      control={control}
                      render={({ field: { onChange, onBlur, value, ref } }) => (
                        <Typeahead
                          id="basic-typeahead-single"
                          labelKey="so_id"
                          multiple={false}
                          options={salesOrders.data}
                          isLoading={salesOrders.loading}
                          placeholder="Select sales order"
                          onChange={onChange}
                          onBlur={onBlur}
                          selected={value}
                        />
                      )}
                    />
                  </div>
                </div>

                {selSalesOrder && selSalesOrder.length > 0 ? (
                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-12 roboto-bold"
                    >
                      {constLabel?.lbl_product_name
                        ? constLabel.lbl_product_name
                        : "Product Name"}
                    </label>

                    <div className="col-12 col-md-9">
                      <Controller
                        name={`productName`}
                        control={control}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <Typeahead
                            id="basic-typeahead-single"
                            labelKey="productname"
                            multiple={false}
                            options={selSalesOrder?.[0]?.so_order_lines}
                            placeholder="Select product name"
                            onChange={onChange}
                            onBlur={onBlur}
                            selected={value}
                          />
                        )}
                      />
                    </div>
                  </div>
                ) : null}
              </div>

              {(selSalesOrder !== undefined && selSalesOrder.length > 0) && (selProduct !== undefined && selProduct.length > 0) ? 
              <>
                <hr className="my-2" />
                <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                  {constLabel?.lbl_product_detail
                    ? constLabel.lbl_product_detail
                    : "Product Detail"}
                </p>
                <hr className="my-2" />

                <div className="row my-4">
                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-12 roboto-bold"
                    >
                      {constLabel?.lbl_party_name
                        ? constLabel.lbl_party_name
                        : "Party Name"}
                    </label>

                    <div className="col-12 col-md-9">
                      <input
                        className={
                          errors.partyName
                            ? "form-control  is-invalid"
                            : "form-control "
                        }
                        id="partyName"
                        name="partyName"
                        type="text"
                        // defaultValue={selSalesOrder !== undefined && selSalesOrder.length > 0 ? selSalesOrder[0]?.customer_name : "NA"}
                        disabled
                        placeholder="Enter party name"
                        {...register("partyName")}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-12 roboto-bold"
                    >
                      {constLabel?.lbl_product_family
                        ? constLabel.lbl_product_family
                        : "Product Family"}
                    </label>

                    <div className="col-12 col-md-9">
                      <input
                        className={
                          errors.productFamily
                            ? "form-control  is-invalid"
                            : "form-control "
                        }
                        id="productFamily"
                        name="productFamily"
                        type="text"
                        disabled
                        placeholder="Enter product family"
                        {...register("productFamily")}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-12 roboto-bold"
                    >
                      {constLabel?.lbl_oem_part_no
                        ? constLabel.lbl_oem_part_no
                        : "OEM Part Number"}
                    </label>

                    <div className="col-12 col-md-9">
                      <input
                        className={
                          errors.oem_part_no
                            ? "form-control  is-invalid"
                            : "form-control "
                        }
                        id="oem_part_no"
                        name="oem_part_no"
                        disabled
                        type="text"
                        placeholder="Enter OEM part no"
                        {...register("oem_part_no")}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-12 roboto-bold"
                    >
                      {constLabel?.lbl_quantity
                        ? constLabel.lbl_quantity
                        : "Quantity"}
                    </label>

                    <div className="col-12 col-md-9">
                      <input
                        className={
                          errors.display_quantity
                            ? "form-control  is-invalid"
                            : "form-control "
                        }
                        id="display_quantity"
                        name="display_quantity"
                        disabled
                        type="text"
                        placeholder="Enter display quantity"
                        {...register("display_quantity")}
                      />
                    </div>
                  </div>

                </div>

                <hr className="my-2" />
                <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                  {constLabel?.lbl_product_detail
                    ? constLabel.lbl_product_detail
                    : "Lot Detail"}
                </p>
                <hr className="my-2" />

                <div className="row my-4">

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                   {constLabel?.lbl_start_date ? constLabel.lbl_start_date : "Start Date"}{""}
                   <span className="text-danger">*</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      control={control}
                      name="startDate"
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.startDate
                              ? "form-control  is-invalid"
                              : "form-control  "
                          }
                          placeholderText="Select start date"
                          disabled
                          selected={start_date}
                          onChange={(date) => {
                            field.onChange(date);
                            handleFromDateChange(date)

                          }}
                          
                          minDate={start_date}
                          dateFormat="dd/MM/yyyy"
                          autoComplete="false"
                        />
                      )}
                    />

                    {errors.startDate && (
                      <span className="text-danger err-msg">
                        {errors.startDate.message}
                      </span>
                    )}
                  </div>
                </div>

                <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label col-12 col-md-12 roboto-bold"
                  >
                   {constLabel?.lbl_end_date ? constLabel.lbl_end_date : "End Date"}{""}
                   <span className="text-danger">*</span>
                  </label>

                  <div className="col-12 col-md-9">
                    <Controller
                      control={control}
                      name="endDate"
                      render={({ field }) => (
                        <DatePicker
                          className={
                            errors.endDate
                              ? "form-control  is-invalid"
                              : "form-control  "
                          }
                          placeholderText="Select end date"
                          selected={end_date}
                          onChange={(date) => {
                            field.onChange(date);
                            handleToDateChange(date)

                          }}
                          minDate={new Date()}
                          dateFormat="dd/MM/yyyy"
                          autoComplete="false"
                        />
                      )}
                    />

                    {errors.endDate && (
                      <span className="text-danger err-msg">
                        {errors.endDate.message}
                      </span>
                    )}
                  </div>
                </div>

                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-12 roboto-bold"
                    >
                      {constLabel?.lbl_lot_size
                        ? constLabel.lbl_lot_size
                        : "Lot Size"}
                    </label>

                    <div className="col-12 col-md-9">
                      <input
                        className={
                          errors.lotSize
                            ? "form-control  is-invalid"
                            : "form-control "
                        }
                        id="lotSize"
                        name="lotSize"
                        type="text"
                        placeholder="Enter lot size"
                        {...register("lotSize")}
                      />
                      {errors.lotSize && (
                      <span className="text-danger err-msg">
                        {errors.lotSize.message}
                      </span>
                    )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-12 roboto-bold"
                    >
                      {constLabel?.lbl_job_from
                        ? constLabel.lbl_job_from
                        : "Job From"}
                    </label>

                    <div className="col-12 col-md-9">
                      <input
                        className={
                          errors.jobFrom
                            ? "form-control  is-invalid"
                            : "form-control "
                        }
                        id="jobFrom"
                        name="jobFrom"
                        type="text"
                        placeholder="Enter job from"
                        {...register("jobFrom")}
                      />
                      {errors.jobFrom && (
                      <span className="text-danger err-msg">
                        {errors.jobFrom.message}
                      </span>
                    )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-12 roboto-bold"
                    >
                      {constLabel?.lbl_job_to
                        ? constLabel.lbl_job_to
                        : "Job To"}
                    </label>

                    <div className="col-12 col-md-9">
                      <input
                        className={
                          errors.jobTo
                            ? "form-control  is-invalid"
                            : "form-control "
                        }
                        id="jobTo"
                        name="jobTo"
                        type="text"
                        placeholder="Enter job to"
                        {...register("jobTo")}
                      />
                      {errors.jobTo && (
                      <span className="text-danger err-msg">
                        {errors.jobTo.message}
                      </span>
                    )}
                    </div>
                  </div>

                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-12 roboto-bold"
                    >
                      {constLabel?.lbl_status
                        ? constLabel.lbl_status
                        : "Status"}
                        <span className="text-danger">*</span>
                    </label>

                    <div className="col-12 col-md-9">
                      <input
                        className={
                          errors.status
                            ? "form-control  is-invalid"
                            : "form-control "
                        }
                        id="status"
                        name="status"
                        type="text"
                        disabled
                        placeholder="Enter status"
                        {...register("status")}
                      />
                    </div>
                  </div>

                  <div className="col-12 col-md-6  row mb-3 me-0 me-md-1">
                    <label
                      style={{ fontSize: "14px" }}
                      className="col-form-label col-12 col-md-12 roboto-bold"
                    >
                      {constLabel?.lbl_remark
                        ? constLabel.lbl_remark
                        : "Remark"}
                    </label>

                    <div className="col-12 col-md-9">
                      <textarea
                        className={
                          errors.remark
                            ? "form-control  is-invalid"
                            : "form-control "
                        }
                        id="remark"
                        name="remark"
                        type="text"
                        placeholder="Enter remark"
                        {...register("remark")}
                      ></textarea>
                    </div>
                  </div>

                </div>

                <hr className="my-2" />
                <p className="roboto-bold mb-0" style={{ fontSize: "20px" }}>
                  {constLabel?.lbl_bom_detail
                    ? constLabel.lbl_bom_detail
                    : "BOM Detail"}
                </p>
                <hr className="my-2" />

                <div className="row my-4">
                {bomList.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : bomList.data.length > 0 ? (
                  <>
                    <div
                      className=""
                      style={{ overflowX: "scroll" }}
                    >
                      <table className="table mt-2 table-responsive table-bordered table-sm">
                        <thead className="table-grey roboto-bold">
                          <tr className="">
                            <th scope="col">{"Sr.No"}</th>
                            <th scope="col">{constLabel?.lbl_product_name ? constLabel.lbl_product_name : "Product Name"}</th>
                            <th scope="col">{constLabel?.lbl_product_name ? constLabel.lbl_product_name : "Product Type"}</th>
                            <th scope="col">{constLabel?.lbl_product_name ? constLabel.lbl_product_name : "BOM Type"}</th>
                            <th scope="col">{constLabel?.lbl_product_name ? constLabel.lbl_product_name : "Quantity"}</th>
                            <th scope="col" width="15%">
                              {"Action"}
                            </th>
                          </tr>
                        </thead>
                        <tbody className="roboto">
                          {bomList.data.map((val, ind) => (
                            <tr key={ind}>
                              <td>{parseInt(ind) + 1}</td>
                              <td>{val.productname != "" && val.productname !== null ? val.productname : "NA"}</td>
                              <td>{val.productType != "" && val.productType !== null ? val.productType : "NA"}</td>
                              <td>{val.bom_type != "" && val.bom_type !== null ? val.bom_type : "NA"}</td>
                              <td>{val.qty != "" && val.qty !== null ? val.qty : "NA"}</td>
                              
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </>
                ) : (
                  <div
                    className="alert alert-danger mb-0 text-center my-1"
                    role="alert"
                  >
                    {"Product BOM not found !"}
                  </div>
                )}

                </div>

              </> : 
              <div
              className="alert alert-danger mb-0 text-center my-4"
              role="alert"
            >
               {"Please select sales order and product name ! "}
            </div>
              }
            </div>
          </div>

          <div className="card-footer d-flex justify-content-end py-3">
            <button
              className="btn btn-grey px-4 ms-0 ms-md-2"
              onClick={handleSubmit(onSubmit)}
            >
              {constLabel?.lbl_save ? constLabel.lbl_save : "Save"}
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default CreateWorkOrder;

import React, { useState, useEffect } from "react";
import { useForm, Controller, useFieldArray } from "react-hook-form";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS
import { Trash2, Eye } from "react-feather";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SweetAlert from 'sweetalert2';
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faUpload,
  faClose,
} from "@fortawesome/free-solid-svg-icons";
import {
  getOperationApi,
  getRoutesApi,
  getMachineList,
  getProdConfig,
  uploadMultiImage,
  createProdConfig,
  updateProdConfig,
  getProdImages,
  deleteProdOperation,
  deleteProdMachine,
} from "../../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import CloneDrawingGallary from "./CloneDrawingGallary";
import CloneMachineModal from "./CloneMachineModal";
import { getLabels } from "../../../../common/Functions/CommonFunctions";
import CloneBom from "./CloneBom";

const schema = yup
  .object()
  .shape({
    items: yup.array().of(
      yup.object().shape({
        sequenceNo: yup
          .string()
          .matches(/^\d+$/, "Enter valid sequence")
          .required("Please enter sequence"),
        operation: yup
          .array()
          .required("Please select operation")
          .min(1, "Please select operation"),
        oprcost: yup
          .string()
          .required("Operation cost is required !")
          .matches(/^\d+(\.\d+)?$/, "Enter valid operation cost"),
        route: yup
          .array()
          .required("Please select route")
          .min(1, "Please select route"),
      })
    ),
  })
  .required();

function CloneProductSpecification({
  nextStep,
  previousStep,
  main_obj,
  setMain_obj,
}) {
  const [drawingFiles, setDrawingFiles] = useState([{ img: [] }]);
  const [machines, setMachines] = useState([{ data: [] }]);
  const [images, setImages] = useState({ loading: false, data: [] });

  const [operationList, setOperationList] = useState({
    data: [],
    loading: false,
  });

  const [action, setAction] = useState("create");
  const [routesList, setRoutesList] = useState({ data: [], loading: false });
  const [galVisible, setGalVisible] = useState(false);
  const [machineModal, setMachineModal] = useState({
    state: false,
    mode: "create",
    selMachine: {},
    macInd: "",
  });

  const [currIndex, setCurrIndex] = useState(0);
  const [machineList, setMachineList] = useState({ data: [], loading: false });
  const [operList, setOperList] = useState({ data: [], loading: false });
  const [constLabel, setConstLabel] = useState({});
  const [btnDisable, setBtnDisable] = useState(false);

  const [bomShow, setBomShow] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    
  }, []);

  // const productData = JSON.parse(localStorage.getItem("prod_res"));
  // const bom_status = JSON.parse(localStorage.getItem('bomStatus'));

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    getValues,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [{}],
    },
  });

  let { errors } = formState;

  const { fields, append, remove } = useFieldArray({ control, name: "items" });
  // const prod_data = JSON.parse(localStorage.getItem("prod_res"));

  function removeMachine(index) {
    let tempMachines = [...machines];

    tempMachines[index]?.data?.splice(index, 1);
    setMachines(tempMachines);
    
  }

  function fetchImages(index) {
    
    

    if (fields[index].po_uuid !== undefined) {
      setImages({ ...images, loading: true, data: [] });
      getProdImages({ product_operation_id: fields[index].po_uuid }).then(
        (res) => {
          

          setImages({ ...images, loading: false, data: res.data.data });
        },
        (err) => {
          
          setImages({ ...images, loading: false, data: [] });
        }
      );
    } else {
      setImages({ ...images, loading: true, data: [] });
    }
  }

  useEffect(() => {
    setConstLabel(getLabels("Master", "Product"));
    setRoutesList({ ...routesList, data: [], loading: true });
    setOperationList({ ...operationList, data: [], loading: true });

    getRoutesApi().then(
      (res) => {
        
        setRoutesList({ ...routesList, data: res.data.data, loading: false });
      },
      (err) => {
        
        setRoutesList({ ...routesList, data: [], loading: false });
      }
    );

    getOperationApi().then(
      (res) => {
        
        setOperationList({
          ...operationList,
          data: res.data.data,
          loading: false,
        });
      },
      (err) => {
        
        setOperationList({ ...operationList, data: [], loading: false });
      }
    );

    setMachineList({ ...machineList, data: [], loading: true });
    getMachineList().then(
      (res) => {
        const result = res.data.data.filter(item => item.machineName !== "" && item.machineName !== null && item.machineName !== undefined);
        
        const ress = result.map((item) => ({
          ...item,
          machineNameSerialNo: item.machineName + " - " + item.serialNo,
        }));
        setMachineList({ ...machineList, data: ress, loading: false });
      },
      (err) => {
        
        setMachineList({ ...machineList, data: [], loading: false });
      }
    );
    if (main_obj.prod_overview.uuid !== undefined) {
      getchProdConfig();
    }

  }, []);

  function getchProdConfig() {
    setOperList({ ...operList, data: [], loading: true });

    getProdConfig({ product_id: main_obj?.prod_overview?.uuid }).then(
      (res) => {
        
        setOperList({ ...operList, data: res.data.data, loading: false });

        if (res.data.data.length == 0) {
          getProdConfig({ product_id: main_obj.old_prod_uuid }).then(
            (res) => {
              
              setOperList({ ...operList, data: res.data.data, loading: false });


              const tempList = [];

              for (let i = 0; i < res.data.data.length; i++) {
                tempList.push({
                  sequenceNo: res.data.data[i].sequenceNo,
                  serial_number_tracking: res.data.data[i].serial_number_tracking,
                  // po_uuid: res.data.data[i].uuid,
                  operation: [
                    {
                      ProdOperationId: res.data.data[i].ProdOperationId,
                      uuid: res.data.data[i].operation_id,
                      operationName: res.data.data[i].operationname,
                      operationID: res.data.data[i].operationid,
                    },
                  ],
                  oprcost: res.data.data[i].oprcost,
                  route: [
                    {
                      routesId: res.data.data[i].routes1,
                      routesType: res.data.data[i].routesname,
                      uuid: res.data.data[i].routes_id,
                    },
                  ],
                });
              }


              let tempMachines = [...machines];
              const response = [...res.data.data];

              let dfiles = [...drawingFiles];


              if (response.length > 0) {

                for (let i = 0; i < response.length; i++) {
                  if (i !== 0) {
                    tempMachines.push({ data: [] });
                    dfiles.push({ img: [] });
                  }
                  for (let j = 0; j < response[i].machine.length; j++) {
                    let data = {
                      cycleTime: response[i].machine[j].cycle_time,
                      machineRate: response[i].machine[j].machine_rate,
                      settingTime: response[i].machine[j].settings_time,
                      toolChangeTime: response[i].machine[j].tool_change_time,
                      machine: [{
                        machineName: response[i].machine[j].name,
                        serialNo: response[i].machine[j].serialNo,
                        uuid: response[i].machine[j].machine_id_id,
                        machineId: response[i].machine[j].machine_id,
                        opmachine_id: response[i].machine[j].opmachine_id,
                        machineNameSerialNo: response[i].machine[j].name + " - " + response[i].machine[j].serialNo,
                      }]
                    }
                    // Find the index of the matching object in tempMachines[i]?.data[i]
                    const indexInTempMachines = tempMachines[i]?.data.findIndex(
                      tempMachine => tempMachine?.uuid === data.uuid
                    );

                    if (indexInTempMachines !== -1) {
                      // Delete the matching object from tempMachines[i]?.data[i]
                      tempMachines[i]?.data.splice(indexInTempMachines, 1);
                    }

                    // Push the currentResponseMachine into tempMachines[i]?.data
                    tempMachines[i]?.data.push(data);
                  }
                }

                
                setMachines(tempMachines);
                setDrawingFiles(dfiles);
              }

              
              reset({
                items: tempList.length > 0 ? tempList : [{}],
              });
            },
            (err) => {
              
              setOperList({ ...operList, data: [], loading: false });
            }
          );
          setAction("create");
        } else {
          setAction("update");
          const tempList = [];

          for (let i = 0; i < res.data.data.length; i++) {
            tempList.push({
              sequenceNo: res.data.data[i].sequenceNo,
              po_uuid: res.data.data[i].uuid,
              serial_number_tracking: res.data.data[i].serial_number_tracking,
              operation: [
                {
                  ProdOperationId: res.data.data[i].ProdOperationId,
                  uuid: res.data.data[i].operation_id,
                  operationName: res.data.data[i].operationname,
                  operationID: res.data.data[i].operationid,
                },
              ],
              oprcost: res.data.data[i].oprcost,
              route: [
                {
                  routesId: res.data.data[i].routes1,
                  routesType: res.data.data[i].routesname,
                  uuid: res.data.data[i].routes_id,
                },
              ],
            });
          }

          let tempMachines = [...machines];
          const response = [...res.data.data];
          let dfiles = [...drawingFiles];

          if (response.length > 0) {
            for (let i = 0; i < response.length; i++) {
              if (i !== 0) {
                tempMachines.push({ data: [] });
                dfiles.push({ img: [] });
              }
              for (let j = 0; j < response[i].machine.length; j++) {
                let data = {
                  cycleTime: response[i].machine[j].cycle_time,
                  machineRate: response[i].machine[j].machine_rate,
                  settingTime: response[i].machine[j].settings_time,
                  toolChangeTime: response[i].machine[j].tool_change_time,
                  machine: [
                    {
                      machineName: response[i].machine[j].name,
                      serialNo: response[i].machine[j].serialNo,
                      uuid: response[i].machine[j].machine_id_id,
                      machineId: response[i].machine[j].machine_id,
                      machineNameSerialNo: response[i].machine[j].name + " - " + response[i].machine[j].serialNo,
                    },
                  ],
                  uuid: response[i].machine[j].uuid,
                };

                // Find the index of the matching object in tempMachines[i]?.data[i]
                const indexInTempMachines = tempMachines[i]?.data.findIndex(
                  tempMachine => tempMachine?.uuid === data.uuid
                );

                if (indexInTempMachines !== -1) {
                  // Delete the matching object from tempMachines[i]?.data[i]
                  tempMachines[i]?.data.splice(indexInTempMachines, 1);
                }

                // Push the currentResponseMachine into tempMachines[i]?.data
                tempMachines[i]?.data.push(data);

                // tempMachines[i].data?.push(data);
              }
            }

            
            setMachines(tempMachines);
            setDrawingFiles(dfiles);
          }

          
          reset({
            items: tempList,
          });
        }
      },
      (err) => {
        
        setOperList({ ...operList, data: [], loading: false });
      }
    );
  }

  function removeMachine(machine) {
    
    SweetAlert.fire({
      title: "Are you sure to delete \n" + machine.name,
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteProdMachine({ uuid: machine.uuid }).then(
          (res) => {
            
            toast.success("Machine deleted successfully", {
              autoClose: 2000,
            });
            getchProdConfig();
          },
          (err) => {
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
            
          }
        );
      }
    });
  }

  function removeMac(index, maci) {
    
    let tempMachines = [...machines];

    tempMachines[index].data.splice(maci, 1);
    setMachines(tempMachines);
    
  }

  const onSubmit = (data) => {
    setBtnDisable(true);
    // setMachineData([...machineData, data]);
    
    
    
    
    

    let primaryData = [];
    let drawingData = [];
    let machineData = [];

    if (action == "create") {
      for (let i = 0; i < data.items.length; i++) {
        primaryData.push({
          productid: main_obj && main_obj?.prod_overview?.productId,
          productname: main_obj && main_obj?.prod_overview?.productName,
          prodType1: main_obj && main_obj?.prod_overview?.productType,
          product_id: main_obj && main_obj?.prod_overview?.uuid,
          product_type_id:
            main_obj && main_obj?.prod_overview?.product_type_id.uuid,
          operation_id: data.items[i].operation[0].uuid,
          operationname: data.items[i].operation[0].operationName,
          operationid: data.items[i].operation[0].operationID,
          oprcost: data.items[i].oprcost,
          sequenceNo: data.items[i].sequenceNo,
          routes1: data.items[i].route[0].routesId,
          routesname: data.items[i].route[0].routesType,
          routes_id: data.items[i].route[0].uuid,
          serial_number_tracking: data.items[i].serial_number_tracking,
        });
      }

      for (let j = 0; j < machines.length; j++) {
        machineData.push([]);
        for (let k = 0; k < machines[j].data.length; k++) {
          machineData[j].push({
            productid: main_obj && main_obj?.prod_overview?.productId,
            operation_id: data.items[j].operation[0].operationID,
            machine_id: machines[j].data[k].machine[0].machineId,
            name: machines[j].data[k].machine[0].machineName,
            serialNo: machines[j].data[k].machine[0].serialNo,
            cycle_time: machines[j].data[k].cycleTime,
            settings_time: machines[j].data[k].settingTime,
            tool_change_time: machines[j].data[k].toolChangeTime,
            machine_rate: machines[j].data[k].machineRate,
            product_id_id: main_obj && main_obj?.prod_overview?.uuid,
            // operation_id_id: data.items[j].operation[0].uuid,
            machine_id_id: machines[j].data[k].machine[0].uuid,
            uuid: machines[j].data[k].uuid,
          });
        }
      }

      for (let x = 0; x < drawingFiles.length; x++) {
        // drawingData.push([]);
        for (let y = 0; y < drawingFiles[x].img.length; y++) {
          // drawingData[x].push({
          drawingData.push({
            name: drawingFiles[x].img[y].filename,
            attachment: drawingFiles[x].img[y].file,
            doc_type: drawingFiles[x].img[y].docType[0].name,
            // product_operation: data.items[x].operation[0].uuid,
            doc_type_id: drawingFiles[x].img[y].docType[0].uuid,
          });
        }
      }

      for (let a = 0; a < primaryData.length; a++) {
        primaryData[a].product_machine = machineData[a];
        // primaryData[a].operation_drawing = drawingData[a];
        // primaryData[a].operation_drawing = [];
      }

      
      
      

      const payload = {
        product_operation: primaryData,
      };
      

      createProdConfig(payload).then(
        (res) => {
          setBtnDisable(false);
          

          let imgArr = {};

          

          for (let a = 0; a < drawingData.length; a++) {
            if(res){
              drawingData[a].product_operation_id = res.data.data[a].product_operation.uuid;
            }
            // for (let b = 0; b < drawingData[a].length; b++) {
              
            //   imgArr["attachment" + (b + 1)] = drawingData[a][b].attachment;
            //   // delete drawingData[a][b].attachment;
            //   drawingData[a][b].product_operation =
            //     res.data.data[a].product_operation.uuid;
            //   imgArr["obj" + (b + 1)] = JSON.stringify(drawingData[a][b]);
            //   imgArr["img_count"] = drawingData[a].length;
            // }
            

            // let formDataToSend = new FormData();

            // for (const [key, value] of Object.entries(imgArr)) {
            //   formDataToSend.append(key, value);
            // }
            // imgArr = {};

            if (drawingData.length > 0) {
              uploadMultiImage({data: drawingData}).then(
                (res) => {
                  

                  toast.success(
                    "Operation sequence created successfully",
                    {
                      autoClose: 2000,
                    }
                  );

                  // let bom_status = JSON.parse(localStorage.getItem("bomStatus"));
                  
                  // if (bom_status == false || bom_status == null) {
                  //   localStorage.removeItem("prod_res");
                  //   setTimeout(() => {
                  //     navigate("/pcms/product");
                  //   }, [2200]);

                  // } else {
                  //   props.setCurrentStep((prevStep) => prevStep + 1);
                  // }
                },
                (err) => {
                  

                  toast.error("Something went wrong in saving images", {
                    autoClose: 2000,
                  });
                }
              );
            } else {
              toast.success("Operation sequence created successfully", {
                autoClose: 2000,
              });
            }
          }

          setTimeout(() => {
            nextStep();
          }, [2300]);
        },
        (err) => {
          setBtnDisable(false);
          
          toast.error("Something went wrong in saving operation", {
            autoClose: 2000,
          });
        }
      );
    } else {
      for (let i = 0; i < data.items.length; i++) {
        let temp = {
          ProdOperationId: data.items[i].operation[0].ProdOperationId,
          uuid: data.items[i].po_uuid,
          productid: main_obj && main_obj?.prod_overview?.productId,
          productname: main_obj && main_obj?.prod_overview?.productName,
          prodType1: main_obj && main_obj?.prod_overview?.productType,
          product_id: main_obj && main_obj?.prod_overview?.uuid,
          product_type_id:
            main_obj && main_obj?.prod_overview?.product_type_id.uuid,
          operation_id: data.items[i].operation[0].uuid,
          operationname: data.items[i].operation[0].operationName,
          operationid: data.items[i].operation[0].operationID,
          oprcost: data.items[i].oprcost,
          sequenceNo: data.items[i].sequenceNo,
          routes1: data.items[i].route[0].routesId,
          routesname: data.items[i].route[0].routesType,
          routes_id: data.items[i].route[0].uuid,
          serial_number_tracking: data.items[i].serial_number_tracking,
        };
        if (data.items[i].operation[0].ProdOperationId == undefined) {
          delete temp.ProdOperationId;
        }
        if (data.items[i].po_uuid == undefined) {
          delete temp.uuid;
        }
        primaryData.push(temp);
      }

      for (let j = 0; j < machines.length; j++) {
        machineData.push([]);
        for (let k = 0; k < machines[j].data.length; k++) {
          machineData[j].push({
            productid: main_obj && main_obj?.prod_overview?.productId,
            operation_id: data.items[j].operation[0].operationID,
            machine_id: machines[j].data[k].machine[0].machineId,
            name: machines[j].data[k].machine[0].machineName,
            serialNo: machines[j].data[k].machine[0].serialNo,
            cycle_time: machines[j].data[k].cycleTime,
            settings_time: machines[j].data[k].settingTime,
            tool_change_time: machines[j].data[k].toolChangeTime,
            machine_rate: machines[j].data[k].machineRate,
            product_id_id: main_obj && main_obj?.prod_overview?.uuid,
            // operation_id_id: data.items[j].operation[0].uuid,
            machine_id_id: machines[j].data[k].machine[0].uuid,
            uuid: machines[j].data[k].uuid,
          });
        }
      }

      for (let x = 0; x < drawingFiles.length; x++) {
        // drawingData.push([]);
        for (let y = 0; y < drawingFiles[x].img.length; y++) {
          drawingData.push({
            name: drawingFiles[x].img[y].filename,
            attachment: drawingFiles[x].img[y].file,
            doc_type: drawingFiles[x].img[y].docType[0].name,
            // product_operation: data.items[x].operation[0].uuid,
            doc_type_id: drawingFiles[x].img[y].docType[0].uuid,
          });
        }
      }

      
      

      
      for (let z = 0; z < primaryData.length; z++) {
        primaryData[z].product_machine = machineData[z];
        // primaryData[z].operation_drawing = [];
      }

      const payload = {
        product_operation: primaryData,
      };
      

      updateProdConfig(payload).then(
        (res) => {
          setBtnDisable(false);
          
          // let imgArr = {};

          for (let i = 0; i < drawingData.length; i++) {
            if(res){
              drawingData[i].product_operation_id = res.data.data[i].product_operation.uuid;
            }
            // if (drawingData[i].length > 0) {
            //   for (let b = 0; b < drawingData[i].length; b++) {
                
            //     imgArr["attachment" + (b + 1)] = drawingData[i][b].attachment;
            //     drawingData[i][b].product_operation =
            //       res.data.data[i].prod_operation.uuid;
            //     imgArr["obj" + (b + 1)] = JSON.stringify(drawingData[i][0]);
            //     imgArr["img_count"] = drawingData[i].length;
            //   }
            // }
          }
          

          // let formDataToSend = new FormData();

          // for (const [key, value] of Object.entries(imgArr)) {
          //   formDataToSend.append(key, value);
          // }

          
          if (drawingData.length > 0) {
            uploadMultiImage({data: drawingData}).then(
              (ress) => {
                
                toast.success("Operation sequence updated successfully", {
                  autoClose: 2000,
                });


                setTimeout(() => {
                  nextStep();
                }, [2300]);
              },
              (err) => {
                
                toast.error("Something went wrong in saving images", {
                  autoClose: 2000,
                });
              }
            );
          } else {
            toast.success("Operation sequence updated successfully", {
              autoClose: 2000,
            });

            setTimeout(() => {
              nextStep();
            }, [2300]);
          }
          drawingData = [];
        },
        (err) => {
          setBtnDisable(false);
          toast.error("Something went wrong in updating", {
            autoClose: 2000,
          });
          
        }
      );
    }
  };

  function delProdOper(index) {
    

    SweetAlert.fire({
      title: "Are you sure to delete",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        deleteProdOperation({ uuid: fields[index].po_uuid }).then(
          (res) => {
            
            getchProdConfig();
          },
          (err) => {
            
          }
        );
      }
    });
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <CloneDrawingGallary
        galVisible={galVisible}
        setGalVisible={setGalVisible}
        setDrawingFiles={setDrawingFiles}
        drawingFiles={drawingFiles}
        currIndex={currIndex}
        fetchImages={fetchImages}
        images={images}
        action={action}
        operList={operList}
        fields={fields}

      />

      {machineModal.state ? (
        <CloneMachineModal
          machineList={machineList}
          machineModal={machineModal}
          setMachineModal={setMachineModal}
          machines={machines}
          setMachines={setMachines}
          currIndex={currIndex}
          fields={fields}
          getchProdConfig={getchProdConfig}
          propsData={main_obj.prod_overview}
        />
      ) : null}
      {bomShow === false ? (
        <div className="prod_configurator card shadow-sm rounded-0 p-2">
          <div className="card-body">
            <div className="d-flex justify-content-start">
              <p className="pc_card_title roboto-bold">
                {constLabel?.lbl_product_specification
                  ? constLabel.lbl_product_specification
                  : "Product Specification"}
              </p>
            </div>

            {fields.length > 0 ? (
              fields.map(({ id }, index) => (
                <React.Fragment key={index}>
                  <div className="col-12 row me-0 me-md-1 vf-danger">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <label className="lbl-style col-form-label roboto-bold">
                          {constLabel?.lbl_product_name
                            ? constLabel.lbl_product_name
                            : "Product Name"}
                        </label>

                        <label
                          className="col-form-label roboto-bold ms-0 ms-md-2"
                          style={{ color: "#EEAE2D" }}
                        >
                          {main_obj && main_obj?.prod_overview?.productName}
                        </label>
                      </div>
                      <div>
                        <FontAwesomeIcon
                          style={{
                            color: "black",
                            cursor: "pointer",
                          }}
                          icon={faClose}
                          size="lg"
                          onClick={() => {
                            setCurrIndex((prevCurr) => parseInt(prevCurr) - 1);

                            if (fields[index].po_uuid !== undefined) {
                              delProdOper(index);
                            } else {
                              remove(index);
                              let tempMachines = [...machines];
                              tempMachines.splice(index, 1);
                              setMachines(tempMachines);
                            }

                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row mt-3 mb-5">
                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-5">
                          <div className="form-group">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_sequence_no
                                ? constLabel.lbl_sequence_no
                                : "Sequence No."}
                            </label>
                            <input
                              className={
                                errors.items?.[index]?.sequenceNo
                                  ? "form-control mt-2 is-invalid"
                                  : "form-control mt-2 "
                              }
                              type="text"
                              {...register(`items[${index}].sequenceNo`)}
                              placeholder="Enter sequence no."
                            />

                            {errors.items?.[index]?.sequenceNo && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.sequenceNo.message}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-7">
                          <div className="form-group">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_operation_name
                                ? constLabel.lbl_operation_name
                                : "Operation Name"}
                            </label>
                            <Controller
                              name={`items[${index}].operation`}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  className="mt-2"
                                  id="basic-typeahead"
                                  labelKey="operationName"
                                  multiple={false}
                                  options={operationList.data}
                                  isLoading={false}
                                  placeholder="Select operation"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={
                                    errors.items?.[index]?.operation
                                      ? true
                                      : false
                                  }
                                />
                              )}
                            />

                            {errors.items?.[index]?.operation && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.operation.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_operation_cost
                                ? constLabel.lbl_operation_cost
                                : "Operation Cost "}
                              <span>&#8377;</span>
                            </label>
                            <input
                              className={
                                errors.items?.[index]?.oprcost
                                  ? "form-control mt-2 is-invalid"
                                  : "form-control mt-2 "
                              }
                              type="text"
                              defaultValue={0}
                              placeholder="Enter operation cost"
                              {...register(`items[${index}].oprcost`)}
                            />

                            {errors.items?.[index]?.oprcost && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.oprcost.message}
                              </span>
                            )}
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label className="lbl-style roboto-bold">
                              {constLabel?.lbl_route
                                ? constLabel.lbl_route
                                : "Route"}
                            </label>
                            <Controller
                              name={`items[${index}].route`}
                              control={control}
                              render={({
                                field: { onChange, onBlur, value, ref },
                              }) => (
                                <Typeahead
                                  className="mt-2"
                                  id="basic-typeahead"
                                  labelKey="routesType"
                                  multiple={false}
                                  options={routesList.data}
                                  isLoading={routesList.loading}
                                  placeholder="Select route"
                                  onChange={onChange}
                                  onBlur={onBlur}
                                  selected={value}
                                  isInvalid={
                                    errors.items?.[index]?.route ? true : false
                                  }
                                />
                              )}
                            />

                            {errors.items?.[index]?.route && (
                              <span className="text-danger err-msg">
                                {errors.items?.[index]?.route.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 machineDropdown">
                      <div className="d-flex">
                        <div>
                          <div className="mt-2">
                            <label className="invisible">Dropdown</label>
                          </div>

                          <button
                            className="btn btn-grey px-3"
                            onClick={() => {
                              setCurrIndex(index);
                              setMachineModal({
                                ...machineModal,
                                state: true,
                                mode: "create",
                              });
                            }}
                          >
                            <FontAwesomeIcon
                              style={{
                                color: "#fff",
                              }}
                              icon={faAdd}
                              size="sm"
                              className="me-2"
                            />
                            {constLabel?.lbl_add_machine
                              ? constLabel.lbl_add_machine
                              : "Add machine"}
                          </button>
                        </div>

                        <div className="ms-0 ms-md-3">
                          <div className="mt-2">
                            <label className="invisible">Dropdown</label>
                          </div>
                          <button
                            className="btn btn-yellow-wt px-3"
                            onClick={() => {
                              setGalVisible(true);
                              setCurrIndex(index);
                              if (action == "update") {
                                fetchImages(index);
                              }
                            }}
                          >
                            <FontAwesomeIcon
                              style={{
                                color: "#fff",
                                marginRight: "5px",
                              }}
                              icon={faUpload}
                              size="sm"
                            />{" "}
                            {constLabel?.lbl_drawing
                              ? constLabel.lbl_drawing
                              : "Drawing"}
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="form-check mt-3 ms-3">

                      <Controller
                        name={`items[${index}].serial_number_tracking`}
                        control={control}
                        // defaultValue={false}
                        render={({
                          field: { onChange, onBlur, value, ref },
                        }) => (
                          <>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              // value=""
                              id={"serialNumTracking" + index}
                              // {...field}
                              onChange={onChange}
                              onBlur={onBlur}
                              checked={value}
                            />
                            <label
                              className="form-check-label" htmlFor={"serialNumTracking" + index}
                              style={{ fontSize: '14px', color: '#344454' }}>
                              Serial number tracking
                            </label>
                          </>
                        )}
                      />


                    </div>
                  </div>

                  {machines[index]?.data?.length > 0 ? (
                    <div className="pill-container">
                      {machines[index]?.data?.map((mac, maci) => (
                        <div className="custom-pill" key={maci}>
                          <u
                            style={{ color: "blue" }}
                            onClick={() =>
                              {setCurrIndex(index);
                                setMachineModal({
                                ...machineModal,
                                state: true,
                                mode: "update",
                                selMachine: mac,
                                macInd: maci,
                              })
                            }
                            }
                          >
                            {mac.machine[0]?.machineName +
                              " - " +
                              mac.cycleTime}
                          </u>

                          <FontAwesomeIcon
                            style={{
                              color: "#fff",
                              cursor: "pointer",
                              marginLeft: "20px",
                            }}
                            icon={faClose}
                            size="lg"
                            onClick={() => {
                              if (mac.uuid !== undefined) {
                                removeMachine(mac);
                              } else {
                                removeMac(index, maci);
                              }
                            }}
                          />
                        </div>
                      ))}
                    </div>
                  ) : null}

                  <hr className="my-4" />
                </React.Fragment>
              ))
            ) : (
              <div
                className="alert alert-danger mb-0 text-center my-4"
                role="alert"
              >
                {"No product operation routines found !"}
              </div>
            )}

            <div>
              <span
                onClick={() => {
                  append({});
                  let tempdimg = [...drawingFiles];
                  tempdimg.push({ img: [] });
                  setDrawingFiles(tempdimg);

                  let tempMachines = [...machines];
                  
                  tempMachines.push({ data: [] });
                  setMachines(tempMachines);
                  setCurrIndex((prevCurr) => parseInt(prevCurr) + 1);
                }}
                className="mt-4 mb-0 text_gold roboto-bold cursor_pointer"
                style={{ fontSize: "20px" }}
              >
                {"+"}{" "}
                {constLabel?.lbl_add_sequence
                  ? constLabel?.lbl_add_sequence
                  : "Add Sequence"}
              </span>
            </div>

            <div className="d-flex justify-content-end">
              {/* <button
          className="btn btn-yellow px-4 me-2"
          onClick={() => navigate("/pcms/product")}
        >
          {constLabel?.lbl_cancel ? constLabel?.lbl_cancel : ""}
        </button> */}
              <button
                className="btn btn-grey px-4 me-2"
                onClick={() => previousStep()}
              >
                {constLabel?.lbl_previous
                  ? constLabel?.lbl_previous
                  : "Previous"}
              </button>
              <button
                className="btn button-primary px-4 me-2"
                onClick={handleSubmit(onSubmit)}
                disabled={btnDisable}
              >
                {constLabel?.lbl_save_continue
                  ? constLabel?.lbl_save_continue
                  : "Save & Continue"}
              </button>


              <button
                className="btn btn-grey px-4 "
                // onClick={() => props.setCurrentStep(props.currentStep + 1)}
                onClick={() => { nextStep(); }}
              >
                {constLabel?.lbl_skip ? constLabel?.lbl_skip : "Skip"}
              </button>

            </div>
          </div>
        </div>
      ) : (
        <CloneBom
          main_obj={main_obj}
          setMain_obj={setMain_obj}
          nextStep={nextStep}
          setBomShow={setBomShow}
          bomShow={bomShow}
        />
      )}
    </React.Fragment>
  );
}

export default CloneProductSpecification;

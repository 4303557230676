
import React from 'react';

const General = () => {
    return (
        <div>
            <h2>General Settings</h2>
            <form>
                <div>
                    <label>Company Name:</label>
                    <input type="text" name="companyName" />
                </div>
                <div>
                    <label>Default Language:</label>
                    <select name="language">
                        <option value="en">English</option>
                        <option value="es">Spanish</option>
                    </select>
                </div>
                <div>
                    <label>Time Zone:</label>
                    <select name="timezone">
                        <option value="UTC">UTC</option>
                        <option value="GMT">GMT</option>
                    </select>
                </div>
                <button type="submit">Save</button>
            </form>
        </div>
    );
};

export default General;

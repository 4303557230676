import React, { useEffect, useState } from "react";
import SweetAlert from "sweetalert2";
import logo from "../../../assets/images/sample.png";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Inter from "../../../assets/fonts/Inter-Regular.ttf";
import Inter600 from "../../../assets/fonts/Inter-SemiBold.ttf";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { getSupplier, getQuote_Products } from "../../../api";
import numWords from "num-words";
import {
    PDFViewer,
    Document,
    Text,
    View,
    Image,
    Page,
    Link,
    StyleSheet,
    Font,
    PDFDownloadLink,
} from "@react-pdf/renderer";
import { convertDate, getLabels } from "../../../common/Functions/CommonFunctions";

function ViewQuotationTemp2() {
    let navigate = useNavigate();
    let location = useLocation();

    const [suppList, setSuppList] = useState({ data: [], loading: false });
    const [quoteProd, setQuoteProd] = useState({ data: [], loading: false });
    const [total, setTotal] = useState("");

    const [constLabel, setConstLabel] = useState({});

    useEffect(() => {
        setConstLabel(getLabels('Master', 'Supplier Quotation'));


        if (location.state?.supplierid_id) {
            console.log("location.state", location.state);
            fetchSupp();
            // fetchQuoteProd();
            setQuoteProd({ ...quoteProd, data: location.state?.quote_products, loading: false });
        }

    }, [])


    function fetchSupp() {
        setSuppList({ ...suppList, loading: true });
        const payload = {
            uuid: location.state?.supplierid_id,
            partyType: "Supplier"
        }
        getSupplier(payload).then((res) => {

            setSuppList({ ...suppList, data: res.data.data, loading: false });
        }, (err) => {

            setSuppList({ ...suppList, data: [], loading: false });
        })
    }

    // function fetchQuoteProd() {
    //     setQuoteProd({ ...quoteProd, loading: true });
    //     const payload = {
    //         quote_id: location.state?.uuid
    //     }
    //     getQuote_Products(payload).then((res) => {

    //         setQuoteProd({ ...quoteProd, data: res.data.data, loading: false });
    //     }, (err) => {

    //         setQuoteProd({ ...quoteProd, data: [], loading: false });
    //     })
    // }


    Font.register({
        family: "Inter, sans-serif",
        fonts: [
            {
                src: Inter,
            },
            {
                src: Inter600,
                fontWeight: 600,
            },
        ],
    });

    const userData = [
        { name: "User 1", age: 25 },
        { name: "User 2", age: 30 },
        { name: "User 3", age: 30 },
        { name: "User 4", age: 30 },
        { name: "User 5", age: 30 },
        { name: "User 6", age: 30 },
        { name: "User 7", age: 30 },
        { name: "User 8", age: 30 },
        { name: "User 9", age: 30 },
        { name: "User 10", age: 30 },
        { name: "User 11", age: 30 },
        { name: "User 12", age: 30 },
        { name: "User 13", age: 30 },
        { name: "User 14", age: 30 },
        { name: "User 15", age: 30 },
        { name: "User 16", age: 30 },
        { name: "User 17", age: 30 },
        { name: "User 18", age: 30 },
        { name: "User 19", age: 30 },
        { name: "User 20", age: 30 },
        { name: "User 21", age: 30 },
        { name: "User 22", age: 30 },
        { name: "User 23", age: 30 },

        // Add more user data as needed
    ];

    const styles = StyleSheet.create({
        viewer: {
            width: "100%",
            height: window.innerHeight,
        },
        page: {
            paddingTop: 15,
            paddingBottom: 15,
            paddingHorizontal: 15,
            color: "black",
            fontFamily: "Inter, sans-serif",
        },
        pageNumber: {
            position: 'absolute',
            fontSize: 12,
            bottom: 40,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'black',
        },
        footer: {
            position: 'absolute',
            fontSize: 12,
            bottom: 20,
            left: 0,
            right: 0,
            textAlign: 'center',
            color: 'black',
        },
        row1: {
            flexDirection: 'row',
            border: '1px solid black',
        },
        row2: {
            flexDirection: 'row',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
        },
        row3: {
            flexDirection: 'row',
            fontSize: '10px'
        },
        tablColumn1: {
            width: '5%',
            fontSize: '10px',
            border: '1px solid black',
            padding: '5px',
            fontWeight: 600
        },
        tablColumn1Data: {
            width: '5%',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
            padding: '5px',
        },
        tablColumn2: {
            width: '30%',
            border: '1px solid black',
            padding: '5px',
            fontWeight: 600
        },
        tablColumn2Data: {
            width: '30%',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
            padding: '5px',
        },
        tablColumn3: {
            width: '10%',
            border: '1px solid black',
            padding: '5px',
            fontWeight: 600
        },
        tablColumn3Data: {
            width: '10%',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
            padding: '5px',
        },
        tablColumn4: {
            width: '10%',
            border: '1px solid black',
            padding: '5px',
            fontWeight: 600
        },
        tablColumn4Data: {
            width: '10%',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
            padding: '5px',
        },
        tablColumn5: {
            width: '15%',
            border: '1px solid black',
            padding: '5px',
            fontWeight: 600
        },
        tablColumn5Data: {
            width: '15%',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
            padding: '5px',
        },
        tablColumn6: {
            width: '8%',
            border: '1px solid black',
            padding: '5px',
            fontWeight: 600
        },
        tablColumn6Data: {
            width: '8%',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
            padding: '5px',
        },
        tablColumn7: {
            width: '10%',
            border: '1px solid black',
            padding: '5px',
            fontWeight: 600
        },
        tablColumn7Data: {
            width: '10%',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
            padding: '5px',
            textAlign: 'right'
        },
        tablColumn8: {
            width: '8%',
            border: '1px solid black',
            padding: '5px',
            fontWeight: 600
        },
        tablColumn8Data: {
            width: '8%',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
            padding: '5px',
            textAlign: 'right'
        },
        tablColumn9: {
            width: '14%',
            border: '1px solid black',
            padding: '5px',
            fontWeight: 600
        },
        tablColumn9Data: {
            width: '14%',
            borderLeft: '1px solid black',
            borderRight: '1px solid black',
            borderBottom: '1px solid black',
            padding: '5px',
            textAlign: 'right'
        },
        subDivRow: {
            flexDirection: 'row',
        },
        column: {
            width: '50%',
            // borderRight: '1px solid black',
            // backgroundColor: 'red',
            fontSize: '10px'
        },
        title: {
            textAlign: 'center',
            fontWeight: 600
        },
        companyName: {
            fontWeight: '600',
            fontSize: '12px'
        },
        signatureBlock: {
            width: '100%',
            marginLeft: 'auto',
            marginTop: '100px',
            borderTop: '1px solid black',
            borderLeft: '1px solid black',
            padding: '5px'
        },

    });



    return (
        <React.Fragment>
            <ToastContainer />

            <div className="manufac_goods_form mb-4">
                <div className="card">
                    <div className="card-header bg-grey d-flex align-items-center">
                        <button
                            className="btn btn-yellow"
                            onClick={() => {
                                navigate("/pcms/quotation/");
                            }}
                        >
                            <FontAwesomeIcon icon={faArrowLeft} size="sm" />
                        </button>

                        <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
                            {constLabel?.lbl_view_quotation ? constLabel.lbl_view_quotation : "View Quotation"}
                        </p>
                    </div>
                    <div className="card-body">
                        <div className="createNc container-fluid">
                            <PDFViewer style={styles.viewer}>
                                <Document title={`Quotation - ${location.state?.quotesid}`}>
                                    <Page style={styles.page}>
                                        <Text style={styles.title}>{"Quotation"}</Text>
                                        <View style={[styles.row1, { marginTop: '10px' }]}>
                                            <View style={styles.column}>
                                                <View style={{ padding: '10px' }}>
                                                    <Text>To</Text>
                                                    <Text style={styles.companyName}>{location.state?.quotationForDetails?.comName}</Text>
                                                    <Text>{location.state?.quotationForDetails?.comaddress}</Text>
                                                    <Text>GSTIN/UIN : {location.state?.quotationForDetails?.comGSTnumber}</Text>
                                                    {/* <Text>Email : {location.state?.quotation_for_id?.mail}</Text> */}
                                                    <Text>Contact : {location.state?.quotationForDetails?.phone}</Text>
                                                </View>
                                            </View>
                                            <View style={[styles.column, { borderLeft: '1px solid black' }]}>
                                                <View style={[styles.subDivRow,]}>
                                                    <View style={[styles.column, { padding: '5px' }]}>
                                                        <Text>Number</Text>
                                                        <Text>{location.state?.quotesid}</Text>
                                                    </View>
                                                    <View style={[styles.column, { padding: '5px', borderLeft: '1px solid black' }]}>
                                                        <Text>Date</Text>
                                                        <Text>{convertDate(location.state?.quote_date, '-')}</Text>
                                                    </View>
                                                </View>
                                                {/* <View style={[styles.subDivRow, { borderTop: '1px solid black' }]}>
                                                    <View style={[styles.column, { padding: '5px' }]}>
                                                        <Text></Text>
                                                    </View>
                                                    <View style={[styles.column, { padding: '5px', borderLeft: '1px solid black' }]}>
                                                        <Text>Date</Text>
                                                        <Text>16 Sepetember 2023</Text>
                                                    </View>
                                                </View> */}
                                                <View style={[styles.subDivRow, { borderTop: '1px solid black' }]}>
                                                    <View style={[styles.column, { padding: '5px' }]}>
                                                        <Text>Supplier's Ref / Order No</Text>
                                                        {/* <Text>PO/001</Text> */}
                                                    </View>
                                                    <View style={[styles.column, { padding: '5px', borderLeft: '1px solid black' }]}>
                                                        <Text>Other References</Text>
                                                    </View>
                                                </View>
                                                <View style={[styles.subDivRow, { borderTop: '1px solid black' }]}>
                                                    <View style={[styles.column, { padding: '5px' }]}>
                                                        <Text>Despatch through</Text>
                                                    </View>
                                                    <View style={[styles.column, { padding: '5px', borderLeft: '1px solid black' }]}>
                                                        <Text>Destination</Text>

                                                    </View>
                                                </View>
                                            </View>

                                        </View>
                                        <View style={[styles.row2,]}>
                                            <View style={styles.column}>
                                                <View style={{ padding: '10px' }}>
                                                    <Text>From</Text>
                                                    <Text style={styles.companyName}>{suppList.data[0]?.name}</Text>
                                                    <Text>{suppList.data[0]?.address}</Text>
                                                    <Text>GSTIN/UIN : {suppList.data[0]?.gst}</Text>
                                                    <Text>Email : {suppList.data[0]?.email}</Text>
                                                    <Text>Contact : {suppList.data[0]?.primaryContactNo}</Text>
                                                </View>
                                            </View>

                                            <View
                                                style={[
                                                    styles.column,
                                                    { borderLeft: "1px solid black", padding: "10px" },
                                                ]}
                                            >
                                                <Text>Terms of Delivery</Text>
                                                <Text>Delivery at Ex-Factory</Text>
                                            </View>
                                        </View>

                                        <View style={[styles.row3, { marginTop: '10px' }]}>
                                            <View style={styles.tablColumn1}>
                                                <Text>Sr No</Text>
                                            </View>
                                            <View style={styles.tablColumn2}>
                                                <Text>Description of Goods</Text>
                                            </View>
                                            {/* <View style={styles.tablColumn3}>
                                                <Text>Due on</Text>
                                            </View> */}
                                            <View style={styles.tablColumn4}>
                                                <Text>Quantity</Text>
                                            </View>
                                            <View style={styles.tablColumn5}>
                                                <Text>Unit price ({"\u20B9"})</Text>
                                            </View>
                                            <View style={styles.tablColumn6}>
                                                <Text>Unit</Text>
                                            </View>
                                            <View style={styles.tablColumn7}>
                                                <Text>GST (%)</Text>
                                            </View>
                                            <View style={styles.tablColumn8}>
                                                <Text>Discount</Text>
                                            </View>
                                            <View style={styles.tablColumn9}>
                                                <Text>Amount ({"\u20B9"})</Text>
                                            </View>
                                        </View>
                                        {quoteProd.data.map((val, i) => (
                                            <View style={[styles.row3]} key={i}>
                                                <View style={styles.tablColumn1Data}>
                                                    <Text>{i + 1}</Text>
                                                </View>
                                                <View style={styles.tablColumn2Data}>
                                                    <Text>{val.product_name}</Text>
                                                </View>
                                                {/* <View style={styles.tablColumn3Data}>
                                                <Text>16 Sep 2023</Text>
                                            </View> */}
                                                <View style={styles.tablColumn4Data}>
                                                    <Text>{val.quantity}</Text>
                                                </View>
                                                <View style={styles.tablColumn5Data}>
                                                    <Text>{val.rate}</Text>
                                                </View>
                                                <View style={styles.tablColumn6Data}>
                                                    <Text>{val.unit}</Text>
                                                </View>
                                                <View style={styles.tablColumn7Data}>
                                                    <Text>{val.gst_rate}</Text>
                                                </View>
                                                <View style={styles.tablColumn8Data}>
                                                    <Text>{val.discount}</Text>
                                                </View>
                                                <View style={styles.tablColumn9Data}>
                                                    <Text>{val.amount.toFixed(2)}</Text>
                                                </View>
                                            </View>
                                        ))}
                                        <View style={[styles.row3, { borderTop: '1px solid black' }]}>
                                            <View style={styles.tablColumn1Data}>
                                                <Text></Text>
                                            </View>
                                            <View style={styles.tablColumn2Data}>
                                                <Text></Text>
                                            </View>
                                            {/* <View style={styles.tablColumn3Data}>
                                                <Text>16 Sep 2023</Text>
                                            </View> */}
                                            <View style={styles.tablColumn4Data}>
                                                <Text></Text>
                                            </View>
                                            <View style={[styles.tablColumn5Data, { fontWeight: "600" }]}>
                                                <Text></Text>
                                            </View>
                                            <View style={styles.tablColumn6Data}>
                                                <Text></Text>
                                            </View>
                                            <View style={[styles.tablColumn7Data, { fontWeight: "600" }]}>
                                                <Text>{"Total"}</Text>
                                            </View>
                                            <View style={styles.tablColumn8Data}>
                                                <Text></Text>
                                            </View>
                                            <View style={styles.tablColumn9Data}>
                                                <Text>{quoteProd.data.reduce((acc, val) => acc + val.amount, 0).toFixed(2)}</Text>
                                            </View>
                                        </View>



                                        <View style={[styles.row3, {
                                            padding: '10px',

                                            borderLeft: '1px solid black',
                                            borderRight: '1px solid black',
                                            fontWeight: 600,
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                        }]}>
                                            <View style={{ width: '30%', }}>
                                                {/* <Text>Discount :</Text> */}
                                                {/* <Text style={{ marginTop: '5px' }}>Amount After Discount :</Text> */}
                                                {location.state?.cgst_cost != 0 ? <Text style={{ marginTop: '5px' }}>CGST :</Text> : null}
                                                {location.state?.sgst_cost != 0 ? <Text style={{ marginTop: '5px' }}>SGST :</Text> : null}
                                                {location.state?.igst_cost != 0 ? <Text style={{ marginTop: '5px' }}>IGST :</Text> : null}
                                                {location.state?.ugst_cost != 0 ? <Text style={{ marginTop: '5px' }}>UGST :</Text> : null}
                                                <Text style={{ marginTop: '5px' }}>Final Amount :</Text>
                                                {/* <Text></Text> */}
                                            </View>
                                            <View style={{ width: '20%', textAlign: "right" }}>
                                                {/* <Text>{location.state?.discount + " %      "}{(quoteProd.data.reduce((acc, val) => acc + val.amount, 0) * location.state?.discount) / 100 + " \u20B9"}</Text> */}
                                                {/* <Text style={{ marginTop: '5px' }}>{location.state?.sub_total + " \u20B9"}</Text> */}

                                                {location.state?.cgst_cost !== 0 ?
                                                    <Text style={{ marginTop: '5px' }}>{location.state?.cgst_cost + " \u20B9"}</Text>
                                                    : null}

                                                {location.state?.sgst_cost !== 0 ?
                                                    <Text style={{ marginTop: '5px' }}>{location.state?.sgst_cost + " \u20B9"}</Text>
                                                    : null}

                                                {location.state?.igst_cost !== 0 ?
                                                    <Text style={{ marginTop: '5px' }}>{location.state?.igst_cost + " \u20B9"}</Text>
                                                    : null}

                                                {location.state?.ugst_cost !== 0 ?
                                                    <Text style={{ marginTop: '5px' }}>{location.state?.ugst_cost + " \u20B9"}</Text>
                                                    : null}

                                                <Text style={{ marginTop: '5px' }}>{location.state?.final_total + " \u20B9"}</Text>
                                                {/* <Text></Text> */}
                                            </View>
                                        </View>





                                        <View style={[styles.row2,]}>

                                            <View style={{ padding: '10px', }}>
                                                <Text style={{ fontSize: '12px' }}>{`Amount (In Words)`}</Text>
                                                <Text style={{ fontSize: '12px', fontWeight: '600', textTransform: "capitalize" }}>
                                                    {"\u20B9"} {numWords(parseInt(location.state?.final_total)) + " Rupees Only"}
                                                </Text>
                                            </View>


                                            <View style={styles.signatureBlock}>
                                                {/* <Text style={{ textAlign: 'right', fontSize: '12px', padding: '5px' }}>
                                                    For <Text style={{ fontWeight: '600' }}>
                                                        Ravi Cam Manufacturing Private Limited
                                                    </Text>
                                                </Text> */}

                                                <View
                                                    style={{ flexDirection: 'row', fontSize: '12px', marginTop: '80px' }}>
                                                    <View style={{ width: '30%' }}>
                                                        <Text>Prepared By</Text>
                                                    </View>
                                                    <View style={{ width: '30%' }}>
                                                        <Text>Verified By</Text>
                                                    </View>
                                                    <View style={{ width: '40%' }}>
                                                        <Text>Authorised Signatory</Text>
                                                    </View>

                                                </View>
                                            </View>
                                        </View>

                                        <Text
                                            style={styles.pageNumber}
                                            render={({ pageNumber, totalPages }) =>
                                                `${pageNumber} / ${totalPages}`
                                            }
                                            fixed
                                        />
                                        <Text style={styles.footer} fixed>
                                            This is a Computer Generated Document
                                        </Text>
                                    </Page>
                                </Document>
                            </PDFViewer>
                        </div>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}

export default ViewQuotationTemp2;

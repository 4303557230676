import React, { useState, useEffect } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  get_document_type,
  get_master_files,
  del_master_file,
  master_multi_file_upload,
  update_master_file,
} from "../../../api/index";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { fileTypes } from "../../../common/Common";
import { convertBase64, convertUrl } from "../../../common/Functions/CommonFunctions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faClose } from "@fortawesome/free-solid-svg-icons";
import pdf_icon from "../../../assets/images/pdf_icon.png";
import xlsx_icon from "../../../assets/images/xlsx_icon.png";
import docx_icon from "../../../assets/images/docx_icon.png";
import { useLocation } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { imagefileTypes } from "../../../common/Common";

const schema = yup
  .object()
  .shape({
    image: yup
      .mixed()
      .test(
        "fileSize",
        "File can be uploaded upto 5 MB",
        (value) => !value || (value && value[0]?.size <= 5 * 1024 * 1024)
      )
      .test("fileType", "Only image, excel, doc files are allowed", (value) => {
        if (!value) return false;
        return fileTypes.includes(value[0]?.type);
      })
      .required("Required"),
    fileName: yup.string().required("File name is required !"),
    // docType: yup
    //   .array()
    //   .min(1, "Select document type")
    //   .required("Select document type"),
  })
  .required();

function EmployeeGallery({ constLabel, setFileList, fileList }) {
  const [docTypeList, setDocTypeList] = useState({ data: [], loading: false });

  const [docList, setDocList] = useState({ data: [], loading: false });
  const [updateStatus, setUpdateStatus] = useState({
    status: false,
    data: {},
    ind: null,
  });

  const [formValues, setFormValues] = useState({ docType: [], filename: "" });
  const [errVal, setErrVal] = useState({ docTypeErr: "", filenameErr: "" });

  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState,
    control,
    reset,
    watch,
    setValue,
    setError,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: { docType: [], image: "", fileName: "" },
    mode: "onChange",
  });
  let { errors } = formState;

  let wdoc_type = watch("docType");

  // useEffect(() => {
  //     if (wdoc_type !== undefined && wdoc_type.length > 0) {
  //         setValue("fileName", wdoc_type[0].name, { shouldValidate: true });
  //     }
  // }, [wdoc_type]);

  useEffect(() => {
    // fetchDocTypes();

    if (location.state?.action == "update") {
      fetchEmpDocs();
    }
  }, []);

  function fetchDocTypes() {
    setDocTypeList({ ...docTypeList, data: [], loading: true });

    get_document_type().then(
      (res) => {
        setDocTypeList({ ...docTypeList, data: res.data.data, loading: false });
      },
      (err) => {
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
        setDocTypeList({ ...docTypeList, data: [], loading: false });
      }
    );
  }

  function removeFile(img) {
    let tempImg = [...fileList];

    tempImg.splice(img, 1);
    setFileList(tempImg);
  }

  function deleteFile(val) {
    SweetAlert.fire({
      title: "Are you sure to delete file ? \n",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        del_master_file({ uuid: val.uuid }).then(
          (res) => {
            toast.success("File deleted successfully", {
              autoClose: 2000,
            });
            fetchEmpDocs();
            // props.setGalVisible(false);
          },
          (err) => {
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  function fetchEmpDocs() {
    get_master_files({ ref_model_uuid: location.state.data.uuid }).then(
      (res) => {
        setDocList({ ...docList, data: res.data.data, loading: false });
      },
      (err) => {
        setDocList({ ...docList, data: [], loading: false });
        toast.error(err.response.data.message, {
          autoClose: 2000,
        });
      }
    );
  }

  function updateFileName() {
    let errMarker = [];

    if (location.state?.action == "update") {
      if (formValues.filename.length == 0) {
        setErrVal({ ...errVal, filenameErr: "Enter filename" });
        errMarker.push(0);
      }
      if (formValues.docType.length == 0) {
        setErrVal({ ...errVal, docTypeErr: "Select document type" });
        errMarker.push(0);
      }

      if (errMarker.length == 0) {
        let imgArr = {
          uuid: updateStatus.data?.uuid,
          img_count: 1,
          // attachment1: data.image[0],
          ref_model_id: updateStatus.data?.ref_model_id,
          ref_model_uuid: updateStatus.data?.ref_model_uuid,
          model_name: "Employee",
          name: formValues.filename,
        };
        if (
          convertUrl(updateStatus.data.attachment).includes(".png") ||
          convertUrl(updateStatus.data.attachment).includes(".jpg") ||
          convertUrl(updateStatus.data.attachment).includes(".jpeg")
        ) {
          imgArr.document_type = "image";
        } else {
          imgArr.document_type = "document";
        }

        // let formDataToSend = new FormData();

        // for (const [key, value] of Object.entries(imgArr)) {
        //     formDataToSend.append(key, value);
        // }

        update_master_file(imgArr).then(
          (res) => {
            toast.success("Employee document updated successfully", {
              autoClose: 2000,
            });
            fetchEmpDocs();
            setUpdateStatus({ status: false, data: {}, ind: null });
            setFormValues({ docType: [], filename: "" });
          },
          (err) => {
            toast.error(err?.response?.data?.message, {
              autoClose: 2000,
            });
          }
        );
      }
    } else {
      let tempFileList = [...fileList];

      for (let i = 0; i < tempFileList.length; i++) {
        if (i == updateStatus.ind) {
          tempFileList[i].docType = formValues.filename;
          tempFileList[i].fileName = formValues.filename;
        }
      }

      setFileList(tempFileList);
      setUpdateStatus({ status: false, data: {}, ind: null });
      setFormValues({ docType: [], filename: "" });
    }
  }

  const onSubmit = async (data) => {
    console.log("data", data);
    if (location.state?.action == "update") {
    //   let obj = {};
    //   obj.ref_model_id = location.state.data?.empid;
    //   obj.ref_model_uuid = location.state.data?.uuid;
    //   obj.model_name = "Employee";
    //   obj.name = data.fileName;

    //   if (imagefileTypes.includes(data.image[0].type)) {
    //     obj.document_type = "image";
    //   } else {
    //     obj.document_type = "document";
    //   }

    //   let imgArr = {};
    //   let formDataToSend = new FormData();
    //   imgArr["attachment" + 1] = data.image[0];
    //   imgArr["obj" + 1] = JSON.stringify(obj);
    //   imgArr["img_count"] = 1;

    //   for (const [key, value] of Object.entries(imgArr)) {
    //     formDataToSend.append(key, value);
    //   }

        const imgArr = [];

    //   for (let a = 0; a < fileList.length; a++) {
        const getExt = data.image[0].name?.split(".").pop();

        console.log("get", getExt);
        const dataimage = await convertBase64(data.image[0]);
        const newImage = dataimage.replace(
          /data:image\/[a-zA-Z]+;/,
          "data:image/" + getExt + ";"
        );
        imgArr.push({
          document: newImage,
          document_type: imagefileTypes.includes(data.image[0].type) ? "image" : "document",
          document_name: data.fileName + `.${getExt}`,
        });

    //   }

      const payload = {
        model_name: "Employee",
        ref_model_id: location.state.data?.empid,
        ref_model_uuid: location.state.data?.uuid,
        files: imgArr
      }

      master_multi_file_upload(payload).then(
        (res) => {
          reset();
          toast.success("Employee document uploaded successfully", {
            autoClose: 2000,
          });
          fetchEmpDocs();
        },
        (err) => {
          if (err.response == undefined) {
            toast.error("Something went wrong uploading images", {
              autoClose: 2000,
            });
          } else {
            toast.error(err?.response?.data?.message, {
              autoClose: 2000,
            });
          }
        }
      );
    } else {
      if (fileList.length < 5) {
        setFileList((prevFileList) => {
          prevFileList.push({
            docType: data.docType[0].name,
            fileName: data.fileName,
            image: data.image[0],
          });

          return prevFileList;
        });
        reset();
      } else {
        toast.error("5 files allowed to upload in single time !", {
          autoClose: 2000,
        });
        reset();
      }
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="emp_gallery">
        {updateStatus.status ? (
          <div className="row p-2">
            {/* <div className="col-12 col-md-3">
              <div className="form-group">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label roboto-bold "
                >
                  {constLabel?.lbl_doctype
                    ? constLabel.lbl_doctype
                    : "Select Doc Type"}
                  <span className="text-danger">*</span>
                </label>

                <div className="">
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    multiple={false}
                    options={docTypeList.data}
                    isLoading={docTypeList.loading}
                    placeholder="Select document type"
                    onChange={(e) => {
                      if (e.length == 0) {
                        setErrVal({
                          ...errVal,
                          docTypeErr: "Select document type",
                        });
                      } else {
                        setErrVal({ ...errVal, docTypeErr: "" });
                      }
                      setFormValues({
                        ...formValues,
                        docType: e,
                        filename: e.length > 0 ? e[0].name : "",
                      });
                    }}
                    selected={formValues.docType}
                    isInvalid={errVal?.docTypeErr.length > 0 ? true : false}
                  />

                  {errVal?.docTypeErr.length > 0 && (
                    <span className="text-danger err-msg">
                      {errVal?.docTypeErr}
                    </span>
                  )}
                </div>
              </div>
            </div> */}

            <div className="col-12 col-md-3">
              <div className="form-group">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label roboto-bold "
                >
                  {constLabel?.lbl_selfile
                    ? constLabel.lbl_selfile
                    : "Filename"}
                  <span className="text-danger">*</span>
                </label>

                <div className="">
                  <input
                    className={`form-control ${
                      errVal?.filenameErr.length > 0 && "is-invalid"
                    }`}
                    type="text"
                    placeholder="Enter filename"
                    onChange={(e) => {
                      if (e.target.value.length == 0) {
                        setErrVal({ ...errVal, filenameErr: "Enter filename" });
                      } else {
                        setErrVal({ ...errVal, filenameErr: "" });
                      }
                      setFormValues({
                        ...formValues,
                        filename: e.target.value,
                      });
                    }}
                    value={formValues.filename}
                  />

                  {errVal?.filenameErr.length > 0 && (
                    <span className="text-danger err-msg">
                      {errVal?.filenameErr}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3">
              <div>
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label roboto-bold invisible"
                >
                  {constLabel?.lbl_selfile
                    ? constLabel.lbl_selfile
                    : "Select File"}
                  <span className="text-danger">*</span>
                </label>
              </div>
              <div>
                <button
                  className="btn btn-yellow px-3"
                  onClick={() => updateFileName()}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="row p-2">
            {/* <div className="col-12 col-md-3">
              <div className="form-group">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label roboto-bold "
                >
                  {constLabel?.lbl_doctype
                    ? constLabel.lbl_doctype
                    : "Select Doc Type"}
                  <span className="text-danger">*</span>
                </label>

                <div className="">
                  <Controller
                    name="docType"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="name"
                        multiple={false}
                        options={docTypeList.data}
                        isLoading={docTypeList.loading}
                        placeholder="Select document type"
                        onChange={onChange}
                        onBlur={onBlur}
                        selected={value}
                        isInvalid={errors.docType ? true : false}
                      />
                    )}
                  />

                  {errors?.docType && (
                    <span className="text-danger err-msg">
                      {errors?.docType.message}
                    </span>
                  )}
                </div>
              </div>
            </div> */}
            <div className="col-12 col-md-3">
              <div className="form-group">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label roboto-bold "
                >
                  {constLabel?.lbl_selfile
                    ? constLabel.lbl_selfile
                    : "Select File"}
                  <span className="text-danger">*</span>
                </label>

                <div className="">
                  <input
                    className={`form-control ${
                      errors?.image ? "is-invalid" : null
                    }`}
                    type="file"
                    {...register("image")}
                  />

                  {errors?.image && (
                    <span className="text-danger err-msg">
                      {errors?.image.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3">
              <div className="form-group">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label roboto-bold "
                >
                  {constLabel?.lbl_selfile
                    ? constLabel.lbl_selfile
                    : "Filename"}
                  <span className="text-danger">*</span>
                </label>

                <div className="">
                  <input
                    className={`form-control ${
                      errors?.fileName ? "is-invalid" : null
                    }`}
                    type="text"
                    placeholder="Enter filename"
                    {...register("fileName")}
                  />
                  {errors?.fileName && (
                    <span className="text-danger err-msg">
                      {errors?.fileName.message}
                    </span>
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 col-md-3">
              <div>
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label roboto-bold invisible"
                >
                  {constLabel?.lbl_selfile
                    ? constLabel.lbl_selfile
                    : "Select File"}
                  <span className="text-danger">*</span>
                </label>
              </div>
              <div>
                <button
                  className="btn btn-yellow px-3"
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}

        <div
          style={{ maxHeight: "400px", overflowX: "auto" }}
          className="row mt-4 emp_drawing_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-6 g-4"
        >
          {fileList?.map((val, i) =>
            val.image?.type.includes("image") == true ? (
              <div className="col text-center" key={i}>
                <div className="image-container">
                  <img
                    src={URL.createObjectURL(val.image)}
                    className="img-fluid shadow-sm"
                    alt="Image 1"
                    id="org_img"
                  />

                  <FontAwesomeIcon
                    onClick={() => removeFile(i)}
                    className="icon-close"
                    style={{ color: "#000" }}
                    icon={faClose}
                    size="lg"
                  />
                </div>

                <span
                  // onClick={() => {
                  //   setUpdateStatus({
                  //     ...updateStatus,
                  //     status: true,
                  //     data: val,
                  //     ind: i,
                  //   });
                  //   setFormValues({
                  //     ...formValues,
                  //     filename: val.fileName,
                  //     docType: [{ name: val.docType }],
                  //   });
                  // }}
                  className="image-name cursor_pointer"
                >
                  {val.fileName}
                </span>
              </div>
            ) : val.image?.name.includes(".xlsx") ||
              val.image?.name.includes(".xls") ? (
              <div className="col text-center" key={i}>
                <div className="image-container cursor_pointer">
                  <div className="other_doc shadow-sm d-flex justify-content-center align-items-center">
                    <img src={xlsx_icon} height={"80"} width={"80"} />

                    <FontAwesomeIcon
                      onClick={() => removeFile(i)}
                      className="icon-close"
                      style={{ color: "#000" }}
                      icon={faClose}
                      size="lg"
                    />
                  </div>
                </div>

                <span
                  // onClick={() => {
                  //   setUpdateStatus({
                  //     ...updateStatus,
                  //     status: true,
                  //     data: val,
                  //     ind: i,
                  //   });
                  //   setFormValues({ ...formValues, filename: val.fileName });
                  // }}
                  className="image-name cursor_pointer"
                >
                  {val.fileName}
                </span>
              </div>
            ) : val.image?.name.includes(".docx") ||
              val.image?.name.includes(".doc") ? (
              <div className="col text-center" key={i}>
                <div className="image-container cursor_pointer">
                  <div className="other_doc shadow-sm d-flex justify-content-center align-items-center">
                    <img src={docx_icon} height={"80"} width={"80"} />

                    <FontAwesomeIcon
                      onClick={() => removeFile(i)}
                      className="icon-close"
                      style={{ color: "#000" }}
                      icon={faClose}
                      size="lg"
                    />
                  </div>
                </div>

                <span
                  // onClick={() => {
                  //   setUpdateStatus({
                  //     ...updateStatus,
                  //     status: true,
                  //     data: val,
                  //     ind: i,
                  //   });
                  //   setFormValues({ ...formValues, filename: val.fileName });
                  // }}
                  className="image-name cursor_pointer"
                >
                  {val.fileName}
                </span>
              </div>
            ) : val.image?.name.includes(".pdf") ? (
              <div className="col text-center" key={i}>
                <div className="image-container cursor_pointer">
                  <div className="other_doc shadow-sm d-flex justify-content-center align-items-center">
                    <img src={pdf_icon} height={"80"} width={"80"} />

                    <FontAwesomeIcon
                      onClick={() => removeFile(i)}
                      className="icon-close"
                      style={{ color: "#000" }}
                      icon={faClose}
                      size="lg"
                    />
                  </div>
                </div>

                <span
                  // onClick={() => {
                  //   setUpdateStatus({
                  //     ...updateStatus,
                  //     status: true,
                  //     data: val,
                  //     ind: i,
                  //   });
                  //   setFormValues({ ...formValues, filename: val.fileName });
                  // }}
                  className="image-name cursor_pointer"
                >
                  {val.fileName}
                </span>
              </div>
            ) : (
              <div className="col text-center" key={i}>
                <div className="image-container cursor_pointer">
                  <div className="other_doc shadow-sm d-flex justify-content-center align-items-center">
                    <FontAwesomeIcon
                      className="icon-file-pdf"
                      style={{ color: "#fff" }}
                      icon={faFileAlt}
                      size="3x"
                    />

                    <FontAwesomeIcon
                      onClick={() => removeFile(i)}
                      className="icon-close"
                      style={{ color: "#000" }}
                      icon={faClose}
                      size="lg"
                    />
                  </div>
                </div>

                <span
                  // onClick={() => {
                  //   setUpdateStatus({
                  //     ...updateStatus,
                  //     status: true,
                  //     data: val,
                  //     ind: i,
                  //   });
                  //   setFormValues({ ...formValues, filename: val.fileName });
                  // }}
                  className="image-name cursor_pointer"
                >
                  {val.fileName}
                </span>
              </div>
            )
          )}

          {docList.loading ? (
            <div className="text-center">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : location.state?.action == "update" &&
            docList.data.length == 0 &&
            fileList.length == 0 ? (
            <div
              className="alert alert-danger mb-0 text-center my-4"
              role="alert"
            >
              {"No files found"}
            </div>
          ) : (
            docList.data?.map((val, i) =>
              val.attachment.includes(".jpg") ||
              val.attachment.includes(".jpeg") ||
              val.attachment.includes(".png") ? (
                <div className="col text-center" key={i}>
                  <div className="image-container">
                    <img
                      src={process.env.REACT_APP_IMAGE_URL + val.attachment}
                      className="img-fluid shadow-sm"
                      alt="Image 1"
                      id="org_img"
                      onClick={() =>
                        window.open(process.env.REACT_APP_IMAGE_URL + val.attachment, "_blank")
                      }
                    />

                    <FontAwesomeIcon
                      onClick={() => deleteFile(val)}
                      className="icon-close"
                      style={{ color: "#000" }}
                      icon={faClose}
                      size="lg"
                    />
                  </div>

                  <span
                    onClick={() => {
                      setUpdateStatus({
                        ...updateStatus,
                        status: true,
                        data: val,
                        ind: i,
                      });
                      setFormValues({ ...formValues, filename: val.name });
                    }}
                    className="image-name cursor_pointer"
                  >
                    {val.name}
                  </span>
                </div>
              ) : val.attachment.includes(".xlsx") ||
                val.attachment.includes(".xls") ? (
                <div className="col text-center" key={i}>
                  <div className="image-container cursor_pointer">
                    <div className="other_doc shadow-sm d-flex justify-content-center align-items-center">
                      <img
                        onClick={() =>
                          window.open(process.env.REACT_APP_IMAGE_URL + val.attachment, "_blank")
                        }
                        src={xlsx_icon}
                        height={"80"}
                        width={"80"}
                      />

                      <FontAwesomeIcon
                        onClick={() => deleteFile(val)}
                        className="icon-close"
                        style={{ color: "#000" }}
                        icon={faClose}
                        size="lg"
                      />
                    </div>
                  </div>

                  <span
                    onClick={() => {
                      setUpdateStatus({
                        ...updateStatus,
                        status: true,
                        data: val,
                        ind: i,
                      });
                      setFormValues({ ...formValues, filename: val.name });
                    }}
                    className="image-name cursor_pointer"
                  >
                    {val.name}
                  </span>
                </div>
              ) : val.attachment.includes(".docx") ||
                val.attachment.includes(".doc") ? (
                <div className="col text-center" key={i}>
                  <div className="image-container cursor_pointer">
                    <div className="other_doc shadow-sm d-flex justify-content-center align-items-center">
                      <img
                        onClick={() =>
                          window.open(process.env.REACT_APP_IMAGE_URL + val.attachment, "_blank")
                        }
                        src={docx_icon}
                        height={"80"}
                        width={"80"}
                      />

                      <FontAwesomeIcon
                        onClick={() => deleteFile(val)}
                        className="icon-close"
                        style={{ color: "#000" }}
                        icon={faClose}
                        size="lg"
                      />
                    </div>
                  </div>

                  <span
                    onClick={() => {
                      setUpdateStatus({
                        ...updateStatus,
                        status: true,
                        data: val,
                        ind: i,
                      });
                      setFormValues({ ...formValues, filename: val.name });
                    }}
                    className="image-name cursor_pointer"
                  >
                    {val.name}
                  </span>
                </div>
              ) : val.attachment.includes(".pdf") ? (
                <div className="col text-center" key={i}>
                  <div className="image-container cursor_pointer">
                    <div className="other_doc shadow-sm d-flex justify-content-center align-items-center">
                      <img
                        src={pdf_icon}
                        onClick={() =>
                          window.open(process.env.REACT_APP_IMAGE_URL + val.attachment, "_blank")
                        }
                        height={"80"}
                        width={"80"}
                      />

                      <FontAwesomeIcon
                        onClick={() => deleteFile(val)}
                        className="icon-close"
                        style={{ color: "#000" }}
                        icon={faClose}
                        size="lg"
                      />
                    </div>
                  </div>

                  <span
                    onClick={() => {
                      setUpdateStatus({
                        ...updateStatus,
                        status: true,
                        data: val,
                        ind: i,
                      });
                      setFormValues({ ...formValues, filename: val.name });
                    }}
                    className="image-name cursor_pointer"
                  >
                    {val.name}
                  </span>
                </div>
              ) : (
                <div className="col text-center" key={i}>
                  <div className="image-container cursor_pointer">
                    <div className="other_doc shadow-sm d-flex justify-content-center align-items-center">
                      <FontAwesomeIcon
                        className="icon-file-pdf"
                        style={{ color: "#fff" }}
                        icon={faFileAlt}
                        size="3x"
                        onClick={() =>
                          window.open(process.env.REACT_APP_IMAGE_URL + val.attachment, "_blank")
                        }
                      />

                      <FontAwesomeIcon
                        onClick={() => deleteFile(val)}
                        className="icon-close"
                        style={{ color: "#000" }}
                        icon={faClose}
                        size="lg"
                      />
                    </div>
                  </div>

                  <span
                    onClick={() => {
                      setUpdateStatus({
                        ...updateStatus,
                        status: true,
                        data: val,
                        ind: i,
                      });
                      setFormValues({ ...formValues, filename: val.name });
                    }}
                    className="image-name cursor_pointer"
                  >
                    {val.name}
                  </span>
                </div>
              )
            )
          )}
        </div>
      </div>
    </React.Fragment>
  );
}

export default React.memo(EmployeeGallery);

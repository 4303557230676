import React from "react";
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faHouse,
  faTruck, faBan, faBox, faWrench, faSuitcase
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation } from "react-router-dom";

function Sidebar() {
  let toggle = false;
  let navigate = useNavigate();
  let url = useLocation();
  console.log("url", url);

  function reSize() {
    var screenWidth = window.innerWidth;

    if (screenWidth > 768) {
      // Add margin to the div for small screens
      var div1 = document.getElementById("root_body");
      var div2 = document.getElementById("navbar");
      if (toggle == true) {
        div1.style.marginLeft = "64px";
        div2.style.marginLeft = "64px";
      } else {
        div1.style.marginLeft = "240px";
        div2.style.marginLeft = "240px";
      }
    } else {
      // No margin needed for larger screens
    }
  }

  return (
    <SideNav
      onSelect={(selected) => {
        console.log("selected", selected);
        navigate(selected);
        // alert("asids");
      }}
      onToggle={() => {
        reSize();
        toggle = !toggle;
      }}
    >
      <SideNav.Toggle />
      <SideNav.Nav defaultSelected={url.pathname}>
        <NavItem eventKey="home">
          <NavIcon>
            <FontAwesomeIcon style={{ color: '#8d8d8d' }} icon={faHouse} size="lg" />
          </NavIcon>
          <NavText>Home</NavText>
        </NavItem>
        <NavItem eventKey="/pcms/customer">
          <NavIcon>
            <FontAwesomeIcon style={{ color: '#8d8d8d' }} icon={faTruck} size="lg" />
          </NavIcon>
          <NavText>Customer</NavText>
        </NavItem>
        <NavItem eventKey="/pcms/supplier">
          <NavIcon>
            <FontAwesomeIcon style={{ color: '#8d8d8d' }} icon={faCartShopping} size="lg" />
          </NavIcon>
          <NavText>Supplier</NavText>
        </NavItem>
        <NavItem eventKey="/pcms/operation">
          <NavIcon>
            <FontAwesomeIcon style={{ color: '#8d8d8d' }} icon={faWrench} size="lg" />
          </NavIcon>
          <NavText>Operation</NavText>
        </NavItem>
        <NavItem eventKey="/pcms/machineAsset">
          <NavIcon>
            <FontAwesomeIcon style={{ color: '#8d8d8d' }} icon={faSuitcase} size="lg" />
          </NavIcon>
          <NavText>Machine</NavText>
        </NavItem>
        <NavItem eventKey="/pcms/nc">
          <NavIcon>
            <FontAwesomeIcon style={{ color: '#8d8d8d' }} icon={faBan} size="lg" />
          </NavIcon>
          <NavText>NC</NavText>
        </NavItem>
        <NavItem eventKey="/pcms/product">
          <NavIcon>
            <FontAwesomeIcon style={{ color: '#8d8d8d' }} icon={faBox} size="lg" />
          </NavIcon>
          <NavText>Product</NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
}

export default Sidebar;

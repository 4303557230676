import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect} from 'react';

function CustomerOverview(props) {

    const {name, gst_treatment, faxNo, email, customerType, organization, website, primaryContactNo, secondaryContactNo} = props.data;

  return (
    <React.Fragment>
        <div className='row'>
        <div className="col-12 col-md-12">
                  <div className="card border-0">
                    <div className="card-body p-2">
                      <div className="d-flex justify-content-between align-items-center">
                        <h5 className="roboto-bold">{"Customer Detail"}</h5>
                        <FontAwesomeIcon
                          className="pt-1"
                          icon={faCircleInfo}
                          size="sm"
                        />
                      </div>

                    <div className='bg-lightgrey'>
                        <div className="row p-2">
                            <div className="col-12 col-md-4">
                                <div>
                                    <p className="m-0 nc_detail_label">{`Organization`}</p>
                                    <p className="mb-2 roboto-bold">{organization !== "" && organization !== null ? organization : "NA"}</p>
                                </div>

                                <div>
                                    <p className="m-0 nc_detail_label">{`Fax No`}</p>
                                    <p className="mb-2 roboto-bold">{faxNo !== "" && faxNo !== null ? faxNo : "NA"}</p>
                                </div>
                            
                                <div>
                                    <p className="m-0 nc_detail_label">{`GST Treatment`}</p>
                                    <p className="mb-2 roboto-bold">{gst_treatment !== "" && gst_treatment !== null ? gst_treatment : "NA"}</p>
                                </div>
            
                            </div>
                            <div className="col-12 col-md-4">
                                <div>
                                    <p className="m-0 nc_detail_label">{`Customer Type`}</p>
                                    <p className="mb-2 roboto-bold">{customerType !== "" && customerType !== null ? customerType : "NA"}</p>
                                </div>

                                <div>
                                    <p className="m-0 nc_detail_label">{`Name`}</p>
                                    <p className="mb-2 roboto-bold">{name !== "" && name !== null ? name : "NA"}</p>
                                </div>

                                <div>
                                    <p className="m-0 nc_detail_label">{`Primary Contact No`}</p>
                                    <p className="mb-2 roboto-bold">{primaryContactNo !== "" && primaryContactNo !== null ? primaryContactNo : "NA"}</p>
                                </div>

                                

                            </div>
                            <div className="col-12 col-md-4">
                                <div>
                                    <p className="m-0 nc_detail_label">{`Website`}</p>
                                    <p className="mb-2 roboto-bold">{website !== "" && website !== null ? website : "NA"}</p>
                                </div>

                                <div>
                                    <p className="m-0 nc_detail_label">{`Email`}</p>
                                    <p className="mb-2 roboto-bold">{email !== "" && email !== null ? email : "NA"}</p>
                                </div>

                                <div>
                                    <p className="m-0 nc_detail_label">{`Secondary Contact No`}</p>
                                    <p className="mb-2 roboto-bold">{secondaryContactNo !== "" && secondaryContactNo !== null ? secondaryContactNo : "NA"}</p>
                                </div>
                            
                            </div>
                        </div>
                    </div>
                        
                    </div>
                  </div>
                </div>
        </div>

    </React.Fragment>
  )
}

export default React.memo(CustomerOverview)
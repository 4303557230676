import React, { useState, useEffect} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd, faPlus } from "@fortawesome/free-solid-svg-icons";
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { Trash2, Edit2 } from 'react-feather';
import { Modal } from 'react-bootstrap';

const schema = yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      sequenceNo: yup
          .number()
          .typeError("Please enter a valid number")
          .required("Sequence no is required !"),
      operation: yup
          .array()
          .min(1,"Select atleast one type")
          .required("Operation is required !"),
    })
  ),
  // selfile: yup
  //     .mixed()
  //     .test('fileSize', 'Image can be uploaded upto 10 MB',
  //         (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)),
})
.required();

function EditOperationList() {
  const [typeList, setTypeList] = useState({ data:[{name: "type1"}, {name: "type2"}], loading: false});
  const [visible, setVisible] = useState(false);
  
  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      items: [{ sequenceNo: "", operation: [], operationCost: "",  route: [] }],
    },
  }); // initialise the hook
  
  const { fields, append, remove } = useFieldArray({
      control,
      name: "items",
    });
  
  let { errors } = formState;
  
  const onSubmit = (data) => {
    
  }

  return (
    <React.Fragment>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">{"Edit Operation"}</Tooltip>}
      >
        <div className="editIcon me-2">
          <Edit2
            color="#FFFFFF"
            size={18}
            className="cursor-pointer"
            onClick={() => {
              // setUpdateForm(val)
              setVisible(true);
            }}
          />
        </div>
      </OverlayTrigger>
      <Modal show={visible} onHide={() => setVisible(false)} size={"xl"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>{"Edit Operation"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form action="">
            <div
              className="table-responsive py-3"
              style={{ overflowX: "scroll" }}
            >
              <table className="table table-bordered">
                <thead className="thead-light text-center">
                  <tr>
                    <th scope="col">{"Sequence No"}</th>
                    <th scope="col">{"Operation"}</th>
                    <th scope="col">{"Operation Cost"}</th>
                    <th scope="col">{"Route"}</th>
                    <th scope="col">{"Action"}</th>
                  </tr>
                </thead>
                <tbody>
                  {fields.map(({ id }, index) => (
                    <tr key={id} className="f-18">
                      <td>
                      <input
                          className="form-control form-control-sm"
                          type="text"
                          name={`items[${index}].sequenceNo`}
                          {...register(`items[${index}].sequenceNo`)}
                          // onChange={(e) => handleFileChange(e, index)}
                          placeholder="Enter sequence no"
                        />

                        {errors.items?.[index]?.sequenceNo && (
                          <span className="text-danger err-msg">
                            {errors.items?.[index]?.sequenceNo.message}
                          </span>
                        )}

                        {index + 1 == fields.length ? (
                          <div>
                            <button
                              style={{ fontSize: "16px" }}
                              className="btn btn-sm button-primary px-2 mt-2"
                              onClick={() => append({})}
                            >
                              <FontAwesomeIcon
                                style={{
                                  color: "#ffffff",
                                  marginRight: "5px",
                                }}
                                icon={faAdd}
                                size="sm"
                              />{" "}
                              Add
                            </button>
                          </div>
                        ) : null}
                      </td>
                      <td style={{ verticalAlign: "top" }}>
                      <Controller
                          name={`items[${index}].operation`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              size="sm"
                              id="basic-typeahead"
                              labelKey="name"
                              multiple={false}
                              options={typeList.data}
                              isLoading={typeList.loading}
                              placeholder="Select operation"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={
                                errors.items?.[index]?.operation ? true : false
                              }
                            />
                          )}
                        />
                        {errors.items?.[index]?.operation && (
                          <span className="text-danger err-msg">
                            {errors.items?.[index]?.operation.message}
                          </span>
                        )}
                      </td>

                      <td style={{ verticalAlign: "top" }}>
                        <input
                          className="form-control form-control-sm"
                          type="text"
                          name={`items[${index}].operationCost`}
                          {...register(`items[${index}].operationCost`)}
                          // onChange={(e) => handleFileChange(e, index)}
                          placeholder="Enter operation cost"
                        />

                        {errors.items?.[index]?.operationCost && (
                          <span className="text-danger err-msg">
                            {errors.items?.[index]?.operationCost.message}
                          </span>
                        )}
                      </td>

                      <td style={{ verticalAlign: "top" }}>
                      <Controller
                          name={`items[${index}].route`}
                          control={control}
                          render={({
                            field: { onChange, onBlur, value, ref },
                          }) => (
                            <Typeahead
                              size="sm"
                              id="basic-typeahead"
                              labelKey="name"
                              multiple={false}
                              options={typeList.data}
                              isLoading={typeList.loading}
                              placeholder="Select route"
                              onChange={onChange}
                              onBlur={onBlur}
                              selected={value}
                              isInvalid={
                                errors.items?.[index]?.route ? true : false
                              }
                            />
                          )}
                        />
                        {errors.items?.[index]?.route && (
                          <span className="text-danger err-msg">
                            {errors.items?.[index]?.route.message}
                          </span>
                        )}
                      </td>

                      <td
                        style={{
                          textAlign: "center",
                          verticalAlign: "top",
                          width: "10%",
                        }}
                      >
                        <div
                          className="deleteIcon cursor_pointer"
                          onClick={index > 0 ? () => remove(index) : null}
                        >
                          <Trash2 color="#FFFFFF" size={18} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {"Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default EditOperationList
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClose } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SweetAlert from "sweetalert2";
// import Accordion from "react-bootstrap/Accordion";
// import { get_document_type } from "../../../../../api/index";
// import { Typeahead } from "react-bootstrap-typeahead";
import {
  del_master_file,
  master_multi_file_upload,
  update_master_file,
} from "../../api";
import {
  convertBase64,
  getLabels,
} from "../../common/Functions/CommonFunctions";

const imageFileTypes = ["image/jpeg", "image/jpg", "image/png"];

const schema = yup
  .object()
  .shape({
    image: yup
      .mixed()
      .test(
        "fileSize",
        "Image can be uploaded upto 10 MB",
        (value) => !value || (value && value[0]?.size <= 10 * 1024 * 1024)
      )
      .test("fileType", "Only image files are allowed", (value) => {
        if (!value) return true; // If no file is selected, it's considered valid.
        // return value[0]?.type.startsWith('image/');
        return imageFileTypes.includes(value[0]?.type);
      })
      .required("Required"),
    fileName: yup.string().required("Image name is required !"),
  })
  .required();

function UpdateSOImageGallary(props) {
  const { taskImgs, fetchImage, locData } = props;
  const [filenameUpdate, setFilenameUpdate] = useState({
    status: false,
    value: "",
  });

  const [formData, setFormData] = useState({});
  const [eroor, setEroor] = useState({ filename1: "" });

  const [constLabel, setConstLabel] = useState({});

  const {
    register,
    handleSubmit,
    formState,
    // control,
    reset,
    // watch,
    // setValue,
    // setError,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  let { errors } = formState;

  useEffect(() => {
    setConstLabel(getLabels("Sales", "Sales Order"));
  }, [filenameUpdate]);

  useEffect(() => {
    props.setDrawingFiles({ ...props.drawingFiles, data: taskImgs.data });
  }, [taskImgs]);

  function deleteFile(val) {
    SweetAlert.fire({
      title: "Are you sure to delete image ? \n",
      // text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        del_master_file({ uuid: val.uuid }).then(
          (res) => {
            toast.success("Image deleted successfully", {
              autoClose: 2000,
            });
            fetchImage();
            // props.setGalVisible(false);
          },
          (err) => {
            toast.error("Something went wrong", {
              autoClose: 2000,
            });
          }
        );
      }
    });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    const newFormData = { ...formData, [name]: value };
    setFormData(newFormData);

    // Perform custom validation for the specific input field
    const newErrors = { ...eroor };
    if (!value || value.trim() === "") {
      newErrors[name] = "Image name is required";
    } else {
      newErrors[name] = ""; // Clear the error message if the input is valid
    }
    setEroor(newErrors);
  };

  const updateName = (e) => {
    e.preventDefault();

    // Check for any remaining validation errors before submitting
    const hasErrors = Object.values(eroor).some((error) => error !== "");
    if (!hasErrors) {
      // Validation passed, perform your submit logic here

      let imgArr = {
        uuid: filenameUpdate.value?.uuid,
        img_count: 1,
        // attachment1: data.image[0],
        // ref_model_id: selMachine.machineId,
        // ref_model_uuid: selMachine.uuid,
        model_name: "SO",
        name: formData.filename1,
        document_type: "image",
      };

      let formDataToSend = new FormData();

      for (const [key, value] of Object.entries(imgArr)) {
        formDataToSend.append(key, value);
      }

      update_master_file(formDataToSend).then(
        (res) => {
          reset();
          toast.success("Image name updated successfully", {
            autoClose: 2000,
          });
          fetchImage();
          setFilenameUpdate({ ...filenameUpdate, status: false });
        },
        (err) => {
          toast.error(err.response.data, {
            autoClose: 2000,
          });
        }
      );
    } else {
      toast.error(eroor, {
        autoClose: 2000,
      });
    }
  };

  // const onsubmit = (data) => {
  //   let tempImg = [...props.drawingFiles.data];
  //   tempImg.push({ filename: data.fileName, file: data.image[0] });
  //   props.setDrawingFiles({ ...props.drawingFiles, data: tempImg });

  //   reset({
  //     image: null,
  //     fileName: "",
  //   });
  // };

  const onsubmit = async (data) => {
    console.log("Data", data);
    const getExt = data.image[0].name?.split(".").pop();

    // console.log("get", getExt);
    const dataimage = await convertBase64(data.image[0]);
    const newImage = dataimage.replace(
      /data:image\/[a-zA-Z]+;/,
      "data:image/" + getExt + ";"
    );

    const payload = {
      model_name: "SO",
      ref_model_id: "",
      ref_model_uuid: locData.uuid,
      files: [
        {
          document: newImage,
          document_type: "image",
          document_name: data.fileName + `.${getExt}`,
        },
      ],
    };
    // console.log("payload", payload);
    master_multi_file_upload(payload).then(
      (res) => {
        reset({
          image: {},
          fileName: "",
        });
        toast.success("Image uploaded successfully", {
          autoClose: 2000,
        });
        fetchImage();
      },
      (err) => {
        toast.error(err.response.data, {
          autoClose: 2000,
        });
      }
    );
  };

  function removeFile(i) {
    let tempImg = [...props.drawingFiles.data];

    tempImg.splice(i, 1);
    props.setDrawingFiles({ ...props.drawingFiles, data: tempImg });
  }

  return (
    <React.Fragment>
      <ToastContainer />

      <Modal
        show={props.galVisible}
        onHide={() => {
          props.setGalVisible(false);
          setFilenameUpdate({ ...filenameUpdate, status: false });
        }}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {constLabel?.lbl_image_gallary
              ? constLabel.lbl_image_gallary
              : "Image Gallery"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {filenameUpdate.status == false ? (
            <div className="row">
              <div className="col-12 col-md-4">
                <label style={{ fontSize: "14px" }} className="col-form-label">
                  {constLabel?.lbl_file ? constLabel.lbl_file : "File"}
                </label>
                <input
                  className={
                    errors.image
                      ? "form-control form-control-sm is-invalid"
                      : "form-control form-control-sm"
                  }
                  type="file"
                  {...register("image")}
                  accept="image/*"
                />
                {errors.image && (
                  <span className="text-danger err-msg">
                    {errors.image.message}
                  </span>
                )}
              </div>
              <div className="col-12 col-md-4">
                <label style={{ fontSize: "14px" }} className="col-form-label">
                  {constLabel?.lbl_file_name
                    ? constLabel.lbl_file_name
                    : "Filename"}
                </label>
                <input
                  className={
                    errors.fileName
                      ? "form-control form-control-sm is-invalid"
                      : "form-control form-control-sm"
                  }
                  type="text"
                  {...register("fileName")}
                  placeholder="Enter filename"
                />
                {errors.fileName && (
                  <span className="text-danger err-msg">
                    {errors.fileName.message}
                  </span>
                )}
              </div>

              <div className="col-12 col-md-3">
                <label
                  style={{ fontSize: "14px" }}
                  className="col-form-label invisible d-block"
                >
                  {constLabel?.lbl_file_name
                    ? constLabel.lbl_file_name
                    : "Filename"}
                </label>

                <button
                  className="btn btn-sm btn-yellow px-3"
                  onClick={handleSubmit(onsubmit)}
                >
                  {constLabel?.lbl_submit ? constLabel.lbl_submit : "Submit"}
                </button>
              </div>
            </div>
          ) : null}

          {filenameUpdate.status == true ? (
            <form onSubmit={updateName}>
              <div className="row">
                <div className="col-12 col-md-4">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label"
                  >
                    {constLabel?.lbl_file_name
                      ? constLabel.lbl_file_name
                      : "Filename"}
                  </label>
                  <input
                    className={
                      eroor.filename1
                        ? "form-control form-control-sm is-invalid"
                        : "form-control form-control-sm"
                    }
                    type="text"
                    name="filename1"
                    id="filename1"
                    // value={formData.filename1}
                    onChange={handleChange}
                    defaultValue={
                      filenameUpdate !== undefined
                        ? filenameUpdate.value?.name
                        : ""
                    }
                  />
                  {eroor.filename1 && (
                    <span className="text-danger err-msg">
                      {eroor.filename1}
                    </span>
                  )}
                </div>

                <div className="col-12 col-md-3">
                  <label
                    style={{ fontSize: "14px" }}
                    className="col-form-label invisible d-block"
                  >
                    {constLabel?.lbl_file_name
                      ? constLabel.lbl_file_name
                      : "Filename"}
                  </label>

                  <button type="submit" className="btn btn-sm btn-yellow px-3">
                    {constLabel?.lbl_submit ? constLabel.lbl_submit : "Submit"}
                  </button>
                </div>
              </div>
            </form>
          ) : null}

          {taskImgs?.loading ? (
            <div className="text-center mt-4">
              <div className="spinner-border" role="status">
                <span className="visually-hidden">Loading...</span>
              </div>
            </div>
          ) : (
            <div className="row mt-4 drawing_gallery row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-4">
              {taskImgs?.data.map((val, i) =>
                val.attachment !== null ? (
                  <div className="col text-center" key={i}>
                    <div className="image-container">
                      <img
                        src={process.env.REACT_APP_IMAGE_URL + val.attachment}
                        className="img-fluid"
                        alt="Image 1"
                      />

                      <FontAwesomeIcon
                        onClick={() => deleteFile(val)}
                        // onClick={() => removeFile(i)}
                        className="icon-close"
                        style={{ color: "#000" }}
                        icon={faClose}
                        size="lg"
                      />
                    </div>

                    <span
                      onClick={() =>
                        setFilenameUpdate({
                          ...filenameUpdate,
                          status: true,
                          value: val,
                        })
                      }
                      className="image-name cursor_pointer"
                    >
                      {val.name}
                    </span>
                  </div>
                ) : null
              )}
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-grey px-4"
            onClick={() => {
              props.setGalVisible(false);
              setFilenameUpdate({ ...filenameUpdate, status: false });
            }}
          >
            {constLabel?.lbl_close ? constLabel.lbl_close : "Close"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  );
}

export default UpdateSOImageGallary;

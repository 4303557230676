import axios from "axios";
// import { RefreshToken } from "./index";

const client3 = axios.create({
  baseURL: process.env.REACT_APP_COGNITO_URL,
});

// function saveToken(access_token) {
//   localStorage.setItem("access", access_token);
//   // localStorage.setItem('refresh', refresh_token);
// }

// function destroyToken() {
//   localStorage.removeItem("access");
//   localStorage.removeItem("refresh");
// }

// function refresh() {
//   return new Promise((resolve, reject) => {
//     client3
//       .post(RefreshToken, {
//         refresh: localStorage.getItem("refresh"),
//       })
//       .then((response) => {
//         saveToken(response.data.access, response.data.refresh_token);
//         return resolve(response.data.access_token);
//       })
//       .catch((error) => {
//         destroyToken();
//         window.location.replace(`${process.env.PUBLIC_URL}/login`);
//         return reject(error);
//       });
//   });
// }

client3?.interceptors.request.use((config) => {
  
    var idtoken=localStorage.getItem('id');

//  var authToken = localStorage.getItem("access");
  config.headers = { Authorization: `Bearer ${idtoken}` };
  //  console.log("con",config);
  
   return config;
   
});

client3?.interceptors.response.use(
  (res) => res,
  (error) => {
    
    // console.log("error.response", error.response);
    const status = error.response ? error.response.status : null;
    switch (status) {
      case 401:

        localStorage.clear();
        window.location.replace(`${process.env.PUBLIC_URL}/login`);
        break;

      case 403:

        localStorage.clear();
        window.location.replace(`${process.env.PUBLIC_URL}/login`);
        break;
      //localStorage.removeItem('refresh');

      case 400:
        break;

      default:
        // return null;
        break;
    }

    if (status === 401 || status === 403) {
      //window.location.replace('/login');
      // localStorage.removeItem("access");
      // window.location.replace(`${process.env.PUBLIC_URL}/login`);
      //localStorage.removeItem('refresh');
    }

    // status might be undefined
    if (!status) {
      // refresh();
    }
    return Promise.reject(error);
  }
);

export default client3;

import React, { useState, useEffect } from "react";
import "../../Customer/Customer/NewCustomer.css"; // Import your CSS file for styling
import { useLocation, useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLabels } from "../../../../common/Functions/CommonFunctions";
import NewSupplierDetail from "./SupplierDetail/NewSupplierDetail";
import NewAddressDetail from "./AddressDetail/NewAddressDetail";
import NewContactDetail from "./ContactDetail/NewContactDetail";
import { get_party_contact } from "../../../../api";
import Preview from "./Preview";

const NewSupplier = () => {
  let navigate = useNavigate();
  const location = useLocation();
  const [constLabel, setConstLabel] = useState({});
  const [currentStep, setCurrentStep] = useState(1);
  const steps_array = ["Supplier Detail", "Address Detail", "Contact Detail"];
  const numSteps = 3;
  const {
    supp_type,
    supplier_type_id,
    partyType,
    name,
    email,
    contactPersonName,
    contactPersonContact,
    contactPersonDesignation,
    VATNO,
    CSTNO,
    PANNO,
    gst_treatment,
    gst,
    description,
    createdBy,
    modifiedBy,
    address,
    address2,
    city_id,
    city_name,
    city,
    state_id,
    state_name,
    state,
    country_name,
    country_id,
    country,
    postalCode,
    faxNo,
    party_contact,
  } = location.state || {};

  let obj = {
    supp_type,
    supplier_type_id,
    partyType,
    name,
    email,
    contactPersonName,
    contactPersonContact,
    contactPersonDesignation,
    VATNO,
    CSTNO,
    PANNO,
    gst_treatment,
    gst,
    description,
    createdBy,
    modifiedBy,
    address,
    address2,
    city_id,
    city_name,
    city,
    state_id,
    state_name,
    state,
    country_name,
    country_id,
    country,
    postalCode,
    faxNo,
    party_contact,
  };

  const [main_obj, setMain_obj] = useState(obj);
  const nextStep = () => {
    let next = currentStep + 1;
    // if (next > numSteps) {
    //     next = 1;
    // }
    setCurrentStep(next);
  };

  // const previousStep = () => {
  //     let prev = currentStep - 1;
  //     if (prev < 1) {
  //         prev = numSteps;
  //     }
  //     setCurrentStep(prev);
  // };

  const previousStep = (stepNumber) => {
    if (stepNumber !== undefined && stepNumber >= 1 && stepNumber <= numSteps) {
      setCurrentStep(stepNumber);
    } else {
      let prev = currentStep - 1;
      if (prev < 1) {
        prev = numSteps;
      }
      setCurrentStep(prev);
    }
  };

  useEffect(() => {
    setConstLabel(getLabels("Party Master", "Supplier"));
  }, []);

  useEffect(() => {
    if (location.state?.uuid) {
      const payload = {
        partyId: location.state?.uuid,
      };
      get_party_contact(payload).then(
        (res) => {
          var contactArr = [];
          if (res.data.data?.length > 0) {
            for (let i = 0; i < res.data.data.length; i++) {
              contactArr.push({
                uuid: res.data.data[i].uuid,
                firstName: res.data.data[i].firstName,
                lastName: res.data.data[i].lastName,
                mobileNo: res.data.data[i].mobileNo,
                landlineNo: res.data.data[i].landlineNo,
                landlineExtentionNo: res.data.data[i].landlineExtentionNo,
                alternateMobileNo: res.data.data[i].alternateMobileNo,
                alternateLandlineExtentionNo:
                  res.data.data[i].alternateLandlineExtentionNo,
                alternateLandlineNo: res.data.data[i].alternateLandlineNo,
                // contactId: res.data.data[i].contactId,
                emailid: res.data.data[i].emailid,
                party: res.data.data[i].party,
                // partyId: res.data.data[i].partyId,
                designation: res.data.data[i].designation,
                remark: res.data.data[i].remark,
              });
            }

            let reqpayload = {
              ...obj,
              uuid: location.state.uuid,
              party_contact: contactArr,
            };
            setMain_obj(reqpayload);
          }
        },
        (err) => {}
      );
    }
  }, [location.state?.uuid]);

  return (
    <>
      <div className="mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => navigate("/pcms/supplier")}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              {constLabel?.lbl_update_supplier
                ? constLabel.lbl_update_supplier
                : "Update Supplier"}
            </p>
          </div>
          <div className="card-body" id="party_master">
            <div className="stepper-horizontal" id="stepper1">
              {[...Array(numSteps)].map((_, index) => {
                const stepNum = index + 1;
                const isEditing = stepNum === currentStep ? "editing" : "";
                const isDone = stepNum < currentStep ? "done" : "";

                return (
                  <div className={`step ${isEditing} ${isDone}`} key={index}>
                    <div className="step-circle">
                      <span>{stepNum}</span>
                    </div>
                    <div className="step-title">{steps_array[stepNum - 1]}</div>
                    <div className="step-bar-left"></div>
                    <div className="step-bar-right"></div>
                  </div>
                );
              })}
            </div>

            {currentStep == 1 && (
              <NewSupplierDetail
                nextStep={nextStep}
                main_obj={main_obj}
                setMain_obj={setMain_obj}
              />
            )}
            {currentStep == 2 && (
              <NewAddressDetail
                nextStep={nextStep}
                previousStep={previousStep}
                main_obj={main_obj}
                setMain_obj={setMain_obj}
              />
            )}
            {currentStep == 3 && (
              <NewContactDetail
                nextStep={nextStep}
                previousStep={previousStep}
                main_obj={main_obj}
                setMain_obj={setMain_obj}
              />
            )}
            {currentStep == 4 && (
              <Preview previousStep={previousStep} main_obj={main_obj} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewSupplier;

import React, { useState, useEffect} from 'react'
import { useForm, Controller } from 'react-hook-form';
import { Typeahead } from 'react-bootstrap-typeahead';
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Edit, Edit2 } from 'react-feather';
import { Modal } from 'react-bootstrap';
import { updateLocationType } from '../../../../api';
import { Tooltip } from 'react-tooltip';

const schema = yup
  .object()
  .shape({
    typename: yup
      .string()
      .required("Location type name is required !"),
  })
  .required();

function EditLocationType(props) {
const [visible, setVisible] = useState(false);

const { register, handleSubmit, formState, control, reset, watch } = useForm({
  resolver: yupResolver(schema),
  mode: "onChange",
}); // initialise the hook

let { errors } = formState;

const onSubmit = (data) => {
  

  if (data !== "") {
    const reqPayload = {
      uuid: props.data.uuid,
      typename: data.typename,
    };

    

    updateLocationType(reqPayload)
      .then(
        (res) => {
          
          if (res.status === 200 || res.status === 201) {
            toast.success("Location type update Successfully !", {
              autoClose: 3000,
            });
            setVisible(false);
            props.fetchLocationType(props.page, props.entriesPerPage);
          }
        },
        (err) => {
          
          if (err.response.status === 400) {
            
            toast.error(err.response.data.message, {
              autoClose: 2000,
            });
            // reset();
          }
        }
      )
  } else {
    errors.showMessages();
  }
};

  return (
    <React.Fragment>
      <Tooltip id={"edit-tooltip"} place="top" />
      <Edit
        data-tooltip-id={"edit-tooltip"}
        data-tooltip-content={
          props.constLabel?.lbl_edit_type
            ? props.constLabel?.lbl_edit_type
            : "Edit Type"
        }
        className="menu_icon_grey cursor_pointer me-2"
        size={20}
        onClick={() => {
          setVisible(true);
          reset();
        }}
      />
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
          <Modal.Header
            style={{ background: "#2A3643", color: "white" }}
            closeButton
          >
            <Modal.Title>{props.constLabel?.lbl_update_location_type ? props.constLabel.lbl_update_location_type : "Update Location Type"}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <form action="">
            <div className="form-group row mt-2">
              <label style={{fontSize: "14px"}} className="col-form-label col-md-12 roboto-bold">
              {props.constLabel?.lbl_location_type ? props.constLabel.lbl_location_type : "Location Type"}
                <span className="text-danger">*</span>
              </label>
              <div className="col-md-12">
                <input
                  type="text"
                  className={errors.typename ? "form-control  is-invalid"
                        : "form-control "}
                  id="typename"
                  name="typename"
                  placeholder="Enter location type name"
                  defaultValue={props.data.typename}
                  {...register("typename")}
                />
                <span className="text-danger err-msg">
                  {errors.typename?.message}
                </span>
              </div>
            </div>
          </form>
          </Modal.Body>
          <Modal.Footer>
            <button
              onClick={handleSubmit(onSubmit)}
              className="f-16 btn btn-yellow "
            >
              {props.constLabel?.lbl_save ? props.constLabel.lbl_save : "Save"}
            </button>
          </Modal.Footer>
      </Modal>

    </React.Fragment>
  )
}

export default EditLocationType
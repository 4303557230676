import React, { useState, useEffect } from 'react'
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typeahead } from "react-bootstrap-typeahead";
import * as yup from "yup";


const schema = yup
    .object()
    .shape({
        materialType: yup
            .array()
            .min(1, "Please select material type")
            .required("Please select material type"),
        consumptionPat: yup
            .string()
            .required("please enter consumption pattern"),
        selfile: yup
            .mixed()
            .test('fileSize', 'Please upload image upto 10MB', (value) => {
                if (!value || value.length === 0) return false; // File is mandatory, so return false if not provided
                return value && value[0]?.size <= 10 * 1024 * 1024;
            })
            .required('Image is required'),
    })
    .required();
function RawMaterial(props) {
    const [imagePreview, setImagePreview] = useState(null);

    const { register, handleSubmit, formState, control, reset, watch, setValue, setError } = useForm({
        resolver: yupResolver(schema),
        mode: "onChange",
    });
    let { errors } = formState;
    let image = watch("selfile")

    useEffect(() => {
        if (image?.length > 0) {
            setImagePreview(URL.createObjectURL(image[0]));
        } else {
            setImagePreview(null);
        }
    }, [image]);

    useEffect(() => {
        if (props.rawMatModal) {
            reset();
        }
    }, [props.rawMatModal])

    const onSubmit = (data) => {
        
    }

    return (
        <React.Fragment>
            <Modal show={props.rawMatModal} onHide={() => props.setRawMatModal(false)} size='lg' backdrop="static">
                <Modal.Header style={{ background: "#2A3643", color: "white" }} closeButton>
                    <Modal.Title>Raw Material</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='row'>
                        <div className='col-12 col-md-6'>
                            <label style={{ fontSize: '14px' }}
                                className='col-form-label'>Material Type</label>
                            <Controller
                                name={`materialType`}
                                control={control}
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <Typeahead
                                        id="basic-typeahead-single"
                                        labelKey="machineName"
                                        size='sm'
                                        multiple={false}
                                        options={[]}
                                        // isLoading={machineList.loading}
                                        placeholder="Select
                                                    Machine"
                                        onChange={onChange}
                                        onBlur={onBlur}
                                        selected={value}
                                        isInvalid={errors.materialType ? true : false}
                                    />
                                )}
                            />
                            {errors.materialType
                                && <span className='text-danger err-msg'>{errors.materialType.message}</span>}
                        </div>
                        <div className='col-12 col-md-6'>
                            <label style={{ fontSize: '14px' }}
                                className='col-form-label'>Consumption Pattern</label>

                            <input
                                type='text'
                                className={errors.consumptionPat ?
                                    "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"}
                                placeholder=''
                                {...register("consumptionPat")}
                            />

                            {errors.consumptionPat
                                && <span className='text-danger err-msg'>{errors.consumptionPat.message}</span>}
                        </div>

                        <div className='col-12 col-md-6'>
                            <label style={{ fontSize: '14px' }}
                                className='col-form-label'>File Upload</label>

                            <input
                                className={errors.selfile ?
                                    "form-control form-control-sm is-invalid"
                                    : "form-control form-control-sm"}
                                type="file"
                                accept="image/*"
                                placeholder='Upload Image'
                                {...register("selfile")}
                            />
                            {errors.selfile
                                && <span className='text-danger err-msg'>{errors.selfile.message}</span>}
                        </div>

                        {imagePreview && !errors.selfile &&
                            <div className='col-12 col-md-6'>
                                <label style={{ fontSize: '14px' }}
                                    className="col-form-label">Image Preview</label>

                                <div className='text-center' style={{ border: '1px solid #c6c6c6', borderRadius: '8px' }}>
                                    <img src={imagePreview} alt="Preview"
                                        style={{ maxWidth: '200px', margin: '10px', borderRadius: '8px' }} />
                                </div>
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-yellow px-4" onClick={handleSubmit(onSubmit)}>
                        Save
                    </button>
                    <button className="btn btn-grey px-4" onClick={() => props.setRawMatModal(false)}>
                        Close
                    </button>
                </Modal.Footer>
            </Modal>
        </React.Fragment>
    )
}

export default RawMaterial
import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  download_product_template,
  bulk_upload_product,
  get_masters_count,
  getProductType,
} from "../../../../api/index";
import { isExcelFile } from "../../../../common/Functions/CommonFunctions";
import { Tooltip } from "react-tooltip";
import BulkImport from "../../../../assets/images/icons/project_menu/bulk_import.png";
import Check from "../../../../assets/images/icons/project_menu/check.png";
import { Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { MANUFACTURED_GOODS, RAW_MATERIAL, FINISHED_GOODS, CONSUMABLE_ITEMS } from "../../../../common/Constants/constants.js";
import info from "../../../../assets/images/info.png";

function SetProduct(props) {
  let navigate = useNavigate();
  const { nextStep, prevStep, steps, setSteps, setCurrentStep, setMst_Count } = props;

  const [constLabel, setConstLabel] = useState({});
  const [disableBtn, setDisableBtn] = useState(false);
  const [base64String, setBase64String] = useState("");

  const [prodType, setProdType] = useState({ data: [], loading: false });

  const [importData, setImportData] = useState(false);
  const [downloadTemplate, setDownloadTemplate] = useState(false);

  const { register, handleSubmit, formState, control, reset, setValue, watch } =
    useForm({
      mode: "onChange",
    });

  let { errors } = formState;

  // const mst_count = JSON.parse(localStorage.getItem("mst_count"));

  const prod_type = watch("type");

  useEffect(() => {
    fetchProductType();
  }, []);

  const onSubmit = () => {
    // window.location.href = "/pcms/adminDashboard";
    setImportData(true);
    if (base64String && base64String !== "") {
      const payload = {
        file_base_64: base64String,
        type: prod_type && prod_type?.[0]?.productType,
      };

      bulk_upload_product(payload)
        .then(
          (res) => {
            if (res.status === 200 || res.status === 201) {
              toast.success("Product imported successfully !", {
                autoClose: 2000,
              });

              localStorage.removeItem("mst_count");

              // After Onboarding get record count of primary masters
              get_masters_count().then(
                (res) => {
                  // console.log("masters count", res);
                  setMst_Count(res.data)
                  localStorage.setItem("mst_count", JSON.stringify(res.data));
                  if (
                    (res.data?.ManufacturedGoods !== 0 || res.data?.finishedGoods !== 0) &&
                     res.data?.rawMaterial !== 0
                  ) {
                      setTimeout(() => {
                          window.location.href = "/pcms/adminDashboard";
                      }, 2300);
                  } else {
                    setCurrentStep(4);
                  }
                },
                (err) => {
                  console.log("err", err);
                }
              );
              // After Onboarding get record count of primary masters

             
              
            }
          },
          (err) => {
            if (err?.response?.status === 400) {
              let message=err?.response?.data?.message; 
              toast.error(message?message:"Something went wrong , please try after some time !", {
                autoClose: 2000,
              });
            }else{
              toast.error("Something went wrong , please try after some time !", {
                autoClose: 2000,
              });
            }
          }
        )
        .finally(() => {
          setImportData(false);
        });
    } else {
      setImportData(false);
    }
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0]; // Get the selected file
    // console.log("file", file);
    // setSelectedFile(file);

    if (isExcelFile(file.name)) {
      try {
        if (file) {
          //   console.log("file", file);
          const reader = new FileReader();

          reader.readAsDataURL(file);
          reader.onload = () => {
            const base64 = reader.result.split(",")[1];
            setBase64String(base64);
          };

          reader.onerror = (error) => {
            toast.error("Error creating base 64 string !", {
              autoClose: 2000,
            });
          };
        }
      } catch (err) {
        toast.error("Something went wrong !", {
          autoClose: 2000,
        });
      }
    } else {
      toast.error("Import valid excel file !", {
        autoClose: 2000,
      });
    }
  };

  function base64ToExcel(response) {
    const { statusCode, headers, body, isBase64Encoded } = response;

    // Assume base64String contains the base64 representation of the Excel file
    const base64String = body; // Replace this with your base64 string

    // Convert the base64 string into a Uint8Array
    const bytes = Uint8Array.from(atob(base64String), (c) => c.charCodeAt(0));

    // Create a Blob object from the Uint8Array
    const blob = new Blob([bytes], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a URL for the Blob object
    const url = URL.createObjectURL(blob);

    // Create a link element with download attribute
    const link = document.createElement("a");
    link.href = url;
    const currentDate = new Date().toDateString().split(" ").join("_");
    link.download = `Product_Template_${currentDate}.xlsx`;

    // Programmatically click on the link to trigger the download
    setDownloadTemplate(false);
    document.body.appendChild(link);
    link.click();

    // Cleanup: remove the link and revoke the URL
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }

  function fetchProductType() {
    setProdType({ ...prodType, data: [], loading: true });
    getProductType().then(
      (res) => {
        // console.log("product type res", res);
        if(res && res.data.data.length > 0){

          const productTypes = [MANUFACTURED_GOODS, RAW_MATERIAL, FINISHED_GOODS, CONSUMABLE_ITEMS];

          let prod_type_arr = res.data.data.filter(
            (item) => productTypes.includes(item.productType)
          );
          setProdType({ ...prodType, data: prod_type_arr, loading: false });

          let result = prod_type_arr.filter(
            (item) => item.productType == MANUFACTURED_GOODS
          );
          setValue("type", result);
        } else {
          toast.error("Product type not found!", {
            autoClose: 2000,
          });
        }
      },
      (err) => {
        setProdType({ ...prodType, data: [], loading: false });
      }
    );
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="row StepOne mt-4">
        <div className="col-12 col-md-8 mx-auto">
          <div className="card shadow-sm">
            <div className="card-body">
              <div className="text-center">
                <p className="bold body1">Setup Product Details</p>
                <p className="body3 text-danger">* Please add record for both product type Manufactured Goods/Finished Goods and Raw material</p>
              </div>

              <div className="d-flex justify-content-center pb-4">
                <div className="form-group col-12 col-md-4 typeahead-container">
                  <label className="lbl-style roboto-bold">
                    {"Product Type"}{" "}
                    <Tooltip id={"open-tooltip"} place="top" />
                    <img
                      src={info}
                      alt="info"
                      className="cursor_pointer"
                      data-tooltip-id={"open-tooltip"}
                      data-tooltip-html="Classifies items based on their role in the manufacturing process, including categories<br />
                             such as raw materials, components, finished goods, packaging materials, tools, and gauges. This field helps in<br /> 
                             organizing and tracking inventory for streamlined production, procurement, and management."
                      height={20}
                      width={20}
                    />
                  </label>
                  <Controller
                    name="type"
                    control={control}
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <Typeahead
                        id="basic-typeahead-single"
                        labelKey="productType"
                        size="sm"
                        multiple={false}
                        options={prodType.data}
                        isLoading={prodType.loading}
                        placeholder="Select product type"
                        onChange={(selected) => {
                          onChange(selected);
                        }}
                        selected={value}
                        onBlur={onBlur}
                        isInvalid={errors.type ? true : false}
                      />
                    )}
                  />
                  {!prodType.loading && <span className="arrow-icon"></span>}
                </div>
              </div>

              {/* Card Section with Template and Import Options */}
              <div className="row justify-content-center">
                <div className="col-12 col-sm-6 col-md-3 col-lg-3 py-1">
                  <div className="card shadow-sm cursor_pointer">
                    <div
                      className="card-body py-1 text-center"
                      onClick={() => {
                        setDownloadTemplate(true);

                        download_product_template({
                          type: prod_type && prod_type?.[0]?.productType,
                        }).then(
                          (res) => {
                            // console.log("res", res);
                            base64ToExcel(res.data);
                          },
                          (err) => {
                            // console.log("err", err);
                            toast.error("Something went wrong", {
                              autoClose: 2000,
                            });
                            setDownloadTemplate(false);
                          }
                        );
                      }}
                      disabled={downloadTemplate}
                    >
                      <Tooltip id="open-tooltip1" place="top" />
                      <img
                        src={Check}
                        alt="info"
                        data-tooltip-id="open-tooltip1"
                        data-tooltip-content="Download Excel Template"
                        height={40}
                        width={40}
                      />
                      <p className="bold body2 mt-3">Template</p>{" "}
                      {downloadTemplate && (
                        <Spinner
                          animation="border"
                          size="sm"
                          className="ms-2"
                        />
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-12 col-sm-6 col-md-3 col-lg-3 py-1">
                  <div className="card shadow-sm cursor_pointer">
                    <input
                      type="file"
                      accept=".xls,.xlsx"
                      onChange={handleFileChange}
                      style={{ display: "none" }} // Hide the input element
                      id="fileInput2"
                    />
                    <div
                      className="card-body py-1 text-center"
                      onClick={() =>
                        document.getElementById("fileInput2").click()
                      }
                      onChange={handleFileChange}
                    >
                      <Tooltip id="open-tooltip2" place="top" />
                      <img
                        src={BulkImport}
                        alt="info"
                        className="cursor_pointer"
                        data-tooltip-id="open-tooltip2"
                        data-tooltip-content="Upload Customer Excel"
                        height={40}
                        width={40}
                      />
                      <p className="bold body2 mt-3">
                        Bulk Import
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* Button Section */}
              <div className="d-flex justify-content-center mt-4 flex-wrap">
                <button
                  className="btn button-secondary px-4 py-2 me-2 mt-2"
                  onClick={() => prevStep()}
                >
                  Back
                </button>
                <button
                  disabled={importData}
                  className="btn button-primary px-4 py-2 mt-2"
                  onClick={handleSubmit(onSubmit)}
                >
                  {constLabel?.lbl_proceed ? constLabel.lbl_proceed : `Proceed`}{" "}
                  {importData && <Spinner animation="border" size="sm" />}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SetProduct;

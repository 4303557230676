import React from 'react'
import logo from "../assets/images/proditm.png"

function Footer() {
    const presentYear = new Date().getFullYear();
    return (
        // <div className='py-2' style={{
        //     position: 'fixed',
        //     left: 0,
        //     bottom: 0,
        //     width: '100%',
        //     backgroundColor: 'white',
        //     color: 'black',
        //     textAlign: 'center',
        //     zIndex: '99',
        //     fontSize: "14px"
        // }}> <span>Copyright {presentYear} © <a href='https://www.technicious.in/'>Technicious</a> All rights reserved. Version {process.env.REACT_APP_VERSION}</span>
        
        // </div>

        <div className="row py-2 fixed-bottom bg-white text-black text-center body1" style={{ zIndex: '99'}}>
            <div className='d-md-flex justify-content-end col-12 col-md-8'>
                <span className='me-2 my-1'>
                    Copyright {presentYear} © 
                    <a href="https://www.technicious.in/"> Technicious </a> 
                    All rights reserved. Version {process.env.REACT_APP_VERSION}
                </span>
            </div>
            <div className='d-md-flex justify-content-end col-12 col-md-4'>
                <img className='me-4' src={logo} height={25} width={70}></img>
            </div>
            {/* <span className='me-2 my-1'>
                Copyright {presentYear} © 
                <a href="https://www.technicious.in/"> Technicious </a> 
                All rights reserved. Version {process.env.REACT_APP_VERSION}
            </span> */}
            {/* <img src={logo} height={20} width={70}></img> */}
        </div>
    )
}

export default Footer
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import {
  getCountry, getState, getCity,
  get_country, get_state, get_city,
  getCustomerTypeAll,
  getCustomerType
} from "../../../../../api/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { getLabels } from "../../../../../common/Functions/CommonFunctions";

const schema = yup.object().shape({
    customerType: yup
      .array()
      .min(1, "Please select customer type !")
      .required("Please select customer type !"),
    organization: yup
      .string()
      .required("Company name is required !")
      .matches(/^[a-zA-Z&./ ]*$/, "Enter valid company name"),
    address: yup
      .string()
      .required("Address is required !"),
    cState: yup
      .array()
      .min(1, "Please select state !")
      .required("Please select state !"),
    cCountry: yup
      .array()
      .min(1, "Please select country !")
      .required("Please select country !"),
    cCity: yup
      .array()
      .min(1, "Please select city !")
      .required("Please select city !"),
    email: yup.string().email("Enter valid email id").notRequired(),
    primaryContactNo: yup.string().matches(/^[0-9]*$/, 'Enter valid contact number').notRequired(),
    secondaryContactNo: yup.string().matches(/^[0-9]*$/, 'Enter valid alternate contact number').notRequired(),
    postalCode: yup.string().matches(/^[0-9]*$/, 'Enter valid postal code').notRequired(),
  })
  .required();

function CreateBasicDetail(props) {
  const [countryList, setCountryList] = useState({ data: [], state: false });
  const [stateList, setStateList] = useState({ data: [], state: false });
  const [cityList, setCityList] = useState({ data: [], state: false });
  const [custType, setCustType] = useState({ data: [], state: false });

  const [constLabel, setConstLabel] = useState({});

  var FormData = require("form-data");
  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  }); // initialise the hook

  const selectedCountry = watch("cCountry");
  const selectedState = watch("cState");

  let { errors } = formState;

  useEffect(() => {
    setConstLabel(getLabels('Party Master', 'Customer'));
    // resetting the form
    setCustType({ ...custType, state: true, data: [] });

    //axios.post("https://masters.ravicam.in/api/master/country/",{payload}).then(
    getCustomerType().then(
      (res) => {
        
        setCustType({
          ...custType,
          state: false,
          data: res.data.data,
        });
      },
      (err) => {
        
        setCustType({ ...custType, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }, []);

  useEffect(() => {
    // resetting the form
    reset();
    setCountryList({ ...countryList, state: true, data: [] });

    //axios.post("https://masters.ravicam.in/api/master/country/",{payload}).then(
    get_country().then(
      (res) => {
        
        setCountryList({
          ...countryList,
          state: false,
          data: res.data.data,
        });
      },
      (err) => {
        
        setCountryList({ ...countryList, state: false, data: [] });
        toast.error(err.response.data.message, {
          autoClose: 3000,
        });
      }
    );
  }, []);

  useEffect(() => {
    

    // fetching states for selected country through api
    if (selectedCountry !== undefined && selectedCountry.length !== 0) {
      setStateList({ ...stateList, data: [], state: true });
      
      const payload = {
        country_id: selectedCountry[0]?.uuid,
      };
      get_state(payload).then(
        (res) => {
          
          setStateList({
            ...stateList,
            data: res.data.data,
            state: false,
          });
        },
        (err) => {
          
          setStateList({ ...stateList, data: [], state: false });
          // toast.error("Something went wrong in fetching states !", { autoClose: 5000 })
        }
      );
    }
  }, [selectedCountry]);

  useEffect(() => {
    
    if (selectedState !== undefined && selectedState.length > 0) {
      setCityList({ ...cityList, data: [], state: true });
      

      const payload = {
        state_id: selectedState[0]?.uuid,
      };

      get_city(payload).then(
        (res) => {
          
          setCityList({ ...cityList, data: res.data.data, state: false });
        },
        (err) => {
          
          setCityList({ ...cityList, data: [], state: false });
          // toast.error("Something went wrong in fetching cities !", { autoClose: 5000 })
        }
      );
    }
  }, [selectedState]);

  const onSubmit = (data) => {
    

    if (data !== "") {
      const basic = {
        partyType: "Customer",
        customerType: data.customerType[0].typename,
        organization: data.organization,
        website: data.website,
        name: data.name,
        primaryContactNo: data.primaryContactNo,
        secondaryContactNo: data.secondaryContactNo,
        email: data.email,
        country: data.cCountry[0]?.id,
        state: data.cState[0]?.id,
        city: data.cCity[0]?.id,
        country_name: data.cCountry[0]?.countryName,
        state_name: data.cState[0]?.stateName,
        city_name: data.cCity[0]?.cityName,
        country_id: data.cCountry[0]?.uuid,
        state_id: data.cState[0]?.uuid,
        city_id: data.cCity[0]?.uuid,
        address: data.address,
        postalCode: data.postalCode,
        vendor_code: data.vendor_code,
        createdBy: 1,
        modifiedBy: 1,
      };

      localStorage.setItem("basic", JSON.stringify(basic));
      props.setKey("contactDetail");
    } else {
      errors.showMessages();
    }
  };

  return (
    <>
      <div className="row mt-2">
        <div className="col-md-6">
          <div className="form-group row">
            <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_customer_type ? constLabel.lbl_customer_type : "Customer Type"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <div className="col-md-8">
              <Controller
                name="customerType"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="typename"
                    multiple={false}
                    size="sm"
                    options={custType.data !== undefined ? custType.data : []}
                    isLoading={custType.state}
                    placeholder="Select customer type"
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid={errors.customerType ? true : false}
                  />
                )}
              />
              <span className="text-danger err-msg">
                {errors.customerType?.message}
              </span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_name ? constLabel.lbl_name : "Name"}
              </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="name"
                name="name"
                placeholder="Enter full name"
                {...register("name")}
              />
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_alternate_contact ? constLabel?.lbl_alternate_contact : "Alternate Contact"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="secondaryContactNo"
                name="secondaryContactNo"
                maxLength={10}
                placeholder="Enter alternate contact"
                {...register("secondaryContactNo")}
              />
              <span className="text-danger err-msg">{errors.secondaryContactNo?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_email_id ? constLabel.lbl_email_id : "Email Id"}
              </label>
            <div className="col-md-8">
              <input
                type="email"
                className="form-control form-control-sm"
                id="email"
                name="email"
                placeholder="Enter email id"
                {...register("email")}
              />
              <span className="text-danger err-msg">{errors.email?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_address ? constLabel.lbl_address : "Address"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <div className="col-md-8">
              <textarea
                type="text"
                className={
                  errors.address
                    ? "form-control form-control-sm is-invalid"
                    : "form-control form-control-sm"
                }
                id="address"
                name="address"
                placeholder="Enter address"
                {...register("address")}
              ></textarea>
              <span className="text-danger err-msg">{errors.address?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_state ? constLabel.lbl_state : "State"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <div className="col-md-8">
              <Controller
                name="cState"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="stateName"
                    size="sm"
                    multiple={false}
                    options={stateList.data !== undefined ? stateList.data : []}
                    isLoading={stateList.state}
                    placeholder="Select state"
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid={errors.cState ? true : false}
                  />
                )}
              />
              <span className="text-danger err-msg">{errors.cState?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
            {constLabel?.lbl_postal_code ? constLabel.lbl_postal_code : "Postal Code"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="postalCode"
                name="postalCode"
                placeholder="Enter postal code"
                {...register("postalCode")}
                maxLength={6}
              />
              <span className="text-danger err-msg">{errors.postalCode?.message}</span>
            </div>
          </div>
        </div>

        <div className="col-md-6">
          <div className="form-group row">
            <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
            {constLabel?.lbl_company_name ? constLabel.lbl_company_name : "Company Name"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className={
                  errors.organization
                    ? "form-control form-control-sm mt-2 is-invalid"
                    : "form-control form-control-sm mt-2"
                }
                id="organization"
                name="organization"
                placeholder="Enter company name"
                {...register("organization")}
              />
              <span className="text-danger err-msg">{errors.organization?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
            {constLabel?.lbl_contact_no ? constLabel.lbl_contact_no : "Contact No"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="primaryContactNo"
                name="primaryContactNo"
                placeholder="Enter contact no"
                maxLength={10}
                {...register("primaryContactNo")}
              />
               <span className="text-danger err-msg">{errors.primaryContactNo?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_website ? constLabel.lbl_website : "Website"}</label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="website"
                name="website"
                placeholder="Enter website"
                {...register("website")}
              />
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_vendor_id ? constLabel.lbl_vendor_id : "Vendor Id"}
            </label>
            <div className="col-md-8">
              <input
                type="text"
                className="form-control form-control-sm"
                id="vendor_code"
                name="vendor_code"
                placeholder="Enter vendor code"
                {...register("vendor_code")}
              />
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_country ? constLabel.lbl_country : "Country"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <div className="col-md-8">
              <Controller
                name="cCountry"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="countryName"
                    multiple={false}
                    size="sm"
                    options={
                      countryList.data !== undefined ? countryList.data : []
                    }
                    isLoading={countryList.state}
                    placeholder="Select country"
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid={errors.cCountry ? true : false}
                  />
                )}
              />
              <span className="text-danger err-msg">{errors.cCountry?.message}</span>
            </div>
          </div>

          <div className="form-group row mt-2">
            <label style={{fontSize: "14px"}} className="col-form-label col-md-4">
              {constLabel?.lbl_city ? constLabel.lbl_city : "City"}
              <span>
                <i style={{ color: "red" }}>*</i>
              </span>
            </label>
            <div className="col-md-8">
              <Controller
                name="cCity"
                control={control}
                render={({ field: { onChange, onBlur, value, ref } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="cityName"
                    size="sm"
                    multiple={false}
                    options={cityList.data !== undefined ? cityList.data : []}
                    isLoading={cityList.state}
                    placeholder="Select city"
                    onChange={onChange}
                    onBlur={onBlur}
                    selected={value}
                    isInvalid={errors.cCity ? true : false}
                  />
                )}
              />
              <span className="text-danger err-msg">{errors.cCity?.message}</span>
            </div>
          </div>
        </div>
      </div>

      <div className="row justify-content-between mt-3">
        <div className="col-md-4">
          <span>
            <i style={{ color: "red" }}>*</i>
            <u> indicate Mandatory fields</u>
          </span>
        </div>
        <div className="col-sm-2 text-end">
          <button
            onClick={handleSubmit(onSubmit)}
            className="f-16 btn btn-yellow "
          >
            {constLabel?.lbl_next ? constLabel.lbl_next : "Next"}
            {/* <FontAwesomeIcon
              style={{ color: "#000" }}
              icon={faPlay}
              size="sm"
            /> */}
          </button>
        </div>
      </div>
    </>
  );
}

export default CreateBasicDetail;

import React from "react";
import SweetAlert from "sweetalert2";
import logo from "../../../assets/images/sample.png";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Inter from "../../../assets/fonts/Inter-Regular.ttf";
import Inter600 from "../../../assets/fonts/Inter-SemiBold.ttf";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import numWords from "num-words";
import {
  PDFViewer,
  Document,
  Text,
  View,
  Image,
  Page,
  Link,
  StyleSheet,
  Font,
  PDFDownloadLink,
} from "@react-pdf/renderer";

function ViewQuotation() {
  let navigate = useNavigate();

  Font.register({
    family: "Inter, sans-serif",
    fonts: [
      {
        src: Inter,
      },
      {
        src: Inter600,
        fontWeight: 600,
      },
    ],
  });

  const styles = StyleSheet.create({
    page: {
      backgroundColor: "#fff",
      color: "black",
      // fontFamily: "Roboto, sans-serif",
      fontFamily: "Inter, sans-serif",
      padding: "20px",

      height: "80%",
    },

    viewer: {
      width: "100%",
      height: window.innerHeight,
    },

    btm: {
      // marginBottom: 200,
      flex: 1,
    },
    tableContainer: {
      flexDirection: "row",
      borderTopWidth: 1,
      borderTopColor: "grey",
      borderTopStyle: "solid",
      borderBottomWidth: 1,
      borderBottomColor: "grey",
      borderBottomStyle: "solid",
      borderLeftWidth: 1
      // marginBottom: 3,
    },
    columnHeader1: {
      width: "20%",
      paddingTop: 3,
      paddingBottom: 3,
      // paddingRight: 10,
      fontSize: "12px",
      fontWeight: "bold",
      textAlign: "center",
      borderRightWidth: 1,
      borderRightStyle: "solid",
    },
    columnValue: {
      width: "20%",
      paddingBottom: 2,
      paddingRight: 10,
      fontSize: "11px",
      textAlign: "center",
      borderRightWidth: 1,
      borderRightStyle: "solid",
    },
  });

  return (
    <React.Fragment>
      <ToastContainer />

      <div className="manufac_goods_form mb-4">
        <div className="card">
          <div className="card-header bg-grey d-flex align-items-center">
            <button
              className="btn btn-yellow"
              onClick={() => {
                navigate("/pcms/quotation/");
              }}
            >
              <FontAwesomeIcon icon={faArrowLeft} size="sm" />
            </button>

            <p className="mb-0 ms-3" style={{ fontSize: "24px" }}>
              View Quotation
            </p>
          </div>
          <div className="card-body">
            <div className="createNc container-fluid">
              <PDFViewer style={styles.viewer}>
                <Document>
                  <Page size="A4" style={styles.page}>
                    <View
                      style={{
                        height: "8%",
                        padding: "0px 15px",
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flexDirection: "row",
                        border: "1px solid gray",
                        paddingBottom: "10px",
                        paddingTop: "10px",
                      }}
                      fixed
                    >
                      <View>
                        {/* <Image style={{ width: '50px' }} src={userlogo === null ? logo : userlogo} /> */}
                        <Image
                          style={{ width: "150px", height: "40px" }}
                          src={logo !== null ? logo : null}
                        />
                      </View>

                      <View
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <Text style={{ fontSize: "16px", fontWeight: "600" }}>
                          {"Demo Company"}
                        </Text>
                        <Text style={{ fontSize: "12px"}}>
                          {"MIDC, Kolhapur"}
                        </Text>
                      </View>
                    </View>

                    <View
                      style={[
                        styles.btm,
                        {
                          borderLeft: "1px solid gray",
                          borderRight: "1px solid gray",
                          borderBottom: "1px solid gray",
                          // paddingTop: '10px',
                          fontSize: "12px",
                        },
                      ]}
                      wrap
                    >
                      <View
                        style={{
                          padding: "0px 10px",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          marginTop: "5px",
                          marginBottom: "5px",
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <View style={{ width: "30%" }}>
                            <Text
                              style={{
                                fontSize: "12px",
                                paddingBottom: "10px",
                                fontWeight: "700",
                              }}
                            >
                              Quotation By
                            </Text>
                            <Text style={{ fontSize: "12px", paddingBottom: "5px" }}>
                              Ravi Cam pvt ltd{" "}
                            </Text>
                            <Text style={{ fontSize: "10px", paddingBottom: "5px" }}>
                              66 E ward Shivaji Udyamnagar Kolhapur - 416001{" "}
                            </Text>
                            <View style={{flexDirection: 'row' }}>
                            <Text style={{ fontSize: "10px", fontWeight: '600'}}>
                              Contact :
                            </Text>
                            <Text style={{ fontSize: "10px", marginLeft: '2px'}}>
                              0231-2334455
                            </Text>
                            </View>
                            <View style={{flexDirection: 'row' }}>
                            <Text style={{ fontSize: "10px", fontWeight: '600'}}>
                              GSTIN/UIN :
                            </Text>
                            <Text style={{ fontSize: "10px", marginLeft: '2px'}}>
                              27PQRST9876U9876
                            </Text>
                            </View>
                            <View style={{flexDirection: 'row' }}>
                            <Text style={{ fontSize: "10px", fontWeight: '600'}}>
                              Email :
                            </Text>
                            <Text style={{ fontSize: "10px", marginLeft: '2px'}}>
                              ravicampvtltd@gmail.com
                            </Text>
                            </View>
                          </View>
                          <View style={{ width: "5%" }}></View>
                          <View style={{ width: "30%" }}>
                            <Text
                              style={{
                                fontSize: "12px",
                                paddingBottom: "10px",
                                fontWeight: "700",
                              }}
                            >
                              Quotation To
                            </Text>
                            <Text style={{ fontSize: "12px", paddingBottom: "5px" }}>
                              Bharat Cam pvt ltd{" "}
                            </Text>
                            <Text style={{ fontSize: "10px", paddingBottom: "5px" }}>
                              100/12 C ward Shivaji Peth Kolhapur - 416012
                            </Text>
                            <View style={{flexDirection: 'row' }}>
                            <Text style={{ fontSize: "10px", fontWeight: '600'}}>
                              Contact :
                            </Text>
                            <Text style={{ fontSize: "10px", marginLeft: '2px'}}>
                              0231-2334455
                            </Text>
                            </View>
                            <View style={{flexDirection: 'row' }}>
                            <Text style={{ fontSize: "10px", fontWeight: '600'}}>
                              GSTIN/UIN :
                            </Text>
                            <Text style={{ fontSize: "10px", marginLeft: '2px'}}>
                              27ABCDE1234F1234
                            </Text>
                            </View>
                            <View style={{flexDirection: 'row' }}>
                            <Text style={{ fontSize: "10px", fontWeight: '600'}}>
                              Email :
                            </Text>
                            <Text style={{ fontSize: "10px", marginLeft: '2px'}}>
                              bharatcampvtltd@gmail.com
                            </Text>
                            </View>
                          </View>
                          <View style={{ width: "5%" }}></View>
                          <View style={{ width: "30%" }}>
                            <View
                              style={{ display: "flex", flexDirection: "row", justifyContent: 'flex-end' }}
                            >
                              <Text
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "700",
                                }}
                              >
                                Quotation No :
                              </Text>
                              <Text
                                style={{ fontSize: "10px", marginLeft: "5px", paddingBottom: "5px", marginTop: "2px" }}
                              >
                                Inv/2023/01
                              </Text>
                            </View>
                            <View
                              style={{ display: "flex", flexDirection: "row", justifyContent: 'flex-end' }}
                            >
                              <Text
                                style={{
                                  fontSize: "12px",
                                  fontWeight: "700",
                                }}
                              >
                                Date :
                              </Text>
                              <Text
                                style={{ fontSize: "10px", marginLeft: "5px", paddingBottom: "5px", marginTop: "2px" }}
                              >
                                14-09-2023
                              </Text>
                            </View>
                          </View>
                        </View>
                      </View>
                      <View
                        style={{
                          height: "1px",
                          backgroundColor: "grey",
                          width: "100%",
                        }}
                      ></View>

                      <View
                        style={{
                          padding: "0px 10px",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          marginTop: "10px",
                        }}
                      >
                        <View style={{ display: "flex", flexDirection: "row" }}>
                          <Text
                            style={{
                              fontWeight: "700",
                              fontSize: "12px",
                            }}
                          >
                            {"Quotation Items "}
                          </Text>
                        </View>
                      </View>

                      <View
                        style={[
                          {
                            flexDirection: "row",
                            fontSize: "12px",
                            borderLeft: "1px solid gray",
                            borderRight: "1px solid gray",
                            borderBottom: "1px solid gray",
                            borderTop: "1px solid gray",
                            // padding: '10px',
                            margin: "10px",
                          },
                        ]}
                        wrap
                        // key={ind}
                      >
                        <View style={{ width: "100%" }}>
                          
                          <View style={styles.tableContainer}>
                            <Text style={[styles.columnHeader1, {width:"5%"}]}>Sr </Text>
                            <Text style={[styles.columnHeader1, {width:"45%"}]}>Description of Goods</Text>
                            <Text style={[styles.columnHeader1, {width:"15%"}]}>Quantity</Text>
                            <Text style={[styles.columnHeader1, {width:"10%"}]}>Unit Price</Text>
                            <Text style={[styles.columnHeader1, {width:"10%"}]}>Unit</Text>
                            <Text style={[styles.columnHeader1, {width:"15%"}]}>Amount ({"\u20B9"}) </Text>
                          </View>
                          {/* {state.stdwiseInvoiceList?.data !== undefined
                            ? state.stdwiseInvoiceList?.data.map(
                            (item, itemi) => ( */}
                               {[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0].map((item,itemi)=>{
                               return  <View
                                  key={itemi}
                                 style={[
                                   styles.tableContainer,
                                   { display: "flex" },
                                 ]}
                               >
                                 <Text style={[styles.columnValue, {width:"5%"}]}>
                                   {itemi+1}
                                 </Text>
                                 <Text style={[styles.columnValue, {width:"45%"}]}>
                                   {"Product 1"}
                                 </Text>
                                 <Text style={[styles.columnValue, {width:"15%"}]}>
                                   {"10"}
                                 </Text>
                                 <Text style={[styles.columnValue, {width:"10%"}]}>
                                   {"100"}
                                 </Text>
                                 <Text style={[styles.columnValue, {width:"10%"}]}>
                                   {"Kg"}
                                 </Text>
                                 <Text style={[styles.columnValue, {width:"15%"}]}>
                                   {"1000"}
                                 </Text>
                               </View>
                               })}
                              {/*}  )
                              )
                            : null} */}

                          <View style={[styles.tableContainer, {fontWeight: 600}]}>
                            <Text style={[styles.columnValue, {width:"5%"}]}></Text>
                            <Text style={[styles.columnValue, {width:"45%"}]}>Total</Text>
                            <Text style={[styles.columnValue, {width:"15%"}]}>180</Text>
                            <Text style={[styles.columnValue, {width:"10%"}]}>1800</Text>
                            <Text style={[styles.columnValue, {width:"10%"}]}></Text>
                            <Text style={[styles.columnValue, {width:"15%"}]}>18000</Text>
                          </View>
                          
                        </View>
                      </View>
                      {/*}))} */}
                      
                      <View
                        style={{
                          padding: "0px 10px",
                          justifyContent: "space-between",
                          flexDirection: "row",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <View style={{ width: "45%" }}>
                            <Text
                              style={{
                                fontSize: "12px",
                                paddingBottom: "10px",
                                fontWeight: "700",
                              }}
                            >
                              Terms & Conditions
                            </Text>
                            <Text style={{ fontSize: "10px", paddingBottom: "5px" }}>
                              The quotation is valid for 1 month only{" "}
                            </Text>
                            <Text style={{ fontSize: "10px", paddingBottom: "5px" }}>
                              Please quote invoice number when remitting fund
                            </Text>
                          </View>
                          <View style={{ width: "10%" }}></View>
                          <View style={{ width: "45%" }}>
                          <View style={styles.tableContainer}>
                            <Text style={[styles.columnHeader1, {width:"60%"}]}>CGST (9%) </Text>
                            <Text style={[styles.columnHeader1, {width:"40%"}]}>{"\u20B9"} 1620 /-</Text>
                          </View>
                          <View style={styles.tableContainer}>
                            <Text style={[styles.columnHeader1, {width:"60%"}]}>SGST (9%) </Text>
                            <Text style={[styles.columnHeader1, {width:"40%"}]}>{"\u20B9"} 1620 /-</Text>
                          </View>
                          <View style={styles.tableContainer}>
                            <Text style={[styles.columnHeader1, {width:"60%"}]}>Final Amount </Text>
                            <Text style={[styles.columnHeader1, {width:"40%"}]}>{"\u20B9"} 21240 /-</Text>
                          </View>
                          <View style={styles.tableContainer}>
                            <Text style={[styles.columnHeader1, {width:"20%"}]}>In words </Text>
                            <Text style={[styles.columnHeader1, {width:"80%", fontSize: '10px'}]}>Rupees twenty one thousand two hundred forty only</Text>
                          </View>
                          </View>
                        </View>
                      </View>

                      <View
                        style={{
                          padding: "0px 10px",
                          justifyContent: "flex-end",
                          flexDirection: "row",
                          marginTop: "auto",
                          marginBottom: "10px",
                        }}
                      >
                        <View
                          style={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "row",
                          }}
                        >
                          <View style={{ width: "45%" }}></View>
                          <View style={{ width: "10%" }}></View>
                          <View style={{ width: "45%", alignItems: "flex-end" }}>
                            <Text style={{ fontSize: "12px", fontWeight: "600" }}>
                              {"Authorized Signature"}
                            </Text>
                          </View>
                        </View>
                      </View>
                    </View>

                    <View
                      style={{
                        // padding: "0px 15px",
                        display: "flex",
                        height: "30px",
                        justifyContent: "space-between",
                        alignItems: "flex-end",
                        flexDirection: "row",
                        // position: "absolute",
                        // bottom: "10px",
                        width: "100%",
                        // paddingTop: '20px',
                        paddingBottom: "5px",
                        paddingHorizontal: 10,
                        // paddingTop: '5px',
                        border: "1px solid gray",

                        // backgroundColor: "red",
                      }}
                      fixed
                    >
                      <Text
                        style={{
                          fontSize: "10px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                      >
                        {"For any enquiries, email us on abc@gmail.com or call us on +91 9876543210"}
                      </Text>
                      <Text
                        style={{
                          fontSize: "12px",
                          display: "flex",
                          justifyContent: "flex-end",
                        }}
                        render={({ pageNumber, totalPages }) =>
                          `Page ${pageNumber} / ${totalPages}`
                        }
                      ></Text>
                    </View>
                  </Page>
                </Document>
              </PDFViewer>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ViewQuotation;

import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form';
import { Eye } from 'react-feather';
import { Modal } from "react-bootstrap";
import { Tooltip } from 'react-tooltip';

function ViewMachineCategory(props) {

const [visible, setVisible] = useState(false);
const { register, handleSubmit, formState, control, reset, watch } = useForm({
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;

  return (
    <React.Fragment>
        <Tooltip id={"view-tooltip"} place="top" />
        <Eye
            data-tooltip-id={"view-tooltip"}
            data-tooltip-content={
              props.constLabel?.lbl_view_category
              ? props.constLabel?.lbl_view_category
              : "View Category"
            }
          className="menu_icon_grey cursor_pointer me-2"
          size={20} 
          onClick={() => setVisible(true)}
        />
      {/* Add Item Modal*/}
      <Modal show={visible} onHide={() => setVisible(false)} size={"md"}>
        <Modal.Header style={{ background: "#2A3643", color: "white" }} closeButton>
          <Modal.Title>{props.constLabel?.lbl_machine_category_detail ? props.constLabel?.lbl_machine_category_detail : "Machine Category Detail"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form action="">
            <div className="form-group row mt-2">
              <label className="form-label form_label col-md-4">{props.constLabel?.lbl_category_name ? props.constLabel.lbl_category_name : "Category Name"}</label>
              <div className="col-md-8">
                <label className="form-label roboto-bold">{props.data.machineCatName}</label>
              </div>
            </div>
            <div className="form-group row mt-2">
              <label className="form-label form_label col-md-4">{props.constLabel?.lbl_remark ? props.constLabel.lbl_remark : "Remark"}</label>
              <div className="col-md-8">
                <label className="form-label roboto-bold">{props.data.remark}</label>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  )
}

export default ViewMachineCategory
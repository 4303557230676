import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { Eye } from "react-feather";
import { Modal } from "react-bootstrap";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import DatePicker from "react-datepicker";

function ViewMachineAsset(props) {
  const [visible, setVisible] = useState(false);
  const { register, handleSubmit, formState, control, reset, watch } = useForm({
    mode: "onChange",
  }); // initialise the hook

  let { errors } = formState;
  return (
    <React.Fragment>
      <OverlayTrigger
        placement="top"
        overlay={<Tooltip id="tooltip">{"View Machine"}</Tooltip>}
      >
        <div className="pdrIcon me-2">
          <Eye
            color="#FFFFFF"
            size={18}
            className="cursor-pointer"
            onClick={() => {
              // setUpdateForm(val)
              setVisible(true);
            }}
          />
        </div>
      </OverlayTrigger>
      <Modal show={visible} onHide={() => setVisible(false)} size={"lg"}>
        <Modal.Header style={{ background: "#2A3643", color: "white" }} closeButton>
          <Modal.Title>{"View Machine Details"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form action="">
            <div className="row mt-2">
              <div className="col-md-6">
              <div className="form-group row">
                  <label style={{fontSize: "14px"}} className="col-form-label  col-md-5">Name</label>
                  <div className="col-md-7">
                    <label style={{fontSize: "14px"}} className="col-form-label roboto-bold">{props.data.machineName}</label>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-5">
                    Last Service Date
                  </label>
                  <div className="col-md-7">
                    <label style={{fontSize: "14px"}} className="col-form-label roboto-bold">{props.data.lastServiceDate}</label>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label  col-md-5">Vendor Name</label>
                  <div className="col-md-7">
                    <label style={{fontSize: "14px"}} className="col-form-label roboto-bold">{props.data.vendorName}</label>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-5">
                    Exp. Date
                  </label>
                  <div className="col-md-7">
                    <label style={{fontSize: "14px"}} className="col-form-label roboto-bold">{props.data.expDate}</label>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label  col-md-5">Serial No</label>
                  <div className="col-md-7">
                    <label style={{fontSize: "14px"}} className="col-form-label roboto-bold">{props.data.serialNo}</label>
                  </div>
                </div>
              </div>

              <div className="col-md-6">
                <div className="form-group row">
                  <label style={{fontSize: "14px"}} className="col-form-label  col-md-5">Location</label>
                  <div className="col-md-7">
                    <label style={{fontSize: "14px"}} className="col-form-label roboto-bold">{props.data.locationname}</label>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label  col-md-5">Frequency</label>
                  <div className="col-md-7">
                    <label style={{fontSize: "14px"}} className="col-form-label roboto-bold">{props.data.mantainanceFrequency}</label>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label col-md-5">
                    Next Service Date
                  </label>
                  <div className="col-md-7">
                    <label style={{fontSize: "14px"}} className="col-form-label roboto-bold">{props.data.nextServiceDate}</label>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label  col-md-5">Cost</label>
                  <div className="col-md-7">
                    <label style={{fontSize: "14px"}} className="col-form-label roboto-bold">{props.data.cost}</label>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label  col-md-5">Model</label>
                  <div className="col-md-7">
                    <label style={{fontSize: "14px"}} className="col-form-label roboto-bold">{props.data.machineModel}</label>
                  </div>
                </div>

                <div className="form-group row mt-2">
                  <label style={{fontSize: "14px"}} className="col-form-label  col-md-5">
                    Remark
                  </label>
                  <div className="col-md-7">
                    <label style={{fontSize: "14px"}} className="col-form-label roboto-bold">{props.data.remark}</label>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default ViewMachineAsset;

export const fileTypes = ["image/jpeg",
    "image/jpg",
    "image/png",
    "application/pdf",
    "application/pdf",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document"];

export const imagefileTypes = ["image/jpeg",
    "image/jpg",
    "image/png",];
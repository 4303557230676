import React, {useEffect, useState} from 'react';
import { Typeahead } from 'react-bootstrap-typeahead';
import {useForm, Controller, useFieldArray } from 'react-hook-form';
import { Trash2, Eye } from "react-feather";
import { Modal } from 'react-bootstrap';
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAdd} from "@fortawesome/free-solid-svg-icons";
import { ToastContainer, toast } from 'react-toastify';
import { getLabels } from '../../../common/Functions/CommonFunctions';

// const schema = yup
//   .object()
//   .shape({
//     items: yup.array().of(
//       yup.object().shape({
//         attribute: yup
//           .array()
//           .min(1, "Please select product attribute !"),
//         attrib_value: yup.string()
//           .transform((value, originalValue) => {
//             if (originalValue === null) {
//                 return null;
//             }
//             return originalValue == '' ? null : value;
//           })
//           .matches(/^[0-9]+(\.[0-9]+)?$/, 'Enter attribute value !')
//           .nullable(),
//       })
//     ),
//   })
//   .required();
const schema = yup.object().shape({
  items: yup.array().of(
    yup.object().shape({
      attribute: yup
        .array(),
      attrib_value: yup
        .string()
        .when('attribute', {
          is: (attributes) => attributes && attributes.length > 0,
          then: yup.string()
            .required("Enter measured value !")
            .matches(/^[0-9]+(\.[0-9]+)?$/, 'Enter valid measured value !')
            .nullable(),
          otherwise: yup.string()
            .transform((value, originalValue) => {
              if (originalValue === null) {
                return '';
              }
              return originalValue === '' ? '' : value;
            })
        })
        .nullable(),
    })
  ),
}).required();

function NcAttributes({attributeModal, setAttributeModal, nc_attrib, setNC_Attrib, currInd, prod_attr 
}) {

const {handleSubmit, register, formState, control, reset} = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
        items: [{ attribute: [], attrib_value: ""}],
      },
});

let { errors } = formState;
const { fields, append, remove } = useFieldArray({ control, name: "items" });

const [constLabel, setConstLabel] = useState({});


useEffect(() => {
    
    
    setConstLabel(getLabels('Quality', 'NC'));
    var attibItems = [];  
    if (
      nc_attrib?.[currInd] !== undefined &&
      nc_attrib?.[currInd]?.length > 0
    ) {

      for (let i = 0; i < nc_attrib?.[currInd]?.length; i++) {
            attibItems.push({
            attribute: [
              {
                productId : nc_attrib?.[currInd]?.[i]?.productId,
                attId : nc_attrib?.[currInd]?.[i]?.attId,
                attname : nc_attrib?.[currInd]?.[i]?.attname, 
                value : nc_attrib?.[currInd]?.[i]?.value,       
                unit : nc_attrib?.[currInd]?.[i]?.unit,
                unitname : nc_attrib?.[currInd]?.[i]?.unitname,
                name : nc_attrib?.[currInd]?.[i]?.name,
                minValue : nc_attrib?.[currInd]?.[i]?.minValue,
                maxValue : nc_attrib?.[currInd]?.[i]?.maxValue,
                uuid : nc_attrib?.[currInd]?.[i]?.product_attribute_id,
                product_id : nc_attrib?.[currInd]?.[i]?.product_id,
                attribute_id : nc_attrib?.[currInd]?.[i]?.attribute_id,
                unit_id : nc_attrib?.[currInd]?.[i]?.unit_id
              },
            ],
            attrib_value : nc_attrib?.[currInd]?.[i]?.attrib_value,
          });
       
      }
      
      reset({ items: attibItems });
    } else {
      
      reset({items: [{ attribute: [], attrib_value: ""}]})
    }
}, [attributeModal, nc_attrib?.[currInd]?.length > 0 ])


const onSubmit = (data) => {
    
    let reqPayload = [];

    if(data.items !== undefined && data.items.length > 0 && data.items?.[0]?.attribute.length > 0 && data.items?.[0]?.attrib_value !== null){
        for (let i = 0; i < data.items.length; i++) {

            const payload = {
                productId : data.items[i]?.attribute?.[0]?.productId,
                attId : data.items[i]?.attribute?.[0]?.attId,
                attname : data.items[i]?.attribute?.[0]?.attname,
                attrib_value : parseFloat(data.items[i]?.attrib_value),
                value : data.items[i]?.attribute?.[0]?.value,       
                unit : data.items[i]?.attribute?.[0]?.unit,
                unitname : data.items[i]?.attribute?.[0]?.unitname,
                name : data.items[i]?.attribute?.[0]?.name,
                minValue : data.items[i]?.attribute?.[0]?.minValue,
                maxValue : data.items[i]?.attribute?.[0]?.maxValue,
                product_attribute_id : data.items[i]?.attribute?.[0]?.uuid,
                product_id : data.items[i]?.attribute?.[0]?.product_id,
                attribute_id : data.items[i]?.attribute?.[0]?.attribute_id,
                unit_id : data.items[i]?.attribute?.[0]?.unit_id
            }

            reqPayload.push(payload);
        }
            // 
            let arr = [...nc_attrib];
            arr[currInd] = reqPayload;
            
            setNC_Attrib(arr);
            setAttributeModal({state:false});
    } else {
      setNC_Attrib([]);
      setAttributeModal({state:false});
    }

}

  return (
    <React.Fragment>
      <ToastContainer />
      <Modal
        show={attributeModal.state}
        onHide={() => setAttributeModal({ ...attributeModal, state: false })}
        size="lg"
        backdrop="static"
      >
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
          onClick={() =>  { reset();}}
        >
          <Modal.Title>
            {constLabel?.lbl_add_nc_attribute ? constLabel?.lbl_add_nc_attribute : "Add NC Attribute"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <div className="createNc container-fluid">
              <div
                className="py-3"
                style={{ overflowX: "scroll" }}
              >
                <table className="table table-responsive table-bordered">
                  <thead className="thead-light text-center">
                    <tr>
                      <th scope="col">{constLabel?.lbl_attribute ? constLabel?.lbl_attribute : "Attribute"}</th>
                      <th scope="col">{constLabel?.lbl_measured_value ? constLabel?.lbl_measured_value : "Measured Value"}</th>
                      <th scope="col">{"Action"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fields.map(({ id }, index) => (
                      <tr key={id} className="f-18">
                        <td>
                        <Controller
                            name={`items[${index}].attribute`}
                            control={control}
                            render={({
                              field: { onChange, onBlur, value, ref },
                            }) => (
                              <Typeahead
                                id="basic-typeahead-single"
                                labelKey="name"
                                size="sm"
                                multiple={false}
                                options={prod_attr.data}
                                isLoading={prod_attr.loading}
                                placeholder="Select product attribute"
                                onChange={onChange}
                                onBlur={onBlur}
                                selected={value}
                              />
                            )}
                          />

                          {errors.items?.[index]?.attribute && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.attribute.message}
                            </span>
                          )}

                          {index + 1 == fields.length ? (
                            <div>
                              <button
                                style={{ fontSize: "16px" }}
                                className="btn btn-sm button-primary px-2 mt-2"
                                onClick={() => {
                                  append({});
                                }}
                              >
                                <FontAwesomeIcon
                                  style={{
                                    color: "#ffffff",
                                    marginRight: "5px",
                                  }}
                                  icon={faAdd}
                                  size="sm"
                                />{" "}
                                {"Add"}
                              </button>
                            </div>
                          ) : null}
                        </td>

                        <td style={{ verticalAlign: "top" }}>
                          <input
                            className="form-control form-control-sm"
                            type="text"
                            name={`items[${index}].attrib_value`}
                            placeholder='Enter measured value'
                            {...register(`items[${index}].attrib_value`)}
                          />

                          {errors.items?.[index]?.attrib_value && (
                            <span className="text-danger err-msg">
                              {errors.items?.[index]?.attrib_value.message}
                            </span>
                          )}
                        </td>

                        <td
                          style={{
                            textAlign: "center",
                            verticalAlign: "top",
                            width: "10%",
                          }}
                        >
                          <div
                            className="deleteIcon cursor_pointer"
                            onClick={
                              // index > 0 ? 
                              () => {
                                  remove(index);
                                } 
                              // : null
                            }
                          >
                            <Trash2 color="#FFFFFF" size={18} />
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-yellow px-4"
            onClick={handleSubmit(onSubmit)}
          >
            {constLabel?.lbl_save ? constLabel?.lbl_save : "Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </React.Fragment>
  )
}

export default NcAttributes
import React, { useState } from "react";
import dash_image from "../../../assets/images/dash-image.png";
import logo from "../../../assets/images/proditm.png";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Spinner } from "react-bootstrap";
import { gst_details_api } from "../../../constant";
import axios from "axios";
import NameSetting from "./NameSetting";
import RegisterClient from "./RegisterClient";
import OTPValidation from "./OTPValidation";
import SetCredential from "./SetCredential";
import SweetAlert from "sweetalert2";

const schema = yup
  .object()
  .shape({
    gst_no: yup
      .string()
      .required("Please enter gst no !")
      .matches(
        /^([0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1})$/,
        "Enter valid gst no !"
      ),
  })
  .required();

function Common() {
  const [steps, setSteps] = useState([
    { label: "Gst Validation", completed: false },
    { label: "Name Setting", completed: false },
    { lable: "Register Client", completed: false },
    { label: "Otp Validation", completed: false },
    { label: "Set Credential", completed: false },
  ]);

  const [currStep, setCurrStep] = useState(0);
  const [resetLink, setResetLink] = useState(false);

  const [main_obj, setMain_obj] = useState({
    gst_detail: {},
    company_detail: {},
    reg_manual: false
  });

  let navigate = useNavigate();
  const [lockbtn, setLockBtn] = useState(false);
  const { register, handleSubmit, formState, setError, setValue, clearErrors } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const { errors } = formState;

  const onSubmit = (data) => {
    setLockBtn(true);
    // const url = process.env.REACT_APP_API_INV_URL + "/" + gst_details_api;
    let base_url = process.env.REACT_APP_COGNITO_AUTH_URL;
    const url = `${base_url}${gst_details_api}`;
    // console.log("url",url)
    axios
      .post(url, { gstin: data.gst_no, client: true })
      .then((res) => {
        setResetLink(false);
        setMain_obj({ ...main_obj, gst_detail: res.data.data.data });
        setLockBtn(false);
        nextStep();
      })
      .catch((err) => {
        console.log("err", err);
        if (err?.response?.data?.message === "GST number is invalid !" ||err?.response?.data?.message=="Client is already registered with this GST number !") {
          setError("gst_no", {
            type: "manual",
            message: err.response.data.message,
          });
        } else {
          // setError("gst_no", {
          //   type: "manual",
          //   message: err.response.data.message,
          // });    
          SweetAlert.fire({
            title: "Service failed!",
            text: "Please go through manually registration",
            icon: "warning",
            confirmButtonText: "Ok",
            cancelButtonText: "Cancel",
          }).then(() => {
            setMain_obj({ ...main_obj, reg_manual: true });
            setCurrStep(2)
            setLockBtn(false);
          });      
        }
         
        setResetLink(true);
        setLockBtn(false);
      });
    // nextStep();
  };

  const nextStep = () => {
    steps[currStep].completed = true;
    setSteps(steps);
    setCurrStep((prevStep) => Math.min(prevStep + 1, steps.length - 1));
  };

  const prevStep = () => {
    steps[currStep].completed = false;
    setSteps(steps);
    setCurrStep((prevStep) => Math.max(prevStep - 1, 0));
  };

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="card rounded-0 border-0 shadow-dark w-100">
        <div className="d-flex flex-wrap h-100" style={{ minHeight: "100vh" }}>
          {/* Left Section */}
          <div className="col-12 col-md-6 button-primary text-center d-md-flex d-none flex-column align-items-center pb-5 pd-30">
            <div className="text-start mt-5">
              <p className="roboto w-100 mt-5">
                MES SOLUTION FOR SME MANUFACTURERS
              </p>

              <h1 className="bold w-100 mt-1">Unlocking Success</h1>
              <h1 className="bold w-100">with PRODi Software</h1>

              <img
                src={dash_image}
                className="img-fluid mt-5 mx-auto"
                alt="Logo"
                style={{ maxHeight: "250px", maxWidth: "400px" }} // Ensure it's responsive.
              />
            </div>
          </div>

          {/* Right Section */}
          {currStep === 0 ? (
            <div className="col-12 col-md-6 pt-5 mt-30 align-items-center">
              <div className="text-center">
                <img
                  src={logo}
                  className="img-fluid mx-auto"
                  alt="Logo"
                  style={{ height: "70px", width: "150px" }} // Adjust image size
                />
                <h1 className="bold w-100 mt-5">Welcome to PRODi !</h1>
                <h3 className="w-100 my-0 mt-2">Experience the seamless</h3>
                <h3 className="w-100">onboarding process</h3>

                <p className="bold w-100 my-0 mt-5">Please enter your GSTIN</p>
                <p className="bold w-100">number to proceed?</p>
              </div>

              <div className="d-flex justify-content-center">
                <div className="w-50 mt-md-1">
                  <input
                    className={
                      errors.gst_no ? "form-control is-invalid" : "form-control"
                    }
                    id="gst_no"
                    name="gst_no"
                    type="text"
                    placeholder="Enter GST No"
                    {...register("gst_no")}
                  />
                  {errors.gst_no && (
                    <span className="text-danger err-msg ms-2">
                      {errors.gst_no.message}
                    </span>
                  )}
                </div>
              </div>

            { resetLink ? 
              <div className="d-flex justify-content-center">
                  <a href="#" className="body2" onClick={(e) => {e.preventDefault(); setResetLink(false); clearErrors(); setValue("gst_no", "")}}> Try with another GST number</a>
              </div>
            : null }
            
              <div className="d-flex justify-content-center">
                <div className="w-50 d-flex justify-content-center">
                  <button
                    className="btn button-primary w-100 mt-4 roboto-bold"
                    type="submit"
                    onClick={handleSubmit(onSubmit)}
                    disabled={lockbtn}
                  >
                    Submit{" "}
                    {lockbtn ? <Spinner animation="border" size="sm" /> : null}{" "}
                  </button>
                </div>
              </div>
            </div>
          ) : currStep === 1 ? (
            <NameSetting
              nextStep={nextStep}
              prevStep={prevStep}
              setSteps={setSteps}
              steps={steps}
              main_obj={main_obj}
              setMain_obj={setMain_obj}
            />
          ) : currStep === 2 ? (
            <RegisterClient
              nextStep={nextStep}
              prevStep={prevStep}
              setSteps={setSteps}
              steps={steps}
              main_obj={main_obj}
              setMain_obj={setMain_obj}
            />
          ) : currStep === 3 ? (
            <OTPValidation
              nextStep={nextStep}
              prevStep={prevStep}
              setSteps={setSteps}
              steps={steps}
              main_obj={main_obj}
              setMain_obj={setMain_obj}
            />
          ) : currStep === 4 ? (
            <SetCredential
              nextStep={nextStep}
              prevStep={prevStep}
              setSteps={setSteps}
              steps={steps}
              main_obj={main_obj}
              setMain_obj={setMain_obj}
              setCurrStep={setCurrStep}
              currStep={currStep}

            />
          ) : null}
        </div>
      </div>
    </React.Fragment>
  );
}

export default Common;

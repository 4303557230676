import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { OverlayTrigger } from "react-bootstrap";
import { Edit2 } from "react-feather";
import { Modal } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { getProductAttribute } from "../../../../api";
import { Tooltip } from "react-tooltip";

function Product_Attributes(props) {

  const [visible, setVisible] = useState(false);
  const [prod_attr, setProd_Attr] = useState({ data: [], loading: false });


  useEffect(() => {
    if(visible){
        fetchProductAttributes(props?.data?.product_id ?? props?.prod_id?.uuid);
    }
  }, [visible]);

  function fetchProductAttributes(prod_uuid) {
    setProd_Attr({ ...prod_attr, data: [], loading: true });
    const payload = {
      product_id: prod_uuid,
    };
    getProductAttribute(payload).then(
      (res) => {
        console.log("res", res);
        
        const result = res.data.data.filter(
          (item) =>
            item.prod_operation_details?.operation_id === props.data?.operation_id
        );

        if (result) {
          setProd_Attr({ ...prod_attr, data: result, loading: false });
        } else {
          setProd_Attr({ ...prod_attr, data: [], loading: false });
        }
      },
      (err) => {
        setProd_Attr({ ...prod_attr, data: [], loading: false });
      }
    );
  }

  return (
    <React.Fragment>
        <button
          className="btn btn-outline-secondary btn-sm me-3"
          onClick={() => setVisible(true)}
        >
          {props.constLabel?.lbl_inspection_parameter
            ? props.constLabel?.lbl_inspection_parameter
            : "Inspection Parameter"}
        </button>
      <Modal show={visible} onHide={() => setVisible(false)} size={"lg"}>
        <Modal.Header
          style={{ background: "#2A3643", color: "white" }}
          closeButton
        >
          <Modal.Title>
            {props.constLabel?.lbl_inspection_parameter
              ? props.constLabel?.lbl_inspection_parameter
              : "Inspection Parameter"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {prod_attr.loading ? (
                  <div className="text-center">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : prod_attr.data?.length > 0 ? (
                    <div className="col-12 col-md-12">
                      <table className="table table-responsive table-bordered table-sm">
                        <thead className="table-grey roboto-bold">
                          <tr>
                            <th>{props.constLabel?.lbl_attribute_name ? props.constLabel?.lbl_attribute_name : "Attribute Name"}</th>
                            <th>{props.constLabel?.lbl_name ? props.constLabel?.lbl_name : "Name"}</th>
                            <th>{props.constLabel?.lbl_min_value ? props.constLabel?.lbl_min_value : "Min Value"}</th>
                            <th>{props.constLabel?.lbl_max_value ? props.constLabel?.lbl_max_value : "Max Value"}</th>
                            <th>{props.constLabel?.lbl_value ? props.constLabel?.lbl_value : "Value"}</th>
                            <th>{props.constLabel?.lbl_uom ? props.constLabel?.lbl_uom : "UOM"}</th>
                          </tr>
                        </thead>
                        <tbody>
                        {prod_attr.data?.map((val, ind) => (
                          <tr key={ind}>
                            <td>{val.attname}</td>
                            <td>{val.name}</td>
                            <td>{val.minValue}</td>
                            <td>{val.maxValue}</td>
                            <td>{val.value}</td>
                            <td>{val.unitname}</td>
                          </tr>
                        ))}

                        </tbody>

                      </table>
                    </div>
                 ) : (
                  <div
                    className="alert alert-danger mb-0 text-center px-2"
                    role="alert"
                  >
                    {"Inspection parameter not found !"}
                  </div>
                )}
        </Modal.Body>
      </Modal>
    </React.Fragment>
  );
}

export default Product_Attributes;
